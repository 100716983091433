import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';

import classes from '../portfolio.module.css';
import CreateUpdatePortfolioModal from './CreateUpdatePortfolioModal';
import ActionButton from './ActionButton';
import PerformanceStats from './PerformanceStats';
import { changeSelectedPortfolio } from '../portfolioSlice';
import { getCurrencyLabel } from '../currencies';

const { CancelToken } = axios;

export default function Header() {
  const selectedPortfolio = useSelector((state) => state.portfolio.selected);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    // Fix url with selected portfolio and selected layout
    if (selectedPortfolio && selectedPortfolio.id.toString() !== params.portfolioID) {
      const url = `/portfolio/${selectedPortfolio.id}`;
      navigate(url);
    }
  }, [selectedPortfolio]);

  const handleAddPortfolio = () => {
    setShowModal(true);
  };

  return (
    <>
      <div className="col-lg-4">
        <div className="g-mb-15">
          <h3>
            <span className={`text-break ${classes.portfolioName}`}>{selectedPortfolio.name}</span>
            <span className={selectedPortfolio.default ? 'd-inline-block' : 'd-none'}>
              <i className={`fa-solid fa-star ms-2 ${classes.portfolioDefaultIcon}`} />
            </span>
          </h3>
          <h6>Portfolio Currency: {getCurrencyLabel(selectedPortfolio.currency)}</h6>
        </div>
        <div className="d-flex flex-row ">
          <PortfolioSelect />
          <Button variant="light" className="g-ml-10" onClick={handleAddPortfolio}>
            Create New
          </Button>
          <CreateUpdatePortfolioModal
            show={showModal}
            handleCloseModal={() => {
              setShowModal(false);
            }}
          />
        </div>
      </div>
      <div className="col-lg-8">
        <div className="row g-3">
          <div className="col-11 g-mb-10 order-lg-1">
            <PerformanceStats />
          </div>
          <div className="col-lg-auto g-mb-10 order-first order-lg-2">
            <ActionButton />
          </div>
          <div id="portfolioAnchorForFirstTab" className="order-last" />
        </div>
      </div>
    </>
  );
}

function PortfolioSelect() {
  const dispatch = useDispatch();
  const source = CancelToken.source();
  const selectedPortfolio = useSelector((state) => state.portfolio.selected);
  const allPortfolio = useSelector((state) => state.portfolio.all);
  const selectedLayout = useSelector((state) => state.portfolio.selectedLayout);

  const handleSelectedPortfolioChange = (portfolio) => {
    const newLayout = !portfolio.id ? '' : portfolio.classic ? 'trading_data' : 'portfolio';
    dispatch(
      changeSelectedPortfolio({
        selectedPortfolioID: portfolio.id,
        selectedLayout: newLayout,
        cancelToken: source.token,
      }),
    );
  };

  return (
    <Dropdown>
      <Dropdown.Toggle variant="light">
        <span className="g-ml-15">Switch</span>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {allPortfolio
          .filter((portfolio) => portfolio.id !== selectedPortfolio.id)
          .map((portfolio) => {
            return (
              <Dropdown.Item
                as={Link}
                to={portfolio.id.toString()}
                key={portfolio.id}
                value={portfolio.id}
                onClick={() => {
                  handleSelectedPortfolioChange(portfolio);
                }}>
                <span>{portfolio.name}</span>
                <span className={portfolio.default ? 'd-inline-block' : 'd-none'}>
                  <i className={`fa-solid fa-star ms-2 ${classes.portfolioDefaultIcon}`} />
                </span>
              </Dropdown.Item>
            );
          })}
      </Dropdown.Menu>
    </Dropdown>
  );
}
