import { createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';

const consensusEstimatesSlice = createSlice({
  name: 'marketsConsensusEstimates',
  initialState: {
    data: {},
    loading: true,
    err: null,
    dataPortfolio: {},
    loadingPortfolio: true,
    errPortfolio: null,
  },
  reducers: {
    consensusEstimatesDataFetchStart(state, action) {
      state.data = null;
      state.err = null;
      state.loading = true;
    },
    consensusEstimatesDataFetchSuccess(state, action) {
      state.data = action.payload.data;
      state.err = null;
      state.loading = false;
    },
    consensusEstimatesDataFetchFail(state, action) {
      state.err = action.payload;
      state.loading = false;
    },
    consensusEstimatesPortfolioFetchStart(state, action) {
      state.dataPortfolio = null;
      state.errPortfolio = null;
      state.loadingPortfolio = true;
    },
    consensusEstimatesPortfolioFetchSuccess(state, action) {
      state.dataPortfolio = action.payload.dataPortfolio;
      state.errPortfolio = null;
      state.loadingPortfolio = false;
    },
    consensusEstimatesPortfolioFetchFail(state, action) {
      state.errPortfolio = action.payload;
      state.loadingPortfolio = false;
    },
  },
});

export default consensusEstimatesSlice.reducer;

const {
  consensusEstimatesDataFetchStart,
  consensusEstimatesDataFetchSuccess,
  consensusEstimatesDataFetchFail,
  consensusEstimatesPortfolioFetchStart,
  consensusEstimatesPortfolioFetchSuccess,
  consensusEstimatesPortfolioFetchFail,
} = consensusEstimatesSlice.actions;

export const fetchData =
  ({ market, minRatings, cancelToken }) =>
  (dispatch) => {
    let url = `/${market}/consensus_estimates.json`;
    if (minRatings) {
      url += `?min_ratings=${minRatings}`;
    }

    dispatch(consensusEstimatesDataFetchStart());
    api
      .get(url, {
        cancelToken,
      })
      .then(({ data }) => {
        dispatch(consensusEstimatesDataFetchSuccess({ data }));
      })
      .catch((error) => {
        dispatch(consensusEstimatesDataFetchFail(error));
      });
  };

export const fetchPortolioData =
  ({ counterList, market, folder, cancelToken }) =>
  (dispatch) => {
    let url = `/${market}/consensus_estimates_portfolio.json`;
    if (folder) {
      url += `?folder=${folder}`;
    } else if (counterList) {
      url += `?counter_list=${counterList}`;
    }

    dispatch(consensusEstimatesPortfolioFetchStart());
    api
      .get(url, {
        cancelToken,
      })
      .then(({ data }) => {
        dispatch(consensusEstimatesPortfolioFetchSuccess({ dataPortfolio: data }));
      })
      .catch((error) => {
        dispatch(consensusEstimatesPortfolioFetchFail({ errPortfolio: error }));
      });
  };
