import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ResetPasswordComponent from './ResetPasswordComponent';

function ResetPassword({ site_key = '', valid_code = false, perform_check = true }) {
  return (
    <Container className="mt-4 mb-4">
      <Row className="justify-content-center">
        <Col sm={12} xxl={4} lg={8}>
          <ResetPasswordComponent site_key={site_key} valid_code={valid_code} perform_check={perform_check}></ResetPasswordComponent>
        </Col>
      </Row>
    </Container>
  );
}

ResetPassword.propTypes = {
  site_key: PropTypes.string,
  valid_code: PropTypes.bool,
  perform_check: PropTypes.bool
};

export default ResetPassword;
