import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import store from '../../utils/store';
import NotFound from '../../components/NotFound';
import { createLoadableComponent } from '../../utils/Loadable';
import MembershipSelectPlan from './MembershipSelectPlan';
import MembershipSelectAddOns from './MembershipSelectAddOns';
import MembershipPayment from './MembershipPayment';
import MembershipComplete from './MembershipComplete';

const MembershipMain = createLoadableComponent(() => import('./MembershipMain'));

function Membership(props) {
  const { hash } = window.location;
  const { country, plan, period, addOns, priceType, checkCaptcha, siteKey, promoCode } = props;

  let initPlan = plan;
  if (hash) {
    if (hash.includes('StationSubscription')) {
      initPlan = `station${country === 'my' ? '_my' : ''}`;
    } else {
      initPlan = `webpro${country === 'my' ? '_my' : ''}`;
    }
  }

  return (
    <Provider store={store}>
      <Router
        country={country}
        plan={initPlan}
        period={period}
        addOns={addOns}
        priceType={priceType}
        checkCaptcha={checkCaptcha}
        siteKey={siteKey}
        promoCode={promoCode}
      />
    </Provider>
  );
}

export default Membership;

Membership.propTypes = {
  country: PropTypes.string,
  plan: PropTypes.string,
  period: PropTypes.string,
  addOns: PropTypes.string,
  priceType: PropTypes.string,
  checkCaptcha: PropTypes.bool,
  siteKey: PropTypes.string,
  promoCode: PropTypes.string,
};

function Router({ country, plan, period, addOns, priceType, checkCaptcha, siteKey, promoCode }) {
  const router = createBrowserRouter([
    {
      path: '/membership/plans',
      element: (
        <MembershipMain
          country={country}
          initPlan={plan}
          initPeriod={period}
          initAddOns={addOns}
          initPriceType={priceType}
          initPromoCode={promoCode}
        />
      ),
      children: [
        {
          path: '',
          label: 'Plans Comparison',
          element: (
            <MembershipSelectPlan country={country} checkCaptcha={checkCaptcha} siteKey={siteKey} />
          ),
        },
        {
          path: 'select_plans',
          label: 'Select Plans',
          element: <MembershipSelectAddOns country={country} />,
        },
        {
          path: 'payment_options',
          label: 'Payment Options',
          element: <MembershipPayment country={country} />,
        },
        {
          path: 'complete',
          label: 'complete',
          element: <MembershipComplete country={country} />,
        },
      ],
    },
    {
      path: '*',
      element: <NotFound />,
    },
  ]);

  return <RouterProvider router={router} />;
}

Router.propTypes = {
  country: PropTypes.string,
  plan: PropTypes.string,
  period: PropTypes.string,
  addOns: PropTypes.string,
  priceType: PropTypes.string,
  checkCaptcha: PropTypes.bool,
  siteKey: PropTypes.string,
  promoCode: PropTypes.string,
};
