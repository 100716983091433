import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink, Outlet } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import { Nav } from 'react-bootstrap';

// Used for tabs with the use of React Router
export default function RouterNavComponent({
  navLinks,
  defaultActiveNav,
  content,
  classes,
  counterInfoComponent,
}) {
  const [isSSR, setIsSSR] = useState(true);
  const [activeNav, setActiveNav] = useState(defaultActiveNav);

  useEffect(() => {
    setIsSSR(typeof document === 'undefined');
    return () => {};
  }, []);

  useEffect(() => {
    setActiveNav(defaultActiveNav);
    return () => {};
  }, [defaultActiveNav]);

  return (
    <Tab.Container>
      <Nav className={`nav-pills ${classes || 'g-mb-40'}`}>
        {counterInfoComponent}
        <div className="navItemsContainer">
          {navLinks
            .filter((tab) => !tab.hideNav && !tab.index)
            .map((tab) => (
              <Nav.Item key={`${tab.key}-item`} className={`${tab.class} g-mb-5`}>
                <NavLink
                  className={({ isActive }) =>
                    isActive || activeNav === tab.key ? 'nav-link active' : 'nav-link'
                  }
                  eventkey={tab.key}
                  to={tab.path}
                  onClick={() => setActiveNav(tab.key)}
                  id={tab.key}>
                  {tab.icon && !isSSR && <i className={tab.icon} />}
                  {tab.label}
                </NavLink>
              </Nav.Item>
            ))}
        </div>
      </Nav>
      <Tab.Content>{content || <Outlet />}</Tab.Content>
    </Tab.Container>
  );
}

RouterNavComponent.propTypes = {
  navLinks: PropTypes.array,
  defaultActiveNav: PropTypes.string,
  content: PropTypes.object,
  classes: PropTypes.string,
  counterInfoComponent: PropTypes.object,
};
