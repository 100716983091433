import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useSearchParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { NewsInfiniteScroll } from '../../../components/News';
import { createLoadableComponent } from '../../../utils/Loadable';
import Loader from '../../../components/Loader';
import APIErrorHandler from '../../../components/APIErrorHandler';
import { useDetectMobileScreen } from '../../../utils/utils';

const DateFilter = createLoadableComponent(() => import('../../../components/DateFilter'));
const CategoryFilter = createLoadableComponent(() =>
  import('../../../components/News/CategoryFilter'),
);

const DELAY = 800;
const NEWS_PER_PAGE = 30;
function News({ counter, newsType, basePath }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [fromDate, setFromDate] = useState(searchParams.get('from_date'));
  const [toDate, setToDate] = useState(searchParams.get('to_date'));
  const [category, setCategory] = useState(searchParams.get('category'));
  const [viewMode, setViewMode] = useState('list');
  const [isSSR, setIsSSR] = useState(true);
  const error = useSelector((state) => state.newsInfiniteScroll.err);
  const location = useLocation();
  const isMobile = useDetectMobileScreen();
  const newsConfig = {
    counter: `${counter}`,
    newsType: newsType || 'news_all',
    fromDate,
    toDate,
    category: category,
    origin: 'factsheet',
  };

  const handleViewMode = (mode) => {
    setViewMode(mode);
  };

  const VIEW_MODE_BUTTONS = [
    {
      icon: 'fa-thin fa-grid-2 fa-lg',
      value: 'grid',
    },
    {
      icon: 'fa-thin fa-list-ul fa-lg',
      value: 'list',
    },
  ];

  const viewModeComponent = (
    <div className="d-flex gap-2 flex-wrap">
      {VIEW_MODE_BUTTONS.map((button) => {
        return (
          <button
            type="button"
            key={button.value}
            onClick={() => handleViewMode(button.value)}
            className={`${
              button.value === viewMode ? 'active' : ''
            } btn btn-outline-light g-min-width-0 g-width-40 g-height-40 p-0`}>
            <i className={`${button.icon}`} />
          </button>
        );
      })}
    </div>
  );

  const getCurrentParams = () => {
    const params = {};

    if (fromDate) {
      params.from_date = fromDate;
    }

    if (toDate) {
      params.to_date = toDate;
    }

    if (newsType === 'sgxnet' && category) {
      params.category = category;
    }

    return params;
  };

  const handleFromDateChange = (_newDate) => {
    if (!_newDate) {
      return;
    }

    let newDate = '';
    if (_newDate.length > 0) {
      newDate = dayjs(new Date(_newDate)).format('YYYY-MM-DD');
    }

    if (fromDate === newDate) {
      return;
    }

    setSearchParams({ ...getCurrentParams(), from_date: newDate });
    setFromDate(newDate);
  };

  const handleToDateChange = (_newDate) => {
    if (!_newDate) {
      return;
    }

    let newDate = '';
    if (_newDate.length > 0) {
      newDate = dayjs(new Date(_newDate)).format('YYYY-MM-DD');
    }

    if (toDate === newDate) {
      return;
    }

    setSearchParams({ ...getCurrentParams(), to_date: newDate });
    setToDate(newDate);
  };

  const handleDateReset = () => {
    if (searchParams.has('from_date')) {
      searchParams.delete('from_date');
    }
    if (searchParams.has('to_date')) {
      searchParams.delete('to_date');
    }
    setSearchParams(searchParams);
    setToDate('');
    setFromDate('');
  };

  const handleCategoryChange = (newCategory) => {
    if (!newCategory || category === newCategory) {
      return;
    }

    setSearchParams({ ...getCurrentParams(), category: newCategory });
    setCategory(newCategory);
  };

  // change to list mode in mobile
  useEffect(() => {
    if (isMobile) {
      handleViewMode('list');
    }
  }, [isMobile]);

  useEffect(() => {
    setSearchParams(getCurrentParams());
    return () => {};
  }, [newsType]);

  useEffect(() => {
    if (searchParams.get('from_date') && fromDate !== searchParams.get('from_date')) {
      setFromDate(searchParams.get('from_date'));
    }
    if (searchParams.get('to_date') && toDate !== searchParams.get('to_date')) {
      setToDate(searchParams.get('to_date'));
    }
    if (searchParams.get('category') && category !== searchParams.get('category')) {
      setCategory(searchParams.get('category'));
    }
  }, [location]);

  useEffect(() => {
    setIsSSR(typeof document === 'undefined');
    setFromDate(searchParams.get('from_date'));
    setToDate(searchParams.get('to_date'));
  }, []);

  if (isSSR) {
    return <Loader visible={isSSR} classes="g-height-800" />;
  }

  if (error) {
    return <APIErrorHandler error={error} />;
  }

  return (
    <div id="newsLists" className="row gy-4 g-mb-15">
      <div className="col-12">
        <div className="row gap-2 align-items-center justify-content-between">
          <div className="col-lg">
            <DateFilter
              isInterval
              fromDate={fromDate}
              handleFromDateChange={handleFromDateChange}
              toDate={toDate}
              handleToDateChange={handleToDateChange}
              handleReset={handleDateReset}
            />
            {newsType === 'sgxnet' && (
              <CategoryFilter category={category} handleCategoryChange={handleCategoryChange} />
            )}
          </div>
          <div className="col-lg-auto">{!isMobile && viewModeComponent}</div>
        </div>
      </div>
      <NewsInfiniteScroll
        delay={DELAY}
        newsPerLoad={NEWS_PER_PAGE}
        newsConfig={newsConfig}
        basePath={basePath}
        viewMode={viewMode}
      />
    </div>
  );
}

News.propTypes = {
  counter: PropTypes.string,
  newsType: PropTypes.string,
  basePath: PropTypes.string,
};

export default News;
