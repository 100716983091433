import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ContentLoader from 'react-content-loader';

import ConsensusEstimatesGaugeChart from '../../../components/ConsensusEstimatesGaugeChart';
import SparklineChart from '../../../components/SparklineChart';
import BarLineChart from './BarLineChart';
import CompanyLogoImage from '../../../components/CompanyLogoImage';
import DefinitionPopup from '../../../components/DefinitionPopup';
import { Constants } from '../../../utils/constants';
import { MAX_STOCKS } from './StocksComparisonConstants';

import {
  selectStockCodes,
  selectWarrantCodes,
  selectStockData,
  selectWarrantData,
  selectStockStatus,
  selectWarrantStatus,
} from './stocksComparisonSlice';

function StocksComparisonRow({ columnHeader, screenType }) {
  const stockCodes =
    screenType == 'warrants' ? useSelector(selectWarrantCodes) : useSelector(selectStockCodes);
  const stockData =
    screenType == 'warrants' ? useSelector(selectWarrantData) : useSelector(selectStockData);
  const loadingStatus =
    screenType == 'warrants' ? useSelector(selectWarrantStatus) : useSelector(selectStockStatus);

  const getSparkLineData = () => {
    const stockDataArray = Object.values(stockData);
    const stockCodesArray = Object.values(stockCodes);
    if (stockDataArray && stockDataArray.length > 0) {
      return stockDataArray
        .filter((stock) => stockCodesArray.includes(stock.counter.toLowerCase()))
        .map((stock) => ({
          name: stock.name,
          value: Math.round(stock.sparkline),
        }));
    }
    return [];
  };

  const getRevenueChartData = () => {
    const stockDataArray = Object.values(stockData);
    const stockCodesArray = Object.values(stockCodes);
    if (stockDataArray && stockDataArray.length > 0) {
      return stockDataArray
        .filter((stock) => stockCodesArray.includes(stock.counter.toLowerCase()))
        .map((stock) => ({
          name: stock.name,
          value: Math.round(parseFloat(stock.revenue)),
        }));
    }
    return [];
  };

  const getCellHeaderElement = (index, fieldName) => {
    const stockCode = stockCodes[index].toLowerCase();
    const stockDataRow = stockData[stockCode];
    const market = stockDataRow?.market?.toUpperCase();
    const symbol = stockDataRow?.symbol?.toUpperCase();

    if (stockDataRow) {
      return (
        <td
          key={`td_${fieldName}_${stockDataRow.symbol}`}
          className="text-center align-middle g-per-width-16">
          {stockDataRow.total_share && (
            <div className="g-mb-5 mx-auto">
              <a
                href={`/quote/${market}:${symbol}`}
                target="_blank"
                className="g-width-45 g-height-45 bg-white g-pt-7 g-pr-7 g-pb-7 g-pl-7 d-inline-block"
                rel="noreferrer">
                <CompanyLogoImage counter={stockDataRow.counter} />
              </a>
            </div>
          )}
          <div>
            <a href={`/quote/${market}:${symbol}`} target="_blank" rel="noreferrer">
              {stockDataRow.name}
            </a>
          </div>
        </td>
      );
    }
    return (
      <td key={`emptyBodyCell_${index}`} className="text-center g-per-width-16">
        &nbsp;
      </td>
    );
  };

  function Loader() {
    return (
      <td className="text-center align-middle g-per-width-16">
        <ContentLoader
          speed={1}
          width={195}
          height={22.5}
          viewBox="0 0 195 22.5"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb">
          <rect x="0" y="0" rx="3" ry="3" width="195" height="22.5" />
        </ContentLoader>
      </td>
    );
  }

  function CellElement({ index, fieldName }) {
    const stockCode = stockCodes[index].toLowerCase();
    const stockDataRow = stockData[stockCode];

    if (stockDataRow) {
      return (
        <td
          key={`td_${fieldName}_${stockDataRow.symbol}`}
          className="text-center align-middle g-per-width-16">
          {fieldName == 'ce_recommendation' ? (
            <ConsensusEstimatesGaugeChart
              recommendation={stockDataRow[fieldName]}
              chartType="stocks_comparison"
            />
          ) : (
            stockDataRow[fieldName]
          )}
        </td>
      );
    }
    return <td className="text-center g-per-width-16">&nbsp;</td>;
  }

  return columnHeader.map((row) => (
    <tr
      key={row.title}
      className={`${row.classes} ${row.header ? 'table-light align-middle fw-bold table-no-hover' : ''
        }`}>
      {row.header && (
        <>
          <td className="g-per-width-15" colSpan={`${row.full_width ? '7' : ''} `}>
            {row.title}
          </td>
          {!row.full_width && (
            <>
              <td className="text-center align-middle px-1 g-per-width-5">Trend</td>
              {[...Array(MAX_STOCKS).keys()].map((index) => {
                return index in stockCodes ? (
                  getCellHeaderElement(index, row.field)
                ) : (
                  <td key={`emptyBodyCell_${index}`} className="text-center g-per-width-16">
                    &nbsp;
                  </td>
                );
              })}
            </>
          )}
        </>
      )}
      {!row.header && (
        <>
          {row.full_width && (
            <td colSpan="7">
              {row.field === 'revenue' && (
                <BarLineChart data={getRevenueChartData()} title="Revenue" />
              )}
            </td>
          )}
          {!row.full_width && (
            <>
              <td className="g-per-width-15">
                <DefinitionPopup keyword={row.definition} delayShow={500} delayHide={1000}>
                  <span>{row.title}</span>
                </DefinitionPopup>
              </td>

              <td className="text-center align-middle px-1 g-per-width-5">
                {row.sparkline && <SparklineChart data={getSparkLineData()} />}
              </td>
              {[...Array(MAX_STOCKS).keys()].map((index) => {
                return index in stockCodes ? (
                  loadingStatus[index] == Constants.STATUS_LOADING ? (
                    <Loader key={`td_${row.field}_${index}`} />
                  ) : (
                    <CellElement key={index} index={index} fieldName={row.field} />
                  )
                ) : (
                  <td key={`emptyBodyCell_${index}`} className="text-center g-per-width-16">
                    &nbsp;
                  </td>
                );
              })}
            </>
          )}
        </>
      )}
    </tr>
  ));
}
StocksComparisonRow.propTypes = {
  columnHeader: PropTypes.arrayOf(PropTypes.object),
  screenType: PropTypes.string,
};

export default StocksComparisonRow;
