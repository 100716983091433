import { createSlice } from '@reduxjs/toolkit';

const selectedMarketSlice = createSlice({
  name: 'selectedMarket',
  initialState: {
    isInitialized: false,
    selectedMarket: null,
  },
  reducers: {
    selectedMarketOnInit(state, action) {
      if (!state.isInitialized && action.payload.market) {
        state.selectedMarket = action.payload.market;
        state.isInitialized = true;
      }
    },
    selectedMarketOnUpdate(state, action) {
      const newSelectedMarket = action.payload.market;
      if (state.isInitialized && state.selectedMarket != newSelectedMarket) {
        state.selectedMarket = newSelectedMarket;
      }
    },
  },
});

export default selectedMarketSlice.reducer;

const { selectedMarketOnInit, selectedMarketOnUpdate } = selectedMarketSlice.actions;

export const initSelectedMarket =
  ({ market }) =>
  (dispatch) => {
    if (market) {
      dispatch(selectedMarketOnInit({ market }));
    }
  };

export const updateSelectedMarket =
  ({ market }) =>
  (dispatch) => {
    if (market) {
      dispatch(selectedMarketOnUpdate({ market }));
    }
  };
