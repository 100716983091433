import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import classes from './insights.module.css';
import ValuationChart from '../../Screener/IntrinsicValueAnalysis/ValuationChart';
import IntrinsicAnalysisCalculator from '../../Screener/IntrinsicValueAnalysis/IntrinsicAnalysisCalculator';
import CalculatorModel from '../../Screener/IntrinsicValueAnalysis/CalculatorModel';
import APIErrorHandler from '../../../components/APIErrorHandler';
import { fetchData } from '../../Screener/IntrinsicValueAnalysis/intrinsicValueAnalysisSlice';
import { compareObjectsByNumberAttr } from '../../../utils/utils';

const { CancelToken } = axios;
export default function IntrinsicValueAnalysis() {
  const source = CancelToken.source();
  const dispatch = useDispatch();
  const selectedPortfolio = useSelector((state) => state.portfolio.selected);
  const [currentCounters, setCurrentCounters] = useState([]);
  const [selectOptions, setSelectOptions] = useState([]);
  const [activeCounter, setActiveCounter] = useState(null);
  const [showCalculator, setShowCalculator] = useState(false);
  const toggleCalculator = () => setShowCalculator((_show) => !_show);
  const data = useSelector((state) => state.intrinsicValueAnalysis.data);
  const error = useSelector((state) => state.intrinsicValueAnalysis.err);
  const loading = useSelector((state) => state.intrinsicValueAnalysis.loading);
  const intrinsicValue = useSelector((state) => state.intrinsicValueAnalysis.intrinsicValue);

  useEffect(() => {
    if (selectedPortfolio?.counters?.length > 0) {
      const counters = selectedPortfolio.counters
        .map((c) => {
          return { counter: c.counter, stockName: c.Name, position: c.No };
        })
        .sort((a, b) => compareObjectsByNumberAttr(a, b, 'position'));
      if (JSON.stringify(counters) !== JSON.stringify(currentCounters)) {
        setCurrentCounters(counters);
        setActiveCounter(counters[0].counter);
      }
    } else {
      setCurrentCounters([]);
      setActiveCounter(null);
    }
  }, [selectedPortfolio]);

  useEffect(() => {
    if (currentCounters.length > 0) {
      setSelectOptions(
        currentCounters.map((c) => {
          return { value: c.counter, label: `${c.stockName} (${c.counter})` };
        }),
      );
    } else {
      setSelectOptions([]);
    }
  }, [currentCounters]);

  useEffect(() => {
    if (activeCounter) {
      dispatch(
        fetchData({
          counter: activeCounter,
          cancelToken: source.token,
          type: 'valuation',
        }),
      );
    }
  }, [activeCounter]);

  const handleActiveCounterChange = (newCounter) => {
    if (newCounter && newCounter.value !== activeCounter) {
      setActiveCounter(newCounter.value);
    }
  };

  return (
    <Card className="position-static  h-100">
      <Card.Body className="g-min-height-555">
        <div className="g-mb-20 d-flex flex-column flex-xl-row justify-content-between">
          <Card.Title>Intrinsic Value Analysis</Card.Title>
          {selectOptions.length > 0 && (
            <Select
              unstyled
              isClearable
              backspaceRemovesValue
              className="react-select-container d-inline-block g-width-275 g-max-per-width-100"
              classNamePrefix="react-select"
              defaultValue={{
                value: activeCounter || selectOptions[0].value,
                label:
                  currentCounters.filter((c) => c.value === activeCounter)[0]?.stockName ||
                  selectOptions[0].label,
              }}
              onChange={handleActiveCounterChange}
              options={selectOptions}
            />
          )}
        </div>
        {loading && (
          <div className="g-text-size-18 g-lg-text-size-30 text-center">
            <i className="fa-duotone fa-spinner-third fa-spin" />
          </div>
        )}
        <APIErrorHandler error={error} />
        {data && !loading && (
          <div className="g-mt-15 g-mb-15 g-height-350 g-sm-height-450">
            <div className="d-flex gap-3 flex-wrap justify-content-between g-mb-15">
              <CalculatorModel tab="valuation" />
              <Button
                variant="light"
                className="g-min-height-40 g-min-width-40"
                onClick={toggleCalculator}>
                <i className="fa-light fa-gear" />
              </Button>
            </div>
            <div className={showCalculator ? 'd-none' : 'd-block h-100'}>
              <ValuationChart
                lastDone={data.last_done}
                intrinsicValue={intrinsicValue}
                classes="g-height-400 g-max-height-400"
              />
            </div>
            <div className={showCalculator ? 'd-block h-100' : 'd-none'}>
              <IntrinsicAnalysisCalculator
                tab="valuation"
                hideFieldInfo
                callbackOnClick={toggleCalculator}
              />
            </div>
          </div>
        )}
      </Card.Body>
    </Card>
  );
}
