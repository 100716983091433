import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import SearchQuote from '../../../../components/SearchQuote';
import { addToInstruments, removeFromInstruments } from './portfolioInstrumentsSlice';

const { CancelToken } = axios;

function AddToInstruments({ loading }) {
  const [showModal, setShowModal] = useState(false);

  const handleAddToInstruments = () => {
    setShowModal(true);
  };

  return (
    <>
      <Button className="btn" variant="light" onClick={handleAddToInstruments} disabled={loading}>
        <i className="fa-light fa-circle-plus" /> Add Instrument
      </Button>
      <AddToInstrumentsModal show={showModal} setShow={setShowModal} />
    </>
  );
}

AddToInstruments.propTypes = {
  loading: PropTypes.bool,
};

export default AddToInstruments;

function AddToInstrumentsModal({ show, setShow }) {
  const dispatch = useDispatch();
  const source = CancelToken.source();
  const selectedPortfolio = useSelector((state) => state.portfolio.selected);
  const counters =
    selectedPortfolio && selectedPortfolio.counters
      ? selectedPortfolio.counters.map((counter) => counter.counter)
      : [];

  const handleClose = () => {
    setShow(false);
  };

  const handleAddOrRemoveFromInstruments = (counterData) => {
    handleClose();

    const params = {
      id: selectedPortfolio.id,
      counter: counterData.symbol,
      selectedLayout: null,
      cancelToken: source.token,
    };

    if (counterData.checkbox === true && !counters.includes(counterData.symbol)) {
      dispatch(addToInstruments(params));
    } else if (counterData.checkbox === false && counters.includes(counterData.symbol)) {
      dispatch(removeFromInstruments(params));
    }
  };

  return (
    <Modal
      show={show}
      centered
      dialogClassName="g-text-size-14 addToWatchlistModal addStockModal"
      onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add to Instruments</Modal.Title>
      </Modal.Header>

      <SearchQuote
        placeholder="Search to add stocks to your portfolio"
        type="watchlist"
        handleActiveCounterChanged={handleAddOrRemoveFromInstruments}
        checkedData={counters}
      />
    </Modal>
  );
}

AddToInstrumentsModal.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
};
