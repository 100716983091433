import axios from 'axios';
import md5 from 'md5';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import api from '../../utils/api';
import { getParamCounterId, isNegative, isPositive, isZero } from '../../utils/utils';
import APIErrorHandler from '../../components/APIErrorHandler';
import Loader from '../../components/Loader';
import { NavComponent } from '../../components/PageLocalNav';
import CompanyLogoImage from '../../components/CompanyLogoImage';
import classes from './optimizedInvestmentSignals.module.css';

const { CancelToken } = axios;

const MAX_RESULTS_COUNT = 5;
const TABS = [
  {
    key: 'lowestPER',
    label: 'Lowest PER',
    // check: /prices/stock_prices?market=bursa&tab=counters&page=1&filter=FU01&type=fundamental&layout=custom
    filter: 'FU01',
    extra_fields: [
      { key: 'pe', label: 'PE' },
      { key: 'trailing_pe', label: 'Trailing PE' },
      { key: 'eps', label: 'EPS' },
    ],
  },
  {
    key: 'highestDivPayout',
    label: 'Highest Dividend Payout',
    // check: /prices/stock_prices?market=bursa&tab=counters&page=1&filter=FU15&type=fundamental&layout=custom
    filter: 'FU15',
    extra_fields: [
      { key: 'dividend_payout_ratio', label: 'Dividend Payout Ratio' },
      { key: 'dividend_yield', label: 'Dividend Yield (%)' },
    ],
  },
  {
    key: 'highestRevCAGR',
    label: 'Highest Revenue CAGR',
    // check: /prices/stock_prices?market=bursa&tab=counters&page=1&filter=FU20&type=fundamental&layout=custom
    filter: 'FU20',
    extra_fields: [
      { key: 'revenue_growth', label: 'Revenue Growth (%)' },
      { key: 'net_earnings_growth', label: 'Net Earnings Growth (%)' },
    ],
  },
  {
    key: 'highestShortTermBeta',
    label: 'Highest Short Term Beta',
    // check: /prices/stock_prices?market=bursa&tab=counters&page=1&filter=FU19&type=fundamental&layout=custom
    filter: 'FU19',
    extra_fields: [
      { key: 'buy_vol', label: 'Buy Volume' },
      { key: 'sell_vol', label: 'Sell Volume' },
    ],
  },
  {
    key: 'mostLongSignals',
    label: 'Most Long Signals',
    signal: 'long',
    extra_fields: [{ key: 'volume', label: 'Volume' }],
  },
  {
    key: 'mostShortSignals',
    label: 'Most Short Signals',
    signal: 'short',
    extra_fields: [{ key: 'volume', label: 'Volume' }],
  },
];

export default function OptimizedInvestmentSignals({ country, tab }) {
  const source = CancelToken.source();
  const market = country?.toUpperCase().trim() === 'MY' ? 'bursa' : 'sgx';
  const [isSSR, setIsSSR] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState();
  const [screenResults, setScreenResults] = useState();
  const [currentTabKey, setCurrentTabKey] = useState('');

  useEffect(() => {
    setIsSSR(typeof document === 'undefined');
    const initTabKey = TABS.map((t) => t.key).includes(tab) ? tab : 'lowestPER';
    setCurrentTabKey(initTabKey);
  }, []);

  useEffect(() => {
    if (isSSR) {
      return;
    }
    const cancelToken = source.token;
    const currentTab = TABS.filter((t) => t.key === currentTabKey)[0];
    let url = `/screener/home_predefined_ta_screens.json?market=${market}&limit=${MAX_RESULTS_COUNT}`;
    if (currentTab?.filter) {
      url += `&filter=${currentTab.filter}`;
    }
    if (currentTab?.signal) {
      url += `&signal=${currentTab.signal}`;
    }
    setIsLoading(true);
    setError(null);
    api
      .get(url, {
        cancelToken,
      })
      .then(({ data }) => {
        if (data?.success) {
          setScreenResults(data.stock_info);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  }, [currentTabKey]);

  const onTabSelectCallback = (event, selectedTabKey) => {
    event.preventDefault();
    if (
      !(
        selectedTabKey &&
        TABS.map((t) => t.key).includes(selectedTabKey) &&
        selectedTabKey !== currentTabKey
      )
    ) {
      return;
    }
    setCurrentTabKey(selectedTabKey);
  };

  const renderComponent = (tabKey, data) => {
    const extraFields = TABS.filter((t) => t.key === tabKey)[0]?.extra_fields;
    return (
      <div className="row rowXscrollable">
        {!(data?.length > 0) && (
          <span className="text-center align-middle">No available data.</span>
        )}
        {data?.length > 0 &&
          data.map((stock, index) => {
            if (index >= MAX_RESULTS_COUNT) {
              return null;
            }
            return (
              <div className="col-auto p-3" key={`${stock.counter}_${index}`}>
                <StockCard stock={stock} extraFields={extraFields} />
              </div>
            );
          })}
      </div>
    );
  };

  return (
    <div id="homeOptimizedInvestmentSignals" className="g-min-height-600">
      <h3 className="g-mb-30">Optimized Investment Signals</h3>
      {(isSSR || isLoading) && <Loader visible className="g-height-600" />}
      {error && <APIErrorHandler error={error} />}
      {!(isSSR || isLoading) && !error && (
        <NavComponent
          navLinks={TABS}
          tabClasses="rowXscrollable g-mb-10 g-pb-10"
          activeNav={currentTabKey}
          content={renderComponent(currentTabKey, screenResults)}
          handleNavClick={onTabSelectCallback}
        />
      )}
    </div>
  );
}

function StockCard({ stock, extraFields }) {
  const miniChartBaseUrl = '//chart.shareinvestor.com/charts_cached/charts.pl';
  const changeClass = isNegative(stock.change)
    ? 'text-danger'
    : isPositive(stock.change)
    ? 'text-success'
    : '';
  return (
    <div className="card g-width-300 g-height-400">
      <div className="card-body g-ml-5 g-mr-5">
        {/* Logo and Company Name */}
        <div className="row g-mb-10">
          <a href={`/quote/${getParamCounterId(stock.counter)}`}>
            <div className="d-flex">
              <div className="g-width-45 g-height-45 g-pt-6 g-pr-6 g-pb-6 g-pl-6">
                <CompanyLogoImage counter={stock.counter} />
              </div>
              <div className="g-ml-10">
                <div className="g-text-size-20 g-lg-text-size-24 g-fw-500">{stock.name}</div>
                <div className="g-text-size-14 text-muted">{stock.code}</div>
              </div>
            </div>
          </a>
        </div>
        {/* Price and Minichart */}
        <div className="row text-start align-middle g-mt-15 g-height-100">
          <div className="col-7 g-pr-0">
            {/* last_done_price */}
            <div
              id={`sic_${stock.counter}_lastDone`}
              className="g-text-size-20 g-lg-text-size-24 fw-bold">
              {!isZero(stock.price) ? stock.price : '-'}
            </div>
            {/* change and perc_change */}
            <div id={`sic_${stock.counter}_change`} className={`g-text-size-15 ${changeClass}`}>
              <span id={`sic_${stock.counter}_changeValue`}>
                {!isZero(stock.change) ? stock.change : '-'}
              </span>
              <span id={`sic_${stock.counter}_percentageChange`}>
                {!isZero(stock.perc_change)
                  ? ` (${stock.perc_change}%)`
                  : isNegative(stock.change)
                  ? ' (-0.00%)'
                  : isPositive(stock.change)
                  ? ' (+0.00%)'
                  : ''}
              </span>
            </div>
          </div>
          <div className="col-5 g-pl-0">
            <img
              id={`sic_${stock.counter}_indexChart`}
              className="g-height-80 img-fluid"
              src={`${miniChartBaseUrl}?type=si_stock_historical_mini_plain&id=${stock.counter}`}
              alt="Chart"
            />
            <span className={`${classes.historical_data_tooltip} hover_popup_container`}>
              <i className="fa-solid fa-clock-rotate-left fa-sm text-muted" />
              <div
                className={`${classes.historical_data_tooltip_content} hover_popup_content g-border-radius-10 border p-1`}>
                <div className="hover_popup_content_inner g-text-size-13">Historical Data (1Y)</div>
              </div>
            </span>
          </div>
        </div>
        {/* Extra Fields */}
        <div className="d-fex flex-column align-middle g-mt-15">
          {extraFields &&
            extraFields.map((field) => {
              return (
                <div
                  key={`${stock.counter}_${field.key}`}
                  className="g-border-1 g-border-b-1 text-start align-middle p-1">
                  <div className="g-text-size-14">{field.label}</div>
                  <div className="g-text-size-16 g-lg-text-size-20 g-fw-500">
                    {stock[field.key] || '-'}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

OptimizedInvestmentSignals.propTypes = {
  country: PropTypes.string,
  tab: PropTypes.string,
};
