import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';

import axios from 'axios';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import MarketSelectDropdown from '../../../components/MarketSelectDropdown';
import { createLoadableComponent } from '../../../utils/Loadable';
import Pagination from '../../../components/Pagination';
import Loader from '../../../components/Loader';
import APIErrorHandler from '../../../components/APIErrorHandler';
import LargestValueTraded from '../../../components/InsiderTrades/LargestValueTraded';
import InsiderTradesTable from '../../../components/InsiderTrades/InsiderTradesTable';
import FilterBuyerSeller from '../../../components/InsiderTrades/FilterBuyerSeller';
import FilterTradeTypes from '../../../components/InsiderTrades/FilterTradeTypes';

import Notes from '../../../components/InsiderTrades/Notes';
import { fetchDataMarkets } from '../../../components/InsiderTrades/insiderTradesSlice';
import SelectCounter from '../../../components/SelectCounter';

const DateFilter = createLoadableComponent(() => import('../../../components/DateFilter'));

const { CancelToken } = axios;
const TYPES = [
  {
    key: 'insider_dir_ceo',
    name: 'Director/Chief Executive Officer',
    value: 'DIR/CEO',
  },
  {
    key: 'insider_tm_rp',
    name: 'Trustee-Manager/Responsible Person',
    value: 'TM/RP',
  },
  {
    key: 'insider_ssh',
    name: 'Substantial Shareholder/Unitholder',
    value: 'SSH',
  },
  {
    key: 'insider_coy',
    name: 'Company Share Buyback',
    value: 'COY',
  },
];

export default function InsiderTradesMain({ counter, market, availableMarkets }) {
  const dispatch = useDispatch();
  const source = CancelToken.source();
  const [isSSR, setIsSSR] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const data = useSelector((state) => state.insiderTrades.data);
  const completed = useSelector((state) => state.insiderTrades.completed);
  const error = useSelector((state) => state.insiderTrades.err);
  const loading = useSelector((state) => state.insiderTrades.loading);
  const pagination = useSelector((state) => state.insiderTrades.pagination);
  const defaultMarket = useSelector((state) => state.selectedMarket.selectedMarket);
  const [selectedMarket, setSelectedMarket] = useState(market || defaultMarket);
  const [selectedCounter, setSelectedCounter] = useState(counter);
  const defaultTradeTypes = searchParams.get('search_trade_types')
    ? searchParams.get('search_trade_types').split(',')
    : TYPES.filter((type) => type.key !== 'insider_coy').map((type) => type.key);
  // This is reformatted tradeType specifically for their checkboxes as they cannot read the searchParams.get('search_trade_types') directly
  const [checkboxTradeTypes, setCheckboxTradeTypes] = useState({
    insider_dir_ceo: defaultTradeTypes.includes('insider_dir_ceo'),
    insider_tm_rp: defaultTradeTypes.includes('insider_tm_rp'),
    insider_ssh: defaultTradeTypes.includes('insider_ssh'),
  });
  const [tradeTypes, setTradeTypes] = useState(
    searchParams.get('search_trade_types') ||
      TYPES.filter((type) => type.key !== 'insider_coy')
        .map((type) => type.key)
        .join(','),
  );
  const [tradeTypesAlert, setTradeTypesAlert] = useState(false);
  const [buyerSeller, setBuyerSeller] = useState(searchParams.get('search_buyer_seller') || '');
  const [fromDate, setFromDate] = useState(searchParams.get('search_start_date') || '');
  const [toDate, setToDate] = useState(searchParams.get('search_end_date') || '');
  const [page, setPage] = useState(searchParams.get('page') || '1');

  const getCurrentParams = () => {
    const params = {};

    if (selectedCounter?.code) {
      params.counter = selectedCounter.code;
    }
    if (fromDate) {
      params.search_start_date = fromDate;
    }
    if (toDate) {
      params.search_end_date = toDate;
    }
    if (tradeTypes) {
      params.search_trade_types = tradeTypes;
    }
    if (buyerSeller) {
      params.search_buyer_seller = buyerSeller;
    }
    if (page) {
      params.page = page;
    }

    return params;
  };

  const resetPage = () => {
    setPage('1');
    if (searchParams.get('page')) {
      searchParams.delete('page');
    }
  };

  const handleCounterChange = (counterData) => {
    const newCounter = counterData ? { name: counterData.label, code: counterData.value } : {};
    if (selectedCounter?.code === newCounter?.code) {
      return;
    }

    if (newCounter?.code) {
      searchParams.set('counter', newCounter.code);
    } else if (searchParams.has('counter')) {
      searchParams.delete('counter');
    }
    resetPage();
    setSearchParams(searchParams);
    setSelectedCounter(newCounter);
  };

  const handleOnChangeFromDate = (_newDate) => {
    if (!_newDate) {
      return;
    }

    let newDate = '';
    if (_newDate.length > 0) {
      newDate = dayjs(new Date(_newDate)).format('YYYY-MM-DD');
    }

    if (fromDate === newDate) {
      return;
    }

    resetPage();
    setSearchParams({ ...getCurrentParams(), search_start_date: newDate });
    setFromDate(newDate);
  };

  const handleOnChangeToDate = (_newDate) => {
    if (!_newDate) {
      return;
    }

    let newDate = '';
    if (_newDate.length > 0) {
      newDate = dayjs(new Date(_newDate)).format('YYYY-MM-DD');
    }

    if (toDate === newDate) {
      return;
    }
    resetPage();
    setSearchParams({ ...getCurrentParams(), search_end_date: newDate });
    setToDate(newDate);
  };

  const handleDateReset = () => {
    setToDate('');
    setFromDate('');
    searchParams.delete('search_start_date');
    searchParams.delete('search_end_date');
    resetPage();
    setSearchParams(searchParams);
  };

  const handleCheckboxTradeTypesChange = (event) => {
    if (!event) {
      return;
    }

    const { name, checked } = event.target;
    const newTradeTypes = {
      ...checkboxTradeTypes,
      [name]: checked,
    };

    // Check if at least one checkbox is selected
    const atLeastOneSelected = Object.values(newTradeTypes).some((value) => value);

    // If no checkbox is selected, prevent unchecking the current checkbox
    if (!atLeastOneSelected && !checked) {
      setTradeTypesAlert(true);
      return;
    }

    const selectedTradeTypes = Object.keys(newTradeTypes)
      .filter((key) => newTradeTypes[key])
      .join(',');

    if (tradeTypes === newTradeTypes) {
      return;
    }

    resetPage();
    setSearchParams({ ...getCurrentParams(), search_trade_types: selectedTradeTypes });
    setTradeTypes(selectedTradeTypes);
    setTradeTypesAlert(false);
  };

  const handleBuyerSellerChange = (event) => {
    if (!event) {
      return;
    }
    const newBuyerSeller = event.target.value;

    if (buyerSeller === newBuyerSeller) {
      return;
    }

    resetPage();
    setSearchParams({ ...getCurrentParams(), search_buyer_seller: newBuyerSeller });
    setBuyerSeller(newBuyerSeller || '');
  };

  const handlePageChange = (newPage) => {
    if (newPage && page !== newPage.toString()) {
      setSearchParams({ ...getCurrentParams(), page: newPage });
      setPage(newPage.toString());
    }
  };

  // Reset all params from browser url
  const resetSearchParams = () => {
    setSelectedCounter({});
    setBuyerSeller('');
    setFromDate('');
    setToDate('');
    setCheckboxTradeTypes({
      insider_dir_ceo: defaultTradeTypes.includes('insider_dir_ceo'),
      insider_tm_rp: defaultTradeTypes.includes('insider_tm_rp'),
      insider_ssh: defaultTradeTypes.includes('insider_ssh'),
    });
    setTradeTypes(
      TYPES.filter((type) => type.key !== 'insider_coy')
        .map((type) => type.key)
        .join(','),
    );
    resetPage();

    if (searchParams.has('counter')) {
      searchParams.delete('counter');
    }
    if (searchParams.has('search_buyer_seller')) {
      searchParams.delete('search_buyer_seller');
    }
    if (searchParams.has('search_start_date')) {
      searchParams.delete('search_start_date');
    }
    if (searchParams.has('search_end_date')) {
      searchParams.delete('search_end_date');
    }
    if (searchParams.has('search_trade_types')) {
      searchParams.delete('search_trade_types');
    }
    setSearchParams(searchParams);
  };

  // update selectedMarket state when selectedMarket redux has changed.
  useEffect(() => {
    if (defaultMarket == null || defaultMarket === selectedMarket.toLowerCase()) {
      return;
    }

    setSelectedMarket(defaultMarket);
    resetSearchParams();
    navigate(`/${defaultMarket.toUpperCase()}/insider_trades`);
  }, [defaultMarket]);

  // delay fetchData because onChanges buyerSeller can trigger the fetchData immediately
  useEffect(() => {
    if (!selectedMarket) {
      return;
    }

    const delayDebounceFn = setTimeout(() => {
      const filters = {
        market: selectedMarket,
        counter: selectedCounter?.code ? selectedCounter.code : '',
        buyerSeller,
        fromDate,
        toDate,
        tradeTypes,
        page,
      };

      dispatch(fetchDataMarkets({ filters, cancelToken: source.token }));
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [selectedMarket, selectedCounter, buyerSeller, fromDate, toDate, tradeTypes, page]);

  useEffect(() => {
    setCheckboxTradeTypes({
      insider_dir_ceo: tradeTypes.includes('insider_dir_ceo'),
      insider_tm_rp: tradeTypes.includes('insider_tm_rp'),
      insider_ssh: tradeTypes.includes('insider_ssh'),
    });
  }, [tradeTypes]);

  useEffect(() => {
    const paramCounter = searchParams.get('counter') || '';
    const paramFromDate = searchParams.get('search_start_date') || '';
    const paramEndDate = searchParams.get('search_end_date') || '';
    const paramTradeTypes =
      searchParams.get('search_trade_types') ||
      TYPES.filter((type) => type.key !== 'insider_coy')
        .map((type) => type.key)
        .join(',');
    const paramBuyerSeller = searchParams.get('search_buyer_seller') || '';
    const paramPage = searchParams.get('page') || '1';

    if (selectedCounter?.code !== paramCounter) {
      setSelectedCounter(paramCounter ? { code: paramCounter } : {});
    }
    if (fromDate !== paramFromDate) {
      setFromDate(paramFromDate);
    }
    if (toDate !== paramEndDate) {
      setToDate(paramEndDate);
    }
    if (tradeTypes !== paramTradeTypes) {
      setTradeTypes(paramTradeTypes);
    }
    if (buyerSeller !== paramBuyerSeller) {
      setBuyerSeller(paramBuyerSeller);
    }
    if (page !== paramPage) {
      setPage(paramPage);
    }
  }, [location]);

  useEffect(() => {
    setIsSSR(typeof document === 'undefined');
  }, []);

  if (isSSR) {
    return <Loader visible={isSSR} classes="g-height-800" />;
  }

  if (error) {
    return <APIErrorHandler error={error} />;
  }

  return (
    <>
      <form id="searchInsiderTrades" className="row g-2 align-items-center g-mt-30 g-mb-50">
        <div className="col-auto">
          <MarketSelectDropdown
            market={selectedMarket}
            variant="light"
            validMarkets={availableMarkets}
            updateUrlOnClick
          />
        </div>
        <div className="col-auto">
          <SelectCounter
            counter={selectedCounter}
            market={[selectedMarket]}
            handleOnChangeCounter={handleCounterChange}
          />
        </div>
        <div className="col-auto">
          <FilterBuyerSeller
            buyerSeller={buyerSeller}
            handleBuyerSellerChange={handleBuyerSellerChange}
          />
        </div>
        <div className="col-12 col-md-auto">
          <DateFilter
            isInterval
            fromDate={fromDate}
            toDate={toDate}
            handleFromDateChange={handleOnChangeFromDate}
            handleToDateChange={handleOnChangeToDate}
            handleReset={handleDateReset}
          />
        </div>
        <div className="col-12 col-lg-auto">
          <FilterTradeTypes
            checkboxTradeTypes={checkboxTradeTypes}
            handleCheckboxTradeTypesChange={handleCheckboxTradeTypesChange}
            showAlert={tradeTypesAlert}
          />
        </div>
      </form>

      {loading && <Loader visible={loading} classes="g-height-800" />}
      {!loading && data && data.insider_data && data.insider_data.length > 0 && (
        <>
          <h4 className="g-mb-30">Latest Insider Trades</h4>
          <div className="g-mb-30">
            <InsiderTradesTable
              data={data.insider_data}
              classificationTypes={TYPES}
              currency={data.currency}
            />
            <Pagination
              rowOffset={pagination.page_row_offset}
              currentPageRows={data.insider_data.length}
              totalRows={pagination.total_rows}
              currentPage={Number(page)}
              totalPages={pagination.total_pages}
              handlePageChange={handlePageChange}
            />
          </div>
          <Notes />
        </>
      )}
      {!loading &&
        (Object.keys(data).length === 0 ||
          (data.insider_data && data.insider_data.length === 0)) && (
          <div className="text-center">No data available.</div>
        )}
    </>
  );
}

InsiderTradesMain.propTypes = {
  counter: PropTypes.oneOfType([PropTypes.object]),
  market: PropTypes.string,
  availableMarkets: PropTypes.oneOfType([PropTypes.array]),
};
