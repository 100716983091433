import React from 'react';

import banner from 'images/img-loyalty-program.png';
import mobileBanner from 'images/img-loyalty-program-mobile.png';
import list from 'images/img-loyalty-program-subsciption-years.png';

export default function LoyaltyProgram() {
  return (
    <>
      <div id="loyaltyProgram" />
      <div className="g-mt-80">
        <div className="row g-mb-40">
          <div className="col-12 text-left">
            <h2>Loyalty Program</h2>
          </div>
        </div>
        <div className="row g-mb-40">
          <div className="col-12">
            <div className="text-center g-mb-40 d-none d-sm-block">
              <img
                src={banner}
                title="Premium Loyalty Discount: The Longer You Stay The Lesser You Pay"
                alt="Premium Loyalty Discount: The Longer You Stay The Lesser You Pay"
                className="g-max-width-1288 w-100"
              />
            </div>
            <div className="text-center g-mb-40 d-sm-none">
              <img
                src={mobileBanner}
                title="Premium Loyalty Discount: The Longer You Stay The Lesser You Pay"
                alt="Premium Loyalty Discount: The Longer You Stay The Lesser You Pay"
                className="g-max-width-430 w-100"
              />
            </div>
            <p>
              This Loyalty Program is based on your continuous subscription to ShareInvestor
              WebPro/Station memberships. For a renewal at the end of the first year, members will
              receive a discount of 8%. By maintaining continuity of membership, members will be
              able to enjoy higher discounts in accordance with the table below. A member with more
              than 15 years of continuous membership will enjoy the maximum renewal discount of 18%.
            </p>

            <div className="text-center g-mt-40 g-mb-40">
              <img
                src={list}
                title="Premium Loyalty Discount: The Longer You Stay The Lesser You Pay"
                alt="Premium Loyalty Discount: The Longer You Stay The Lesser You Pay"
                className="g-max-width-870 w-100"
              />
            </div>

            <p>
              Members' years of continuous subscription will be monitored automatically by
              ShareInvestor. During renewal, ShareInvestor will indicate the member’s discount
              entitlement on the subscription page after signing in. The benefit to members is that
              they can now renew close to their membership expiry date and do not have to wait for
              promotions and commit to renewal well in advance.
            </p>

            <div className="text-muted">
              <h6>Terms &amp; Conditions:</h6>
              <ol>
                <li>
                  The quantum of the Premium Loyalty Discount ("Discount") is linked to the number
                  of years of continuous membership as shown in the diagram above.
                </li>
                <li>
                  Continuous membership is defined as membership that is renewed before every expiry
                  date, with a grace period of up to 14 calendar days for late renewals.
                </li>
                <li>
                  This Discount cannot be used in conjunction with other membership promotions
                  unless otherwise stated.
                </li>
                <li>
                  The Discount is not applicable to any membership upgrade before expiry of the
                  membership.
                </li>
                <li>
                  Subscriber can continue to enjoy the Discount at the next renewal after the
                  upgrade.{' '}
                </li>
                <li>
                  The Discount is not transferable to another User ID including the case of a change
                  of User ID by the same member.
                </li>
                <li>The Discount is applicable to Platform Fee only.</li>
                <li>
                  Persons who use ShareInvestor by subscribing through other channels (e.g.
                  ValueXpert) or bundling with other media subscriptions / parties are not entitled
                  to the Discount.{' '}
                </li>
                <li>
                  ShareInvestor’s decision on all matters relating to the Loyalty Program is final
                  and binding on all subscribers.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
