import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';

import Header from '../Header';
import PlanSelection from './PlanSelection';
import PlansComparison from './PlansComparison';
import LoyaltyProgram from './LoyaltyProgram';
import FAQ from './FAQ';
import UserReviews from './UserReviews';

function MembershipSelectPlan({ country, checkCaptcha, siteKey }) {
  useEffect(() => {
    const { hash } = window.location;

    const scrollToAnchor = () => {
      if (!hash) {
        return;
      }

      const element = document.getElementById(hash.substring(1));
      if (element) {
        window.scrollTo({
          top: element.offsetTop,
          behavior: 'smooth',
        });
      }
    };

    setTimeout(scrollToAnchor, 500);
  }, []);

  return (
    <>
      <Header subtitle="It's S$0 now. Set up payments for later." />
      <Container>
        <PlanSelection country={country} checkCaptcha={checkCaptcha} siteKey={siteKey} />
        <PlansComparison country={country} />
        {country !== 'my' && <LoyaltyProgram />}
        <FAQ country={country} />
      </Container>
      <UserReviews country={country} />
    </>
  );
}

export default MembershipSelectPlan;

MembershipSelectPlan.propTypes = {
  country: PropTypes.string,
  checkCaptcha: PropTypes.bool,
  siteKey: PropTypes.string,
};
