import { createSlice } from '@reduxjs/toolkit';
import api from '../../utils/api';

const priceTableSlice = createSlice({
  name: 'priceTable',
  initialState: {
    pricesSubtitle: null,
    selectedstocks: [],
  },
  reducers: {
    pricesSubtitleFetchStart(state, action) {
      state.pricesSubtitle = null;
    },
    pricesSubtitleFetchSuccess(state, action) {
      state.pricesSubtitle = action.payload.pricesSubtitle;
    },
    pricesSubtitleFetchFail(state, action) {
      // do nothing on error for now
    },
    pricesAddSelectedStocks(state, action) {
      state.selectedstocks.push(action.payload);
    },
    pricesRemoveSelectedStocks(state, action) {
      const selectedStock = state.selectedstocks.filter(stock => stock.code != action.payload);
      state.selectedstocks = selectedStock;
    },
    pricesClearAllSelectedStocks(state, action) {
      state.selectedstocks.length = 0;
    }
  },
});

export default priceTableSlice.reducer;

const { pricesSubtitleFetchStart, pricesSubtitleFetchSuccess, pricesSubtitleFetchFail } =
  priceTableSlice.actions;

export const { pricesAddSelectedStocks, pricesRemoveSelectedStocks, pricesClearAllSelectedStocks } = priceTableSlice.actions;

export const fetchPricesSubtitle =
  ({ filter, market, cancelToken }) =>
    (dispatch) => {
      const params = [];

      if (filter) {
        params.push(`filter=${filter}`);
      }
      if (market) {
        params.push(`market=${market}`);
      }

      dispatch(pricesSubtitleFetchStart());
      api
        .get(`prices/get_price_subtitle.json?${params.join('&')}`, { cancelToken })
        .then(({ data }) => {
          dispatch(pricesSubtitleFetchSuccess({ pricesSubtitle: data }));
        })
        .catch((error) => {
          dispatch(pricesSubtitleFetchFail(error));
        });
    };
