import axios from 'axios';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import WorldIndicesList from './WorldIndicesList';
import { fetchData } from './worldIndicesPricesSlice';

const { CancelToken } = axios;

export default function WorldIndicesPrices({ children, defaultIndices }) {
  const dispatch = useDispatch();
  const source = CancelToken.source();
  const data = useSelector((state) => state.worldIndicesPrices.data);
  const completed = useSelector((state) => state.worldIndicesPrices.completed);
  const error = useSelector((state) => state.worldIndicesPrices.err);

  useEffect(() => {
    dispatch(fetchData({ cancelToken: source.token }));
  }, []);

  if (error) {
    console.log('Error detected: ', error);
  }

  return (
    <WorldIndicesList data={data.world_indices_prices || defaultIndices}>
      {children}
    </WorldIndicesList>
  );
}

WorldIndicesPrices.propTypes = {
  children: PropTypes.object,
  defaultIndices: PropTypes.array,
};
