import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import UserLoginComponent from './UserLoginComponent';

function UserLogin({
  redirect = '',
  site_key = '',
  perform_check = true,
  error = '',
  showForgetPasswordPage = false,
}) {
  return (
    <Container className="mt-4 mb-4">
      <Row className="justify-content-center">
        <Col sm={12} xxl={4} lg={8}>
          <UserLoginComponent
            redirect={redirect}
            site_key={site_key}
            perform_check={perform_check}
            error={error}
            showForgetPasswordPage={showForgetPasswordPage}
          />
        </Col>
      </Row>
    </Container>
  );
}

UserLogin.propTypes = {
  redirect: PropTypes.string,
  site_key: PropTypes.string,
  perform_check: PropTypes.bool,
  error: PropTypes.string,
  showForgetPasswordPage: PropTypes.bool,
};

export default UserLogin;
