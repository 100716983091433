import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { useSearchParams, useLocation } from 'react-router-dom';

import { createLoadableComponent } from '../../../utils/Loadable';
import Pagination from '../../../components/Pagination';
import Loader from '../../../components/Loader';
import APIErrorHandler from '../../../components/APIErrorHandler';
import { fetchDataFactsheets } from '../../../components/InsiderTrades/insiderTradesSlice';

import InsiderTradesTable from '../../../components/InsiderTrades/InsiderTradesTable';
import FilterBuyerSeller from '../../../components/InsiderTrades/FilterBuyerSeller';
import FilterTradeTypes from '../../../components/InsiderTrades/FilterTradeTypes';
import Notes from '../../../components/InsiderTrades/Notes';

const DateFilter = createLoadableComponent(() => import('../../../components/DateFilter'));

const { CancelToken } = axios;
const TYPES = [
  {
    key: 'insider_dir_ceo',
    name: 'Director/Chief Executive Officer',
    value: 'DIR/CEO',
  },
  {
    key: 'insider_tm_rp',
    name: 'Trustee-Manager/Responsible Person',
    value: 'TM/RP',
  },
  {
    key: 'insider_ssh',
    name: 'Substantial Shareholder/Unitholder',
    value: 'SSH',
  },
  {
    key: 'insider_coy',
    name: 'Company Share Buyback',
    value: 'COY',
  },
];

export default function InsiderTrades({ counter, tab, toggleFactsheetNav }) {
  const dispatch = useDispatch();
  const source = CancelToken.source();
  const [isSSR, setIsSSR] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const data = useSelector((state) => state.insiderTrades.data);
  const completed = useSelector((state) => state.insiderTrades.completed);
  const error = useSelector((state) => state.insiderTrades.err);
  const loading = useSelector((state) => state.insiderTrades.loading);
  const pagination = useSelector((state) => state.insiderTrades.pagination);
  const [page, setPage] = useState(searchParams.get('page') || '1');
  const defaultTradeTypes = searchParams.get('search_trade_types')
    ? searchParams.get('search_trade_types').split(',')
    : TYPES.filter((type) => type.key !== 'insider_coy').map((type) => type.key);
  // This is reformatted tradeType specifically for their checkboxes as they cannot read the searchProps.tradeTypes directly
  const [checkboxTradeTypes, setCheckboxTradeTypes] = useState({
    insider_dir_ceo: defaultTradeTypes.includes('insider_dir_ceo'),
    insider_tm_rp: defaultTradeTypes.includes('insider_tm_rp'),
    insider_ssh: defaultTradeTypes.includes('insider_ssh'),
  });
  const [tradeTypes, setTradeTypes] = useState(
    searchParams.get('search_trade_types') ||
      TYPES.filter((type) => type.key !== 'insider_coy')
        .map((type) => type.key)
        .join(','),
  );
  const [tradeTypesAlert, setTradeTypesAlert] = useState(false);
  const [buyerSeller, setBuyerSeller] = useState(searchParams.get('search_buyer_seller') || '');
  const [fromDate, setFromDate] = useState(searchParams.get('search_start_date') || '');
  const [toDate, setToDate] = useState(searchParams.get('search_end_date') || '');

  const getCurrentParams = () => {
    const params = {};

    if (tab !== 'insider_search') {
      return params;
    }
    if (fromDate) {
      params.search_start_date = fromDate;
    }
    if (toDate) {
      params.search_end_date = toDate;
    }
    if (tradeTypes) {
      params.search_trade_types = tradeTypes;
    }
    if (buyerSeller) {
      params.search_buyer_seller = buyerSeller;
    }
    if (page) {
      params.page = page;
    }

    return params;
  };

  const handleOnChangeFromDate = (_newDate) => {
    if (!_newDate) {
      return;
    }

    let newDate = '';
    if (_newDate.length > 0) {
      newDate = dayjs(new Date(_newDate)).format('YYYY-MM-DD');
    }

    if (fromDate === newDate) {
      return;
    }

    setFromDate(newDate);
  };

  const handleOnChangeToDate = (_newDate) => {
    if (!_newDate) {
      return;
    }

    let newDate = '';
    if (_newDate.length > 0) {
      newDate = dayjs(new Date(_newDate)).format('YYYY-MM-DD');
    }

    if (toDate === newDate) {
      return;
    }

    setToDate(newDate);
  };

  const handleDateReset = () => {
    setToDate('');
    setFromDate('');
  };

  const handleCheckboxTradeTypesChange = (event) => {
    if (!event) {
      return;
    }

    const { name, checked } = event.target;
    const newTradeTypes = {
      ...checkboxTradeTypes,
      [name]: checked,
    };

    // Check if at least one checkbox is selected
    const atLeastOneSelected = Object.values(newTradeTypes).some((value) => value);

    // If no checkbox is selected, prevent unchecking the current checkbox
    if (!atLeastOneSelected && !checked) {
      setTradeTypesAlert(true);
      return;
    }

    const selectedTradeTypes = Object.keys(newTradeTypes)
      .filter((key) => newTradeTypes[key])
      .join(',');

    if (tradeTypes === newTradeTypes) {
      return;
    }

    setTradeTypes(selectedTradeTypes);
    setTradeTypesAlert(false);
  };

  const handleBuyerSellerChange = (event) => {
    if (!event) {
      return;
    }
    const newBuyerSeller = event.target.value;

    if (buyerSeller === newBuyerSeller) {
      return;
    }

    setBuyerSeller(newBuyerSeller || '');
  };

  const handlePageChange = (newPage) => {
    if (newPage && page !== newPage.toString()) {
      setSearchParams({ ...getCurrentParams(), page: newPage });
      setPage(newPage.toString());
    }
  };

  const handleClickSearch = () => {
    const updatedParams = {
      ...getCurrentParams(),
      search_start_date: fromDate,
      search_end_date: toDate,
      search_trade_types: tradeTypes,
      search_buyer_seller: buyerSeller,
      page: '1',
    };

    // Set the updated params
    setSearchParams(updatedParams);

    const filters = {
      page,
      buyerSeller,
      fromDate,
      toDate,
      tradeTypes,
      counter,
      tab,
    };

    getData(filters);
  };

  const getData = (filters) => {
    dispatch(fetchDataFactsheets({ filters, cancelToken: source.token }));
  };

  useEffect(() => {
    setCheckboxTradeTypes({
      insider_dir_ceo: tradeTypes.includes('insider_dir_ceo'),
      insider_tm_rp: tradeTypes.includes('insider_tm_rp'),
      insider_ssh: tradeTypes.includes('insider_ssh'),
    });
  }, [tradeTypes]);

  useEffect(() => {
    const filters = {
      counter,
      tab,
      page,
      buyerSeller,
      fromDate,
      toDate,
      tradeTypes,
    };

    const delayDebounceFn = setTimeout(() => {
      getData(filters);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [tab, page]);

  useEffect(() => {
    const paramFromDate = searchParams.get('search_start_date') || '';
    const paramEndDate = searchParams.get('search_end_date') || '';
    const paramTradeTypes =
      searchParams.get('search_trade_types') ||
      TYPES.filter((type) => type.key !== 'insider_coy')
        .map((type) => type.key)
        .join(',');
    const paramBuyerSeller = searchParams.get('search_buyer_seller') || '';
    const paramPage = searchParams.get('page') || '1';

    if (fromDate !== paramFromDate) {
      setFromDate(paramFromDate);
    }
    if (toDate !== paramEndDate) {
      setToDate(paramEndDate);
    }
    if (tradeTypes !== paramTradeTypes) {
      setTradeTypes(paramTradeTypes);
    }
    if (buyerSeller !== paramBuyerSeller) {
      setBuyerSeller(paramBuyerSeller);
    }
    if (page !== paramPage) {
      setPage(paramPage);
    }
  }, [location]);

  useEffect(() => {
    setIsSSR(typeof document === 'undefined');
  }, []);

  if (isSSR || loading) {
    return <Loader visible={isSSR || loading} classes="g-height-800" />;
  }

  if (error) {
    return <APIErrorHandler error={error} />;
  }

  return (
    <>
      {tab === 'insider_search' && (
        <form id="searchInsiderTrades" className="row g-2 align-items-center g-mt-30 g-mb-30">
          <div className="col-auto">
            <FilterBuyerSeller
              buyerSeller={buyerSeller}
              handleBuyerSellerChange={handleBuyerSellerChange}
            />
          </div>
          <div className="col-12 col-md-auto">
            <DateFilter
              isInterval
              fromDate={fromDate}
              toDate={toDate}
              handleFromDateChange={handleOnChangeFromDate}
              handleToDateChange={handleOnChangeToDate}
              handleReset={handleDateReset}
            />
          </div>
          <div className="col-12 col-lg-auto">
            <div className="d-flex gap-3 flex-row align-items-center">
              <FilterTradeTypes
                checkboxTradeTypes={checkboxTradeTypes}
                handleCheckboxTradeTypesChange={handleCheckboxTradeTypesChange}
                showAlert={tradeTypesAlert}
              />
              <button
                id="search"
                className="btn btn-gray-100"
                type="submit"
                onClick={handleClickSearch}>
                Search
              </button>
            </div>
          </div>
        </form>
      )}
      {!loading &&
        data &&
        data.insider_data &&
        data.insider_data.length > 0 &&
        data.insider_data[0].counter === counter && (
          <>
            <InsiderTradesTable
              data={data.insider_data}
              classificationTypes={TYPES}
              currency={data.currency}
              toggleFactsheetNav={toggleFactsheetNav}
            />
            <Pagination
              rowOffset={pagination.page_row_offset}
              currentPageRows={data.insider_data.length}
              totalRows={pagination.total_rows}
              currentPage={Number(page)}
              totalPages={pagination.total_pages}
              handlePageChange={handlePageChange}
            />
            <Notes />
          </>
        )}
      {!loading &&
        (Object.keys(data).length === 0 ||
          (data.insider_data && data.insider_data.length === 0)) && (
          <div className="text-center">No data available.</div>
        )}
    </>
  );
}

InsiderTrades.propTypes = {
  counter: PropTypes.string,
  tab: PropTypes.string,
  toggleFactsheetNav: PropTypes.func,
};
