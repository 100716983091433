import { createSlice } from '@reduxjs/toolkit';
import api from '../../utils/api';
import { resetInstrumentsResponseAndError } from './Analytics/Positions/portfolioInstrumentsSlice';

const portfolioSlice = createSlice({
  name: 'portfolio',
  initialState: {
    loading: true,
    completed: false,
    selected: {
      id: null,
      name: '',
      currency: '',
      default: false,
      encFolder: '',
      counters: [],
      classic: false,
      totalCashFlow: 0,
    },
    lastCounterNotesUpdated: {},
    statsSummary: {},
    statsSummaryAsOf: {},
    fetchingStatsSummary: false,
    all: [],
    selectedLayout: '',
    allFields: null,
    currentCustomLayoutFields: null,
    customLayoutHeaders: null,
    performanceComparisonIndicesList: [],
    performanceChartData: {},
    relatedInsiderTrades: {},
    isLegalUser: false,
    isEodUser: true,
    countryMarket: '',
    isDuringStreamingHours: false,
    err: null,
    response: null,
  },
  reducers: {
    portfolioFetchStart(state, action) {
      state.selected = {
        id: null,
        name: '',
        currency: '',
        default: false,
        encFolder: '',
        counters: [],
        classic: false,
        totalCashFlow: 0,
      };
      state.lastCounterNotesUpdated = {};
      state.all = [];
      state.err = null;
      state.completed = false;
      state.loading = true;
    },
    portfolioFetchSuccess(state, action) {
      if (action.payload.data.selectedFolder) {
        state.selected.id = action.payload.data.selectedFolder.id;
        state.selected.name = action.payload.data.selectedFolder.name;
        state.selected.currency = action.payload.data.selectedFolder.currency;
        state.selected.default = action.payload.data.selectedFolder.default;
        state.selected.encFolder = action.payload.data.selectedFolder.encFolder;
        state.selected.classic = action.payload.data.selectedFolder.classic;
        state.selected.totalCashFlow = action.payload.data.selectedFolder.totalCashFlow;
        // update counters for selected portfolio
        if (action.payload.data.selectedFolder.counters) {
          state.selected.counters = action.payload.data.selectedFolder.counters;
        }
      }
      state.all = action.payload.data.allFolders;
      state.selectedLayout = action.payload.data.selectedLayout;
      state.performanceComparisonIndicesList = action.payload.data.performanceComparisonIndicesList;
      state.performanceChartData = action.payload.data.performanceChartData;
      state.isLegalUser = action.payload.data.isLegalUser;
      state.isEodUser = action.payload.data.isEodUser;
      state.countryMarket = action.payload.data.country_market;
      state.isDuringStreamingHours = action.payload.data.isDuringStreamingHours;
      state.completed = true;
      state.loading = false;
      if (action.payload.data.all_fields) {
        state.allFields = action.payload.data.all_fields;
      }
      if (action.payload.data.custom_layout) {
        state.currentCustomLayoutFields = action.payload.data.custom_layout;
        state.customLayoutHeaders = action.payload.data.custom_layout_headers;
      }
    },
    portfolioFetchFail(state, action) {
      state.err = action.payload;
      state.completed = false;
      state.loading = false;
    },
    portfolioCreateUpdateFetchStart(state, action) {
      state.err = null;
      state.completed = false;
      state.loading = true;
      if (action.payload?.isNew) {
        state.selected.counters = [];
        state.lastCounterNotesUpdated = {};
      }
    },
    portfolioCreateUpdateFetchSuccess(state, action) {
      if (action.payload.data.error) {
        state.err = { message: action.payload.data.error };
      } else {
        if (action.payload.data.selectedFolder) {
          state.selected.id = action.payload.data.selectedFolder.id;
          state.selected.name = action.payload.data.selectedFolder.name;
          state.selected.currency = action.payload.data.selectedFolder.currency;
          state.selected.default = action.payload.data.selectedFolder.default;
          state.selected.classic = action.payload.data.selectedFolder.classic;
          state.selected.encFolder = action.payload.data.selectedFolder.encFolder;
          state.selected.totalCashFlow = action.payload.data.selectedFolder.totalCashFlow;
          // update counters for selected portfolio
          if (action.payload.data.selectedFolder.counters) {
            state.selected.counters = action.payload.data.selectedFolder.counters;
          }
        }
        state.all = action.payload.data.allFolders;
        state.selectedLayout = action.payload.data.selectedLayout;
        state.performanceChartData = action.payload.data.performanceChartData;
      }
      state.completed = true;
      state.loading = false;
    },
    portfolioCreateUpdateFetchFail(state, action) {
      state.err = action.payload;
      state.completed = false;
      state.loading = false;
    },
    portfolioCountersFetchStart(state, action) {
      state.err = null;
      state.completed = false;
      state.loading = true;
      state.selected.counters = [];
      state.lastCounterNotesUpdated = {};
    },
    portfolioCountersFetchSuccess(state, action) {
      if (action.payload.data.error) {
        state.err = { message: action.payload.data.error };
      } else {
        // update counters for selected portfolio
        if (action.payload.data.counters) {
          state.selected.counters = action.payload.data.counters;
        }
        if (action.payload.data.all_fields) {
          state.allFields = action.payload.data.all_fields;
        }
        if (action.payload.data.custom_layout) {
          state.currentCustomLayoutFields = action.payload.data.custom_layout;
          state.customLayoutHeaders = action.payload.data.custom_layout_headers;
        }
      }

      state.completed = true;
      state.loading = false;
    },
    portfolioCountersFetchFail(state, action) {
      state.err = action.payload;
      state.completed = false;
      state.loading = false;
    },
    portfolioStatsSummaryFetchStart(state, action) {
      state.err = null;
      if (action.payload?.forToday) {
        state.statsSummary = {};
      } else {
        state.statsSummaryAsOf = {};
      }
      state.fetchingStatsSummary = true;
    },
    portfolioStatsSummaryFetchSuccess(state, action) {
      if (action.payload.data.error) {
        state.err = { message: action.payload.data.error };
      } else if (action.payload.data.ago) {
        // update statsSummaryAsOf for selected portfolio
        state.statsSummaryAsOf = action.payload.data;
      } else {
        // update statsSummary for selected portfolio
        state.statsSummary = action.payload.data;
      }
      state.fetchingStatsSummary = false;
    },
    portfolioStatsSummaryFetchFail(state, action) {
      state.err = action.payload;
      state.fetchingStatsSummary = false;
    },
    portfolioUpdateNotesStart(state, action) {
      state.err = null;
      state.lastCounterNotesUpdated = {};
    },
    portfolioUpdateNotesSuccess(state, action) {
      if (action.payload.data.error) {
        state.err = { message: action.payload.data.error };
      } else if (action.payload.data.counter) {
        state.lastCounterNotesUpdated = {
          counter: action.payload.data.counter,
          notes: action.payload.data.notes,
        };
      }
    },
    portfolioUpdateNotesFail(state, action) {
      state.err = action.payload;
    },
    portfolioRelatedInsiderTradesFetchStart(state, action) {
      state.relatedInsiderTrades.error = null;
    },
    portfolioRelatedInsiderTradesFetchSuccess(state, action) {
      // update relatedInsiderTrades for selected portfolio
      state.relatedInsiderTrades = action.payload.data;
    },
    portfolioRelatedInsiderTradesFetchFail(state, action) {
      state.relatedInsiderTrades.error = action.payload;
    },
    handleChangeSelectedLayout(state, action) {
      state.selectedLayout = action.payload.selectedLayout;
    },
    portfolioCounterUpdateStart(state, action) {
      state.err = null;
      state.completed = false;
      state.loading = true;
      state.response = '';
    },
    portfolioCounterUpdateSuccess(state, action) {
      if (action.payload.data.error) {
        state.err = { message: action.payload.data.error };
      } else {
        state.selected = action.payload.data.selectedFolder;
        state.response = action.payload.data.message;
      }
      state.completed = true;
      state.loading = false;
    },
    portfolioCounterUpdateFail(state, action) {
      state.err = action.payload;
      state.completed = false;
      state.loading = false;
    },
    resetMessage(state, action) {
      state.err = null;
      state.response = null;
    },
  },
});

export default portfolioSlice.reducer;

const {
  portfolioFetchStart,
  portfolioFetchSuccess,
  portfolioFetchFail,
  portfolioCreateUpdateFetchStart,
  portfolioCreateUpdateFetchSuccess,
  portfolioCreateUpdateFetchFail,
  portfolioCountersFetchStart,
  portfolioCountersFetchSuccess,
  portfolioCountersFetchFail,
  portfolioStatsSummaryFetchStart,
  portfolioStatsSummaryFetchSuccess,
  portfolioStatsSummaryFetchFail,
  portfolioUpdateNotesStart,
  portfolioUpdateNotesSuccess,
  portfolioUpdateNotesFail,
  portfolioRelatedInsiderTradesFetchStart,
  portfolioRelatedInsiderTradesFetchSuccess,
  portfolioRelatedInsiderTradesFetchFail,
  handleChangeSelectedLayout,
  portfolioCounterUpdateStart,
  portfolioCounterUpdateSuccess,
  portfolioCounterUpdateFail,
  resetMessage,
} = portfolioSlice.actions;

export const fetchData =
  ({ selectedID, selectedLayout, cancelToken }) =>
  (dispatch) => {
    dispatch(portfolioFetchStart());

    let url = 'portfolio';
    if (selectedID) {
      url += `/${selectedID}`;
    }
    url = `${url}.json`;
    if (selectedLayout?.length) {
      url += `?layout=${selectedLayout}`;
    }

    api
      .get(url, { cancelToken })
      .then(({ data }) => {
        dispatch(portfolioFetchSuccess({ data }));
      })
      .catch((error) => {
        dispatch(portfolioFetchFail(error));
      });

    dispatch(resetInstrumentsResponseAndError());
  };

export const createFetchData =
  ({ name, currency, setDefault, classicFlag, cancelToken }) =>
  (dispatch) => {
    dispatch(portfolioCreateUpdateFetchStart({ isNew: true }));
    const params = { name, currency };

    if (setDefault) {
      params.set_default = setDefault;
    }

    if (classicFlag) {
      params.classic_flag = classicFlag;
    }

    api
      .post('portfolio.json', params, { cancelToken })
      .then(({ data }) => {
        dispatch(portfolioCreateUpdateFetchSuccess({ data }));
      })
      .catch((error) => {
        dispatch(portfolioCreateUpdateFetchFail(error));
      });
  };

export const updateFetchData =
  ({ id, name, currency, setDefault, cancelToken }) =>
  (dispatch) => {
    dispatch(portfolioCreateUpdateFetchStart());
    const params = {};

    if (name) {
      params.name = name;
    }

    if (currency) {
      params.currency = currency;
    }

    if (setDefault) {
      params.set_default = setDefault;
    }

    api
      .patch(`portfolio/${id}.json`, params, { cancelToken })
      .then(({ data }) => {
        dispatch(portfolioCreateUpdateFetchSuccess({ data }));
      })
      .catch((error) => {
        dispatch(portfolioCreateUpdateFetchFail(error));
      });
  };

export const deleteFetchData =
  ({ id, cancelToken }) =>
  (dispatch) => {
    dispatch(portfolioCreateUpdateFetchStart());

    api
      .delete(`portfolio/${id}.json`, { cancelToken })
      .then(({ data }) => {
        dispatch(portfolioCreateUpdateFetchSuccess({ data }));
      })
      .catch((error) => {
        dispatch(portfolioCreateUpdateFetchFail(error));
      });
  };

export const addToPortfolioFetchData =
  ({ id, counter, cancelToken }) =>
  (dispatch) => {
    dispatch(portfolioCounterUpdateStart());
    const params = { counter };
    api
      .post(`portfolio/${id}/add_counter.json`, params, { cancelToken })
      .then(({ data }) => {
        dispatch(portfolioCounterUpdateSuccess({ data }));
      })
      .catch((error) => {
        dispatch(portfolioCounterUpdateFail(error));
      });
  };

export const getCounterList =
  ({ selectedPortfolioID, selectedLayout, instrument, cancelToken }) =>
  (dispatch) => {
    dispatch(portfolioCountersFetchStart());

    let url = `/portfolio/${selectedPortfolioID}/counter_list.json`;
    if (selectedLayout?.length) {
      url += `?layout=${selectedLayout}`;
    }

    api
      .get(url, { cancelToken })
      .then(({ data }) => {
        dispatch(portfolioCountersFetchSuccess({ data }));
      })
      .catch((error) => {
        dispatch(portfolioCountersFetchFail(error));
      });

    if (!instrument) {
      dispatch(resetInstrumentsResponseAndError());
    }
  };

export const fetchStatsSummary =
  ({ selectedPortfolioID, ago, cancelToken }) =>
  (dispatch) => {
    dispatch(portfolioStatsSummaryFetchStart({ forToday: !ago?.length }));

    let url = `/portfolio/${selectedPortfolioID}/stats_summary.json`;
    if (ago?.length) {
      url = `${url}?ago=${ago}`;
    }

    api
      .get(url, { cancelToken })
      .then(({ data }) => {
        dispatch(portfolioStatsSummaryFetchSuccess({ data }));
      })
      .catch((error) => {
        dispatch(portfolioStatsSummaryFetchFail(error));
      });
  };

export const updateCounterNotes =
  ({ portfolioID, counter, notes, cancelToken }) =>
  (dispatch) => {
    dispatch(portfolioUpdateNotesStart());

    const url = `/portfolio/${portfolioID}/update_notes.json?stock_id=${counter}&notes=${notes}`;
    api
      .patch(url, { cancelToken })
      .then(({ data }) => {
        dispatch(portfolioUpdateNotesSuccess({ data }));
      })
      .catch((error) => {
        dispatch(portfolioUpdateNotesFail(error));
      });
  };

export const fetchRelatedInsiderTrades =
  ({ selectedPortfolioID, cancelToken }) =>
  (dispatch) => {
    dispatch(portfolioRelatedInsiderTradesFetchStart());

    const url = `/portfolio/${selectedPortfolioID}/related_insider_trades.json`;
    api
      .get(url, { cancelToken })
      .then(({ data }) => {
        dispatch(portfolioRelatedInsiderTradesFetchSuccess({ data }));
      })
      .catch((error) => {
        dispatch(portfolioRelatedInsiderTradesFetchFail(error));
      });
  };

export const changeSelectedPortfolio =
  ({ selectedPortfolioID, selectedLayout, cancelToken }) =>
  (dispatch) => {
    dispatch(fetchData({ selectedID: selectedPortfolioID, selectedLayout, cancelToken }));
  };

export const changeSelectedLayout =
  ({ selectedPortfolioID, selectedLayout, cancelToken }) =>
  (dispatch) => {
    dispatch(handleChangeSelectedLayout({ selectedLayout }));
    dispatch(getCounterList({ selectedPortfolioID, selectedLayout, cancelToken }));
  };

export const clearMessage = () => (dispatch) => {
  dispatch(resetMessage());
};
