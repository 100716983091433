import React, { useState } from 'react';
import PropTypes from 'prop-types';

import classes from './tableColumnSearchFilter.module.css';

export default function TableColumnSearchFilter({ headerLabel, handleFilterChange, placeholder }) {
  const [showSearchBar, setShowSearchBar] = useState(false);

  const handleOnClickButton = () => {
    handleFilterChange(null);
    setShowSearchBar(!showSearchBar);
  };

  return showSearchBar ? (
    <div className="searchBarWrapper d-flex position-relative">
      <input
        className={`${classes.searchBar} form-control`}
        onChange={(e) => {
          if (handleFilterChange) {
            handleFilterChange(e.target.value);
          }
        }}
        autoFocus={true}
        placeholder={placeholder || 'Search...'}
      />
      <button type="button" className={classes.closeButton} onClick={handleOnClickButton}>
        <i className="fa-light fa-xmark" />
      </button>
    </div>
  ) : (
    <>
      {headerLabel}
      <button type="button" className={classes.searchButton} onClick={handleOnClickButton}>
        <i className="fa-light fa-magnifying-glass" />
      </button>
    </>
  );
}

TableColumnSearchFilter.propTypes = {
  headerLabel: PropTypes.string,
  handleFilterChange: PropTypes.func,
  placeholder: PropTypes.string,
};
