import React, { useState, useEffect, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import RangeSlider from './RangeSlider';
import './marketScreener.scss';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useDetectMobileScreen } from '../../../utils/utils';
import {
  setAddedCriterias,
  setInvalidForms,
  setCriteriasDropdownLabel,
  removeCriteriasDropdownLabel,
} from './marketScreenerSlice';

function CriteriaTemplateRenderer({
  market,
  fieldKey,
  fieldIndex,
  attribute,
  stockCategories,
  validated,
  orCriteria,
  parentKey,
  parentIndex,
}) {
  const defaultValue = () => {
    // Fix issue in load template and save template for template [tmpl_price_to_high, tmpl_price_to_low] as they have different operator value in v1.
    const tmplOperatorSelect =
      attribute.tmpl === 'tmpl_price_to_high' || attribute.tmpl === 'tmpl_price_to_low'
        ? 'GTEQ_SELECT'
        : 'GTEQ';
    return {
      operator: attribute?.templateValue?.operator ?? tmplOperatorSelect,
      val: attribute?.templateValue?.val ?? '',
      year: attribute?.templateValue?.year ?? '',
      W: attribute?.templateValue?.W ?? '',
      X: attribute?.templateValue?.X ?? '',
      Y: attribute?.templateValue?.Y ?? '',
      Z: attribute?.templateValue?.Z ?? '',
      MA1: attribute?.templateValue?.MA1 ?? 'MA',
      MA2: attribute?.templateValue?.MA2 ?? 'MA',
      GTEQ: attribute?.templateValue?.GTEQ ?? '',
      LTEQ: attribute?.templateValue?.LTEQ ?? '',
    };
  };
  const dispatch = useDispatch();
  const addedCriterias = useSelector((state) => state.marketScreener.addedCriterias);
  const invalidForms = useSelector((state) => state.marketScreener.invalidForms);
  const criteriasDropdownLabel = useSelector(
    (state) => state.marketScreener.criteriasDropdownLabel,
  );
  const [values, setValues] = useState(() => defaultValue());
  const isInitialRender = useRef(true); // Track initial render
  const renderTemplateContainerRef = useRef(null);
  const isMobile = useDetectMobileScreen();

  const generateSelectedLabel = (container) => {
    const clonedContainer = container.cloneNode(true);

    // Copy values from original container to cloned container
    const originalSelects = container.querySelectorAll('select');
    // value in <SELECT> will not work in cloneNode(), thus we must explicity copy the value to cloned <SELECT>
    const clonedSelects = clonedContainer.querySelectorAll('select');
    originalSelects.forEach((originalSelect, index) => {
      clonedSelects[index].value = originalSelect.value;
    });

    // RangeSlider
    const isSlider = clonedContainer.querySelector('.slider');
    const clonedRangeSliders = clonedContainer.querySelectorAll('input[type=range]');
    if (isSlider && clonedRangeSliders && clonedRangeSliders.length > 0) {
      const label = document.createDocumentFragment();
      clonedRangeSliders.forEach((element, index, array) => {
        const span1 = document.createElement('span');
        span1.className = 'text-success';
        span1.textContent = element.value;
        label.appendChild(span1);
        if (index !== array.length - 1) {
          const span2 = document.createElement('span');
          span2.textContent = 'to';
          label.appendChild(span2);
        }
      });
      clonedContainer.querySelector('.slider').replaceWith(label);
    } else {
      clonedContainer.querySelectorAll('SELECT, INPUT').forEach((element) => {
        if (element.type !== 'hidden') {
          const span = document.createElement('span');
          switch (element.value) {
            case 'LTEQ':
            case 'LTEQ_SELECT':
              span.textContent = '<';
              break;
            case 'GTEQ':
            case 'GTEQ_SELECT':
              span.textContent = '>';
              break;
            case 'BT':
              span.textContent = '';
              break;
            default:
              span.textContent = element.value;
              break;
          }
          span.classList.add('text-success');
          element.replaceWith(span);
        } else {
          element.remove();
        }
      });
    }
    return clonedContainer.innerHTML;
  };

  // refresh the values when input changes
  useEffect(() => {
    setValues(() => defaultValue());
  }, [fieldIndex, fieldKey, criteriasDropdownLabel]);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return; // Skip the first render
    }

    // Update addedCriterias state whenever values change
    const updatedCriterias = JSON.parse(JSON.stringify(addedCriterias));
    if (orCriteria || parentKey !== undefined) {
      const parentCriteria = updatedCriterias[parentIndex][parentKey];
      const subCriteria = parentCriteria.sub_criterias[fieldIndex][fieldKey];
      subCriteria.templateValue = values;
    } else {
      const parentCriteria = updatedCriterias[fieldIndex][fieldKey];
      parentCriteria.templateValue = values;
    }

    dispatch(setAddedCriterias({ criteria: updatedCriterias }));

    // Add debounce to avoid crash when values changed too quickly
    const container = renderTemplateContainerRef.current;
    const delayDebounceFn = setTimeout(() => {
      const desktopDropdownLabel = generateSelectedLabel(container);
      dispatch(
        setCriteriasDropdownLabel({
          label: desktopDropdownLabel,
          index: fieldIndex,
          parentIndex: orCriteria || parentIndex !== undefined ? parentIndex : null,
        }),
      );
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [values]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setValues((prevValues) => {
      let resetFields;
      if (name === 'operator' && value === 'BT') {
        // if operator is selected and is 'BT', reset all other form-controls other than GTEQ and LTEQ
        const fieldsToReset = ['val', 'W', 'X', 'Y', 'Z'];
        resetFields = fieldsToReset.reduce((acc, field) => {
          acc[field] = '';
          return acc;
        }, {});
      } else if (name === 'operator' && value !== 'BT') {
        // if operator is selected but not 'BT', reset GTEQ and LTEQ
        const fieldsToReset = ['GTEQ', 'LTEQ'];
        resetFields = fieldsToReset.reduce((acc, field) => {
          acc[field] = '';
          return acc;
        }, {});
      } else {
        // reset noting
        resetFields = {};
      }

      // Update the specific field with the new value
      return { ...prevValues, ...resetFields, [name]: value };
    });
  };

  // prevent user from pressing "enter" key to submit the form
  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };

  const handleRemoveCriteriaButtonClick = (event, criteria_index) => {
    event.preventDefault();

    // remove existing invalid validation (if anys)
    const updatedInvalidForms = [...invalidForms];
    if (orCriteria) {
      updatedInvalidForms.splice(`sub_${fieldIndex}`, 1);
    } else {
      updatedInvalidForms.splice(fieldIndex, 1);
    }

    dispatch(
      setInvalidForms({
        invalidForms: updatedInvalidForms,
      }),
    );

    // remove criteria from addedCriteria
    const updatedCriterias = JSON.parse(JSON.stringify(addedCriterias));
    if (parentKey !== undefined || orCriteria) {
      // remove OR criteria
      updatedCriterias[parentIndex][parentKey].sub_criterias.splice(criteria_index, 1);
    } else {
      // remove AND criteria
      updatedCriterias.splice(criteria_index, 1);
    }

    dispatch(
      removeCriteriasDropdownLabel({
        index: criteria_index,
        parentIndex: orCriteria ? parentIndex : null,
      }),
    );

    dispatch(
      setAddedCriterias({
        criteria: updatedCriterias,
      }),
    );
  };

  const renderTemplate = () => {
    switch (attribute.tmpl) {
      case 'tmpl_perc_for_years':
        /*
        perc_for_years
        More than ____ % for the past (1/2/3/4/5) financial year(s)
        Less than ____ % for the past (1/2/3/4/5) financial year(s)
        Between ____ to ____ % for the past (1/2/3/4/5) financial year(s)
         */
        return (
          <>
            <Form.Select name="operator" onChange={handleChange} value={values.operator} required>
              <option value="GTEQ">more than</option>
              <option value="LTEQ">less than</option>
              <option value="BT">between</option>
            </Form.Select>
            {values.operator === 'BT' ? (
              <>
                <Form.Control
                  type="text"
                  name="GTEQ"
                  value={values.GTEQ}
                  onChange={handleChange}
                  size="5"
                  required
                />
                <span>% to</span>
                <Form.Control
                  type="text"
                  name="LTEQ"
                  value={values.LTEQ}
                  onChange={handleChange}
                  size="5"
                  required
                />
                <span>%</span>
              </>
            ) : (
              <>
                <Form.Control
                  type="text"
                  name="val"
                  value={values.val}
                  onChange={handleChange}
                  size="5"
                  required
                />
                <span>%</span>
              </>
            )}
            <span>for the past</span>
            <Form.Select name="year" value={values.year} onChange={handleChange} required>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </Form.Select>
            <span>financial year(s)</span>
            <input type="hidden" name="input_val_perc" value="true" />
            <input type="hidden" name="criteria" value={fieldKey} />
            <input type="hidden" name="title" value={attribute.title} />
          </>
        );
      case 'tmpl_perc_growth_for_years':
        /*
        perc_for_years
        More than ____ % for the past (1/2/3/4/5) financial year(s)
        Less than ____ % for the past (1/2/3/4/5) financial year(s)
        Between ____ to ____ % for the past (1/2/3/4/5) financial year(s)
         */
        return (
          <>
            <Form.Select name="operator" onChange={handleChange} value={values.operator} required>
              <option value="GTEQ">more than</option>
              <option value="LTEQ">less than</option>
              <option value="BT">between</option>
            </Form.Select>
            {values.operator === 'BT' ? (
              <>
                <Form.Control
                  type="text"
                  name="GTEQ"
                  value={values.GTEQ}
                  onChange={handleChange}
                  size="5"
                  required
                />
                <span>% to</span>
                <Form.Control
                  type="text"
                  name="LTEQ"
                  value={values.LTEQ}
                  onChange={handleChange}
                  size="5"
                  required
                />
                <span>%</span>
              </>
            ) : (
              <>
                <Form.Control
                  type="text"
                  name="val"
                  value={values.val}
                  onChange={handleChange}
                  size="5"
                  required
                />
                <span>%</span>
              </>
            )}
            <span>anually for the past</span>
            <Form.Select name="year" value={values.year} onChange={handleChange} required>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </Form.Select>
            <span>financial year(s)</span>
            <input type="hidden" name="input_val_perc" value="true" />
            <input type="hidden" name="criteria" value={fieldKey} />
            <input type="hidden" name="title" value={attribute.title} />
          </>
        );
      case 'tmpl_perc_for_years_div':
        /*
        perc_for_years_div
        More than ____ % for the past (1/2/3/4/5) financial year(s)
        Less than ____ % for the past (1/2/3/4/5) financial year(s)
        Between ____ to ____ % for the past (1/2/3/4/5) financial year(s)
        Did Not Pay Dividend
        */
        return (
          <>
            <Form.Select name="operator" onChange={handleChange} value={values.operator}>
              <option value="GTEQ">more than</option>
              <option value="LTEQ">less than</option>
              <option value="BT">between</option>
              <option value="NO">did not pay dividend</option>
            </Form.Select>
            {values.operator === 'BT' && (
              <>
                <Form.Control
                  type="text"
                  name="GTEQ"
                  value={values.GTEQ}
                  onChange={handleChange}
                  size="5"
                  required
                />
                <span>% to</span>
                <Form.Control
                  type="text"
                  name="LTEQ"
                  value={values.LTEQ}
                  onChange={handleChange}
                  size="5"
                  required
                />
                <span>%</span>
              </>
            )}
            {(values.operator === 'GTEQ' || values.operator === 'LTEQ') && (
              <>
                <Form.Control
                  type="text"
                  name="val"
                  value={values.val}
                  onChange={handleChange}
                  size="5"
                  required
                />
                <span>%</span>
              </>
            )}
            <span>for the past</span>
            <Form.Select name="year" value={values.year} onChange={handleChange} required>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </Form.Select>
            <span>financial year(s)</span>
            <input type="hidden" name="input_val_perc" value="true" />
            <input type="hidden" name="criteria" value={fieldKey} />
            <input type="hidden" name="title" value={attribute.title} />
          </>
        );
      case 'tmpl_perc_for_years_cagr':
        /*
          perc_for_years_cagr
          More than ____ % for the past (3/5) financial year(s)
          Less than ____ % for the past (3/5) financial year(s)
          More than ____ % for the past (3/5) financial year(s)
          */
        return (
          <>
            <Form.Select name="operator" onChange={handleChange} value={values.operator}>
              <option value="GTEQ">more than</option>
              <option value="LTEQ">less than</option>
            </Form.Select>
            <Form.Control
              type="text"
              name="val"
              value={values.val}
              onChange={handleChange}
              size="5"
              required
            />
            <span>%</span>
            <span>for the past</span>
            <Form.Select name="year" value={values.year} onChange={handleChange} required>
              <option value="3">3</option>
              <option value="5">5</option>
            </Form.Select>
            <span>financial year(s)</span>
            <input type="hidden" name="input_val_perc" value="true" />
            <input type="hidden" name="criteria" value={fieldKey} />
            <input type="hidden" name="title" value={attribute.title} />
          </>
        );
      case 'tmpl_perc_for_years_returns':
        /*
        perc_for_years_cagr
        More than ____ % for the past (1/3/5) financial year(s)
        Less than ____ % for the past (1/3/5) financial year(s)
        More than ____ % for the past (1/3/5) financial year(s)
         */
        return (
          <>
            <Form.Select name="operator" onChange={handleChange} value={values.operator} required>
              <option value="GTEQ">more than</option>
              <option value="LTEQ">less than</option>
            </Form.Select>
            <Form.Control
              type="text"
              name="val"
              value={values.val}
              onChange={handleChange}
              size="5"
              required
            />
            <span>%</span>
            <span>for the past</span>
            <Form.Select name="year" value={values.year} onChange={handleChange} required>
              <option value="1">1</option>
              <option value="3">3</option>
              <option value="5">5</option>
            </Form.Select>
            <span>financial year(s)</span>
            <input type="hidden" name="input_val_perc" value="true" />
            <input type="hidden" name="criteria" value={fieldKey} />
            <input type="hidden" name="title" value={attribute.title} />
          </>
        );
      case 'tmpl_times_for_years':
        /*
        times_for_years
        More than ____ times for the past (1/2/3/4/5) financial year(s)
        Less than ____ times for the past (1/2/3/4/5) financial year(s)
        Between ____ to ____ times for the past (1/2/3/4/5) financial year(s)
         */
        return (
          <>
            <Form.Select name="operator" onChange={handleChange} value={values.operator} required>
              <option value="GTEQ">more than</option>
              <option value="LTEQ">less than</option>
              <option value="BT">between</option>
            </Form.Select>
            {values.operator === 'BT' ? (
              <>
                <Form.Control
                  type="text"
                  name="GTEQ"
                  value={values.GTEQ}
                  onChange={handleChange}
                  size="5"
                  required
                />
                <span>to</span>
                <Form.Control
                  type="text"
                  name="LTEQ"
                  value={values.LTEQ}
                  onChange={handleChange}
                  size="5"
                  required
                />
                <span>times</span>
              </>
            ) : (
              <>
                <Form.Control
                  type="text"
                  name="val"
                  value={values.val}
                  onChange={handleChange}
                  size="5"
                  required
                />
                <span>times</span>
              </>
            )}
            <span>for the past</span>
            <Form.Select name="year" value={values.year} onChange={handleChange} required>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </Form.Select>
            <span>financial year(s)</span>
            <input type="hidden" name="input_val_perc" value="false" />
            <input type="hidden" name="criteria" value={fieldKey} />
            <input type="hidden" name="title" value={attribute.title} />
          </>
        );
      case 'tmpl_times_for_now':
        /*
        times_for_now
        More than ____ times now
        Less than ____ times now
        Between ____ to ____ times now
         */
        return (
          <>
            <Form.Select name="operator" onChange={handleChange} value={values.operator} required>
              <option value="GTEQ">more than</option>
              <option value="LTEQ">less than</option>
              <option value="BT">between</option>
            </Form.Select>
            {values.operator === 'BT' ? (
              <>
                <Form.Control
                  type="text"
                  name="GTEQ"
                  value={values.GTEQ}
                  onChange={handleChange}
                  size="5"
                  required
                />
                <span>to</span>
                <Form.Control
                  type="text"
                  name="LTEQ"
                  value={values.LTEQ}
                  onChange={handleChange}
                  size="5"
                  required
                />
                <span>times now</span>
              </>
            ) : (
              <>
                <Form.Control
                  type="text"
                  name="val"
                  value={values.val}
                  onChange={handleChange}
                  size="5"
                  required
                />
                <span>times now</span>
              </>
            )}
            <input type="hidden" name="input_val_perc" value="false" />
            <input type="hidden" name="criteria" value={fieldKey} />
            <input type="hidden" name="title" value={attribute.title} />
          </>
        );
      case 'tmpl_perc_for_now':
        /*
        times_for_now
        More than ____ % now
        Less than ____ % now
        Between ____ to ____ % now
         */
        return (
          <>
            <Form.Select name="operator" onChange={handleChange} value={values.operator} required>
              <option value="GTEQ">more than</option>
              <option value="LTEQ">less than</option>
              <option value="BT">between</option>
            </Form.Select>
            {values.operator === 'BT' ? (
              <>
                <Form.Control
                  type="text"
                  name="GTEQ"
                  value={values.GTEQ}
                  onChange={handleChange}
                  size="5"
                  required
                />
                <span>% to</span>
                <Form.Control
                  type="text"
                  name="LTEQ"
                  value={values.LTEQ}
                  onChange={handleChange}
                  size="5"
                  required
                />
                <span>% now</span>
              </>
            ) : (
              <>
                <Form.Control
                  type="text"
                  name="val"
                  value={values.val}
                  onChange={handleChange}
                  size="5"
                  required
                />
                <span>% now</span>
              </>
            )}
            <input type="hidden" name="input_val_perc" value="false" />
            <input type="hidden" name="criteria" value={fieldKey} />
            <input type="hidden" name="title" value={attribute.title} />
          </>
        );
      case 'tmpl_value_for_years':
        /*
        value_for_years
        More than ____  for the past (1/2/3/4/5) financial year(s)
        Less than ____  for the past (1/2/3/4/5) financial year(s)
        Between ____ to ____  for the past (1/2/3/4/5) financial year(s)
         */
        return (
          <>
            <Form.Select name="operator" onChange={handleChange} value={values.operator} required>
              <option value="GTEQ">more than</option>
              <option value="LTEQ">less than</option>
              <option value="BT">between</option>
            </Form.Select>
            {values.operator === 'BT' ? (
              <>
                <Form.Control
                  type="text"
                  name="GTEQ"
                  value={values.GTEQ}
                  onChange={handleChange}
                  size="5"
                  required
                />
                <span>to</span>
                <Form.Control
                  type="text"
                  name="LTEQ"
                  value={values.LTEQ}
                  onChange={handleChange}
                  size="5"
                  required
                />
              </>
            ) : (
              <Form.Control
                type="text"
                name="val"
                value={values.val}
                onChange={handleChange}
                size="5"
                required
              />
            )}
            <span>for the past</span>
            <Form.Select name="year" value={values.year} onChange={handleChange} required>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </Form.Select>
            <span>financial year(s)</span>
            <input type="hidden" name="input_val_perc" value="false" />
            <input type="hidden" name="criteria" value={fieldKey} />
            <input type="hidden" name="title" value={attribute.title} />
          </>
        );
      case 'tmpl_ta_ma_cross_above':
        // MA (_X_) crossed above MA (_Y_)
        return (
          <>
            <Form.Select name="MA1" onChange={handleChange} value={values.MA1} required>
              <option value="MA">MA</option>
              <option value="WMA">WMA</option>
              <option value="EMA">EMA</option>
            </Form.Select>
            <Form.Control
              type="text"
              name="X"
              size="5"
              onChange={handleChange}
              value={values.X}
              required
            />
            <span>crossed above</span>
            <Form.Select name="MA2" onChange={handleChange} value={values.MA2} required>
              <option value="MA">MA</option>
              <option value="WMA">WMA</option>
              <option value="EMA">EMA</option>
            </Form.Select>
            <Form.Control
              type="text"
              name="Y"
              size="5"
              onChange={handleChange}
              value={values.Y}
              required
            />

            <input type="hidden" name="criteria" value={fieldKey} />
            <input type="hidden" name="title" value={attribute.title} />
            <input
              type="hidden"
              name="xml_tmpl"
              value={`<NA><Field>taCross</Field><StrVal>${values.MA1}(CLOSE,${values.X}):${values.MA2}(CLOSE,${values.Y}):1</StrVal></NA>`}
            />
          </>
        );
      case 'tmpl_ta_ma_cross_below':
        // MA (_X_) crossed below MA (_Y_)
        return (
          <>
            <Form.Select name="MA1" onChange={handleChange} value={values.MA1} required>
              <option value="MA">MA</option>
              <option value="WMA">WMA</option>
              <option value="EMA">EMA</option>
            </Form.Select>
            <Form.Control
              type="text"
              name="X"
              size="5"
              onChange={handleChange}
              value={values.X}
              required
            />
            <span>crossed above</span>
            <Form.Select name="MA2" onChange={handleChange} value={values.MA2} required>
              <option value="MA">MA</option>
              <option value="WMA">WMA</option>
              <option value="EMA">EMA</option>
            </Form.Select>
            <Form.Control
              type="text"
              name="Y"
              size="5"
              onChange={handleChange}
              value={values.Y}
              required
            />

            <input type="hidden" name="criteria" value={fieldKey} />
            <input type="hidden" name="title" value={attribute.title} />
            <input
              type="hidden"
              name="xml_tmpl"
              value={`<NA><Field>taCross</Field><StrVal>${values.MA1}(CLOSE,${values.Y}):${values.MA2}(CLOSE,${values.X}):1</StrVal></NA>`}
            />
          </>
        );
      case 'tmpl_ta_ma_bullish_price':
        // MA (_X_) crossed below Close Price
        return (
          <>
            <Form.Select name="MA1" onChange={handleChange} value={values.MA1} required>
              <option value="MA">MA</option>
              <option value="WMA">WMA</option>
              <option value="EMA">EMA</option>
            </Form.Select>
            <Form.Control
              type="text"
              name="X"
              size="5"
              onChange={handleChange}
              value={values.X}
              required
            />
            <span>crossed below Close Price</span>

            <input type="hidden" name="criteria" value={fieldKey} />
            <input type="hidden" name="title" value={attribute.title} />
            <input
              type="hidden"
              name="xml_tmpl"
              value={`<NA><Field>taCross</Field><StrVal>CLOSE:${values.MA1}(CLOSE,${values.X}):1</StrVal></NA>`}
            />
          </>
        );
      case 'tmpl_ta_ma_bearish_price':
        // MA (_X_) crossed above Close Price
        return (
          <>
            <Form.Select name="MA1" onChange={handleChange} value={values.MA1} required>
              <option value="MA">MA</option>
              <option value="WMA">WMA</option>
              <option value="EMA">EMA</option>
            </Form.Select>
            <Form.Control
              type="text"
              name="X"
              size="5"
              onChange={handleChange}
              value={values.X}
              required
            />
            <span>crossed above Close Price</span>

            <input type="hidden" name="criteria" value={fieldKey} />
            <input type="hidden" name="title" value={attribute.title} />
            <input
              type="hidden"
              name="xml_tmpl"
              value={`<NA><Field>taCross</Field><StrVal>${values.MA1}(CLOSE,${values.X}):CLOSE:1</StrVal></NA>`}
            />
          </>
        );
      case 'tmpl_ta_ma_above_close':
        // MA (_X_)  above Close Price for _Y_  Days
        return (
          <>
            <Form.Select name="MA1" onChange={handleChange} value={values.MA1} required>
              <option value="MA">MA</option>
              <option value="WMA">WMA</option>
              <option value="EMA">EMA</option>
            </Form.Select>
            <Form.Control
              type="text"
              name="X"
              size="5"
              onChange={handleChange}
              value={values.X}
              required
            />
            <span>above Close Price for</span>
            <Form.Control
              type="text"
              name="Y"
              size="5"
              onChange={handleChange}
              value={values.Y}
              required
            />
            <span>Days</span>

            <input type="hidden" name="criteria" value={fieldKey} />
            <input type="hidden" name="title" value={attribute.title} />
            <input
              type="hidden"
              name="xml_tmpl"
              value={`<NA><Field>taAbove</Field><StrVal>${values.MA1}(CLOSE,${values.X}):CLOSE:${values.Y}</StrVal></NA>`}
            />
          </>
        );
      case 'tmpl_ta_ma_below_close':
        // MA (_X_)  below Close Price for _Y_ Days
        return (
          <>
            <Form.Select name="MA1" onChange={handleChange} value={values.MA1} required>
              <option value="MA">MA</option>
              <option value="WMA">WMA</option>
              <option value="EMA">EMA</option>
            </Form.Select>
            <Form.Control
              type="text"
              name="X"
              size="5"
              onChange={handleChange}
              value={values.X}
              required
            />
            <span>below Close Price for</span>
            <Form.Control
              type="text"
              name="Y"
              size="5"
              onChange={handleChange}
              value={values.Y}
              required
            />
            <span>Days</span>

            <input type="hidden" name="criteria" value={fieldKey} />
            <input type="hidden" name="title" value={attribute.title} />
            <input
              type="hidden"
              name="xml_tmpl"
              value={`<NA><Field>taBelow</Field><StrVal>${values.MA1}(CLOSE,${values.X}):CLOSE:${values.Y}</StrVal></NA>`}
            />
          </>
        );
      case 'tmpl_ta_fast_so_above':
        // FastSO ( W ,_X  ) above _Y_ for   _Z_  Days
        return (
          <>
            <span>FastSO</span>
            (
            <Form.Control
              type="text"
              name="W"
              size="5"
              onChange={handleChange}
              value={values.W}
              required
            />
            <span>,</span>
            <Form.Control
              type="text"
              name="X"
              size="5"
              onChange={handleChange}
              value={values.X}
              required
            />
            )<span>above</span>
            <Form.Control
              type="text"
              name="Y"
              size="5"
              onChange={handleChange}
              value={values.Y}
              required
            />
            <span>for</span>
            <Form.Control
              type="text"
              name="Z"
              size="5"
              onChange={handleChange}
              value={values.Z}
              required
            />
            <span>Days</span>
            <input type="hidden" name="criteria" value={fieldKey} />
            <input type="hidden" name="title" value={attribute.title} />
            <input
              type="hidden"
              name="xml_tmpl"
              value={`<NA><Field>taAbove</Field><StrVal>FastSO(${values.W},${values.X}):${values.Y}:${values.Z}</StrVal></NA>`}
            />
          </>
        );
      case 'tmpl_ta_fast_so_below':
        // FastSO ( W ,_X  ) below _Y_  for  _Z_  Days
        return (
          <>
            <span>FastSO</span>
            (
            <Form.Control
              type="text"
              name="W"
              size="5"
              onChange={handleChange}
              value={values.W}
              required
            />
            <span>,</span>
            <Form.Control
              type="text"
              name="X"
              size="5"
              onChange={handleChange}
              value={values.X}
              required
            />
            )<span>below</span>
            <Form.Control
              type="text"
              name="Y"
              size="5"
              onChange={handleChange}
              value={values.Y}
              required
            />
            <span>for</span>
            <Form.Control
              type="text"
              name="Z"
              size="5"
              onChange={handleChange}
              value={values.Z}
              required
            />
            <span>Days</span>
            <input type="hidden" name="criteria" value={fieldKey} />
            <input type="hidden" name="title" value={attribute.title} />
            <input
              type="hidden"
              name="xml_tmpl"
              value={`<NA><Field>taBelow</Field><StrVal>FastSO(${values.W},${values.X}):${values.Y}:${values.Z}</StrVal></NA>`}
            />
          </>
        );
      case 'tmpl_ta_macd_bullish':
        // MACD(_X_, _Y_, _Z_): Diff crossed above Signal
        return (
          <>
            <span>MACD</span>
            (
            <Form.Control
              type="text"
              name="X"
              size="5"
              onChange={handleChange}
              value={values.X}
              required
            />
            <span>,</span>
            <Form.Control
              type="text"
              name="Y"
              size="5"
              onChange={handleChange}
              value={values.Y}
              required
            />
            <span>,</span>
            <Form.Control
              type="text"
              name="Z"
              size="5"
              onChange={handleChange}
              value={values.Z}
              required
            />
            )<span>: Diff crossed above Signal</span>
            <input type="hidden" name="criteria" value={fieldKey} />
            <input type="hidden" name="title" value={attribute.title} />
            <input
              type="hidden"
              name="xml_tmpl"
              value={`<NA><Field>taCross</Field><StrVal>MACD[Diff](${values.X},${values.Y},${values.Z}):MACD[Signal](${values.X},${values.Y},${values.Z}):1</StrVal></NA>`}
            />
          </>
        );
      case 'tmpl_ta_macd_bearish':
        // MACD(_X_, _Y_, _Z_): Signal crossed above Diff
        return (
          <>
            <span>MACD</span>
            (
            <Form.Control
              type="text"
              name="X"
              size="5"
              onChange={handleChange}
              value={values.X}
              required
            />
            <span>,</span>
            <Form.Control
              type="text"
              name="Y"
              size="5"
              onChange={handleChange}
              value={values.Y}
              required
            />
            <span>,</span>
            <Form.Control
              type="text"
              name="Z"
              size="5"
              onChange={handleChange}
              value={values.Z}
              required
            />
            )<span>: Signal crossed above Diff</span>
            <input type="hidden" name="criteria" value={fieldKey} />
            <input type="hidden" name="title" value={attribute.title} />
            <input
              type="hidden"
              name="xml_tmpl"
              value={`<NA><Field>taCross</Field><StrVal>MACD[Signal](${values.X},${values.Y},${values.Z}):MACD[Diff](${values.X},${values.Y},${values.Z}):1</StrVal></NA>`}
            />
          </>
        );
      case 'tmpl_ta_rsi_overbought':
        // RSI (_X_) above _Y_ for   _Z_  Days
        return (
          <>
            <span>RSI</span>
            (
            <Form.Control
              type="text"
              name="X"
              size="5"
              onChange={handleChange}
              value={values.X}
              required
            />
            )<span>above</span>
            <Form.Control
              type="text"
              name="Y"
              size="5"
              onChange={handleChange}
              value={values.Y}
              required
            />
            <span> for </span>
            <Form.Control
              type="text"
              name="Z"
              size="5"
              onChange={handleChange}
              value={values.Z}
              required
            />
            <span>days</span>
            <input type="hidden" name="criteria" value={fieldKey} />
            <input type="hidden" name="title" value={attribute.title} />
            <input
              type="hidden"
              name="xml_tmpl"
              value={`<NA><Field>taAbove</Field><StrVal>RSI(${values.X}):${values.Y}:${values.Z}</StrVal></NA>`}
            />
          </>
        );
      case 'tmpl_ta_rsi_oversold':
        // RSI (_X_) below _Y_  for  _Z_  Days
        return (
          <>
            <span>RSI</span>
            (
            <Form.Control
              type="text"
              name="X"
              size="5"
              onChange={handleChange}
              value={values.X}
              required
            />
            )<span>below</span>
            <Form.Control
              type="text"
              name="Y"
              size="5"
              onChange={handleChange}
              value={values.Y}
              required
            />
            <span> for </span>
            <Form.Control
              type="text"
              name="Z"
              size="5"
              onChange={handleChange}
              value={values.Z}
              required
            />
            <span>days</span>
            <input type="hidden" name="criteria" value={fieldKey} />
            <input type="hidden" name="title" value={attribute.title} />
            <input
              type="hidden"
              name="xml_tmpl"
              value={`<NA><Field>taBelow</Field><StrVal>RSI(${values.X}):${values.Y}:${values.Z}</StrVal></NA>`}
            />
          </>
        );
      case 'tmpl_ta_cci_overbought':
        // CCI (_X_) above _Y_  for  _Z_  Days
        return (
          <>
            <span>CCI</span>
            (
            <Form.Control
              type="text"
              name="X"
              size="5"
              onChange={handleChange}
              value={values.X}
              required
            />
            )<span>above</span>
            <Form.Control
              type="text"
              name="Y"
              size="5"
              onChange={handleChange}
              value={values.Y}
              required
            />
            <span> for </span>
            <Form.Control
              type="text"
              name="Z"
              size="5"
              onChange={handleChange}
              value={values.Z}
              required
            />
            <span>days</span>
            <input type="hidden" name="criteria" value={fieldKey} />
            <input type="hidden" name="title" value={attribute.title} />
            <input
              type="hidden"
              name="xml_tmpl"
              value={`<NA><Field>taAbove</Field><StrVal>CCI(${values.X}):${values.Y}:${values.Z}</StrVal></NA>`}
            />
          </>
        );
      case 'tmpl_ta_cci_oversold':
        //  CCI (_X_) below _Y_  for  _Z_  Days
        return (
          <>
            <span>CCI</span>
            (
            <Form.Control
              type="text"
              name="X"
              size="5"
              onChange={handleChange}
              value={values.X}
              required
            />
            )<span>below</span>
            <Form.Control
              type="text"
              name="Y"
              size="5"
              onChange={handleChange}
              value={values.Y}
              required
            />
            <span> for </span>
            <Form.Control
              type="text"
              name="Z"
              size="5"
              onChange={handleChange}
              value={values.Z}
              required
            />
            <span>days</span>
            <input type="hidden" name="criteria" value={fieldKey} />
            <input type="hidden" name="title" value={attribute.title} />
            <input
              type="hidden"
              name="xml_tmpl"
              value={`<NA><Field>taBelow</Field><StrVal>CCI(${values.X}):${values.Y}:${values.Z}</StrVal></NA>`}
            />
          </>
        );
      case 'tmpl_ta_adx_bullish':
        // ADX (_X_, _Y_): +DI above -DI
        return (
          <>
            <span>ADX</span>
            (
            <Form.Control
              type="text"
              name="X"
              value={values.X}
              onChange={handleChange}
              size="5"
              required
            />
            <span>,</span>
            <Form.Control
              type="text"
              name="Y"
              value={values.Y}
              onChange={handleChange}
              size="5"
              required
            />
            )<span>: +DI above -DI</span>
            <input type="hidden" name="criteria" value={fieldKey} />
            <input type="hidden" name="title" value={attribute.title} />
            <input
              type="hidden"
              name="xml_tmpl"
              value={`<NA><Field>taAbove</Field><StrVal>DMI[%2BDI](${values.X},${values.Y}):DMI[%2DDI](${values.X},${values.Y}):1</StrVal></NA>`}
            />
          </>
        );
      case 'tmpl_ta_adx_bearish':
        // ADX (_X_, _Y_): -DI above +DI
        return (
          <>
            <span>ADX</span>
            (
            <Form.Control
              type="text"
              name="X"
              value={values.X}
              onChange={handleChange}
              size="5"
              required
            />
            <span>,</span>
            <Form.Control
              type="text"
              name="Y"
              value={values.Y}
              onChange={handleChange}
              size="5"
              required
            />
            )<span>: -DI above +DI</span>
            <input type="hidden" name="criteria" value={fieldKey} />
            <input type="hidden" name="title" value={attribute.title} />
            <input
              type="hidden"
              name="xml_tmpl"
              value={`<NA><Field>taAbove</Field><StrVal>DMI[%2DDI](${values.X},${values.Y}):DMI[%2BDI](${values.X},${values.Y}):1</StrVal></NA>`}
            />
          </>
        );
      case 'tmpl_ta_adx_trend':
        // --ADX (_W_, _X_) above _Y_  for  _Z_  Days
        return (
          <>
            <span>ADX</span>
            (
            <Form.Control
              type="text"
              name="W"
              value={values.W}
              onChange={handleChange}
              size="5"
              required
            />
            <span>,</span>
            <Form.Control
              type="text"
              name="X"
              value={values.X}
              onChange={handleChange}
              size="5"
              required
            />
            )<span>above</span>
            <Form.Control
              type="text"
              name="Y"
              value={values.Y}
              onChange={handleChange}
              size="5"
              required
            />
            <span>for </span>
            <Form.Control
              type="text"
              name="Z"
              value={values.Z}
              onChange={handleChange}
              size="5"
              required
            />
            <span>Days</span>
            <input type="hidden" name="criteria" value={fieldKey} />
            <input type="hidden" name="title" value={attribute.title} />
            <input
              type="hidden"
              name="xml_tmpl"
              value={`<NA><Field>taAbove</Field><StrVal>DMI[ADX](${values.W},${values.X},1):${values.Y}:${values.Z}</StrVal></NA>`}
            />
          </>
        );
      case 'tmpl_ta_vol_spike':
        // Volume _X_ times greater than _Y_ days average
        return (
          <>
            <span>Volume</span>
            <Form.Control
              type="text"
              name="Y"
              value={values.Y}
              onChange={handleChange}
              size="5"
              required
            />
            <span>times greater than</span>
            <Form.Control
              type="text"
              name="Z"
              onChange={handleChange}
              size="5"
              value={values.Z}
              required
            />
            <span>days average</span>
            <input type="hidden" name="criteria" value={fieldKey} />
            <input type="hidden" name="title" value={attribute.title} />
            <input
              type="hidden"
              name="xml_tmpl"
              value={`<NA><Field>taAbove</Field><StrVal>VOLUME/${values.Y}:MA(VOLUME,${values.Z}):1</StrVal></NA>`}
            />
          </>
        );
      case 'tmpl_ta_vol_avg':
        // _X_ days average volume greater than _Y_ shares
        return (
          <>
            <Form.Control
              type="text"
              name="X"
              value={values.X}
              onChange={handleChange}
              size="5"
              required
            />
            <span>days average volume greater than</span>
            <Form.Control
              type="text"
              name="Y"
              value={values.Y}
              onChange={handleChange}
              size="5"
              required
            />
            <span>lots</span>
            <input type="hidden" name="criteria" value={fieldKey} />
            <input type="hidden" name="title" value={attribute.title} />
            <input
              type="hidden"
              name="xml_tmpl"
              value={`<NA><Field>taAbove</Field><StrVal>MA(VOLUME,${values.X}):${values.Y}:1</StrVal></NA>`}
            />
          </>
        );
      case 'tmpl_ta_candlestick':
        // _X_ days average volume greater than _Y_ shares
        let hiddenInputVal;
        switch (fieldKey) {
          case 'taCandlestickBearishDarkCloudCover':
            hiddenInputVal =
              '<NA><Field>taBelow</Field><StrVal>CSP_DARKCLOUDCOVER(1):0:1</StrVal></NA>';
            break;
          case 'taCandlestickBearishEngulfing':
            hiddenInputVal = '<NA><Field>taBelow</Field><StrVal>CSP_ENGULFING(1):0:1</StrVal></NA>';
            break;
          case 'taCandlestickBearishEveningStar':
            hiddenInputVal =
              '<NA><Field>taBelow</Field><StrVal>CSP_EVENINGSTAR(1):0:1</StrVal></NA>';
            break;
          case 'taCandlestickBearishHaramiCross':
            hiddenInputVal =
              '<NA><Field>taBelow</Field><StrVal>CSP_HARAMICROSS(1):0:1</StrVal></NA>';
            break;
          case 'taCandlestickBearishHangingMan':
            hiddenInputVal =
              '<NA><Field>taBelow</Field><StrVal>CSP_HANGINGMAN(1):0:1</StrVal></NA>';
            break;
          case 'taCandlestickBearishShootingStar':
            hiddenInputVal =
              '<NA><Field>taBelow</Field><StrVal>CSP_SHOOTINGSTAR(1):0:1</StrVal></NA>';
            break;
          case 'taCandlestickBullishEngulfing':
            hiddenInputVal = '<NA><Field>taAbove</Field><StrVal>CSP_ENGULFING(1):0:1</StrVal></NA>';
            break;
          case 'taCandlestickBullishHammer':
            hiddenInputVal = '<NA><Field>taAbove</Field><StrVal>CSP_HAMMER(1):0:1</StrVal></NA>';
            break;
          case 'taCandlestickBullishHaramiCross':
            hiddenInputVal =
              '<NA><Field>taAbove</Field><StrVal>CSP_HARAMICROSS(1):0:1</StrVal></NA>';
            break;
          case 'taCandlestickBullishInvertedHammer':
            hiddenInputVal =
              '<NA><Field>taAbove</Field><StrVal>CSP_INVERTEDHAMMER(1):0:1</StrVal></NA>';
            break;
          case 'taCandlestickBullishKicking':
            hiddenInputVal = '<NA><Field>taAbove</Field><StrVal>CSP_KICKING:0:1</StrVal></NA>';
            break;
          case 'taCandlestickIndecisionDoji':
            hiddenInputVal = '<NA><Field>taBelow</Field><StrVal>CSP_DOJI(1):0:1</StrVal></NA>';
            break;
          default:
            hiddenInputVal = null;
            break;
        }
        return (
          <>
            <input type="hidden" name="criteria" value={fieldKey} />
            <input type="hidden" name="title" value={attribute.title} />
            <input type="hidden" name="xml_tmpl" value={hiddenInputVal} />
          </>
        );
      case 'tmpl_value_range':
        /*
        More than ____
        Less than ____
        Between ____ to ____
         */
        return (
          <>
            <Form.Select name="operator" onChange={handleChange} value={values.operator} required>
              <option value="GTEQ">more than</option>
              <option value="LTEQ">less than</option>
              <option value="BT">between</option>
            </Form.Select>
            {values.operator === 'BT' ? (
              <>
                <Form.Control
                  type="text"
                  name="GTEQ"
                  value={values.GTEQ}
                  onChange={handleChange}
                  size="5"
                  required
                />
                <span>to</span>
                <Form.Control
                  type="text"
                  name="LTEQ"
                  value={values.LTEQ}
                  onChange={handleChange}
                  size="5"
                  required
                />
              </>
            ) : (
              <Form.Control
                type="text"
                name="val"
                value={values.val}
                onChange={handleChange}
                size="5"
                required
              />
            )}
            {fieldKey === 'faMarketCap' && <span>M</span>}
            <input type="hidden" name="input_val_perc" value="false" />
            <input type="hidden" name="criteria" value={fieldKey} />
            <input type="hidden" name="title" value={attribute.title} />
          </>
        );
      case 'tmpl_value_range_perc':
        /*
        More than ____ %
        Less than ____ %
        Between ____ % to ____ %
         */
        return (
          <>
            <Form.Select name="operator" onChange={handleChange} value={values.operator} required>
              <option value="GTEQ">more than</option>
              <option value="LTEQ">less than</option>
              <option value="BT">between</option>
            </Form.Select>
            {values.operator === 'BT' ? (
              <>
                <Form.Control
                  type="text"
                  name="GTEQ"
                  value={values.GTEQ}
                  onChange={handleChange}
                  size="5"
                  required
                />
                <span>% to</span>
                <Form.Control
                  type="text"
                  name="LTEQ"
                  value={values.LTEQ}
                  onChange={handleChange}
                  size="5"
                  required
                />
                <span>%</span>
              </>
            ) : (
              <>
                <Form.Control
                  type="text"
                  name="val"
                  value={values.val}
                  onChange={handleChange}
                  size="5"
                  required
                />
                <span>%</span>
              </>
            )}
            <input type="hidden" name="input_val_perc" value="true" />
            <input type="hidden" name="criteria" value={fieldKey} />
            <input type="hidden" name="title" value={attribute.title} />
          </>
        );
      case 'tmpl_value_range_perc_display':
        /*
        More than ____ %
        Less than ____ %
        Between ____ % to ____ %
        !! Value not divide by 100
         */
        return (
          <>
            <Form.Select name="operator" onChange={handleChange} value={values.operator} required>
              <option value="GTEQ">more than</option>
              <option value="LTEQ">less than</option>
              <option value="BT">between</option>
            </Form.Select>
            {values.operator === 'BT' ? (
              <>
                <Form.Control
                  type="text"
                  name="GTEQ"
                  value={values.GTEQ}
                  onChange={handleChange}
                  size="5"
                  required
                />
                <span>% to</span>
                <Form.Control
                  type="text"
                  name="LTEQ"
                  value={values.LTEQ}
                  onChange={handleChange}
                  size="5"
                  required
                />
                <span>%</span>
              </>
            ) : (
              <>
                <Form.Control
                  type="text"
                  name="val"
                  value={values.val}
                  onChange={handleChange}
                  size="5"
                  required
                />
                <span>%</span>
              </>
            )}
            <input type="hidden" name="input_val_perc" value="false" />
            <input type="hidden" name="criteria" value={fieldKey} />
            <input type="hidden" name="title" value={attribute.title} />
          </>
        );
      case 'tmpl_price_to_high':
        /*
        is within (5/10/15/20/25/30/35/40/45/50) %
        less than (10/15/20/25/30/35/40/45/50/60/70) %
         */
        return (
          <>
            <Form.Select name="operator" onChange={handleChange} value={values.operator} required>
              <option value="GTEQ_SELECT">is within</option>
              <option value="LTEQ_SELECT">less than</option>
              <option hidden value="LTEQ">
                is within
              </option>
              <option hidden value="GTEQ">
                more than
              </option>
            </Form.Select>
            {(values.operator === 'GTEQ_SELECT' || values.operator === 'GTEQ') && (
              <Form.Select name="GTEQ" value={values.GTEQ} onChange={handleChange} required>
                <option value="0.95">5</option>
                <option value="0.90">10</option>
                <option value="0.85">15</option>
                <option value="0.80">20</option>
                <option value="0.75">25</option>
                <option value="0.70">30</option>
                <option value="0.65">35</option>
                <option value="0.60">40</option>
                <option value="0.55">45</option>
                <option value="0.50">50</option>
              </Form.Select>
            )}
            {(values.operator === 'LTEQ_SELECT' || values.operator === 'LTEQ') && (
              <Form.Select name="LTEQ" value={values.LTEQ} onChange={handleChange} required>
                <option value="0.10">10</option>
                <option value="0.15">15</option>
                <option value="0.20">20</option>
                <option value="0.25">25</option>
                <option value="0.30">30</option>
                <option value="0.35">35</option>
                <option value="0.40">40</option>
                <option value="0.45">45</option>
                <option value="0.50">50</option>
                <option value="0.60">60</option>
                <option value="0.70">70</option>
              </Form.Select>
            )}
            <span>% of {attribute.form_val}</span>

            <input type="hidden" name="criteria" value={fieldKey} />
            <input type="hidden" name="title" value={attribute.title} />
          </>
        );
      case 'tmpl_price_to_low':
        /*
        is within (5/10/15/20/25/30/35/40/45/50) %
        more than (25/50/75/100/125/150/200/250/300/400) %
         */
        return (
          <>
            <Form.Select name="operator" onChange={handleChange} value={values.operator} required>
              <option value="LTEQ_SELECT">is within</option>
              <option value="GTEQ_SELECT">more than</option>
              <option hidden value="LTEQ">
                is within
              </option>
              <option hidden value="GTEQ">
                more than
              </option>
            </Form.Select>
            {(values.operator === 'GTEQ_SELECT' || values.operator === 'GTEQ') && (
              <Form.Select name="GTEQ" value={values.GTEQ} onChange={handleChange} required>
                <option value="1.25">25</option>
                <option value="1.50">50</option>
                <option value="1.75">75</option>
                <option value="2.00">100</option>
                <option value="2.25">125</option>
                <option value="2.50">150</option>
                <option value="3.00">200</option>
                <option value="3.50">250</option>
                <option value="4.00">300</option>
                <option value="5.00">400</option>
              </Form.Select>
            )}
            {(values.operator === 'LTEQ_SELECT' || values.operator === 'LTEQ') && (
              <Form.Select name="LTEQ" value={values.LTEQ} onChange={handleChange} required>
                <option value="1.05">5</option>
                <option value="1.10">10</option>
                <option value="1.15">15</option>
                <option value="1.20">20</option>
                <option value="1.25">25</option>
                <option value="1.30">30</option>
                <option value="1.35">35</option>
                <option value="1.40">40</option>
                <option value="1.45">45</option>
                <option value="1.50">50</option>
              </Form.Select>
            )}
            <span>% of {attribute.form_val}</span>

            <input type="hidden" name="criteria" value={fieldKey} />
            <input type="hidden" name="title" value={attribute.title} />
          </>
        );
      case 'tmpl_yes_no':
        // (Yes/No)
        return (
          <>
            <Form.Select name="operator" onChange={handleChange} value={values.operator} required>
              <option value="EQ">Yes</option>
              <option value="NEQ">No</option>
            </Form.Select>
            <input type="hidden" name="criteria" value={fieldKey} />
            <input type="hidden" name="title" value={attribute.title} />
            <input type="hidden" name="val" value={attribute.form_val} />
          </>
        );
      case 'tmpl_component_category':
        return (
          <>
            {(market === 'sgx' || market === 'bursa') && (
              <>
                <Form.Select name="val" value={values.val} onChange={handleChange} required>
                  {stockCategories[market].map((catGroup) => (
                    <optgroup key={catGroup[0]} label={catGroup[0]}>
                      {catGroup[1].map((cat) => (
                        <option key={cat[1]} value={cat[1]}>
                          {cat[0]}
                        </option>
                      ))}
                    </optgroup>
                  ))}
                </Form.Select>
                <input type="hidden" name="criteria" value={fieldKey} />
                <input type="hidden" name="title" value={attribute.title} />
              </>
            )}
          </>
        );
      case 'tmpl_range_slider':
        return (
          <>
            <RangeSlider
              minTitle="Buy"
              maxTitle="Sell"
              minValue={1}
              maxValue={3}
              fromVal={parseFloat(values.GTEQ) || 1}
              toVal={parseFloat(values.LTEQ) || 1.76}
              handleChange={handleChange}
              step={0.02}
              minInputName="GTEQ"
              maxInputName="LTEQ"
            />

            <input type="hidden" name="criteria" value={fieldKey} />
            <input type="hidden" name="title" value={attribute.title} />
          </>
        );
      case 'tmpl_estimates_perc_for_years':
        /*
        estimates_perc_for_years
        more than ____ % for the next (1/2/3) financial year(s)
        less than ____ % for the next (1/2/3) financial year(s)
        between ____ to ____ % for the next (1/2/3) financial year(s)
         */
        return (
          <>
            <Form.Select name="operator" onChange={handleChange} value={values.operator} required>
              <option value="GTEQ">more than</option>
              <option value="LTEQ">less than</option>
              <option value="BT">between</option>
            </Form.Select>
            {values.operator === 'BT' ? (
              <>
                <Form.Control
                  type="text"
                  name="GTEQ"
                  value={values.GTEQ}
                  onChange={handleChange}
                  size="5"
                  required
                />
                <span>% to</span>
                <Form.Control
                  type="text"
                  name="LTEQ"
                  value={values.LTEQ}
                  onChange={handleChange}
                  size="5"
                  required
                />
                <span>%</span>
              </>
            ) : (
              <>
                <Form.Control
                  type="text"
                  name="val"
                  value={values.val}
                  onChange={handleChange}
                  size="5"
                  required
                />
                <span>%</span>
              </>
            )}
            <span>for the next</span>
            <Form.Select name="year" value={values.year} onChange={handleChange} required>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </Form.Select>
            <span>financial year(s)</span>
            <input type="hidden" name="input_val_perc" value="true" />
            <input type="hidden" name="criteria" value={fieldKey} />
            <input type="hidden" name="title" value={attribute.title} />
          </>
        );
      case 'tmpl_estimates_perc_growth_for_years':
        /*
        estimates_perc_for_years
        more than ____ % for the next (1/2/3) financial year(s)
        less than ____ % for the next (1/2/3) financial year(s)
        between ____ to ____ % for the next (1/2/3) financial year(s)
         */
        return (
          <>
            <Form.Select name="operator" onChange={handleChange} value={values.operator} required>
              <option value="GTEQ">more than</option>
              <option value="LTEQ">less than</option>
              <option value="BT">between</option>
            </Form.Select>
            {values.operator === 'BT' ? (
              <>
                <Form.Control
                  type="text"
                  name="GTEQ"
                  value={values.GTEQ}
                  onChange={handleChange}
                  size="5"
                  required
                />
                <span>% to</span>
                <Form.Control
                  type="text"
                  name="LTEQ"
                  value={values.LTEQ}
                  onChange={handleChange}
                  size="5"
                  required
                />
                <span>%</span>
              </>
            ) : (
              <>
                <Form.Control
                  type="text"
                  name="val"
                  value={values.val}
                  onChange={handleChange}
                  size="5"
                  required
                />
                <span>%</span>
              </>
            )}
            <span>by the next</span>
            <Form.Select name="year" value={values.year} onChange={handleChange} required>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </Form.Select>
            <span>financial year(s)</span>
            <input type="hidden" name="input_val_perc" value="true" />
            <input type="hidden" name="criteria" value={fieldKey} />
            <input type="hidden" name="title" value={attribute.title} />
          </>
        );
      case 'tmpl_estimates_times_for_years':
        /*
        estimates_times_for_years
        more than ____ for the next (1/2/3) financial year(s)
        less than ____ for the next (1/2/3) financial year(s)
        between ____ to ____ for the next (1/2/3) financial year(s)
         */
        return (
          <>
            <Form.Select name="operator" onChange={handleChange} value={values.operator} required>
              <option value="GTEQ">more than</option>
              <option value="LTEQ">less than</option>
              <option value="BT">between</option>
            </Form.Select>
            {values.operator === 'BT' ? (
              <>
                <Form.Control
                  type="text"
                  name="GTEQ"
                  value={values.GTEQ}
                  onChange={handleChange}
                  size="5"
                  required
                />
                <span>to</span>
                <Form.Control
                  type="text"
                  name="LTEQ"
                  value={values.LTEQ}
                  onChange={handleChange}
                  size="5"
                  required
                />
              </>
            ) : (
              <>
                <Form.Control
                  type="text"
                  name="val"
                  value={values.val}
                  onChange={handleChange}
                  size="5"
                  required
                />
                <span>times</span>
              </>
            )}
            <span>for the next</span>
            <Form.Select name="year" value={values.year} onChange={handleChange} required>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </Form.Select>
            <span>financial year(s)</span>
            <input type="hidden" name="input_val_perc" value="false" />
            <input type="hidden" name="criteria" value={fieldKey} />
            <input type="hidden" name="title" value={attribute.title} />
          </>
        );
      default:
        return null;
    }
  };

  const handleActiveCriteriaCheckboxChange = (event) => {
    if (!event) {
      return;
    }

    const { checked, value } = event.target;
    const fIndex = event.target.getAttribute('data-index');
    const updatedCriterias = JSON.parse(JSON.stringify(addedCriterias));
    const criteria = updatedCriterias[fIndex][value];
    criteria.active = checked;

    dispatch(
      setAddedCriterias({
        criteria: updatedCriterias,
      }),
    );
  };

  return (
    <Form
      noValidate
      validated={validated}
      className={`screener_template_form ${attribute.active ? '' : 'disabled'}`}
      onKeyDown={handleKeyDown}>
      <div className="row g-2 justify-content-between align-items-center g-mb-10">
        <div className="col-9 col-lg-3 order-0">
          {orCriteria ? (
            <div className="d-flex gap-1">
              <span className="g-border-radius-20 bg-gray-700 text-white p-1 g-width-22 g-height-22 d-inline-block justify-content-center align-middle line-height-0 g-text-size-12 d-inline-flex align-items-center">
                OR
              </span>
              <span>{attribute.title}</span>
            </div>
          ) : (
            <span>{attribute.title}</span>
          )}
        </div>
        <div className="col-12 col-lg-8 order-last" ref={renderTemplateContainerRef}>
          <div className="d-flex flex-lg-row gap-1 align-items-center flex-wrap">
            {renderTemplate()}
          </div>
        </div>
        <div className="criteria_action col-auto ms-auto order-lg-last order-1 d-flex gap-2 justify-content-end align-items-center ">
          {/* This checkbox is only for Mobile view AND Criteria to disable/enable status */}
          {isMobile && !orCriteria && (
            <input
              type="checkbox"
              onChange={handleActiveCriteriaCheckboxChange}
              checked={attribute.active}
              value={fieldKey}
              data-index={fieldIndex}
            />
          )}
          <OverlayTrigger placement="top" overlay={<Tooltip>Remove</Tooltip>}>
            <Button
              variant="outline-danger"
              size="sm"
              className="g-min-width-auto g-width-23 g-height-23 p-0"
              onClick={(event) => handleRemoveCriteriaButtonClick(event, fieldIndex)}>
              <i className="fa-light fa-trash-can" />
            </Button>
          </OverlayTrigger>
        </div>
      </div>
    </Form>
  );
}

CriteriaTemplateRenderer.propTypes = {
  market: PropTypes.string,
  parentKey: PropTypes.string,
  parentIndex: PropTypes.number,
  fieldKey: PropTypes.string,
  fieldIndex: PropTypes.number,
  attribute: PropTypes.oneOfType([PropTypes.object]),
  stockCategories: PropTypes.oneOfType([PropTypes.object]),
  orCriteria: PropTypes.bool,
  validated: PropTypes.bool,
};

export default CriteriaTemplateRenderer;
