import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';

import styles from './searchQuote.module.css';

export default function StockQuotesMarkets({
  searchResult,
  markets,
  handleMarketsChange,
  allMarkets,
}) {
  return (
    <div className={`quoteMarkets ${styles.quoteMarkets}`}>
      <div className="quoteMarketsHeader">
        <span className="fw-bold g-text-size-18">Stock Quotes</span>
        <span className={`${styles.grayText} float-end pt-1`}>
          {searchResult && searchResult.total} results
        </span>
      </div>
      <ul className={`${styles.quoteMarketsList} px-0 quoteMarketsList`}>
        {allMarkets.map((market) => (
          <li key={market} className={styles.quoteMarketsItem}>
            <input
              type="checkbox"
              id={market}
              value={market}
              checked={markets.includes(market)}
              onChange={handleMarketsChange}
              className={styles.checkBox}
            />
            <label className="g-text-size-14 ms-2" htmlFor={market}>
              {market}
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
}

StockQuotesMarkets.propTypes = {
  searchResult: PropTypes.object,
  markets: PropTypes.array,
  handleMarketsChange: PropTypes.func,
  allMarkets: PropTypes.array,
};
