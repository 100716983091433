import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Nav from 'react-bootstrap/Nav';
import { useDispatch, useSelector } from 'react-redux';
import EditUserTemplatesList from './EditUserTemplatesList';
import { convertPercValue } from './utils';

import { fetchUserTemplates, setAddedCriterias } from './marketScreenerSlice';
import Loader from '../../../components/Loader';

const { CancelToken } = axios;

function LoadTemplates({ market, criteriaConfig }) {
  const dispatch = useDispatch();
  const source = CancelToken.source();
  const [showModal, setShowModal] = useState(false);
  const userTemplatesData = useSelector((state) => state.marketScreener.userTemplatesData);
  const userTemplatesLoading = useSelector((state) => state.marketScreener.userTemplatesLoading);
  const [activeTab, setActiveTab] = useState('predefined_tmpl');
  const [editMode, setEditMode] = useState(false);

  const toggleModal = () =>
    setShowModal((_show) => {
      if (!_show) {
        dispatch(
          fetchUserTemplates({
            cancelToken: source.token,
          }),
        );
      }
      return !_show;
    });

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    handleToggleEdit(false);
  };

  const handleTemplateClick = (event, folder, newTemplateVal) => {
    event.preventDefault();
    const newCriteriasConfig = newTemplateVal.criteria;
    const criteriaList = [];
    newCriteriasConfig.forEach((criteria_group) => {
      if (!criteria_group || criteria_group.length === 0) {
        return;
      }

      // populate OR criterias
      const subCriteriaList = [];
      if (criteria_group.length > 1) {
        criteria_group.slice(1).forEach((or_criteria) => {
          subCriteriaList.push({
            [or_criteria.key]: {
              active: true,
              title:
                criteriaConfig[or_criteria.key].display_name ||
                criteriaConfig[or_criteria.key].title,
              tmpl: criteriaConfig[or_criteria.key].form_tmpl,
              form_val: criteriaConfig[or_criteria.key].form_val,
              market,
              templateValue:
                folder === 'predefined_tmpl'
                  ? convertPercValue(or_criteria, criteriaConfig[or_criteria.key].form_tmpl)
                  : or_criteria,
            },
          });
        });
      }

      // populate AND criterias
      const and_criteria = criteria_group[0];
      criteriaList.push({
        [and_criteria.key]: {
          active: and_criteria.active,
          title:
            criteriaConfig[and_criteria.key].display_name || criteriaConfig[and_criteria.key].title,
          tmpl: criteriaConfig[and_criteria.key].form_tmpl,
          form_val: criteriaConfig[and_criteria.key].form_val,
          market,
          templateValue: convertPercValue(and_criteria, criteriaConfig[and_criteria.key].form_tmpl),
          sub_criterias: subCriteriaList,
        },
      });
    });

    dispatch(
      setAddedCriterias({
        criteria: criteriaList,
      }),
    );
    toggleModal();
  };

  const handleToggleEdit = (mode) => {
    setEditMode(mode);
  };

  useEffect(() => {
    handleToggleEdit(false);
    if (!Object.keys(userTemplatesData).includes('user_tmpl')) {
      handleTabClick('predefined_tmpl');
    }
  }, [userTemplatesData]);

  return (
    <>
      <Button variant="light" className="p-3 px-4" onClick={toggleModal}>
        <i className="fa-xl text-primary fa-solid fa-upload g-mr-10" />
        Load Templates
      </Button>
      <Modal show={showModal} centered onHide={toggleModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Load Templates</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {userTemplatesLoading && Object.keys(userTemplatesData).length === 0 ? (
            <Loader visible={userTemplatesLoading} />
          ) : (
            <Tab.Container id="user_templates" activeKey={activeTab}>
              <div className="row g-3">
                <div className="col-lg-3 g-lg-border-r-1 g-border-b-1 g-lg-border-b-0 g-pb-10">
                  <Nav variant="pills" className="flex-column design-3">
                    {Object.keys(userTemplatesData).map((k) => (
                      <Nav.Item key={k} className="g-mb-5">
                        <Nav.Link eventKey={k} onClick={() => handleTabClick(k)}>
                          <div className="d-flex gap-2 align-items-center lh-sm">
                            <i className={`${userTemplatesData[k].icon} fa-xl g-width-24`} />{' '}
                            {userTemplatesData[k].title}
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                </div>
                <div className="col-lg-9">
                  <Tab.Content>
                    {Object.entries(userTemplatesData).map(([k, v]) => {
                      return (
                        <Tab.Pane
                          key={k}
                          eventKey={k}
                          className="design-3 g-lg-height-300 g-lg-max-height-300 g-lg-height-450 g-lg-max-height-450 overflow-auto">
                          {k === 'user_tmpl' ? (
                            editMode ? (
                              <EditUserTemplatesList
                                templates={v.templates}
                                handleToggleEdit={handleToggleEdit}
                              />
                            ) : (
                              <>
                                {v.templates.map((template) => (
                                  <Nav.Item key={template.key} className="g-mb-5">
                                    <Nav.Link
                                      onClick={(e) => handleTemplateClick(e, k, template)}
                                      className="">
                                      {template.title}
                                    </Nav.Link>
                                  </Nav.Item>
                                ))}
                                <div className="d-flex gap-3 align-items-center justify-content-end">
                                  <Button variant="primary" onClick={() => handleToggleEdit(true)}>
                                    Edit
                                  </Button>
                                </div>
                              </>
                            )
                          ) : (
                            <>
                              {v.templates.map((template) => (
                                <Nav.Item key={template.key} className="g-mb-5">
                                  <Nav.Link
                                    onClick={(e) => handleTemplateClick(e, k, template)}
                                    className="">
                                    {template.title}
                                  </Nav.Link>
                                </Nav.Item>
                              ))}
                            </>
                          )}
                        </Tab.Pane>
                      );
                    })}
                  </Tab.Content>
                </div>
              </div>
            </Tab.Container>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

LoadTemplates.propTypes = {
  market: PropTypes.string,
  criteriaConfig: PropTypes.oneOfType([PropTypes.object]),
};

export default LoadTemplates;
