import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import classes from './FactsheetCollapseTableRows.module.css';

function FactsheetCollapseHeader({ headers, showAll, toggleShowAll, showSparklines, isConsensus }) {
  return (
    <>
      <colgroup>
        <col className="g-per-width-40" />
        {showSparklines && <col className="g-width-40" />}
        {headers.map((item) => (
          <col key={item.date_time_f} className="g-width-70" />
        ))}
      </colgroup>
      <thead className="table-light">
        <tr>
          <th className="text-start fixedColumn">
            {!isConsensus && (
              <div className="form-check form-switch">
                <label
                  className="form-check-label g-fw-500 g-text-size-15"
                  htmlFor="sic_displayMode">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="sic_displayMode"
                    checked={showAll}
                    onChange={toggleShowAll}
                  />
                  Shows All % Growth
                </label>
              </div>
            )}
          </th>
          {showSparklines && <th className="g-fw-500 g-text-size-15 text-center">Trend</th>}
          {headers.map((item) => {
            return (
              <th
                key={item.date_time_f}
                className={`g-fw-500 g-text-size-15 text-center g-fw-500 g-text-size-15 text-center ${
                  isConsensus && item.is_estimates === true ? 'text-white bg-gray-600' : ''
                }`}>
                {item.period_f}
                <br />
                {item.date_time_f}
                {isConsensus && (
                  <>
                    <br />
                    {!item.is_estimates && (
                      <span
                        className={`g-text-size-14 text-success ${classes.headerActualWrapper}`}>
                        Actual
                      </span>
                    )}
                    {item.is_estimates && (
                      <span className={`g-text-size-14 ${classes.headerEstimatesWrapper}`}>
                        Estimates
                      </span>
                    )}
                  </>
                )}
              </th>
            );
          })}
        </tr>
      </thead>
    </>
  );
}

FactsheetCollapseHeader.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      date_time_f: PropTypes.string,
      period_f: PropTypes.string,
      period_on_percent_growth: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
      value_f: PropTypes.string,
    }),
  ),
  showAll: PropTypes.bool,
  toggleShowAll: PropTypes.func,
  showSparklines: PropTypes.bool,
  isConsensus: PropTypes.bool,
};

export default FactsheetCollapseHeader;
