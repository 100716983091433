import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'react-bootstrap/Alert';

export default function APIErrorHandler({ error, classes, message_ }) {
  if (!error) {
    return null;
  }

  let message = message_;
  let link;

  if (
    error.response &&
    error.response.status === 404 &&
    error.response.data &&
    error.response.data.redirect_to
  ) {
    window.location.replace(error.response.data.redirect_to);
  }

  if (error.response && error.response.status === 401) {
    if (error.response.data && error.response.data.message) {
      message = error.response.data.message;
    } else {
      message = 'You do not have access to this content.';
    }

    if (error.response.data && error.response.data.redirect_to) {
      link = error.response.data.redirect_to;
    } else {
      link = '/user/login';
    }

    setTimeout(function () {
      window.location.replace(link);
    }, 1000);

    return (
      <div className={`text-center ${classes}`}>
        <h4>{message}</h4>
        {link && <a href={link}>Redirecting...</a>}
      </div>
    );
  }

  if (error && error.message && error.message.length > 0) {
    message = error.message;
  }

  return (
    <Alert variant="danger" className={classes}>
      {message}
    </Alert>
  );
}

APIErrorHandler.propTypes = {
  error: PropTypes.object,
  classes: PropTypes.string,
  message_: PropTypes.string,
};
