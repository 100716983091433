import React, { useEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5hierarchy from '@amcharts/amcharts5/hierarchy';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import md5 from 'md5';

import { getParamCounterId } from '../../../../utils/utils';
import classes from '../../portfolio.module.css';

const LOGO_BASE = 'https://coylogos.s3.ap-southeast-1.amazonaws.com/d/';

// There are five variations of red, with progressive 20% loss for each of the color shade
// (1-20% loss for the first red shade, 21-40% loss for the second red shade and so forth until 80-100% loss)
// and 6 colors for green (similar arrangement as red color, but the 6th color shade will be more than 100% gain)
// The grey color will be 0% P/L

const calculateFillColor = (plPercentage) => {
  if (plPercentage === 0) {
    // Grey color for 0% P/L
    return am5.color(0xcddcd2);
  }
  if (plPercentage < -80) {
    return am5.color(0xff0000);
  }
  if (plPercentage >= -80 && plPercentage <= -60) {
    return am5.color(0xf4324f);
  }
  if (plPercentage > -60 && plPercentage <= -40) {
    return am5.color(0xf54e67);
  }
  if (plPercentage > -40 && plPercentage <= -20) {
    return am5.color(0xfb768a);
  }
  if (plPercentage > -20 && plPercentage <= 0) {
    return am5.color(0xfc96a2);
  }
  if (plPercentage > 0 && plPercentage <= 20) {
    return am5.color(0x34f083);
  }
  if (plPercentage > 20 && plPercentage <= 40) {
    return am5.color(0x19d768);
  }
  if (plPercentage > 40 && plPercentage <= 60) {
    return am5.color(0x14c558);
  }
  if (plPercentage > 60 && plPercentage <= 80) {
    return am5.color(0x05b64d);
  }
  if (plPercentage > 80 && plPercentage <= 100) {
    return am5.color(0x2aa300);
  }
  return am5.color(0x228200);
};

function PositionsTreemapChart({ portfolioCounters }) {
  useEffect(() => {
    const root = am5.Root.new('chartdiv');

    root.setThemes([am5themes_Animated.new(root)]);

    // Create wrapper container
    const container = root.container.children.push(
      am5.Container.new(root, {
        width: am5.percent(100),
        height: am5.percent(100),
        layout: root.verticalLayout,
      }),
    );

    // Create series
    const series = container.children.push(
      am5hierarchy.Treemap.new(root, {
        singleBranchOnly: true,
        valueField: 'weightagePercentage', // Use the allocation percentage as the value field
        categoryField: 'name',
        childDataField: 'children',
        nodePaddingOuter: 0,
        nodePaddingInner: 1,
        legendLabelText: '{category}',
        legendValueText: '{value}',
      }),
    );

    // tooltip
    const tooltip = am5.Tooltip.new(root, {
      getLabelFillFromSprite: true,
      layer: 100,
      labelText:
        '[bold]{name}[/]{counterCodeLabel}:[fontSize: 12px]\n\nP/L: {profitLose}\nP/L: {profitLosePerc}%\nWeightage: {weightage}%[/]',
    });

    series.set('tooltip', tooltip);

    // calculate Fill Color for each counter
    series.rectangles.template.adapters.add('fill', function (fill, target) {
      return calculateFillColor(parseFloat(target.dataItem.dataContext.profitLosePerc));
    });

    series.labels.template.setAll({
      text: '[bold]{name}[/]\n[fontSize: 18px]{profitLosePerc}%[/]',
      fill: am5.color(0x000000),
      fontSize: 25,
    });

    function extractFirstNameFromAnchor(anchorHtml) {
      const startIndex = anchorHtml.indexOf('<'); // Find the index of the first '<'
      const name = startIndex !== -1 ? anchorHtml.slice(0, startIndex).trim() : anchorHtml.trim();
      return name;
    }

    // Generate and set data
    const data = {
      name: '',
      children: portfolioCounters.map((counter) => {
        const lastIndex = counter.counter.lastIndexOf('.');
        let symbol = '';
        let market = '';

        if (lastIndex !== -1) {
          symbol = counter.counter.substring(0, lastIndex);
          market = counter.counter.substring(lastIndex + 1);
        }

        const logo_url = LOGO_BASE + md5(`coylogos://${market}/${symbol}`);
        return {
          name: extractFirstNameFromAnchor(counter.Name),
          weightagePercentage: parseFloat(counter.Weightage.replace('%', '')),
          profitLosePerc: counter['P/L'].substring(
            counter['P/L'].indexOf('(') + 1,
            counter['P/L'].indexOf('%'),
          ),
          profitLose: counter['P/L'].substring(0, counter['P/L'].indexOf(' (')),
          weightage: counter.Weightage.replace('%', ''),
          counterCodeLabel: `(${getParamCounterId(counter.counter)})`,
          logo_url,
        };
      }),
    };

    series.data.setAll([data]);

    series.set('selectedDataItem', series.dataItems[0]);

    // this to add the logo of the company , currently facing CORS error due to amchart limitation
    // https://www.amcharts.com/docs/v5/concepts/common-elements/images/#Cross_domain_issues

    // series.bullets.push(function (root, series, dataItem) {
    //  const depth = dataItem.get('depth');
    //
    //  if (depth === 1) {
    //    const lastIndex = counter.lastIndexOf('.');
    //    let symbol = '';
    //    let market = '';
    //    if (lastIndex !== -1) {
    //      symbol = counter.substring(0, lastIndex);
    //      market = counter.substring(lastIndex + 1);
    //    }
    //   const logo = LOGO_BASE + md5(`coylogos://${market}/${symbol}`);
    //   const picture = am5.Picture.new(root, {
    //     src: dataItem.dataContext.logo_url,
    //     centerX: am5.p50,
    //     centerY: am5.p50,
    //     width: am5.percent(30),
    //     isMeasured: true,
    //   });
    //
    //   picture.states.lookup('default').setAll({ opacity: 0.15 });
    //
    //   return am5.Bullet.new(root, { sprite: picture });
    //  }
    // });

    // Make stuff animate on load
    series.appear(1000, 100);

    return () => {
      // Cleanup on component unmount
      if (root) {
        root.dispose();
      }
    };
  }, [portfolioCounters]);

  return (
    <>
      <ColorLegend />
      <div id="chartdiv" className="g-per-width-100 g-height-500" />
    </>
  );
}

export default PositionsTreemapChart;

function ColorLegend() {
  const legendColors = [
    { label: '80-100% Loss', color: calculateFillColor(-100) },
    { label: '60-80% Loss', color: calculateFillColor(-80) },
    { label: '40-60% Loss', color: calculateFillColor(-60) },
    { label: '20-40% Loss', color: calculateFillColor(-40) },
    { label: '0-20% Loss', color: calculateFillColor(-20) },
    { label: '0% P/L', color: calculateFillColor(0) },
    { label: '0-20% Gain', color: calculateFillColor(20) },
    { label: '20-40% Gain', color: calculateFillColor(40) },
    { label: '40-60% Gain', color: calculateFillColor(60) },
    { label: '60-80% Gain', color: calculateFillColor(80) },
    { label: '80-100% Gain', color: calculateFillColor(100) },
    { label: '100%+ Gain', color: calculateFillColor(110) },
  ];

  legendColors.forEach((item) => {
    item.color = `${am5.color(item.color).toString(16)}`;
  });

  return (
    <div className="legend-container">
      <div className="legend-items">
        {legendColors.map(({ label, color }, index) => (
          <OverlayTrigger
            key={index}
            placement="top"
            overlay={<Tooltip id={`tooltip-${index}`}>{label}</Tooltip>}>
            <span
              className={`${classes.treeMapLegendStyle}`}
              style={{
                backgroundColor: color,
              }}
            />
          </OverlayTrigger>
        ))}
      </div>
    </div>
  );
}
