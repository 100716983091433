import { createSlice } from '@reduxjs/toolkit';
import api from '../../utils/api';

const stockPricesSlice = createSlice({
  name: 'stockPrices',
  initialState: {
    filtersInitialized: false,
    filters: {
      tab: null,
      filter: null,
      type: null,
      layout: null,
      page: null,
      market: null,
    },
    data: null,
    selectedstocks: [],
  },
  reducers: {
    stockPricesFetchStart(state, action) {
      state.data = null;
      state.err = null;
      state.completed = false;
      state.loading = true;
    },
    stockPricesFetchSuccess(state, action) {
      state.data = action.payload.data;
      state.completed = true;
      state.loading = false;
    },
    stockPricesFetchFail(state, action) {
      state.err = action.payload.message;
      state.completed = false;
      state.loading = false;
    },
    stockPricesUpdateFilters(state, action) {
      const newFilters = action.payload.filters;
      state.filters.tab = newFilters.tab ? newFilters.tab : '';
      state.filters.filter = newFilters.filter ? newFilters.filter : '';
      state.filters.type = newFilters.type ? newFilters.type : '';
      state.filters.layout = newFilters.layout ? newFilters.layout : '';
      state.filters.page = newFilters.page ? newFilters.page : '';
      state.filters.market = newFilters.market ? newFilters.market : '';
      state.filtersInitialized = true;
    },
    stockPricesAddSelectedStocks(state, action) {
      state.selectedstocks.push(action.payload);
    },
    stockPricesRemoveSelectedStocks(state, action) {
      const index = state.selectedstocks.indexOf(action.payload);
      if (index > -1) {
        state.selectedstocks.splice(index, 1);
      }      
    },
  },
});

export default stockPricesSlice.reducer;

const {
  stockPricesFetchStart,
  stockPricesFetchSuccess,
  stockPricesFetchFail,
  stockPricesUpdateFilters,
} = stockPricesSlice.actions;

export const { stockPricesAddSelectedStocks, stockPricesRemoveSelectedStocks } = stockPricesSlice.actions;

export const fetchData =
  ({ filters, cancelToken }) =>
  (dispatch) => {
    dispatch(stockPricesFetchStart());
    api
      .get(
        `prices/stock_prices.json?tab=${filters.tab}&filter=${filters.filter}&type=${filters.type}&layout=${filters.layout}&page=${filters.page}&market=${filters.market}`,
        { cancelToken },
      )
      .then(({ data }) => {
        dispatch(stockPricesFetchSuccess({ data }));
      })
      .catch((error) => {
        dispatch(stockPricesFetchFail(error));
      });
  };

export const setFilters =
  ({ filters }) =>
  (dispatch) => {
    if (filters) {
      dispatch(stockPricesUpdateFilters({ filters }));
    }
  };
