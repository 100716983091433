import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fetchData as fetchAnalysisToolsData } from '../../../../components/AnalysisToolsButton/analysisToolsSlice';
import { createLoadableComponent } from '../../../../utils/Loadable';

const AllocationPieChart = createLoadableComponent(() =>
  import('../../../../components/AllocationPieChart'),
);
const { CancelToken } = axios;

export default function Sector() {
  const dispatch = useDispatch();
  const source = CancelToken.source();
  const selectedPortfolio = useSelector((state) => state.portfolio.selected);
  const countryMarket = useSelector((state) => state.portfolio.countryMarket);
  const data = useSelector((state) => state.analysisTools.data);
  const loading = useSelector((state) => state.analysisTools.loading);
  const [currentCounters, setCurrentCounters] = useState([]);

  useEffect(() => {
    if (selectedPortfolio?.counters?.length > 0) {
      // only get from open positions
      const counters = selectedPortfolio.counters
        .filter((c) => c['Shares Held'] && c['Shares Held'] !== '-')
        .map((c) => c.counter)
        .sort();
      if (JSON.stringify(counters) !== JSON.stringify(currentCounters)) {
        dispatch(
          fetchAnalysisToolsData({
            counters: counters.join(':'),
            market: countryMarket,
            analysisToolsType: 'sector_allocation',
            cancelToken: source.token,
          }),
        );
        setCurrentCounters(counters);
      } else {
        setCurrentCounters([]);
      }
    }
  }, [selectedPortfolio]);

  if (!(currentCounters.length > 0 && data?.allocation_sector?.length > 0)) {
    return <div className="text-center">No allocation data available.</div>;
  }

  return (
    <>
      {loading && (
        <div className="g-text-size-18 g-lg-text-size-30 text-center">
          <i className="fa-duotone fa-spinner-third fa-spin"></i>
        </div>
      )}
      {!loading && (
        <div className="g-mt-15 g-mb-15 g-height-450">
          <AllocationPieChart
            data={data.allocation_sector}
            percentInnerRadius={55}
            percentHeight={95}
          />
        </div>
      )}
    </>
  );
}
