import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  createEntityAdapter,
} from '@reduxjs/toolkit';

import api from '../../../utils/api';
import { Constants } from '../../../utils/constants';

/* Routes */
const membershipProfileRoute = 'membership/profile.json';
const membershipPlanRoute = 'membership/plan.json';
const membershipPaymentHistoryRoute = 'membership/payment_history.json';
const userUploadPhotoRoute = 'user/upload_photo';

/* AsyncThunk - fetchMembershipProfile */
export const fetchMembershipProfile = createAsyncThunk(
  'membership/fetchMembershipProfile',
  async ({ cancelToken }) => {
    const response = await api.get(membershipProfileRoute, { cancelToken, responseType: 'json' });
    const responseData = response.data;
    return responseData;
  },
);

export const uploadProfilePhoto = createAsyncThunk(
  'membership/uploadProfilePhoto',
  async ({ imageFile, cancelToken }) => {
    const formData = new FormData();
    formData.append('profile_picture', imageFile);

    const response = await api.post(userUploadPhotoRoute, formData, {
      cancelToken,
      responseType: 'json',
    });
    const responseData = response.data;
    return responseData;
  },
);

export const updateProfileDetail = createAsyncThunk(
  'membership/updateProfileDetail',
  async ({ firstName, lastName, birthDate, captchaToken, cancelToken }) => {
    const response = await api.patch(
      membershipProfileRoute,
      {
        user: {
          firstName,
          lastName,
          birthDate,
        },
        recaptcha_token: captchaToken,
      },
      { cancelToken, responseType: 'json' },
    );
    const responseData = response.data;
    return responseData;
  },
);

/* AsyncThunk - fetchPlan */
export const fetchMembershipPlan = createAsyncThunk(
  'membership/fetchMembershipPlan',
  async ({ cancelToken }) => {
    const response = await api.get(membershipPlanRoute, {
      cancelToken,
      responseType: 'json',
    });
    const responseData = response.data;
    return responseData;
  },
);

/* AsyncThunk - fetchPaymentHistory */
export const fetchPaymentHistory = createAsyncThunk(
  'membership/fetchPaymentHistory',
  async ({ cancelToken }) => {
    const response = await api.get(membershipPaymentHistoryRoute, {
      cancelToken,
      responseType: 'json',
    });
    const responseData = response.data;
    return responseData;
  },
);

/* Membership Profile Initial State */
const membershipProfileInitialState = {
  siteKey: '',
  performCheck: true,
  userId: '',
  firstName: '',
  lastName: '',
  birthMonth: '',
  birthYear: '',
  mobile: '',
  email: '',
  profilePicture: '',
  status: Constants.STATUS_IDLE,
  showSuccess: {},
  error: undefined,

  /* Update Details */
  updateDetailsStatus: Constants.STATUS_IDLE,
  updateDetailsError: undefined,

  /* Email */
  updateEmailStatus: Constants.STATUS_IDLE,
  updateEmailError: undefined,

  /* Password */
  updatePasswordStatus: Constants.STATUS_IDLE,
  updatePasswordError: undefined,
};

const membershipPlanInitialState = {
  plan_name: '',
  status: Constants.STATUS_IDLE,
  error: undefined,
};

/* Payment History Adapter */
const paymentHistoriesAdapter = createEntityAdapter({
  selectId: (entity) => entity.id,
});

/* Payment History Initial State */
const paymentHistoriesInitialState = paymentHistoriesAdapter.getInitialState({
  pagination: {
    rowOffset: 0,
    currentPageRows: 0,
    totalRows: 0,
    currentPage: 1,
    totalPages: 0,
  },
  status: Constants.STATUS_IDLE,
  error: undefined,
});

/* Profile Slice */
const membershipProfileSlice = createSlice({
  name: 'membershipProfile',
  initialState: membershipProfileInitialState,
  reducers: {
    setSiteKey: (state, action) => {
      state.siteKey = action.payload;
    },
    setPerformCheck: (state, action) => {
      state.performCheck = action.payload;
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setFieldValue: (state, action) => {
      const { fieldName, value } = action.payload;
      state[fieldName] = value;
    },
    hideSuccessLabel: (state) => {
      state.showSuccess = {};
    },
    showSuccessLabel: (state, action) => {
      const { showSuccess } = state;
      showSuccess[action.payload] = true;
      state.showSuccess = showSuccess;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMembershipProfile.pending, (state) => {
      state.status = Constants.STATUS_LOADING;
    });
    builder.addCase(fetchMembershipProfile.fulfilled, (state, action) => {
      state.status = Constants.STATUS_SUCCESS;
      /* Insert data here */
      const { payload } = action;
      if (payload.success) {
        state.firstName = payload.data.firstName;
        state.lastName = payload.data.lastName;
        state.mobile = payload.data.mobile;
        state.email = payload.data.email;
        state.profilePicture = payload.data.profilePicture;

        /* break into month and year */
        let month = '';
        let startingYear = '';
        const parts = payload.data.birthDate ? payload.data.birthDate.split(' ') : [];
        month = parts[0] ?? '';
        const yearRange = parts[1] ?? '';
        startingYear = yearRange.includes('-') ? yearRange.split('-')[0] : '';

        state.birthMonth = month;
        state.birthYear = startingYear;
      }
    });
    builder.addCase(fetchMembershipProfile.rejected, (state, action) => {
      state.status = Constants.STATUS_FAILED;
      state.error = action.error.message;
    });
    builder.addCase(uploadProfilePhoto.pending, (state) => {
      state.status = Constants.STATUS_LOADING;
    });
    builder.addCase(uploadProfilePhoto.fulfilled, (state, action) => {
      state.status = Constants.STATUS_SUCCESS;
      const { payload } = action;
      if (payload.success) {
        state.profilePicture = payload.data;
      }
    });
    builder.addCase(uploadProfilePhoto.rejected, (state, action) => {
      state.status = Constants.STATUS_FAILED;
      state.error = action.error.message;
    });
    builder.addCase(updateProfileDetail.pending, (state) => {
      state.updateDetailsError = '';
      state.updateDetailsStatus = Constants.STATUS_LOADING;
    });
    builder.addCase(updateProfileDetail.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.updateDetailsStatus = Constants.STATUS_SUCCESS;
        state.showSuccess.updateProfileDetail = true;
      } else {
        state.updateDetailsStatus = Constants.STATUS_FAILED;
        state.updateDetailsError = action.payload.message;
      }
    });
    builder.addCase(updateProfileDetail.rejected, (state, action) => {
      state.updateDetailsStatus = Constants.STATUS_FAILED;
      state.updateDetailsError = action.error.message;
    });
  },
});

/* Plan Slice */
const membershipPlanSlice = createSlice({
  name: 'membershipPlan',
  initialState: membershipPlanInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMembershipPlan.pending, (state) => {
      state.status = Constants.STATUS_LOADING;
    });
    builder.addCase(fetchMembershipPlan.fulfilled, (state, action) => {
      state.status = Constants.STATUS_SUCCESS;
    });
    builder.addCase(fetchMembershipPlan.rejected, (state, action) => {
      state.status = Constants.STATUS_FAILED;
      state.error = action.error.message;
    });
  },
});

/* Payment History Slice */
const paymentHistoriesSlice = createSlice({
  name: 'paymentHistories',
  initialState: paymentHistoriesInitialState,
  reducers: {
    setCurrentPage(state, action) {
      state.pagination.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPaymentHistory.pending, (state) => {
      state.status = Constants.STATUS_LOADING;
    });
    builder.addCase(fetchPaymentHistory.fulfilled, (state, action) => {
      state.status = Constants.STATUS_SUCCESS;
      if (action.payload.success) {
        paymentHistoriesAdapter.setAll(state, action.payload.data);
      } else {
        state.error = action.payload.message;
        state.status = Constants.STATUS_FAILED;
      }
    });
    builder.addCase(fetchPaymentHistory.rejected, (state, action) => {
      state.status = Constants.STATUS_FAILED;
      state.error = action.error.message;
    });
  },
});

/* Profile */
export const {
  setSiteKey,
  setPerformCheck,
  setUserId,
  setFieldValue,
  hideSuccessLabel,
  showSuccessLabel,
} = membershipProfileSlice.actions;
export const selectMembershipProfile = (state) => state.membershipProfile;

/* Plan */
export const selectMembershipPlan = (state) => state.membershipPlan;

/* Payment History */
export const selectPaymentHistoriesPagination = (state) => state.paymentHistories.pagination;
export const { selectAll: selectPaymentHistories } = paymentHistoriesAdapter.getSelectors(
  (state) => state.paymentHistories,
);

/* Reducers */
export const membershipProfileReducer = membershipProfileSlice.reducer;
export const membershipPlanReducer = membershipPlanSlice.reducer;
export const paymentHistoriesReducer = paymentHistoriesSlice.reducer;
