import React, { useEffect, Fragment } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

import { useDetectMobileScreen } from '../../utils/utils';
import classes from './membership.module.css';

function Header({ title, subtitle, step }) {
  const isMobile = useDetectMobileScreen();

  const STEPS = {
    'Select Plans': 'fa-regular fa-table-columns fa-xl',
    'Payment Options': 'fa-regular fa-dollar-sign fa-xl',
    Complete: 'fa-regular fa-check fa-xl',
  };

  return (
    <div className={`jumbotron bg-cover text-white ${classes.headerBanner}`}>
      <div className={`container text-center ${classes.headerBannerContainer}`}>
        <h1>
          {title}
          {!title && (
            <>
              <span>Get Started Today. </span>
              <span className="text-primary">Try for Free!</span>
            </>
          )}
        </h1>
        {subtitle && <h4 className="g-pt-15">{subtitle}</h4>}

        {step && (
          <div className="container d-flex justify-content-center align-items-center g-mt-25">
            <div className={classes.processProgress}>
              {Object.keys(STEPS).map((stepKey, index) => (
                <Fragment key={stepKey}>
                  <div
                    className={`${classes.processProgressStep} ${
                      step === stepKey ? classes.processProgressStepActive : ''
                    } position-relative`}>
                    <span>
                      <i className={STEPS[stepKey]} />
                    </span>
                    <div
                      className={`${classes.headerBannerStepName} ${
                        isMobile ? 'g-width-80 g-text-size-18' : 'g-width-150 g-text-size-16'
                      }  ${step === stepKey ? 'text-white' : ''} fw-bold`}>
                      {stepKey}
                    </div>
                  </div>

                  {index !== Object.keys(STEPS).length - 1 && (
                    <span
                      className={`${classes.processProgressSeparator} ${
                        isMobile ? 'g-width-100' : 'g-width-200'
                      }`}
                    />
                  )}
                </Fragment>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Header;

Header.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  step: PropTypes.string,
};
