import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';

import api from '../../../../utils/api';
import { formatNumberAddCommas, formatDecimal, getParamCounterId } from '../../../../utils/utils';
import { createTransactionFetchData } from '../Transactions/transactionSlice';
import { calculateBrokerFeeCharges } from '../Transactions/Brokers';
import { getCurrencyName } from '../../currencies';

const { CancelToken } = axios;

export default function ClosePositionModal({ show, handleCloseModal, position }) {
  const dispatch = useDispatch();
  const source = CancelToken.source();
  const brokers = useSelector((state) => state.portfolioBroker.brokers);
  const [quantity, setQuantity] = useState(position?.quantity || 0);
  const [sellPrice, setSellPrice] = useState(position?.sellPrice || 0);
  const [currencyExchange, setCurrencyExchange] = useState({});
  const [folderCurrency, setFolderCurrency] = useState('');
  const [counterCurrency, setCounterCurrency] = useState('');
  const [exchangeRate, setExchangeRate] = useState(1);
  const [selectedBroker, setSelectedBroker] = useState({});
  const [commission, setCommission] = useState(0);
  const [txnDate, setTxnDate] = useState('');
  const [txnRemarks, setTxnRemarks] = useState('');
  const [grossValue, setGrossValue] = useState('');

  useEffect(() => {
    // init txnDate
    setTxnDate(
      `${`${new Date().toLocaleDateString('en-CA')}T${new Date()
        .toLocaleTimeString('en-CA', { hour12: false })
        .slice(0, 5)}`}`,
    );
    // retrieve currencyExchange data
    const portfolioID = position?.portfolioID;
    const counter = position?.counter;
    if (!(portfolioID && counter)) {
      return;
    }
    api
      .get(`/portfolio/${portfolioID}/get_price_and_exchange_price?counter=${counter}`, {
        cancelToken: source.token,
      })
      .then(({ data }) => {
        setCurrencyExchange(data);
      })
      .catch((error) => {
        console.log(
          '[ClosePositionModal] Failed to retrieve currencyExchange data due to error',
          error,
        );
      });
  }, []);

  useEffect(() => {
    if (currencyExchange.folder_currency && folderCurrency !== currencyExchange.folder_currency) {
      setFolderCurrency(currencyExchange.folder_currency);
    }
    if (
      currencyExchange.counter_currency &&
      counterCurrency !== currencyExchange.counter_currency
    ) {
      setCounterCurrency(currencyExchange.counter_currency);
    }
    if (currencyExchange.exchange_rate && exchangeRate !== currencyExchange.exchange_rate) {
      setExchangeRate(currencyExchange.exchange_rate);
    }
  }, [currencyExchange]);

  const handleBrokerChange = (brokerID) => {
    const broker = brokers.find((b) => b.Id === parseInt(brokerID, 10));
    setSelectedBroker(broker);
  };

  useEffect(() => {
    if (selectedBroker?.Id) {
      setCommission(calculateBrokerFeeCharges(sellPrice, quantity, selectedBroker, exchangeRate));
    }
  }, [selectedBroker]);

  // gross value: commission subtracted from value for SELL transaction
  useEffect(() => {
    const grossValue = quantity * sellPrice * exchangeRate - commission;
    setGrossValue(formatNumberAddCommas(formatDecimal(grossValue, 3, true)));
  }, [commission, quantity, sellPrice, exchangeRate]);

  const handleClose = () => {
    handleCloseModal();
    setQuantity(0);
    setSellPrice(0);
    setTxnDate('');
    setCurrencyExchange({});
  };

  const handleSave = () => {
    const txnValue = parseFloat(grossValue.replaceAll(',', '')) || 0;
    if (txnValue > 0) {
      const folder = position.portfolioID;
      const counter = position.counter;
      const selectedLayout = position.selectedLayout;
      const activity = 1; // buy 0 , sell 1
      const price = sellPrice;
      const date = txnDate;
      const remarks = txnRemarks;
      const broker = selectedBroker.Id;
      dispatch(
        createTransactionFetchData({
          exchangeRate,
          quantity,
          price,
          commission,
          counter,
          activity,
          date,
          broker,
          folder,
          remarks,
          selectedLayout,
          cancelToken: source.token,
        }),
      );
    }

    handleClose();
  };

  return (
    <Modal show={show} centered dialogClassName="g-text-size-14" onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Close Position</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="row mt-2">
          <h6 className="g-mb-10">
            Stock:
            {` ${position.name} (${getParamCounterId(position.counter)})`}
          </h6>
          <div className="row g-mb-20">
            {/* Action: SELL */}
            <div className="col-6">
              <Form.Group>
                <Form.Label>
                  <b>Action</b>
                </Form.Label>
                <div>
                  <input type="radio" className="btn-check" autoComplete="off" checked={true} />
                  <label
                    className="btn btn-sm btn-outline-danger active white-active"
                    htmlFor="danger-outlined">
                    Sell
                  </label>
                </div>
              </Form.Group>
            </div>
            {/* Transaction Date */}
            <div className="col-6">
              <Form.Group>
                <Form.Label>
                  <b>Transaction Date</b>
                </Form.Label>
                <Form.Control
                  type="datetime-local"
                  value={txnDate}
                  onChange={(e) => setTxnDate(e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please choose a valid date.
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>
          <div className="row g-mb-20">
            {/* Quantity */}
            <div className="col-6">
              <Form.Group>
                <Form.Label>
                  <b>Quantity</b>
                </Form.Label>
                <Form.Control
                  type="number"
                  placeholder="eg. 1000"
                  min="1"
                  defaultValue={quantity}
                  disabled={true}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Quantity must be a positive number.
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            {/* Sell Price */}
            <div className="col-6">
              <Form.Group>
                <Form.Label>
                  <b>Unit/Share Price</b>
                </Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    type="number"
                    min="0.001"
                    step="0.001"
                    placeholder="eg. 1.23"
                    value={sellPrice}
                    required
                    onChange={(e) => setSellPrice(Number(e.target.value) || 0)}
                  />
                  <InputGroup.Text id="basic-addon3" className="g-text-size-14">
                    {getCurrencyName(counterCurrency)}
                  </InputGroup.Text>
                  <Form.Control.Feedback type="invalid">
                    Price must be a positive number.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </div>
          </div>
          <div className="row g-mb-20">
            {/* Broker */}
            <div className="col-6">
              <Form.Group>
                <Form.Label>
                  <b>Select Broker</b>
                </Form.Label>
                <Form.Control
                  as="select"
                  value={selectedBroker?.Id || ''}
                  onChange={(e) => handleBrokerChange(e.target.value)}>
                  <option value="" disabled>
                    Select Broker
                  </option>
                  {brokers &&
                    brokers.map((broker) => (
                      <option key={broker.Id} value={broker.Id}>
                        {broker.BrokerName}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </div>
            {/* Commission */}
            <div className="col-6">
              <Form.Group>
                <Form.Label>
                  <b>Commission</b>
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    type="number"
                    placeholder="eg. 10.00"
                    min="0"
                    value={commission}
                    onChange={(e) => setCommission(Number(e.target.value) || 0)}
                  />
                  <InputGroup.Text id="basic-addon3" className="g-text-size-14">
                    {getCurrencyName(folderCurrency)}
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
            </div>
          </div>
          {/* Exchange Rate */}
          {counterCurrency !== folderCurrency && (
            <div className="row g-mb-20">
              <Form.Group>
                <Form.Label>
                  <b>Exchange Rate</b>
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    type="number"
                    placeholder="N/A"
                    value={exchangeRate === 1 ? '' : exchangeRate}
                    onChange={(e) =>
                      Number(e.target.value) > 0
                        ? setExchangeRate(Number(e.target.value))
                        : setExchangeRate(1)
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    Exchange Rate must be a positive number.
                  </Form.Control.Feedback>
                  <InputGroup.Text id="basic-addon3" className="g-text-size-14">
                    {`${getCurrencyName(counterCurrency)} to ${getCurrencyName(folderCurrency)}`}
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
            </div>
          )}
          {/* Remarks */}
          <div className="row g-mb-20">
            <Form.Group>
              <Form.Label>
                <b>Remarks (optional)</b>
              </Form.Label>
              <Form.Control
                type="text"
                defaultValue={txnRemarks}
                onBlur={(e) => setTxnRemarks(e.target.value)}
              />
            </Form.Group>
          </div>
          <div className="g-mb-20 footer-lower">
            <div className="text-center align-items-center">
              <div>
                <h5>{`${getCurrencyName(folderCurrency)} ${grossValue}`}</h5>
                Sell Proceeds
              </div>
            </div>
          </div>
          <br />
          <Form.Group className="col-12 g-mb-10 d-flex align-items-center justify-content-center">
            <Button variant="secondary" className="g-ml-10 g-mr-10" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" className="g-ml-10 g-mr-10" onClick={handleSave}>
              Save
            </Button>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

ClosePositionModal.propTypes = {
  show: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  position: PropTypes.shape({
    portfolioID: PropTypes.string,
    counter: PropTypes.string,
    name: PropTypes.string,
    quantity: PropTypes.number,
    sellPrice: PropTypes.number,
    selectedLayout: PropTypes.string,
  }),
};
