import axios from 'axios';
import ReactOnRails from 'react-on-rails';

if (typeof document !== 'undefined') {
  axios.defaults.headers.common['X-CSRF-Token'] = ReactOnRails.authenticityToken();
}

const headers = {};
const instance = axios.create({
  baseURL: '/api/v1/',
  headers,
});
export default instance;
