import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
  deleteAlertSettings,
  updateSelectedAlertSettings,
  updateActiveStatus,
} from './stockNewsAlertsSlice';
import ModalDialog from '../ModalDialog';
import PopUpNote from '../PopUpNote';
import Loader from '../Loader';
import classes from './alert.module.css';

const ALERT_ACTIVE = {
  false: { label: 'Inactive', cssClass: 'danger' },
  true: { label: 'Active', cssClass: 'primary' },
};

function AlertSettingList({ alerts, handleShowAlertFormModal }) {
  const dispatch = useDispatch();
  const source = axios.CancelToken.source();
  const selectedSettings = useSelector((state) => state.stockNewsAlerts.selectedAlertSettings);
  const alertSettingsFilter = useSelector((state) => state.stockNewsAlerts.alertSettingsFilter);
  const alertSettingsSort = useSelector((state) => state.stockNewsAlerts.alertSettingsSort);
  const loading = useSelector((state) => state.stockNewsAlerts.loading);
  const [deleteID, setDeleteID] = useState();
  const [activateAlert, setActivateAlert] = useState();
  const [deactivateAlert, setDeactivateAlert] = useState();

  const deleteSetting = (id) => {
    dispatch(deleteAlertSettings({ alertSettingIDs: [id], cancelToken: source.token }));
  };

  const activateSetting = (id) => {
    dispatch(
      updateActiveStatus({
        alertSettingIDs: [id],
        action: 'activate',
        cancelToken: source.token,
      }),
    );
  };

  const deactivateSetting = (id) => {
    dispatch(
      updateActiveStatus({
        alertSettingIDs: [id],
        action: 'deactivate',
        cancelToken: source.token,
      }),
    );
  };

  const onChangeSetting = (e, id) => {
    let newSelectedSettings = [];

    if (e.target.checked) {
      newSelectedSettings = [...selectedSettings, id];
    } else {
      newSelectedSettings = selectedSettings.filter((setting) => setting !== id);
    }

    dispatch(updateSelectedAlertSettings(newSelectedSettings));
  };

  const listFilter = (alert) => {
    const counterFilter =
      alertSettingsFilter.counters.includes('ALL') ||
      alertSettingsFilter.counters.includes(alert.instCode);

    if (alertSettingsFilter.active === 1 && alertSettingsFilter.inactive === 1) {
      return counterFilter;
    }
    if (alertSettingsFilter.active === 1) {
      return alert.active && counterFilter;
    }
    if (alertSettingsFilter.inactive === 1) {
      return !alert.active && counterFilter;
    }
    return false;
  };

  const listSorting = (a, b) => {
    if (alertSettingsSort.by === 'counter') {
      if (alertSettingsSort.dir === 'asc') {
        return a.instName.localeCompare(b.instName);
      }
      return b.instName.localeCompare(a.instName);
    }

    if (alertSettingsSort.by === 'time') {
      if (alertSettingsSort.dir === 'asc') {
        return a.dateTime.localeCompare(b.dateTime);
      }
      return b.dateTime.localeCompare(a.dateTime);
    }

    if (a.id === 0) {
      return -1;
    }

    if (b.id === 0) {
      return 1;
    }

    return b.id.toString().localeCompare(a.id.toString());
  };

  if ((!alerts || alerts.length === 0) && loading) {
    return <Loader visible classes="g-min-height-300 g-pt-100" />;
  }

  return (
    <div className="g-min-height-300">
      {alerts
        .filter(listFilter)
        .sort(listSorting)
        .map((alert, index, array) => (
          <div
            key={alert.id}
            className={`${index + 1 !== array.length ? 'g-border-b-1' : ''} alert-item p-3  ${
              alert.active ? '' : classes.inactiveAlert
            }`}>
            <div className="d-flex gap-2 justify-content-between">
              <div className="flex-shrink">
                <input
                  type="checkbox"
                  onChange={(e) => onChangeSetting(e, alert.id)}
                  disabled={alert.id === 0}
                />
              </div>
              <div className="flex-fill">
                <div className="d-flex gap-2 align-items-center justify-content-between g-mb-10">
                  <div className="flex-fill d-flex gap-2">
                    <span
                      className={`badge badge-outlined text-bg-${
                        ALERT_ACTIVE[alert.active]?.cssClass
                      }`}>
                      {ALERT_ACTIVE[alert.active]?.label}
                    </span>
                    {alert.new && <span className="badge bg-info">NEW</span>}
                    {/* TODO: NOT AVAILBE IN V1 */}
                    {/* <span className="badge badge-outlined text-bg-warning">
                      Triggered 2 times
                    </span> */}
                  </div>
                  <div className="flex-fill">
                    {alert.id !== 0 && (
                      <div className="alert-item-action d-flex justify-content-end gap-2">
                        <button
                          className={`${
                            alert.active ? 'd-inline-block' : 'd-none'
                          } bg-transparent border-0`}
                          type="button"
                          aria-label="deactivate"
                          onClick={() => setDeactivateAlert(alert.id)}>
                          <i className="fa-light fa-pause" />
                        </button>
                        <button
                          className={`${
                            alert.active ? 'd-none' : 'd-inline-block'
                          } bg-transparent border-0`}
                          type="button"
                          aria-label="activate"
                          onClick={() => setActivateAlert(alert.id)}>
                          <i className="fa-light fa-play" />
                        </button>
                        <button
                          className="bg-transparent border-0"
                          type="button"
                          aria-label="edit"
                          onClick={() =>
                            handleShowAlertFormModal({ action: 'edit', alertID: alert.id })
                          }>
                          <i className="fa-light fa-pen-to-square" />
                        </button>
                        <button
                          className="bg-transparent border-0"
                          type="button"
                          aria-label="duplicate"
                          onClick={() =>
                            handleShowAlertFormModal({ action: 'duplicate', alertID: alert.id })
                          }>
                          <i className="fa-light fa-copy" />
                        </button>
                        <button
                          className="bg-transparent border-0"
                          type="button"
                          aria-label="delete"
                          onClick={() => setDeleteID(alert.id)}>
                          <i className="fa-light fa-trash-can" />
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <strong className="g-mr-5">{alert.instName}</strong>
                  {alert.instCode && <span className="text-muted g-mr-5">{alert.instCode}</span>}
                  {alert.desc && (
                    <PopUpNote
                      content={alert.desc}
                      icon="fa-light fa-note fa-sm"
                      classes="text-start text-muted g-width-300"
                    />
                  )}
                </div>
                <div className="text-muted g-mb-10">
                  <strong>Last updated:</strong> {alert.lastUpdated}
                </div>
                <div className="d-flex gap-2 g-mb-10">
                  <div className="d-flex gap-2">
                    <span
                      className={`badge badge-outlined ${
                        alert.alertByApplication ? 'text-bg-primary' : 'text-bg-gray-600'
                      }`}>
                      Application
                    </span>
                    <span
                      className={`badge badge-outlined ${
                        alert.alertBySms ? 'text-bg-primary' : 'text-bg-gray-600'
                      }`}>
                      SMS
                    </span>
                    <span
                      className={`badge badge-outlined ${
                        alert.alertByEmail ? 'text-bg-primary' : 'text-bg-gray-600'
                      }`}>
                      Email
                    </span>
                  </div>
                  <div className="divider-v g-per-height-100 ms-1 me-1">&nbsp;</div>
                  <span className="badge badge-outlined text-bg-gray-600">
                    Repeat: {alert.alertRepeatability}
                  </span>
                </div>
                <div className="g-text-size-14">{alert.alertContent}</div>
              </div>
            </div>
          </div>
        ))}

      {alerts && alerts.length > 0 && alerts.filter(listFilter).length === 0 && !loading && (
        <h5 className="g-mt-100 text-center text-muted">No result matching filters</h5>
      )}

      {(!alerts || alerts.length === 0) && !loading && (
        <div className="text-muted text-center g-min-height-300">
          <div className="g-pt-100">
            <div className="pb-2">
              <i className="fa-solid fa-bell-exclamation g-text-size-40" />
            </div>
            <div>Alert will notify you immediately when your conditions are met.</div>
            <div>To set up, click on the button below.</div>
            <button
              type="button"
              aria-label="add"
              className="btn btn-primary g-mt-20"
              onClick={() => handleShowAlertFormModal({ action: 'create' })}>
              <i className="fa-light fa-circle-plus g-mr-5 fa-xl" />
              Add alert
            </button>
          </div>
        </div>
      )}

      <ModalDialog
        show={activateAlert}
        handleClose={(e) => {
          if (e) {
            e.stopPropagation();
          }
          setActivateAlert(null);
        }}
        title="Activate Alert Setting"
        body="Are you sure you want to activate this alert setting?"
        handleSave={(params, e) => {
          e.stopPropagation();
          activateSetting(activateAlert);
          setActivateAlert(null);
        }}
        saveLabel="Activate"
        saveVariant="primary"
        backdrop="static"
      />

      <ModalDialog
        show={deactivateAlert}
        handleClose={(e) => {
          if (e) {
            e.stopPropagation();
          }
          setDeactivateAlert(null);
        }}
        title="Deactivate Alert Setting"
        body="Are you sure you want to deactivate this alert setting?"
        handleSave={(params, e) => {
          e.stopPropagation();
          deactivateSetting(deactivateAlert);
          setDeactivateAlert(null);
        }}
        saveLabel="Deactivate"
        saveVariant="danger"
        backdrop="static"
      />

      <ModalDialog
        show={deleteID}
        handleClose={(e) => {
          if (e) {
            e.stopPropagation();
          }
          setDeleteID(null);
        }}
        title="Delete Alert Setting"
        body="Are you sure you want to delete this alert setting?"
        handleSave={(params, e) => {
          e.stopPropagation();
          deleteSetting(deleteID);
          setDeleteID(null);
        }}
        saveLabel="Delete"
        saveVariant="danger"
        backdrop="static"
      />
    </div>
  );
}

export default AlertSettingList;
AlertSettingList.propTypes = {
  alerts: PropTypes.oneOfType([PropTypes.array]),
  handleShowAlertFormModal: PropTypes.func,
};
