import React, { useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import { buildTemplateString } from './utils';
import { saveTemplate } from './marketScreenerSlice';
import ModalDialog from '../../../components/ModalDialog';
import 'react-toastify/dist/ReactToastify.css';

const { CancelToken } = axios;
function SaveTemplatesForm({ handleOpenModal }) {
  const dispatch = useDispatch();
  const source = CancelToken.source();
  const [saveTemplateData, setSaveTemplateData] = useState({ newName: '', selectedTemplate: '' });
  const userTemplatesData = useSelector((state) => state.marketScreener.userTemplatesData);
  const [validated, setValidated] = useState(false);
  const [showDialog, setShowDialog] = useState({ show: false });

  const saveUsrTemplate = (newTemplateData) => {
    dispatch(saveTemplate(newTemplateData));
    handleOpenModal(false);
  };

  const handleChangeSaveTemplate = (event) => {
    const { name, value } = event.target;

    setValidated(false);
    if (name === 'new_name') {
      setSaveTemplateData({ newName: value, selectedTemplate: '' });
    }
    if (name === 'selected_template') {
      setSaveTemplateData({ newName: '', selectedTemplate: value });
    }
  };

  const handleClickCreateOrSaveTemplate = (event, type) => {
    event.preventDefault();

    const and_groups = document.querySelectorAll(
      '#form_selected_criteria > .criteria_row_wrapper ',
    );

    const tmpl_str = buildTemplateString(and_groups);

    const saveTemplatePayload = {
      criteria: tmpl_str,
      cancelToken: source.token,
    };

    if (type === 'create') {
      if (saveTemplateData.newName === '') {
        setValidated(true);
        return;
      }
      saveTemplatePayload.newName = saveTemplateData.newName;
    } else if (type === 'update') {
      if (saveTemplateData.selectedTemplate === '') {
        setValidated(true);
        return;
      }
      saveTemplatePayload.selectedTemplate = saveTemplateData.selectedTemplate;
    }

    setValidated(false);

    if (
      type === 'create' &&
      userTemplatesData &&
      userTemplatesData?.user_tmpl?.templates?.some(
        (template) => template.title === saveTemplatePayload.newName,
      )
    ) {
      setShowDialog({
        show: true,
        title: 'Warning',
        body: 'Template with this name is existed. Overwrite?',
        label: 'OK',
        variant: 'primary',
        handleSave: () => saveUsrTemplate(saveTemplatePayload),
      });
    } else {
      saveUsrTemplate(saveTemplatePayload);
    }
  };

  return (
    <>
      <Form noValidate validated={validated}>
        <Form.Group>
          <Form.Label>Create new template </Form.Label>
          <div className="d-flex flex-row gap-3">
            <Form.Control
              name="new_name"
              value={saveTemplateData.newName}
              onChange={handleChangeSaveTemplate}
              required
            />
            <Button onClick={(e) => handleClickCreateOrSaveTemplate(e, 'create')}>Create</Button>
          </div>
        </Form.Group>
      </Form>
      <hr />
      <Form noValidate validated={validated}>
        <Form.Group>
          <Form.Label>Save template as</Form.Label>
          <div className="d-flex flex-row gap-3">
            <Form.Select
              name="selected_template"
              value={saveTemplateData.selectedTemplate}
              onChange={handleChangeSaveTemplate}
              required>
              <option value="">Select a template</option>
              {Object.keys(userTemplatesData).length > 0 &&
                userTemplatesData?.user_tmpl &&
                userTemplatesData?.user_tmpl?.templates &&
                userTemplatesData.user_tmpl.templates.map((template) => (
                  <option key={template.key} value={template.key}>
                    {template.title}
                  </option>
                ))}
            </Form.Select>
            <Button onClick={(e) => handleClickCreateOrSaveTemplate(e, 'update')}>Save</Button>
          </div>
        </Form.Group>
        <Form.Control.Feedback type="invalid" className={validated ? 'd-block' : ''}>
          Please name your new template or save as existing template.
        </Form.Control.Feedback>
      </Form>

      <ModalDialog
        show={showDialog.show}
        handleClose={(e) => {
          if (e) {
            e.stopPropagation();
          }
          setShowDialog({ show: false });
        }}
        title={showDialog.title}
        body={showDialog.body}
        handleSave={(params, e) => {
          e.stopPropagation();
          showDialog.handleSave();
          setShowDialog({ show: false });
        }}
        saveLabel={showDialog.label}
        saveVariant={showDialog.variant}
      />
    </>
  );
}

SaveTemplatesForm.propTypes = {
  handleOpenModal: PropTypes.func,
};

export default SaveTemplatesForm;
