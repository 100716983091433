import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Responsive from '@amcharts/amcharts5/themes/Responsive';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

import classes from '../../portfolio.module.css';

const COLORS = [
  '#cf9c3c',
  '#b05cc6',
  '#75b441',
  '#7777c7',
  '#7f863d',
  '#c85889',
  '#4faa79',
  '#d14c3c',
  '#52aad0',
  '#bb7151',
];

function StackedLineChart({
  portfolioData,
  chartMarkers,
  selectedPeriodInterval,
  selectedRange,
  groupBy,
}) {
  useLayoutEffect(() => {
    const pL = (data) => {
      return (
        parseFloat(data.realized_sell_proceeds) +
        parseFloat(data.unrealized_sell_proceeds) +
        parseFloat(data.total_dividend) -
        parseFloat(data.buy_cost)
      );
    };

    const root = am5.Root.new('PortfolioStackedLineChart');
    root.setThemes([am5themes_Animated.new(root), am5themes_Responsive.newEmpty(root)]);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        paddingLeft: 0,
        layout: root.verticalLayout,
        maxTooltipDistance: 0,
      }),
    );

    // set chart color
    chart.get('colors').set('colors', COLORS);

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    const cursor = chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        behavior: 'none',
      }),
    );
    cursor.lineY.set('visible', false);

    const group = [];
    const formatData = (date, val) => {
      const result = {
        date: Date.parse(date),
      };

      if (val[groupBy]) {
        Object.entries(val[groupBy]).forEach(([k, v]) => {
          if (!group.includes(k)) group.push(k);
          result[k] = pL(v);
        });
      }

      return result;
    };
    const data = Object.entries(portfolioData).map(([key, val]) => {
      return formatData(key, val);
    });

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    const xRenderer = am5xy.AxisRendererX.new(root, {
      minorGridEnabled: true,
    });
    const xAxis = chart.xAxes.push(
      am5xy.GaplessDateAxis.new(root, {
        groupData: true,
        groupIntervals: [selectedPeriodInterval],
        baseInterval: {
          timeUnit: 'day',
          count: 1,
        },
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {}),
      }),
    );
    xAxis.get('renderer').labels.template.setAll({
      fill: am5.color(0x646464),
      fontSize: 12,
    });

    xAxis.get('dateFormats').day = 'dd MMM yy';
    xAxis.get('dateFormats').week = 'dd MMM yy';
    xAxis.get('dateFormats').month = 'MMM yy';
    xAxis.get('dateFormats').year = 'yyyy';
    xAxis.get('periodChangeDateFormats').day = 'dd MMM yy';
    xAxis.get('periodChangeDateFormats').week = 'dd MMM yy';
    xAxis.get('periodChangeDateFormats').month = 'MMM yy';
    xAxis.get('periodChangeDateFormats').year = 'yyyy';

    xAxis.data.setAll(data);

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {
          pan: 'zoom',
        }),
      }),
    );
    yAxis.get('renderer').labels.template.setAll({
      fill: am5.color(0x646464),
      fontSize: 12,
    });

    // Add legend
    // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
    const legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50,
      }),
    );
    legend.labels.template.setAll({
      fill: am5.color(0x646464),
      fontSize: 12,
    });
    legend.markers.template.setAll({
      width: 16,
      height: 16,
    });

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    function createSeries(name) {
      const tooltip = am5.Tooltip.new(root, {
        getFillFromSprite: false,
        getStrokeFromSprite: false,
        autoTextColor: false,
        getLabelFillFromSprite: true,
      });
      tooltip.get('background').setAll({
        stroke: am5.color(0x646464),
        color: am5.color(0x646464),
        fill: am5.color(0xffffff),
        strokeOpacity: 0.8,
      });
      const series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name,
          xAxis,
          yAxis,
          stacked: true,
          valueYField: name,
          valueXField: 'date',
          tooltip,
        }),
      );

      series.fills.template.setAll({
        fillOpacity: 0.5,
        visible: true,
      });

      series.get('tooltip').label.adapters.add('text', (text, target) => {
        let newText = "[#646464 bold]{valueX.formatDate('dd MMM yyyy')}[/]\n\n";
        let i = 0;
        chart.series.each((series_) => {
          const tooltipDataItem = series_.get('tooltipDataItem');
          if (tooltipDataItem) {
            const value = tooltipDataItem.get('valueY')
              ? tooltipDataItem.get('valueY').toLocaleString('en-SG')
              : 0;
            if (i !== 0) {
              newText += '\n';
            }
            newText += `[${series_.get('fill').toString()}]●[/] [#646464]${series_.get(
              'name',
            )}:[/] [#646464 bold width:150px]${value}[/]`;
          }
          i += 1;
        });
        return newText;
      });
      series.get('tooltip').on('visible', function (visible, target) {
        if (visible) {
          chart.series.each(function (s) {
            if (s.get('tooltip') !== target) {
              s.get('tooltip').set('visible', false);
            }
          });
        }
      });

      series.data.setAll(data);
      series.appear(1000);
      legend.data.push(series);

      series.events.once('datavalidated', (ev) => {
        if (!selectedRange || !selectedRange.startDate) {
          return;
        }

        // To make sure the data is in center get start_date - 1day and end_date + 1day
        const startDate = new Date(selectedRange.startDate);
        const endDAte = new Date();
        startDate.setDate(startDate.getDate() - 1);
        endDAte.setDate(endDAte.getDate() + 1);
        ev.target.get('xAxis').zoomToDates(startDate, endDAte);
      });
    }

    group.forEach((g) => {
      createSeries(g);
    });

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [portfolioData, chartMarkers, selectedPeriodInterval, selectedRange, groupBy]);

  return <div id="PortfolioStackedLineChart" className={classes.portfolioPerformanceChart} />;
}

StackedLineChart.propTypes = {
  portfolioData: PropTypes.object,
  chartMarkers: PropTypes.array,
  selectedPeriodInterval: PropTypes.object,
  selectedRange: PropTypes.object,
  groupBy: PropTypes.string,
};

export default StackedLineChart;
