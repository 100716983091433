import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Nav, Button, Form } from 'react-bootstrap';
import store from '../../../utils/store';
import styles from './membership.module.css';

function Plan() {
  return (
    <Container className={styles.membershipContainer}>
      <Row>
        <Col>
          <div>
            <h5>Membership Information</h5>
            <hr className="mt-2" />
          </div>
        </Col>
      </Row>
      <Row className={styles.sectionGap}>
        <Col>
          <h4>Pro</h4>
          <h5>S$24.22 per month</h5>
          <div className={styles.smallNote}>Your membership will be expiring in 67 days</div>
        </Col>
        <Col className="d-flex justify-content-end align-items-center">
          <Button
            type="button"
            variant="success"
            className={[styles.planButton, styles.greenButton]}>
            Switch Membership
          </Button>
          <Button type="button" variant="outline-light" className={styles.planButton}>
            Cancel Subscription
          </Button>
        </Col>
      </Row>
      <Row className={`${styles.sectionGap} d-none`}>
        <Col>
          <h5>Add-on Options</h5>
          <hr className="mt-2" />
          <div className={`d-flex flex-row mt-4 pt-2 ${styles.cardList}`}>
            <Col className="g-pr-24" md={3} xs={12}>
              <div className={styles.addOnOptionTitle}>
                <Form.Check
                  type="checkbox"
                  inline
                  reverse
                  className="d-flex justify-content-between align-items-center g-mr-0">
                  <Form.Check.Label>Malaysia Stock Prices</Form.Check.Label>
                  <Form.Check.Input type="checkbox" isValid className="mt-0" />
                </Form.Check>
              </div>
              <div className={styles.addOnOptionSub}>Real Time</div>
            </Col>
            <Col className="g-pr-24" md={3} xs={12}>
              <div className={styles.addOnOptionTitle}>
                <Form.Check
                  type="checkbox"
                  inline
                  reverse
                  className="d-flex justify-content-between align-items-center g-mr-0">
                  <Form.Check.Label>Singapore Stock Prices</Form.Check.Label>
                  <Form.Check.Input type="checkbox" isValid className="mt-0" />
                </Form.Check>
              </div>
              <div className={styles.addOnOptionSub}>Real Time</div>
            </Col>
          </div>
          <Row className="mt-4">
            <Col className="d-flex flex-row">
              <Button
                type="button"
                variant="success"
                className={[styles.planButton, styles.greenButton]}>
                Add-on options
              </Button>
              <Button
                type="button"
                variant="outline-secondary"
                size="lg"
                className={[styles.cancelAddOnButton]}>
                Cancel add-on
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className={`${styles.sectionGap} d-none`}>
        <Col>
          <h5>Payment Options</h5>
          <hr className="mt-2" />
          <div className={`d-flex flex-row mt-4 pt-2 ${styles.cardList}`}>
            <Col className="g-pr-24">
              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center" style={{ minHeight: 28 }}>
                  <img height={19} src="/images/icon-visa.svg" />
                  <span className={styles.primaryBadge}>Primary</span>
                </div>
                <div className="d-flex align-items-center">
                  <i className="fa-sharp fa-regular fa-ellipsis float-end" />
                </div>
              </div>
              <div className={styles.creditCardDetail}>************8888 | Expires 28/28</div>
              <div className={styles.creditCardStatus}>
                Auto-recharge status: <span className="text-danger">Inactive</span>
                <Button type="button" variant="success" className={styles.updateButton}>
                  Update
                </Button>
              </div>
            </Col>
            <Col className="g-pr-24">
              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center">
                  <img height={28} src="/images/icon-mastercard.svg" />
                  <span className={styles.secondaryBadge}>Set as Primary</span>
                </div>
                <div className="d-flex align-items-center">
                  <i className="fa-sharp fa-regular fa-ellipsis float-end" />
                </div>
              </div>
              <div className={styles.creditCardDetail}>************9999 | Expires 29/29</div>
              <div className={styles.creditCardStatus}>
                Status: <span className="text-success">Active</span>
              </div>
            </Col>
            <Col
              className={`d-flex justify-content-center align-items-center flex-column ${styles.addPaymentCol}`}>
              <i className="fa-solid fa-circle-plus" />
              Add Payment Method
            </Col>
          </div>
        </Col>
      </Row>
      <Row className={styles.sectionGap}>
        <Col>
          <h5>Billing Address</h5>
          <hr className="mt-2" />
          <div className={`d-flex flex-row mt-4 pt-2 ${styles.cardList}`}>
            <Col className="g-pr-24" xs={12} sm={6} lg={4}>
              <div className={styles.editButton}>
                <i className="fa-light fa-pen-to-square" />
              </div>
              <div className={styles.addressText}>
                <b>Jane Long</b>
                <br />
                Jalan 128, Kampung Tanjung Rambutan Gila,
                <br />
                31250, Perak Darul Ridzuan
                <br />
                Malaysia
                <br />
                Tel: +60 168181828
              </div>
            </Col>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Plan;
