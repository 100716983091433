import React from 'react';
import PropTypes from 'prop-types';

import { createLoadableComponent } from '../../../utils/Loadable';

const PriceChart = createLoadableComponent(() => import('../../../components/PriceChart'));

function CompanyBuyBackChart({ counter, buybackChartData }) {
  const chartDataURL = '/api/v1/charts/factsheet_overview_chart_data';
  const chartOptions = {
    isCustomMarker: true,
    showZoomOptions: true,
    showChartTypeOptions: true,
    hasIntradayData: false,
    showTimeIntervalOptions: true,
    showRangeSlider: true,
    defaultChartType: 'line',
    markerData: buybackChartData,
    zoom: '1y',
    periodicity: {
      label: '1 Day',
      value: {
        base: 'year',
        multiplier: 1,
        periodicity: { period: 1, interval: 1, timeUnit: 'day' },
      },
    },
    chartClasses: 'g-height-450 g-lg-height-500 g-mb-30 g-lg-mb-60',
  };
  return (
    <div className="g-mb-80">
      <h4 className="g-mb-40">Company Buyback Chart</h4>
      <PriceChart counter={counter} dataURL={chartDataURL} options={chartOptions} />
    </div>
  );
}

CompanyBuyBackChart.propTypes = {};

export default CompanyBuyBackChart;
