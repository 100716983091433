import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';

import sgFlag from 'images/icon-flag-singapore.svg';
import myFlag from 'images/icon-flag-malaysia.svg';
import hkFlag from 'images/icon-flag-hongkong.svg';
import thFlag from 'images/icon-flag-thailand.svg';
import idFlag from 'images/icon-flag-indonesia.svg';
import jpFlag from 'images/icon-flag-japan.svg';
import koFlag from 'images/icon-flag-korea.svg';
import cnFlag from 'images/icon-flag-china.svg';
import asFlag from 'images/icon-flag-australia.svg';
import usFlag from 'images/icon-flag-us.svg';
import classes from './home.module.css';
import { useDetectMobileScreen } from '../../utils/utils';
import { changeMarketsSectionIndex } from './homeSlice';
import './indicesCarousel.css';

export default function IndicesCarousel({ data, dataFeedMode, country }) {
  const activeIndex = useSelector((state) => state.home.marketsSection.index);
  const dispatch = useDispatch();

  useEffect(() => {
    const FBMKLCI = '0200I.MY';
    const STI = 'STI.SI';
    let initActiveIndex = undefined;
    if (country?.toUpperCase() === 'MY' && activeIndex !== FBMKLCI) {
      initActiveIndex = data?.filter((index) => index.code === FBMKLCI)[0];
    } else if (!(country?.toUpperCase() === 'MY') && activeIndex !== STI) {
      initActiveIndex = data?.filter((index) => index.code === STI)[0];
    }
    if (initActiveIndex) {
      dispatch(changeMarketsSectionIndex(initActiveIndex));
    }
  }, []);

  const isMobile = useDetectMobileScreen();
  const mainOptions = {
    // type: 'loop',
    rewind: true,
    // clones: 0,
    drag: 'free',
    perPage: 6,
    perMove: 1,
    autoScroll: {
      speed: 1,
    },
    pagination: false,
    lazyload: 'nearby',
    autoWidth: 'auto',
    gap: '1rem',
    height: '180px',
    padding: isMobile && '3rem',
  };

  const onActiveIndexChange = (selectedIndex) => {
    if (selectedIndex?.code !== activeIndex) {
      dispatch(changeMarketsSectionIndex(selectedIndex));
    }
  };

  if (!data) {
    return null;
  }

  return (
    <Splide
      options={mainOptions}
      aria-labelledby="thumbnail-slider-example"
      hasTrack={false}
      id="sic_indexList"
      data-feed_mode={dataFeedMode}>
      <SplideTrack>
        {data.map((row, index) => (
          <SplideSlide
            key={`splide_${index}_${row.code}`}
            onClick={() => {
              onActiveIndexChange(row);
            }}>
            <CardItem
              key={`sic_indexList_${index}_${row.code}`}
              label={row.label}
              lastDone={row.last_done_price}
              changeVal={row.change}
              percChange={row.percent_change}
              symbol={row.code}
              flag={row.flag}
              isActive={activeIndex === row.code}
            />
          </SplideSlide>
        ))}
      </SplideTrack>
    </Splide>
  );
}

IndicesCarousel.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      lastDone: PropTypes.string,
      changeVal: PropTypes.string,
      percChange: PropTypes.string,
      symbol: PropTypes.string,
      flag: PropTypes.string,
    }),
  ),
  dataFeedMode: PropTypes.string,
  country: PropTypes.string,
};

function CardItem({ label, lastDone, changeVal, percChange, symbol, flag, isActive }) {
  if (!symbol || !flag) {
    return null;
  }

  const marketSuffix = symbol.split('.').pop();
  if (marketSuffix.length === 0) {
    return null;
  }

  const itemLabel = String(label).toUpperCase().trim();
  let percClass = '';
  if (Number(changeVal) < 0) {
    percClass = 'text-danger';
  } else if (Number(changeVal) > 0) {
    percClass = 'text-success';
  }

  const flagIMG = (_flag) => {
    switch (_flag.toLowerCase()) {
      case 'sg':
        return sgFlag;
      case 'my':
        return myFlag;
      case 'hk':
        return hkFlag;
      case 'th':
        return thFlag;
      case 'id':
        return idFlag;
      case 'jp':
        return jpFlag;
      case 'cn':
        return cnFlag;
      case 'ko':
        return koFlag;
      case 'ax':
        return asFlag;
      case 'us':
        return usFlag;
      default:
        return usFlag;
    }
  };

  return (
    <Card
      className={`${symbol} ${classes.indexPriceCard} ${
        isActive ? classes.indexPriceCardActive : classes.indexPriceCardInactive
      }`}
      type="button">
      <Card.Body className="text-center">
        <div className="mb-2">
          <img
            src={flagIMG(flag)}
            alt="Flag"
            className={isActive ? classes.indexPriceFlag : classes.indexPriceFlagInactive}
          />
        </div>
        <div className="fw-bold content mb-2">{itemLabel}</div>
        {lastDone && (
          <div id={`sic_${itemLabel}_lastDone`} className="fw-bold lastDone content">
            {lastDone}
          </div>
        )}
        {(changeVal || percChange) && (
          <div id={`sic_${itemLabel}_change`} className={`change ${percClass}`}>
            {changeVal && (
              <span id={`sic_${itemLabel}_changeValue`} className="changeValue content me-1">
                {changeVal}
              </span>
            )}
            {percChange && (
              <span id={`sic_${itemLabel}_percentageChange`} className="percentageChange content">
                {percChange}
              </span>
            )}
          </div>
        )}
      </Card.Body>
    </Card>
  );
}

CardItem.propTypes = {
  label: PropTypes.string,
  lastDone: PropTypes.string,
  changeVal: PropTypes.string,
  percChange: PropTypes.string,
  symbol: PropTypes.string,
  flag: PropTypes.string,
  isActive: PropTypes.bool,
};
