import { createSlice } from '@reduxjs/toolkit';
import api from '../../utils/api';

const ipoSlice = createSlice({
  name: 'ipo',
  initialState: {
    data: [],
    err: null,
    loading: false,
    market: 'sgx',
  },
  reducers: {
    ipoFetchStart(state, action) {
      state.data = null;
      state.err = null;
      state.loading = true;
    },
    ipoFetchSuccess(state, action) {
      state.data = action.payload.data.ipo;
      state.err = null;
      state.loading = false;
    },
    ipoFetchFail(state, action) {
      state.err = action.payload;
      state.loading = false;
    },
  },
});

export default ipoSlice.reducer;

const { ipoFetchStart, ipoFetchSuccess, ipoFetchFail } = ipoSlice.actions;

export const fetchData =
  ({ market, cancelToken }) =>
  (dispatch) => {
    const url = `ipo/ipo_data.json?market=${market}`;
    dispatch(ipoFetchStart());
    api
      .get(url, {
        cancelToken,
      })
      .then(({ data }) => {
        dispatch(ipoFetchSuccess({ data }));
      })
      .catch((error) => {
        dispatch(ipoFetchFail(error));
      });
  };
