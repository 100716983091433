import React, { useState } from 'react';
import { Modal, Nav, Tab } from 'react-bootstrap';
import PropTypes from 'prop-types';
import styles from './alert.module.css';
import AlertForm from './AlertForm';

const ALERT_TYPES = {
  price: 'Counter Alert',
  news: 'News Alert',
};
function AlertFormModal({ showAlertFormModal, setShowAlertFormModal }) {
  const handleClose = () => setShowAlertFormModal({ show: false });
  const [activeTab, setActiveTab] = useState('price');
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  return (
    <div
      className={`modal ${styles.alertFormModal} ${
        showAlertFormModal.show
          ? 'show position-absolute d-block g-lg-height-auto modal-lg g-width-auto'
          : 'd-none'
      }`}>
      <Modal.Dialog className="my-0 g-width-auto g-ml-0">
        <Modal.Header closeButton onHide={handleClose} className="py-2 px-3">
          <Modal.Title className="h6">
            {showAlertFormModal?.action === 'create' || showAlertFormModal?.action === 'duplicate'
              ? 'Create'
              : 'Edit'}{' '}
            Alert
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-3">
          {showAlertFormModal.action === 'create' ? (
            <Tab.Container defaultActiveKey={activeTab}>
              <Nav variant="pills" className="g-mb-25 g-pb-25 g-border-b-1">
                {Object.keys(ALERT_TYPES).map((tab) => {
                  return (
                    <Nav.Item key={tab}>
                      <Nav.Link
                        className={`nav-link  ${activeTab === tab ? 'active' : ''}`}
                        eventKey={tab}
                        id={tab}
                        onClick={() => handleTabClick(tab)}>
                        {ALERT_TYPES[tab]}
                      </Nav.Link>
                    </Nav.Item>
                  );
                })}
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey={activeTab}>
                  <div
                    className={`${styles.alertFormTabPane} g-width-400 g-lg-width-600 overflow-x-hidden overflow-y-scroll styled-scrollbar mb-3`}>
                    <AlertForm
                      alertType={activeTab}
                      action={showAlertFormModal.action}
                      handleClose={handleClose}
                    />
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          ) : (
            <div
              className={`${styles.alertFormTabPane} g-width-400 g-lg-width-600 overflow-x-hidden overflow-y-scroll styled-scrollbar mb-3`}>
              <AlertForm
                action={showAlertFormModal.action}
                alertID={showAlertFormModal?.alertID}
                handleClose={handleClose}
              />
            </div>
          )}
        </Modal.Body>
      </Modal.Dialog>
    </div>
  );
}

export default AlertFormModal;
AlertFormModal.propTypes = {
  showAlertFormModal: PropTypes.oneOfType([PropTypes.object]),
  setShowAlertFormModal: PropTypes.func,
};
