import React from 'react';
import PropTypes from 'prop-types';

// Used for normal tabs without the use of React Router
export default function NavComponent({ navLinks, activeNav, content, handleNavClick, tabClasses }) {
  return (
    <>
      <ul className={`nav nav-pills ${tabClasses || 'g-mb-40'}`}>
        {navLinks
          .filter((tab) => !tab.hideNav && !tab.index)
          .map((tab) => (
            <li className="nav-item" key={tab.key}>
              <a
                className={activeNav === tab.key ? 'nav-link active' : 'nav-link'}
                id={tab.key}
                onClick={(e) => handleNavClick(e, tab.key)}
                type="button"
                href={tab.path}>
                {tab.label}
              </a>
            </li>
          ))}
      </ul>
      <div className="tab-content">
        <div
          className="tab-pane fade show active"
          id={activeNav}
          role="tabpanel"
          aria-labelledby={activeNav}>
          {content}
        </div>
      </div>
    </>
  );
}

NavComponent.propTypes = {
  navLinks: PropTypes.array,
  activeNav: PropTypes.string,
  content: PropTypes.object,
  handleNavClick: PropTypes.func,
  tabClasses: PropTypes.string,
};
