import React from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import { fetchData } from './stockPricesSlice';
import PrintButton from '../../components/PrintButton';

const { CancelToken } = axios;

function ActionButtons() {
  const filters = useSelector((state) => state.stockPrices.filters);
  const data = useSelector((state) => state.stockPrices.data);

  const dispatch = useDispatch();
  const source = CancelToken.source();

  const handleRefresh = () => {
    dispatch(fetchData({ filters: filters, cancelToken: source.token }));
  };

  return (
    filters &&
    filters.layout &&
    filters.layout !== 'trading_data' && (
      <>
        <button type="button" className="btn btn-light" onClick={handleRefresh}>
          <i className="fa-thin fa-arrows-rotate fa-lg g-mr-5" /> Refresh
        </button>
        <PrintButton
          title=""
          autoPrint={false}
          disabled={!(data && data.stock_info)}
          printNodeID="dt-sic_pricesTable"
        />
        <button type="button" className="btn btn-light">
          <i className="fa-thin fa-file-export fa-lg g-mr-5" /> Export to Excel
        </button>
        <button type="button" className="btn btn-light">
          <i className="fa-thin fa-copy fa-lg g-mr-5" /> Copy to Clipboard
        </button>
      </>
    )
  );
}

ActionButtons.propTypes = {};

export default ActionButtons;
