import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import axios from 'axios';
import api from '../../utils/api';

const { CancelToken } = axios;

export default function SelectCounter({ counter, market, handleOnChangeCounter, disabled }) {
  const source = CancelToken.source();
  const [defaultCounter, setDefaultCounter] = useState(counter);
  useEffect(() => {
    setDefaultCounter(counter);
  }, [counter]);

  const loadCounterList = (inputValue, callback) => {
    clearTimeout(loadCounterList.timeout);
    loadCounterList.timeout = setTimeout(() => {
      const cancelToken = source.token;
      api
        .get(`quote/search.json?markets=${market}&keyword=${inputValue}`, { cancelToken })
        .then(({ data }) => {
          if (data && data.data && data.data.length > 0) {
            const counters = data.data.map((c) => {
              return {
                label: `${c.stock_name} (${c.stock_id}.${c.market_suffix})`,
                value: `${c.stock_id}.${c.market_suffix}`,
              };
            });
            callback(counters);
          } else {
            callback([]);
          }
        })
        .catch((error) => {
          console.log('Search keyword error', error);
          callback([]);
        });
    }, 500);
  };

  return (
    <AsyncSelect
      unstyled
      isClearable
      isDisabled={disabled}
      backspaceRemovesValue
      noOptionsMessage={(e) => (e.inputValue ? 'No options' : null)}
      className={`${
        disabled ? 'disabled' : ''
      } g-width-250 react-select-container react-select-counter`}
      placeholder="Search counter"
      classNamePrefix="react-select"
      loadOptions={loadCounterList}
      value={
        Object.keys(defaultCounter).length > 0
          ? { value: defaultCounter.code, label: defaultCounter.name }
          : null
      }
      onChange={handleOnChangeCounter || null}
    />
  );
}

SelectCounter.propTypes = {
  counter: PropTypes.oneOfType([PropTypes.object]),
  market: PropTypes.oneOfType([PropTypes.array]),
  handleOnChangeCounter: PropTypes.func,
  disabled: PropTypes.bool,
};
