import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import store from '../../../utils/store';
import ConsensusEstimatesMain from './ConsensusEstimatesMain';

function ConsensusEstimates({
  market,
  minRatings,
  availableMarkets,
  minConsensusEstimatesRatings,
  estimatesRankingTypes,
}) {
  return (
    <Provider store={store}>
      <ConsensusEstimatesMain
        market={market}
        minRatings={minRatings}
        availableMarkets={availableMarkets}
        minConsensusEstimatesRatings={minConsensusEstimatesRatings}
        estimatesRankingTypes={estimatesRankingTypes}
      />
    </Provider>
  );
}

ConsensusEstimates.propTypes = {
  market: PropTypes.string,
  minRatings: PropTypes.number,
  availableMarkets: PropTypes.oneOfType([PropTypes.array]),
  minConsensusEstimatesRatings: PropTypes.oneOfType([PropTypes.object]),
  estimatesRankingTypes: PropTypes.oneOfType([PropTypes.object]),
};

export default ConsensusEstimates;
