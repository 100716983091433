import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { createLoadableComponent } from '../../../utils/Loadable';

const PriceChart = createLoadableComponent(() => import('../../../components/PriceChart'));

export default function PerformanceChart({ countersToCompare }) {
  const counter = countersToCompare[0];
  const restCounters = countersToCompare.map((stock) => stock.replace(/_/g, '.'));
  const [refresh, setRefresh] = useState(0);
  const chartDataURL = '/api/v1/charts/factsheet_overview_chart_data';
  const chartOptions = {
    showZoomOptions: true,
    hasIntradayData: false,
    defaultChartType: 'line',
    zoom: '1y',
    periodicity: {
      label: '1 Day',
      value: {
        base: 'year',
        multiplier: 1,
        periodicity: { period: 1, interval: 1, timeUnit: 'day' },
      },
    },
    chartClasses: 'g-height-330',
  };

  useEffect(() => {
    setRefresh(Date.now());
  }, [countersToCompare]);

  return (
    <PriceChart
      counter={counter}
      dataURL={chartDataURL}
      options={chartOptions}
      countersToCompare={restCounters}
      refresh={refresh}
    />
  );
}

PerformanceChart.propTypes = {
  countersToCompare: PropTypes.arrayOf(PropTypes.string),
};
