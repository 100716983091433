import React, { useRef, useEffect, useState } from 'react';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

function TimeRangePicker({
  startName,
  startValue,
  endName,
  endValue,
  handleChange,
  placeholderStart,
  placeholderEnd,
}) {
  const startRef = useRef(null);
  const endRef = useRef(null);
  const [error, setError] = useState(null);

  const handleTimeChange = (name, value) => {
    const timeStart = startRef.current.value;
    const timeEnd = endRef.current.value;

    // Ensure end time is not earlier than start time
    if (name === endName && timeStart && value < timeStart) {
      setError('End time cannot be earlier than start time');
    } else {
      setError(null);
      handleChange({ target: { name, value } });
    }
  };

  useEffect(() => {
    const startPicker = flatpickr(startRef.current, {
      enableTime: true,
      noCalendar: true,
      dateFormat: 'H:i',
      static: true,
      defaultDate: startValue,
      time_24hr: true,
      onChange: (selectedDates, dateStr) => {
        handleTimeChange(startName, dateStr);
      },
    });

    const endPicker = flatpickr(endRef.current, {
      enableTime: true,
      noCalendar: true,
      dateFormat: 'H:i',
      static: true,
      defaultDate: endValue,
      time_24hr: true,
      onChange: (selectedDates, dateStr) => {
        handleTimeChange(endName, dateStr);
      },
    });

    return () => {
      startPicker.destroy();
      endPicker.destroy();
    };
  }, []);

  return (
    <div>
      <div className="d-flex gap-3">
        <div className="m-0 icon-input-wrapper">
          <Form.Control
            ref={startRef}
            name={startName}
            placeholder={placeholderStart || 'Start Time'}
            value={startValue}
            data-input
          />
          <span className="form-control-icon">
            <i className="fa-thin fa-clock" />
          </span>
        </div>

        <div className="m-0 icon-input-wrapper">
          <Form.Control
            ref={endRef}
            name={endName}
            placeholder={placeholderEnd || 'End Time'}
            value={endValue}
            data-input
          />
          <span className="form-control-icon">
            <i className="fa-thin fa-clock" />
          </span>
        </div>
      </div>

      {error && <div className="text-danger mt-2">{error}</div>}
    </div>
  );
}

export default TimeRangePicker;
TimeRangePicker.propTypes = {
  startName: PropTypes.string,
  startValue: PropTypes.string,
  endName: PropTypes.string,
  endValue: PropTypes.string,
  handleChange: PropTypes.func,
  placeholderStart: PropTypes.string,
  placeholderEnd: PropTypes.string,
};
