import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import DOMPurify from 'dompurify';

import ConsensusEstimatesGaugeChart from '../../../components/ConsensusEstimatesGaugeChart';

const RECOMMENDATION_COLOR = {
  buy: { color: '#2aa300' },
  overweight: { color: '#7fd03b' },
  hold: { color: '#f9c301' },
  underweight: { color: '#f96903' },
  sell: { color: '#f82735' },
};

const ESTIMATES_COMPARISON_CONTENT = (data, period = null) => {
  let targetPriceSubtitle = `Target price was ${period.target_price_value}.<br />Now it is&nbsp;${data.estimates_comparison.now.target_price_value}.`;
  let changeRevenueSubtitle = `Forecasted revenue was ${period.revenue_value} M. <br />Now it is ${data.estimates_comparison.now.revenue_value} M.`;
  let changeProbabilitySubtitle = `Forecasted profit was ${period.profitability_value} M.<br />Now it is&nbsp;${data.estimates_comparison.now.profitability_value} M.`;
  let targetPriceValueClassName = 'text-gray-600';
  let changeRevenueValueClassName = 'text-gray-600';
  let changeProbabilityValueClassName = 'text-gray-600';

  if (period.target_price_change_perc > 0) {
    targetPriceValueClassName = 'text-green-200';
  } else if (period.target_price_change_perc < 0) {
    targetPriceValueClassName = 'text-red-200';
  }

  if (period.revenue_change_perc > 0) {
    changeRevenueValueClassName = 'text-green-200';
  } else if (period.revenue_change_perc < 0) {
    changeRevenueValueClassName = 'text-red-200';
  }

  if (period.profitability_change_perc > 0) {
    changeProbabilityValueClassName = 'text-green-200';
  } else if (period.profitability_change_perc < 0) {
    changeProbabilityValueClassName = 'text-red-200';
  }

  if (period.target_price_change_perc === '-') {
    targetPriceSubtitle = `No change in target price at&nbsp;${data.estimates_comparison.now.target_price_value}.`;
  } else if (period.target_price_value === 'n.a.') {
    targetPriceSubtitle = `Consensus for forecasted target price is not available.`;
  }

  if (period.revenue_change_perc === '-') {
    changeRevenueSubtitle = `No change in forecasted revenue at&nbsp;${data.estimates_comparison.now.revenue_value} M.`;
  } else if (period.revenue_change_perc === 'n.a.') {
    changeRevenueSubtitle = 'Consensus for forecasted revenue is not available.';
  }

  if (period.profitability_change_perc === '-') {
    changeProbabilitySubtitle = `No change in forecasted profit at&nbsp;${data.estimates_comparison.now.profitability_value} M.`;
  } else if (period.profitability_change_perc === 'n.a.') {
    changeProbabilitySubtitle = `Consensus for forecasted profit is not available.`;
  }

  return [
    {
      title: 'Target Price Change',
      subtitle: targetPriceSubtitle,
      value: `${period.target_price_change_perc}${
        Number(period.target_price_change_perc.replaceAll(',', '')) ? '%' : ''
      }`,
      valueClassName: targetPriceValueClassName,
    },
    {
      title: 'Change in Revenue',
      subtitle: changeRevenueSubtitle,
      value: `${period.revenue_change_perc}${
        Number(period.revenue_change_perc.replaceAll(',', '')) ? '%' : ''
      }`,
      valueClassName: changeRevenueValueClassName,
    },
    {
      title: 'Change In Profitability',
      subtitle: changeProbabilitySubtitle,
      value: `${period.profitability_change_perc}${
        Number(period.profitability_change_perc.replaceAll(',', '')) ? '%' : ''
      }`,
      valueClassName: changeProbabilityValueClassName,
    },
  ];
};

function Overview({ data }) {
  const recommendationTextColor =
    RECOMMENDATION_COLOR[data.estimates.recommendation?.toLowerCase()];
  const estimatesComparisonPeriodTabs = {
    one_week: '1 Week',
    one_month: '1 Month',
    three_months: '3 Months',
    six_months: '6 Months',
    one_year: '1 Year',
  };

  const [activeTab, setActiveTab] = useState('three_months');
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const period = data.estimates_comparison[activeTab] || null;

  return (
    <div className="container">
      {data.estimates && (
        <div className="row align-items-center ">
          <div className="col-lg-5 g-mb-30 g-lg-mb-0 text-center">
            <ConsensusEstimatesGaugeChart recommendation={data.estimates.recommendation} />
          </div>
          <div className="col-lg-7 text-center">
            <div className="d-flex flex-column align-items-center g-mb-40">
              <div className="h5 g-mb-20">
                Mean Target Price <small>({data.estimates.currency})</small>
              </div>
              <div className="h1 g-mb-0 g-fw-500" style={recommendationTextColor}>
                {data.estimates.target_price_f}
              </div>
              <div className="g-mt-20 g-width-150">
                <div className="d-flex flex-row justify-content-between g-text-size-18 g-fw-500">
                  <span>{data.estimates.low_target_price_f}</span>
                  <span>-</span>
                  <span>{data.estimates.high_target_price_f}</span>
                </div>
                <input
                  type="range"
                  className="form-range consensus-estimate-range"
                  value={data.estimates.target_price}
                  min={data.estimates.low_target_price}
                  max={data.estimates.high_target_price}
                  id="consensus-estimate-range"
                  disabled
                />
                <div className="d-flex flex-row justify-content-between g-text-size-14">
                  <span>Min</span>
                  <span>Max</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6 col-sm-3 g-mb-15 g-sm-mb-0">
                <div className="card h-100 p-3 lh-sm border-0 basic-card text-center">
                  <div className="h3">{data.estimates.rating_note}</div>
                  <div className="">Consensus Rating</div>
                </div>
              </div>
              <div className="col-6 col-sm-3 g-mb-15 g-sm-mb-0">
                <div className="card h-100 p-3 lh-sm border-0 basic-card text-center">
                  <div className="h3">{data.estimates.number_of_ratings}</div>
                  <div className="">Number of Ratings</div>
                </div>
              </div>
              <div className="col-6 col-sm-3 g-mb-15 g-sm-mb-0">
                <div className="card h-100 p-3 lh-sm border-0 basic-card text-center">
                  <div className="h3" style={recommendationTextColor}>
                    {data.estimates.upside}
                  </div>
                  <div className="">Upside</div>
                </div>
              </div>
              <div className="col-6 col-sm-3 g-mb-15 g-sm-mb-0">
                <div className="card h-100 p-3 lh-sm border-0 basic-card text-center">
                  <div className="h3" style={recommendationTextColor}>
                    {data.estimates.upside_perc}
                  </div>
                  <div className="">Upside (%)</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <hr className="g-mt-50 g-mb-50" />
      <Tab.Container defaultActiveKey={activeTab}>
        <div className="row align-items-center g-mb-50">
          <div className="col-4 col-lg-auto">
            <div className="h6 g-mb-0">Estimate Changes Over Past</div>
          </div>
          <div className="col-8 col-lg">
            <Nav variant="pills" className="g-mb-0">
              {Object.keys(estimatesComparisonPeriodTabs).map((tab) => {
                return (
                  <Nav.Item key={tab}>
                    <Nav.Link
                      className={`nav-link  ${activeTab === tab ? 'active' : ''}`}
                      eventKey={tab}
                      id={tab}
                      onClick={() => handleTabClick(tab)}>
                      {estimatesComparisonPeriodTabs[tab]}
                    </Nav.Link>
                  </Nav.Item>
                );
              })}
            </Nav>
          </div>
          <div className="col-12 g-mt-30">
            {data.estimates_comparison && (
              <Tab.Content>
                <div className="row g-3">
                  {period &&
                    ESTIMATES_COMPARISON_CONTENT(data, period).map((content, index) => {
                      return (
                        <div className="col-lg-4" key={index}>
                          <div className="card h-100 p-3 lh-sm border-0 basic-card">
                            <div className="d-flex justify-content-between align-items-center">
                              <div>
                                <div className="h6">{content.title}</div>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(content.subtitle),
                                  }}
                                />
                              </div>
                              <div className={`h3 ${content.valueClassName}`}>{content.value}</div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </Tab.Content>
            )}
          </div>
        </div>
      </Tab.Container>
    </div>
  );
}

Overview.propTypes = {
  data: PropTypes.shape({
    estimates: PropTypes.shape({
      currency: PropTypes.string,
      number_of_ratings: PropTypes.string,
      rating_note: PropTypes.string,
      recommendation: PropTypes.string,
      target_price: PropTypes.string,
      upside: PropTypes.string,
      upside_perc: PropTypes.string,
    }),
    estimates_comparison: PropTypes.shape({
      now: PropTypes.shape({
        profitability_value: PropTypes.string,
        revenue_value: PropTypes.string,
        target_price_value: PropTypes.string,
        time_frame: PropTypes.string,
      }),
      one_month: PropTypes.shape({
        profitability_change_perc: PropTypes.string,
        profitability_value: PropTypes.string,
        revenue_change_perc: PropTypes.string,
        revenue_value: PropTypes.string,
        target_price_change_perc: PropTypes.string,
        target_price_value: PropTypes.string,
        time_frame: PropTypes.string,
      }),
      one_week: PropTypes.shape({
        profitability_change_perc: PropTypes.string,
        profitability_value: PropTypes.string,
        revenue_change_perc: PropTypes.string,
        revenue_value: PropTypes.string,
        target_price_change_perc: PropTypes.string,
        target_price_value: PropTypes.string,
        time_frame: PropTypes.string,
      }),
      one_year: PropTypes.shape({
        profitability_change_perc: PropTypes.string,
        profitability_value: PropTypes.string,
        revenue_change_perc: PropTypes.string,
        revenue_value: PropTypes.string,
        target_price_change_perc: PropTypes.string,
        target_price_value: PropTypes.string,
        time_frame: PropTypes.string,
      }),
      six_months: PropTypes.shape({
        profitability_change_perc: PropTypes.string,
        profitability_value: PropTypes.string,
        revenue_change_perc: PropTypes.string,
        revenue_value: PropTypes.string,
        target_price_change_perc: PropTypes.string,
        target_price_value: PropTypes.string,
        time_frame: PropTypes.string,
      }),
      three_months: PropTypes.shape({
        profitability_change_perc: PropTypes.string,
        profitability_value: PropTypes.string,
        revenue_change_perc: PropTypes.string,
        revenue_value: PropTypes.string,
        target_price_change_perc: PropTypes.string,
        target_price_value: PropTypes.string,
        time_frame: PropTypes.string,
      }),
    }),
  }),
};

export default Overview;
