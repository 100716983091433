import React, { useEffect, useState } from 'react';
import { Provider, useDispatch } from 'react-redux';
import { BrowserRouter, Routes, Route, useLocation, useNavigate, Outlet } from 'react-router-dom';
import { Container, Row, Col, Nav } from 'react-bootstrap';
import PropTypes from 'prop-types';
import axios from 'axios';
import EditProfile from './EditProfile';
import PaymentHistory from './PaymentHistory';
import store from '../../../utils/store';

import Setting from './Setting';
import Plan from './Plan';
import { setPerformCheck, setSiteKey, setUserId, fetchMembershipProfile } from './membershipSlice';
import styles from './membership.module.css';

function MembershipCorner({ siteKey = '', performCheck = true, userId = '' }) {
  return (
    <Provider store={store}>
      <Container fluid>
        <Row>
          <Col>
            <div className={`g-lg-mb-30 g-mb-20 text-center ${styles.membershipOffset}`}>
              <MembershipCornerMain siteKey={siteKey} performCheck={performCheck} userId={userId} />
            </div>
          </Col>
        </Row>
      </Container>
    </Provider>
  );
}

function MembershipCornerMain({ siteKey = '', performCheck = true, userId = '' }) {
  /* Put in redux site_key and perform_check. */
  const useTypedDispatch = () => useDispatch();
  const dispatch = useTypedDispatch();
  const { CancelToken } = axios;

  useEffect(() => {
    dispatch(setPerformCheck(performCheck));
    dispatch(setSiteKey(siteKey));
    dispatch(setUserId(userId));

    /* Load membership profile for first time only. */
    const source = CancelToken.source();
    dispatch(fetchMembershipProfile({ cancelToken: source.token }));
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/membership/" element={<Layout />}>
          <Route index path="edit_profile" element={<EditProfile />} />
          <Route path="plan" element={<Plan />} />
          <Route path="payment_history" element={<PaymentHistory />} />
          <Route path="settings" element={<Setting />} />
          <Route path="*" element={<EditProfile />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

function Layout() {
  return (
    <>
      <Header />
      <Outlet />
    </>
  );
}

function Header() {
  const location = useLocation();
  const navigate = useNavigate();
  const [activePath, setActivePath] = useState(location.pathname);
  const handleTabSelect = (eventKey) => {
    navigate(eventKey);
    setActivePath(eventKey);
  };

  return (
    <div className={styles.membershipParent}>
      <Nav
        variant="underline"
        onSelect={handleTabSelect}
        defaultActiveKey={activePath}
        className={styles.membershipTab}>
        <Nav.Item className={styles.membershipNav}>
          <Nav.Link eventKey="/membership/edit_profile">
            <EditProfileIcon active={activePath === '/membership/edit_profile'} />
          </Nav.Link>
        </Nav.Item>
        {/** ***
        <Nav.Item className={styles.membershipNav}>
          <Nav.Link eventKey="/membership/plan">
            <PlanIcon active={activePath === '/membership/plan'} />
          </Nav.Link>
        </Nav.Item>
        **** */}
        <Nav.Item className={styles.membershipNav}>
          <Nav.Link eventKey="/membership/payment_history">
            <PaymentHistoryIcon active={activePath === '/membership/payment_history'} />
          </Nav.Link>
        </Nav.Item>
        <Nav.Item className={styles.membershipNav}>
          <Nav.Link eventKey="/membership/settings">
            <SettingIcon active={activePath === '/membership/settings'} />
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </div>
  );
}

/* We are using d-none to show and hide because manual fontawesome has converted <i> to SVG, class name not gonna work. */

function EditProfileIcon({ active }) {
  return (
    <div className={`${styles.tabButton} ${active ? styles.active : ''}`}>
      <div className={active ? 'd-none' : ''}>
        <i className="fa-light fa-user-pen fa-fw fa-lg" />
      </div>
      <div className={active ? '' : 'd-none'}>
        <i className="fa-solid fa-user-pen fa-fw fa-lg" />
      </div>
      <div className={styles.tabText}>Edit Profile</div>
    </div>
  );
}

function PlanIcon({ active }) {
  return (
    <div className={`${styles.tabButton} ${active ? styles.active : ''}`}>
      <div className={active ? 'd-none' : ''}>
        <i className="fa-light fa-address-card fa-fw fa-lg" />
      </div>
      <div className={active ? '' : 'd-none'}>
        <i className="fa-solid fa-address-card fa-fw fa-lg" />
      </div>
      <div className={styles.tabText}>Plan Subscription</div>
    </div>
  );
}

function PaymentHistoryIcon({ active }) {
  return (
    <div className={`${styles.tabButton} ${active ? styles.active : ''}`}>
      <div className={active ? 'd-none' : ''}>
        <i className="fa-light fa-file-invoice-dollar fa-fw fa-lg" />
      </div>
      <div className={active ? '' : 'd-none'}>
        <i className="fa-solid fa-file-invoice-dollar fa-fw fa-lg" />
      </div>
      <div className={styles.tabText}>Payment History</div>
    </div>
  );
}

function SettingIcon({ active }) {
  return (
    <div className={`${styles.tabButton} ${active ? styles.active : ''}`}>
      <div className={active ? 'd-none' : ''}>
        <i className="fa-light fa-gear fa-fw fa-lg" />
      </div>
      <div className={active ? '' : 'd-none'}>
        <i className="fa-solid fa-gear fa-fw fa-lg" />
      </div>
      <div className={styles.tabText}>Settings</div>
    </div>
  );
}

const activePropType = {
  active: PropTypes.bool,
};

EditProfileIcon.propTypes = activePropType;
PlanIcon.propTypes = activePropType;
PaymentHistoryIcon.propTypes = activePropType;
SettingIcon.propTypes = activePropType;

MembershipCorner.propTypes = {
  siteKey: PropTypes.string,
  performCheck: PropTypes.bool,
  userId: PropTypes.string,
};

MembershipCornerMain.propTypes = {
  siteKey: PropTypes.string,
  performCheck: PropTypes.bool,
  userId: PropTypes.string,
};

export default MembershipCorner;
