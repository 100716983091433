import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';

export default function ModalData({
  show,
  taResults,
  stockInfo,
  counter,
  taResultsDate,
  handleClose,
}) {
  return (
    <Modal show={show} centered dialogClassName="g-text-size-14" onHide={handleClose}>
      <Modal.Header closeButton className="border-0 g-pb-0">
        <Modal.Title>{stockInfo?.stockName}</Modal.Title>
      </Modal.Header>
      <div className="px-3">
        <span className="fw-bold">{counter}</span>
        <span className="text-muted float-end">Price updated at {stockInfo?.dateTime}</span>
      </div>
      <div className="p-3">
        <table className="stock-popup-table w-100 g-mb-0 borderless">
          <tbody>
            <tr>
              <th className="g-fw-400 g-pl-0">
                Last {stockInfo?.currency ? `(${stockInfo?.currency})` : ''}
              </th>
              <td className="g-fw-500 text-end">{stockInfo?.lastDone}</td>
              <th className="g-fw-400 g-pl-100">{stockInfo?.volumeText}</th>
              <td className="g-fw-500 text-end">{stockInfo?.volume}</td>
            </tr>
            <tr>
              <th className="g-fw-400 g-pl-0">Change</th>
              <td className={`g-fw-500 text-end ${stockInfo?.colorClass}`}>{stockInfo?.change}</td>
              <th className="g-fw-400 g-pl-100">Day Range</th>
              <td className="g-fw-500 text-end">{stockInfo?.dayRange}</td>
            </tr>
            <tr>
              <th className="g-fw-400 g-pl-0">Change (%)</th>
              <td className={`g-fw-500 text-end ${stockInfo?.colorClass}`}>
                {stockInfo?.changePercent}
              </td>
              <th className="g-fw-400 g-pl-100">Open</th>
              <td className="g-fw-500 text-end">{stockInfo?.open}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr className="mx-3 my-0" />
      <Modal.Body>
        {taResultsDate && (
          <div className="text-muted pb-2">
            TA Scanner Results based on historical data up to {taResultsDate}
          </div>
        )}
        {taResults && taResults.length > 0 && (
          <table className="table mb-0 d-block g-max-height-300 overflow-scroll">
            <thead className="table-light sticky-top">
              <tr>
                <th className="align-middle g-per-width-45">Condition</th>
                <th className="align-middle text-center g-per-width-10">Signal</th>
                <th className="align-middle text-center g-per-width-45">Explanation</th>
              </tr>
            </thead>
            <tbody>
              {taResults.map((result) => {
                return (
                  <tr key={`${result.id}`}>
                    <td className="text-left">{result.description}</td>
                    <td className="text-center">{result.signal}</td>
                    <td className="text-left">{result.explanation}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </Modal.Body>
    </Modal>
  );
}

ModalData.propTypes = {
  show: PropTypes.bool,
  taResults: PropTypes.array,
  stockInfo: PropTypes.object,
  counter: PropTypes.string,
  taResultsDate: PropTypes.string,
  handleClose: PropTypes.func,
};
