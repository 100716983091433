import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { CURRENCIES } from '../currencies';

// import classes from './portfolio.module.css';
import { createFetchData, updateFetchData } from '../portfolioSlice';

const { CancelToken } = axios;

const optSGD = 'S';

function CreateUpdatePortfolioModal({ show, handleCloseModal, currentPortfolio }) {
  const dispatch = useDispatch();
  const source = CancelToken.source();
  const innerRef = useRef(null);
  const [name, setName] = useState(currentPortfolio ? currentPortfolio.name : '');
  const [currency, setCurrency] = useState(currentPortfolio ? null : optSGD);
  const [defaultUponCreate, setDefaultUponCreate] = useState(currentPortfolio ? null : false);
  const [classicPortfolioFlag, setClassicPortfolioFlag] = useState(false);
  const [error, setError] = useState('');

  const handleNameChange = (e) => {
    let { value } = e.target;
    if (!value || value === name) {
      return;
    }
    value = value.trim();
    setName(value);
  };

  const handleCurrencyChange = (newCurrency) => {
    if (!newCurrency || currency === newCurrency) {
      return;
    }
    setCurrency(newCurrency);
  };

  const handleClose = () => {
    handleCloseModal();
    setName('');
    setCurrency(currentPortfolio ? null : optSGD);
    setDefaultUponCreate(currentPortfolio ? null : false);
    setClassicPortfolioFlag(false);
  };

  const handleSave = (e) => {
    const params = { name, currency, setDefault: null, cancelToken: source.token };

    if (e && e.target && e.target.value) {
      params.name = e.target.value;
    }

    if (defaultUponCreate) {
      params.setDefault = '1';
    }

    if (classicPortfolioFlag) {
      params.classicFlag = '1';
    }

    if (params.name && params.name.length > 0) {
      if (currentPortfolio && currentPortfolio.name) {
        params.id = currentPortfolio.id;
        dispatch(updateFetchData(params));
      } else {
        dispatch(createFetchData(params));
      }
      handleClose();
    } else {
      setError('Portfolio name is missing!');
    }
  };

  useEffect(() => {
    if (innerRef.current) {
      innerRef.current.focus();
    }
  }, [show]);

  return (
    <Modal show={show} centered dialogClassName="g-text-size-14`" onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{currentPortfolio ? 'Rename Portfolio' : 'Create New Portfolio'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="row mt-2">
          <h6>Portfolio Name</h6>
          <Form.Group className="col-12 g-mb-20">
            <Form.Control
              type="text"
              placeholder="Enter portfolio name"
              className="w-100"
              onBlur={(e) => {
                handleNameChange(e);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  handleNameChange(e);
                  handleSave(e);
                }
              }}
              isInvalid={!!error}
              defaultValue={currentPortfolio ? currentPortfolio.name : name}
              ref={innerRef}
              autoFocus={show}
            />
            <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
          </Form.Group>
          <br />
          {!currentPortfolio && (
            <>
              <h6>Portfolio Currency</h6>
              <Dropdown className="g-mb-20">
                <Dropdown.Toggle variant="light" className="col-12 mt-1 mb-2 d-flex">
                  <span className="g-mr-10 text-break">
                    {CURRENCIES.filter((c) => c.value === currency)[0] &&
                      CURRENCIES.filter((c) => c.value === currency)[0].label}
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="col-12 mt-1 mb-2">
                  {CURRENCIES.filter((c) => c.value !== currency).map((c) => {
                    return (
                      <Dropdown.Item
                        key={c.value}
                        value={c.value}
                        onClick={() => {
                          handleCurrencyChange(c.value);
                        }}>
                        <span className="g-mr-10 text-break">{c.label}</span>
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
              <div className="col-12 g-mb-20 d-flex align-items-center">
                <Form.Check
                  type="checkbox"
                  id="create_default_portfolio_checkbox"
                  value=""
                  checked={defaultUponCreate}
                  onChange={() => setDefaultUponCreate(!defaultUponCreate)}
                />
                <label className="g-ml-10 g-mt-4" htmlFor="create_default_portfolio_checkbox">
                  Set default
                </label>
              </div>
              <div className="col-12 g-mb-20">
                <div className="d-flex align-items-center">
                  <Form.Check
                    type="checkbox"
                    id="create_classic_portfolio_checkbox"
                    value=""
                    checked={classicPortfolioFlag}
                    onChange={() => setClassicPortfolioFlag(!classicPortfolioFlag)}
                  />
                  <label className="g-ml-10 g-mt-4" htmlFor="create_classic_portfolio_checkbox">
                    Do you want to add stocks without transactions to this portfolio?
                  </label>
                </div>
                <Form.Text muted>
                  For proper segregation of your monitored stocks, we recommend that you keep stocks
                  that you want to monitor in the Watchlist instead.
                </Form.Text>
              </div>
            </>
          )}
          <Form.Group className="col-12 g-mb-10 d-flex align-items-center justify-content-center">
            <Button variant="primary" onClick={handleSave}>
              {currentPortfolio ? 'Save' : 'Create'}
            </Button>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

CreateUpdatePortfolioModal.propTypes = {
  show: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  currentPortfolio: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    currency: PropTypes.string,
    default: PropTypes.bool,
  }),
};

export default CreateUpdatePortfolioModal;
