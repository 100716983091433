import { createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';

const financialsSlice = createSlice({
  name: 'financials',
  initialState: {
    data: {},
    loading: true,
    err: null,
    period: 'fy',
    periodCols: null,
    page: 1,
  },
  reducers: {
    setPeriod(state, action) {
      state.period = action.payload;
    },
    setPeriodCols(state, action) {
      state.periodCols = action.payload;
    },
    setPage(state, action) {
      state.page = action.payload;
    },
    financialsDataFetchStart(state, action) {
      state.data = null;
      state.err = null;
      state.loading = true;
    },
    financialsDataFetchSuccess(state, action) {
      state.data = action.payload.data;
      state.loading = false;
    },
    financialsDataFetchFail(state, action) {
      state.err = action.payload;
      state.loading = false;
    },
  },
});

export default financialsSlice.reducer;

export const {
  setPeriod,
  setPeriodCols,
  financialsDataFetchStart,
  financialsDataFetchSuccess,
  financialsDataFetchFail,
  setPage,
} = financialsSlice.actions;

export const fetchData =
  ({ counter, cancelToken, period, periodCols, page }) =>
  (dispatch) => {
    dispatch(financialsDataFetchStart());
    api
      .get(`quote/${counter}/financials.json`, {
        params: { period, cols: periodCols, page },
        cancelToken,
      })
      .then(({ data }) => {
        dispatch(financialsDataFetchSuccess({ data }));
      })
      .catch((error) => {
        dispatch(financialsDataFetchFail(error));
      });
  };

export const onChangePeriod =
  ({ counter, cancelToken, period, periodCols, page }) =>
  (dispatch) => {
    dispatch(setPeriod(period));
    dispatch(fetchData({ counter, cancelToken, period, periodCols, page }));
  };

export const onChangePeriodCols =
  ({ counter, cancelToken, period, periodCols, page }) =>
  (dispatch) => {
    dispatch(setPeriodCols(periodCols));
    dispatch(fetchData({ counter, cancelToken, period, periodCols, page }));
  };

export const onChangePage =
  ({ counter, cancelToken, period, periodCols, page }) =>
  (dispatch) => {
    dispatch(setPeriodCols(periodCols));
    dispatch(fetchData({ counter, cancelToken, period, periodCols, page }));
  };
