import { createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';

const factsheetOverviewSlice = createSlice({
  name: 'factsheetOverview',
  initialState: {
    loading: true,
    completed: false,
    data: null,
    err: null,
  },
  reducers: {
    factsheetOverviewFetchStart(state, action) {
      state.loading = true;
      state.completed = false;
      state.data = null;
      state.err = null;
    },
    factsheetOverviewFetchSuccess(state, action) {
      state.data = action.payload.data;
      state.completed = true;
      state.loading = false;
    },
    factsheetOverviewFetchFail(state, action) {
      state.err = action.payload;
      state.completed = false;
      state.loading = false;
    },
  },
});

export default factsheetOverviewSlice.reducer;

const { factsheetOverviewFetchStart, factsheetOverviewFetchSuccess, factsheetOverviewFetchFail } =
  factsheetOverviewSlice.actions;

export const fetchData =
  ({ counter, cancelToken }) =>
  (dispatch) => {
    dispatch(factsheetOverviewFetchStart());
    api
      .get(`quote/${counter}/factsheet.json`, {
        cancelToken,
      })
      .then(({ data }) => {
        dispatch(factsheetOverviewFetchSuccess({ data }));
      })
      .catch((error) => {
        dispatch(factsheetOverviewFetchFail(error));
      });
  };
