import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';

import { useSelector, useDispatch } from 'react-redux';
import AddCriterias from './AddCriterias';
import CriteriaTemplateRenderer from './CriteriaTemplateRenderer';
import './marketScreener.scss';
import DOMPurify from 'dompurify';
import { setAddedCriterias } from './marketScreenerSlice';

function DesktopSelectedCriteria({
  fieldKey,
  fieldIndex,
  attribute,
  parentAttribute,
  parentKey,
  parentIndex,
  market,
  criteriaConfig,
  criteriaGroups,
  stockCategories,
}) {
  const dispatch = useDispatch();
  const invalidForms = useSelector((state) => state.marketScreener.invalidForms);
  const addedCriterias = useSelector((state) => state.marketScreener.addedCriterias);
  const criteriasDropdownLabel = useSelector(
    (state) => state.marketScreener.criteriasDropdownLabel,
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [showDropdown, setShowDropdown] = useState({});
  const [dropdownInvalidStatus, setDropdownInvalidStatus] = useState(false);
  const dropdown = useRef(null);

  const handleToggleDropdown = (key, show) => {
    if (!modalOpen) {
      setShowDropdown((prev) => ({
        ...prev,
        [key]: show,
      }));
    } else {
      setShowDropdown((prev) => ({
        ...prev,
        [key]: true, // if modal is open, force dropdown open!
      }));
    }
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setTimeout(() => {
      setModalOpen(false); // add some delay to prevent Dropdown close immediately together with Modal
    }, 500);
  };

  // invalidForms contain all the invalid criterias
  // eg {main_criteria_index}_{sub_criteria_index}
  // if parentIndex is defined, check invalid sub criteria
  // otherwise, check invalid main criteria
  const containsInvalid = (index) => {
    return invalidForms.some((invalidCriteria) => {
      const parts = invalidCriteria.split('_');
      if (parentIndex !== undefined) {
        return parts[1] == index;
      }
      return parts[0] == index;
    });
  };

  let label;
  if (criteriasDropdownLabel.length > 0) {
    if (parentIndex !== undefined) {
      label = criteriasDropdownLabel[parentIndex]?.sub_criteria[fieldIndex];
    } else {
      label = criteriasDropdownLabel[fieldIndex]?.value;
    }
  }

  const renderCriteriaTemplate = (key, index, attr, isMain) => {
    return (
      <CriteriaTemplateRenderer
        market={market}
        fieldKey={key}
        fieldIndex={index}
        attribute={attr}
        stockCategories={stockCategories}
        validated={invalidForms.includes(isMain ? index.toString() : `${parentIndex}_${index}`)}
        orCriteria={!isMain}
        parentKey={isMain ? undefined : parentKey !== undefined ? parentKey : fieldKey}
        parentIndex={isMain ? undefined : parentIndex !== undefined ? parentIndex : fieldIndex}
      />
    );
  };

  const renderSubCriteria = (subCriterias) =>
    subCriterias.map((subCriterias, sub_index) =>
      Object.entries(subCriterias).map(([sub_key, sub_attribute]) => (
        <div key={sub_key} className="sub_criteria_row g-mb-15" data-index={sub_index}>
          {renderCriteriaTemplate(sub_key, sub_index, sub_attribute, false)}
        </div>
      )),
    );

  const handleActiveCriteriaCheckboxChange = (event) => {
    if (!event) {
      return;
    }

    const { checked, value } = event.target;
    const fIndex = event.target.getAttribute('data-index');
    const updatedCriterias = JSON.parse(JSON.stringify(addedCriterias));
    const criteria = updatedCriterias[fIndex][value];
    criteria.active = checked;

    dispatch(
      setAddedCriterias({
        criteria: updatedCriterias,
      }),
    );
  };

  useEffect(() => {
    // update dropdown invalid status based on invalidForms state (when "Screen Now" is clicked)
    const initialDropdownInvalidStatus = containsInvalid(fieldIndex);
    setDropdownInvalidStatus(initialDropdownInvalidStatus);
  }, [invalidForms]);

  useEffect(() => {
    // Update dropdown invalid status if there's any invalid/blank input fields.
    const dropdownContainer = dropdown.current;
    const invalidInputExists =
      dropdownContainer.querySelectorAll('.form-control:invalid').length > 0;

    setDropdownInvalidStatus(invalidInputExists);
  }, [addedCriterias, criteriasDropdownLabel]);

  return (
    <Dropdown
      show={showDropdown[fieldIndex]}
      ref={dropdown}
      className="criteria_row_wrapper"
      onToggle={(show) => handleToggleDropdown(fieldIndex, show)}>
      <div className="desktop_criteria_dropdown d-flex gap-2 align-items-center g-pl-13 g-pr-0 border g-border-radius-10 ">
        {!parentKey && !parentIndex && (
          <input
            type="checkbox"
            onChange={handleActiveCriteriaCheckboxChange}
            checked={attribute.active}
            value={fieldKey}
            data-index={fieldIndex}
          />
        )}

        <Dropdown.Toggle
          variant="light"
          className={`criteria_dropdown ${dropdownInvalidStatus ? 'invalid' : ''} ${
            attribute.active ? '' : 'disabled'
          } ps-0 border-0`}>
          <span>{attribute.title}</span>
          {label && (
            <span
              className="g-ml-10 g-pl-10 g-border-l-1 g-mr-10"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(label) }}
            />
          )}
        </Dropdown.Toggle>
      </div>

      <Dropdown.Menu renderOnMount className="g-lg-width-800 z-3" data-index={fieldIndex}>
        <div className="criteria_row_form g-mb-15">
          {parentKey === undefined
            ? renderCriteriaTemplate(fieldKey, fieldIndex, attribute, true)
            : renderCriteriaTemplate(parentKey, parentIndex, parentAttribute, true)}
          {parentKey === undefined
            ? renderSubCriteria(attribute.sub_criterias)
            : renderSubCriteria(parentAttribute.sub_criterias)}
        </div>
        <AddCriterias
          market={market}
          criteriaConfig={criteriaConfig}
          criteriaGroups={criteriaGroups}
          parentKey={parentKey === undefined ? fieldKey : parentKey}
          parentIndex={parentIndex === undefined ? fieldIndex : parentIndex}
          orCriteria
          isMobile={false}
          onModalOpen={handleModalOpen}
          onModalClose={handleModalClose}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
}

DesktopSelectedCriteria.propTypes = {
  fieldKey: PropTypes.string,
  fieldIndex: PropTypes.number,
  attribute: PropTypes.oneOfType([PropTypes.object]),
  market: PropTypes.string,
  criteriaConfig: PropTypes.oneOfType([PropTypes.object]),
  criteriaGroups: PropTypes.oneOfType([PropTypes.object]),
  stockCategories: PropTypes.oneOfType([PropTypes.object]),
};

export default DesktopSelectedCriteria;
