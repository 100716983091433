import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import store from '../../../utils/store';
import InsiderTradesMain from './InsiderTradesMain';

function InsiderTrades({ market, availableMarkets, counter }) {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <div className="row align-items-center g-lg-mb-30 g-mb-20">
          <div className="col-lg-8">
            <h3 className="g-mb-5 g-lg-mb-0">Insider Trades</h3>
          </div>
          <div className="col-lg-4 d-flex justify-content-lg-end justify-content-start" />
        </div>
        <div className="divider-h w-100 g-mb-20 g-lg-mb-30" />
        <Routes>
          <Route
            path="/:id/insider_trades"
            element={
              <InsiderTradesMain
                counter={counter}
                market={market}
                availableMarkets={availableMarkets}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

InsiderTrades.propTypes = {
  market: PropTypes.string,
  availableMarkets: PropTypes.oneOfType([PropTypes.array]),
  counter: PropTypes.oneOfType([PropTypes.object]),
};

export default InsiderTrades;
