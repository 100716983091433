/* eslint-disable react/prop-types */
import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

function getLayoutOptions({ filter, market, isLegalUser, isEodUser, isPortfolio }) {
  const wldIndicesForexAndCommodities = ['WI01', 'WX01', 'WF01'];
  const wldIndicesForexCommoditiesAndMktIndices = ['CS06', 'WI01', 'WX01', 'WF01'];
  const usMarkets = ['nasdaq', 'nyse', 'nyse_mkt'];
  const layoutsConfig = [
    { trading_data: 'Trading Data', canDisplay: true },
    { portfolio: 'Portfolio', canDisplay: isPortfolio && isLegalUser },
    {
      fundamental: 'Fundamental',
      canDisplay: !isEodUser && !wldIndicesForexAndCommodities.includes(filter),
    },
    {
      tsr: 'Total Returns',
      canDisplay: !isEodUser && !wldIndicesForexAndCommodities.includes(filter),
    },
    {
      ratios: 'Ratios',
      canDisplay: !isEodUser && !wldIndicesForexCommoditiesAndMktIndices.includes(filter),
    },
    {
      rolling_pe: 'Trailing PE',
      canDisplay: !isEodUser && !wldIndicesForexCommoditiesAndMktIndices.includes(filter),
    },
    {
      beta_cagr: 'Beta/CAGR',
      canDisplay:
        !isEodUser &&
        !wldIndicesForexCommoditiesAndMktIndices.includes(filter) &&
        !usMarkets.includes(market),
    },
    {
      cagr: 'CAGR',
      canDisplay:
        !isEodUser &&
        !wldIndicesForexCommoditiesAndMktIndices.includes(filter) &&
        usMarkets.includes(market),
    },
    {
      ipo_details: 'IPO Details',
      canDisplay: !wldIndicesForexCommoditiesAndMktIndices.includes(filter),
    },
    {
      highlow: 'High/Low',
      canDisplay: !isEodUser && !wldIndicesForexCommoditiesAndMktIndices.includes(filter),
    },
    {
      historical: 'Historical',
      canDisplay: !isEodUser && !wldIndicesForexCommoditiesAndMktIndices.includes(filter),
    },
    {
      short_sell: 'Short Sell',
      canDisplay: !isEodUser && !wldIndicesForexCommoditiesAndMktIndices.includes(filter),
    },
    {
      custom: 'Custom',
      canDisplay:
        !isEodUser && isLegalUser && !wldIndicesForexCommoditiesAndMktIndices.includes(filter),
    },
  ];

  const layoutOptions = {};
  layoutsConfig
    .filter((x) => x.canDisplay)
    .forEach((x) => {
      const { canDisplay, ...optionObj } = x;
      const key = Object.keys(optionObj)[0];
      layoutOptions[key] = optionObj[key];
    });
  return layoutOptions;
}

export default function LayoutOptions(props) {
  if (!props) {
    return null;
  }

  const { isPortfolio, isLegalUser, isEodUser, filter, market, selectedLayout, setSelectedLayout } =
    props;

  const onClickHandler = (e) => {
    const newLayoutSelected = e.target.getAttribute('layout');
    if (newLayoutSelected && selectedLayout !== newLayoutSelected) {
      setSelectedLayout(newLayoutSelected);
    }
  };

  const layoutOptions = getLayoutOptions({
    filter,
    market,
    isLegalUser,
    isEodUser,
    isPortfolio,
  });
  return (
    <Dropdown drop="down" id="sic_columnLayout" className="d-inline-block">
      <Dropdown.Toggle variant="light">
        <span className="g-mr-10">
          {layoutOptions[selectedLayout]
            ? `Layout: ${layoutOptions[selectedLayout]}`
            : 'Select Layout'}
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {Object.keys(layoutOptions).map((layout) => (
          <Dropdown.Item
            key={layout}
            className={`dropdown-item ${layout === selectedLayout ? 'active' : ''}`}
            layout={layout}
            onClick={onClickHandler}>
            {layoutOptions[layout]}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
