import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';

const TRUNCATE_DESCRIPTION_LETTERS = 100;

function ipoOpenCard({ stock }) {
  const tableConfig = [
    {
      title: 'Listing Price',
      field: 'listing_price',
      icon: 'fa-thin fa-chart-mixed-up-circle-dollar fa-xl',
    },
    {
      title: 'Closing Date',
      field: 'closing_date',
      icon: 'fa-thin fa-calendar-circle-minus fa-xl',
    },
    {
      title: 'Balloting Date',
      field: 'balloting_date',
      icon: 'fa-thin fa-calendar-lines-pen fa-xl',
    },
    { title: 'Listing Date', field: 'listing_date', icon: 'fa-thin fa-calendar-star fa-xl' },
    { title: 'Market', field: 'market', icon: 'fa-thin fa-chart-user fa-xl' },
    { title: 'PE Ratio', field: 'ratio_pe', icon: 'fa-thin fa-percent fa-xl' },
  ];

  const linksConfig = [
    { title: 'IPO Prospectus', field: 'prospectus', icon: 'fa-thin fa-book-open fa-xl' },
    { title: 'Website', field: 'website', icon: 'fa-thin fa-globe fa-xl' },
  ];

  return (
    <Card className="g-mb-30">
      <Card.Header className="p-3">
        <div className="d-inline-block g-per-width-20 align-top">
          {stock.company_logo && (
            <div class="g-pt-6 g-pr-6 g-pb-6 g-pl-6 g-per-width-70 border bg-white">
              <img
                className="img-fluid"
                src={stock.company_logo}
                alt={stock.name}
              />
            </div>
          )}
        </div>
        <div className="d-inline-block g-per-width-80">
          <h5>{stock.name}</h5>
          <Description description={stock.ipo_description} />
        </div>
      </Card.Header>

      <ul className="list-group list-group-flush">
        {tableConfig.map(
          (row, i) =>
            stock[row.field] && (
              <li className="list-group-item border-0 p-3" key={i}>
                <span>
                  <i className={`${row.icon} g-mr-10`} />
                  {row.title}
                </span>
                <span className="float-end">
                  <strong>{stock[row.field]}</strong>
                </span>
              </li>
            ),
        )}
      </ul>
      <Card.Footer className="text-center bg-white">
        {linksConfig.map(
          (row, i) =>
            stock[row.field] && (
              <div className="d-inline-block text-center p-3" key={i}>
                <a
                  className="mx-2 text-dark"
                  href={stock[row.field]}
                  target="_blank"
                  rel="noreferrer">
                  <i className={`${row.icon} g-mr-5`} />
                  {row.title}
                </a>
              </div>
            ),
        )}
      </Card.Footer>
    </Card>
  );
}

export default ipoOpenCard;

ipoOpenCard.propTypes = {
  stock: PropTypes.object,
};

function Description({ description }) {
  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleDescription = (e) => {
    e.preventDefault();
    setShowFullDescription(!showFullDescription);
  };

  const truncateText = (text, length) => {
    if (text.length <= length) {
      return text;
    }
    return `${text.substring(0, length)}...`;
  };

  const displayText = showFullDescription
    ? description
    : truncateText(description, TRUNCATE_DESCRIPTION_LETTERS);

  return (
    <div>
      <p className="text-muted">{displayText}</p>
      <a href="#" className="float-end" onClick={toggleDescription}>
        {showFullDescription ? 'Show Less' : 'Show More'}
      </a>
    </div>
  );
}

Description.propTypes = {
  description: PropTypes.string,
};
