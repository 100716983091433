import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import AddUpdateTransactionModal from './AddUpdateTransactionModal';

function AddTransaction({ label, loading, variant, className }) {
  const [showModal, setShowModal] = useState({ show: false, type: '' });

  const handleAddTransaction = () => {
    setShowModal({ show: true, type: 'transaction' });
  };

  return (
    <>
      <Button
        className={className || 'btn btn-lg'}
        variant={variant || 'light'}
        disabled={loading}
        onClick={handleAddTransaction}>
        {label}
      </Button>
      <AddUpdateTransactionModal show={showModal} setShow={setShowModal} showUploadCSV />
    </>
  );
}

AddTransaction.propTypes = {
  label: PropTypes.object,
  loading: PropTypes.bool,
};

export default AddTransaction;
