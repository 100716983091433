import React from 'react';

import notFoundImg from 'images/errors-404.svg';

export default function NotFound() {
  return (
    <div className="container text-center my-5">
      <img
        alt="Error Code: 404"
        className="g-per-width-60 img-fluid mx-auto d-block mb-10"
        src={notFoundImg}
      />
      <h3 className="g-mb-30">Page Not Found!</h3>
      <p>We are sorry, the page you requested could not be found.</p>
      <p>Please go back to the homepage.</p>
      <div className="text-muted g-mb-30">Error code: 404</div>
      <a className="btn btn-dark" href="/">
        Return Home
      </a>
    </div>
  );
}
