import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Form } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import axios from 'axios';
import Select from 'react-select';
import dayjs from 'dayjs';
import { createTransactionFetchData, updateTransactionFetchData } from './transactionSlice';
import { getCurrencyName } from '../../currencies';
import { getParamCounterId } from '../../../../utils/utils';
import api from '../../../../utils/api';

const { CancelToken } = axios;

const corporateActionActivitiesCodes = {
  bonus_issue: '2',
  add_share: '4',
  share_split: '5',
  share_cons: '6', // Consolidate
};

function extractNumbersFromString(str) {
  const parts = str.split('-');
  const numbers = [];
  parts.forEach((part) => {
    const extractedNumbers = part.match(/\d+/g);
    if (extractedNumbers) {
      numbers.push(...extractedNumbers.map(Number));
    }
  });
  return numbers;
}

function CorporateActionTransactionModal({ show, setShow, editingTransaction }) {
  const dispatch = useDispatch();
  const source = CancelToken.source();
  const [error, setError] = useState('');
  const formRef = useRef(null); // Create a ref

  const selectedPortfolio = useSelector((state) => state.portfolio.selected);
  const [counterListOptions, setCounterListOptions] = useState([]);

  const brokers = useSelector((state) => state.portfolioBroker.brokers);
  const [selectedBroker, setSelectedBroker] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [ratioErrorMessage, setRatioErrorMessage] = useState('');

  const [counter, setCounter] = useState('');
  const [selectedCounter, setSelectedCounter] = useState('');
  const [activity, setActivity] = useState(corporateActionActivitiesCodes.bonus_issue);
  const [price, setPrice] = useState('');
  const [share, setShare] = useState('');
  const [remarks, setRemarks] = useState('');
  const [ratio1, setRatio1] = useState('');
  const [ratio2, setRatio2] = useState('');
  const [stockCurrencyMarket, setStockCurrencyMarket] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [transactionToEdit, setTransactionToEdit] = useState(editingTransaction);
  const [transactionId, setTransactionId] = useState();
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    const counters = selectedPortfolio.counters.filter(
      (c) => c['Shares Held'] && c['Shares Held'] !== '-',
    );
    setCounterListOptions(
      counters.map((c) => ({
        label: `${c.Name}`,
        value: c.counter,
      })),
    );
  }, [selectedPortfolio]);

  const selectedLayout = useSelector((state) => state.portfolio.selectedLayout);

  const [date, setDate] = useState(dayjs(new Date()).format('YYYY-MM-DD HH:mm'));

  const handleBrokerChange = (BrokerId) => {
    const broker = brokers.find((b) => b.Id === parseInt(BrokerId, 10));
    setSelectedBroker(broker);
  };

  useEffect(() => {
    const isEditMode =
      show.type === 'corporateAction' &&
      editingTransaction &&
      typeof editingTransaction === 'object' &&
      Object.keys(editingTransaction).length > 0;

    setEditMode(isEditMode);
    setTransactionToEdit(editingTransaction);
  }, [show.type, editingTransaction]);

  const handleClose = () => {
    setShow({ show: false, type: '' });
  };

  useEffect(() => {
    if (editMode) {
      setTransactionId(transactionToEdit.id);
      setDate(dayjs(transactionToEdit.date).format('YYYY-MM-DD HH:mm'));
      setRemarks(transactionToEdit.note);
      setActivity(transactionToEdit.trade_type.toString());
      setCounter(transactionToEdit.stock_code);

      setPrice(transactionToEdit.price);
      setShare(transactionToEdit.shares);

      setSelectedBroker({ Id: transactionToEdit.broker_id });

      if (transactionToEdit.trade_type.toString() !== corporateActionActivitiesCodes.add_share) {
        const ratios = extractNumbersFromString(transactionToEdit.amount);
        setRatio1(Number(ratios[0]));
        setRatio2(Number(ratios[1]));
      }
    }
  }, [editMode, transactionToEdit]);

  const findCounter = () => {
    for (let i = 0; i < counterListOptions.length; i += 1) {
      if (counterListOptions[i].value === counter) {
        return counterListOptions[i];
      }
    }
    return {};
  };

  useEffect(() => {
    if (editMode) setSelectedCounter(findCounter());
  }, [counter]);

  const handleSave = (e) => {
    e.preventDefault();

    const form = formRef.current; // Access the form using ref
    if (form.checkValidity() === false || !counter || ratioErrorMessage) {
      if (!counter) {
        setErrorMessage('Please select a stock.');
      } else {
        setErrorMessage('');
      }
      e.stopPropagation();
      setValidated(true);
      return;
    }

    let detail = null;

    if (activity === corporateActionActivitiesCodes.bonus_issue) {
      detail = `${ratio1}-for-${ratio2}`;
      setPrice('');
      setShare('');
    }

    if (
      activity === corporateActionActivitiesCodes.share_split ||
      activity === corporateActionActivitiesCodes.share_cons
    ) {
      detail = `${ratio1}-into-${ratio2}`;
      setPrice('');
      setShare('');
    }
    const broker = selectedBroker.Id;
    const folder = selectedPortfolio.id;
    const params = {
      price,
      quantity: share,
      counter,
      activity,
      date,
      folder,
      remarks,
      selectedLayout,
      detail,
      broker,
      cancelToken: source.token,
    };

    if (editMode && transactionToEdit && Object.keys(transactionToEdit).length && transactionId) {
      params.transactionId = transactionId;
      dispatch(updateTransactionFetchData(params));
    } else {
      dispatch(createTransactionFetchData(params));
    }
    handleClose();
  };

  const handleRatioChange = (value, isRatio1) => {
    const newValue = parseFloat(value);
    let newRatio1 = ratio1;
    let newRatio2 = ratio2;

    if (isRatio1) {
      newRatio1 = newValue;
    } else {
      newRatio2 = newValue;
    }

    if (newRatio1 && newRatio2) {
      if (activity === corporateActionActivitiesCodes.share_split && newRatio2 <= newRatio1) {
        setRatioErrorMessage('adjust ratio second field should be higher than the first');
      } else if (activity === corporateActionActivitiesCodes.share_cons && newRatio2 >= newRatio1) {
        setRatioErrorMessage('adjust ratio second field should be lower than the first');
      } else {
        setRatioErrorMessage('');
      }
    }
    setRatio1(newRatio1);
    setRatio2(newRatio2);
  };

  // Clear state when modal is closed
  const resetState = () => {
    setActivity(corporateActionActivitiesCodes.bonus_issue);
    setEditMode(false);
    setRemarks('');
    setError('');
    setShare('');
    setPrice('');
    setRatio1('');
    setRatio2('');
    setCounter('');
    setSelectedCounter('');
    setStockCurrencyMarket('');
    setSelectedBroker({});
    setRatioErrorMessage('');
    setErrorMessage('');
    setDate(dayjs(new Date()).format('YYYY-MM-DD HH:mm'));
    setValidated(false);
  };

  async function fetchDataAndSetStockCurrencyMarket(newCounter) {
    const cancelToken = source.token;

    await api
      .get(
        `/portfolio/${selectedPortfolio.id}/get_price_and_exchange_price?counter=${newCounter}`,
        {
          cancelToken,
        },
      )
      .then(({ data }) => {
        setStockCurrencyMarket(data);
      })
      .catch((e) => {
        console.error('Error fetching exchange rate:', e);
        setStockCurrencyMarket('');
      });
  }

  const handleSelectCounter = (newCounter) => {
    if (!editMode) {
      if (newCounter) {
        setCounter(newCounter.value);
        setSelectedCounter(newCounter);
        setErrorMessage('');
        if (activity === corporateActionActivitiesCodes.add_share) {
          fetchDataAndSetStockCurrencyMarket(newCounter.value);
        }
      } else {
        setCounter('');
        setSelectedCounter('');
        setStockCurrencyMarket('');
      }
    }
  };

  useEffect(() => {
    if (!editMode) {
      setShare('');
      setPrice('');
      setRatio1('');
      setRatio2('');
    }
    setRatioErrorMessage('');
    if (!editMode && activity === corporateActionActivitiesCodes.add_share)
      fetchDataAndSetStockCurrencyMarket(counter);
  }, [activity]);

  useEffect(() => {
    if (!show.show) {
      resetState();
    }
  }, [show.show]);

  const customSelectStyles = {
    option: (provided, state) => ({
      whiteSpace: 'unset',
      lineBreak: 'auto',
      wordBreak: 'break-word',
    }),
  };

  const formatOptionLabel = (data) => {
    return (
      <div>
        <div className="text-wrap">{data.label}</div>
        <div className="text-muted g-text-size-12 text-wrap">({getParamCounterId(data.value)})</div>
      </div>
    );
  };

  return (
    <Modal
      show={show.show && show.type === 'corporateAction'}
      centered
      dialogClassName="g-text-size-14 CorporateActionTransactionModal"
      onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{editMode ? 'Edit ' : ''}Corporate Action</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate validated={validated} onSubmit={handleSave} ref={formRef}>
          {' '}
          <Form.Group className="col-12 g-mb-20">
            <Form.Label>
              <b>Stock</b>
            </Form.Label>
            <Select
              isClearable
              backspaceRemovesValue
              name="stock"
              placeholder="Search stock"
              className="corporateActionFormSelect react-select-container"
              classNamePrefix="react-select"
              value={selectedCounter}
              onChange={handleSelectCounter}
              options={counterListOptions}
              formatOptionLabel={(data) => formatOptionLabel(data)}
              styles={customSelectStyles}
              isDisabled={editMode}
            />
            {errorMessage && (
              <div
                className="invalid-feedback"
                style={{ display: errorMessage ? 'block' : 'none' }}>
                {errorMessage}
              </div>
            )}
          </Form.Group>
          <Form.Group className="col-12 g-mb-20">
            <Form.Label>
              <b>Date</b>
            </Form.Label>
            <Form.Control
              size="lg"
              type="datetime-local"
              name="date"
              value={date}
              required
              onChange={(e) => setDate(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              Please choose a valid date.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="col-12 g-mb-20">
            <Form.Label>
              <b>Action</b>
            </Form.Label>
            <Form.Select
              name="action"
              value={activity}
              onChange={(e) => setActivity(e.target.value)}
              disabled={editMode}>
              <option value={corporateActionActivitiesCodes.bonus_issue}>Bonus Issue</option>
              <option value={corporateActionActivitiesCodes.add_share}>Add Share</option>
              <option value={corporateActionActivitiesCodes.share_split}>Share Split</option>
              <option value={corporateActionActivitiesCodes.share_cons}>Share Consolidate</option>
            </Form.Select>
          </Form.Group>
          <div className="row g-mb-20">
            <Form.Group>
              <Form.Label>
                <b>Broker</b>
              </Form.Label>
              <Form.Select
                name="broker"
                value={selectedBroker?.Id || ''}
                onChange={(e) => handleBrokerChange(e.target.value)}>
                <option value="" disabled>
                  Select Broker
                </option>
                {brokers.map((broker) => (
                  <option key={broker.Id} value={broker.Id}>
                    {broker.BrokerName}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </div>
          {activity && activity === corporateActionActivitiesCodes.add_share && (
            <>
              <Form.Group className="col-12 g-mb-20">
                <Form.Label>
                  <b>Shares</b>
                </Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    size="lg"
                    type="number"
                    value={share}
                    placeholder="eg. 100.231"
                    min="1"
                    step="1"
                    required={activity === corporateActionActivitiesCodes.add_share}
                    onChange={(e) => setShare(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Share must be a positive number.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group className="col-12 g-mb-20">
                <Form.Label>
                  <b>Price</b>
                </Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    size="lg"
                    type="number"
                    value={price}
                    placeholder="eg. 100.231"
                    min="0.001"
                    step="0.001"
                    required={activity === corporateActionActivitiesCodes.add_share}
                    onChange={(e) => setPrice(e.target.value)}
                  />
                  {selectedPortfolio && selectedPortfolio.currency && (
                    <InputGroup.Text className="g-text-size-14">
                      {getCurrencyName(selectedPortfolio.currency)}
                    </InputGroup.Text>
                  )}
                  {stockCurrencyMarket &&
                    stockCurrencyMarket.counter_currency &&
                    selectedPortfolio.currency !== stockCurrencyMarket.counter_currency && (
                      <InputGroup.Text id="basic-addon3" className="g-text-size-14">
                        {getCurrencyName(stockCurrencyMarket.counter_currency)} Ex. Rate{' '}
                        {stockCurrencyMarket.exchange_rate}
                      </InputGroup.Text>
                    )}
                  <Form.Control.Feedback type="invalid">
                    Price must be a positive number.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </>
          )}
          {activity && activity !== corporateActionActivitiesCodes.add_share && (
            <div className="row g-mb-20">
              <div className="g-per-width-50">
                <Form.Group>
                  <Form.Label>
                    <b>
                      {activity === corporateActionActivitiesCodes.bonus_issue
                        ? 'Bonus Ratio'
                        : 'Adjust Ratio'}
                    </b>
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      size="lg"
                      type="number"
                      value={ratio1}
                      min="0.001"
                      step="0.001"
                      placeholder="Ratio 1"
                      required={activity !== corporateActionActivitiesCodes.add_share}
                      onChange={(e) => handleRatioChange(e.target.value, true)}
                    />
                    <Form.Control.Feedback type="invalid">
                      First ratio must be a positive number.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="g-per-width-50">
                <Form.Group>
                  <Form.Label>
                    <b>
                      {activity === corporateActionActivitiesCodes.bonus_issue ? 'for:' : 'into:'}
                    </b>
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      size="lg"
                      type="number"
                      value={ratio2}
                      min="0.001"
                      step="0.001"
                      placeholder="Ratio 2"
                      required={activity !== corporateActionActivitiesCodes.add_share}
                      onChange={(e) => handleRatioChange(e.target.value, false)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Second ratio must be a positive number.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </div>
              {ratioErrorMessage && (
                <div
                  className="invalid-feedback"
                  style={{ display: ratioErrorMessage ? 'block' : 'none' }}>
                  {ratioErrorMessage}
                </div>
              )}
            </div>
          )}
          <Form.Group className="col-12 g-mb-20">
            <Form.Label>
              <b>Remarks(optional)</b>
            </Form.Label>
            <Form.Control
              type="text"
              size="lg"
              name="remarks"
              defaultValue={remarks}
              onChange={(e) => setRemarks(e.target.value)}
            />
          </Form.Group>
          <div className="g-tb-20 g-mb-20 text-center">
            <Button variant="primary" type="submit" className="text-center">
              {editMode ? 'Edit' : 'Create'}
            </Button>
            <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

CorporateActionTransactionModal.propTypes = {
  show: PropTypes.object,
  setShow: PropTypes.func,
  editingTransaction: PropTypes.object,
};

export default CorporateActionTransactionModal;
