import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Button, Form, Spinner } from 'react-bootstrap';
import * as yup from 'yup';
import md5 from 'md5';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import styles from './membership.module.css';
import { Constants } from '../../../utils/constants';
import api from '../../../utils/api';
import { selectMembershipProfile, setFieldValue } from './membershipSlice';

const validationSchema = yup.object().shape({
  email: yup.string().email('Invalid email format').required('Email is required'),
  password: yup
    .string()
    .trim()
    .min(8, 'Password must be at least 8 characters')
    .required('Password is required'),
});

function ChangeEmailForm({ setIsEmailModalOpen }) {
  const STAGES = Object.freeze({
    VERIFY_PASSWORD: 0,
    ENTER_EMAIL: 1,
    CHANGE_COMPLETE: 2,
  });

  const RECAPTCHA_FAILED_MESSAGE =
    'Failed to verify that you are a human. Please refresh page and try again.';
  const SERVER_ERROR_FAILED_MESSAGE =
    'Failed to verify with server. Please contact us if you are unable to change email';
  const VERIFY_PASSWORD_ROUTE = 'user/verify_password';
  const MEMBERSHIP_PROFILE_ROUTE = 'membership/profile.json';

  const { CancelToken } = axios;

  const dispatch = useDispatch();
  const membershipProfile = useSelector(selectMembershipProfile);
  const { performCheck, siteKey } = membershipProfile;

  const [stage, setStage] = useState(STAGES.VERIFY_PASSWORD);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [status, setStatus] = useState(Constants.STATUS_IDLE);
  const reCaptchaRef = useRef(null);

  const getRecaptchaToken = async () => {
    try {
      return await reCaptchaRef.current.executeAsync();
    } catch (err) {
      setPasswordError(err);
      return '';
    }
  };

  const handleVerifyPassword = async () => {
    /* Get grecaptcha */
    let captchaToken = '';
    const source = CancelToken.source();

    if (performCheck) {
      captchaToken = await getRecaptchaToken();
      if (!captchaToken) {
        setPasswordError(RECAPTCHA_FAILED_MESSAGE);
        return;
      }
    }

    validationSchema
      .pick(['password'])
      .validate({ password }, { abortEarly: true })
      .then(() => {
        setStatus(Constants.STATUS_LOADING);
        setPasswordError('');
        api
          .post(
            VERIFY_PASSWORD_ROUTE,
            {
              password: password.trim().toLowerCase(),
              password_m: md5(password.trim().toLowerCase()).toLowerCase(),
              recaptcha_token: captchaToken,
            },
            { cancelToken: source.token, responseType: 'json' },
          )
          .then((response) => {
            if (response.data.success) {
              setStage(STAGES.ENTER_EMAIL);
            } else {
              setPasswordError(response.data.message);
            }
          })
          .catch((err) => {
            setPasswordError(`${SERVER_ERROR_FAILED_MESSAGE} ${err.message}`);
          })
          .then(() => {
            setStatus(Constants.STATUS_IDLE);
            reCaptchaRef.current?.reset();
          });
      })
      .catch((err) => {
        setPasswordError(err.message);
      });
  };

  const handleUpdateEmail = async () => {
    /* Get grecaptcha */
    let captchaToken = '';
    const source = CancelToken.source();

    if (performCheck) {
      captchaToken = await getRecaptchaToken();
      if (!captchaToken) {
        setEmailError(RECAPTCHA_FAILED_MESSAGE);
        return;
      }
    }

    validationSchema
      .pick(['email'])
      .validate({ email }, { abortEarly: true })
      .then(() => {
        setStatus(Constants.STATUS_LOADING);
        setEmailError('');
        api
          .patch(
            MEMBERSHIP_PROFILE_ROUTE,
            {
              user: {
                email,
                verify_password: password.trim().toLowerCase(),
                verify_password_m: md5(password.trim().toLowerCase()).toLowerCase(),
              },
              recaptcha_token: captchaToken,
            },
            { cancelToken: source.token, responseType: 'json' },
          )
          .then((response) => {
            if (response.data.success) {
              setStage(STAGES.CHANGE_COMPLETE);
              /* Update redux */
              dispatch(setFieldValue({ fieldName: 'email', value: email }));
            } else {
              setEmailError(response.data.message);
            }
          })
          .catch((err) => {
            setEmailError(`${SERVER_ERROR_FAILED_MESSAGE} ${err.message}`);
          })
          .then(() => {
            setStatus(Constants.STATUS_IDLE);
            reCaptchaRef.current?.reset();
          });
      })
      .catch((err) => {
        setEmailError(err.message);
      });
  };

  return (
    <div>
      {stage === STAGES.VERIFY_PASSWORD && (
        <Form>
          <div className="small mb-3">To continue, first verify it&#39;s you</div>
          <Form.Group className="mb-3" controlId="password">
            <Form.Label className={styles.formLabel}>Enter your current password</Form.Label>
            <Form.Control
              type="password"
              className={styles.formInput}
              placeholder="Enter your current password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              data-lpignore="true"
              autoComplete="off"
              isInvalid={!!passwordError}
            />
            <Form.Control.Feedback type="invalid">{passwordError}</Form.Control.Feedback>
          </Form.Group>
          <div className="d-flex justify-content-center">
            <Button
              type="button"
              variant="dark"
              className={`${styles.greenButton} mb-2 mt-3`}
              onClick={handleVerifyPassword}>
              {status === Constants.STATUS_LOADING ? (
                <Spinner animation="border" role="status" size="sm" variant="light">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                'Verify'
              )}
            </Button>
          </div>
        </Form>
      )}
      {stage === STAGES.ENTER_EMAIL && (
        <>
          <div className="small mb-3">Password verified!</div>
          <Form.Group className="mb-3" controlId="email">
            <Form.Label className={styles.formLabel}>Enter your new email address</Form.Label>
            <Form.Control
              type="email"
              className={styles.formInput}
              placeholder="Enter your new email address"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              data-lpignore="true"
              autoComplete="off"
              isInvalid={!!emailError}
            />
            <Form.Control.Feedback type="invalid">{emailError}</Form.Control.Feedback>
          </Form.Group>
          <div className="d-flex justify-content-center">
            <Button
              type="button"
              variant="dark"
              className={`${styles.greenButton} mb-2 mt-3`}
              onClick={handleUpdateEmail}>
              {status === Constants.STATUS_LOADING ? (
                <Spinner animation="border" role="status" size="sm" variant="light">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                'Update'
              )}
            </Button>
          </div>
        </>
      )}
      {stage === STAGES.CHANGE_COMPLETE && (
        <div className="text-center">
          <h5>Email changed</h5>
          <div className="mt-2 mb-2" style={{ fontSize: 14 }}>
            Your email has been successfully updated.
          </div>
          <Button
            type="button"
            variant="dark"
            className={`${styles.greenButton} mb-3 mt-4 d-inline-block`}
            onClick={() => setIsEmailModalOpen(false)}>
            Okay
          </Button>
        </div>
      )}
      {performCheck && (
        <ReCAPTCHA
          ref={reCaptchaRef}
          className="d-inline-block"
          theme="dark"
          size="invisible"
          sitekey={siteKey}
        />
      )}
    </div>
  );
}

ChangeEmailForm.propTypes = {
  setIsEmailModalOpen: PropTypes.func,
};

export default ChangeEmailForm;
