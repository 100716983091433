import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';

import SignUpForm from './SignUpForm';
import MobileVerification from './MobileVerification';
import SearchQuote from '../SearchQuote';
import { membershipResetData, membershipSetRedirectTo } from './signUpSlice';

function SignUpModal({
  country,
  showModal,
  setShowModal,
  promoCode,
  redirectToInit,
  checkCaptcha,
  siteKey,
  successSubtitle,
}) {
  const dispatch = useDispatch();
  const searchRef = useRef(null);
  const userid = useSelector((state) => state.signUp.userid);
  const mobile = useSelector((state) => state.signUp.mobile);
  const redirectTo = useSelector((state) => state.signUp.redirectTo);

  const closeModal = () => {
    setShowModal(false);
  };

  const resetState = () => {
    dispatch(membershipResetData());
  };

  const handleClickSearch = () => {
    closeModal();
    searchRef.current.click();
  };

  useEffect(() => {
    if (userid && mobile && redirectTo) {
      closeModal();
      dispatch(membershipResetData());
      window.location.replace(redirectTo);
    }
  }, [mobile]);

  useEffect(() => {
    dispatch(membershipSetRedirectTo(redirectToInit));
  }, [redirectToInit]);

  return (
    <Modal centered show={showModal} onHide={closeModal} onExited={resetState} className="p-4">
      <Modal.Body className="p-4">
        <button
          type="button"
          className="btn-close float-end"
          aria-label="Close"
          onClick={closeModal}
        />

        {!userid && !mobile && (
          <SignUpForm
            country={country}
            promoCode={promoCode}
            checkCaptcha={checkCaptcha}
            siteKey={siteKey}
          />
        )}
        {userid && !mobile && <MobileVerification checkCaptcha={checkCaptcha} siteKey={siteKey} />}
        {userid && mobile && (
          <div className="text-center">
            <Modal.Title className="text-center g-mb-30">
              <h3>Verified successfully!</h3>
            </Modal.Title>
            <div>{successSubtitle || 'You have created an account.'}</div>

            <Button variant="primary" className="mt-4 text-center" onClick={handleClickSearch}>
              Search a stock now
            </Button>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default SignUpModal;

SignUpModal.propTypes = {
  country: PropTypes.string,
  promoCode: PropTypes.string,
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  checkCaptcha: PropTypes.bool,
  siteKey: PropTypes.string,
  redirectToInit: PropTypes.string,
  successSubtitle: PropTypes.string,
};
