import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Responsive from '@amcharts/amcharts5/themes/Responsive';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

import classes from '../../portfolio.module.css';

const POSITIVE_COLOR = 0x7193d8;
const NEGATIVE_COLOR = 0xb30000;

function WaterfallChart({ portfolioData, chartMarkers, selectedPeriodInterval, selectedRange }) {
  useLayoutEffect(() => {
    const root = am5.Root.new('PortfolioWaterfallChart');
    root.setThemes([am5themes_Animated.new(root), am5themes_Responsive.newEmpty(root)]);
    root.numberFormatter.setAll({
      numberFormat: '#a',

      // Group only into M (millions), and B (billions)
      bigNumberPrefixes: [
        { number: 1e3, suffix: 'K' },
        { number: 1e6, suffix: 'M' },
        { number: 1e9, suffix: 'B' },
      ],

      // Do not use small number prefixes at all
      smallNumberPrefixes: [],
    });
    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        paddingLeft: 0,
        layout: root.verticalLayout,
        maxTooltipDistance: 0,
      }),
    );

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    const xRenderer = am5xy.AxisRendererX.new(root, {
      minorGridEnabled: true,
    });

    const xAxis = chart.xAxes.push(
      am5xy.GaplessDateAxis.new(root, {
        groupData: true,
        groupIntervals: [selectedPeriodInterval],
        baseInterval: {
          timeUnit: 'day',
          count: 1,
        },
        renderer: xRenderer,
      }),
    );
    xAxis.get('renderer').labels.template.setAll({
      fill: am5.color(0x646464),
      fontSize: 11,
    });

    xAxis.get('dateFormats').day = 'dd MMM yy';
    xAxis.get('dateFormats').week = 'dd MMM yy';
    xAxis.get('dateFormats').month = 'MMM yy';
    xAxis.get('dateFormats').year = 'yyyy';
    xAxis.get('periodChangeDateFormats').day = 'dd MMM yy';
    xAxis.get('periodChangeDateFormats').week = 'dd MMM yy';
    xAxis.get('periodChangeDateFormats').month = 'MMM yy';
    xAxis.get('periodChangeDateFormats').year = 'yyyy';

    xRenderer.grid.template.setAll({
      location: 1,
    });

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, { strokeOpacity: 0.1 }),
      }),
    );
    yAxis.get('renderer').labels.template.setAll({
      fill: am5.color(0x646464),
      fontSize: 11,
    });

    // Create series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    const series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        xAxis,
        yAxis,
        valueYField: 'close',
        valueXField: 'date',
        openValueYField: 'open',
        tooltip: am5.Tooltip.new(root, {
          labelText: '',
        }),
      }),
    );

    series.get('tooltip').label.adapters.add('text', (text_, target) => {
      let text = '';
      const i = 0;
      chart.series.each((series_) => {
        const tooltipDataItem = series_.get('tooltipDataItem');
        if (tooltipDataItem) {
          const diff =
            parseFloat(tooltipDataItem.get('valueY')) -
            parseFloat(tooltipDataItem.get('openValueY'));
          text = `{valueX.formatDate('dd MMM yyyy')}: [bold]${diff.toLocaleString('en-SG')}[/]`;
        }
      });
      return text;
    });

    series.columns.template.setAll({
      templateField: 'columnConfig',
      strokeOpacity: 0,
    });

    series.bullets.push(() => {
      const sprite = am5.Label.new(root, {
        centerY: am5.p50,
        centerX: am5.p50,
        populateText: true,
        fontSize: 11,
        fontWeight: 'bold',
      });

      sprite.adapters.add('text', (text, target) => {
        const formatter = Intl.NumberFormat('en', { notation: 'compact' });
        const diff =
          parseFloat(target.dataItem.get('valueY')) - parseFloat(target.dataItem.get('openValueY'));
        return formatter.format(diff);
      });

      return am5.Bullet.new(root, {
        sprite,
      });
    });

    const stepSeries = chart.series.push(
      am5xy.StepLineSeries.new(root, {
        xAxis,
        yAxis,
        valueYField: 'close',
        valueXField: 'date',
        openValueYField: 'open',
        noRisers: true,
        locationX: 0.65,
        groupDataWithOriginals: true,
        groupDataCallback(dataItem, interval) {
          const originals = dataItem.get('originals');
          const { open } = originals[0].dataContext;
          const { close } = originals[originals.length - 1].dataContext;
          dataItem.set('valueY', close);
          dataItem.set('openValueY', open);
          dataItem.set('valueYWorking', close);
          dataItem.set('openValueYWorking', close);
        },
      }),
    );

    stepSeries.strokes.template.setAll({
      strokeDasharray: [3, 3],
    });

    // Set data
    let lastVal = 0;
    const formatData = (key, val) => {
      const value =
        parseFloat(val.unrealized_sell_proceeds) +
        parseFloat(val.realized_sell_proceeds) +
        parseFloat(val.total_cash) +
        parseFloat(val.total_dividend) -
        parseFloat(val.buy_cost);

      const currentVal = {
        date: Date.parse(key),
        close: value,
        open: lastVal,
        displayValue: value,
      };

      lastVal = value;
      return currentVal;
    };

    const data = Object.entries(portfolioData).map(([key, val]) => {
      return formatData(key, val);
    });

    series.columns.template.onPrivate('width', (width, target) => {
      if (!target.dataItem.bullets) {
        return;
      }
      am5.array.each(target.dataItem.bullets, (bullet) => {
        if (width > 20) {
          bullet.get('sprite').show();
        } else {
          bullet.get('sprite').hide();
        }
      });
    });

    series.columns.template.states.create('riseFromPrevious', {
      fill: am5.color(POSITIVE_COLOR),
    });

    series.columns.template.states.create('dropFromPrevious', {
      fill: am5.color(NEGATIVE_COLOR),
    });

    xAxis.data.setAll(data);
    series.data.setAll(data);
    stepSeries.data.setAll(data);

    // Add cursor
    const cursor = chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        xAxis,
      }),
    );
    cursor.lineY.set('visible', false);

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    series.appear(1000);
    chart.appear(1000, 100);

    series.events.once('datavalidated', (ev) => {
      if (!selectedRange || !selectedRange.startDate) {
        return;
      }

      // To make sure the data is in center get start_date - 1day and end_date + 1day
      const startDate = new Date(selectedRange.startDate);
      const endDAte = new Date();
      startDate.setDate(startDate.getDate() - 1);
      endDAte.setDate(endDAte.getDate() + 1);
      ev.target.get('xAxis').zoomToDates(startDate, endDAte);
    });

    return () => {
      root.dispose();
    };
  }, [portfolioData, chartMarkers, selectedPeriodInterval, selectedRange]);

  return <div id="PortfolioWaterfallChart" className={classes.portfolioPerformanceChart} />;
}

WaterfallChart.propTypes = {
  portfolioData: PropTypes.object,
  chartMarkers: PropTypes.array,
  selectedPeriodInterval: PropTypes.object,
  selectedRange: PropTypes.object,
};

export default WaterfallChart;
