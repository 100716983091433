import { createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';

const ownershipSlice = createSlice({
  name: 'ownership',
  initialState: {
    data: {},
    loading: true,
    err: null,
  },
  reducers: {
    ownershipDataFetchStart(state, action) {
      state.data = null;
      state.err = null;
      state.loading = true;
    },
    ownershipDataFetchSuccess(state, action) {
      if (action.payload.data) {
        state.data = action.payload.data.owner;
      }
      state.err = null;
      state.loading = false;
    },
    ownershipDataFetchFail(state, action) {
      state.err = action.payload;
      state.loading = false;
    },
  },
});

export default ownershipSlice.reducer;

const { ownershipDataFetchStart, ownershipDataFetchSuccess, ownershipDataFetchFail } =
  ownershipSlice.actions;

export const fetchData =
  ({ factsetOwnerId, cancelToken }) =>
  (dispatch) => {
    const url = `/significant_shareholders/ownership_by_shareholder.json?shareholder_id=${factsetOwnerId}`;
    dispatch(ownershipDataFetchStart());
    api
      .get(url, {
        cancelToken,
      })
      .then(({ data }) => {
        dispatch(ownershipDataFetchSuccess({ data }));
      })
      .catch((error) => {
        dispatch(ownershipDataFetchFail(error));
      });
  };
