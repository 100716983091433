import React from 'react';
import { Button, Form } from 'react-bootstrap';

import styles from './sociallogin.module.css';
import GoogleIcon from 'images/google-icon.svg';
import { getCsrfToken } from '../../utils/utils';

function SocialLoginComponent() {

  const csrfToken = getCsrfToken();

  return (
    <>
      <Form action='/auth/google_oauth2' method='post'>
        <Form.Control
          type="hidden"
          name={csrfToken.csrf_param}
          value={csrfToken.csrf_token}
        />
        <Button
          variant="outline-secondary"
          type="submit"
          className={`w-100 d-inline-flex justify-content-center align-items-center mb-3 p-0 ${styles.googleButton}`}
          name="submit">
          <img src={GoogleIcon} />
          Continue with Google
        </Button>
      </Form>
      <Form action='/auth/facebook' method='post'>
        <Form.Control
          type="hidden"
          name={csrfToken.csrf_param}
          value={csrfToken.csrf_token}
        />
        <Button
          variant="outline-secondary"
          type="submit"
          className={`w-100 d-inline-flex justify-content-center align-items-center p-0 ${styles.facebookButton}`}
          name="submit">
          <i className="fa-brands fa-facebook fa-inverse"></i>
          Continue with Facebook
        </Button>
      </Form>
    </>
  )
}

export default SocialLoginComponent;
