/*
 * jQuery extensions that are used across the whole web site
 */
(function($){
  $.fn.extend({
    si_popup : function(options) {
    
      var default_show_popup_call_back = function (elem)
      {
        var popup_div = elem.find(settings.popup_elem_selector);
        var load_data = function() 
        {
          $.ajax({
            url: settings.ajax_url,
            success: function (data) {
              popup_div.html(data);
              popup_div.data("time", new Date().getTime());
            }
          })
        }
        
        if (popup_div.length <= 0)
        {
          popup_div = $('<div>');
          popup_div.addClass(settings.popup_class_names);
          popup_div.html(settings.loading_indicator_html);
          elem.append(popup_div);
          load_data();
        }
        else
        {
          //Reload data when last update more than 5 minutes
          var current_time = new Date().getTime();
          var prev_time = popup_div.data("time");
          if (prev_time && (current_time - prev_time > settings.reload_interval))
          {
            load_data();
            popup_div.html(settings.loading_indicator_html);
          }
        }
        popup_div.show();
        check_position(elem);
      }
      
      var default_hide_popup_call_back = function (elem)
      {
        var popup_div = elem.find(settings.popup_elem_selector);
        if (popup_div.length)
        {
            popup_div.css('display', 'none');
        }
      }

      var check_position = function (elem)
      {
        var popup_div = elem.find(settings.popup_elem_selector);
        var offset_left = popup_div.offset().left;
        var offset_right = offset_left + popup_div.outerWidth();
        var window_width = $(window).width();

        if (offset_right > window_width) {
          var difference = offset_right - window_width;
          var position_left = popup_div.position().left;

          popup_div.css({left: position_left - difference - 10});
        }
      }
      
      var defaults = {
        delay : 800,
        popup_elem_selector : '.sic_edu_series_popup_content',
        loading_indicator_html : '<img src="/images/icon_loadingLarge.gif" width="32" height="32" alt="loading..." class="sic_icon_loadingLarge" />',
        popup_class_names : 'sic_popup sic_popup2 sic_edu_series_popup_content',
        reload_interval : 1000 * 60 * 5,
        ajax_url : '',
        show_popup_call_back : default_show_popup_call_back,
        hide_popup_call_back : default_hide_popup_call_back
      },
      
      settings = $.extend({}, defaults, options);
      
      var show_timer, hide_timer;
      
      var show_popup = function (elem, settings) {
        if (hide_timer) {
            clearTimeout(hide_timer);
            hide_timer = null;
        }

        if (show_timer)
            return;
        
        show_timer = setTimeout(function(){
          settings.show_popup_call_back(elem);
        }, settings.delay);
      }
        
      var hide_popup = function (elem, settings) {
        if (show_timer) {
            clearTimeout(show_timer);
            show_timer = null;
        }

        if (hide_timer) return;
        
        hide_timer = setTimeout(function(){
          settings.hide_popup_call_back(elem);
        }, settings.delay);
      }
    
      return this.each (function(){
        var jele = $(this);
        jele.mouseover(function(){
            show_popup(jele, settings);
        }).mouseout(function(){
            hide_popup(jele, settings);
        });
      });
    }
  })
})(jQuery);


/*********************************************************/
// Helper class to show a mini counter summary popup, when mouse over certain html element.
(function($){
    $.fn.extend({
        counterQuote2Popup: function(options) {
          //if client platform is station, dont show the counter quote pop up
          if (get_platform() == 'station')
              return;

          var defaults = {
              delay : 400
          },

          settings = $.extend({}, defaults, options);

          var show_popup_call_back = function(elem) {
            var popup_div = elem.find('.sic_companyQuickInfo');
            var portfolio_id = $('#sic_portfolioSummary .sic_one strong').attr('id') ? $('#sic_portfolioSummary .sic_one strong').attr('id') : "";

            var load_data = function() {
                $.ajax({
                    url: '/prices/counter_quote_f.html?type=2&counter=' + settings.code + '&folder=' + portfolio_id,
                    success: function(data){
                        popup_div.html(data);
                        popup_div.data("time", new Date().getTime());
                        popup_div.data("code", settings.code);
                        $(document).trigger("counterquotes_2_f:loaded", [popup_div]);
                    }
                });
            }

            if (popup_div.length <= 0) {
                popup_div = $('<div>');
                popup_div.attr('id', settings.code + Math.random());

                popup_div.addClass('sic_companyQuickInfo');
                popup_div.addClass('sic_popup');
                popup_div.html('<img src="/images/icon_loadingLarge.gif" width="32" height="32" alt="loading..." class="sic_icon_loadingLarge" />');
                elem.append(popup_div);

                load_data();
            }
            else {
                //Reload data when last update more than 5 minutes
                var current_time = new Date().getTime();
                var prev_time = popup_div.data("time");
                if (prev_time && (current_time - prev_time > 1000 * 60 * 5))
                    load_data();

                //Reload data if the code has changed.
                var current_code = popup_div.data("code");
                if (current_code != settings.code) {
                    load_data();
                }
            }
            popup_div.css('display', 'block');

            elem.css('zIndex', '1');
          }

          var hide_popup_call_back = function(elem) {
            var popup_div = elem.find('.sic_companyQuickInfo');
            if (popup_div.length)
            {
                popup_div.css('display', 'none');

                elem.css('zIndex', '0');;
            }
          }

          return $(this).si_popup({show_popup_call_back : show_popup_call_back, hide_popup_call_back : hide_popup_call_back, delay : settings.delay});
        }
    });
})(jQuery);

