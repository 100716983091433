import { DataSubscription, BaseTable, pushPage } from './protocol';
import { VisualTable } from './tables';
import { resetModeToStreaming } from '.';
import { getParamCounterId } from '../utils';
import store from '../store';
import { showStockPopup } from '../../components/StockInfoPopup/stockInfoPopupSlice';
import {
  pricesAddSelectedStocks,
  pricesRemoveSelectedStocks,
} from '../../components/PriceTable/priceTableSlice';

let query;
let priceTable;
const price_params = {
  tab: null,
  filter: null,
  type: null,
  streamingChannel: null,
  layout: null,
  page: null,
  page_row_offset: null,
  market: null,
  streamingType: null,
};

// Set up some constants
const live_streaming_title = 'Live Streaming Data';
const delay_streaming_title = 'Delayed Streaming Data';
const eod_title = 'End of trading day';
const max_record_per_page = 50;
let last_update = '00000000000000';
let last_update_bursa = '00000000000000';

// Handle when stock comparison checkbox is checked or unchecked
const selectStockHandler = function (event) {
  if (event.target.checked) {
    const state = store.getState();
    const stockData = state.stockPrices.data;
    const stockInfo = stockData.stock_info.find(item => item.counter == event.target.value);
    const isStock = stockInfo ? (stockInfo["Is Stock"] ? "true" : "false") : "true";
    store.dispatch(pricesAddSelectedStocks({ code: event.target.value, isStock: isStock }));
  } else {
    store.dispatch(pricesRemoveSelectedStocks(event.target.value));
  }
};

/** NOTE: fix_table_header() was in application.js for v1 */
const fix_table_header = function (table, table_id) {
  console.log(`fix_table_header() called for: ${table_id}`);
};

/** NOTE: remove_fix_table_header() was in application.js for v1 */
const remove_fix_table_header = function () {
  // clear cloned fix header table
  $('div.fixedHeader').remove();
};

/** ****************************************************** */
const constructStreamingTable = function (query) {
  if (price_params.streamingType === 'Portfolio') {
    query = price_params.folder + "." + (new Date().getTime());
  }

  const priceTable = new VisualTable('sic_pricesTable', new DataSubscription(price_params.streamingType, query, []));

  priceTable.allowUpdate = function (update_) {
    if (price_params.streamingType === 'Portfolio' && update_.code === 'CASH.ALL') {
      return false;
    }
    return true;
  };

  priceTable.onUpdating = function () {
    const table = $(`#${this.getId()}`);
    if (table.length) {
      const tbody = table.children('tbody');
      if (tbody.length && table[0].dataset.feed_mode == 'static') {
        tbody.remove();
      }
    }

    setTimeout(function () {
      remove_fix_table_header();
      const price_table_obj = $('#sic_pricesTable');
      if (price_table_obj.length > 0) {
        fix_table_header(price_table_obj[0], 'sic_pricesTable');
        const compare_stocks_checkbox = $('.sic_compare_stocks_checkbox');
        if (compare_stocks_checkbox) {
          compare_stocks_checkbox.show();
        }
      }
    }, 2000);
  };

  priceTable.prepareCellUpdate = function (update_, field_, change_whole_row_) {
    const curValue = update_.newValue;

    if (field_ == 'change' || field_ == 'perc_change') {
      update_.removeClassNames = 'sic_up sic_down';
      if (curValue > 0) update_.addClassNames = 'sic_up';
      else if (curValue < 0) update_.addClassNames = 'sic_down';
    } else if (field_ == 'short_name') {
      update_.addClassNames = 'sic_unchangedArrow';
    }

    if (!change_whole_row_) {
      update_.dynamicEffect = getNumericDynamicUpdateEffect(update_.oldValue, curValue, field_);
    }
  };

  priceTable.afterRowUpdate = function (row_, update_) {
    let arrow_flag = null;
    let code = null;
    let { change } = update_;
    let { update_time } = update_;
    let selected_market = '';
    let update_time_bursa = '';
    let exchange = '';
    let symbol = '';

    if ($('strong#sic_selectedMarket').length > 0) {
      selected_market = $('strong#sic_selectedMarket')[0].getAttribute('selected_market');
    }

    if (change) {
      change = parseFloat(change);
      if (change > 0) arrow_flag = 'sic_upArrow';
      else if (change < 0) arrow_flag = 'sic_downArrow';
      else arrow_flag = 'sic_unchangedArrow';
    }

    if ('code' in update_) {
      code = update_.code;
      symbol = code;
    }
    else {
      const tmp = row_.children('.sic_companyName');
      if (tmp.length) {
        const symbol_cell = tmp.children('.sic_cell');
        symbol = $(symbol_cell).find('a').data('counter');
      }
    }

    if (price_params.streamingType === 'Portfolio') {
      if (symbol && symbol.match(/.MY$/)) {
        exchange = 'bursa';
        update_time_bursa = update_.update_time;
      }
      if (symbol && symbol.match(/.SI$/)) {
        exchange = 'sgx';
        update_time = update_.update_time;
      }

      if (update_time != '' && update_time > last_update || update_time_bursa != '' && update_time_bursa > last_update_bursa) {
        if (price_params.layout == 'trading_data') {
          const sgxLastUpdated = document.getElementById('sic_subTitle_lastUpdate_sgx')
          if (update_time != '') {
            last_update = update_time;
          } else if (last_update === '00000000000000' && sgxLastUpdated && sgxLastUpdated.dataset) {
            last_update = sgxLastUpdated.dataset.lastupdatenum;
          }

          const bursaLastUpdated = document.getElementById('sic_subTitle_lastUpdate_bursa')
          if (update_time_bursa != '') {
            last_update_bursa = update_time_bursa;
          } else if (last_update_bursa === '00000000000000' && bursaLastUpdated && bursaLastUpdated.dataset) {
            last_update_bursa = bursaLastUpdated.dataset.lastupdatenum;
          }

          const formated_time = this.formatTime(last_update);
          const formated_time_bursa = this.formatTime(last_update_bursa);
          let streaming_status_str = "";

          $.each([['sgx', 'SGX', formated_time], ['bursa', ' Bursa', formated_time_bursa]], function (i, v) {
            if (pushPage.hasMarketRealtimeStreamingAccess(v[0]) == 'true')
              streaming_status_str += " <strong>" + v[1] + "</strong> " + live_streaming_title + ". Updated at <strong>" + v[2] + "</strong>. ";
            else
              streaming_status_str += " <strong>" + v[1] + "</strong> " + delay_streaming_title + ". Updated at <strong>" + v[2] + "</strong>. ";
          });

          $('#sic_subTitle_lastUpdate').html(streaming_status_str);
        }
      }
    } else {
      const d = new Date(); // do not update subtitle if new update_time has future date
      if (update_time && update_time.substr(0, 8) <= d.format('yyyymmdd') && update_time > last_update) {
        if (price_params.layout == 'trading_data') {
          if (pushPage.hasMarketRealtimeStreamingAccess(selected_market) == 'true')
            $('#sic_subTitle_lastUpdate').html(
              `<span class="g-mr-5">Updated at <strong>${this.formatTime(update_time)}</strong>. ${live_streaming_title}.</span>`,
            );
          else if (['asx', 'nyse', 'nyse_mkt', 'nasdaq', 'set'].indexOf(selected_market) > -1)
            $('#sic_subTitle_lastUpdate').html(
              `<span class="g-mr-5">Price updated on <strong>${this.formatTime(update_time).substr(0, 11)}</strong>. (${eod_title})</span>`,
            );
          else
            $('#sic_subTitle_lastUpdate').html(
              `<span class="g-mr-5">Updated at <strong>${this.formatTime(update_time)}</strong>. ${delay_streaming_title}.</span>`,
            );

          last_update = update_time;
        }
      }
    }

    if (arrow_flag || code) {
      let cell = row_.children('.sic_companyName');
      if (cell.length) {
        var jele = $(cell);
        if (arrow_flag) {
          jele.removeClass('sic_upArrow sic_downArrow sic_unchangedArrow');
          jele.addClass(arrow_flag);
        }
        if (code) {
          const sic_cell = jele.children('.sic_cell');
          // sic_cell.counterQuote2Popup({
          //   code,
          // });
          sic_cell.children('a').attr(
            'href',
            `/home/redirect?type=factsheet&counter=${getParamCounterId(code)}`);

          // add hover eventListener to all company_name to allow trigger StockInfoPopUp
          function handleMouseOver(event) {
            const currentElement = event.currentTarget;
            const counter = getParamCounterId(code);
            if (!counter) {
              return;
            }

            const offsets = currentElement.getBoundingClientRect();
            const popupContainer = document.getElementById('stock_popup_container');
            if (popupContainer) {
              popupContainer.style.top = `${offsets.top + window.pageYOffset - currentElement.ownerDocument.documentElement.clientTop
                }px`;
              popupContainer.style.left = `${offsets.right + 5}px`;
            }

            store.dispatch(showStockPopup({ counter, isShown: true }));
          }

          function handleMouseOut(event) {
            store.dispatch(showStockPopup({ isShown: false }));
          }

          const hoverElement = document.querySelector(`#${row_.attr('id')} .stock_popup_hover`);
          if (hoverElement) {
            // remove old listeners (if any) before adding new
            hoverElement.removeEventListener('mouseover', handleMouseOver);
            hoverElement.removeEventListener('mouseout', handleMouseOut);
            hoverElement.addEventListener('mouseover', handleMouseOver);
            hoverElement.addEventListener('mouseout', handleMouseOut);
          }
        }
      }

      // for adding checkbox in each row
      cell = row_.children('.sic_compare_stocks_checkbox');
      if (cell.length) {
        var jele = $(cell);
        if (code) {
          const code_ = code.replaceAll('.', '_').toUpperCase();
          // Set value to selected only when the counter exists in
          $(jele).html(
            `<div><label><input type='checkbox' class='sic_compare_stocks_checkbox' data-is-stock="" id='compare_stocks_checkbox_${code_}' name='counter_select[]' value='${code}' /></label></div>`,
          );
          const newCheckbox = document.querySelector(`[id=compare_stocks_checkbox_${code_}]`);
          if (newCheckbox) {
            newCheckbox.addEventListener('change', selectStockHandler);
          }
        }
      }

      // update sparkline chart
      cell = row_.children('.sparkline');
      if (cell.length) {
        var jele = $(cell);
        if (code) {
          $(jele).html(
            `<div class="h-100 w-100 d-flex flex-wrap align-items-center"><img class="w-100" src="//chart.shareinvestor.com/charts_cached/charts.pl?type=si_watchlist_mini_plain&amp;id=${code}"></div>`
          );
        }
      }

      // update actions
      cell = row_.children('.action');
      if (cell.length && (cell.html().length === 0 || cell.html() === '&nbsp;')) {
        // copy action buttons
        let actions = '&nbsp;';

        $(row_).closest('table').find('tr td.action').each(function() {
          const content = $(this).html().trim();
          if (content !== '' && content !== '&nbsp;') {
            actions = content;
            return false; // Breaks out of the each loop
          }
        });

        var jele = $(cell);
        $(jele).html(`<div class="copiedActions">${actions}</div>`);
        $(row_).closest('table')[0].dispatchEvent(new Event("streamingTableCopiedActions"));
      }
    }
  };

  priceTable.formatField = function (field_, value_, update_, volume_size_) {
    let formated_val = null;

    if (field_ == 'itemPos') formated_val = Number(price_params.page_row_offset) + Number(value_) + 1;
    else if (field_ == 'short_name')
      formated_val = `<div class='sic_cell'><a class="stock_popup_hover d-inline-block" data-counter="${update_.code}" data-name="${value_}" href='#'><div><div>${value_}</div><div class="text-muted g-text-size-13">${getParamCounterId(update_.code)}</div></div></a></div>`;
    else
      formated_val = BaseTable.prototype.formatField.call(
        this,
        field_,
        value_,
        update_,
        volume_size_,
      );

    return formated_val;
  };

  return priceTable;
};

/** ****************************************************** */
const startStreaming = function (tab) {
  if (price_params.streamingType === 'Portfolio') {
    query = price_params.folder + "." + (new Date().getTime());
  } else {
    const filter_div = $('#sic_filterList div.selected');
    const range = `${max_record_per_page * (price_params.page - 1)}...${max_record_per_page * price_params.page
      }`;

    let type = 'C';
    if (tab == 'stocks') type = 'S';
    else if (tab == 'warrants') type = 'W';

    let counter_market = price_params.market;
    switch (counter_market) {
      case 'sgx':
        counter_market = 'SI';
        break;
      case 'bursa':
        counter_market = 'MY';
        break;
      case 'hkex':
        counter_market = 'HK';
        break;
      case 'set':
        counter_market = 'BK';
        break;
      case 'idx':
        counter_market = 'JK';
        break;
      case 'asx':
        counter_market = 'AX';
        break;
      case 'nyse':
        counter_market = 'NY';
        break;
      case 'nyse_mkt':
        counter_market = 'NM';
        break;
      case 'nasdaq':
        counter_market = 'NQ';
        break;
      case 'world':
        counter_market = 'WLD';
        break;
    }

    let { streamingChannel } = price_params;
    let subscribe_channel = '';
    let default_page_range = null;
    if (filter_div.length > 0) {
      let selected_filter_type = '';
      let channel = '';
      let default_page_limit = null;

      const selected_filter = $('div.sic_selectedFilter', filter_div);
      if (selected_filter.length > 0) {
        selected_filter_type = selected_filter[0].getAttribute('selected_type');
        channel = selected_filter[0].children[0].innerHTML.trim();
        streamingChannel = selected_filter[0].getAttribute('streaming_channel');
        default_page_limit = selected_filter[0].getAttribute('page_limit');
      }

      if (channel.match(/^([A-Z]|0-9)$/)) subscribe_channel = `${channel}_${type}`;
      else if (streamingChannel) {
        if (streamingChannel.match(/\$title\$/))
          subscribe_channel = escape(streamingChannel.replace('$title$', channel));
        else subscribe_channel = escape(streamingChannel);
      } else subscribe_channel = escape(`FDM_${selected_filter_type}`);

      if (default_page_limit) default_page_range = `0...${default_page_limit}`;

      if (filter_div.hasClass('sic_categories')) {
        // under category
        // no pagination for category
        // query = constructQuery(subscribe_channel, counter_market, default_page_range ? default_page_range : "");
        // not sure the reason to disable pagination on category. Enable for performace issue.
        query = constructQuery(subscribe_channel, counter_market, default_page_range || range);
      } else if (filter_div.hasClass('sic_fundamental')) {
        // under fundamental
        // no access for not logged in user
        if (pushPage.getData('user_id'))
          query = constructQuery(subscribe_channel, counter_market, default_page_range || range);
        else query = null;
      } else {
        query = constructQuery(subscribe_channel, counter_market, default_page_range || range);
      }
    } else if (tab == 'indices') {
      var index = price_params.filter;
      if (index) {
        if (index == 'CS06') query = constructQuery('TopAlphabet_I', counter_market, '0...60');
        else if (index == 'WI01') query = constructQuery('TopAlphabet_I', counter_market, '0...60');
        else query = constructQuery(`FDM_${index}`, counter_market, '');
      }
    } else if (tab == 'forex') {
      var index = price_params.filter;
      if (index) {
        if (index == 'WX01') query = constructQuery('TopAlphabet_X', counter_market, '0...60');
      }
    } else if (tab == 'futures') {
      var index = price_params.filter;
      if (index) {
        if (index == 'WF01') query = constructQuery('TopAlphabet_F', counter_market, '0...60');
      }
    }
  }

  if (query) {
    priceTable.setSubscription(new DataSubscription(price_params.streamingType, query, []));
    // update table fields and classes to cater for same layout but different columns
    priceTable.updateFieldsAndClasses();
    pushPage.scheduleTable(priceTable);
  };
};

/** ****************************************************** */
var constructQuery = function (channel, market, range) {
  if (market != '') market = `.${market}`;
  if (range != '') range = `:${range}`;

  const query = channel + market + range;
  return query;
};

let isAlreadyStreaming = false;
export const startPricesStreaming = function () {
  const price_table_obj = document.getElementById('sic_pricesTable');
  if (!price_table_obj || isAlreadyStreaming) {
    return;
  }
  // update price_params based on sic_pricesTable dataset
  price_params.tab = price_table_obj.dataset.tbl_tab;
  price_params.filter = price_table_obj.dataset.filter_code;
  price_params.type = price_table_obj.dataset.filter_type;
  price_params.streamingChannel = price_table_obj.dataset.streaming_channel;
  price_params.layout = price_table_obj.dataset.tbl_layout;
  price_params.page = price_table_obj.dataset.tbl_page_num;
  price_params.page_row_offset = price_table_obj.dataset.tbl_row_offset;
  price_params.market = price_table_obj.dataset.market;
  price_params.streamingType = price_table_obj.dataset.streamingType;
  price_params.folder = price_table_obj.dataset.tbl_folder;
  // ensure pushPage will be in streaming mode
  resetModeToStreaming();
  if (!priceTable) priceTable = constructStreamingTable();
  // re-schedule table only when the partial is loaded after first time
  startStreaming(price_params.tab);
  remove_fix_table_header();
  fix_table_header(price_table_obj, 'sic_pricesTable');
  pushPage.createEngine(); // this does nothing if pushPage engine is already running
  isAlreadyStreaming = true;
};

export const stopPricesStreaming = function () {
  if (!isAlreadyStreaming) {
    return;
  }
  if (priceTable) {
    pushPage.cancelTable(priceTable);
    // note: set streaming table(s) to null for GC
    priceTable = null;
  }
  isAlreadyStreaming = false;
};
