import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import classes from './portfolio.module.css';
import AddTransaction from './Analytics/Transactions/AddTransaction';
import DepositWithdraw from './Analytics/Transactions/DepositWithdraw';
import AddToInstruments from './Analytics/Positions/AddToInstruments';

export default function NoDataAvailable() {
  const selectedPortfolio = useSelector((state) => state.portfolio.selected);
  const loading = useSelector((state) => state.portfolio.loading);
  const result = useSelector((state) => state.transaction.result);
  const [notificationMessage, setNotificationMessage] = useState('');

  const AddTransactionButtonLabel = (
    <>
      <i className="fa-light fa-circle-plus me-2" />
      Add Transactions
    </>
  );

  const AddDepositButtonLabel = (
    <>
      <i className="fa-light fa-circle-plus me-2" />
      Add Deposit
    </>
  );

  useEffect(() => {
    if (result) {
      setNotificationMessage(result);
    }
  }, [result]);

  useEffect(() => {
    if (notificationMessage && notificationMessage.message) {
      const message = Array.isArray(notificationMessage.message) ? (
        <div>
          {notificationMessage.message.map((msg, index) => (
            <div key={index}>
              {msg}
              <br />
            </div>
          ))}
        </div>
      ) : (
        <div>{notificationMessage.message}</div>
      );

      toast(message, {
        type: notificationMessage.success ? 'success' : 'error',
      });
    }
  }, [notificationMessage]);

  return (
    <>
      <div className="text-center g-mt-60">
        <div className="g-mb-20">
          <i
            className={`fa-kit fa-solid-table-list-circle-exclamation fa-3x ${classes.portfolioNoDataIcon}`}
          />
        </div>
        <h3>Your portfolio is empty.</h3>
        <p className="lead">
          Track your holdings manually, input average prices and number of shares.
        </p>
        <p className="lead g-mt-50">
          {selectedPortfolio?.classic && <AddToInstruments loading={loading} />}
          <AddTransaction
            label={AddTransactionButtonLabel}
            variant="primary"
            className="lg g-mr-15"
          />
          <DepositWithdraw label={AddDepositButtonLabel} variant="dark" className="lg g-mr-15" />
        </p>
      </div>
      <ToastContainer transition={Slide} />
    </>
  );
}
