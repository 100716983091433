import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Button } from 'react-bootstrap';
import IntlTelInput, { intlTelInput } from 'intl-tel-input/reactWithUtils';
import 'intl-tel-input/styles';

import { sendOTP, membershipResetOtpRetrySeconds, verifyAccount } from './signUpSlice';

const { CancelToken } = axios;

function MobileVerification({ title = 'Account Verification' }) {
  const ERROR_MAP = [
    'Invalid mobile number',
    'Invalid country code',
    'Mobile number too short',
    'Mobile number too long',
    'Invalid mobile number',
  ];

  const dispatch = useDispatch();
  const source = CancelToken.source();
  const loading = useSelector((state) => state.signUp.loading);
  const userid = useSelector((state) => state.signUp.userid);
  const otpSentCount = useSelector((state) => state.signUp.otpSentCount);
  const err = useSelector((state) => state.signUp.err);
  const otpRetrySeconds = useSelector((state) => state.signUp.otpRetrySeconds);
  const [mobile, setMobile] = useState('');
  const [isValid, setIsValid] = useState('');
  const [errorCode, setErrorCode] = useState('');
  const [error, setError] = useState(null);
  const [OTP, setOTP] = useState();
  const [counter, setCounter] = useState();
  const [countryCode, setCountryCode] = useState();

  const handleSendOTP = async (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() && isValid) {
      setError();
      dispatch(
        sendOTP({
          userid,
          mobile,
          dialCode: countryCode,
          cancelToken: source.token,
        }),
      );
    } else if (mobile.length === 0) {
      setError('Please set your mobile number');
    } else if (!isValid) {
      setError(ERROR_MAP[errorCode || 0] || 'Invalid number');
    }
    event.stopPropagation();
  };

  const handleVerifyOTP = (event) => {
    event.preventDefault();
    dispatch(
      verifyAccount({
        userid,
        mobile,
        dialCode: countryCode,
        otp: OTP,
        cancelToken: source.token,
      }),
    );
    event.stopPropagation();
  };

  const handleMobileChange = (newMobile) => {
    const newMobileF = newMobile.replace(`+${countryCode}`, '').replaceAll(' ', '');
    if (newMobileF === mobile) {
      return;
    }

    setMobile(newMobileF);
  };

  const handleCountryChange = (newCountryCode) => {
    const newCountryCodeF = intlTelInput.getCountryData().filter((c) => {
      return c.iso2 === newCountryCode;
    });

    if (newCountryCodeF[0]?.dialCode === countryCode) {
      return;
    }

    setCountryCode(newCountryCodeF[0]?.dialCode);
  };

  let otpRetryInterval = 0;
  useEffect(() => {
    // clear previous interval set, if any
    if (otpRetryInterval) {
      clearInterval(otpRetryInterval);
      otpRetryInterval = 0;
    }

    if (!otpRetrySeconds) {
      return;
    }

    let i = otpRetrySeconds;
    setCounter(i);

    otpRetryInterval = setInterval(() => {
      i -= 1;
      setCounter(i);
      if (i === 0) {
        clearInterval(otpRetryInterval);
        dispatch(membershipResetOtpRetrySeconds());
      }
    }, 1000);

    // this return ensures that countdown timer interval is cleared whenever the component is unloaded
    return () => {
      if (otpRetryInterval) {
        clearInterval(otpRetryInterval);
        otpRetryInterval = 0;
      }
    };
  }, [otpRetrySeconds]);

  return (
    <div className="text-center">
      <Modal.Title className="text-center g-mb-30">
        <h3 id="signUpTitle">{title}</h3>
      </Modal.Title>
      {otpSentCount === 0 && (
        <div>
          <p>
            Enter your mobile number for verification.
            <br />
            We will send you a <strong>One Time Password (OTP)</strong>.
          </p>

          <Form noValidate onSubmit={handleSendOTP} className="m-4">
            <Form.Group className="mb-3">
              <IntlTelInput
                initialValue={mobile}
                onChangeNumber={handleMobileChange}
                onChangeCountry={handleCountryChange}
                onChangeValidity={setIsValid}
                onChangeErrorCode={setErrorCode}
                inputProps={{
                  className: 'form-control form-control-lg g-text-size-16',
                  required: true,
                  onBlur: (e) => handleMobileChange(e.target.value),
                }}
                initOptions={{
                  initialCountry: 'auto',
                  geoIpLookup(success, failure) {
                    fetch('https://ipapi.co/json')
                      .then((res) => {
                        return res.json();
                      })
                      .then((data) => {
                        success(data.country_code);
                      })
                      .catch(() => {
                        failure();
                      });
                  },
                  containerClass: 'w-100',
                  separateDialCode: true,
                  strictMode: true,
                }}
              />
              <div className="invalid-feedback d-block text-start">{error || err}</div>
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              className="w-100 text-center"
              disabled={loading}>
              Get OTP
            </Button>
          </Form>
        </div>
      )}
      {otpSentCount > 0 && (
        <div>
          <p>
            You will get an OTP via <strong>SMS</strong>.
            <br />
            Enter the 6 digit verification code received on your mobile.
          </p>

          <Form noValidate onSubmit={handleVerifyOTP} className="m-4">
            <Form.Group className="mb-3">
              <OtpInput length={6} onComplete={setOTP} />
              <div className="invalid-feedback d-block">{err}</div>
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              className="w-100 text-center mb-2"
              disabled={loading || !OTP || OTP.length < 6}>
              Verify
            </Button>
            <Button
              variant="secondary"
              className="w-100 text-center mb-2"
              disabled={counter > 0}
              onClick={handleSendOTP}>
              Resend OTP {counter > 0 && `in ${counter}s`}
            </Button>
          </Form>
        </div>
      )}
    </div>
  );
}

export default MobileVerification;

function OtpInput({ length, onComplete }) {
  const inputRef = useRef(Array(length).fill(null));
  const [OTP, setOTP] = useState(Array(length).fill(''));

  const handleTextChange = (input, index, e) => {
    const newPin = [...OTP];
    newPin[index] = input;
    setOTP(newPin);

    // check if the user has entered the first digit, if yes, automatically focus on the next input field and so on.
    if (input.length === 1 && index < length - 1) {
      inputRef.current[index + 1]?.focus();
    }
    if (input.length === 0 && index > 0) {
      inputRef.current[index - 1]?.focus();
    }
  };

  useEffect(() => {
    onComplete(OTP.join(''));
  }, [OTP]);

  return (
    <div className="grid grid-cols-4 gap-5">
      {Array.from({ length }, (_, index) => (
        <input
          key={index}
          type="text"
          maxLength={1}
          value={OTP[index]}
          onChange={(e) => handleTextChange(e.target.value, index, e)}
          ref={(ref) => {
            inputRef.current[index] = ref;
          }}
          className="form-control form-control-lg g-text-size-16 border border-solid border-border-slate-500 focus:border-blue-600 outline-none d-inline-block g-width-45 me-2"
          style={{ marginRight: index === length - 1 ? '0' : '10px' }}
        />
      ))}
    </div>
  );
}

OtpInput.propTypes = {
  length: PropTypes.number,
  onComplete: PropTypes.func,
};
