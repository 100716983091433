import React from 'react';

function Notes() {
  return (
    <>
      <div className="text-muted g-mb-40">
        <div className="mb-2">
          <strong>Notes:</strong>
        </div>
        <div className="sic_legendAlpha">
          <div className="row">
            {/* column a */}
            <div className="col-lg-4">
              <div className="d-flex gap-1 ms-4">
                <sup className="footer-disclaimer">a</sup>
                <div className="d-flex gap-1 ms-4">
                  <div className="g-text-size-14 footer-disclaimer">
                    <div>
                      DIR/CEO - Director (who may also be a Substantial Shareholder/Unitholder) /
                      Chief Executive Officer
                    </div>
                    <div>TM/RP - Trustee-Manager/Responsible Person</div>
                    <div>SSH - Substantial Shareholder/Unitholder </div>
                    <div>COY - Company Share Buyback</div>
                  </div>
                </div>
              </div>
            </div>

            {/* column b */}
            <div className="col-lg-4">
              <div className="d-flex gap-1 ms-4">
                <sup className="footer-disclaimer">b</sup>
                <div className="d-flex gap-1 ms-4">
                  <div className="g-text-size-14 footer-disclaimer">
                    <div>S/U – Ordinary Voting Shares/Units/Stapled Securities</div>
                    <div>OS/OU - Other Types of Shares/Units/Stapled Securities</div>
                    <div>
                      R/O/W - Rights/Options/Warrants over Voting Shares/Units/Stapled Securities
                    </div>
                    <div>
                      D - Convertible Debentures over Voting Shares/Units/Stapled Securities
                    </div>
                    <div>R/O (D) - Rights/Options over Debentures</div>
                    <div>
                      C - Contracts under which any person has a right to call for or to make
                      delivery of shares/units/stapled securities in the Listed Issuer
                    </div>
                    <div>PI – Participatory Interests</div>
                    <div>PS - Perpetual Securities</div>
                    <div>OTH - Others</div>
                  </div>
                </div>
              </div>
            </div>

            {/* column c */}
            <div className="col-lg-2">
              <div className="d-flex gap-1 ms-4">
                <sup className="footer-disclaimer">c</sup>
                <div className="d-flex gap-1 ms-4">
                  <div className="g-text-size-14 footer-disclaimer">
                    <div>Direct & Deemed Interests</div>
                  </div>
                </div>
              </div>
            </div>
            {/* column d */}
            <div className="col-lg-2">
              <div className="d-flex gap-1 ms-4">
                <sup className="footer-disclaimer">d</sup>
                <div className="d-flex gap-1 ms-4">
                  <div className="g-text-size-14 footer-disclaimer">
                    <div>As at Date of Effective Change of Interest</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="text-muted">
        <div className="mb-2">
          <strong>Notes:</strong>
        </div>
        <div className="sic_legendAlpha">
          <div className="d-flex gap-1 ms-4">
            <span className="footer-disclaimer">1</span>
            <div className="footer-disclaimer">
              <div>
                There is generally a delay between the announcement date and the date of effective
                change of interest. ShareInvestor updates the information as soon as the trades are
                announced by the respective listed companies.
              </div>
            </div>
          </div>
          <div className="d-flex gap-2 ms-4">
            <span className="footer-disclaimer">2</span>
            <div className="footer-disclaimer">
              <div>
                Please click <a href="mailto:admin@shareinvestor.com">here</a> to let us know if you
                spot any mistakes.
              </div>
            </div>
          </div>
          <div className="d-flex gap-1 ms-4">
            <span className="footer-disclaimer">3</span>
            <div className="footer-disclaimer">
              <div>
                Some of the data presented has been intentionally marked and ShareInvestor will not
                hesitate to prosecute to protect our intellectual property rights. Our licensing
                charge is $100 per data item per month.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Notes;
