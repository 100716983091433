import React from 'react';

export default function RemarksSgx() {
  return (
    <table className="table table-borderless g-text-size-13 g-lg-text-size-14 table-shrink mb-0 text-nowrap">
      <thead>
        <tr>
          <th>Remarks</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>SUSP</th>
          <td>Suspended</td>
        </tr>
        <tr>
          <th>PL</th>
          <td>Pending Listing</td>
        </tr>
        <tr>
          <th>DL</th>
          <td>Delisted</td>
        </tr>
        <tr>
          <th>CW</th>
          <td>Conditional When Issue</td>
        </tr>
        <tr>
          <th>BI</th>
          <td>BuyIn Only</td>
        </tr>
        <tr>
          <th>H</th>
          <td>Trading Halt</td>
        </tr>
        <tr>
          <th>XD</th>
          <td>Ex Dividend</td>
        </tr>
        <tr>
          <th>XB</th>
          <td>Ex Bonus</td>
        </tr>
        <tr>
          <th>XR</th>
          <td>Ex Rights</td>
        </tr>
        <tr>
          <th>XO</th>
          <td>Ex Offer</td>
        </tr>
        <tr>
          <th>XE</th>
          <td>Ex Entitlement</td>
        </tr>
        <tr>
          <th>XI</th>
          <td>Ex Interest</td>
        </tr>
        <tr>
          <th>XA</th>
          <td>Ex All</td>
        </tr>
        <tr>
          <th>CIRB</th>
          <td>Circuit Breaker Triggered</td>
        </tr>
        <tr>
          <th>IMM</th>
          <td>Immediate Delivery</td>
        </tr>
        <tr>
          <th>PA</th>
          <td>Pending Announcement</td>
        </tr>
        <tr>
          <th>CT</th>
          <td>Conditional Trading</td>
        </tr>
        <tr>
          <th>WI</th>
          <td>When Issue</td>
        </tr>
        <tr>
          <th>J</th>
          <td>Adjust</td>
        </tr>
        <tr>
          <th>CD</th>
          <td>Cum Dividend</td>
        </tr>
        <tr>
          <th>CR</th>
          <td>Cum Bonus</td>
        </tr>
        <tr>
          <th>CB</th>
          <td>Cum Rights</td>
        </tr>
        <tr>
          <th>CO</th>
          <td>Cum Offer</td>
        </tr>
        <tr>
          <th>CE</th>
          <td>Cum Entitlement</td>
        </tr>
        <tr>
          <th>CI</th>
          <td>Cum Interest</td>
        </tr>
        <tr>
          <th>CA</th>
          <td>Cum All</td>
        </tr>
        <tr>
          <th>SC</th>
          <td>Scripless Conversion</td>
        </tr>
        <tr>
          <th>CIRH</th>
          <td>Halt due to Circuit Breaker Triggered</td>
        </tr>
      </tbody>
    </table>
  );
}
