import React, { lazy, Suspense } from 'react';
import { Spinner } from 'react-bootstrap';

export const createLoadableComponent = (importFunc) => {
  const LazyComponent = lazy(importFunc);

  return function (props) {
    return (
      <Suspense
        fallback={
          <div className="text-center">
            <Spinner />
          </div>
        }>
        <LazyComponent {...props} />
      </Suspense>
    );
  };
};
