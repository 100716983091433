// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import * as ActiveStorage from '@rails/activestorage';
import Rails from '@rails/ujs';
import * as bootstrap from 'bootstrap';
import '../../../vendor/fontawesome-pro/js/all';
import '../channels';
import 'jquery';
import './clientBundle';
import '../utils/Streaming';
import Turbolinks from 'turbolinks';
import '../stylesheets/application.scss';

const images = require.context('../../assets/images', true);

require('./header.js');
require('./footer.js');

window.bootstrap = bootstrap;

Rails.start();
Turbolinks.start();
ActiveStorage.start();
