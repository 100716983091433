import { createSlice } from '@reduxjs/toolkit';
import api from '../../utils/api';

const watchlistSlice = createSlice({
  name: 'watchlist',
  initialState: {
    loading: true,
    completed: false,
    selected: {
      id: null,
      name: '',
      counters: [],
      encFolder: '',
    },
    all: [],
    selectedLayout: '',
    allFields: null,
    currentCustomLayoutFields: null,
    isLegalUser: false,
    isEodUser: true,
    isDuringStreamingHours: false,
    analysisChartsFeatures: {},
    countryMarket: '',
    err: null,
    response: null,
  },
  reducers: {
    watchlistFetchStart(state, action) {
      state.selected = {
        id: null,
        name: '',
        counters: [],
      };
      state.all = [];
      state.err = null;
      state.completed = false;
      state.loading = true;
      state.response = null;
    },
    watchlistFetchSuccess(state, action) {
      state.selected = action.payload.data.selectedFolder;
      state.all = action.payload.data.allFolders;
      state.selectedLayout = action.payload.data.selectedLayout;
      state.isLegalUser = action.payload.data.isLegalUser;
      state.isEodUser = action.payload.data.isEodUser;
      state.isDuringStreamingHours = action.payload.data.isDuringStreamingHours;
      state.analysisChartsFeatures = action.payload.data.has_analysis_charts_feature;
      state.countryMarket = action.payload.data.country_market;
      state.completed = true;
      state.loading = false;
      if (action.payload.data.all_fields) {
        state.allFields = action.payload.data.all_fields;
      }
      if (action.payload.data.custom_layout) {
        state.currentCustomLayoutFields = action.payload.data.custom_layout;
      }
    },
    watchlistFetchFail(state, action) {
      state.err = action.payload;
      state.completed = false;
      state.loading = false;
    },
    watchlistCreateUpdateFetchStart(state, action) {
      state.err = null;
      state.completed = false;
      state.loading = true;
      state.response = null;
    },
    watchlistCreateUpdateFetchSuccess(state, action) {
      if (action.payload.data.error) {
        state.err = { message: action.payload.data.error };
      } else {
        state.selected = action.payload.data.selectedFolder;
        state.all = action.payload.data.allFolders;
        state.selectedLayout = action.payload.data.selectedLayout;
        state.response = action.payload.data.message;
      }
      state.completed = true;
      state.loading = false;
    },
    watchlistCreateUpdateFetchFail(state, action) {
      state.err = action.payload;
      state.completed = false;
      state.loading = false;
    },
    handleChangeSelectedWatchlist(state, action) {
      state.selected = state.all.filter((w) => {
        return w.id === action.payload.selectedWatchlistID;
      })[0];
    },
    watchlistCountersFetchStart(state, action) {
      state.err = null;
      state.completed = false;
      state.loading = true;
      state.selected.counters = [];
      state.response = null;
    },
    watchlistCountersFetchSuccess(state, action) {
      if (action.payload.data.error) {
        state.err = { message: action.payload.data.error };
      } else {
        state.selected.counters = action.payload.data.counters;
        if (action.payload.data.all_fields) {
          state.allFields = action.payload.data.all_fields;
        }
        if (action.payload.data.custom_layout) {
          state.currentCustomLayoutFields = action.payload.data.custom_layout;
        }
      }

      state.completed = true;
      state.loading = false;
    },
    watchlistCountersFetchFail(state, action) {
      state.err = action.payload;
      state.completed = false;
      state.loading = false;
    },
    handleChangeSelectedLayout(state, action) {
      state.selectedLayout = action.payload.selectedLayout;
    },

    watchlistCounterUpdateStart(state, action) {
      state.err = null;
      state.completed = false;
      state.loading = true;
      state.response = '';
    },
    watchlistCounterUpdateSuccess(state, action) {
      if (action.payload.data.error) {
        state.err = { message: action.payload.data.error };
      } else {
        state.selected = action.payload.data.selectedFolder;
        state.response = action.payload.data.message;
      }
      state.completed = true;
      state.loading = false;
    },
    watchlistCounterUpdateFail(state, action) {
      state.err = action.payload;
      state.completed = false;
      state.loading = false;
    },
    resetMessage(state, action) {
      state.err = null;
      state.response = null;
    },
  },
});

export default watchlistSlice.reducer;

const {
  watchlistFetchStart,
  watchlistFetchSuccess,
  watchlistFetchFail,
  watchlistCreateUpdateFetchStart,
  watchlistCreateUpdateFetchSuccess,
  watchlistCreateUpdateFetchFail,
  handleChangeSelectedWatchlist,
  watchlistCountersFetchStart,
  watchlistCountersFetchSuccess,
  watchlistCountersFetchFail,
  handleChangeSelectedLayout,
  watchlistCounterUpdateStart,
  watchlistCounterUpdateSuccess,
  watchlistCounterUpdateFail,
  resetMessage,
} = watchlistSlice.actions;

export const fetchData =
  ({ selectedID, selectedLayout, cancelToken }) =>
  (dispatch) => {
    dispatch(watchlistFetchStart());

    let url = 'watchlist';
    if (selectedID) {
      url += `/${selectedID}`;
    }
    url = `${url}.json`;
    if (selectedLayout && selectedLayout.length > 0) {
      url = `${url}?layout=${selectedLayout}`;
    }

    api
      .get(url, { cancelToken })
      .then(({ data }) => {
        dispatch(watchlistFetchSuccess({ data }));
      })
      .catch((error) => {
        dispatch(watchlistFetchFail(error));
      });
  };

export const createFetchData =
  ({ name, cancelToken }) =>
  (dispatch) => {
    dispatch(watchlistCreateUpdateFetchStart());

    api
      .post(
        'watchlist.json',
        {
          name,
        },
        { cancelToken },
      )
      .then(({ data }) => {
        dispatch(watchlistCreateUpdateFetchSuccess({ data }));
      })
      .catch((error) => {
        dispatch(watchlistCreateUpdateFetchFail(error));
      });
  };

export const updateFetchData =
  ({ id, name, setDefault, cancelToken }) =>
  (dispatch) => {
    dispatch(watchlistCreateUpdateFetchStart());
    const params = {};

    if (name) {
      params.name = name;
    }

    if (setDefault) {
      params.set_default = setDefault;
    }

    api
      .patch(`watchlist/${id}.json`, params, { cancelToken })
      .then(({ data }) => {
        dispatch(watchlistCreateUpdateFetchSuccess({ data }));
      })
      .catch((error) => {
        dispatch(watchlistCreateUpdateFetchFail(error));
      });
  };

export const deleteFetchData =
  ({ id, cancelToken }) =>
  (dispatch) => {
    dispatch(watchlistCreateUpdateFetchStart());

    api
      .delete(`watchlist/${id}.json`, { cancelToken })
      .then(({ data }) => {
        dispatch(watchlistCreateUpdateFetchSuccess({ data }));
      })
      .catch((error) => {
        dispatch(watchlistCreateUpdateFetchFail(error));
      });
  };

export const addToWatchlistFetchData =
  ({ id, counter, cancelToken }) =>
  (dispatch) => {
    dispatch(watchlistCounterUpdateStart());
    const params = { counter };
    api
      .post(`watchlist/${id}/add_counter.json`, params, { cancelToken })
      .then(({ data }) => {
        dispatch(watchlistCounterUpdateSuccess({ data }));
      })
      .catch((error) => {
        dispatch(watchlistCounterUpdateFail(error));
      });
  };

export const removeFromWatchlistFetchData =
  ({ id, counter, cancelToken }) =>
  (dispatch) => {
    dispatch(watchlistCounterUpdateStart());
    const params = { counter };
    api
      .post(`watchlist/${id}/remove_counter.json`, params, { cancelToken })
      .then(({ data }) => {
        dispatch(watchlistCounterUpdateSuccess({ data }));
      })
      .catch((error) => {
        dispatch(watchlistCounterUpdateFail(error));
      });
  };
const getCounterList =
  ({ selectedWatchlistID, selectedLayout, cancelToken }) =>
  (dispatch) => {
    dispatch(watchlistCountersFetchStart());

    let url = `/watchlist/${selectedWatchlistID}/counter_list.json`;
    if (selectedLayout && selectedLayout.length > 0) {
      url = `${url}?layout=${selectedLayout}`;
    }

    api
      .get(url, { cancelToken })
      .then(({ data }) => {
        dispatch(watchlistCountersFetchSuccess({ data }));
      })
      .catch((error) => {
        dispatch(watchlistCountersFetchFail(error));
      });
  };
export const changeSelectedWatchlist =
  ({ selectedWatchlistID, selectedLayout, cancelToken }) =>
  (dispatch) => {
    dispatch(handleChangeSelectedWatchlist({ selectedWatchlistID }));
    dispatch(getCounterList({ selectedWatchlistID, selectedLayout, cancelToken }));
  };
export const changeSelectedLayout =
  ({ selectedWatchlistID, selectedLayout, cancelToken }) =>
  (dispatch) => {
    dispatch(handleChangeSelectedLayout({ selectedLayout }));
    dispatch(getCounterList({ selectedWatchlistID, selectedLayout, cancelToken }));
  };

export const clearMessage = () => (dispatch) => {
  dispatch(resetMessage());
};
