import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import Dropdown from 'react-bootstrap/Dropdown';
import DateTimePicker from './DateTimePicker';

export default function DateFilter({
  isInterval,
  dateFormat,
  minFromDate,
  fromDate,
  handleFromDateChange,
  toDate,
  handleToDateChange,
  date,
  handleDateChange,
  handleReset,
  popupDirection,
  options,
}) {
  const [dropdownShown, setDropdownShown] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [dropdownLabel, setDropdownLabel] = useState('Custom Date Range');
  const calendarContainerRef = useRef(null);
  const onDropdownToggle = (nextShow, meta) => {
    setDropdownShown(nextShow);
  };

  useEffect(() => {
    if (dropdownShown) {
      setShowCalendar(true);
    }
  }, [dropdownShown]);

  useEffect(() => {
    if (fromDate && !toDate) {
      setDropdownLabel(`From ${dayjs(fromDate).format('DD/MM/YYYY')}`);
    } else if (!fromDate && toDate) {
      setDropdownLabel(`To ${dayjs(toDate).format('DD/MM/YYYY')}`);
    } else if (fromDate && toDate) {
      setDropdownLabel(
        `From ${dayjs(fromDate).format('DD/MM/YYYY')} To ${dayjs(toDate).format('DD/MM/YYYY')}`,
      );
    } else {
      setDropdownLabel(`Custom Date Range`);
    }
  }, [fromDate, toDate]);

  return isInterval ? (
    <Dropdown
      show={dropdownShown}
      onToggle={onDropdownToggle}
      className="datepicker-dropdown d-inline-block"
      drop="down"
      autoClose="outside">
      <Dropdown.Toggle className="h-100" variant="light">
        <span className="g-mr-10">{dropdownLabel}</span>
      </Dropdown.Toggle>
      <Dropdown.Menu className="g-width-200">
        <div className="g-fw-500 g-mb-15">Custom Dates</div>
        {showCalendar && (
          <>
            <label htmlFor="filterDateFrom" className="g-mb-20">
              <div className="g-text-size-13 g-fw-500 g-mb-5">From</div>
              <DateTimePicker
                id="myid"
                value={fromDate}
                onChange={handleFromDateChange}
                className="g-max-per-width-100 g-per-width-100"
                options={{
                  altInput: true,
                  altFormat: 'd/m/Y',
                  disableMobile: false,
                  dateFormat,
                  maxDate: new Date(),
                  appendTo: calendarContainerRef.current,
                }}>
                <input
                  id="filterDateFrom"
                  className="form-control g-text-size-14"
                  type="text"
                  placeholder="Select Date.."
                  data-input
                />
              </DateTimePicker>
            </label>
            <label htmlFor="filterDateTo" className="g-mb-20">
              <div className="g-text-size-13 g-fw-500 g-mb-5">To</div>
              <DateTimePicker
                value={toDate}
                onChange={handleToDateChange}
                className="g-max-per-width-100 g-per-width-100"
                options={{
                  altInput: true,
                  altFormat: 'd/m/Y',
                  disableMobile: false,
                  dateFormat,
                  minDate: fromDate,
                  maxDate: new Date(),
                  appendTo: calendarContainerRef.current,
                }}>
                <input
                  id="filterDateTo"
                  className="form-control g-text-size-14 "
                  type="text"
                  placeholder="Select Date.."
                  data-input
                />
              </DateTimePicker>
            </label>
          </>
        )}
        <button type="button" className="btn btn-reset g-min-width-0" onClick={handleReset}>
          Reset
        </button>
        <div ref={calendarContainerRef} id="calendar_container" className={popupDirection} />
      </Dropdown.Menu>
    </Dropdown>
  ) : (
    <DateTimePicker value={date} onChange={handleDateChange} options={options}>
      <input
        id="filterDate"
        className="form-control"
        type="text"
        placeholder="Select Date.."
        data-input
      />
    </DateTimePicker>
  );
}

DateFilter.propTypes = {
  isInterval: PropTypes.bool,
  dateFormat: PropTypes.string,
  minFromDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  fromDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  handleFromDateChange: PropTypes.func,
  toDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  handleToDateChange: PropTypes.func,
  date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  handleDateChange: PropTypes.func,
  handleReset: PropTypes.func,
  popupDirection: PropTypes.string,
  options: PropTypes.oneOfType([PropTypes.object]),
};

DateFilter.defaultProps = {
  dateFormat: 'Y-m-d',
  popupDirection: 'right', // only left/right
};
