import { createSlice } from '@reduxjs/toolkit';
// TODO: Refactor to use local state instead since this is not needed globally
const homeSlice = createSlice({
  name: 'home',
  initialState: {
    marketsSection: {
      index: 'STI.SI',
      isIntradayChart: true,
    },
  },
  reducers: {
    onChangeMarketsSectionIndex(state, action) {
      state.marketsSection.index = action.payload.code;
      state.marketsSection.isIntradayChart = action.payload.intraday_chart;
    },
  },
});

export default homeSlice.reducer;

const { onChangeMarketsSectionIndex } = homeSlice.actions;

export const changeMarketsSectionIndex = (activeIndex) => (dispatch) => {
  dispatch(onChangeMarketsSectionIndex(activeIndex));
};
