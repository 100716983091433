import React, { useState } from 'react';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Nav from 'react-bootstrap/Nav';
import PropTypes from 'prop-types';
import { convertPercValue } from './utils';

import { useDispatch } from 'react-redux';
import { setAddedCriterias } from './marketScreenerSlice';

const { CancelToken } = axios;

function PortfolioSeries({ data, market, criteriaConfig }) {
  const dispatch = useDispatch();
  const source = CancelToken.source();
  const toggleModal = () => setShowModal((_show) => !_show);
  const [showModal, setShowModal] = useState(false);

  const handleTemplateClick = (event, newTemplateVal) => {
    event.preventDefault();
    const newCriteriasConfig = newTemplateVal.criteria;
    const criteriaList = [];
    newCriteriasConfig.forEach((criteria_group) => {
      if (!criteria_group || criteria_group.length === 0) {
        return;
      }

      // populate OR criterias
      const subCriteriaList = [];
      if (criteria_group.length > 1) {
        criteria_group.slice(1).forEach((or_criteria) => {
          subCriteriaList.push({
            [or_criteria.key]: {
              active: true,
              title:
                criteriaConfig[or_criteria.key].display_name ||
                criteriaConfig[or_criteria.key].title,
              tmpl: criteriaConfig[or_criteria.key].form_tmpl,
              form_val: criteriaConfig[or_criteria.key].form_val,
              market: market,
              templateValue: convertPercValue(
                or_criteria,
                criteriaConfig[or_criteria.key].form_tmpl,
              ),
            },
          });
        });
      }

      // populate AND criterias
      const and_criteria = criteria_group[0];
      criteriaList.push({
        [and_criteria.key]: {
          active: true,
          title:
            criteriaConfig[and_criteria.key].display_name || criteriaConfig[and_criteria.key].title,
          tmpl: criteriaConfig[and_criteria.key].form_tmpl,
          form_val: criteriaConfig[and_criteria.key].form_val,
          market: market,
          templateValue: convertPercValue(and_criteria, criteriaConfig[and_criteria.key].form_tmpl),
          sub_criterias: subCriteriaList,
        },
      });
    });

    dispatch(
      setAddedCriterias({
        criteria: criteriaList,
      }),
    );
    toggleModal();
  };

  return (
    <>
      <Button variant="light" className="p-3 px-4" onClick={toggleModal}>
        <i className="fa-xl text-primary fa-solid fa-briefcase g-mr-10"></i>The Portfolio Series
      </Button>
      <Modal show={showModal} centered onHide={toggleModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>The Portfolio Series</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row g-3">
            {Object.entries(data).map(([k, v]) => (
              <div className="col-lg-6" key={k}>
                <Nav.Link
                  className="btn btn-light text-start py-2 px-3 h-100 border-1 border g-border-radius-10"
                  onClick={(e) => handleTemplateClick(e, v)}>
                  <div className="d-flex flex-row gap-3 align-items-center">
                    <i className={`fa-xl text-primary ${v.icon}`}></i>
                    <div>
                      <h6 className="mb-0">{v.title}</h6>
                      <div>{v.text}</div>
                    </div>
                    <i className="ms-auto g-text-size-25 text-secondary fa-light fa-angle-right text-gray-600"></i>
                  </div>
                </Nav.Link>
              </div>
            ))}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

PortfolioSeries.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]),
  market: PropTypes.string,
  criteriaConfig: PropTypes.oneOfType([PropTypes.object]),
};

export default PortfolioSeries;
