import axios from 'axios';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSearchParams, useLocation } from 'react-router-dom';

import api from '../../../utils/api';
import Item from './Item';
import YearFilter from './YearFilter';
import Loader from '../../../components/Loader';
import APIErrorHandler from '../../../components/APIErrorHandler';

const { CancelToken } = axios;

function Reports({ counter, type }) {
  const source = CancelToken.source();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [year, setYear] = useState(searchParams.get('year'));
  const [reports, setReports] = useState({});
  const [isSSR, setIsSSR] = useState(true);
  const [error, setError] = useState();
  const location = useLocation();

  const hasFinancialStatetments = () => {
    return (
      type === 'financial_statements' &&
      reports &&
      ((Object.values(reports).flat().length > 0 && (!year || year === 'All')) ||
        (year && year !== 'All'))
    );
  };

  const handleYearChange = (newYear) => {
    if (!newYear || year === newYear) {
      return;
    }

    setSearchParams({ year: newYear.toString() });
    setYear(newYear.toString());
  };

  useEffect(() => {
    const cancelToken = source.token;
    setIsLoading(true);

    let url = `quote/${counter}/reports.json?report_type=${type}`;
    if (year && year !== 'All') {
      url = `${url}&year=${year}`;
    }

    api
      .get(url, { cancelToken })
      .then(({ data }) => {
        setReports(data.reports);
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
      });
  }, [type, year]);

  useEffect(() => {
    if (year !== searchParams.get('year')) {
      setYear(searchParams.get('year'));
    }
  }, [location]);

  useEffect(() => {
    setIsSSR(typeof document === 'undefined');

    return () => {};
  }, []);

  if (isSSR) {
    return <Loader visible={isSSR} classes="g-height-800" />;
  }

  if (error) {
    return <APIErrorHandler error={error} />;
  }

  return (
    <>
      <h3 className={`g-mb-${type === 'financial_statements' ? '50' : '70'}`}>Reports Download</h3>
      {hasFinancialStatetments() && (
        <YearFilter year={year ? String(year) : null} handleYearChange={handleYearChange} />
      )}
      <div className="overflow-hidden">
        <div className="row gx-5" id={type}>
          <Loader visible={isLoading} />
          {(!reports || Object.values(reports).flat().length === 0) && !isLoading && (
            <div className="col-12 text-center">No data found.</div>
          )}
          {reports &&
            Object.values(reports).flat().length > 0 &&
            !isLoading &&
            Object.keys(reports)
              .reverse()
              .map((label) => {
                if (!reports[label] || reports[label].length === 0) {
                  return null;
                }
                return (
                  <React.Fragment key={label}>
                    {type === 'financial_statements' && label !== 'data' && (
                      <h3 className="my-4">{label}</h3>
                    )}
                    {reports[label] &&
                      reports[label].map((report) => {
                        return <Item report={report} key={report.key} reportType={type} />;
                      })}
                  </React.Fragment>
                );
              })}
        </div>
      </div>
    </>
  );
}

Reports.propTypes = {
  counter: PropTypes.string,
  type: PropTypes.string,
};

export default Reports;
