import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams, useLocation } from 'react-router-dom';
import { fetchData, setSelectedModel } from './intrinsicValueAnalysisSlice';
import Loader from '../../../components/Loader';
import APIErrorHandler from '../../../components/APIErrorHandler';
import SearchStockFilter from './SearchStockFilter';
import IntrinsicAnalysisCalculator from './IntrinsicAnalysisCalculator';
import PeerAnalysisTable from './PeerAnalysisTable';
import PeerAnalysisChart from './PeerAnalysisChart';
import { createLoadableComponent } from '../../../utils/Loadable';

const QuotePriceSummary = createLoadableComponent(() =>
  import('../../../components/QuotePriceSummary'),
);
const { CancelToken } = axios;
export default function PeerAnalysisTab({ defaultCounter }) {
  const source = CancelToken.source();
  const dispatch = useDispatch();
  const [isSSR, setIsSSR] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const selectedModel = useSelector((state) => state.intrinsicValueAnalysis.selectedModel);
  const data = useSelector((state) => state.intrinsicValueAnalysis.data);
  const priceSummary = useSelector((state) => state.intrinsicValueAnalysis.priceSummary);
  const isDuringStreamingHours = useSelector(
    (state) => state.intrinsicValueAnalysis.isDuringStreamingHours,
  );
  const peerAnalysisTableData = useSelector(
    (state) => state.intrinsicValueAnalysis.peerAnalysisTableData,
  );
  const error = useSelector((state) => state.intrinsicValueAnalysis.err);
  const loading = useSelector((state) => state.intrinsicValueAnalysis.loading);
  const [counter, setCounter] = useState(searchParams.get('counter') || defaultCounter);

  const handleCounterChange = (counterData) => {
    const newCounter = counterData.symbol;

    if (!newCounter || counter === newCounter) {
      return;
    }
    setSearchParams({ counter: newCounter });
    setCounter(newCounter);
  };

  // change default selectedModel to discounted_earning if it is gordon_growth from valuation tab
  useEffect(() => {
    if (selectedModel === 'gordon_growth') {
      dispatch(setSelectedModel({ selectedModel: 'discounted_earning' }));
    }
  }, []);

  useEffect(() => {
    setIsSSR(typeof document === 'undefined');
    dispatch(
      fetchData({
        counter,
        cancelToken: source.token,
        type: 'peer_analysis',
      }),
    );
    return () => {};
  }, [counter]);

  useEffect(() => {
    if (searchParams.get('counter') && counter !== searchParams.get('counter')) {
      setCounter(searchParams.get('counter'));
    }
  }, [location]);

  if (isSSR || loading) {
    return <Loader visible={isSSR || loading} className="g-height-800" />;
  }

  if (error) {
    return <APIErrorHandler error={error} />;
  }

  return (
    <>
      <SearchStockFilter counter={counter} handleCounterChange={handleCounterChange} />
      {data && data.success && !loading && (
        <>
          {Object.keys(priceSummary).length > 0 && (
            <div className="g-mt-20">
              <QuotePriceSummary
                counter={data.counter}
                priceSummary={priceSummary}
                isDuringStreamingHours={isDuringStreamingHours}
              />
            </div>
          )}

          {data && data.peer_comparison ? (
            <>
              {peerAnalysisTableData && Object.keys(peerAnalysisTableData).length > 0 && (
                <div className="w-100 g-mb-120 g-pb-120 g-height-500">
                  <PeerAnalysisChart data={peerAnalysisTableData} />
                </div>
              )}
              <div className="g-mt-50">
                <div className="row">
                  <div className="col-lg-6 g-lg-pr-21">
                    {peerAnalysisTableData && Object.keys(peerAnalysisTableData).length > 0 && (
                      <PeerAnalysisTable
                        selectedModel={selectedModel}
                        tableData={peerAnalysisTableData}
                      />
                    )}
                  </div>
                  <div className="col-lg-6">
                    <h5>Explanation</h5>
                    <p>
                      We compare <strong>{data.name}</strong> with its local and global peers in the
                      same industry. Depending on the model, the intrinsic value and margin of
                      safety is calculated by using the Earnings Per Share (EPS) or Free Cash Flow
                      Per Share (FCF) and the same growth rate will be applied to each company.
                    </p>
                    <p>
                      We added another important metric:{' '}
                      <strong>
                        <span>Piotroski F Score</span>
                      </strong>{' '}
                      to highlight fundamentally strong companies.
                    </p>
                    <p>
                      This helps you to spot strong and undervalued companies in the same industry
                      worldwide.
                    </p>
                    <h6>Notes:</h6>
                    <ol type="a" className="text-muted">
                      <li>
                        <div>
                          Market Cap is based on the trading currency of the company and peers are
                          sorted by market capitalisation.
                        </div>
                      </li>
                    </ol>
                    <IntrinsicAnalysisCalculator tab="peer_analysis" showModelWithin={true} />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="text-danger text-center">Data not found.</div>
          )}
        </>
      )}
      {!data ||
        (!data.success && <div className="g-mt-25 text-center text-danger">Data not found.</div>)}
    </>
  );
}

PeerAnalysisTab.propTypes = {
  defaultCounter: PropTypes.string,
};
