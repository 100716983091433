import React from 'react';
import PropTypes from 'prop-types';
import styles from './marginOfSafety.module.css';

export default function MarginOfSafety({ data, statement, type }) {
  return (
    <>
      {type === 'valuation' ? (
        <div className={`${styles[statement.color]} card`}>
          <div className="card-body">
            <div className="d-flex justify-content-between g-text-size-16 align-items-center">
              <div className="fw-bold g-text-size-24">
                <i className="fa-regular fa-sliders-simple"></i>
              </div>
              <div className="">{statement.label}</div>
            </div>
            <div className="d-flex justify-content-between g-text-size-16">
              <div className="fw-bold g-text-size-20">Margin of Safety</div>
              <div className="fw-bold g-text-size-20">{data.value_f}</div>
            </div>
          </div>
        </div>
      ) : (
        <td className={statement.label === '' ? 'text-gray-600' : styles[statement.color]}>
          <div>{data.value_f}</div>
          <div>{statement.label}</div>
        </td>
      )}
    </>
  );
}

MarginOfSafety.propTypes = {
  type: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object]),
  statement: PropTypes.oneOfType([PropTypes.object]),
};
