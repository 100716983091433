/* eslint-disable react/no-unknown-property */
/* eslint-disable react/prop-types */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown';
import { setFilters } from './stockPricesSlice';
import { updateUrlBasedOnFilters } from './url';

function CountersDropdownFilters(props) {
  if (!props) {
    return null;
  }

  const { filtersTypes, stockInfoTypes, bdContracts, currentFilters } = props;
  const dispatch = useDispatch();

  const onClickHandler = (e) => {
    const filtersCopy = { ...currentFilters }; // clone
    const newFilterSelected = e.target.getAttribute('filter');
    const newType = e.target.getAttribute('type');
    if (
      !newType ||
      !newFilterSelected ||
      (filtersCopy.filter === newFilterSelected && filtersCopy.type === newType)
    ) {
      return;
    }
    filtersCopy.filter = newFilterSelected;
    filtersCopy.type = newType;
    filtersCopy.page = '1';
    // update address bar in browser
    updateUrlBasedOnFilters(filtersCopy);
    // trigger update on currentFilters
    dispatch(setFilters({ filters: filtersCopy }));
  };

  const renderDropdownFilters = () => {
    const { tab, filter, type, layout, page, market } = currentFilters;
    const dropdownFilters = [];
    if (filtersTypes[tab]) {
      for (let i = 0; i < filtersTypes[tab].length; i++) {
        const marketGroup = filtersTypes[tab][i];
        let marketGroupKey;
        if (Object.prototype.hasOwnProperty.call(marketGroup, market)) {
          marketGroupKey = market;
        } else if (
          ['nasdaq', 'nyse', 'nyse_mkt'].includes(market) &&
          Object.prototype.hasOwnProperty.call(marketGroup, 'us')
        ) {
          marketGroupKey = 'us';
        } else if (
          ['idx', 'asx', 'set', 'nasdaq', 'nyse', 'nyse_mkt'].includes(market) &&
          Object.prototype.hasOwnProperty.call(marketGroup, 'eod')
        ) {
          marketGroupKey = 'eod';
        } else if (Object.prototype.hasOwnProperty.call(marketGroup, 'default')) {
          marketGroupKey = 'default';
        }

        if (!(marketGroupKey && marketGroup[marketGroupKey])) {
          continue;
        }

        const group = marketGroup[marketGroupKey];
        const selected = market === 'bursa_derivative' ? true : type === group.type;

        if (group.type === 'alphabetical') {
          dropdownFilters.push(
            <AlphabeticalDropdown
              selectedFilter={filter}
              group={group}
              selected={selected}
              stockInfoTypes={stockInfoTypes}
              onClickHandler={onClickHandler}
              key="filter_by_alphabetical"
            />,
          );
        } else if (market === 'bursa_derivative') {
          dropdownFilters.push(
            <BursaDerivativeContractsDropdown
              selectedFilter={filter}
              selectedType={type}
              group={group}
              selected={selected}
              bdContracts={bdContracts}
              stockInfoTypes={stockInfoTypes}
              onClickHandler={onClickHandler}
              key="filter_by_bd_contracts"
            />,
          );
        } else {
          dropdownFilters.push(
            <GenericFilterDropdown
              selectedFilter={filter}
              group={group}
              selected={selected}
              stockInfoTypes={stockInfoTypes}
              onClickHandler={onClickHandler}
              key={`filter_by_${group.type}`}
            />,
          );
        }
      }
      if (layout !== 'trading_data' && market !== 'bursa_derivative') {
        dropdownFilters.push(
          <div className="sic_filter sic_table_search" key="filter_by_search" />,
        );
      }
    } else if (layout !== 'trading_data') {
      dropdownFilters.push(<div className="sic_filter sic_table_search" key="filter_by_search" />);
    }
    return dropdownFilters;
  };

  return renderDropdownFilters();
}

export default CountersDropdownFilters;

function AlphabeticalDropdown(props) {
  const { selectedFilter, group, selected, stockInfoTypes, onClickHandler } = props;

  let streamingChannel;
  let pageLimit;
  let selectedOption = group.name;
  if (selected && selectedFilter) {
    if (stockInfoTypes[selectedFilter]) {
      streamingChannel = stockInfoTypes[selectedFilter].streaming_channel;
      pageLimit = stockInfoTypes[selectedFilter].force_streaming_limit;
    }
    selectedOption = selectedFilter.length > 4 ? selectedFilter.substring(4) : '0-9,A-Z';
  }

  const renderDropdownItems = () => {
    const filterOptions = [];
    if (group.show_sub) {
      for (let i = 65 /* A */; i <= 90 /* Z */; i++) {
        const letter = String.fromCharCode(i);
        filterOptions.push(
          <Dropdown.Item
            key={`${group.name.replace(/\s/g, '')}_${letter}`}
            className={`dropdown-item sic_clickable_filter justify-content-center" ${
              selectedOption === letter ? 'active' : ''
            }`}
            filter={`${group.filters}${letter}`}
            type={group.type}
            onClick={onClickHandler}>
            {letter}
          </Dropdown.Item>,
        );
      }
      filterOptions.push(
        <Dropdown.Item
          key={`${group.name.replace(/\s/g, '')}_0to9`}
          className={`dropdown-item sic_clickable_filter justify-content-center" ${
            selectedOption === '0-9' ? 'active' : ''
          }`}
          filter={`${group.filters}0-9`}
          type={group.type}
          onClick={onClickHandler}>
          0-9
        </Dropdown.Item>,
      );
    } else {
      filterOptions.push(
        <Dropdown.Item
          key={`${group.name.replace(/\s/g, '')}_AtoZ`}
          className={`dropdown-item sic_clickable_filter justify-content-center" ${
            selectedOption === 'A-Z' ? 'active' : ''
          }`}
          filter={group.filters}
          type={group.type}
          onClick={onClickHandler}>
          A-Z
        </Dropdown.Item>,
      );
    }
    return filterOptions;
  };

  return (
    <>
      <Dropdown
        className={`d-inline-block sic_filter sic_${group.type}${selected ? ' selected' : ''}`}>
        <div
          className="sic_selectedFilter"
          selected_type={selectedFilter}
          streaming_channel={streamingChannel || undefined}
          page_limit={pageLimit || undefined}>
          <Dropdown.Toggle variant="light" className={selected ? 'active' : ''}>
            <span className="g-mr-10">{encodeURIComponent(selectedOption)}</span>
          </Dropdown.Toggle>
        </div>
        <Dropdown.Menu className="sic_filterOptions">
          <div className="d-grid" style={{ gridTemplateColumns: 'auto auto auto auto auto' }}>
            {renderDropdownItems()}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

function BursaDerivativeContractsDropdown(props) {
  const {
    selectedFilter,
    selectedType,
    group,
    selected,
    bdContracts,
    stockInfoTypes,
    onClickHandler,
  } = props;

  const selectedOption = bdContracts.includes(selectedType) ? selectedType : 'All';
  let streamingChannel;
  let pageLimit;
  if (selected && stockInfoTypes[selectedFilter]) {
    streamingChannel = stockInfoTypes[selectedFilter].streaming_channel;
    pageLimit = stockInfoTypes[selectedFilter].force_streaming_limit;
  }

  const renderDropdownItems = () => {
    const filterOptions = [];
    for (let i = 0; i < bdContracts.length; i++) {
      filterOptions.push(
        <Dropdown.Item
          key={`${i}_${bdContracts[i]}`}
          className={`dropdown-item sic_clickable_filter ${
            selectedOption === bdContracts[i] ? 'active' : ''
          }`}
          filter={selectedFilter}
          type={bdContracts[i]}
          onClick={onClickHandler}>
          {bdContracts[i]}
        </Dropdown.Item>,
      );
    }
    return filterOptions;
  };

  const filterOptions = renderDropdownItems();
  return (
    <Dropdown
      className={`d-inline-block sic_filter sic_${group.type}${selected ? ' selected' : ''}`}>
      <div
        className="sic_selectedFilter"
        selected_type={selectedFilter}
        streaming_channel={streamingChannel || undefined}
        page_limit={pageLimit || undefined}>
        <Dropdown.Toggle variant="light" className={selected ? 'active' : ''}>
          <span className="g-mr-10">{selectedOption}</span>
        </Dropdown.Toggle>
      </div>
      <Dropdown.Menu className="sic_filterOptions">{filterOptions}</Dropdown.Menu>
    </Dropdown>
  );
}

function GenericFilterDropdown(props) {
  const { selectedFilter, group, selected, stockInfoTypes, onClickHandler } = props;
  const selectedFilterKey = selectedFilter ? selectedFilter.substring(0, 4) : undefined;
  let selectedOption = group.name;
  let streamingChannel;
  let pageLimit;
  if (selected && selectedFilterKey) {
    if (stockInfoTypes[selectedFilterKey]) {
      streamingChannel = stockInfoTypes[selectedFilterKey].streaming_channel;
      pageLimit = stockInfoTypes[selectedFilterKey].force_streaming_limit;
    }
    selectedOption = stockInfoTypes[selectedFilterKey].title;
  }

  const renderDropdownSubmenu = (submenuKey, submenuTypeKey, submenuTypeValues) => {
    return (
      <Dropdown key={submenuKey} drop="end" className="sic_filter_hasSub position-static">
        <Dropdown.Toggle
          as="div"
          role="button"
          className={`dropdown-item ${
            selected && submenuTypeValues.includes(selectedFilter) ? 'active' : ''
          }`}>
          <span className="g-mr-10">{submenuTypeKey}</span>
        </Dropdown.Toggle>
        <Dropdown.Menu
          className="submenu"
          popperConfig={{ modifiers: [{ name: 'offset', options: { offset: ['top', 15] } }] }}>
          {submenuTypeValues.map((subType) => {
            if (selected && selectedFilter === subType) {
              selectedOption = `${submenuTypeKey} - ${stockInfoTypes[subType].title}`;
            }
            return (
              <Dropdown.Item
                key={`${submenuKey}_${subType}`}
                className={`dropdown-item sic_clickable_filter ${
                  selectedFilter === subType ? 'active' : ''
                }`}
                filter={subType}
                type={group.type}
                onClick={onClickHandler}>
                {stockInfoTypes[subType].title}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const renderDropdownItems = (submenuTypes) => {
    const submenuOptions = [];
    for (let i = 0; i < submenuTypes.length; i++) {
      const type = submenuTypes[i];
      if (!Array.isArray(type) && type.constructor === Object) {
        // means a hash obj
        Object.entries(type).map(([typeKey, typeValues]) => {
          const submenuKey = `${group.name.replace(/\s/g, '')}_${typeKey.replace(/\s/g, '')}`;
          submenuOptions.push(renderDropdownSubmenu(submenuKey, typeKey, typeValues));
          return null; // dummy return required by =>
        });
      } else {
        submenuOptions.push(
          <Dropdown.Item
            key={`${group.name.replace(/\s/g, '')}_${type}`}
            className={`dropdown-item sic_clickable_filter ${group.name} ${
              selectedFilter === type ? 'active' : ''
            }`}
            filter={type}
            type={group.type}
            onClick={onClickHandler}>
            {stockInfoTypes[type].title}
          </Dropdown.Item>,
        );
      }
    }

    return submenuOptions;
  };

  const filterOptions = [];
  for (let i = 0; i < group.filters.length; i++) {
    const submenuTypes = group.filters[i];
    filterOptions.push(renderDropdownItems(submenuTypes));
  }

  return (
    <Dropdown
      drop="down"
      className={`d-inline-block sic_filter sic_${group.type}${selected ? ' selected' : ''}`}>
      <div
        className="sic_selectedFilter"
        selected_type={selectedFilter}
        streaming_channel={streamingChannel || undefined}
        page_limit={pageLimit || undefined}>
        <Dropdown.Toggle variant="light" className={selected ? 'active' : ''}>
          <span className="g-mr-10">{selectedOption}</span>
        </Dropdown.Toggle>
      </div>
      <Dropdown.Menu className="sic_filterOptions">{filterOptions}</Dropdown.Menu>
    </Dropdown>
  );
}
