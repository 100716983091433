import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Form, Dropdown } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import PropTypes from 'prop-types';
import { Provider, useDispatch, useSelector } from 'react-redux';

import sgFlag from 'images/icon-flag-singapore.svg';
import myFlag from 'images/icon-flag-malaysia.svg';
import hkFlag from 'images/icon-flag-hongkong.svg';
import thFlag from 'images/icon-flag-thailand.svg';
import idFlag from 'images/icon-flag-indonesia.svg';
import asFlag from 'images/icon-flag-australia.svg';
import usFlag from 'images/icon-flag-us.svg';
import { createLoadableComponent } from '../../../utils/Loadable';
import ShareholderSearchBar from './ShareholderSearchBar';
import { fetchData } from './ownershipSlice';
import styles from './significantShareholders.module.css';
import Loader from '../../../components/Loader';
import store from '../../../utils/store';
import { getMarketFromMarketSymbol, getParamCounterId, formatValue } from '../../../utils/utils';
import CompanyNameLink from '../../../components/StockInfoPopup/CompanyNameLink';
import CompanyLogoImage from '../../../components/CompanyLogoImage';
import { getCurrencyName } from '../../Portfolio/currencies';
import APIErrorHandler from '../../../components/APIErrorHandler';

const StockInfoPopup = createLoadableComponent(() => import('../../../components/StockInfoPopup'));

const { CancelToken } = axios;

const MarketOrder = ['SI', 'MY', 'BK', 'JK', 'HK', 'AX', 'NY', 'NM', 'NQ'];

function OwnershipByShareholder({ factsetOwnerId }) {
  const [ownerId, setOwnerId] = useState(factsetOwnerId);
  const [keyword, setKeyword] = useState('');

  const handleSearchChange = (e) => {
    e.preventDefault();
    const searchKey = e.target.value;
    setKeyword(searchKey);
  };

  const handleSubmit = (factsetId) => {
    setOwnerId(factsetId);
    // update URL without reload
    window.history.replaceState(
      '',
      '',
      `/significant_shareholders/ownership_by_shareholder?shareholder_id=${factsetId}`,
    );
  };

  return (
    <>
      <h3 className="g-mb-30">Ownership by Shareholder</h3>
      <div className={`row justify-content-center g-mb-50 ${styles.searchBarContainer}`}>
        <div className="col-lg-5">
          <ShareholderSearchBar
            placeholder="Search shareholder name to view their portfolio"
            searchInput={keyword}
            handleChange={handleSearchChange}
            handleSubmit={handleSubmit}
            autoFocus={false}
          />
        </div>
      </div>
      <Provider store={store}>
        <OwnershipByShareholderDetails factsetOwnerId={ownerId} />
      </Provider>
    </>
  );
}

export default OwnershipByShareholder;

OwnershipByShareholder.propTypes = {
  factsetOwnerId: PropTypes.string,
};

function OwnershipByShareholderDetails({ factsetOwnerId }) {
  const dispatch = useDispatch();
  const source = CancelToken.source();
  const data = useSelector((state) => state.ownership.data);
  const loading = useSelector((state) => state.ownership.loading);
  const error = useSelector((state) => state.ownership.error);
  const [key, setKey] = useState('');

  useEffect(() => {
    if (factsetOwnerId) {
      dispatch(fetchData({ factsetOwnerId, cancelToken: source.token }));
    }
  }, [factsetOwnerId]);

  useEffect(() => {
    if (data && data.stats_by_market) {
      const foundMarket = MarketOrder.find((market) =>
        Object.keys(data.stats_by_market).includes(market),
      );

      if (foundMarket) {
        setKey(foundMarket);
      } else {
        // If no matching market is found in data.stats_by_market, set the key to the first key in data.stats_by_market
        setKey(Object.keys(data.stats_by_market)[0]);
      }
    }
  }, [data]);

  if (error) {
    return <APIErrorHandler error={error} />;
  }

  return (
    <>
      <Loader visible={factsetOwnerId && loading} classes="g-height-800" />
      {data && data.owner && data.ownership && data.stats_by_market && (
        <div className="row text-center">
          <h3 className="col-lg-12">{data.owner.name}</h3>
          <small className={styles.grayText}>Updated at: {data.owner.updated_at}</small>
          <div className="col-lg-12 g-mt-20">
            {data.owner.name} is a Top 20 Shareholder of {data.owner.ownership_count}{' '}
            {data.owner.ownership_count === 1 ? 'company' : 'companies'} in the following{' '}
            {Object.keys(data.ownership.by_market).length > 1 ? 'markets' : 'market'}
          </div>
          <div className="col-lg-12 g-mt-20">
            <Tab.Container activeKey={key}>
              <Nav variant="pills" className="flex justify-content-center">
                {MarketOrder.filter((mkt) => data.stats_by_market[mkt]?.count >= 0).map(
                  (market) => (
                    <Nav.Item eventKey={market} onClick={() => setKey(market)}>
                      <CountryCardPill
                        market={market}
                        stats={data.stats_by_market[market]}
                        isActive={key === market}
                      />
                    </Nav.Item>
                  ),
                )}
              </Nav>
              <Tab.Content className="g-mt-20">
                {Object.keys(data.stats_by_market).map((market) => (
                  <Tab.Pane eventKey={market}>
                    {key === market && ( // we stop the tab from rendering in the back so it does not trigger 403 error from requesting all the logos at once
                      <div className="row">
                        <MarketCountersCards
                          market={market}
                          ownership={data.ownership.by_market[market]}
                          lookup={data.ownership.lookup}
                          stockInfoLookup={data.stock_info_lookup}
                        />
                      </div>
                    )}
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      )}
      <StockInfoPopup />
    </>
  );
}

OwnershipByShareholderDetails.propTypes = {
  factsetOwnerId: PropTypes.string,
};

function CountryCardPill({ market, stats, isActive }) {
  const itemLabel = getMarketFromMarketSymbol(market);

  const flagIMG = (flag) => {
    switch (flag.toLowerCase()) {
      case 'si':
        return sgFlag;
      case 'my':
        return myFlag;
      case 'hk':
        return hkFlag;
      case 'bk':
        return thFlag;
      case 'jk':
        return idFlag;
      case 'ax':
        return asFlag;
      case ('nm', 'nq', 'ny'):
        return usFlag;
      default:
        return usFlag;
    }
  };

  return (
    <Card className={`${isActive ? styles.cardActive : styles.cardInactive}`} type="button">
      <Card.Body className="text-center">
        <div className="mb-2">
          <img
            src={flagIMG(market)}
            alt="Flag"
            className={`g-width-100 g-text-size-20 ${isActive ? styles.flag : styles.flagInactive}`}
          />
        </div>
        <div className="fw-bold content mb-2">{itemLabel}</div>
        <div className="fw-bold content mb-2">{stats && stats.count}</div>
      </Card.Body>
    </Card>
  );
}

function MarketCountersCards({ market, ownership, lookup, stockInfoLookup }) {
  if (!(ownership?.length > 0)) {
    return (
      <div>
        No companies found for this shareholder under {getMarketFromMarketSymbol(market)} market.
      </div>
    );
  }

  return (
    <div className="row g-3">
      {ownership.map((company, index) => {
        const { counter } = company;
        const stockPrice =
          stockInfoLookup[counter]?.success && stockInfoLookup[counter]?.stock_price;

        if (stockPrice) {
          const lookupCounter = lookup[counter];
          const lastDonePrice = stockPrice.last_done_price || stockPrice.bs_last_done_price;
          const numberOfShares = lookupCounter.num_shares;
          const sharesWorth = lastDonePrice * numberOfShares;
          return (
            <div key={index} className="col-lg-4">
              <Card>
                <Card.Header>
                  <td className="text-start g-width-400">
                    <div className="d-flex align-items-center">
                      <div className="g-width-35 g-height-35 g-mr-10">
                        <CompanyLogoImage counter={counter} />
                      </div>
                      <div>
                        <CompanyNameLink
                          counter={getParamCounterId(counter)}
                          name={lookupCounter.name}
                          showStockInfoPopUp
                        />
                        <br />
                        <small className={styles.grayText}>
                          Currency:{' '}
                          {
                            // SIMarket ownership does not return currency for Bursa
                            stockPrice.currency ||
                              (stockPrice.cur_id && getCurrencyName(stockPrice.cur_id)) ||
                              'MYR'
                          }
                        </small>
                      </div>
                    </div>
                  </td>
                  <div className={styles.ribbonContainer}>
                    <span className={`${styles.ribbon} ${styles.whiteColor}`}>
                      <div className="g-text-size-11">Top 20 Rank</div>
                      {lookupCounter.rank}
                    </span>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Card.Title />
                  <Card.Text>
                    <div className="row g-mt-20">
                      <td className="col-6 text-start">
                        <span className="g-mr-20">
                          <i className="fa-regular fa-chart-mixed" />
                        </span>
                        <span className="g-text-size-14">No. of Shares</span>
                      </td>
                      <td className="text-end col-6">
                        <span className="g-text-size-14">{formatValue(numberOfShares, false, 0)}</span>
                      </td>
                    </div>
                    <div className="row g-mt-20">
                      <td className="col-6 text-start">
                        <span className="g-mr-20">
                          <i className="fa-regular fa-chart-pie" />
                        </span>
                        <span className="g-text-size-14">% Shareholdings</span>
                      </td>
                      <td className="text-end col-6">
                        <span className="g-text-size-14">{lookupCounter.percent_shareholding}</span>
                      </td>
                    </div>
                    <div className="row g-mt-20">
                      <td className="col-6 text-start">
                        <span className="g-mr-20">
                          <i className="fa-sharp fa-regular fa-square-poll-vertical" />
                        </span>
                        <span className="g-text-size-14">Last Done Price</span>
                      </td>
                      <td className="text-end col-6">
                        <span className="g-text-size-14">{lastDonePrice}</span>
                      </td>
                    </div>
                    <div className="row g-mt-20">
                      <td className="col-6 text-start">
                        <span className="g-mr-20">
                          <i className="fa-regular fa-chart-mixed-up-circle-dollar" />
                        </span>
                        <span className="g-text-size-14">Shares Worth</span>
                      </td>
                      <td className="text-end col-6">
                        <span className="g-text-size-14">{formatValue(sharesWorth)}</span>
                      </td>
                    </div>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          );
        }
        return null; // If conditions are not met, dont process
      })}
    </div>
  );
}
