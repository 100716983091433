import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Card from 'react-bootstrap/Card';

import { fetchPortolioData } from '../../../pages/Markets/ConsensusEstimates/consensusEstimatesSlice';
import Pagination from '../../../components/Pagination';
import APIErrorHandler from '../../../components/APIErrorHandler';
import TableColumnSearchFilter from '../../../components/TableColumnSearchFilter';
import { getSymbolFromCounter } from '../../../utils/utils';
import classes from './insights.module.css';

const { CancelToken } = axios;
const RECORDS_PER_PAGE = 6;

function getRatingClass(rating) {
  switch (rating?.toLowerCase()) {
    case 'buy':
      return classes.ratingsColorBuy;
    case 'overweight':
      return classes.ratingsColorOverweight;
    case 'hold':
      return classes.ratingsColorHold;
    case 'underweight':
      return classes.ratingsColorUnderweight;
    case 'sell':
      return classes.ratingsColorSell;
    default:
      return '';
  }
}

export default function ConsensusEstimates() {
  const dispatch = useDispatch();
  const source = CancelToken.source();
  const selectedPortfolio = useSelector((state) => state.portfolio.selected);
  const countryMarket = useSelector((state) => state.portfolio.countryMarket);
  const data = useSelector((state) => state.marketsConsensusEstimates.dataPortfolio);
  const loading = useSelector((state) => state.marketsConsensusEstimates.loadingPortfolio);
  const error = useSelector((state) => state.marketsConsensusEstimates.errPortfolio);
  const [currentCounters, setCurrentCounters] = useState([]);

  useEffect(() => {
    if (selectedPortfolio?.counters?.length > 0) {
      const counters = selectedPortfolio.counters.map((c) => c.counter).sort();
      if (JSON.stringify(counters) !== JSON.stringify(currentCounters)) {
        setCurrentCounters(counters);
      }
    } else {
      setCurrentCounters([]);
    }
  }, [selectedPortfolio]);

  useEffect(() => {
    if (currentCounters.length > 0) {
      dispatch(
        fetchPortolioData({
          market: countryMarket,
          folder: selectedPortfolio.id,
          cancelToken: source.token,
        }),
      );
    }
  }, [currentCounters]);

  return (
    <Card className="position-static h-100">
      <Card.Body className="g-min-height-555">
        <Card.Title>Consensus Estimates</Card.Title>
        {loading && (
          <div className="g-text-size-18 g-lg-text-size-30 text-center">
            <i className="fa-duotone fa-spinner-third fa-spin"></i>
          </div>
        )}
        <APIErrorHandler error={error} />
        {!error &&
          !loading &&
          (data?.estimates_table_data?.length > 0 ? (
            <ConsensusEstimatesTable data={data.estimates_table_data} />
          ) : (
            <div>No data found.</div>
          ))}
      </Card.Body>
    </Card>
  );
}

function ConsensusEstimatesTable({ data }) {
  const [tableData, setTableData] = useState([]);
  const [pagination, setPagination] = useState({});

  useEffect(() => {
    if (data?.length > 0) {
      const newPagination = {
        currentPage: 1,
        rowOffset: 0,
        currentPageRows: data.length > RECORDS_PER_PAGE ? RECORDS_PER_PAGE : data.length,
        totalRows: data.length,
        totalPages: Math.ceil(data.length / RECORDS_PER_PAGE),
      };
      setTableData(data.slice(0, newPagination.currentPageRows));
      setPagination(newPagination);
    }
  }, [data]);

  const handlePageChange = (newPage) => {
    if (!newPage || pagination.currentPage === newPage) {
      return;
    }

    if (data?.length > 0) {
      const newPagination = { ...pagination };
      newPagination.currentPage = newPage;
      newPagination.rowOffset = (newPage - 1) * RECORDS_PER_PAGE;
      const newOffsetTableData = data.slice(newPagination.rowOffset);
      newPagination.currentPageRows =
        newOffsetTableData.length > RECORDS_PER_PAGE ? RECORDS_PER_PAGE : newOffsetTableData.length;
      setTableData(newOffsetTableData.slice(0, newPagination.currentPageRows));
      setPagination(newPagination);
    }
  };

  const handleStockNameFilterChange = (filterValue) => {
    if (data?.length < 1) {
      return;
    }

    if (!filterValue) {
      setTableData(
        data.slice(
          pagination.rowOffset, // start
          pagination.rowOffset + pagination.currentPageRows, // end
        ),
      );
    } else {
      setTableData(
        data.filter(
          (s) =>
            s.stock_name?.toLowerCase().includes(filterValue.toLowerCase()) ||
            getSymbolFromCounter(s.stock_code)?.toLowerCase().includes(filterValue.toLowerCase()),
        ),
      );
    }
  };

  return (
    <>
      <div className="table-responsive g-min-height-444">
        <table className="table table-hover mb-0">
          <thead className="table-light">
            <tr>
              <th className={`${classes.alignMiddle} g-width-180`}>
                <TableColumnSearchFilter
                  headerLabel={'Name'}
                  handleFilterChange={handleStockNameFilterChange}
                />
              </th>
              <th className={`${classes.alignMiddle} ${classes.alignCenter}`}>
                Consensus Recommendation
              </th>
              <th className={`${classes.alignMiddle} ${classes.alignCenter}`}>Mean Target Price</th>
              <th className={`${classes.alignMiddle} ${classes.alignCenter}`}>Consensus Rating</th>
            </tr>
          </thead>
          <tbody>
            {tableData.length < 1 && (
              <tr>
                <td colSpan={4} className={`${classes.alignMiddle} ${classes.alignCenter}`}>
                  No data found.
                </td>
              </tr>
            )}
            {tableData.length > 0 &&
              tableData.map((stock, index) => {
                return (
                  <tr key={`${index}_${stock.stock_code}`}>
                    {/* Stock Name */}
                    <td>
                      <a href={`/quote/${stock.stock_code}/consensus_estimates`}>
                        {stock.stock_name}
                      </a>
                    </td>
                    {/* Consensus Recommendation */}
                    <td className={classes.alignCenter}>
                      {stock.consensus_recommendation && (
                        <span className={`${getRatingClass(stock.consensus_recommendation)} badge`}>
                          {stock.consensus_recommendation.toUpperCase()}
                        </span>
                      )}
                    </td>
                    {/* Mean Target Price */}
                    <td className={classes.alignCenter}>{stock.average_target_price}</td>
                    {/* Consensus Rating */}
                    <td className={classes.alignCenter}>{stock.consensus_rating}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <Pagination
        rowOffset={pagination.rowOffset}
        currentPageRows={pagination.currentPageRows}
        totalRows={pagination.totalRows}
        currentPage={pagination.currentPage}
        totalPages={pagination.totalPages}
        handlePageChange={handlePageChange}
      />
    </>
  );
}
