import React from 'react';
import { useSelector } from 'react-redux';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import PropTypes from 'prop-types';
import { useDetectMobileScreen } from '../../utils/utils';

function DefinitionPopup({ keyword, delayShow, delayHide, children }) {
  const definition = useSelector((state) => state.definition.data);
  const isMobile = useDetectMobileScreen();
  if (!keyword || definition.length === 0 || !definition[keyword.toLowerCase()]) {
    return children;
  }

  return (
    <OverlayTrigger
      overlay={(props) => (
        <Tooltip
          className="tooltip_custom text-muted g-max-width-300 g-sm-max-width-500 g-md-max-width-700 g-max-per-width-80"
          {...props}>
          {console.log('definition', definition)}
          <div className="text-start overflow-x-hidden">
            <div dangerouslySetInnerHTML={{ __html: definition[keyword.toLowerCase()] }} />
          </div>
        </Tooltip>
      )}
      placement="auto-end"
      {...(!isMobile && { delay: { show: delayShow, hide: delayHide } })}>
      <span className="cursor-help">
        {children}
        <i className="g-ml-5 fa-solid fa-circle-info" />
      </span>
    </OverlayTrigger>
  );
}

DefinitionPopup.propTypes = {
  keyword: PropTypes.string,
  delayShow: PropTypes.number,
  delayHide: PropTypes.number,
  children: PropTypes.node.isRequired,
};

DefinitionPopup.defaultProps = {
  delayShow: 250,
  delayHide: 400,
};

export default DefinitionPopup;
