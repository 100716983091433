import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import BrokersCallMain from './BrokersCallMain';
import { BrokersCallMainProps } from './brokersCallInterfaces';
import store from '../../../utils/store';
import { Col, Container, Row } from 'react-bootstrap';

const BrokersCall: React.FC<BrokersCallMainProps> = ({
  market = '',
  industries = [],
  calls = {},
  dateStart = '',
  dateEnd = '',
  dataUrl = '',
  counter = {},
}) => (
  <Provider store={store}>
    <BrowserRouter>
      <Container fluid={true}>
        <Row>
          <Col>
            <div className="g-lg-mb-30 g-mb-20">
              <div className="col-lg-8">
                <h3 className="g-mb-5 g-lg-mb-0">Brokers&apos; Call</h3>
              </div>
              <div className="col-lg-4 d-flex justify-content-lg-end justify-content-start" />
            </div>
            <div className="divider-h w-100 g-mb-20 g-lg-mb-30" />
            <Routes>
              <Route
                path="/:id/brokers_call"
                element={
                  <BrokersCallMain
                    market={market}
                    industries={industries}
                    calls={calls}
                    dateStart={dateStart}
                    dateEnd={dateEnd}
                    dataUrl={dataUrl}
                    counter={counter}
                  />
                }
              />
            </Routes>
          </Col>
        </Row>
      </Container>
    </BrowserRouter>
  </Provider>
);

export default BrokersCall;
