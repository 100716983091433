import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export default function ModalDialog({
  show,
  title,
  body,
  handleClose,
  handleSave,
  saveParams,
  saveLabel,
  saveVariant,
}) {
  const innerRef = useRef();

  useEffect(() => {
    if (innerRef.current) {
      innerRef.current.focus();
    }
  }, [show]);

  return (
    <Modal show={show} centered dialogClassName="g-text-size-14`" onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant={saveVariant}
          onClick={(e) => handleSave(saveParams, e)}
          ref={innerRef}
          autoFocus>
          {saveLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ModalDialog.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  body: PropTypes.string,
  handleClose: PropTypes.func,
  handleSave: PropTypes.func,
  saveLabel: PropTypes.string,
  saveVariant: PropTypes.string,
  saveParams: PropTypes.object,
};
