import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';

import CompanyNameLink from '../StockInfoPopup/CompanyNameLink';
import { createLoadableComponent } from '../../utils/Loadable';
import PopUpNote from '../PopUpNote';
import FixedHeaderTable from '../FixedHeaderTable';

const StockInfoPopup = createLoadableComponent(() => import('../StockInfoPopup'));
function CompanyLatestBuybackTable({ data, linkToFactsheet, market, toggleFactsheetNav }) {
  const tableContainerID = 'companyBuybackTableContainer';
  const tableProps = {
    className: 'text-center align-middle table-with-hover-popup',
  };

  useEffect(() => {
    const table = document.getElementById(tableContainerID);
    if (toggleFactsheetNav) {
      toggleFactsheetNav(null, table);
    }
    return () => {
      if (toggleFactsheetNav) {
        toggleFactsheetNav(null, table);
      }
    };
  }, []);

  return (
    <>
      <FixedHeaderTable
        header={<TableHeader data={data} linkToFactsheet={linkToFactsheet} market={market} />}
        body={<TableBody data={data} linkToFactsheet={linkToFactsheet} market={market} />}
        handleScrollY={toggleFactsheetNav}
        tableContainerID={tableContainerID}
      />
      <StockInfoPopup />
    </>
  );
}

CompanyLatestBuybackTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      counter: PropTypes.string,
      stock_name: PropTypes.string,
      announcement_date: PropTypes.string,
      date_of_effective_change: PropTypes.string,
      volume_transacted: PropTypes.string,
      price: PropTypes.string,
      value_transacted: PropTypes.string,
      remarks: PropTypes.string,
    }),
  ),
  linkToFactsheet: PropTypes.bool,
  market: PropTypes.string,
  toggleFactsheetNav: PropTypes.func,
};

export default CompanyLatestBuybackTable;

function TableHeader({ data, linkToFactsheet, market }) {
  return (
    <thead className="table-light">
      <tr>
        <th className="fixedColumn">Date of Announcement</th>
        <th>Date of Effective Change</th>
        <th>Stock Name</th>
        <th>Acquired ['000]</th>
        <th>
          Price{' '}
          <span
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(data.currency),
            }}
          />
        </th>
        <th>
          Estimated Transaction Value ['000]<sup>a</sup>
        </th>
        {linkToFactsheet && <th>Details</th>}
        {market === 'sgx' && <th>Note</th>}
      </tr>
    </thead>
  );
}

TableHeader.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      counter: PropTypes.string,
      stock_name: PropTypes.string,
      announcement_date: PropTypes.string,
      date_of_effective_change: PropTypes.string,
      volume_transacted: PropTypes.string,
      price: PropTypes.string,
      value_transacted: PropTypes.string,
      remarks: PropTypes.string,
    }),
  ),
  linkToFactsheet: PropTypes.bool,
  market: PropTypes.string,
};

function TableBody({ data, linkToFactsheet, market }) {
  return (
    <tbody>
      {data.map((stock) => {
        return (
          <tr key={stock.id}>
            <td className="fixedColumn">{stock.date_of_announcement}</td>
            <td>{stock.date_of_effective_change}</td>
            <td className="text-start">
              <CompanyNameLink counter={stock.counter} name={stock.name} showStockInfoPopUp />
            </td>
            <td>{stock.volume_transacted}</td>
            <td>{stock.price}</td>
            <td>{stock.value_transacted}</td>
            {linkToFactsheet && (
              <td>
                <a href={`/quote/${stock.counter}/company_buyback`} className="text-dark">
                  <i className="fa-thin fa-xl fa-magnifying-glass-plus" />
                </a>
              </td>
            )}
            {market === 'sgx' && (
              <td>
                {stock.remarks !== '' ? (
                  <div className="g-min-width-20">
                    <PopUpNote
                      content={stock.remarks}
                      icon="fa-thin fa-note fa-xl"
                      classes="text-start text-muted g-width-300 g-sm-width-500 g-md-width-700"
                    />
                  </div>
                ) : (
                  '-'
                )}
              </td>
            )}
          </tr>
        );
      })}
    </tbody>
  );
}

TableBody.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      counter: PropTypes.string,
      stock_name: PropTypes.string,
      announcement_date: PropTypes.string,
      date_of_effective_change: PropTypes.string,
      volume_transacted: PropTypes.string,
      price: PropTypes.string,
      value_transacted: PropTypes.string,
      remarks: PropTypes.string,
    }),
  ),
  linkToFactsheet: PropTypes.bool,
  market: PropTypes.string,
};
