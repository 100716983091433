import React from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import { useDetectMobileScreen } from '../../../utils/utils';
import './marketScreener.scss';
import DesktopSelectedCriteria from './DesktopSelectedCriteria';
import MobileSelectedCriteria from './MobileSelectedCriteria';

function SelectedCriterias({ market, criteriaConfig, criteriaGroups, stockCategories }) {
  const addedCriterias = useSelector((state) => state.marketScreener.addedCriterias);
  const isMobile = useDetectMobileScreen();

  return (
    <>
      {addedCriterias.map((criteria, index) =>
        Object.entries(criteria).map(([key, value]) => {
          return (
            <div
              id="form_selected_criteria"
              className={`${value.active ? '' : 'disabled'} d-block d-lg-flex flex-wrap flex-row`}
              key={index}
              data-index={index}>
              {!isMobile ? (
                <>
                  <DesktopSelectedCriteria
                    key={index}
                    fieldKey={key}
                    attribute={value}
                    fieldIndex={index}
                    market={market}
                    stockCategories={stockCategories}
                    criteriaGroups={criteriaGroups}
                    criteriaConfig={criteriaConfig}
                  />
                  {/* additionally render sub criterias dropdown */}
                  {value.sub_criterias.length > 0 &&
                    value.sub_criterias.map((subCriterias, sub_index) =>
                      Object.entries(subCriterias).map(([sub_key, sub_value]) => (
                        <div key={sub_key} className="sub_dropdown" data-index={sub_index}>
                          <DesktopSelectedCriteria
                            key={sub_key}
                            fieldKey={sub_key}
                            fieldIndex={sub_index}
                            parentKey={key}
                            parentIndex={index}
                            attribute={sub_value}
                            parentAttribute={value}
                            market={market}
                            stockCategories={stockCategories}
                            criteriaGroups={criteriaGroups}
                            criteriaConfig={criteriaConfig}
                          />
                        </div>
                      )),
                    )}
                </>
              ) : (
                <MobileSelectedCriteria
                  fieldKey={key}
                  attribute={value}
                  fieldIndex={index}
                  market={market}
                  stockCategories={stockCategories}
                  criteriaGroups={criteriaGroups}
                  criteriaConfig={criteriaConfig}
                />
              )}
            </div>
          );
        }),
      )}
    </>
  );
}

SelectedCriterias.propTypes = {
  market: PropTypes.string,
  criteriaConfig: PropTypes.oneOfType([PropTypes.object]),
  criteriaGroups: PropTypes.oneOfType([PropTypes.object]),
  stockCategories: PropTypes.oneOfType([PropTypes.object]),
};

export default SelectedCriterias;
