import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';

const TRUNCATE_LENGTH = 100;
function NewsOutline({ basePath, news, viewMode, handleClickNews }) {
  return (
    <>
      <div className="d-flex align-items-center gap-2 text-muted g-text-size-14">
        <span className="text-muted g-text-size-14">
          <i className="fa-solid fa-clock fa-sm text-primary g-mr-5" />
          {news.time}
        </span>
        <span> | </span>
        <span>{news.source}</span>
      </div>
      {news?.stock_name && (
        <div>
          <span className="badge text-bg-info">{news.stock_name}</span>
        </div>
      )}
      <div className="lh-sm fw-bold mt-3">
        {basePath === '/news' ? (
          <a
            href={news.url}
            className="text-decoration-none"
            target={news.force_new_window.toString()}
            rel="noreferrer">
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(news.title),
              }}
            />
          </a>
        ) : (
          <Link
            to={news.url}
            className="text-decoration-none"
            onClick={handleClickNews && handleClickNews}
            target={news.force_new_window.toString()}
            rel="noreferrer">
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(news.title),
              }}
            />
          </Link>
        )}
      </div>
      {(viewMode === 'grid_main' || viewMode === 'list_main') && (
        <div className="lh-sm g-mt-10 g-text-size-15 g-mb-10">
          {news.synopsis && news.synopsis.length > TRUNCATE_LENGTH
            ? `${news.synopsis.slice(0, TRUNCATE_LENGTH)}...`
            : news.synopsis}
        </div>
      )}
    </>
  );
}

NewsOutline.propTypes = {
  basePath: PropTypes.string,
  news: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  viewMode: PropTypes.string,
  handleClickNews: PropTypes.func,
};

export default NewsOutline;
