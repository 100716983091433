import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import api from '../../../../utils/api';
import PortfolioPerformanceChart from './PortfolioPerformanceChart';
import CashAllocationManagement from './CashAllocationManagement';

const { CancelToken } = axios;

function Charts() {
  const source = CancelToken.source();
  const selectedPortfolio = useSelector((state) => state.portfolio.selected);
  const [portfolioData, setPortfolioData] = useState();
  const [indexData, setIndexData] = useState();
  const [comparisonList, setComparisonList] = useState();
  const [selectedComparisonIndex, setSelectedComparisonIndex] = useState();
  const [chartMarkers, setChartMarkers] = useState();
  const [loading, setLoading] = useState();
  const [error, setError] = useState();

  const getPerformanceData = (selectedComparison) => {
    const cancelToken = source.token;
    setLoading(true);

    let url = `portfolio/${selectedPortfolio.id}/performance_chart_data`;
    if (selectedComparison) {
      url = `${url}?comparison_index_counter=${selectedComparison}`;
    }

    api
      .get(url, { cancelToken })
      .then(({ data }) => {
        setPortfolioData({
          performance: data.portfolio_performance,
          settings: data.settings,
        });

        setSelectedComparisonIndex({ value: data.index_counter });
        setComparisonList(data.performance_comparison_indices_list);
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  };

  const getIndexData = (selectedComparison) => {
    if (!portfolioData || !portfolioData.performance) {
      return;
    }

    const cancelToken = source.token;
    const dates = Object.keys(portfolioData.performance);
    const fromDate = dayjs(new Date(dates[0])).format('YYYYMMDD');
    const toDate = dayjs(new Date(dates[dates.length - 1])).format('YYYYMMDD');
    const url = `charts/c2_charts_data?id=${selectedComparison}&to_retrieve=historical_price&fromdate=${fromDate}&todate=${toDate}`;

    api
      .get(url, { cancelToken })
      .then(({ data }) => {
        const indexPerformance = {};
        data.historical.forEach((x) => {
          indexPerformance[x.dt] = x.cp;
        });

        setIndexData({
          performance: indexPerformance,
          counter: selectedComparison,
          name: data.stock_name,
        });
      })
      .catch((err) => {
        setError(err);
      });
  };

  useEffect(() => {
    if (!selectedComparisonIndex) {
      return;
    }
    getIndexData(selectedComparisonIndex.value);

    if (!chartMarkers) {
      const cancelToken = source.token;
      api
        .get(`charts/news_markers.json?folder_id=${selectedPortfolio.id}`, { cancelToken })
        .then(({ data }) => {
          setChartMarkers(data.body);
        })
        .catch((err) => {
          setError(err);
        });
    }
  }, [selectedComparisonIndex]);

  useEffect(() => {
    getPerformanceData();
  }, []);

  return (
    <>
      <PortfolioPerformanceChart
        portfolioData={portfolioData}
        indexData={indexData}
        comparisonList={comparisonList}
        setSelectedComparisonIndex={setSelectedComparisonIndex}
        loading={loading}
        chartMarkers={chartMarkers}
      />
      <CashAllocationManagement portfolioData={portfolioData} loading={loading} />
    </>
  );
}

export default Charts;
