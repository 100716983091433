import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { createLoadableComponent } from '../../../utils/Loadable/index.js';
import Loader from '../../../components/Loader/index.js';
import store from '../../../utils/store.js';
import { getParamCounterId } from '../../../utils/utils.js';
import SelectCounter from '../../../components/SelectCounter/index.js';
import { BrokersCallMainProps, Call, Option } from './brokersCallInterfaces.tsx';
import { Constants, BROKERS_CALL_BASE_URL } from '../../../utils/constants.js';
import {
  setFilterCounter,
  setFilterIndustry,
  setFilterCall,
  setFromDate,
  setToDate,
  changePage,
  selectDisplayData,
  selectOriginalData,
  selectFilterIndustry,
  selectFilterCall,
  selectStatus,
  selectPagination,
  fetchBrokersCall,
} from './brokersCallSlice.tsx';
import Pagination from '../../../components/Pagination/paging.tsx';
import styles from './brokersCall.module.css';
import CompanyNameLink from '../../../components/StockInfoPopup/CompanyNameLink.js';


const DateFilter = createLoadableComponent(() => import('../../../components/DateFilter/index.js'));

const { CancelToken } = axios;

const BrokersCallMain: React.FC<BrokersCallMainProps> = ({
  market,
  industries,
  calls,
  dateStart,
  dateEnd,
  dataUrl,
  counter
}) => {
  type AppDispatch = typeof store.dispatch;
  const useTypedDispatch = () => useDispatch<AppDispatch>();
  const dispatch = useTypedDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [fromDateLocalState, setFromDateLocalState] = useState(
    searchParams.get('search_start_date') || null,
  );
  const [toDateLocalState, setToDateLocalState] = useState(
    searchParams.get('search_end_date') || '',
  );
  const [filterCounter, setFilterCounterLocalState] = useState(counter);
  const filterCall = useSelector(selectFilterCall);
  const filterIndustry = useSelector(selectFilterIndustry);
  const originalData = useSelector(selectOriginalData);
  const pagination = useSelector(selectPagination);

  const callOptions = Object.entries(calls).map(
    ([value, label]) =>
    ({
      value,
      label: label.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase()),
    } as Option),
  );

  const industryOptions = industries.map((value) => ({ value: value, label: value } as Option));

  const handleCounterChange = (counterData) => {
    const newCounter = counterData ? { name: counterData.label, code: counterData.value } : {};
    if (filterCounter?.code === newCounter?.code) {
      return;
    }
    const counterValue = getParamCounterId(counterData?.value, true) || ''
    if (newCounter?.code) {
      setSearchParams((searchParams) => {
        searchParams.set('counter', newCounter.code);
        return searchParams;
      });
    } else if (searchParams.has('counter')) {
      searchParams.delete('counter');
      setSearchParams(searchParams);
    }

    setFilterCounterLocalState(newCounter);
    dispatch(setFilterCounter(counterValue));
  }

  useEffect(() => {
    const source = CancelToken.source();

    /* Default date range. */
    const counterParam = searchParams.get('counter');
    const industry = searchParams.get('industry');
    const call = searchParams.get('call');
    const fromDate = searchParams.get('fromDate');
    const toDate = searchParams.get('toDate');

    if (counterParam) {
      const counterValue = getParamCounterId(counterParam, true) || ''
      dispatch(setFilterCounter(counterValue));
    }

    if (industry) {
      dispatch(setFilterIndustry(industry));
    }

    if (call) {
      dispatch(setFilterCall(call));
    }

    if (fromDate) {
      dispatch(setFromDate(fromDate));
      setFromDateLocalState(fromDate);
    } else {
      dispatch(setFromDate(dateStart));
      setFromDateLocalState(dateStart);
    }

    if (toDate) {
      dispatch(setToDate(toDate));
      setToDateLocalState(toDate);
    } else {
      dispatch(setToDate(dateEnd));
      setToDateLocalState(dateEnd);
    }

    dispatch(fetchBrokersCall({ dataUrl: dataUrl, cancelToken: source.token }));
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [pagination.currentPage]);

  useEffect(() => {
    const pageNoParam = Number(searchParams.get('currentPage'));
    if (pageNoParam) {
      dispatch(changePage(pageNoParam));
    }
  }, [originalData]);

  const handleDateReset = () => {
    setFromDateLocalState('');
    setToDateLocalState('');
    dispatch(setFromDate(''));
    dispatch(setToDate(''));
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: 'auto',
      fontSize: '15px',
      padding: '6px 12px 6px 12px',
      margin: '0 8px 0 1px',
    }),
    menu: (provided) => ({
      ...provided,
      width: 'auto',
    }),
    control: (provided) => ({
      ...provided,
      width: '100%',
    }),
    menuList: (provided) => ({
      ...provided,
      '::-webkit-scrollbar': {
        width: '6px',
      },
      '::-webkit-scrollbar-track': {
        background: '#ebebeb',
        borderRadius: '10px',
      },
      '::-webkit-scrollbar-thumb': {
        background: '#a8a8a8',
        borderRadius: '10px',
      },
    }),
  };

  return (
    <Container className="p-0">
      <Row>
        <div className={styles.menuBar}>
          <div className="pl-0 p-2">
            <SelectCounter
              counter={filterCounter}
              market={[market]}
              handleOnChangeCounter={handleCounterChange}
            />
          </div>
          <div className="p-2">
            <Select
              isClearable={true}
              unstyled
              styles={customStyles}
              backspaceRemovesValue
              className={`g-width-200 react-select-container ${styles.filterElement}`}
              classNamePrefix="react-select"
              onChange={(newValue) => {
                if (newValue !== null) {
                  setSearchParams((searchParams) => {
                    searchParams.set('industry', newValue.value);
                    return searchParams;
                  });
                  dispatch(setFilterIndustry(newValue.value));
                } else {
                  setSearchParams((searchParams) => {
                    searchParams.delete('industry');
                    return searchParams;
                  });
                  dispatch(setFilterIndustry(''));
                }
              }}
              placeholder="Industry"
              options={industryOptions}
              value={filterIndustry ? { value: filterIndustry, label: filterIndustry } : null}
            />
          </div>
          <div className="p-2">
            <Select
              unstyled
              styles={customStyles}
              isClearable={true}
              backspaceRemovesValue
              className={`g-width-200 ${styles.filterElement}`}
              classNamePrefix="react-select"
              onChange={(newValue) => {
                if (newValue !== null) {
                  setSearchParams((searchParams) => {
                    searchParams.set('call', newValue.value);
                    return searchParams;
                  });
                  dispatch(setFilterCall(newValue.value));
                } else {
                  setSearchParams((searchParams) => {
                    searchParams.delete('call');
                    return searchParams;
                  });
                  dispatch(setFilterCall(''));
                }
              }}
              options={callOptions}
              defaultValue={null}
              placeholder="Price Call"
              value={filterCall ? { value: filterCall, label: calls[filterCall] } : null}
            />
          </div>
          <div className="p-2">
            <DateFilter
              isInterval
              fromDate={fromDateLocalState}
              toDate={toDateLocalState}
              handleReset={() => {
                handleDateReset();
              }}
              handleFromDateChange={(newValue) => {
                if (!newValue) {
                  return;
                }
                let newDateValue = '';
                if (newValue.length > 0) {
                  newDateValue = dayjs(new Date(newValue)).format('YYYY-MM-DD');
                }
                if (fromDateLocalState === newDateValue) {
                  return;
                }
                setFromDateLocalState(newDateValue);
                const date = new Date(newValue);
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const year = date.getFullYear();
                if (newValue !== null) {
                  setSearchParams((searchParams) => {
                    searchParams.set('fromDate', `${year}-${month}-${day}`);
                    return searchParams;
                  });
                  dispatch(setFromDate(`${year}-${month}-${day}`));
                }
              }}
              handleToDateChange={(newValue) => {
                if (!newValue) {
                  return;
                }
                let newDateValue = '';
                if (newValue.length > 0) {
                  newDateValue = dayjs(new Date(newValue)).format('YYYY-MM-DD');
                }
                if (toDateLocalState === newDateValue) {
                  return;
                }
                setToDateLocalState(newDateValue);
                const date = new Date(newValue);
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const year = date.getFullYear();
                if (newValue !== null) {
                  setSearchParams((searchParams) => {
                    searchParams.set('toDate', `${year}-${month}-${day}`);
                    return searchParams;
                  });
                  dispatch(setToDate(`${year}-${month}-${day}`));
                }
              }}
            />
          </div>
        </div>
      </Row>
      <BrokersCallsTable calls={calls} market={market} />
      <Row>
        <Col>
          <Pagination selectPagination={selectPagination} changePageAction={changePage} />
        </Col>
      </Row>
    </Container>
  );
};

const BrokersCallsTable: React.FC<{ calls: Call, market: string }> = ({ calls, market }) => {
  const displayData = useSelector(selectDisplayData);
  const loading = useSelector(selectStatus);

  const getDateFormat = (inputDate: string) => {
    const itemTimestamp = parseInt(inputDate, 10);
    const date = new Date(itemTimestamp * 1000);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    return `${day} ${monthNames[monthIndex]} ${year}`;
  };
  const getCallDisplay = (call: string) => {
    const green = ['0', '3', '8', '9', '10'];
    const red = ['1', '4', '7', '11', '12'];

    if (green.includes(String(call))) {
      return (
        <div className={`d-inline-block text-white bg-success ${styles.callContainer}`}>
          {calls[call]}
        </div>
      );
    } else if (red.includes(String(call))) {
      return (
        <div className={`d-inline-block text-white bg-danger ${styles.callContainer}`}>
          {calls[call]}
        </div>
      );
    } else {
      return (
        <div className={`d-inline-block text-white bg-warning ${styles.callContainer}`}>
          {calls[call]}
        </div>
      );
    }
  };
  const StockInfoPopup = createLoadableComponent(() =>
    import('../../../components/StockInfoPopup'),
  );


  return (
    <Table
      borderless={false}
      hover={true}
      responsive={true}
      className="table-with-hover-popup align-middle">
      <thead className="table-light align-middle">
        <tr>
          <th>Stock Name</th>
          <th>Research House</th>
          <th className="text-center">Current Price (RM)</th>
          <th className="text-center">Target Price (RM)</th>
          <th className="text-center">Buy/Sell Call</th>
          <th className="text-center">Date</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <StockInfoPopup />
        {loading == Constants.STATUS_LOADING && (
          <tr>
            <td colSpan={7}>
              <Loader visible={loading == Constants.STATUS_LOADING} />
            </td>
          </tr>
        )}
        {displayData.map((call, index) => (
          <tr key={index}>
            <td>
              <div>
                <CompanyNameLink counter={`${market.toUpperCase()}:${call.code}`} name={call.company_name} showStockInfoPopUp />
              </div>
              <div>{call.code}</div>
            </td>
            <td>
              <a href={call.broker_trade} target="_blank">
                {call.broker_name}
              </a>
            </td>
            <td className="text-center">{call.stock_price}</td>
            <td className="text-center">{call.call_price}</td>
            <td className="text-center">{getCallDisplay(call.call_id)}</td>
            <td className="text-center">{getDateFormat(call.call_date)}</td>
            <td className="text-center">
              <a href={BROKERS_CALL_BASE_URL + call.file} target="_blank">
                <i className="fa-regular fa-book-open fa-fw" style={{ marginRight: '3px' }}></i>
                View Report
              </a>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default BrokersCallMain;
