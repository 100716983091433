import React from 'react';
import PropTypes from 'prop-types';

export default function FAQ({ country }) {
  const adminNumber = country === 'sg' ? '+65 6958 8000' : '+60 3 8681 0580';
  const adminEmail = country === 'sg' ? 'admin@shareinvestor.com' : 'accounts.my@shareinvestor.com';

  return (
    <>
      <div id="faq" />
      <div className="g-mt-80">
        <div className="row g-mb-40">
          <div className="col-12 text-center">
            <h2>FAQs</h2>
          </div>
        </div>
        <div className="accordion" id="accordion">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne">
                I have a suggestion or I found a problem/bug. What should I do?
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionFAQ">
              <div className="accordion-body">
                <p>
                  Please report the problem to us so that we can look into it. You can do so easily
                  via any of the following methods.
                </p>
                <ul>
                  <li>
                    Send an email with your feedback to{' '}
                    <a href={`mailto:${adminEmail}`}>{adminEmail}</a>.
                  </li>
                  <li>
                    {' '}
                    Give us a call during office hours at <strong>{adminNumber}</strong> (Mon - Fri,
                    8.30am - 12.30pm, 2.00pm - 5.30pm).
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo">
                What is the difference between ShareInvestor and other service providers?
              </button>
            </h2>
            <div
              id="collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionFAQ">
              <div className="accordion-body">
                <p>ShareInvestor differentiate ourselves in</p>
                <ul>
                  <li>
                    <strong>Technology</strong>: We understand how important it is to receive timely
                    and accurate information. We develop our own technology, like the streaming
                    prices on ShareInvestor.com to enable you to make well-informed decisions on the
                    stock market.
                  </li>
                  <li>
                    <strong>Data</strong>: We collect all our fundamental data through our own data
                    collection team to maintain our service level.
                  </li>
                  <li>
                    <strong>Presentation</strong>: We slice and dice all the information and present
                    all these complex data in simple, easy-to-understand formats. We aggregate
                    information of interest to you in your portfolio, ensuring you do not miss out
                    on any important development again.
                  </li>
                  <li>
                    <strong>Value Added Features</strong>: We provide unique value-added features
                    such as our Volume Distribution charts, Fundamental Data and Quote Movements.
                  </li>
                  <li>
                    <strong>Service</strong>: We know how important it is to reach someone when you
                    need help. We have a dedicated technical and membership support hotline.
                  </li>
                </ul>
                <p>
                  We compete base on value of information and we are committed to continual
                  enhancement to improve our services.
                </p>
                <p>
                  Some brokers offer limited information like free stock prices. However the brokers
                  model to provide free data is to encourage trading and they earn from their
                  commission. It may not be viable for the brokers to continually enhance their free
                  financial information because that is not their focus or strength. Some of the
                  brokers have choosen ShareInvestor as their technology partner and outsource their
                  financial information provision to us.
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree">
                Can I use Internet Explorer (IE) to access the website?
              </button>
            </h2>
            <div
              id="collapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionFAQ">
              <div className="accordion-body">
                <p>
                  Internet Explorer is not supported anymore. You are encouraged to use a modern
                  browser (e.g. Chrome, Edge, Safari or Firefox) for the best experience on our
                  site.
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingFour">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour">
                How can I access ShareInvestor on the go?
              </button>
            </h2>
            <div
              id="collapseFour"
              className="accordion-collapse collapse"
              aria-labelledby="headingFour"
              data-bs-parent="#accordionFAQ">
              <div className="accordion-body">
                <p>
                  We have designed the website to be responsive and mobile friendly. You will not
                  have any issue accessing ShareInvestor using your mobile browser. If you want a
                  better experience, we encourage you to use our dedicated mobile app -
                  ShareInvestor Mobile. Just search for "ShareInvestor Mobile" on App Store or Play
                  Store to download and install.
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingFive">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseFive">
                I subscribe to a limited amount of total bandwidth from my Internet Service Provider
                every month. Is the bandwidth usage for streaming going to be very high?
              </button>
            </h2>
            <div
              id="collapseFive"
              className="accordion-collapse collapse"
              aria-labelledby="headingFive"
              data-bs-parent="#accordionFAQ">
              <div className="accordion-body">
                <p>
                  No. We have used our expertise in realtime streaming on ShareInvestor Station and
                  have brought this to the browser. As we are deploying a real streaming technology,
                  only changes will be pushed to you. In addition, all of the user interface
                  elements(color changing/flashing) are done on your browser directly and not
                  streamed. Hence the total bandwidth used will be low.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

FAQ.propTypes = {
  country: PropTypes.string,
};
