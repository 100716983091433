export const CURRENCIES = [
  { value: 'S', label: 'SGD - Singapore Dollar', name: 'SGD', market: 'SGX' },
  { value: 'M', label: 'MYR - Malaysian Ringgit', name: 'MYR', market: 'Bursa' },
  { value: 'H', label: 'HKD - Hong Kong Dollar', name: 'HKD', market: 'HKEx' },
  { value: 'R', label: 'IDR - Indonesian Rupiah', name: 'IDR', market: 'IDX' },
  { value: 'B', label: 'THB - Thai Baht', name: 'THB', market: 'SET' },
  { value: 'A', label: 'AUD - Australian Dollar', name: 'AUD', market: 'ASX' },
  { value: 'U', label: 'USD - US Dollar', name: 'USD', market: 'NASDAQ, NYSE, NYSE' },
];

export const getCurrencyLabel = (value) => {
  const currency = CURRENCIES.find((curr) => curr.value === value);
  return currency ? currency.label : 'Unknown Currency';
};

export const getCurrencyName = (value) => {
  const currency = CURRENCIES.find((curr) => curr.value === value);
  return currency ? currency.name : 'Unknown Currency';
};

export const getCurrencyMarket = (value) => {
  if (!value) return {};
  return CURRENCIES.find((item) => item.market.includes(value));
};
