import axios from 'axios';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import api from '../../utils/api';
import SearchBar from './SearchBar';
import SearchQuotesMarkets from './SearchQuotesMarkets';
import SearchQuotesList from './SearchQuotesList';
import QuoteInfo from './QuoteInfo';
import Loader from '../Loader';
import styles from './searchQuote.module.css';

const { CancelToken } = axios;
const allMarkets = ['SGX', 'Bursa', 'HKEx', 'SET', 'IDX', 'ASX', 'US', 'World'];
const usMarkets = ['NASDAQ', 'NYSE', 'NYSE American'];
const eodMarkets = ['SET', 'IDX', 'ASX', 'US', 'NASDAQ', 'NYSE', 'NYSE American', 'World'];
const MIN_KEYWORD_LENGTH = 1;

export default function SearchQuote({
  type,
  placeholder,
  handleActiveCounterChanged,
  checkedData,
  stockType,
  btnRef,
}) {
  const source = CancelToken.source();
  const [keyword, setKeyword] = useState('');
  const [markets, setMarkets] = useState(allMarkets);
  const [searchResult, setSearchResult] = useState();
  const [showModal, setShowModal] = useState(false);
  const [activeCounter, setActiveCounter] = useState({});
  const [activeCounterPrice, setActiveCounterPrice] = useState({});
  const [activeCounterNews, setActiveCounterNews] = useState({});
  const [isQuoteListLoading, setIsQuoteListLoading] = useState(true);
  const [isQuoteInfoLoading, setIsQuoteInfoLoading] = useState(true);

  const handleShowModal = () => {
    if (showModal) {
      return;
    }
    setShowModal(true);
  };

  const handleSearchChange = (e) => {
    e.preventDefault();
    const searchKey = e.target.value;
    setKeyword(searchKey);
    handleShowModal();
  };

  const handleModalClose = () => setShowModal(false);
  const handleModalShow = () => setShowModal(true);
  let blurTimeout;
  const handleOnBlur = (e) => {
    e.preventDefault();
    blurTimeout = setTimeout(() => {
      handleModalClose();
    }, 100); // set delay so handleActiveCounterChange can trigger
  };

  const handleOnFocus = () => {
    clearTimeout(blurTimeout);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleShowModal();
  };

  const handleMarketsChange = (e) => {
    const marketsCopy = [...markets];
    if (e.target.checked) {
      marketsCopy.push(e.target.id);
    } else {
      const index = marketsCopy.indexOf(e.target.id);
      if (index > -1) {
        marketsCopy.splice(index, 1);
      }
    }
    setMarkets(marketsCopy);
  };

  const redirectToFactsheetOverview = () => {
    if (!activeCounter?.marketAndCode) {
      return;
    }

    // Go to Factsheet
    window.location.href = `/home/redirect?type=factsheet&counter=${activeCounter?.marketAndCode}`;
  };

  const handleActiveCounterChange = (newActiveCounter) => {
    if (
      newActiveCounter.stock_id === activeCounter.stock_id &&
      newActiveCounter.market === activeCounter.market
    ) {
      return;
    }
    if (!newActiveCounter.stock_id) {
      setActiveCounter(newActiveCounter);
      return;
    }

    const symbol = `${newActiveCounter.stock_id}.${newActiveCounter.market_suffix}`;
    if (symbol === activeCounter.symbol && !handleActiveCounterChanged) {
      return;
    }

    const counterCopy = { ...newActiveCounter };
    counterCopy.symbol = symbol;

    // TODO: This should be done in mv_stocks_search_lookup. To add market_prefix to mv_stocks_search_lookup
    switch (counterCopy.market_suffix) {
      case 'MD':
        counterCopy.market_prefix = 'BURSAD';
        break;
      case 'NM':
        counterCopy.market_prefix = 'NYSEMKT';
        break;
      case 'WLD':
        counterCopy.market_prefix = 'INDEX';
        break;
      default:
        counterCopy.market_prefix = counterCopy.market.toUpperCase();
    }

    counterCopy.marketAndCode = `${counterCopy.market_prefix}:${counterCopy.stock_id}`;
    setActiveCounter(counterCopy);
  };

  const fetchPriceAndNewsData = () => {
    const cancelToken = source.token;
    setIsQuoteInfoLoading(true);

    api
      .get(`prices/searchbox_prices.json?counter=${activeCounter.symbol}`, { cancelToken })
      .then(({ data }) => {
        setActiveCounterPrice(data);
        setIsQuoteInfoLoading(false);
      })
      .catch((error) => {
        console.log('Search retrieve price error', error);
        setIsQuoteInfoLoading(false);
      });

    api
      .get(`news/searchbox_news.json?max=5&counter=${activeCounter.symbol}`, { cancelToken })
      .then(({ data }) => {
        setActiveCounterNews(data);
      })
      .catch((error) => {
        console.log('Search retrieve news error', error);
      });
  };

  useEffect(() => {
    if (keyword.length < MIN_KEYWORD_LENGTH || !showModal) {
      return;
    }

    const timer = setTimeout(() => {
      const cancelToken = source.token;
      let searchMarkets = [...markets];
      if (searchMarkets.includes('US')) {
        searchMarkets = [...searchMarkets, ...usMarkets];
      }
      setIsQuoteListLoading(true);

      api
        .get(
          `quote/search.json?markets=${searchMarkets}&keyword=${keyword}&stock_type=${stockType}`,
          { cancelToken },
        )
        .then(({ data }) => {
          setSearchResult(data);
          setIsQuoteListLoading(false);
        })
        .catch((error) => {
          console.log('Search keyword error', error);
          setIsQuoteListLoading(false);
        });
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [keyword, markets]);

  useEffect(() => {
    if (
      searchResult &&
      searchResult.data &&
      searchResult.data.length > 0 &&
      !handleActiveCounterChanged
    ) {
      handleActiveCounterChange(searchResult.data[0]);
    } else {
      handleActiveCounterChange({});
    }
  }, [searchResult]);

  useEffect(() => {
    if (!activeCounter.symbol) {
      return;
    }

    let timer = null;
    if (handleActiveCounterChanged) {
      timer = setTimeout(() => {
        handleActiveCounterChanged(activeCounter);
      }, 500);
    } else {
      fetchPriceAndNewsData();
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [activeCounter]);

  if (type === 'watchlist' || type === 'transaction') {
    return (
      <>
        <div className="searchBarContainer">
          <SearchBar
            placeholder={placeholder}
            searchInput={keyword}
            handleChange={handleSearchChange}
            handleSubmit={handleSubmit}
            autoFocus
          />
        </div>

        <div>
          <SearchQuotesMarkets
            searchResult={searchResult}
            markets={markets}
            handleMarketsChange={handleMarketsChange}
            allMarkets={allMarkets}
          />
          {keyword && <Loader visible={isQuoteListLoading} classes="text-center mb-3" />}
          {!isQuoteListLoading && (
            <SearchQuotesList
              searchResult={searchResult}
              activeCounter={activeCounter}
              changeActiveCounter={handleActiveCounterChange}
              checkedData={checkedData}
              enableCheckButton={type === 'watchlist'}
            />
          )}
        </div>
      </>
    );
  }

  return (
    <>
      {type === 'button' ? (
        <button
          type="button"
          className="btn-rounded g-mr-15 d-inline-block d-lg-none"
          onClick={handleModalShow}
          id="header-search-btn--mobile"
          ref={btnRef}
        />
      ) : (
        <SearchBar
          placeholder={placeholder}
          searchInput={keyword}
          handleChange={handleSearchChange}
          handleSubmit={handleSubmit}
          handleShowModal={handleShowModal}
          handleEnter={redirectToFactsheetOverview}
          type={type}
          autoFocus={false}
        />
      )}

      <Modal
        show={showModal}
        onHide={handleModalClose}
        size="xl"
        fullscreen="lg-down"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName={`${styles.searchModal} g-text-size-14`}>
        <Modal.Header>
          <SearchBar
            placeholder={placeholder}
            searchInput={keyword}
            handleChange={handleSearchChange}
            handleSubmit={handleSubmit}
            handleEnter={redirectToFactsheetOverview}
            handleModalClose={handleModalClose}
            autoFocus
          />
        </Modal.Header>

        <div className="container-fluid bg-white rounded-bottom">
          <div className="row">
            <div className="col-lg-4 col-sm-12 px-0">
              <SearchQuotesMarkets
                searchResult={searchResult}
                markets={markets}
                handleMarketsChange={handleMarketsChange}
                allMarkets={allMarkets}
              />
              {keyword && <Loader visible={isQuoteListLoading} classes="text-center mb-3" />}
              {!isQuoteListLoading && (
                <SearchQuotesList
                  searchResult={searchResult}
                  activeCounter={activeCounter}
                  changeActiveCounter={handleActiveCounterChange}
                  allowRedirect
                />
              )}
            </div>
            <div className="col-lg-8 px-0 bg-light d-none d-lg-block rounded-bottom">
              {keyword && <Loader visible={isQuoteListLoading} classes="text-center mb-3" />}
              {!isQuoteInfoLoading && (
                <QuoteInfo
                  activeCounter={activeCounter}
                  activeCounterPrice={activeCounterPrice}
                  activeCounterNews={activeCounterNews}
                  eodMarkets={eodMarkets}
                />
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

SearchQuote.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  handleActiveCounterChanged: PropTypes.func,
  checkedData: PropTypes.arrayOf(PropTypes.string),
};
