import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Card from 'react-bootstrap/Card';
import Dropdown from 'react-bootstrap/Dropdown';
import Alert from 'react-bootstrap/Alert';

import APIErrorHandler from '../../../../components/APIErrorHandler';
import Loader from '../../../../components/Loader';
import DividendTable from './DividendTable';
import CreateUpdateDividend from './CreateUpdateDividend';
import { RANGE_OPTIONS, fetchData, clearResponseAndError } from './portfolioDividendSlice';
import Pagination from '../../../../components/Pagination';
import { formatDecimal } from '../../../../utils/utils';

const { CancelToken } = axios;

const CATEGORY = {
  all: 'All',
  upcoming: 'Upcoming',
  historical: 'Historical',
  // custom: 'Custom',
  // auto: 'Auto',
};

function DividendAnalysis() {
  const dispatch = useDispatch();
  const source = CancelToken.source();
  const selectedPortfolio = useSelector((state) => state.portfolio.selected);
  const dividendData = useSelector((state) => state.portfolioDividend.data);
  const pagination = useSelector((state) => state.portfolioDividend.pagination);
  const totalDividendIncome = useSelector((state) => state.portfolioDividend.totalDividendIncome);
  const loading = useSelector((state) => state.portfolioDividend.loading);
  const error = useSelector((state) => state.portfolioDividend.err);
  const response = useSelector((state) => state.portfolioDividend.response);
  const [range, setRange] = useState('all');
  const [category, setCategory] = useState('all');
  const [showModal, setShowModal] = useState(false);
  const [showAlert, setShowAlert] = useState(true);
  const [page, setPage] = useState(1);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleRangeChange = (e) => {
    const newRange = e.target.getAttribute('value');
    if (!newRange || newRange === range) {
      return;
    }

    if ((category === 'upcoming' || category === 'historical') && newRange !== 'all') {
      // reset range if category is related to range
      setCategory('all');
    }

    setRange(newRange);
    setPage(1);
  };

  const handleCategoryChange = (e) => {
    const newCategory = e.target.getAttribute('value');
    if (!newCategory || newCategory === category) {
      return;
    }

    if ((newCategory === 'upcoming' || newCategory === 'historical') && range !== 'all') {
      // reset range if category is related to range
      setRange('all');
    }

    setCategory(newCategory);
    setPage(1);
  };

  const handlePageChange = (newPage) => {
    if (!newPage || page === newPage) {
      return;
    }

    setPage(newPage);
  };

  useEffect(() => {
    return () => dispatch(clearResponseAndError());
  }, []);

  useEffect(() => {
    dispatch(
      fetchData({
        portfolioID: selectedPortfolio.id,
        page,
        categoryFilter: category,
        rangeFilter: range,
        cancelToken: source.token,
      }),
    );
  }, [category, range, selectedPortfolio, page]);

  useEffect(() => {
    setShowAlert(response);
    let timer = null;
    if (response) {
      timer = setTimeout(() => {
        setShowAlert(false);
      }, 5000);
      // reset params
      handlePageChange(1);
      setRange('all');
      setCategory('all');
    }
    return () => (timer ? clearTimeout(timer) : timer);
  }, [response]);

  return (
    <Card className="g-mt-20">
      <Card.Body>
        <div className="row justify-content-between g-mb-20">
          <div className="col-lg-6">
            <Card.Title>Dividend Analysis</Card.Title>
          </div>
          <div className="col-auto">
            <CreateUpdateDividend
              dividend={{ id: null, counter: null, curr_id: selectedPortfolio.currency }}
            />
          </div>
        </div>
        <APIErrorHandler error={error} />
        {showAlert && (
          <Alert variant="success" onClose={() => setShowAlert(false)} dismissible>
            {response}
          </Alert>
        )}
        <Loader visible={loading} classes="text-center m-5" />
        {!loading && !error && (
          <>
            <div className="row g-3 g-mb-20">
              <div className="col-auto">
                <Dropdown className="d-inline-block">
                  <Dropdown.Toggle variant="light" disabled={loading}>
                    {RANGE_OPTIONS[range].label}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {Object.keys(RANGE_OPTIONS).map((key) => {
                      return (
                        <Dropdown.Item
                          key={key}
                          value={key}
                          onClick={handleRangeChange}
                          active={range === key}>
                          {RANGE_OPTIONS[key].label}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="col-auto">
                <Dropdown className="d-inline-block">
                  <Dropdown.Toggle variant="light" disabled={loading}>
                    {CATEGORY[category]}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {Object.keys(CATEGORY).map((key) => {
                      return (
                        <Dropdown.Item
                          key={key}
                          value={key}
                          onClick={handleCategoryChange}
                          active={category === key}>
                          {CATEGORY[key]}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <DividendTable />

            <Pagination
              rowOffset={pagination.page_row_offset}
              currentPageRows={dividendData.length}
              totalRows={pagination.total_rows}
              currentPage={page}
              totalPages={pagination.total_pages}
              handlePageChange={handlePageChange}
            />

            <div className="row border-bottom border-dark p-3">
              <div className="col-9 col-lg-8 text-end">
                <strong>Total Dividend Income</strong>
              </div>
              <div className="col-3 col-lg-2 text-end pe-0">
                <strong>{formatDecimal(totalDividendIncome, 3, true)}</strong>
              </div>
            </div>
          </>
        )}
      </Card.Body>
    </Card>
  );
}

export default DividendAnalysis;
