import React from 'react';
import PropTypes from 'prop-types';
import './statisticAndTableNotes.css';
// Quote Movements and Time and Sales notes
function NoteSection({ type }) {
  return (
    <div className="mt-5">
      <div className="g-text-size-14 g-fw-500 footer-disclaimer mb-3 g-mt-25">Notes:</div>
      <div className="sic_legendAlpha">
        <div className="d-flex gap-1 ">
          <div className="g-text-size-14 footer-disclaimer">
            <ul className="sic_legend">
              <li>
                <span className="sic_up">Buy Up</span> refers to transactions done in buying from
                the sell queue
              </li>
              <li>
                <span className="sic_down">Sell Down</span> refers to transactions done in selling
                into the buy queue
              </li>
              <li>
                <strong>Mid</strong> refers to married deals
              </li>
              <li>
                <strong>Preopen</strong> refers to the transaction done during the preopen period at
                8:59
              </li>
              <li>
                <strong>Preclose</strong> refers to the transaction done during the preclose period
                at 17:05
              </li>
              <li>
                <strong>Adjust</strong> refers to the transaction done during the lunch adjustment
                period at 12:59 or after a trading halt
              </li>
              <li>
                <strong>Unknown</strong> refers to deals where there isn't enough information to
                determine the &ldquo;Type&ldquo;
              </li>
              {(type === 'quote_movements' || type === 'time_and_sales') && (
                <>
                  <li>
                    <div className="note-cell sic_upBackground" />A green cell represents an
                    increase in price over the previous value.
                  </li>
                  <li>
                    <div className="note-cell sic_downBackground" />A red cell represents a decrease
                    in price over the previous value.
                  </li>
                  <li>
                    <div className="note-cell sic_highlight" />A highlighted grey row represents a
                    trade whose value is more than $150,000.
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

NoteSection.propTypes = {
  type: PropTypes.string,
};

export default NoteSection;
