import { createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';

const intrinsicValueAnalysisSlice = createSlice({
  name: 'intrinsicValueAnalysis',
  initialState: {
    data: {},
    loading: true,
    err: null,
    hasIntradayData: null,
    priceSummary: {},
    isDuringStreamingHours: false,
    formData: {
      ivc_eps: '',
      ivc_cfo: '',
      ivc_growth_rate: '',
      adjusted_ivc_growth_rate_radio: '',
      ivc_years_growth: 10,
      ivc_discount_rate: 4,
      ivc_terminal_growth_rate: 3,
      include_ivc_terminal_growth_rate: 'exclude',
      dividend_per_share: '',
      rate_of_return: '',
    },
    selectedModel: 'discounted_earning',
    intrinsicValue: {},
    marginOfSafety: {},
    marginOfSafetyStatement: {},
    filteredFields: [],
    marginSafetyOfBounds: [-5, 25],
    peerAnalysisTableData: {},
    marginOfSafetyConfig: [
      {
        key: 'undervalue',
        statement: 'Undervalue',
      },
      {
        key: 'fairvalue',
        statement: 'Fair Value',
      },
      {
        key: 'overvalue',
        statement: 'Overvalue',
      },
    ],
  },
  reducers: {
    valuationFetchStart(state, action) {
      state.data = null;
      state.err = null;
      state.loading = true;
    },
    valuationFetchSuccess(state, action) {
      state.data = action.payload.data;
      state.formData = {
        ...state.formData,
        ivc_eps: action.payload.data.ratio_eps,
        ivc_cfo: action.payload.data.ratio_fcf_per_share,
        ivc_growth_rate: action.payload.data.adjusted?.mean_earnings_cagr_2_year || '',
        adjusted_ivc_growth_rate_radio:
          action.payload.data.adjusted?.mean_earnings_cagr_2_year || '',
        dividend_per_share: action.payload.data.net_dividend,
      };
      state.hasIntradayData = action.payload.data.has_intraday_data;
      state.priceSummary = action.payload.data.price_summary;
      state.isDuringStreamingHours = action.payload.data.is_during_streaming_hours;
      state.err = null;
      state.loading = false;
    },
    valuationFetchFail(state, action) {
      state.err = action.payload;
      state.loading = false;
    },
    valuationSetFormData(state, action) {
      state.formData = action.payload;
    },
    valuationSetSelectedModel(state, action) {
      state.selectedModel = action.payload;
    },
    valuationSetFilteredFields(state, action) {
      state.filteredFields = action.payload;
    },
    valuationSetIntrinsicValue(state, action) {
      state.intrinsicValue = action.payload;
    },
    valuationSetMarginOfSafety(state, action) {
      state.marginOfSafety = action.payload;
    },
    valuationSetMarginOfSafetyStatement(state, action) {
      state.marginOfSafetyStatement = action.payload;
    },
    peerAnalysisSetTableData(state, action) {
      state.peerAnalysisTableData = action.payload;
    },
  },
});

export default intrinsicValueAnalysisSlice.reducer;

const {
  valuationFetchStart,
  valuationFetchSuccess,
  valuationFetchFail,
  valuationSetFormData,
  valuationSetSelectedModel,
  valuationSetFilteredFields,
  valuationSetIntrinsicValue,
  valuationSetMarginOfSafety,
  valuationSetMarginOfSafetyStatement,
  peerAnalysisSetTableData,
} = intrinsicValueAnalysisSlice.actions;

export const fetchData =
  ({ counter, cancelToken, type }) =>
  (dispatch) => {
    let url = `/screener/intrinsic_valuation_tool.json?counter=${counter}`;
    if (type) {
      url += `&type=${type}`;
    }
    dispatch(valuationFetchStart());
    api
      .get(url, { cancelToken })
      .then(({ data }) => {
        dispatch(valuationFetchSuccess({ data }));
      })
      .catch((error) => {
        dispatch(valuationFetchFail(error));
      });
  };

export const setFormData =
  ({ formData }) =>
  (dispatch) => {
    dispatch(valuationSetFormData(formData));
  };

export const setSelectedModel =
  ({ selectedModel }) =>
  (dispatch) => {
    dispatch(valuationSetSelectedModel(selectedModel));
  };

export const setFilteredFields =
  ({ filteredFields }) =>
  (dispatch) => {
    dispatch(valuationSetFilteredFields(filteredFields));
  };

export const setIntrinsicValue =
  ({ intrinsicValue }) =>
  (dispatch) => {
    dispatch(valuationSetIntrinsicValue(intrinsicValue));
  };

export const setMarginOfSafety =
  ({ marginOfSafety }) =>
  (dispatch) => {
    dispatch(valuationSetMarginOfSafety(marginOfSafety));
  };

export const setMarginOfSafetyStatement =
  ({ statement }) =>
  (dispatch) => {
    dispatch(valuationSetMarginOfSafetyStatement(statement));
  };

export const setPeerAnalysisTableData =
  ({ peerAnalysisTableData }) =>
  (dispatch) => {
    dispatch(peerAnalysisSetTableData(peerAnalysisTableData));
  };
