import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { ToastContainer, Slide } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { serializeForm, constructXMLNode, getXMLMarket } from './utils';
import {
  requestQueueNumber,
  fetchScreenerData,
  cancelFetchScreenerData,
  setXmlQueryStr,
  setInvalidForms,
  setScreenerError,
  removeScreenerError,
  fetchUserTemplates,
  setAddedCriterias,
  setSelectedFolder,
  resetQueryData,
} from './marketScreenerSlice';
import Loader from '../../../components/Loader';

import APIErrorHandler from '../../../components/APIErrorHandler';
import ColumnSelection from '../../../components/ColumnSelection';
import AnalysisToolsButton from '../../../components/AnalysisToolsButton';
import AddCriterias from './AddCriterias';
import LoadTemplates from './LoadTemplates';
import PortfolioSeries from './PortfolioSeries';
import FoldersDropdown from './FoldersDropdown';
import StockTypeDropdown from './StockTypeDropdown';
import MarketSelectDropdown from '../../../components/MarketSelectDropdown';
import ScreenerResultTable from './ScreenerResultTable';
import SaveTemplatesForm from './SaveTemplatesForm';

const ALL_MARKETS = [
  'sgx',
  'bursa',
  'set',
  'idx',
  'asx',
  'hkex',
  'nyse',
  'nasdaq',
  'nyse_mkt',
  'world',
];

function MarketScreenerMain({
  market,
  markets_with_access,
  criteria_config,
  criteria_groups,
  stock_categories,
  porfolio_series_templates,
  portfolio_folders,
  watchlist_folders,
}) {
  const dispatch = useDispatch();
  const [isSSR, setIsSSR] = useState(true);
  const selectedFolder = useSelector((state) => state.marketScreener.selectedFolder);
  const xmlQueryStr = useSelector((state) => state.marketScreener.xmlQueryStr);
  const queueNumber = useSelector((state) => state.marketScreener.queueNumber);
  const screeningLoading = useSelector((state) => state.marketScreener.screeningLoading);
  const analysisChartsFeatures = useSelector(
    (state) => state.marketScreener.analysisChartsFeatures,
  );
  const screeningDataCounters = useSelector((state) => state.marketScreener.screeningDataCounters);
  const screeningComplete = useSelector((state) => state.marketScreener.screeningComplete);
  const screeningTryCount = useSelector((state) => state.marketScreener.screeningTryCount);
  const selectedStockType = useSelector((state) => state.marketScreener.selectedStockType);
  const addedCriterias = useSelector((state) => state.marketScreener.addedCriterias);
  const error = useSelector((state) => state.marketScreener.err);
  const defaultMarket = useSelector((state) => state.selectedMarket.selectedMarket);
  const [selectedMarket, setSelectedMarket] = useState(market || defaultMarket);
  const selectedStocks = useSelector((state) => state.marketScreener.selectedStocks);
  const [showModal, setShowModal] = useState(false);
  const [cancelScreeningTokenSource, setCancelScreeningTokenSource] = useState(null);
  const [selectedPortfolio, setSelectedPortfolio] = useState(null);
  const [selectedWatchlist, setSelectedWatchlist] = useState(null);

  const handleDropdownChange = (type, selectedOption) => {
    if (type === 'Watchlist') {
      setSelectedWatchlist(selectedOption);
      setSelectedPortfolio(null); // Reset Portfolio dropdown
    } else if (type === 'Portfolio') {
      setSelectedPortfolio(selectedOption);
      setSelectedWatchlist(null); // Reset Watchlist dropdown
    }

    dispatch(
      setSelectedFolder({
        value: selectedOption === null ? '' : selectedOption.value,
        label: selectedOption === null ? '' : selectedOption.label,
        type,
      }),
    );
  };

  const validateInputs = () => {
    let isInvalid = false;
    const invalidForms = [];
    if (addedCriterias.length === 0) {
      const error = { valid: false, message: 'Please add in at least one criterion to screen.' };
      dispatch(setScreenerError({ error }));
      isInvalid = true;
      return isInvalid;
    }

    const validateCriteria = (form, form_index) => {
      let invalid = false;

      if (form.checkValidity() === false) {
        invalid = true;
        invalidForms.push(form_index);
      }

      if (invalid) isInvalid = true;
    };

    const criteriaRowForms = document.querySelectorAll('#form_selected_criteria:not(.disabled)');
    criteriaRowForms.forEach((criteriaRowForm, index) => {
      const andCriteriaForm = criteriaRowForm.querySelector('.screener_template_form');
      const subCriteriaForms = criteriaRowForm.querySelectorAll(
        '.sub_criteria_row > .screener_template_form',
      );
      if (andCriteriaForm) {
        validateCriteria(andCriteriaForm, index.toString());
      }
      if (subCriteriaForms && subCriteriaForms.length > 0) {
        subCriteriaForms.forEach((subCriteriaForm, sub_index) => {
          validateCriteria(subCriteriaForm, `${index}_${sub_index}`);
        });
      }
    });

    if (invalidForms.length > 0) {
      const error = {
        valid: false,
        message: 'Please set the values for selected criteria(s) before proceed to screen.',
      };
      dispatch(setScreenerError({ error }));
    } else {
      dispatch(removeScreenerError());
      dispatch(resetQueryData());
    }

    dispatch(
      setInvalidForms({
        invalidForms,
      }),
    );

    return isInvalid;
  };

  const handleOpenModal = (type) => {
    setShowModal(type);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleClickSaveTemplate = (event) => {
    event.preventDefault();
    const source = axios.CancelToken.source();
    dispatch(
      fetchUserTemplates({
        cancelToken: source.token,
      }),
    );
    handleOpenModal('save_template');
  };

  const startScreening = (options) => {
    const source = axios.CancelToken.source();
    // 1a. Construct XML
    const andGroups = document.querySelectorAll(
      '#form_selected_criteria:not(.disabled) > .criteria_row_wrapper',
    );
    const and_xml_nodes = [];
    andGroups.forEach(function (group) {
      const paramsForms = group.querySelectorAll('form');
      const or_xml_nodes = [];
      paramsForms.forEach(function (paramsForm) {
        const formData = serializeForm(paramsForm);
        const xml_expression_str = constructXMLNode(formData, criteria_config);
        or_xml_nodes.push(xml_expression_str);
      });

      let or_xml_str = '';
      if (or_xml_nodes.length > 1) {
        or_xml_str = `<OR>${or_xml_nodes.join('')}</OR>`;
      } else if (or_xml_nodes.length == 1) {
        or_xml_str = or_xml_nodes[0];
      }

      if (or_xml_str.length > 0) {
        and_xml_nodes.push(or_xml_str);
      }
    });

    const xmlStockType = [];
    let or_xml_stock_type;
    if (Object.values(selectedStockType).filter((type) => type.checked).length > 0) {
      // <EQ><Field>groupStockStatusStockType</Field><StrVal>stock</StrVal></EQ>
      Object.entries(selectedStockType).map(([k, v]) => {
        if (v.checked) {
          xmlStockType.push(
            `<EQ><Field>groupStockStatusStockType</Field><StrVal>${k}</StrVal>` + `</EQ>`,
          );
        }
      });
      or_xml_stock_type = `<OR>${xmlStockType.join('')}</OR>`;
      and_xml_nodes.push(or_xml_stock_type);
    }

    // 1b. If portfolio is selected, then form portfolio xml nodes.
    let portfolio_xml = '';
    if (selectedFolder?.value) {
      // User selected portfolio, thus we need to screen by portfolio.
      portfolio_xml = `<Portfolio>${selectedFolder.value}</Portfolio>`;
    }

    // pass { 'unlimitedStockList': 1 } to execute for NYSE market
    if (options != undefined && 'unlimitedStockList' in options) {
      and_xml_nodes.push('<EQ><Field>unlimitedStockList</Field><StrVal>1</StrVal></EQ>');
    }

    const xmlMarket = getXMLMarket(selectedMarket);

    and_xml_nodes.push(
      `<EQ><Field>generalMarket</Field><StrVal>${xmlMarket}</StrVal>${portfolio_xml}</EQ>`,
    );

    let xmlQueryStr = `<AND>${and_xml_nodes.join('')}</AND>`;
    xmlQueryStr = decodeURIComponent(xmlQueryStr);

    dispatch(
      setXmlQueryStr({
        xmlQueryStr,
      }),
    );

    dispatch(
      requestQueueNumber({
        market: selectedMarket,
        xmlQueryStr,
        cancelToken: source.token,
      }),
    );
  };

  useEffect(() => {
    setIsSSR(typeof document === 'undefined');
  }, []);

  // update selectedMarket state when selectedMarket redux has changed.
  useEffect(() => {
    if (defaultMarket == null || defaultMarket === selectedMarket.toLowerCase()) {
      return;
    }
    setSelectedMarket(defaultMarket);
  }, [defaultMarket]);

  // when click screenNow
  // 1. validate all inputs and then start screening
  const handleClickScreenNow = (event) => {
    event.preventDefault();

    const isInvalid = validateInputs();

    if (isInvalid === true) {
      document.querySelector('body').classList.add('form-validated');
      return;
    }
    document.querySelector('body').classList.remove('form-validated');

    if (
      selectedMarket === 'hkex' ||
      selectedMarket === 'set' ||
      selectedMarket === 'idx' ||
      selectedMarket === 'asx' ||
      selectedMarket === 'nyse' ||
      selectedMarket === 'nasdaq' ||
      selectedMarket === 'nyse_mkt'
    ) {
      // show prompt whether proceed with limit screening.
      handleOpenModal('warning');
    } else {
      startScreening();
    }
  };

  // 1.a limit screening
  const handleClickScreenNowWithOptions = (event, options) => {
    event.preventDefault();
    handleCloseModal();
    if (options !== undefined && 'unlimitedStockList' in options) {
      startScreening({ unlimitedStockList: 1 });
    } else {
      startScreening();
    }
  };

  // 1.b allow cancel screening while screening is running.
  const handleClickCancelScreenNow = (event) => {
    event.preventDefault();
    handleOpenModal(false);

    if (cancelScreeningTokenSource) {
      cancelScreeningTokenSource.cancel('Stop query');
      setCancelScreeningTokenSource(null);
    }
    dispatch(cancelFetchScreenerData());
  };

  // 2. start fetch result once obtained `queueNumber` and `xmlQueryStr`
  useEffect(() => {
    if (!queueNumber || !xmlQueryStr) {
      return;
    }

    const source = axios.CancelToken.source();
    handleOpenModal('screening');
    dispatch(
      fetchScreenerData({
        market: selectedMarket,
        queueNumber,
        xmlQueryStr,
        tryCount: screeningTryCount,
        cancelToken: source.token,
      }),
    );

    // Store the source so it can be cancelled later
    setCancelScreeningTokenSource(source);
  }, [queueNumber]);

  // 4. close all modal
  useEffect(() => {
    if (screeningComplete) {
      handleCloseModal();
    }
  }, [screeningComplete]);

  if (isSSR) {
    return <Loader visible={isSSR} className="g-height-800" />;
  }

  return (
    <>
      <div className="row align-items-center g-lg-mb-30 g-mb-20">
        <div className="col-lg-8">
          <h3 className="g-mb-5 g-lg-mb-0">Market Screener (FA & TA)</h3>
        </div>
      </div>
      <div className="divider-h w-100 g-mb-20 g-lg-mb-30" />
      <div className="row g-4 align-items-center justify-content-between g-mb-30">
        <div className="col-xl-auto">
          <div className="d-flex flex-row gap-3 align-items-center">
            <LoadTemplates market={selectedMarket} criteriaConfig={criteria_config} />
            <div className="text-center">
              <div>or</div>
              <div>explore</div>
            </div>
            <PortfolioSeries
              data={porfolio_series_templates}
              market={selectedMarket}
              criteriaConfig={criteria_config}
            />
          </div>
        </div>
        <div className="col-xl">
          <div className="d-flex gap-3 flex-wrap justify-content-xl-end">
            <MarketSelectDropdown
              market={selectedMarket}
              variant="light"
              validMarkets={ALL_MARKETS}
              updateUrlOnClick
            />
            <FoldersDropdown
              folders={portfolio_folders}
              type="Portfolio"
              selectedOption={selectedPortfolio}
              onDropdownChange={handleDropdownChange}
            />
            <FoldersDropdown
              folders={watchlist_folders}
              type="Watchlist"
              selectedOption={selectedWatchlist}
              onDropdownChange={handleDropdownChange}
            />
            {/* <StockTypeDropdown /> */}
          </div>
        </div>
      </div>

      <AddCriterias
        market={selectedMarket}
        criteriaConfig={criteria_config}
        criteriaGroups={criteria_groups}
        stockCategories={stock_categories}
      />

      <div className="d-flex justify-content-center gap-3">
        <Button variant="primary" onClick={handleClickScreenNow}>
          Screen Now
        </Button>
        <Button variant="dark" onClick={handleClickSaveTemplate}>
          Save Template
        </Button>
      </div>

      {error && (
        <div className="g-mt-30">
          <APIErrorHandler error={error} />
        </div>
      )}

      <div className="g-mt-30">
        <h4>Screen Results</h4>
        <div className="row justify-content-xxl-between g-mb-15">
          <div className="col-xl gap-2 g-mb-10 g-xl-mb-0 d-flex flex-xxl-row align-items-start">
            <ColumnSelection selectedStocks={selectedStocks} enableCompare enableAddWatchlist />
            <AnalysisToolsButton
              counters={screeningDataCounters}
              market={selectedMarket}
              availableFeatures={analysisChartsFeatures}
              isScreener
            />
          </div>
          <div
            id="sic_layoutOptions"
            className="col-lg-auto d-flex justify-content-xl-end align-self-lg-end gap-2">
            {/* TODO: Add print, copy, edit column buttons */}
          </div>
        </div>
        <ScreenerResultTable criteriaConfig={criteria_config} />
      </div>
      <Modal
        show={showModal !== false}
        centered
        backdrop={showModal === 'screening' && screeningLoading ? 'static' : true}
        dialogClassName="g-text-size-14"
        onHide={handleCloseModal}>
        {showModal === 'warning' && (
          <Modal.Body>
            <h6>Warning: Screening may take at least 8 minutes for the given market.</h6>
            <p>
              To screen faster, you may limit the screening to the top 1,200 counters, based on the
              total volume traded for the last 5 days.
            </p>
            <div className="d-flex gap-2 justify-content-center align-items-center">
              <Button
                variant="secondary"
                onClick={(e) => handleClickScreenNowWithOptions(e, { unlimitedStockList: true })}>
                Continue Without Limit
              </Button>
              <Button variant="primary" onClick={(e) => handleClickScreenNowWithOptions(e)}>
                Limit
              </Button>
            </div>
          </Modal.Body>
        )}
        {showModal === 'screening' && screeningLoading && (
          <Modal.Body>
            <h6>Screening now...</h6>
            <p>Please wait patiently, the results will return within a few minutes.</p>
            <div className="g-mb-20">
              <Loader visible={screeningLoading} />
            </div>
            <div className="d-flex gap-2 justify-content-center align-items-center">
              <Button variant="secondary" onClick={handleClickCancelScreenNow}>
                Cancel
              </Button>
            </div>
          </Modal.Body>
        )}
        {showModal === 'save_template' && (
          <>
            <Modal.Header closeButton>
              <Modal.Title>Save Templates</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <SaveTemplatesForm handleOpenModal={handleOpenModal} />
            </Modal.Body>
          </>
        )}
      </Modal>
      <ToastContainer transition={Slide} containerId="marketScreenerSlice" />
    </>
  );
}

MarketScreenerMain.propTypes = {
  market: PropTypes.string,
  markets_with_access: PropTypes.oneOfType([PropTypes.array]),
  criteria_config: PropTypes.oneOfType([PropTypes.object]),
  criteria_groups: PropTypes.oneOfType([PropTypes.object]),
  stock_categories: PropTypes.oneOfType([PropTypes.object]),
  porfolio_series_templates: PropTypes.oneOfType([PropTypes.object]),
  portfolio_folders: PropTypes.oneOfType([PropTypes.array]),
  watchlist_folders: PropTypes.oneOfType([PropTypes.array]),
};

export default MarketScreenerMain;
