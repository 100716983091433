import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { Container } from 'react-bootstrap';

import Header from './Header';
import MembershipPaymentCard from './MembershipPaymentCard';

function MembershipPayment({ country }) {
  const navigate = useNavigate();
  const data = useSelector((state) => state.membership.data);
  const plan = useSelector((state) => state.membership.plan);
  const period = useSelector((state) => state.membership.period);
  const addOns = useSelector((state) => state.membership.addOns);
  const priceType = useSelector((state) => state.membership.priceType);

  const handleBackClick = () => {
    const params = { period, plan, priceType };
    const addOnsKeys = Object.keys(addOns).filter((addOnKey) => addOns[addOnKey]);
    if (addOns && addOnsKeys.length > 0) {
      params.addOns = Object.keys(addOns)
        .filter((addOnKey) => addOns[addOnKey])
        .join(',');
    }

    navigate({
      pathname: '/membership/plans/select_plans',
      search: createSearchParams(params).toString(),
    });
  };

  if (!plan) {
    navigate({
      pathname: '/membership/plans',
      search: createSearchParams({ period }).toString(),
    });
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <>
      <Header title="Plan Subscription" step="Payment Options" />
      {data?.membership && (
        <Container>
          <div className="row g-mt-60 g-mb-60">
            <div>
              <div className="d-flex align-middle g-mb-60">
                <button
                  className="btn btn-light g-min-width-40 d-inline-block"
                  type="button"
                  onClick={handleBackClick}>
                  <i className="fa-thin fa-arrow-left fa-xl" />
                </button>
                <h2 className="d-inline-block mx-3 mb-0">Payment</h2>
              </div>
              <MembershipPaymentCard country={country} />
            </div>
          </div>
        </Container>
      )}
    </>
  );
}

export default MembershipPayment;

MembershipPayment.propTypes = {
  country: PropTypes.string,
};
