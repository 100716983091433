import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { NavComponent } from '../../components/PageLocalNav';
import { NewsInfiniteScroll } from '../../components/News';
import MarketSelectDropdown from '../../components/MarketSelectDropdown';
import { createLoadableComponent } from '../../utils/Loadable';
import SelectCounter from '../../components/SelectCounter';
import Loader from '../../components/Loader';
import APIErrorHandler from '../../components/APIErrorHandler';
import { useDetectMobileScreen } from '../../utils/utils';

const DateFilter = createLoadableComponent(() => import('../../components/DateFilter'));
const CategoryFilter = createLoadableComponent(() =>
  import('../../components/News/CategoryFilter'),
);

const DELAY = 800;
const NEWS_PER_PAGE = 32;

function setUrlParam(key, value) {
  const url = new URL(window.location);
  url.searchParams.set(key, value);
  window.history.pushState({ [key]: value }, document.title, url);
}

function removeUrlParam(key) {
  const url = new URL(window.location);
  url.searchParams.delete(key);
  window.history.replaceState({ [key]: null }, document.title, url);
}

function NewsMain({
  basepath,
  availableMarketsAndNews,
  newsTabsInfo,
  newsTabs,
  market,
  newsType,
  counter,
  fromDate,
  toDate,
  category,
}) {
  const defaultMarket = useSelector((state) => state.selectedMarket.selectedMarket);
  const [selectedMarket, setSelectedMarket] = useState(market);
  const [currentNewsTabs, setCurrentNewsTabs] = useState(newsTabs);
  const [selectedNewsType, setSelectedNewsType] = useState(newsType);
  const [selectedCounter, setSelectedCounter] = useState(counter);
  const [selectedFromDate, setSelectedFromDate] = useState(fromDate);
  const [selectedToDate, setSelectedToDate] = useState(toDate);
  const [selectedCategory, setSelectedCategory] = useState(category);
  const [viewMode, setViewMode] = useState('grid_main');
  const [isSSR, setIsSSR] = useState(true);
  const error = useSelector((state) => state.newsInfiniteScroll.err);
  const isMobile = useDetectMobileScreen();

  const newsConfig = {
    market: selectedMarket,
    newsType: selectedNewsType,
    counter: selectedCounter?.code,
    fromDate: selectedFromDate,
    toDate: selectedToDate,
    category: selectedCategory,
    origin: 'news_main',
  };

  const handleViewMode = (mode) => {
    setViewMode(mode);
  };

  const VIEW_MODE_BUTTONS = [
    {
      icon: 'fa-thin fa-grid-2 fa-lg',
      value: 'grid_main',
    },
    {
      icon: 'fa-thin fa-list-ul fa-lg',
      value: 'list_main',
    },
  ];

  const viewModeComponent = (
    <div className="d-flex gap-2 flex-wrap">
      {VIEW_MODE_BUTTONS.map((button) => {
        return (
          <button
            type="button"
            key={button.value}
            onClick={() => handleViewMode(button.value)}
            className={`${
              button.value === viewMode ? 'active' : ''
            } btn btn-outline-light g-min-width-0 g-width-40 g-height-40 p-0`}>
            <i className={`${button.icon}`} />
          </button>
        );
      })}
    </div>
  );

  const renderComponent = (tabKey) => {
    if (tabKey !== selectedNewsType) {
      return null;
    }

    if (isSSR) {
      return <Loader visible={isSSR} classes="g-height-80" />;
    }

    return (
      <div id="newsLists" className="row gy-4 g-mb-15">
        <div className="col-12">
          <div className="row gap-2 align-items-center justify-content-between">
            <div className="col-lg d-flex flex-row flex-wrap gap-3">
              {selectedNewsType !== 'acnnewswire' && (
                <div>
                  <SelectCounter
                    counter={selectedCounter}
                    market={selectedMarket === 'regional' ? ['SGX', 'Bursa'] : [selectedMarket]}
                    handleOnChangeCounter={handleCounterChange}
                  />
                </div>
              )}
              <DateFilter
                isInterval
                fromDate={selectedFromDate}
                handleFromDateChange={handleFromDateChange}
                toDate={selectedToDate}
                handleToDateChange={handleToDateChange}
                handleReset={handleDateReset}
              />
              {newsType === 'sgxnet' && (
                <CategoryFilter category={category} handleCategoryChange={handleCategoryChange} />
              )}
            </div>
            <div className="col-lg-auto">{!isMobile && viewModeComponent}</div>
          </div>
        </div>
        <NewsInfiniteScroll
          delay={DELAY}
          newsPerLoad={NEWS_PER_PAGE}
          newsConfig={newsConfig}
          basePath={basepath}
          viewMode={viewMode}
        />
      </div>
    );
  };

  const onTabSelectCallback = (event, selectedTabKey) => {
    event.preventDefault();
    if (
      !(selectedTabKey && currentNewsTabs.map((tab) => tab.key).includes(selectedTabKey)) &&
      selectedTabKey === selectedNewsType
    ) {
      return;
    }

    if (selectedTabKey === 'acnnewswire') {
      removeUrlParam('counter');
      setSelectedCounter({});
    }

    setSelectedCategory('');
    setSelectedNewsType(selectedTabKey);
    setUrlParam('news_type', selectedTabKey);
    removeUrlParam('category');
  };

  const handleCounterChange = (counterData) => {
    const newCounter = counterData ? { name: counterData.label, code: counterData.value } : {};

    if (selectedCounter?.code === newCounter?.code) {
      return;
    }

    if (newCounter?.code) {
      setUrlParam('counter', newCounter.code);
    } else {
      removeUrlParam('counter');
    }

    setSelectedCounter(newCounter);
  };

  const handleFromDateChange = (_newDate) => {
    if (!_newDate) {
      return;
    }

    let newDate = '';
    if (_newDate.length > 0) {
      newDate = dayjs(new Date(_newDate)).format('YYYY-MM-DD');
    }

    if (selectedFromDate === newDate) {
      return;
    }

    setUrlParam('from_date', newDate);
    setSelectedFromDate(newDate);
  };

  const handleToDateChange = (_newDate) => {
    if (!_newDate) {
      return;
    }

    let newDate = '';
    if (_newDate.length > 0) {
      newDate = dayjs(new Date(_newDate)).format('YYYY-MM-DD');
    }

    if (selectedToDate === newDate) {
      return;
    }

    setUrlParam('to_date', newDate);
    setSelectedToDate(newDate);
  };

  const handleDateReset = () => {
    removeUrlParam('from_date');
    removeUrlParam('to_date');
    setSelectedToDate('');
    setSelectedFromDate('');
  };

  const handleCategoryChange = (newCategory) => {
    if (!newCategory || selectedCategory === newCategory) {
      return;
    }

    setUrlParam('category', newCategory);
    setSelectedCategory(newCategory);
  };

  useEffect(() => {
    setIsSSR(typeof document === 'undefined');
  }, []);

  useEffect(() => {
    if (isMobile) {
      handleViewMode('list_main');
    }
  }, [isMobile]);

  useEffect(() => {
    if (defaultMarket == null) {
      return;
    }

    if (defaultMarket === selectedMarket.toLowerCase()) {
      return;
    }

    // update newsTabs according to selectedMarket and reset all filters
    const newNewsTabs = Object.values(availableMarketsAndNews[defaultMarket]).map((type) => ({
      key: type,
      path: `${basepath}/${type}`,
      label: newsTabsInfo[type],
    }));

    setSelectedMarket(defaultMarket);
    setCurrentNewsTabs(newNewsTabs);
    setSelectedNewsType('news_all');
    setSelectedCounter({});
    setSelectedFromDate('');
    setSelectedToDate('');
    setSelectedCategory('');

    // update and reset url
    setUrlParam('market', defaultMarket);
    setUrlParam('news_type', 'news_all');
    removeUrlParam('counter');
    removeUrlParam('from_date');
    removeUrlParam('to_date');
    removeUrlParam('category');
  }, [defaultMarket]);

  if (!isSSR) {
    window.onpopstate = (event) => {
      if (event.state) {
        const urlSearchParams = new URLSearchParams(window.location.search);
        setSelectedMarket(urlSearchParams.get('market') || 'regional');
        setSelectedNewsType(urlSearchParams.get('news_type') || 'news_all');
        setSelectedCounter(
          urlSearchParams.get('counter') ? { code: urlSearchParams.get('counter') } : {},
        );
        setSelectedFromDate(urlSearchParams.get('from_date') || '');
        setSelectedToDate(urlSearchParams.get('to_date') || '');
        setSelectedCategory(urlSearchParams.get('category') || '');
      }
    };
  }

  if (isSSR) {
    return <Loader visible={isSSR} classes="g-height-800" />;
  }

  if (error) {
    return <APIErrorHandler error={error} />;
  }

  return (
    <>
      <div className="g-mb-30">
        <MarketSelectDropdown
          market={selectedMarket}
          variant="light"
          validMarkets={Object.keys(availableMarketsAndNews)}
        />
      </div>

      <div>
        <NavComponent
          navLinks={currentNewsTabs}
          activeNav={selectedNewsType}
          content={renderComponent(selectedNewsType)}
          handleNavClick={onTabSelectCallback}
        />
      </div>
    </>
  );
}

NewsMain.propTypes = {
  basepath: PropTypes.string,
  availableMarketsAndNews: PropTypes.oneOfType([PropTypes.object]),
  newsTabsInfo: PropTypes.oneOfType([PropTypes.object]),
  newsTabs: PropTypes.oneOfType([PropTypes.array]),
  market: PropTypes.string,
  newsType: PropTypes.string,
  counter: PropTypes.string,
  fromDate: PropTypes.string,
  toDate: PropTypes.string,
  category: PropTypes.string,
};
export default NewsMain;
