import axios from 'axios';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, Provider, useDispatch } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import Loader from '../../components/Loader';
import NotFound from '../../components/NotFound';
import store from '../../utils/store';
import { fetchData } from './portfolioSlice';

import Content from './Content';
import Alerts from './Alerts';
import Analytics from './Analytics';
import Download from './Download';
import Insights from './Insights';
import News from './News';
import Report from './Report';
import Social from './Social';

const { CancelToken } = axios;

const TABS = [
  {
    path: 'analytics',
    label: 'Analytics',
    icon: 'fa-thin fa-magnifying-glass-chart fa-lg',
    element: <Analytics />,
  },
  {
    path: 'insights',
    label: 'Insights',
    icon: 'fa-thin fa-square-poll-vertical fa-lg',
    element: <Insights />,
  },
  // {
  //   path: 'news',
  //   label: 'News',
  //   icon: 'fa-thin fa-newspaper fa-lg',
  //   element: <News />,
  // },
  // {
  //   path: 'social',
  //   label: 'Social',
  //   icon: 'fa-thin fa-comments fa-lg',
  //   element: <Social />,
  // },
  // {
  //   path: 'report',
  //   label: 'Report',
  //   icon: 'fa-thin fa-file-chart-column fa-lg',
  //   element: <Report />,
  // },
  // {
  //   path: 'alerts',
  //   label: 'Alerts',
  //   icon: 'fa-thin fa-bell fa-lg',
  //   element: <Alerts />,
  // },
  // {
  //   path: 'download',
  //   label: 'Download',
  //   icon: 'fa-thin fa-file-arrow-down fa-lg',
  //   element: <Download />,
  // },
];

function Portfolio({ selectedID, selectedLayout }) {
  return (
    <Provider store={store}>
      <Router selectedID={selectedID} selectedLayout={selectedLayout} />
    </Provider>
  );
}

Portfolio.propTypes = {
  selectedID: PropTypes.string,
  selectedLayout: PropTypes.string,
};

export default Portfolio;

function Router({ selectedID, selectedLayout }) {
  const dispatch = useDispatch();
  const source = CancelToken.source();
  const selectedPortfolio = useSelector((state) => state.portfolio.selected);
  const allPortfolio = useSelector((state) => state.portfolio.all);
  const loading = useSelector((state) => state.portfolio.loading);

  useEffect(() => {
    const newLayout =
      selectedLayout?.length > 0
        ? selectedLayout
        : !selectedPortfolio.id
        ? ''
        : selectedPortfolio.classic
        ? 'trading_data'
        : 'portfolio';

    dispatch(fetchData({ selectedID, selectedLayout: newLayout, cancelToken: source.token }));
  }, []);

  if (loading) {
    return <Loader visible />;
  }

  if (!allPortfolio || allPortfolio.length === 0) {
    return <>NO PORTFOLIO FOUND!</>;
  }

  const router = createBrowserRouter([
    {
      path: '/portfolio',
      element: <Content tabs={TABS} />,
      children: [
        {
          path: ':portfolioID',
          children: TABS,
        },
      ],
    },
    {
      path: '*',
      element: <NotFound />,
    },
  ]);

  return <RouterProvider router={router} />;
}

Router.propTypes = {
  selectedID: PropTypes.string,
  selectedLayout: PropTypes.string,
};
