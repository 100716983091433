import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import Loader from '../../../components/Loader';
import APIErrorHandler from '../../../components/APIErrorHandler';
import api from '../../../utils/api';

const { CancelToken } = axios;

export default function VolumeDistribution({ counter }) {
  const source = CancelToken.source();
  const [data, setData] = useState([]);
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isSSR, setIsSSR] = useState(true);

  useEffect(() => {
    setIsSSR(typeof document === 'undefined');
    const cancelToken = source.token;
    setIsLoading(true);

    api
      .get(`quote/${counter}/price_analysis.json`, { cancelToken })
      .then(({ data }) => {
        setData(data);
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
      });
  }, []);

  if (isSSR) {
    return <Loader visible={isSSR} classes="g-height-800" />;
  }

  if (error) {
    return <APIErrorHandler error={error} />;
  }

  return (
    <>
      <h4 className="g-mb-30">Volume Distribution</h4>
      <Loader visible={isLoading} />
      {!isLoading && data && (
        <>
          <div className="row gy-4 justify-content-center g-mb-30 g-lg-mb-60">
            <div className="col-md-5 col-12">
              <div className="row gx-4">
                <div className="col-3 text-center text-md-end">
                  <i className="fa-thin fa-user-group-crown g-text-size-50 g-sm-text-size-65 g-lg-text-size-65 mt-3" />
                </div>
                <div className="col-9">
                  <h4>Big Players*</h4>
                  <div className="mb-4">{data.market_insight.bigboys_result}</div>
                  <div className="small text-muted">{data.market_insight.define_big}</div>
                </div>
              </div>
            </div>
            <div className="col-md-5 col-12">
              <div className="row gx-4">
                <div className="col-3 text-center text-md-end">
                  <i className="fa-thin fa-user-group g-text-size-50 g-sm-text-size-65 g-lg-text-size-65 mt-3" />
                </div>
                <div className="col-9">
                  <h4>Retail Investors*</h4>
                  <div className="mb-4">{data.market_insight.retail_result}</div>
                  <div className="small text-muted">{data.market_insight.define_retail}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="row gy-4 justify-content-center g-md-mb-80">
            {data.volume_distribution_chart.map((chart) => (
              <div key={chart.src} className="col-md-4 col-12">
                <div className="card card-body basic-card">
                  <h6 className="card-title.mb-0">{chart.title}</h6>
                  <div className="small text-muted mb-3 fst-italic">
                    {chart.subtitle} <br />
                  </div>
                  <div className="image mx-auto d-block">
                    <img className="h-100 w-100" src={chart.src} alt={chart.src} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      {!isLoading && !data && <div className="text-center">No data found.</div>}
    </>
  );
}

VolumeDistribution.propTypes = {
  counter: PropTypes.string,
};
