import { createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';

const consensusEstimatesSlice = createSlice({
  name: 'consensusEstimates',
  initialState: {
    data: {},
    loading: true,
    err: null,
  },
  reducers: {
    consensusEstimatesDataFetchStart(state, action) {
      state.data = null;
      state.err = null;
      state.loading = true;
    },
    consensusEstimatesDataFetchSuccess(state, action) {
      state.data = action.payload.data;
      state.loading = false;
    },
    consensusEstimatesDataFetchFail(state, action) {
      state.err = action.payload;
      state.loading = false;
    },
  },
});

export default consensusEstimatesSlice.reducer;

const {
  consensusEstimatesDataFetchStart,
  consensusEstimatesDataFetchSuccess,
  consensusEstimatesDataFetchFail,
} = consensusEstimatesSlice.actions;

export const fetchData =
  ({ counter, cancelToken }) =>
  (dispatch) => {
    dispatch(consensusEstimatesDataFetchStart());
    api
      .get(`quote/${counter}/consensus_estimates.json`, { cancelToken })
      .then(({ data }) => {
        dispatch(consensusEstimatesDataFetchSuccess({ data }));
      })
      .catch((error) => {
        dispatch(consensusEstimatesDataFetchFail(error));
      });
  };
