import React from 'react';
import PropTypes from 'prop-types';

export default function IntrinsicValue({ type, data, currency }) {
  return (
    <>
      {type === 'valuation' ? (
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between g-text-size-16 align-items-center">
              <div className="fw-bold g-text-size-24">
                <i className="fa-solid fa-chart-mixed"></i>
              </div>
              <div className="">{currency}</div>
            </div>
            <div className="d-flex justify-content-between g-text-size-16">
              <div className="fw-bold g-text-size-20">Intrinsic Value</div>
              <div className="fw-bold g-text-size-20">{data.value_f}</div>
            </div>
          </div>
        </div>
      ) : (
        <div className={data.value_f === 'n.a.' ? 'text-gray-600' : ''}>{data.value_f}</div>
      )}
    </>
  );
}

IntrinsicValue.propTypes = {
  type: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object]),
  currency: PropTypes.string,
};
