import React from 'react';

export default function Notes() {
  return (
    <>
      <div className="disclaimer text-muted small g-mt-40">
        <div className="row">
          <div className="col-auto">
            <sup className="fw-bold">a</sup>
          </div>
          <div className="col">
            <div>Estimated based on the average transaction price and volume.</div>
          </div>
        </div>
      </div>
      <div className="disclaimer text-muted small g-mt-40">
        <div className="fw-bold">Notes:</div>
        <div className="row">
          <div className="col-auto">
            <sup className="fw-bold">1</sup>
          </div>
          <div className="col">
            There is generally a delay between the announcement date and the date of effective
            change of interest. ShareInvestor updates the information as soon as the trades are
            announced by the respective listed companies.
          </div>
        </div>
        <div className="row">
          <div className="col-auto">
            <sup className="fw-bold">2</sup>
          </div>
          <div className="col">
            Please click <a href="mailto:admin@shareinvestor.com">here</a> to let us know if you
            spot any mistakes.
          </div>
        </div>
        <div className="row">
          <div className="col-auto">
            <sup className="fw-bold">3</sup>
          </div>
          <div className="col">
            Some of the data presented has been intentionally marked and ShareInvestor will not
            hesitate to prosecute to protect our intellectual property rights. Our licensing charge
            is $100 per data item per month.
          </div>
        </div>
      </div>
    </>
  );
}
