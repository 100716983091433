import React, { useEffect, useState } from 'react';
import { Provider, useDispatch } from 'react-redux';

import store from '../../utils/store';
import SignUpModal from '../SignUp/SignUpModal';
import { membershipSetUserID, membershipSetMobile } from '../SignUp/signUpSlice';

function SuccessfulRegistrationMessage() {
  return (
    <Provider store={store}>
      <SuccessfulRegistrationMessageMain />
    </Provider>
  );
}

export default SuccessfulRegistrationMessage;

function SuccessfulRegistrationMessageMain() {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(true);

  useEffect(() => {
    dispatch(membershipSetUserID('-'));
    dispatch(membershipSetMobile('-'));
  }, []);

  return <SignUpModal showModal={showModal} setShowModal={setShowModal} />;
}
