import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { deleteAlertHistories, markNewAlertHistory } from './stockNewsAlertsSlice';
import ModalDialog from '../ModalDialog';
import Loader from '../Loader';
import classes from './alert.module.css';

function AlertHistoryList({ alerts }) {
  const dispatch = useDispatch();
  const source = axios.CancelToken.source();
  const [deleteID, setDeleteID] = useState();
  const loading = useSelector((state) => state.stockNewsAlerts.loading);
  const userSetHistoryFilter = useSelector((state) => state.stockNewsAlerts.userSetHistoryFilter);
  const [isLoading, setIsloading] = useState(loading);

  const deleteHistory = (id) => {
    dispatch(deleteAlertHistories({ alertHistoryID: id, cancelToken: source.token }));
  };

  const markHistory = (objectID) => {
    dispatch(
      markNewAlertHistory({
        objectID,
        cancelToken: source.token,
      }),
    );
  };

  useEffect(() => {
    setIsloading(loading);
  }, [loading]);

  if (isLoading) {
    return <Loader visible={isLoading} classes="g-min-height-300 g-pt-100" />;
  }

  return (
    <div className="g-min-height-300">
      {alerts &&
        alerts.length > 0 &&
        alerts.map((alert, index, array) => (
          <div
            key={alert.id}
            className={`${index + 1 !== array.length ? 'g-border-b-1' : ''} alert-item p-3 ${
              parseInt(alert.alertStatus) === 1 ? '' : classes.inactiveAlert
            }`}
            id={`alertHistory-${alert.id}`}>
            <div className="d-flex gap-2 g-mb-10">
              <div className="flex-fill d-flex gap-2">
                {/* TODO: NOT AVAILBE IN V1 */}
                {/* <span className="badge badge-outlined text-bg-gray-600">Pos {index + 1}</span> */}
                {/* <span className="badge badge-outlined text-bg-warning">
                  Triggered 2 times
                </span> */}

                {parseInt(alert.alertStatus) === 1 && (
                  <button
                    type="button"
                    aria-label="seen"
                    onClick={() => {
                      markHistory(alert.objectID);
                    }}
                    className="btn btn-sm btn-info text-white g-min-width-50">
                    NEW <i className="fa-solid fa-check ms-1" />
                  </button>
                )}
              </div>

              <div className="flex-fill">
                <div className="alert-item-action d-flex justify-content-end gap-2">
                  <button
                    className="bg-transparent border-0"
                    type="button"
                    aria-label="delete"
                    onClick={() => setDeleteID(alert.id)}>
                    <i className="fa-light fa-trash-can" />
                  </button>
                </div>
              </div>
            </div>
            <div className="g-mb-10">
              <strong>Instrument Name</strong>
              <div>{alert.instName}</div>
            </div>
            <div className="g-mb-10">
              <strong>Triggered Time</strong>
              <div>{alert.dateTime}</div>
            </div>
            <div className="g-mb-10">
              <strong>Alert Content</strong>
              <div>{alert.alertContent}</div>
            </div>
            <div>
              <strong>Detail</strong>
              <div>{alert.detail}</div>
            </div>
          </div>
        ))}

      {alerts && alerts.length > 0 && (
        <ModalDialog
          show={deleteID}
          handleClose={(e) => {
            if (e) {
              e.stopPropagation();
            }
            setDeleteID(null);
          }}
          title="Delete Alert History"
          body="Are you sure you want to delete this alert history?"
          handleSave={(params, e) => {
            e.stopPropagation();
            deleteHistory(deleteID);
            setDeleteID(null);
          }}
          saveLabel="Delete"
          saveVariant="danger"
        />
      )}

      {(!alerts || alerts.length === 0) && !loading && userSetHistoryFilter && (
        <h5 className="g-mt-100 text-center text-muted">No result matching filters</h5>
      )}

      {(!alerts || alerts.length === 0) && !loading && !userSetHistoryFilter && (
        <div className="text-muted text-center g-min-height-300">
          <div className="g-pt-100">
            <div className="pb-2">
              <i className="fa-solid fa-bell-exclamation g-text-size-40" />
            </div>
            <div>No alerts have been triggered yet.</div>
            <div>When they do, you'll see a list here.</div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AlertHistoryList;
AlertHistoryList.propTypes = {
  alerts: PropTypes.oneOfType([PropTypes.array]),
  loading: PropTypes.bool,
};
