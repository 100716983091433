import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';

import { createLoadableComponent } from '../../utils/Loadable';

const PriceChart = createLoadableComponent(() => import('../../components/PriceChart'));

export default function IndexPriceChart() {
  const counter = useSelector((state) => state.home.marketsSection.index);
  const isIntradayChart = useSelector((state) => state.home.marketsSection.isIntradayChart);
  const dataURL = '/api/v1/charts/index_chart_data';
  const options = {
    showZoomOptions: true,
    showChartTypeOptions: true,
    hasIntradayData: isIntradayChart,
    chartClasses: 'g-height-276',
  };

  return <PriceChart counter={counter} dataURL={dataURL} options={options} />;
}
