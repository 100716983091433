import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import axios from 'axios';
import api from '../../utils/api';
import Loader from '../Loader';

const { CancelToken } = axios;

export default function SelectFolderWatchlistPortfolio({
  folderType,
  activeFolder,
  handleOnChangeFolder,
}) {
  const source = CancelToken.source();
  const [folders, setFolders] = useState();
  const [selectedFolder, setSelectedFolder] = useState(activeFolder);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const cancelToken = source.token;
    const url = folderType === 'watchlist' ? 'watchlist.json' : 'portfolio.json';
    setLoading(true);
    api
      .get(url, { cancelToken })
      .then(({ data }) => {
        setFolders(data.allFolders);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [folderType]);

  useEffect(() => {
    setSelectedFolder(activeFolder);
  }, [activeFolder]);

  if (loading) {
    return (
      <span className="d-inline-block">
        <Loader visible={loading} />
      </span>
    );
  }

  return (
    <Dropdown>
      <Dropdown.Toggle variant="light" className="w-100">
        <span className="g-mr-15">{selectedFolder?.name || 'Please select'}</span>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {folders
          ?.filter((folder) => folder.id !== selectedFolder?.id)
          .map((folder) => {
            return (
              <Dropdown.Item
                to={folder.id.toString()}
                key={folder.id}
                value={folder.id}
                onClick={handleOnChangeFolder}>
                <span>{folder.name}</span>
              </Dropdown.Item>
            );
          })}
      </Dropdown.Menu>
    </Dropdown>
  );
}

SelectFolderWatchlistPortfolio.propTypes = {
  folderType: PropTypes.string,
  activeFolder: PropTypes.oneOfType([PropTypes.object]),
  handleOnChangeFolder: PropTypes.func,
};
