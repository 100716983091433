import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Row, Col, Container } from 'react-bootstrap';
import api from '../../../utils/api';
import UserSettingsMain from '../../User/UserSettings/UserSettingsMain';
import styles from './membership.module.css';

function Setting() {
  const [settings, setSettings] = useState({});
  const [loading, setLoading] = useState(true);

  const { CancelToken } = axios;

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        setLoading(true);
        const response = await api.get('user/settings', {}, { CancelToken });
        setSettings(response.data);
      } catch (error) {
        console.error('Error: ', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSettings();
  }, []);

  let innerComponent;

  if (loading) {
    innerComponent = 'Loading...';
  } else if (settings && settings.success && settings.data) {
    innerComponent = (
      <UserSettingsMain
        settings={settings.data}
        thirdPartyAuthApps={settings.data.connected_apps}
      />
    );
  } else {
    innerComponent = 'No data available';
  }
  return (
    <Container className={styles.membershipContainer}>
      <Row>
        <Col>{innerComponent}</Col>
      </Row>
    </Container>
  );
}

export default Setting;
