import React from 'react';
import Modal from 'react-bootstrap/Modal';
import finMiniBar from 'images/fin_mini_bar.jpg';

function FinancialMiniBar(props) {
  return (
    <div>
      <Modal size="lg" centered show={props.showMiniBar} onHide={props.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          Financials Mini Bars
        </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div align="center">
          <img src={finMiniBar} alt="Financial Mini Bar" />
          </div><p/>
          <div>
            The Financials Mini Bars represents the values of each line item in the Profit & Loss, Balance Sheet, Cash Flow and Financial Ratios over different periods in the table.
            <p/>
            Positive numbers are represented by green bars while negative numbers are represented by red bars.
            <p/>
            The Financials Mini Bars allows you to
            <ol type="1">
              <li>easily notice trends better than in a table.</li>
              <li>get an estimate of how the values compare relative to each other among the different periods at a glance.</li>
            </ol>
          </div>
        </Modal.Body>
      </Modal>
    </div>
    );
}

export default FinancialMiniBar;
