import PropTypes from 'prop-types';

import { getParamCounterId } from '../../utils/utils';

function Redirect({ url }) {
  const { hash } = window.location;

  if (!hash || !url || !url.includes('-COUNTER-')) {
    window.location.replace('/404');
    return;
  }

  const queryString = hash.split('?')[1];
  const parser = new DOMParser();
  const doc = parser.parseFromString(queryString, 'text/html');
  const params = new URLSearchParams(doc.documentElement.textContent);
  const type = params.get('type');

  if (!params.get('counter')) {
    window.location.replace('/404');
    return;
  }

  const counter = getParamCounterId(params.get('counter'));
  if (url.includes('c2_charts') && type === 'volume_distribution') {
    window.location.replace(`/quote/${counter}/price_analysis/volume_distribution`);
  } else {
    window.location.replace(url.replace('-COUNTER-', counter));
  }
}

Redirect.propTypes = {
  url: PropTypes.string,
};

export default Redirect;
