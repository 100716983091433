import axios from 'axios';
import React, { useEffect, useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';

import { useSearchParams, useLocation } from 'react-router-dom';

import api from '../../../utils/api';
import { createLoadableComponent } from '../../../utils/Loadable';
import Loader from '../../../components/Loader';
import APIErrorHandler from '../../../components/APIErrorHandler';
import './statisticAndTableNotes.css';
import NoteSection from './NoteSection';

const TradeSummaryMatrixTable = createLoadableComponent(() => import('./TradeSummaryMatrixTable'));
const Statistics = createLoadableComponent(() => import('./Statistics'));
const { CancelToken } = axios;
const NUMBER_OF_DAYS = 1;
const NUMBER_OF_DAYS_RANGE = 10;
const DATE = new Date().toISOString().split('T')[0]; // yyyy-mm-dd format

const findPairByDate = (dataArray, searchDate) => {
  const formattedSearchDate = searchDate;
  return dataArray.find((pair) => pair[1] === formattedSearchDate) || null;
};

function TradeSummaryMatrixTab({ counter, toggleFactsheetNav }) {
  const source = CancelToken.source();
  const [searchParams, setSearchParams] = useSearchParams();
  const [error, setError] = useState();
  const [date, setDate] = useState(searchParams.get('date') || DATE);
  const [numberOfDays, setNumberOfDays] = useState(
    parseInt(searchParams.get('date_no')) || NUMBER_OF_DAYS,
  );

  const [recentDaysDropdown, setRecentDaysDropdown] = useState([]);
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const [isSSR, setIsSSR] = useState(true);

  const getCurrentParams = () => {
    const params = {};

    if (numberOfDays) {
      params.date_no = numberOfDays;
    }
    if (date) {
      params.date = date;
    }

    return params;
  };

  const handleNumberOfDays = (newDate) => {
    if (numberOfDays === newDate) {
      return;
    }

    setSearchParams({ ...getCurrentParams(), date_no: newDate });
    setNumberOfDays(newDate);
  };

  const handleDateChange = (newDate) => {
    if (date === newDate) {
      return;
    }
    setSearchParams({ ...getCurrentParams(), date: newDate });
    setDate(newDate);
  };

  useEffect(() => {
    const cancelToken = source.token;
    setIsLoading(true);

    const url = `quote/${counter}/trade_summary_matrix.json?date=${date}&date_no=${numberOfDays}`;

    api
      .get(url, { cancelToken })
      .then(({ data }) => {
        setData(data.data);
        setRecentDaysDropdown(data.recent_days_dropdown);
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
      });
  }, [date, numberOfDays]);

  useEffect(() => {
    if (date !== searchParams.get('date')) {
      setDate(searchParams.get('date') || DATE);
    }
    if (numberOfDays !== parseInt(searchParams.get('date_no'))) {
      setNumberOfDays(parseInt(searchParams.get('date_no')) || NUMBER_OF_DAYS);
    }
  }, [location]);

  useEffect(() => {
    setIsSSR(typeof document === 'undefined');

    return () => {};
  }, []);

  if (isSSR) {
    return <Loader visible={isSSR} classes="g-height-800" />;
  }

  if (error) {
    return <APIErrorHandler error={error} />;
  }

  return (
    <>
      <h4 className="g-md-mb-30">Trade Summary Matrix</h4>
      <Loader visible={isLoading} />

      <div className="row">
        <div className="col-xl-6 col-12 align-items-center mb-2 d-flex gap-3">
          {!isLoading && recentDaysDropdown && recentDaysDropdown.length > 0 && (
            <DateDropdownComponent
              selectedDate={
                recentDaysDropdown.length > 0 ? findPairByDate(recentDaysDropdown, date) : null
              }
              recentDaysDropdown={recentDaysDropdown}
              date={date}
              handleDateChange={handleDateChange}
            />
          )}
          {!isLoading && numberOfDays && (
            <div className="d-flex gap-3 align-items-center">
              <span className="g-mb-10">Consolidate TSM for</span>
              <Dropdown className="g-mb-10">
                <Dropdown.Toggle variant="light" size="lg">
                  {numberOfDays} {numberOfDays === 1 ? 'Day' : 'Days'}
                </Dropdown.Toggle>
                <div className="d-flex gap-2 flex-wrap">
                  <Dropdown.Menu>
                    {Array.from({ length: NUMBER_OF_DAYS_RANGE }, (_, index) => index + 1).map(
                      (day) => (
                        <Dropdown.Item
                          key={day}
                          onClick={() => handleNumberOfDays(day)}
                          className={`${day === numberOfDays ? 'active' : ''}`}>
                          {day} {day === 1 ? 'Day' : 'Days'}
                        </Dropdown.Item>
                      ),
                    )}
                  </Dropdown.Menu>
                </div>
              </Dropdown>
            </div>
          )}
        </div>
        <div className="col-xl-6 justify-content-xl-end col-12 gap-2  d-flex flex-column flex-sm-row  align-items-sm-center mb-2">
          {!isLoading && <Statistics counter={counter} date={date} />}
        </div>
      </div>

      {!isLoading && data && data.result.length > 0 && (
        <>
          <TradeSummaryMatrixTable
            data={data.result}
            total={data.result_total}
            toggleFactsheetNav={toggleFactsheetNav}
          />
          <NoteSection type="trade_summary_matrix" />
        </>
      )}
      {!isLoading && (!data || data.length === 0) && (
        <div className="text-center">No data found.</div>
      )}
    </>
  );
}

TradeSummaryMatrixTab.propTypes = {
  counter: PropTypes.string,
  priceDownloadConfig: PropTypes.shape({
    recentPriceDays: PropTypes.oneOfType([PropTypes.array]),
    hasIntradayData: PropTypes.bool,
    isSgMyMarket: PropTypes.bool,
  }),
  toggleFactsheetNav: PropTypes.func,
};

export default TradeSummaryMatrixTab;

function DateDropdownComponent({ selectedDate, recentDaysDropdown, date, handleDateChange }) {
  return (
    <Dropdown className="g-mb-10">
      <Dropdown.Toggle variant="light" size="lg">
        {selectedDate ? selectedDate[0] : 'Select Date'}
      </Dropdown.Toggle>
      <div className="d-flex gap-2 flex-wrap">
        <Dropdown.Menu>
          {recentDaysDropdown.map((day) => (
            <Dropdown.Item
              key={day[1]}
              onClick={() => handleDateChange(day[1])}
              className={`${day[1] === date ? 'active' : ''}`}>
              {day[0]}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </div>
    </Dropdown>
  );
}

DateDropdownComponent.propTypes = {
  selectedDate: PropTypes.arrayOf(PropTypes.string),
  recentDaysDropdown: PropTypes.oneOfType([PropTypes.array]),
  date: PropTypes.string.isRequired,
  handleDateChange: PropTypes.func.isRequired,
};
