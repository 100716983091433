import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import store from '../../utils/store';
import NewsMain from './NewsMain';

function News({
  basepath,
  availableMarketsAndNews,
  newsTabsInfo,
  newsTabs,
  market,
  newsType,
  counter,
  fromDate,
  toDate,
  category,
}) {
  return (
    <Provider store={store}>
      <NewsMain
        basepath={basepath}
        availableMarketsAndNews={availableMarketsAndNews}
        newsTabsInfo={newsTabsInfo}
        newsTabs={newsTabs}
        market={market}
        newsType={newsType}
        counter={counter}
        fromDate={fromDate}
        toDate={toDate}
        category={category}
      />
    </Provider>
  );
}

News.propTypes = {
  basepath: PropTypes.string,
  availableMarketsAndNews: PropTypes.oneOfType([PropTypes.object]),
  newsTabsInfo: PropTypes.oneOfType([PropTypes.object]),
  newsTabs: PropTypes.oneOfType([PropTypes.array]),
  market: PropTypes.string,
  newsType: PropTypes.string,
  counter: PropTypes.string,
  fromDate: PropTypes.string,
  toDate: PropTypes.string,
  category: PropTypes.string,
};

export default News;
