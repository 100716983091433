import React, { useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Accordion from 'react-bootstrap/Accordion';

import watchlistImg from 'images/img-home-features-list-watchlist.png';
import portfolioImg from 'images/img-home-features-list-portfolio.png';
import factsheetImg from 'images/img-home-features-list-factsheet.png';
import chartsImg from 'images/img-home-features-list-charts.png';
import shareholdersImg from 'images/img-home-features-list-shareholders.png';
import consensusEstimateImg from 'images/img-home-features-list-consensus-estimates.png';
import insiderTradesImg from 'images/img-home-features-list-insider-trades.png';
import { useDetectMobileScreen } from '../../utils/utils';

function FeaturesList() {
  const isMobile = useDetectMobileScreen();
  const [activeTab, setActiveTab] = useState('factsheet');
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const activeColor = ['#4bc264', '#aaaaaa'];
  const featuresTabs = [
    {
      key: 'watchlist',
      nav_title: 'Watchlist',
      new: true,
      nav_body: (
        <div>
          Track your favourite stocks, receive real-time updates, and stay informed on market
          movements.
        </div>
      ),
      nav_icon: (
        <svg xmlns="http://www.w3.org/2000/svg" height="27" width="27" viewBox="0 0 384 512">
          <path d="M48 448V64c0-8.8 7.2-16 16-16H224v80c0 17.7 14.3 32 32 32h80V448c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16zM64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V154.5c0-17-6.7-33.3-18.7-45.3L274.7 18.7C262.7 6.7 246.5 0 229.5 0H64zM216 280c0-13.3-10.7-24-24-24s-24 10.7-24 24V408c0 13.3 10.7 24 24 24s24-10.7 24-24V280zm88 32c0-13.3-10.7-24-24-24s-24 10.7-24 24v96c0 13.3 10.7 24 24 24s24-10.7 24-24V312zM128 344c0-13.3-10.7-24-24-24s-24 10.7-24 24v64c0 13.3 10.7 24 24 24s24-10.7 24-24V344z" />
        </svg>
      ),
      tab_image: watchlistImg,
    },
    {
      key: 'portfolio',
      nav_title: 'Portfolio',
      new: true,
      nav_body: (
        <div>
          Gain a thorough understanding of your portfolio's performance through comprehensive
          analysis.
        </div>
      ),
      nav_icon: (
        <svg xmlns="http://www.w3.org/2000/svg" height="27" width="27" viewBox="0 0 384 512">
          <path d="M320 464c8.8 0 16-7.2 16-16V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320zM0 64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zM160 240.1c8.7-1.5 16 5.8 16 14.7V320c0 8.8 7.2 16 16 16h64c8.9 0 16.3 7.4 14.6 16.1c-.5 2.5-1 5-1.7 7.4c-13.7 51.2-66.4 81.6-117.6 67.9s-81.6-66.4-67.9-117.6c10-37.4 40.8-63.7 76.5-69.8zm63.9-30.8c40.3 6.7 72 38.5 78.8 78.8c1.5 8.7-5.8 15.9-14.7 15.9H224c-8.8 0-16-7.2-16-16V224c0-8.8 7.2-16.1 15.9-14.7z" />
        </svg>
      ),
      tab_image: portfolioImg,
    },
    {
      key: 'factsheet',
      nav_title: 'Factsheet',
      nav_body: (
        <div>
          Discover a listed company's complete analysis, covering financial performance, price
          analysis, consensus estimates, insider trades, shareholders, dividend analysis, and more.
        </div>
      ),
      nav_icon: (
        <svg xmlns="http://www.w3.org/2000/svg" height="27" width="27" viewBox="0 0 576 512">
          <path d="M408 0c-13.3 0-24 10.7-24 24s10.7 24 24 24h52.5L320.2 168.2 207.8 69.9c-8.2-7.1-20.1-7.9-29.1-1.9L10.7 180c-11 7.4-14 22.3-6.7 33.3s22.3 14 33.3 6.7L190 118.2l114.2 99.9c9 7.9 22.4 7.9 31.4 .2L496 80.7V136c0 13.3 10.7 24 24 24s24-10.7 24-24V24c0-13.3-10.7-24-24-24H408zM200 432c0 4.4-3.6 8-8 8s-8-3.6-8-8V272c0-4.4 3.6-8 8-8s8 3.6 8 8V432zm-8-208c-26.5 0-48 21.5-48 48V432c0 26.5 21.5 48 48 48s48-21.5 48-48V272c0-26.5-21.5-48-48-48zM72 432c0 4.4-3.6 8-8 8s-8-3.6-8-8V368c0-4.4 3.6-8 8-8s8 3.6 8 8v64zM64 320c-26.5 0-48 21.5-48 48v64c0 26.5 21.5 48 48 48s48-21.5 48-48V368c0-26.5-21.5-48-48-48zM432 512a144 144 0 1 0 0-288 144 144 0 1 0 0 288zM408.1 339l0 .1c0 .4 0 1.9 4.8 4.5c5.6 3 13.5 5.2 23.9 8.2l.2 0c9.3 2.6 21.1 6 30.5 11.5c10.2 6 20 16.1 20.5 32.3c.5 18.2-9.7 30.4-21.7 36.9c-5.8 3.1-12.1 5.1-18.3 6.2v10.8c0 8.8-7.2 16-16 16s-16-7.2-16-16V438.1c-8.6-1.7-16.7-4.3-23.7-6.6l0 0 0 0c-1.7-.6-3.4-1.1-5-1.6c-8.4-2.6-13.2-11.6-10.5-20s11.6-13.2 20-10.5c2 .6 3.9 1.2 5.8 1.8c11.4 3.6 20.4 6.5 29.9 6.8c6.7 .2 13.8-1.1 18.5-3.6c2.2-1.2 3.4-2.4 4-3.3c.5-.8 1.1-2.1 1-4.5c0-1.4-.2-3-4.7-5.6c-5.3-3.1-12.9-5.4-23.2-8.3l-1.8-.5c-8.9-2.5-19.8-5.6-28.6-10.3c-9.9-5.3-20.6-14.7-21.6-30.7c-1.2-18.8 10-30.9 21.8-37.2c5.7-3.1 12-5.1 18-6.3V288c0-8.8 7.2-16 16-16s16 7.2 16 16v9.4c6.3 .9 12.3 2.3 17.9 3.7c8.6 2.1 13.8 10.8 11.7 19.4s-10.8 13.8-19.4 11.7c-9.3-2.3-18.2-4-26.4-4.1c-6.2-.1-13.6 1.3-18.7 4c-2.4 1.3-3.6 2.5-4.2 3.4c-.4 .7-.9 1.6-.8 3.6z" />
        </svg>
      ),
      tab_image: factsheetImg,
    },
    {
      key: 'charts',
      nav_title: 'Charts',
      nav_body: <div>Conduct technical analysis using technical charts and indicators.</div>,
      nav_icon: (
        <svg xmlns="http://www.w3.org/2000/svg" height="27" width="27" viewBox="0 0 448 512">
          <path d="M240 80V432H208V80h32zM208 32c-26.5 0-48 21.5-48 48V432c0 26.5 21.5 48 48 48h32c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48H208zM80 272V432H48V272H80zM48 224c-26.5 0-48 21.5-48 48V432c0 26.5 21.5 48 48 48H80c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48H48zm320-80h32V432H368V144zm-48 0V432c0 26.5 21.5 48 48 48h32c26.5 0 48-21.5 48-48V144c0-26.5-21.5-48-48-48H368c-26.5 0-48 21.5-48 48z" />
        </svg>
      ),
      tab_image: chartsImg,
    },
    {
      key: 'shareholders',
      nav_title: 'Shareholders',
      nav_body: (
        <div>
          Discover companies' shareholders with actionable insights. Access company updates and
          shareholder resources.{' '}
        </div>
      ),
      nav_icon: (
        <svg xmlns="http://www.w3.org/2000/svg" height="27" width="27" viewBox="0 0 640 512">
          <path d="M576 48H224c-8.8 0-16 7.2-16 16v41.3c-14.8-6-31-9.3-48-9.3V64c0-35.3 28.7-64 64-64H576c35.3 0 64 28.7 64 64V352c0 35.3-28.7 64-64 64H336.8c-8.3-18-19.8-34.2-33.7-48H576c8.8 0 16-7.2 16-16V64c0-8.8-7.2-16-16-16zM412.7 139.3c-4.6-4.6-5.9-11.5-3.5-17.4s8.3-9.9 14.8-9.9h88c8.8 0 16 7.2 16 16v88c0 6.5-3.9 12.3-9.9 14.8s-12.9 1.1-17.4-3.5l-27-27L401 273c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47 55.7-55.7-27-27zM160 272a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm0-144a96 96 0 1 1 0 192 96 96 0 1 1 0-192zM133.3 400c-39.8 0-73.2 27.2-82.6 64H269.3c-9.5-36.8-42.9-64-82.6-64H133.3zm0-48h53.3C260.3 352 320 411.7 320 485.3c0 14.7-11.9 26.7-26.7 26.7H26.7C11.9 512 0 500.1 0 485.3C0 411.7 59.7 352 133.3 352z" />
        </svg>
      ),
      tab_image: shareholdersImg,
    },
    {
      key: 'consensus_estimate',
      nav_title: 'Consensus Estimate',
      nav_body: <div>Projected stock prices and valuations based on analysts' estimates.</div>,
      nav_icon: (
        <svg xmlns="http://www.w3.org/2000/svg" height="27" width="27" viewBox="0 0 512 512">
          <path d="M208 48a160 160 0 1 1 0 320 160 160 0 1 1 0-320zm0 368c48.8 0 93.7-16.8 129.1-44.9L471 505c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9L371.1 337.1C399.2 301.7 416 256.8 416 208C416 93.1 322.9 0 208 0S0 93.1 0 208S93.1 416 208 416zM104 216v64c0 13.3 10.7 24 24 24s24-10.7 24-24l0-64c0-13.3-10.7-24-24-24s-24 10.7-24 24zm80-96V280c0 13.3 10.7 24 24 24s24-10.7 24-24V120c0-13.3-10.7-24-24-24s-24 10.7-24 24zm80 64v96c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24s-24 10.7-24 24z" />
        </svg>
      ),
      tab_image: consensusEstimateImg,
    },
    {
      key: 'insider_trades',
      nav_title: 'Insider Trades',
      nav_body: (
        <div>
          Monitor insider transactions, track buying and selling trends, and stay ahead with timely
          alerts.
        </div>
      ),
      nav_icon: (
        <svg xmlns="http://www.w3.org/2000/svg" height="27" width="27" viewBox="0 0 640 512">
          <path d="M496 192c-46.8 0-89.3 18.2-120.8 48H272V16.6c0-9 7-16.6 16-16.6C401 0 494.5 83.7 509.8 192.5c-4.6-.4-9.2-.5-13.8-.5zM272 288h67.2c-7.6 14.9-13.2 31-16.3 48H224c-26.5 0-48-21.5-48-48V90.9C101.4 117.3 48 188.4 48 272c0 106 86 192 192 192c34.5 0 66.9-9.1 94.9-25c6.5 14.8 15 28.6 25.2 40.9C324.8 500.3 283.8 512 240 512C107.5 512 0 404.6 0 272C0 156.5 81.5 60.1 190.2 37.2c18.1-3.8 33.8 11 33.8 29.5V240v48h48zm80 80a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm120.8-32.6c.6-.9 1.8-2.1 4.2-3.4c5.1-2.7 12.5-4.1 18.7-4c8.2 .1 17.1 1.8 26.4 4.1c8.6 2.1 17.3-3.1 19.4-11.7s-3.1-17.3-11.7-19.4c-5.6-1.4-11.6-2.7-17.9-3.7V288c0-8.8-7.2-16-16-16s-16 7.2-16 16v9.5c-6.1 1.2-12.3 3.2-18 6.3c-11.8 6.3-23 18.4-21.8 37.2c1 16 11.7 25.3 21.6 30.7c8.8 4.7 19.7 7.8 28.6 10.3l1.8 .5c10.3 2.9 17.9 5.2 23.2 8.3c4.5 2.7 4.7 4.2 4.7 5.6c.1 2.4-.5 3.7-1 4.5c-.6 1-1.8 2.2-4 3.3c-4.7 2.5-11.8 3.8-18.5 3.6c-9.5-.3-18.5-3.1-29.9-6.8c-1.9-.6-3.8-1.2-5.8-1.8c-8.4-2.6-17.4 2.1-20 10.5s2.1 17.4 10.5 20c1.6 .5 3.3 1 5 1.6l0 0 0 0c7 2.3 15.1 4.8 23.7 6.6v11.4c0 8.8 7.2 16 16 16s16-7.2 16-16V438.7c6.2-1.1 12.5-3.1 18.3-6.2c12.1-6.5 22.3-18.7 21.7-36.9c-.5-16.2-10.3-26.3-20.5-32.3c-9.4-5.6-21.2-8.9-30.5-11.5l-.2 0c-10.4-2.9-18.3-5.2-23.9-8.2c-4.8-2.6-4.8-4-4.8-4.5l0-.1c-.1-1.9 .3-2.9 .8-3.6z" />
        </svg>
      ),
      tab_image: insiderTradesImg,
    },
  ];

  return (
    <div className="featuresList page-break-inside-avoid">
      {isMobile ? (
        <AccordionFormat
          featuresTabs={featuresTabs}
          activeTab={activeTab}
          handleTabClick={handleTabClick}
          activeColor={activeColor}
        />
      ) : (
        <TabFormat
          featuresTabs={featuresTabs}
          activeTab={activeTab}
          handleTabClick={handleTabClick}
          activeColor={activeColor}
        />
      )}
    </div>
  );
}

export default FeaturesList;

function TabFormat({ featuresTabs, activeTab, handleTabClick, activeColor }) {
  return (
    <Tab.Container defaultActiveKey={activeTab}>
      <div className="row">
        <div className="col-lg-4 col-xl-3">
          <Nav variant="pills" className="flex-column">
            {featuresTabs.map((tab) => {
              return (
                <Nav.Item key={tab.key} className="g-mb-15">
                  <Nav.Link eventKey={tab.key} onClick={() => handleTabClick(tab.key)}>
                    <div className="row gx-3">
                      <div className="col-auto">
                        {React.cloneElement(tab.nav_icon, {
                          style: {
                            fill: activeTab === tab.key ? activeColor[0] : activeColor[1],
                          },
                        })}
                      </div>
                      <div className="col">
                        <div
                          className={`${
                            activeTab === tab.key ? 'text-primary' : 'text-white'
                          } g-fw-500 g-text-size-20`}>
                          {tab.nav_title}
                          {tab.new && (
                            <span className="badge badge-sm bg-red-300 ms-2 align-middle">NEW</span>
                          )}
                        </div>
                        <div
                          className={`${
                            activeTab === tab.key ? 'd-block ' : 'd-none'
                          } text-white g-mt-10`}>
                          {tab.nav_body}
                        </div>
                      </div>
                    </div>
                  </Nav.Link>
                </Nav.Item>
              );
            })}
          </Nav>
        </div>
        <div className="col-lg-8 col-xl-9">
          <Tab.Content>
            {featuresTabs.map((tab) => {
              return (
                <Tab.Pane key={tab.key} eventKey={tab.key} className="text-center">
                  <img src={tab.tab_image} alt={tab.nav_title} className="img-fluid" />
                </Tab.Pane>
              );
            })}
          </Tab.Content>
        </div>
      </div>
    </Tab.Container>
  );
}

function AccordionFormat({ featuresTabs, activeTab, handleTabClick, activeColor }) {
  return (
    <Accordion defaultActiveKey={activeTab} flush>
      {featuresTabs.map((tab) => {
        return (
          <Accordion.Item
            eventKey={tab.key}
            className={activeTab === tab.key ? 'active rounded' : 'rounded'}>
            <Accordion.Header onClick={() => handleTabClick(tab.key)}>
              <div className="row gx-3">
                <div className="col-auto">
                  {React.cloneElement(tab.nav_icon, {
                    style: {
                      fill: activeTab === tab.key ? activeColor[0] : activeColor[1],
                    },
                  })}
                </div>
                <div className="col">
                  <div
                    className={`${
                      activeTab === tab.key ? 'text-primary' : 'text-white'
                    } g-fw-500 g-text-size-20`}>
                    {tab.nav_title}
                    {tab.new && (
                      <span className="badge badge-sm bg-red-300 ms-2 align-middle">NEW</span>
                    )}
                  </div>
                  <div
                    className={`${
                      activeTab === tab.key ? 'd-block ' : 'd-none'
                    } text-white g-mt-10`}>
                    {tab.nav_body}
                  </div>
                </div>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <img src={tab.tab_image} alt={tab.nav_title} className="img-fluid" />
            </Accordion.Body>
          </Accordion.Item>
        );
      })}
    </Accordion>
  );
}
