import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Card, Alert } from 'react-bootstrap';

import { CSVLink } from 'react-csv';
import { createLoadableComponent } from '../../../../utils/Loadable';
import { changeTextClass, formatNumberAddCommas, formatDecimal } from '../../../../utils/utils';
import { NavComponent } from '../../../../components/PageLocalNav';
import Loader from '../../../../components/Loader';
import PositionsTreemapChart from './PositionsTreemapChart';
import { LayoutOptions, LastUpdatedAt } from '../../../../components/PriceTable';
import EditCounterNotesModal from './EditCounterNotesModal';
import ClosePositionModal from './ClosePositionModal';
import { changeSelectedLayout } from '../../portfolioSlice';
import AddToInstruments from './AddToInstruments';
import { removeFromInstruments } from './portfolioInstrumentsSlice';
import ModalDialog from '../../../../components/ModalDialog';
import APIErrorHandler from '../../../../components/APIErrorHandler';
import EditCustomLayout from '../../../../components/PriceTable/EditCustomLayout';

const { CancelToken } = axios;
const PriceTable = createLoadableComponent(() => import('../../../../components/PriceTable'));

const tabOpen = 'open';
const TABS = [
  { key: tabOpen, label: 'Open', path: '#' },
  { key: 'closed', label: 'Closed', path: '#' },
  { key: 'summary', label: 'Summary', path: '#' },
];

const PORTFOLIO_COUNTERS_DATA_HEADERS = [
  'asset_category',
  'Shares Held',
  'Average Price (Unrealized)',
  'Cost, Unrealized',
  'Proceeds, Unrealized',
  'P/L, Unrealized',
  '%P/L, Unrealized',
  'Shares, Realized',
  'Average Price (Realized)',
  'Cost, Realized',
  'Proceeds, Realized',
  'P/L, Realized',
  '%P/L, Realized',
  'Total Shares',
  'Average Price (Overall)',
  'Total Buy Cost',
  'Total Sell Proceeds',
  'Total P/L',
  'Total % P/L',
  'Total Dividend',
  'Notes',
];

function extractOpenPositionsData(counters) {
  if (!(counters && counters.length > 0)) {
    return [];
  }

  const countersTotalValue = counters.reduce((accumulator, c) => {
    const value = parseFloat(c['Proceeds, Unrealized']?.replaceAll(',', '')) || 0;
    return accumulator + value;
  }, 0);

  return counters.map((c) => {
    const value = parseFloat(c['Proceeds, Unrealized']?.replaceAll(',', '')) || 0;
    const weightage = countersTotalValue > 0 ? value / countersTotalValue : null;
    const averagePrice = parseFloat(c['Average Price (Unrealized)']?.replaceAll(',', '')) || 0;
    const chg =
      averagePrice > 0 ? (parseFloat(c['Last Done']?.replaceAll(',', '')) || 0) - averagePrice : 0;
    const percentChg = averagePrice > 0 ? chg / averagePrice : 0;
    const plPlusDiv =
      (parseFloat(c['P/L, Unrealized']?.replaceAll(',', '')) || 0) +
      (parseFloat(c['Total Dividend']?.replaceAll(',', '')) || 0);
    const cData = {};
    cData.No = c.No;
    cData.Name = c.Name;
    cData.counter = c.counter;
    cData.Weightage = weightage
      ? `${formatNumberAddCommas(formatDecimal(weightage * 100, 2, true))}%`
      : '-';
    cData['Average Price'] = c['Average Price (Unrealized)'];
    cData['Last Price'] = c['Last Done'];
    cData.Chg = chg
      ? `${formatNumberAddCommas(formatDecimal(chg, 3, true))} (${
          chg > 0 ? '+' : ''
        }${formatNumberAddCommas(formatDecimal(percentChg * 100, 2, true))}%)`
      : '-';
    cData.Share = c['Shares Held'];
    cData['P/L'] = `${c['P/L, Unrealized']} (${c['%P/L, Unrealized']})`;
    cData.Div = c['Total Dividend'];
    cData['PL + Div'] = plPlusDiv ? formatNumberAddCommas(formatDecimal(plPlusDiv, 2, true)) : '-';
    cData.Cost = c['Cost, Unrealized'];
    cData.Value = value ? formatNumberAddCommas(formatDecimal(value, 2, true)) : '-';
    return cData;
  });
}

function extractClosedPositionsData(counters) {
  if (!(counters && counters.length > 0)) {
    return [];
  }

  const countersTotalValue = counters.reduce((accumulator, c) => {
    const value = parseFloat(c['Proceeds, Realized']?.replaceAll(',', '')) || 0;
    return accumulator + value;
  }, 0);

  return counters.map((c) => {
    const value = parseFloat(c['Proceeds, Realized']?.replaceAll(',', '')) || 0;
    const weightage = countersTotalValue > 0 ? value / countersTotalValue : null;
    const averagePrice = parseFloat(c['Average Price (Realized)']?.replaceAll(',', '')) || 0;
    const chg =
      averagePrice > 0 ? (parseFloat(c['Last Done']?.replaceAll(',', '')) || 0) - averagePrice : 0;
    const percentChg = averagePrice > 0 ? chg / averagePrice : 0;
    const plPlusDiv =
      (parseFloat(c['P/L, Realized']?.replaceAll(',', '')) || 0) +
      (parseFloat(c['Total Dividend']?.replaceAll(',', '')) || 0);
    const cData = {};
    cData.No = c.No;
    cData.Name = c.Name;
    cData.counter = c.counter;
    cData.Weightage = weightage
      ? `${formatNumberAddCommas(formatDecimal(weightage * 100, 2, true))}%`
      : '-';
    cData['Average Price'] = c['Average Price (Realized)'];
    cData['Last Price'] = c['Last Done'];
    cData.Chg = chg
      ? `${formatNumberAddCommas(formatDecimal(chg, 3, true))} (${
          chg > 0 ? '+' : ''
        }${formatNumberAddCommas(formatDecimal(percentChg * 100, 2, true))}%)`
      : '-';
    cData.Share = c['Shares, Realized'];
    cData['P/L'] = `${c['P/L, Realized']} (${c['%P/L, Realized']})`;
    cData.Div = c['Total Dividend'];
    cData['PL + Div'] = plPlusDiv ? formatNumberAddCommas(formatDecimal(plPlusDiv, 2, true)) : '-';
    cData.Cost = c['Cost, Realized'];
    cData.Value = value ? formatNumberAddCommas(formatDecimal(value, 2, true)) : '-';
    return cData;
  });
}

function extractAllPositionsData(counters) {
  if (!(counters && counters.length > 0)) {
    return [];
  }

  const countersTotalValue = counters.reduce((accumulator, c) => {
    const value = parseFloat(c['Total Sell Proceeds']?.replaceAll(',', '')) || 0;
    return accumulator + value;
  }, 0);

  return counters.map((c) => {
    const value = parseFloat(c['Total Sell Proceeds']?.replaceAll(',', '')) || 0;
    const weightage = countersTotalValue > 0 ? value / countersTotalValue : null;
    const averagePrice = parseFloat(c['Average Price (Overall)']?.replaceAll(',', '')) || 0;
    const chg =
      averagePrice > 0 ? (parseFloat(c['Last Done']?.replaceAll(',', '')) || 0) - averagePrice : 0;
    const percentChg = averagePrice > 0 ? chg / averagePrice : 0;
    const plPlusDiv =
      (parseFloat(c['Total P/L']?.replaceAll(',', '')) || 0) +
      (parseFloat(c['Total Dividend']?.replaceAll(',', '')) || 0);
    const cData = {};
    cData.No = c.No;
    cData.Name = c.Name;
    cData.counter = c.counter;
    cData.Weightage = weightage
      ? `${formatNumberAddCommas(formatDecimal(weightage * 100, 2, true))}%`
      : '-';
    cData['Average Price'] = c['Average Price (Overall)'];
    cData['Last Price'] = c['Last Done'];
    cData.Chg = chg
      ? `${formatNumberAddCommas(formatDecimal(chg, 3, true))} (${
          chg > 0 ? '+' : ''
        }${formatNumberAddCommas(formatDecimal(percentChg * 100, 2, true))}%)`
      : '-';
    cData.Share = c['Total Shares'];
    cData['P/L'] = `${c['Total P/L']} (${c['Total % P/L']})`;
    cData.Div = c['Total Dividend'];
    cData['PL + Div'] = plPlusDiv ? formatNumberAddCommas(formatDecimal(plPlusDiv, 2, true)) : '-';
    cData.Cost = c['Total Buy Cost'];
    cData.Value = value ? formatNumberAddCommas(formatDecimal(value, 2, true)) : '-';
    return cData;
  });
}

function extractNotes(counters) {
  if (!(counters && counters.length > 0)) {
    return {};
  }

  const notes = counters.reduce(
    (accumulator, c) => {
      accumulator[c.counter] = {
        name: c.Name,
        notes: c.Notes || '',
      };
      return accumulator;
    },
    {} /* start with empty hash */,
  );
  return notes;
}

function sanitizeCountersForNonPortfolioLayout(counters, custom_headers = []) {
  let sanitizedCounters = [];
  if (!custom_headers || custom_headers.length < 1) {
    sanitizedCounters = counters?.map((c) =>
      Object.keys(c)
        .filter((k) => !PORTFOLIO_COUNTERS_DATA_HEADERS.includes(k))
        .reduce((newObj, key) => {
          newObj[key] = c[key];
          return newObj;
        }, {}),
    );
  } else {
    sanitizedCounters = counters?.map((c) =>
      Object.keys(c)
        .filter((k) => custom_headers.includes(k))
        .reduce((newObj, key) => {
          newObj[key] = c[key];
          return newObj;
        }, {}),
    );
  }
  return sanitizedCounters;
}

function getActionsColumn(tabKey, layout, classicFlag, customTooltipLabelFunc) {
  const actionButtons = [
    // Pending Alerts until after Beta
    // {
    //   action: 'addAlert',
    //   label: 'Add Alert',
    //   icon: <i className="fa-light fa-bell g-mr-25" />,
    // },
  ];

  if (layout === 'portfolio') {
    actionButtons.push({
      action: 'editNotes',
      label: 'Edit Notes',
      icon: <i className="fa-light fa-edit g-mr-25" />,
      tooltipLabelFunc: customTooltipLabelFunc,
    });
    if (tabKey === tabOpen) {
      actionButtons.push({
        action: 'closePosition',
        label: 'Close Position',
        icon: <i className="fa-light fa-square-xmark g-mr-25" />,
      });
    }
  }

  if (tabKey === 'summary' && layout === 'trading_data' && classicFlag) {
    actionButtons.push({
      action: 'removeCounter',
      label: 'Remove counter from portfolio',
      icon: <i className="fa-light fa-square-xmark" />,
    });
  }

  return { header: '', actions: actionButtons };
}

function getPositionsTotalPL(displayCounters) {
  const totalPL = displayCounters?.reduce((accumulator, c) => {
    const value = parseFloat(c['P/L']?.replaceAll(',', '')) || 0;
    return accumulator + value;
  }, 0);
  return formatNumberAddCommas(formatDecimal(totalPL || 0, 2, true));
}

export default function Positions() {
  const dispatch = useDispatch();
  const source = CancelToken.source();
  const isModeStreaming = useSelector((state) => state.pricesStreamingMode.isModeStreaming);
  const isDuringStreamingHours = useSelector((state) => state.portfolio.isDuringStreamingHours);
  const isLegalUser = useSelector((state) => state.portfolio.isLegalUser);
  const isEodUser = useSelector((state) => state.portfolio.isEodUser);
  const selectedPortfolio = useSelector((state) => state.portfolio.selected);
  const selectedLayout = useSelector((state) => state.portfolio.selectedLayout);
  const allFields = useSelector((state) => state.portfolio.allFields);
  const currentCustomLayoutFields = useSelector(
    (state) => state.portfolio.currentCustomLayoutFields,
  );
  const customLayoutHeaders = useSelector((state) => state.portfolio.customLayoutHeaders);
  const lastCounterNotesUpdated = useSelector((state) => state.portfolio.lastCounterNotesUpdated);
  const loading = useSelector((state) => state.portfolio.loading);
  const instrumentsResponse = useSelector((state) => state.portfolioInstruments.response);
  const instrumentsError = useSelector((state) => state.portfolioInstruments.err);
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentTabKey, setCurrentTabKey] = useState(tabOpen);
  const [displayCounters, setDisplayCounters] = useState([]);
  const [selectedPortfolioCounters, setSelectedPortfolioCounters] = useState([]);
  const [notesForCounters, setNotesForCounters] = useState({});
  const [selectedView, setSelectedView] = useState('priceTable');
  const [csvData, setCsvData] = useState([]);
  const [showAlert, setShowAlert] = useState(false);

  const defaultModal = {
    show: false,
    type: '',
    counter: '',
  };
  const [modal, setModal] = useState(defaultModal);

  useEffect(() => {
    // Fix url with selected portfolio and selected layout
    if (selectedLayout && selectedLayout.length > 0 && selectedLayout !== searchParams.layout) {
      setSearchParams({ layout: selectedLayout });
    }
    setSelectedPortfolioCounters(
      selectedPortfolio?.counters?.length > 0 ? selectedPortfolio.counters : [],
    );
    setNotesForCounters(extractNotes(selectedPortfolio?.counters));
  }, [selectedPortfolio]);

  useEffect(() => {
    const updatedCounter = lastCounterNotesUpdated?.counter;
    if (!(updatedCounter && notesForCounters.hasOwnProperty(updatedCounter))) {
      return;
    }
    const copyNotesForCounters = { ...notesForCounters };
    copyNotesForCounters[updatedCounter].notes = lastCounterNotesUpdated.notes || '';
    setNotesForCounters(copyNotesForCounters);
  }, [lastCounterNotesUpdated]);

  useEffect(() => {
    if (selectedLayout === 'trading_data') {
      setDisplayCounters(sanitizeCountersForNonPortfolioLayout(selectedPortfolioCounters));
      setCurrentTabKey('summary');
      return;
    }
    const hasTransactions =
      selectedPortfolioCounters.filter((c) => c['Total Shares'] && c['Total Shares'] !== '-')
        .length > 0;
    // setup portfolio counters for Portfolio/GainLoss layout
    if (currentTabKey === tabOpen) {
      const counters = selectedPortfolioCounters.filter(
        (c) => c['Shares Held'] && c['Shares Held'] !== '-',
      );
      if (selectedLayout === 'portfolio') {
        setDisplayCounters(extractOpenPositionsData(counters));
      } else if (counters.length > 0) {
        setDisplayCounters(
          sanitizeCountersForNonPortfolioLayout(
            counters,
            selectedLayout === 'custom' ? customLayoutHeaders : [],
          ),
        );
      } else if (hasTransactions) {
        setDisplayCounters([]);
      } else {
        setCurrentTabKey('summary');
      }
    } else if (currentTabKey === 'closed') {
      const counters = selectedPortfolioCounters.filter(
        (c) => c['Shares, Realized'] && c['Shares, Realized'] !== '-',
      );
      if (selectedLayout === 'portfolio') {
        setDisplayCounters(extractClosedPositionsData(counters));
      } else if (counters.length > 0) {
        setDisplayCounters(
          sanitizeCountersForNonPortfolioLayout(
            counters,
            selectedLayout === 'custom' ? customLayoutHeaders : [],
          ),
        );
      } else if (hasTransactions) {
        setDisplayCounters([]);
      } else {
        setCurrentTabKey('summary');
      }
    } else if (currentTabKey === 'summary') {
      if (selectedLayout === 'portfolio') {
        const counters = selectedPortfolioCounters.filter(
          (c) => c['Total Shares'] && c['Total Shares'] !== '-',
        );
        setDisplayCounters(extractAllPositionsData(counters));
      } else {
        setDisplayCounters(
          sanitizeCountersForNonPortfolioLayout(
            selectedPortfolioCounters,
            selectedLayout === 'custom' ? customLayoutHeaders : [],
          ),
        );
      }
    }
  }, [selectedLayout, currentTabKey, selectedPortfolioCounters]);

  useEffect(() => {
    if (!displayCounters || displayCounters.length === 0) {
      return;
    }

    // reformat the `No` in the new array when exporting csv
    const copyDisplayCounters = displayCounters.map((c, index) => {
      if (selectedLayout === 'portfolio' && c.hasOwnProperty('Name')) {
        // Extract stock name
        const stockName = c.Name.split('<br/>')[0];

        return {
          ...c,
          Name: stockName,
        };
      }

      return {
        ...c,
        No: index + 1,
      };
    });

    setCsvData(copyDisplayCounters);
  }, [displayCounters]);

  const switchToPriceTable = () => {
    setSelectedView('priceTable');
  };

  const switchToOtherComponent = () => {
    setSelectedView('treeMap');
  };

  const handleChangeLayout = (newLayout) => {
    dispatch(
      changeSelectedLayout({
        selectedPortfolioID: selectedPortfolio.id,
        selectedLayout: newLayout,
        cancelToken: source.token,
      }),
    );
    setSearchParams({ layout: newLayout });
  };

  const onTabSelectCallback = (event, selectedTabKey) => {
    event.preventDefault();
    if (
      !(
        selectedTabKey &&
        TABS.map((t) => t.key).includes(selectedTabKey) &&
        selectedTabKey !== currentTabKey
      )
    ) {
      return;
    }
    setCurrentTabKey(selectedTabKey);
  };

  const getCustomTooltipLabel = (action, counter) => {
    if (action === 'editNotes' && notesForCounters[counter]?.notes) {
      return `Edit Notes : ${notesForCounters[counter].notes}`;
    }
    return null;
  };

  const actionTriggered = (counter, name, action) => {
    switch (action) {
      case 'editNotes':
        setModal({ show: true, type: 'editNotes', counter });
        break;
      case 'addAlert':
        break;
      case 'closePosition':
        setModal({
          show: true,
          type: 'closePosition',
          counter: selectedPortfolioCounters.filter((c) => c.counter === counter)[0] || {},
        });
        break;
      case 'removeCounter':
        setModal({
          show: true,
          type: 'removeCounter',
          counter: selectedPortfolioCounters.filter((c) => c.counter === counter)[0] || {
            counter,
            Name: name,
          },
        });
        break;
      default:
        return null;
    }
    return null;
  };

  const handleCloseModal = () => {
    setModal(defaultModal);
  };

  const renderComponent = (tabKey, actionsColumn = null) => {
    if (!(selectedPortfolio && tabKey === currentTabKey)) {
      return null;
    }

    const positionsTotalPL = getPositionsTotalPL(displayCounters);
    return (
      <div id="sic_priceContainer">
        <div className="portfolio-toolbar">
          <div className="row g-3 d-flex ms-lg-auto g-lg-per-width-50 justify-content-lg-end g-mb-20">
            {selectedPortfolio?.classic && selectedLayout === 'trading_data' && (
              <div className="col-auto">
                <AddToInstruments loading={loading} />
              </div>
            )}
            {selectedLayout === 'custom' && (
              <div id="sic_editCustomLayout" className="col-auto">
                <EditCustomLayout
                  allFields={allFields}
                  currentCustomFields={currentCustomLayoutFields}
                  folderID={selectedPortfolio.id}
                  isPortfolio
                  handleUpdatedCustomLayout={() => {
                    const url = new URL(window.location.href);
                    url.searchParams.set('layout', 'custom');
                    window.location.href = url.toString();
                  }}
                />
              </div>
            )}
            <div
              id="sic_layoutOptions"
              className="col-auto d-flex justify-content-end align-self-lg-end">
              <LayoutOptions
                isPortfolio={1}
                selectedLayout={selectedLayout}
                setSelectedLayout={handleChangeLayout}
                isLegalUser={isLegalUser}
                isEodUser={isEodUser}
              />
            </div>
            {selectedLayout === 'portfolio' ? (
              <div className="col-auto">
                <div className="btn-group " role="group" aria-label="selected View">
                  {/* PriceTable View */}
                  <button
                    onClick={switchToPriceTable}
                    type="button"
                    className={`btn btn-light g-min-width-0 g-border-radius-7 ${
                      selectedView === 'priceTable' ? 'active' : ''
                    }`}>
                    <i className="fa-light fa-table" />
                  </button>
                  {/* Treemap View */}
                  <button
                    onClick={switchToOtherComponent}
                    type="button"
                    className={`btn btn-light g-min-width-0 g-border-radius-7 ${
                      selectedView === 'treeMap' ? 'active' : ''
                    }`}>
                    <i className="fa-light fa-chart-tree-map" />
                  </button>
                  {/* CSV Download */}
                  <CSVLink
                    data={csvData}
                    filename={`Portfolio-${selectedPortfolio.name}-${currentTabKey}-ShareInvestor`}
                    target="_blank"
                    className="btn btn-light g-min-width-0 g-border-radius-7">
                    <i className="fa-light fa-file-arrow-down" />
                  </CSVLink>
                </div>
              </div>
            ) : (
              <div className="col-auto">
                <div className="btn-group " role="group" aria-label="selected View">
                  {/* CSV Download */}
                  <CSVLink
                    data={csvData}
                    filename={`Portfolio-${selectedPortfolio.name}-${currentTabKey}-ShareInvestor`}
                    target="_blank"
                    className="btn btn-light g-min-width-0 g-border-radius-7">
                    <i className="fa-light fa-file-arrow-down" />
                  </CSVLink>
                </div>
              </div>
            )}
          </div>
        </div>
        {(!displayCounters || displayCounters.length === 0) && (
          <h6 className="text-center text-muted m-5">No data found.</h6>
        )}
        {selectedLayout === 'trading_data' && selectedPortfolio.classic && (
          <>
            <APIErrorHandler error={instrumentsError} />
            {showAlert && (
              <Alert variant="success" onClose={() => setShowAlert(false)} dismissible>
                {instrumentsResponse}
              </Alert>
            )}
          </>
        )}
        {displayCounters && displayCounters.length > 0 && selectedLayout === 'portfolio' && (
          <>
            <span className="g-fw-700 g-text-size-15 g-lg-text-size-18">
              Total P/L :&nbsp;&nbsp;
              <span className={changeTextClass(positionsTotalPL)}>{positionsTotalPL}</span>
            </span>
            {selectedView === 'priceTable' ? (
              <PriceTable
                data={displayCounters}
                actionColumns={actionsColumn}
                actionTriggered={actionTriggered}
                filters={{ layout: selectedLayout, embededFilter: ['Name'] }}
                folder={selectedPortfolio.encFolder}
                isPortfolio={1}
              />
            ) : (
              <PositionsTreemapChart portfolioCounters={displayCounters} />
            )}
          </>
        )}
        {displayCounters && displayCounters.length > 0 && selectedLayout !== 'portfolio' && (
          <>
            <LastUpdatedAt
              currentFilters={{ layout: selectedLayout, folderId: selectedPortfolio.id }}
            />
            {selectedLayout === 'custom' && !(currentCustomLayoutFields?.length > 0) && (
              <div className="text-center">
                <h4 className="g-mt-25">
                  You have not yet setup your Custom layout for this Portfolio.
                </h4>
                <h6 className="g-mt-15">You can set it up using the Edit Custom Layout button.</h6>
              </div>
            )}
            {(selectedLayout !== 'custom' || currentCustomLayoutFields?.length > 0) && (
              <PriceTable
                data={displayCounters}
                actionColumns={selectedLayout === 'trading_data' ? actionsColumn : undefined}
                actionTriggered={selectedLayout === 'trading_data' ? actionTriggered : undefined}
                filters={{ layout: selectedLayout, embededFilter: ['Name'] }}
                isModeStreaming={isModeStreaming}
                isDuringStreamingHours={isDuringStreamingHours}
                streamingType="Portfolio"
                folder={selectedPortfolio.encFolder}
                isPortfolio={1}
              />
            )}
          </>
        )}
      </div>
    );
  };

  const removeInstrument = ({ counter }) => {
    dispatch(
      removeFromInstruments({
        id: selectedPortfolio.id,
        counter,
        cancelToken: source.token,
        selectedLayout,
      }),
    );
  };

  useEffect(() => {
    setShowAlert(instrumentsResponse && instrumentsResponse.length > 0);
    let timer = null;
    if (instrumentsResponse && instrumentsResponse.length > 0) {
      timer = setTimeout(() => {
        setShowAlert(false);
      }, 5000);
    }
    return () => (timer ? clearTimeout(timer) : timer);
  }, [instrumentsResponse]);

  if (loading) {
    return <Loader visible />;
  }

  return (
    <Card className="position-static">
      <Card.Body>
        {selectedLayout === 'portfolio' && (
          <>
            <NavComponent
              navLinks={TABS}
              tabClasses="g-mb-25"
              activeNav={currentTabKey}
              content={renderComponent(
                currentTabKey,
                getActionsColumn(
                  currentTabKey,
                  selectedLayout,
                  selectedPortfolio.classic,
                  getCustomTooltipLabel,
                ),
              )}
              handleNavClick={onTabSelectCallback}
            />
            {modal.type === 'editNotes' && (
              <EditCounterNotesModal
                show={modal.show}
                handleCloseModal={handleCloseModal}
                counterNotes={{
                  portfolioID: String(selectedPortfolio.id),
                  counter: modal.counter,
                  name: notesForCounters[modal.counter]?.name,
                  notes: notesForCounters[modal.counter]?.notes,
                }}
              />
            )}
            {modal.type === 'closePosition' && (
              <ClosePositionModal
                show={modal.show}
                handleCloseModal={handleCloseModal}
                position={{
                  portfolioID: String(selectedPortfolio.id),
                  counter: modal.counter.counter,
                  name: modal.counter.Name,
                  quantity: Number(modal.counter['Shares Held']?.replaceAll(',', '')),
                  sellPrice: Number(modal.counter['Last Done']?.replaceAll(',', '')),
                  selectedLayout,
                }}
              />
            )}
          </>
        )}
        {selectedLayout !== 'portfolio' && (
          <>
            <NavComponent
              navLinks={
                selectedLayout === 'trading_data' ? TABS.filter((t) => t.key === 'summary') : TABS
              }
              tabClasses="g-mb-25"
              activeNav={currentTabKey}
              content={renderComponent(
                currentTabKey,
                getActionsColumn(currentTabKey, selectedLayout, selectedPortfolio.classic),
              )}
              handleNavClick={onTabSelectCallback}
            />
            {selectedLayout === 'trading_data' &&
              modal.type === 'removeCounter' &&
              selectedPortfolio.classic && (
                <ModalDialog
                  show={modal.show}
                  handleClose={handleCloseModal}
                  title="Remove from Instruments"
                  body={`Are you sure you want to remove ${modal.counter.Name} from ${selectedPortfolio.name}?`}
                  handleSave={removeInstrument}
                  saveLabel="Remove"
                  saveVariant="danger"
                  saveParams={{ counter: modal.counter.counter }}
                />
              )}
          </>
        )}
      </Card.Body>
    </Card>
  );
}
