import React, { useEffect, useState, useDeferredValue, useMemo } from 'react';
import PropTypes from 'prop-types';

import { v4 as uuidv4 } from 'uuid';
import FactsheetStackBarChart from './FactsheetStackBarChart';
import classes from '../FactsheetCollapseTableRows.module.css';

// refer to financial's Balance Sheet
// Eg. rowData structure = {data: {date: '2018', lamerica: 10, china: 1, namerica: 9}, splitter:{asia: [{name:"China" , field: "china", color:}], america:[{name:"Latin America" , field:"lamerica", color:},{name:"North America" , field:"namerica", color:}]}}
function FactsheetStackBarChartRow({ showChart, showSparklines, rowData, stackBarChartTitle }) {
  const [barchartData, setBarchartData] = useState(rowData);
  const deferredBarchartData = useDeferredValue(barchartData);

  useEffect(() => {
    if (rowData && rowData !== barchartData) {
      setBarchartData(rowData);
    }
  }, [rowData]);

  const renderChart = useMemo(
    () =>
      deferredBarchartData === barchartData &&
      deferredBarchartData.map((row) => {
        const id = uuidv4();
        return (
          <td key={id}>
            <FactsheetStackBarChart data={row.chart_details} />
          </td>
        );
      }),
    [deferredBarchartData],
  );

  return (
    <tr className={showChart ? 'd-table-row' : 'd-none'}>
      <th className="text-start g-fw-500 g-text-size-15">
        <h6 className="g-mb-30">{stackBarChartTitle}</h6>
        <div className="row">
          {Object.keys(rowData[0].chart_details.splitter).map((key) => {
            return (
              <div className="col" key={key}>
                <span className="g-text-size-15 g-fw-500">{key}</span>
                <ul className="list-unstyled">
                  {rowData[0].chart_details.splitter[key].map((legend) => {
                    const id = uuidv4();
                    return (
                      <li key={id}>
                        <div
                          className={classes.stackBarChartLegend}
                          style={{
                            backgroundColor: legend.color,
                          }}
                        />
                        <span className="g-text-size-14">{legend.name}</span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>
      </th>
      {showSparklines && <td aria-label="trend_placeholder" />}
      {renderChart}
    </tr>
  );
}

FactsheetStackBarChartRow.propTypes = {
  showChart: PropTypes.bool,
  showSparklines: PropTypes.bool,
  rowData: PropTypes.arrayOf(
    PropTypes.shape({
      chart_details: PropTypes.shape({
        data: PropTypes.objectOf(
          PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
        ),
        splitter: PropTypes.objectOf(
          PropTypes.arrayOf(
            PropTypes.shape({
              color: PropTypes.string,
              field: PropTypes.string,
              name: PropTypes.string,
            }),
          ),
        ),
      }),
      date_time_f: PropTypes.string,
      period_f: PropTypes.string,
    }),
  ),
  stackBarChartTitle: PropTypes.string,
};

export default FactsheetStackBarChartRow;
