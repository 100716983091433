import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

import UserLoginComponent from './UserLoginComponent';

function UserLoginModal({ site_key = '', perform_check = true }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const urlSegment = window.location.pathname;
  const redirect =
    urlSegment.startsWith('/user/login') ||
    urlSegment === '/sg' ||
    urlSegment === '/my' /* Reset country */
      ? '/'
      : urlSegment; /* Set redirect only if not a login page. */

  const openModal = (event) => {
    event.preventDefault();
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const openModalButtons = document.getElementsByClassName('show-login-modal');

    Array.from(openModalButtons).forEach((button) => {
      button.addEventListener('click', openModal);
    });

    return () => {
      Array.from(openModalButtons).forEach((button) => {
        button.removeEventListener('click', openModal);
      });
    };
  }, []);

  return (
    <Modal size="md" show={isModalOpen} onHide={closeModal}>
      <Modal.Header closeButton className="pb-0 border-0" />
      <Modal.Body>
        <Container>
          <Row className="justify-content-center">
            <Col>
              {isModalOpen && (
                <UserLoginComponent
                  redirect={redirect}
                  site_key={site_key}
                  perform_check={perform_check}
                  setIsModalOpen={setIsModalOpen}
                />
              )}
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

UserLoginModal.propTypes = {
  site_key: PropTypes.string,
  perform_check: PropTypes.bool,
};

export default UserLoginModal;
