import { createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';

const userSettingsSlice = createSlice({
  name: 'userSettings',
  initialState: {
    saved_data: {},
    err: null,
    completed: false,
    form_state: {},
    message: '',
    connected_apps: [],
  },
  reducers: {
    userSettingsUpdateStart(state, action) {
      state.err = null;
      state.completed = false;
    },
    userSettingsUpdateSuccess(state, action) {
      state.completed = true;
      state.saved_data = action.payload.data.data;
      state.message = action.payload.data.message;
    },
    userSettingsUpdateFail(state, action) {
      state.err = action.payload;
      state.message = 'Update unsuccessful';
      state.completed = false;
    },
    setDefaultUserSettingsState(state, action) {
      const { settings, thirdPartyAuthApps } = action.payload;
      state.saved_data = settings;
      state.form_state = settings;
      state.connected_apps = thirdPartyAuthApps;
    },
    setFormState(state, action) {
      const formStateKey = action.payload?.key;
      if (formStateKey) {
        state.form_state = { ...state.form_state, [`${formStateKey}`]: action.payload.value };
      }
    },
    resetFormState(state, action) {
      state.form_state = state.saved_data;
    },
    userSettingsRevokeAppStart(state, action) {
      state.err = null;
      state.completed = false;
    },
    userSettingsRevokeAppSuccess(state, action) {
      state.completed = true;
      state.message = action.payload.data.message;
      state.connected_apps = action.payload.data.data;
    },
    userSettingsRevokeAppFail(state, action) {
      state.completed = false;
      state.message = 'Revoke unsuccessful';
      state.err = action.payload;
    },
  },
});

export default userSettingsSlice.reducer;

export const {
  userSettingsUpdateStart,
  userSettingsUpdateSuccess,
  userSettingsUpdateFail,
  userSettingsFetchStart,
  userSettingsFetchSuccess,
  userSettingsFetchFail,
  setDefaultUserSettingsState,
  setFormState,
  resetFormState,
  setStreamingMode,
  setTimesAndSalesRecordPerPage,
  setQuoteMovementsRecordPerPage,
  setHistoricalPriceRecordPerPage,
  setPortfolioRefreshPageInterval,
  setPortfolioCalculation,
  setFinancialsDisplayOrder,
  setFinancialsFilter,
  setFinancialsPeriod,
  userSettingsRevokeAppStart,
  userSettingsRevokeAppSuccess,
  userSettingsRevokeAppFail,
} = userSettingsSlice.actions;

export const updateUserSettings =
  ({ settings, cancelToken }) =>
  (dispatch) => {
    const params = { settings };
    dispatch(userSettingsUpdateStart());
    api
      .post(`/user/update_settings.json`, params, { cancelToken })
      .then(({ data }) => {
        dispatch(userSettingsUpdateSuccess({ data }));
      })
      .catch((error) => {
        dispatch(userSettingsUpdateFail(error));
      });
  };

export const revokeConnectedApp =
  ({ appID, cancelToken }) =>
  (dispatch) => {
    dispatch(userSettingsRevokeAppStart());
    api
      .delete(`/user/revoke_auth.json?app_id=${appID}`, { cancelToken })
      .then(({ data }) => {
        dispatch(userSettingsRevokeAppSuccess({ data }));
      })
      .catch((error) => {
        dispatch(userSettingsRevokeAppFail(error));
      });
  };
