import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';

import Loader from '../Loader';
import { createLoadableComponent } from '../../utils/Loadable';
import classes from './analysisToolsButton.module.css';
import { fetchData } from './analysisToolsSlice';

const HistoricalWatch = createLoadableComponent(() => import('./HistoricalWatch'));
const VolumeDistribution = createLoadableComponent(() => import('./VolumeDistribution'));
const SectorAllocation = createLoadableComponent(() => import('./SectorAllocation'));
const WeeklyMovers = createLoadableComponent(() => import('./WeeklyMovers'));
const ConsensusEstimates = createLoadableComponent(() => import('./ConsensusEstimates'));

const { CancelToken } = axios;

const TABS = [
  {
    label: 'Historical Watch',
    value: 'historical',
  },
  {
    label: 'Volume Distribution',
    value: 'volume_distribution',
  },
  {
    label: 'Sector Allocation',
    value: 'sector_allocation',
  },
  {
    label: 'Weekly Movers',
    value: 'weekly_movers',
  },
  {
    label: 'Consensus Estimates', // special
    value: 'consensus_estimates',
  },
];

const checkIsNoFeatureAvailable = (data) => {
  if (!data) {
    return true;
  }

  return Object.keys(data).every((key, index, arr) => !data[key]);
};

function AnalysisToolsButton({
  filter,
  folder,
  market,
  counters,
  alphabet,
  page,
  availableFeatures,
  btnClasses,
  isScreener,
}) {
  const [show, setShow] = useState(false);
  const [activeTab, setActiveTab] = useState(null); // type
  const dispatch = useDispatch();
  const completed = useSelector((state) => state.analysisTools.completed);
  const source = CancelToken.source();
  const toggleModal = () => setShow((_show) => !_show);
  const handlePressTab = (tab) => {
    setActiveTab(tab);
  };
  const isNoFeatureAvailable = checkIsNoFeatureAvailable(availableFeatures);
  const selectedStocks = useSelector((state) => state.priceTable.selectedstocks);

  useEffect(() => {
    if (!activeTab && availableFeatures && Object.keys(availableFeatures).length > 0) {
      setActiveTab(Object.keys(availableFeatures).find((x) => availableFeatures[x]));
    }
  }, [availableFeatures]);

  useEffect(() => {
    if (show && activeTab && activeTab !== 'weekly_movers' && activeTab !== 'consensus_estimates') {
      dispatch(
        fetchData({
          filter,
          folder,
          market,
          counters,
          alphabet,
          page,
          analysisToolsType: activeTab,
          cancelToken: source.token,
        }),
      );
    }
  }, [activeTab, show]);

  if (isNoFeatureAvailable) {
    return null;
  }

  return (
    <>
      <button
        className={`btn btn-light ${btnClasses || ''}`}
        type="button"
        onClick={toggleModal}
        disabled={!(availableFeatures && Object.keys(availableFeatures).length > 0)}>
        <i className="fa-thin fa-magnifying-glass-chart fa-lg g-mr-10" />
        Analysis Tools
      </button>

      {show && (
        <Modal size="xl" show={show} onHide={toggleModal} keyboard={false} centered>
          <Modal.Header closeButton className="pb-0">
            <div className="d-flex flex-wrap justify-content-between">
              <div className="nav nav-tabs design-2 justify-content-between" role="tablist">
                {TABS.filter(
                  (tab) =>
                    availableFeatures[tab.value] &&
                    (!['sector_allocation', 'volume_distribution', 'weekly_movers'].includes(
                      tab.value,
                    ) ||
                      // hide volume_distribution tab for indices
                      (tab.value === 'volume_distribution' && filter !== 'CS06') ||
                      // hide sector_allocation tab for indices/selected_stocks
                      (tab.value === 'sector_allocation' &&
                        filter !== 'CS06' &&
                        selectedStocks < 1) ||
                      // hide weekly_movers tab for watchlist/portfolios/selected_stocks
                      (tab.value === 'weekly_movers' &&
                        !folder &&
                        selectedStocks < 1 &&
                        !isScreener)),
                ).map((tab) => {
                  return (
                    <div className="nav-item" role="presentation" key={tab.label}>
                      <button
                        className={`nav-link ${tab.value === activeTab ? 'active' : ''}`}
                        type="button"
                        onClick={() => handlePressTab(tab.value)}>
                        {tab.label}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          </Modal.Header>
          <Modal.Body className={classes.analysisToolsModal}>
            <Loader visible={!completed && activeTab !== 'weekly_movers'} />
            {completed && activeTab === 'historical' && (
              <HistoricalWatch selectedStocks={selectedStocks} />
            )}
            {completed && activeTab === 'volume_distribution' && (
              <VolumeDistribution selectedStocks={selectedStocks} />
            )}
            {completed && activeTab === 'sector_allocation' && <SectorAllocation />}
            {activeTab === 'weekly_movers' && <WeeklyMovers market={market} />}
            {activeTab === 'consensus_estimates' && (
              <ConsensusEstimates
                counters={selectedStocks.length < 1 ? counters : selectedStocks.join(':')}
                market={market}
                folder={folder}
              />
            )}
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}

AnalysisToolsButton.propTypes = {
  filter: PropTypes.string,
  folder: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  market: PropTypes.string,
  counters: PropTypes.string,
  alphabet: PropTypes.string,
  page: PropTypes.string,
  availableFeatures: PropTypes.shape({
    historical_watch: PropTypes.bool,
    volume_distribution: PropTypes.bool,
    sector_allocation: PropTypes.bool,
    weekly_movers: PropTypes.bool,
    consensus_estimates: PropTypes.bool,
  }),
};

export default AnalysisToolsButton;
