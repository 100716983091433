import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from 'react-bootstrap';

import { getLocalCurrency } from '../prices';
import { membershipChooseAddOns } from '../membershipSlice';

export default function MarketDepthAddOns({ country, realTimeBursaPriceKey }) {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.membership.data);
  const plan = useSelector((state) => state.membership.plan);
  const period = useSelector((state) => state.membership.period);
  const addOns = useSelector((state) => state.membership.addOns);
  const [sgxMarketDepth, setSgxMarketDepth] = useState(addOns.full_market_depth);
  const [bursaMarketDepth, setBursaMarketDepth] = useState(addOns.bursa_full_market_depth);

  const toggleSgxMarketDepth = () => {
    setSgxMarketDepth(!sgxMarketDepth);
  };

  const toggleBursaMarketDepth = () => {
    setBursaMarketDepth(!bursaMarketDepth);
  };

  useEffect(() => {
    dispatch(membershipChooseAddOns({ ...addOns, full_market_depth: sgxMarketDepth }));
  }, [sgxMarketDepth]);

  useEffect(() => {
    dispatch(membershipChooseAddOns({ ...addOns, bursa_full_market_depth: bursaMarketDepth }));
  }, [bursaMarketDepth]);

  useEffect(() => {
    if (!addOns['real-time_sgx_market_data']) {
      setSgxMarketDepth(false);
    }
    if (!addOns[realTimeBursaPriceKey]) {
      setBursaMarketDepth(false);
    }
  }, [addOns]);

  if (!plan.includes('station')) {
    return null;
  }

  return (
    <div className="my-2 mx-2 p-2 border-bottom">
      <h6>Market Depth</h6>
      {plan != 'station_my_local' && (
        <div className="d-inline-block g-per-width-40">
          <label
            htmlFor="sgx_full_market_depth"
            className="d-flex align-items-middle fw-bold"
            disabled={!addOns['real-time_sgx_market_data']}>
            <input
              type="checkbox"
              name="full_market_depth"
              id="sgx_full_market_depth"
              className="form-check-input"
              onChange={toggleSgxMarketDepth}
              checked={sgxMarketDepth}
              disabled={!addOns['real-time_sgx_market_data']}
            />
            <span className="ms-2">Singapore</span>
          </label>
        </div>
      )}

      <div className="d-inline-block g-per-width-40">
        <label
          htmlFor="bursa_full_market_depth"
          className="d-flex align-items-middle fw-bold"
          disabled={!addOns[realTimeBursaPriceKey]}>
          <input
            type="checkbox"
            name="bursa_market_depth"
            id="bursa_full_market_depth"
            className="form-check-input"
            onChange={toggleBursaMarketDepth}
            checked={bursaMarketDepth}
            disabled={!addOns[realTimeBursaPriceKey]}
          />

          <span className="ms-2">Malaysia</span>
        </label>
      </div>
    </div>
  );
}

MarketDepthAddOns.propTypes = {
  country: PropTypes.string,
  realTimeBursaPriceKey: PropTypes.string,
};
