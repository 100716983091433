import { createSlice } from '@reduxjs/toolkit';

const pricesStreamingModeSlice = createSlice({
  name: 'pricesStreamingMode',
  initialState: {
    isModeStreaming: true,
  },
  reducers: {
    onToggle(state, action) {
      // ToDo: May need to check on limiting prices-streaming based on time-of-day (i.e.: only when market is open)
      state.isModeStreaming = !state.isModeStreaming;
    },
  },
});

export default pricesStreamingModeSlice.reducer;

const { onToggle } = pricesStreamingModeSlice.actions;

export const togglePricesStreamingMode = () => (dispatch) => {
  dispatch(onToggle());
};
