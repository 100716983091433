import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import SearchQuote from '../../../components/SearchQuote';
import { MAX_STOCKS } from './StocksComparisonConstants';
import { chooseStock, chooseWarrant, removeStock, removeWarrant, selectStockCodes, selectWarrantCodes, fetchData } from './stocksComparisonSlice';

const { CancelToken } = axios;

function AddRemoveToStocksComparison({ screenType }) {
  const dispatch = useDispatch();
  const source = CancelToken.source();
  const stockCodes = screenType == "warrants" ? useSelector(selectWarrantCodes) : useSelector(selectStockCodes);

  const [showModal, setShowModal] = useState(false);
  const [selectedColumn, setSelectedColumn] = useState(0);

  const handleAddStock = (index) => {
    setSelectedColumn(index);
    setShowModal(true);
  };

  const handleRemoveStock = (index) => {
    if (screenType == "warrants") {
      dispatch(removeWarrant(index));
    } else {
      dispatch(removeStock(index));
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleAddToStockComparison = (counterData) => {

    setShowModal(false);

    const symbol = counterData.symbol.toLowerCase();

    const existingColumn = Object.entries(stockCodes)?.find(([key, val]) => val === symbol)?.[0] ?? null;

    if (screenType == "warrants") {
      /* Remove if exist in other column */
      if (existingColumn !== null && existingColumn !== undefined) {
        dispatch(removeWarrant(existingColumn));
      }
      dispatch(chooseWarrant({ columnIndex: selectedColumn, code: symbol }))
    } else {
      /* Remove if exist in other column */
      if (existingColumn !== null && existingColumn !== undefined) {
        dispatch(removeStock(existingColumn));
      }
      dispatch(chooseStock({ columnIndex: selectedColumn, code: symbol }))
    }

    /* Call fetch data */
    dispatch(fetchData({ columnIndex: selectedColumn, stockList: [symbol], stockType: screenType, cancelToken: source.token }));
  };

  return (
    <>
      <div className="d-flex justify-content-around flex-wrap gap-3">
        {
          [...Array(MAX_STOCKS).keys()].map((index) => {
            return (
              (index in stockCodes ?
                <div
                  key={`removeStockButton_${stockCodes[index]}`}
                  className="g-per-width-100 g-lg-width-200">
                  <Button
                    variant="light"
                    type="text"
                    className="w-100 d-flex align-items-center text-start"
                    onClick={() => handleRemoveStock(index)}>
                    {stockCodes[index].toUpperCase()}
                    <span className="ms-auto g-pl-20">
                      <i className="fa-light fa-x" />
                    </span>
                  </Button>
                </div>
                :
                <div key={`addStockButton_${index}`} className="g-per-width-100 g-lg-width-200">
                  <Button
                    type="text"
                    variant="light"
                    className="w-100 d-flex align-items-center justify-content-center"
                    onClick={() => handleAddStock(index)}>
                    <span className="text-hyperlink">
                      {screenType === 'warrants' ? 'Pick a warrant' : 'Pick a stock'}
                    </span>
                  </Button>
                </div>
              ))
          })
        }
      </div>

      <Modal
        show={showModal}
        centered
        dialogClassName="g-text-size-14 addStockModal"
        onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add to Stock Comparison</Modal.Title>
        </Modal.Header>

        <SearchQuote
          placeholder="Search to add stock to your stock comparison"
          type="watchlist"
          handleActiveCounterChanged={handleAddToStockComparison}
          stockType={screenType}
        />
      </Modal>
    </>
  );
}

export default AddRemoveToStocksComparison;

AddRemoveToStocksComparison.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  emptyStockCount: PropTypes.number,
  stockType: PropTypes.string,
};
