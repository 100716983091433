import React from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import styles from './searchQuote.module.css';

export default function QuoteLinks({ activeCounter, eodMarkets, view }) {
  if (!activeCounter.stock_id) {
    return null;
  }

  const isStock = activeCounter.is_index === '0.0' || !activeCounter.is_index;
  const isWarrants = activeCounter.stock_type === 'warrants' || activeCounter.is_warrants === true;
  const isReits = activeCounter.is_reits === '1.0' || activeCounter.is_reits === true;
  const isEOD = eodMarkets.includes(activeCounter.market);
  const sgxBursaMarkets = ['SGX', 'Bursa'];
  const isSgxBursaMarket = sgxBursaMarkets.includes(activeCounter.market);

  const marketList = [
    {
      key: 'timeAndSales',
      label: 'Time & Sales',
      url: `/prices/time_and_sales.html#/?counter=${activeCounter.symbol}`,
      hide_eod: true,
    },
    {
      key: 'trade_summary_matrix',
      label: 'Trade Summary Matrix',
      url: `/prices/trade_summary_matrix.html#/?counter=${activeCounter.symbol}`,
      hide_eod: true,
      hide_indice: true,
    },
    {
      key: 'quote_movements',
      label: 'Quote Movements',
      url: `/prices/quote_movements.html#/?counter=${activeCounter.symbol}`,
      hide_eod: true,
      hide_indice: true,
    },
    {
      key: 'historical_price',
      label: 'Historical Price',
      url: `/prices/historical_price.html#/?counter=${activeCounter.symbol}`,
    },
    {
      key: 'price_download',
      label: 'Price Download',
      url: `/prices/price_download.html#/?counter=${activeCounter.symbol}`,
    },
  ];

  const fundamentalList = [
    {
      key: 'factsheet',
      label: 'Overview',
      url: `/home/redirect?type=factsheet&counter=${activeCounter.marketAndCode}`,
    },
    {
      key: 'stock_news',
      label: 'News',
      url: `/home/redirect?type=stock_news&counter=${activeCounter.marketAndCode}`,
      hide_warrant: true,
      only_sgx_bursa: true,
    },
    {
      key: 'financials',
      label: 'Financials',
      url: `/home/redirect?type=financial&counter=${activeCounter.marketAndCode}`,
      hide_indice: true,
      hide_warrant: true,
    },
    {
      key: 'price_analysis',
      label: 'Price Analysis',
      url: `/home/redirect?type=price_analysis&counter=${activeCounter.marketAndCode}`,
      hide_indice: true,
    },
    {
      key: 'quote_movements',
      label: 'Quote Movements',
      url: `/quote/${activeCounter.marketAndCode}/price_analysis/quote_movements`,
      hide_indice: true,
    },
    {
      key: 'time_and_sales',
      label: 'Time & Sales',
      url: `/quote/${activeCounter.marketAndCode}/price_analysis/time_and_sales`,
      hide_indice: true,
    },
    {
      key: 'events_calendar',
      label: 'Events Calendar',
      url: `/home/redirect?type=event&counter=${activeCounter.marketAndCode}`,
      hide_indice: true,
      hide_warrant: true,
    },
    {
      key: 'consensus_estimates',
      label: 'Consensus Estimates',
      url: `/home/redirect?type=consensus_estimates&counter=${activeCounter.marketAndCode}`,
      hide_indice: true,
      hide_warrant: true,
    },
    {
      key: 'insider_trade',
      label: 'Insider Trades',
      url: `/home/redirect?type=insider_trade&counter=${activeCounter.marketAndCode}`,
      hide_indice: true,
      hide_eod: true,
      hide_warrant: true,
    },
    {
      key: 'shareholders',
      label: isReits ? 'Unitholders' : 'Shareholders',
      url: `/home/redirect?type=shareholders&counter=${activeCounter.marketAndCode}`,
      hide_indice: true,
      hide_warrant: true,
    },
    {
      key: 'dividend_analysis',
      label: 'Dividend Analysis',
      url: `/home/redirect?type=dividend_analysis&counter=${activeCounter.marketAndCode}`,
      hide_indice: true,
      hide_warrant: true,
    },
    {
      key: 'company_buyback',
      label: 'Company Buyback',
      url: `/home/redirect?type=company_buyback&counter=${activeCounter.marketAndCode}`,
      hide_indice: true,
      hide_eod: true,
      hide_warrant: true,
    },
    {
      key: 'reports',
      label: 'Reports',
      url: `/home/redirect?type=reports&counter=${activeCounter.marketAndCode}`,
      hide_indice: true,
      hide_eod: true,
      only_sgx_bursa: true,
      hide_warrant: true,
    },
  ];

  const chartList = [
    {
      key: 'c2_charts',
      label: 'C<sup>2</sup> Chart',
      url: `/charts/c2_charts.html?id=${activeCounter.symbol}`,
      rel: 'noreferrer',
    },
  ];

  const othersList = [
    {
      key: 'forum',
      label: `Community Discussion`,
      url: `/home/redirect?type=forum&counter=${activeCounter.marketAndCode}`,
      hide_indice: true,
      hide_eod: true,
    },
  ];

  const portfolioList = [
    {
      key: 'add_transaction',
      label: `Add Transaction`,
      url: `/portfolio/facebox_add_transaction?counter=${activeCounter.marketAndCode}&folder=285744`,
    },
  ];

  // use isSplit true to split menu into 2 columns
  const generateListItems = (list, title, isSplit = false) => {
    const listItems = [];
    const listClass = isSplit ? styles.quoteInfoLinksList : styles.quoteInfoLinksListNoSplit;

    list.forEach((item) => {
      if (
        (item.hide_indice && !isStock) ||
        (item.hide_eod && isEOD) ||
        (item.only_sgx_bursa && !isSgxBursaMarket) ||
        (item.hide_warrant && isWarrants)
      ) {
        return;
      }

      listItems.push(
        <li key={item.key}>
          <a
            className={styles.grayText}
            href={item.url}
            target={item.target}
            rel={item.rel}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.label) }}
          />
        </li>,
      );
    });

    if (listItems.length === 0) {
      return null;
    }

    return (
      <>
        <strong>{title}</strong>
        <ul className={listClass}>{listItems.map((item) => item)}</ul>
      </>
    );
  };

  return (
    <>
      {view === 'search' && (
        <div className="row g-mb-30">
          {/* <div className="col-4">
            {generateListItems(marketList, "Price Movements", true)}
          </div> */}
          <div className="col-8">{generateListItems(fundamentalList, 'Fundamental', true)}</div>
          <div className="col-4">
            {generateListItems(chartList, 'Charts', true)}
            {generateListItems(othersList, 'Others', true)}
          </div>
        </div>
      )}
      {view === 'stock_popup' && (
        <div className="row">
          <div className="col-6">{generateListItems(fundamentalList, 'Fundamental', false)}</div>
          <div className="col-6">
            {generateListItems(portfolioList, 'Portfolio', false)}
            {/* {generateListItems(marketList, "Markets", false)} */}
            {generateListItems(chartList, 'Charts', false)}

            {generateListItems(othersList, 'Others', false)}
          </div>
        </div>
      )}
    </>
  );
}

QuoteLinks.propTypes = {
  activeCounter: PropTypes.oneOfType([PropTypes.object]),
  eodMarkets: PropTypes.oneOfType([PropTypes.array]),
  view: PropTypes.string,
};
