$(document).on('turbolinks:load', function () {
  function toggleProfileDropdownBehaviour() {
    if ($(window).width() >= 992) {
      $('#header-btn-profile').attr('data-bs-auto-close', 'outside');
    } else {
      $('#header-btn-profile').attr('data-bs-auto-close', 'false');
    }
  }

  $(function () {
    toggleProfileDropdownBehaviour();
    $(window).on('resize', function () {
      toggleProfileDropdownBehaviour();
    });

    // On click Hamburger menu, hide profile and search button
    $('#main-nav-button').on('click', function () {
      if ($(this).hasClass('collapsed')) {
        $('#header-search-btn--mobile').removeClass('d-none');
        $('.nav-profile').removeClass('d-none');
        $(this).removeAttr('style');
      } else {
        $('#header-search-btn--mobile').addClass('d-none');
        $('.nav-profile').addClass('d-none');
        $(this).css('margin-right', 0);
      }
    });

    // On click profile button, hide Hamburger menu and search button
    $('#header-btn-profile').on('click', function () {
      if ($(this).hasClass('show')) {
        $('#main-nav-button').addClass('d-none');
        $('#header-search-btn--mobile').addClass('d-none');
      } else {
        $('#main-nav-button').removeClass('d-none');
        $('#header-search-btn--mobile').removeClass('d-none');
      }
    });

    $('.main_menu_drop_item').on('click', function (e) {
      if (typeof gtag === 'function') {
        gtag('event', 'Main Navigation', {
          event_category: 'Navigation', // umbrella category
          event_action: 'Navigation', // same as event name
          event_value: 1,
          event_label: $(this).attr('button_name'), //
        });
      }
    });
  });
});
