import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Responsive from '@amcharts/amcharts5/themes/Responsive';

export function setupColumnLineChart(rootContainerId, chartData) {
  const root = am5.Root.new(rootContainerId);
  // responsive chart
  const responsive = am5themes_Responsive.newEmpty(root);

  root.setThemes([responsive]);

  // Create chart
  // https://www.amcharts.com/docs/v5/charts/xy-chart/
  const chart = root.container.children.push(
    am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      layout: root.verticalLayout,
      paddingRight: 50,
      paddingLeft: 0,
    }),
  );

  // Add legend
  // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
  const legend = chart.children.push(
    am5.Legend.new(root, {
      centerX: am5.p50,
      x: am5.p50,
      marginTop: 10,
    }),
  );

  legend.markers.template.setAll({
    width: 24,
    height: 24,
  });

  legend.markerRectangles.template.setAll({
    cornerRadiusTL: 24,
    cornerRadiusTR: 24,
    cornerRadiusBL: 24,
    cornerRadiusBR: 24,
  });

  // Create axes
  // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
  // XAxis
  const xRenderer = am5xy.AxisRendererX.new(root, {
    cellStartLocation: 0.3,
    cellEndLocation: 0.7,
    stroke: false,
    minGridDistance: 30,
  });

  const xAxis = chart.xAxes.push(
    am5xy.CategoryAxis.new(root, {
      maxDeviation: 0.3,
      categoryField: 'year',
      renderer: xRenderer,
      tooltip: am5.Tooltip.new(root, {}),
    }),
  );

  // Enable label wrapping
  xRenderer.labels.template.setAll({
    oversizedBehavior: 'wrap',
    textAlign: 'center',
  });

  xRenderer.grid.template.setAll({
    location: 1,
    stroke: false,
  });

  // Automatically set maxWidth on labels when it changes on an axis
  xAxis.onPrivate('cellWidth', function (cellWidth) {
    xRenderer.labels.template.set('maxWidth', cellWidth);
  });

  xAxis.data.setAll(chartData.data);

  // YAxis - Left
  const YAxisLeftRenderer = am5xy.AxisRendererY.new(root, {
    strokeOpacity: 0.1,
  });

  const yAxisLeft = chart.yAxes.push(
    am5xy.ValueAxis.new(root, {
      extraMax: 0.1,
      renderer: YAxisLeftRenderer,
    }),
  );

  const yAxisLeftTitle = am5.Label.new(root, {
    rotation: -90,
    text: chartData.leftYlabel,
    y: am5.p50,
    centerX: am5.p50,
    textAlign: 'center',
    x: 0,
  });

  yAxisLeft.children.unshift(yAxisLeftTitle);

  // YAxis - Right
  const yAxisRightRenderer = am5xy.AxisRendererY.new(root, {
    opposite: true,
  });

  yAxisRightRenderer.grid.template.set('forceHidden', true);

  const yAxisRight = chart.yAxes.push(
    am5xy.ValueAxis.new(root, {
      renderer: yAxisRightRenderer,
      min: 0,
      extraMax: 0.1,
    }),
  );

  // YAxis right title
  const yAxisRightTitle = am5.Label.new(root, {
    rotation: 90,
    text: chartData.rightYlabel,
    textAlign: 'center',
    x: am5.p100,
    dx: 40,
    y: am5.p50,
    centerX: am5.p50,
    position: 'absolute',
  });

  yAxisRight.children.unshift(yAxisRightTitle);

  // Series Config
  chart
    .get('colors')
    .set('colors', [am5.color(0x4bc264), am5.color(0x43bfff), am5.color(0xffc843)]);

  // Add series
  // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
  function makeColumnSeries(name, fieldName, stacked, hidden = false) {
    const series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        stacked,
        name,
        xAxis,
        yAxis: yAxisLeft,
        valueYField: fieldName,
        categoryXField: 'year',
      }),
    );

    series.columns.template.setAll({
      tooltipY: 0,
      strokeOpacity: 0,
      tooltipText: '{categoryX}\n{name}: {valueY}',
      templateField: 'columnSettings',
      width: am5.percent(50),
    });

    // Series events to hide yAxis
    series.on('visible', function () {
      const columnSeriesValues = chart.series.values.filter(
        (columnSeries) => columnSeries instanceof am5xy.ColumnSeries,
      );
      const areBothSeriesHidden = columnSeriesValues.every(
        (columnSeriesValue) => columnSeriesValue._isHidden === true,
      );
      if (areBothSeriesHidden) {
        yAxisLeft.hide();
      } else {
        yAxisLeft.show();
      }
    });

    series.data.setAll(chartData.data);

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    if (hidden) {
      series.hide(0);
    } else {
      series.appear();
    }

    legend.data.push(series);
  }

  function makeLineSeries(name, fieldName, stacked, hidden = false) {
    const series = chart.series.push(
      am5xy.LineSeries.new(root, {
        name,
        xAxis,
        yAxis: yAxisRight,
        valueYField: fieldName,
        categoryXField: 'year',
        fill: name.toLocaleLowerCase().includes('special')
          ? am5.color(0x363636)
          : am5.color(0x828282),
        stroke: name.toLocaleLowerCase().includes('special')
          ? am5.color(0x363636)
          : am5.color(0x828282),
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: 'horizontal',
          labelText: '{categoryX}\n{name}: {valueY}',
        }),
        connect: false,
      }),
    );

    series.strokes.template.setAll({
      strokeWidth: 3,
      templateField: 'strokeSettings',
    });

    // Series events to hide yAxis
    series.on('visible', function () {
      const lineSeriesValues = chart.series.values.filter(
        (lineSeries) => lineSeries instanceof am5xy.LineSeries,
      );
      const areBothSeriesHidden = lineSeriesValues.every(
        (lineSeriesValue) => lineSeriesValue._isHidden === true,
      );
      if (areBothSeriesHidden) {
        yAxisRight.hide();
      } else {
        yAxisRight.show();
      }
    });

    series.data.setAll(chartData.data);

    series.bullets.push(function () {
      return am5.Bullet.new(root, {
        sprite: am5.Circle.new(root, {
          strokeWidth: 3,
          stroke: series.get('stroke'),
          radius: 5,
          fill: root.interfaceColors.get('background'),
        }),
      });
    });

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    if (hidden) {
      series.hide(0);
    } else {
      series.appear();
    }

    legend.data.push(series);
  }

  chartData.seriesConfig
    .filter((config) => config.seriesType === 'column')
    .forEach((seriesConfigItem) => {
      const { seriesType, seriesTitle, id, stacked, hidden } = seriesConfigItem;
      makeColumnSeries(seriesTitle, id, stacked, hidden);
    });

  chartData.seriesConfig
    .filter((config) => config.seriesType !== 'column')
    .forEach((seriesConfigItem) => {
      const { seriesType, seriesTitle, id, stacked, hidden } = seriesConfigItem;
      makeLineSeries(seriesTitle, id, stacked, hidden);
    });

  // Responsive Rule
  responsive.addRule({
    relevant: am5themes_Responsive.widthL,
    applying() {
      yAxisRightRenderer.labels.template.set('visible', false);
      yAxisLeft.get('renderer').labels.template.setAll({
        fontSize: 12,
      });
      yAxisLeftTitle.set('fontSize', 14);
      yAxisRightTitle.set('fontSize', 14);
      xRenderer.labels.template.setAll({
        rotation: -90,
        centerX: am5.p100,
        dx: -10,
        fontSize: 12,
      });
      legend.labels.template.setAll({
        fontSize: 12,
      });
    },
    removing() {
      yAxisRightRenderer.labels.template.set('visible', true);
      yAxisLeftTitle.set('fontSize', 16);
      yAxisRightTitle.set('fontSize', 16);
      xRenderer.labels.template.setAll({
        rotation: 0,
        centerX: am5.p50,
        dx: 0,
        fontSize: 14,
      });
      legend.labels.template.setAll({
        fontSize: 16,
      });
    },
  });

  const cursor = chart.set(
    'cursor',
    am5xy.XYCursor.new(root, {
      behavior: 'none',
    }),
  );
  cursor.lineY.set('visible', false);
  chart.series.getIndex(0).hidden = true;

  return chart;
}

export default setupColumnLineChart;
