import React from 'react';
import Card from 'react-bootstrap/Card';
import PropTypes from 'prop-types';
import SettingsInputField from './SettingsInputField';

const PRIMARY_MARKETS = {
  sgx: 'SGX',
  bursa: 'Bursa',
};

function SettingsPrimaryMarket({ market }) {
  const primaryMarketConfig = {
    key: 'primary_market',
    name: 'Primary Market',
    icon: 'fa-light fa-chart-mixed',
    inputType: 'dropdown',
    value: market,
    options: PRIMARY_MARKETS,
  };

  return (
    <Card className="g-mb-30">
      <Card.Body>
        <Card.Title className="h5">Primary Market Settings</Card.Title>
        <p>
          Select your primary market from your list of subscribed markets. Your primary market will
          always be selected as the default market in all other pages under Market tab. This saves
          you the hassle of reselecting your preferred market every time you visit those pages.
        </p>
        <ul className="list-unstyled">
          <li>
            <SettingsInputField fieldConfig={primaryMarketConfig} />
          </li>
        </ul>
      </Card.Body>
    </Card>
  );
}

SettingsPrimaryMarket.propTypes = {
  market: PropTypes.string,
};

export default SettingsPrimaryMarket;
