import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import store from '../../utils/store';
import StockNewsAlertsMain from './StockNewsAlertsMain';

function StockNewsAlerts() {
  return (
    <Provider store={store}>
      <StockNewsAlertsMain />
    </Provider>
  );
}

export default StockNewsAlerts;
