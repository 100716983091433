import { createSlice } from '@reduxjs/toolkit';
import api from '../../utils/api';

const membershipSlice = createSlice({
  name: 'membership',
  initialState: {
    data: [],
    invoice: null,
    coupon: null,
    err: null,
    loading: false,
    plan: '',
    period: null,
    priceType: null,
    marketType: null,
    couponDiscAmt: 0,
    addOns: {
      'real-time_sgx_market_data': false,
      'real-time_ftse/st_indices': false,
      'real-time_bursa_market_data': false,
      'malaysia_real-time_stock_prices_equity': false,
      'real-time_ftse/bm_index_data': false,
      'malaysia_real-time_stock_prices_derivative': false,
      full_market_depth: false,
      bursa_full_market_depth: false,
    },
    payment: '',
    promoCode: '',
    accessUpdated: false,
  },
  reducers: {
    membershipFetchStart(state, action) {
      state.data = null;
      state.err = null;
      state.loading = true;
    },
    membershipFetchSuccess(state, action) {
      state.data = action.payload.data;
      state.err = null;
      state.loading = false;
    },
    membershipFetchFail(state, action) {
      state.err = action.payload;
      state.loading = false;
    },
    membershipProcessStart(state, action) {
      state.invoice = null;
      state.err = null;
      state.loading = true;
    },
    membershipProcessSuccess(state, action) {
      state.invoice = action.payload.data;
      state.err = null;
      state.loading = false;
    },
    membershipProcessFail(state, action) {
      state.err = action.payload;
      state.loading = false;
    },
    membershipCouponStart(state, action) {
      state.coupon = null;
      state.err = null;
      state.loading = true;
    },
    membershipCouponSuccess(state, action) {
      state.coupon = action.payload.data;
      state.err = null;
      state.loading = false;
    },
    membershipCouponFail(state, action) {
      state.err = action.payload;
      state.loading = false;
    },
    membershipReloadMembershipAccessStart(state, action) {
      state.err = null;
      state.loading = true;
    },
    membershipReloadMembershipAccessSuccess(state, action) {
      state.accessUpdated = true;
      state.err = null;
      state.loading = false;
    },
    membershipReloadMembershipAccessFail(state, action) {
      state.err = action.payload;
      state.loading = false;
    },
    membershipChoosePlan(state, action) {
      state.plan = action.payload;
      state.addOns = {
        'real-time_sgx_market_data': false,
        'real-time_ftse/st_indices': false,
        'real-time_bursa_market_data': false,
        'malaysia_real-time_stock_prices_equity': false,
        'real-time_ftse/bm_index_data': false,
        'malaysia_real-time_stock_prices_derivative': false,
        full_market_depth: false,
        bursa_full_market_depth: false,
      };
      state.payment = '';
      state.promoCode = '';
      state.coupon = null;
    },
    membershipChoosePeriod(state, action) {
      state.period = action.payload;
      state.coupon = null;
    },
    membershipChooseAddOns(state, action) {
      state.addOns = action.payload;
      state.coupon = null;
    },
    membershipChoosePayment(state, action) {
      state.payment = action.payload;
    },
    membershipSetPromoCode(state, action) {
      state.promoCode = action.payload;
    },
    membershipSetPriceType(state, action) {
      state.priceType = action.payload;
      state.coupon = null;
    },
    membershipSetMarketType(state, action) {
      state.marketType = action.payload;
    },
    membershipSetCouponDiscAmt(state, action) {
      state.couponDiscAmt = action.payload;
    },
    membershipCouponReset(state, action) {
      state.coupon = null;
      state.err = null;
      state.loading = false;
    },
  },
});

export default membershipSlice.reducer;
export const {
  membershipChoosePlan,
  membershipChoosePeriod,
  membershipChooseAddOns,
  membershipChoosePayment,
  membershipSetPromoCode,
  membershipSetPriceType,
  membershipSetMarketType,
  membershipSetCouponDiscAmt,
  membershipCouponReset,
} = membershipSlice.actions;

const {
  membershipFetchStart,
  membershipFetchSuccess,
  membershipFetchFail,
  membershipProcessStart,
  membershipProcessSuccess,
  membershipProcessFail,
  membershipCouponStart,
  membershipCouponSuccess,
  membershipCouponFail,
  membershipReloadMembershipAccessStart,
  membershipReloadMembershipAccessSuccess,
  membershipReloadMembershipAccessFail,
} = membershipSlice.actions;

export const fetchData =
  ({ country, cancelToken }) =>
  (dispatch) => {
    let url = 'membership/prices.json';

    if (country) {
      url = `${url}?country=${country}`;
    }

    dispatch(membershipFetchStart());
    api
      .get(url, {
        cancelToken,
      })
      .then(({ data }) => {
        dispatch(membershipFetchSuccess({ data }));
      })
      .catch((error) => {
        dispatch(membershipFetchFail(error));
      });
  };

export const processMembership =
  ({ plan, period, addOns, payment, couponCode, cancelToken }) =>
  (dispatch) => {
    const url = `membership/process_membership.json?plan=${plan}&period=${period}&add_ons=${addOns}&payment=${payment}&coupon_code=${couponCode}`;

    dispatch(membershipProcessStart());
    api
      .get(url, {
        cancelToken,
      })
      .then(({ data }) => {
        dispatch(membershipProcessSuccess({ data }));
      })
      .catch((error) => {
        dispatch(membershipProcessFail(error));
      });
  };

export const applyCouponCode =
  ({ couponCode, plan, addOns, cancelToken }) =>
  (dispatch) => {
    const url = `coupon/get_coupon_information?coupon_code=${couponCode}&plan=${plan}&add_ons=${addOns}`;

    dispatch(membershipCouponStart());
    api
      .get(url, {
        cancelToken,
      })
      .then(({ data }) => {
        dispatch(membershipCouponSuccess({ data }));
      })
      .catch((error) => {
        dispatch(membershipCouponFail(error));
      });
  };

export const reloadMembershipAccess =
  ({ cancelToken }) =>
  (dispatch) => {
    const url = `membership/reload_membership_access`;

    dispatch(membershipReloadMembershipAccessStart());
    api
      .get(url, {
        cancelToken,
      })
      .then(({ data }) => {
        dispatch(membershipReloadMembershipAccessSuccess({ data }));
      })
      .catch((error) => {
        dispatch(membershipReloadMembershipAccessFail(error));
      });
  };
