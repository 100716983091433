import React from 'react';

function Notes() {
  return (
    <div className="text-muted">
      <div className="mb-2">
        <strong>Notes:</strong>
      </div>
      <div className="sic_legendAlpha">
        <div className="d-flex gap-1 ms-4">
          <sup className="footer-disclaimer">a</sup>
          <div className="g-text-size-14 footer-disclaimer">
            Estimated based on the average transaction price and volume.
          </div>
        </div>
      </div>
    </div>
  );
}

export default Notes;
