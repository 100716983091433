import React from 'react';
import PropTypes from 'prop-types';

function index({ titleComponent, rightComponent }) {
  return (
    <>
      <div className="row align-items-center g-lg-mb-30 g-mb-20">
        <div className="col-lg-8">
          <h3 className="g-mb-5 g-lg-mb-0">{titleComponent}</h3>
        </div>
        <div className="col-lg-4 d-flex justify-content-lg-end justify-content-start">
          {rightComponent}
        </div>
      </div>
      <div className="divider-h w-100 g-mb-20 g-lg-mb-30" />
    </>
  );
}

index.propTypes = {
  titleComponent: PropTypes.node.isRequired,
  rightComponent: PropTypes.node,
};

export default index;
