import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { NavComponent } from '../../../components/PageLocalNav';
import Loader from '../../../components/Loader';
import {
  changeTextClass,
  formatNumberAddCommas,
  formatDecimal,
  isNegative,
  isPositive,
} from '../../../utils/utils';
import { fetchStatsSummary } from '../portfolioSlice';
import { getCurrencyName } from '../currencies';

const { CancelToken } = axios;

const tabOverall = 'overall';
const TABS = [
  { key: tabOverall, label: 'Overall Performance', path: '#', refDuration: 'overall' },
  { key: '1d', label: 'Today', path: '#', refDuration: '1d' },
  // { key: '1w', label: 'Past Week', path: '#', refDuration: '1w' },
  // { key: '2w', label: 'Past 2 Weeks', path: '#', refDuration: '2w' },
  // { key: '1m', label: 'Past Month', path: '#', refDuration: '1m' },
  // { key: '3m', label: 'Past 3 Months', path: '#', refDuration: '3m' },
  // { key: '6m', label: 'Past 6 Months', path: '#', refDuration: '6m' },
  // { key: '1y', label: 'Past 1 Year', path: '#', refDuration: '1y' },
];

function extractPerfStatsFromCounters(counters, totalCashFlow) {
  if (!(counters && counters.length > 0)) {
    return {};
  }

  const { totalValue, totalPL, totalDividend, remainingCash, win, lose } = counters.reduce(
    (accumulator, c) => {
      // NOTE: totalValue would be the total value of all open positions (i.e.: unrealized proceeds)
      const uProceeds = parseFloat(c['Proceeds, Unrealized']?.replaceAll(',', '')) || 0;
      accumulator.totalValue += uProceeds;

      const totalPL = parseFloat(c['Total P/L']?.replaceAll(',', '')) || 0;
      accumulator.totalPL += totalPL;

      const div = parseFloat(c['Total Dividend']?.replaceAll(',', '')) || 0;
      accumulator.totalDividend += div;

      const plPlusDiv = totalPL + div;
      if (plPlusDiv > 0) {
        accumulator.win += 1;
      } else if (plPlusDiv < 0) {
        accumulator.lose += 1;
      }

      const buyCost = parseFloat(c['Total Buy Cost']?.replaceAll(',', '')) || 0;
      const rProceeds = parseFloat(c['Proceeds, Realized']?.replaceAll(',', '')) || 0;
      accumulator.remainingCash += rProceeds + div - buyCost;

      return accumulator;
    },
    {
      totalValue: 0,
      totalPL: 0,
      totalDividend: 0,
      remainingCash: Number(totalCashFlow) || 0, // start with TotalDeposit - TotalWithdrawal
      win: 0,
      lose: 0,
    },
  );

  const stats = {};
  stats.total_value = totalValue ? formatNumberAddCommas(formatDecimal(totalValue, 2, true)) : '-';
  stats.total_pl = totalPL ? formatNumberAddCommas(formatDecimal(totalPL, 2, true)) : '-';
  stats.dividend = totalDividend
    ? formatNumberAddCommas(formatDecimal(totalDividend, 2, true))
    : '-';
  stats.pl_plus_div =
    totalPL + totalDividend
      ? formatNumberAddCommas(formatDecimal(totalPL + totalDividend, 2, true))
      : '-';
  stats.remaining_cash = remainingCash
    ? formatNumberAddCommas(formatDecimal(remainingCash, 2, true))
    : '-';
  stats.win_count = win ? formatNumberAddCommas(win) : '-';
  stats.lose_count = lose ? formatNumberAddCommas(lose) : '-';
  return stats;
}

function extractRelativePerfStats(currentStats, refStats) {
  if (!(currentStats && Object.keys(currentStats).length > 0)) {
    return {};
  }

  if (!(refStats && Object.keys(refStats).length > 0)) {
    return currentStats;
  }

  const refTotalValue = parseFloat(refStats.total_value?.replaceAll(',', '')) || 0;
  const refTotalPL = parseFloat(refStats.total_pl?.replaceAll(',', '')) || 0;
  const refDividend = parseFloat(refStats.dividend?.replaceAll(',', '')) || 0;
  const refCash = parseFloat(refStats.remaining_cash?.replaceAll(',', '')) || 0;

  const curTotalValue = parseFloat(currentStats.total_value?.replaceAll(',', '')) || 0;
  const curTotalPL = parseFloat(currentStats.total_pl?.replaceAll(',', '')) || 0;
  const curDividend = parseFloat(currentStats.dividend?.replaceAll(',', '')) || 0;
  const curCash = parseFloat(currentStats.remaining_cash?.replaceAll(',', '')) || 0;

  const relTotalValue = curTotalValue - refTotalValue;
  const relTotalPL = curTotalPL - refTotalPL;
  const relDividend = curDividend - refDividend;
  const relCash = curCash - refCash;

  const stats = {};
  stats.total_value = relTotalValue
    ? formatNumberAddCommas(formatDecimal(relTotalValue, 2, true))
    : '-';
  stats.total_pl = relTotalPL ? formatNumberAddCommas(formatDecimal(relTotalPL, 2, true)) : '-';
  stats.dividend = relDividend ? formatNumberAddCommas(formatDecimal(relDividend, 2, true)) : '-';
  stats.remaining_cash = relCash ? formatNumberAddCommas(formatDecimal(relCash, 2, true)) : '-';
  return stats;
}

export default function PerformanceStats() {
  const dispatch = useDispatch();
  const source = CancelToken.source();
  const isDuringStreamingHours = useSelector((state) => state.portfolio.isDuringStreamingHours);
  const selectedPortfolio = useSelector((state) => state.portfolio.selected);
  const statsSummary = useSelector((state) => state.portfolio.statsSummary);
  const statsSummaryAsOf = useSelector((state) => state.portfolio.statsSummaryAsOf);
  const fetchingStatsSummary = useSelector((state) => state.portfolio.fetchingStatsSummary);
  const loading = useSelector((state) => state.portfolio.loading);
  const [currentTabKey, setCurrentTabKey] = useState(tabOverall);
  const [perfStats, setPerfStats] = useState({});
  const [prevStats, setPrevStats] = useState({});
  const [allPrevStats, setAllPrevStats] = useState({});
  const [selectedPortfolioCounters, setSelectedPortfolioCounters] = useState([]);
  const [overallStats, setOverallStats] = useState({});
  const [isSSR, setIsSSR] = useState(true);
  let updateInterval = 0;

  useEffect(() => {
    setIsSSR(typeof document === 'undefined');

    return () => {};
  }, []);

  useEffect(() => {
    setSelectedPortfolioCounters(
      selectedPortfolio?.counters?.length > 0 ? selectedPortfolio.counters : [],
    );
  }, [selectedPortfolio]);

  useEffect(() => {
    if (
      statsSummaryAsOf?.ago &&
      !Object.prototype.hasOwnProperty.call(allPrevStats, statsSummaryAsOf.ago)
    ) {
      const cpyAllPrevStats = { ...allPrevStats };
      cpyAllPrevStats[statsSummaryAsOf.ago] = statsSummaryAsOf;
      setAllPrevStats(cpyAllPrevStats);
    }
  }, [statsSummaryAsOf]);

  useEffect(() => {
    // disable previously setup polling, if any
    if (updateInterval) {
      clearInterval(updateInterval);
      updateInterval = 0;
    }
    // if selectedPortfolioCounters is empty, clear stats and return immediately
    if (!(selectedPortfolioCounters.length > 0)) {
      setOverallStats({});
      return;
    }
    // trigger updates to statsSummary
    dispatch(
      fetchStatsSummary({
        selectedPortfolioID: selectedPortfolio.id,
        cancelToken: source.token,
      }),
    );
    // trigger updates to statsSummaryAsOf, if needed
    if (!Object.prototype.hasOwnProperty.call(allPrevStats, '1d')) {
      dispatch(
        fetchStatsSummary({
          selectedPortfolioID: selectedPortfolio.id,
          ago: '1d',
          cancelToken: source.token,
        }),
      );
    }
    // trigger update to statsSummary every 60 seconds during streaming hours
    if (isDuringStreamingHours) {
      updateInterval = setInterval(() => {
        if (isDuringStreamingHours && !fetchingStatsSummary) {
          dispatch(
            fetchStatsSummary({
              selectedPortfolioID: selectedPortfolio.id,
              cancelToken: source.token,
            }),
          );
        }
      }, 60000);
    }
    // update overallStats
    setOverallStats(
      extractPerfStatsFromCounters(
        selectedPortfolioCounters.filter((c) => c['Total Shares'] && c['Total Shares'] !== '-'),
        selectedPortfolio.totalCashFlow,
      ),
    );
    return () => {
      if (updateInterval) {
        clearInterval(updateInterval);
        updateInterval = 0;
      }
    };
  }, [selectedPortfolioCounters]);

  useEffect(() => {
    if (!(Object.keys(overallStats).length > 0)) {
      setPerfStats({
        roi: '-',
        remaining_cash: selectedPortfolio.totalCashFlow
          ? formatNumberAddCommas(formatDecimal(Number(selectedPortfolio.totalCashFlow), 2, true))
          : '-',
      });
      setPrevStats({});
      return;
    }

    let currentStats = {};
    if (statsSummary && Object.keys(statsSummary).length > 0) {
      currentStats = { ...statsSummary };
    } else {
      currentStats = { ...overallStats };
      if (Object.prototype.hasOwnProperty.call(perfStats, 'roi') && perfStats.roi !== '-') {
        currentStats.roi = perfStats.roi;
      }
    }

    if (currentTabKey === tabOverall) {
      setPerfStats(currentStats);
      setPrevStats({});
    } else if (Object.prototype.hasOwnProperty.call(allPrevStats, currentTabKey)) {
      const statsAsOf = allPrevStats[currentTabKey];
      const relativeStats = extractRelativePerfStats(currentStats, statsAsOf);
      setPerfStats(relativeStats);
      setPrevStats(statsAsOf);
    }
  }, [currentTabKey, overallStats, statsSummary, allPrevStats]);

  const renderComponent = (tabKey) => {
    if (!(selectedPortfolio && tabKey === currentTabKey)) {
      return null;
    }

    if (currentTabKey == tabOverall) {
      return (
        <OverallPerfStatsTable
          data={perfStats}
          currency={getCurrencyName(selectedPortfolio.currency)}
        />
      );
    }
    return (
      <RelativePerfStatsTable
        data={perfStats}
        currency={getCurrencyName(selectedPortfolio.currency)}
        prevStats={prevStats}
      />
    );
  };

  const onTabSelectCallback = (event, selectedTabKey) => {
    event.preventDefault();
    if (
      !(
        selectedTabKey &&
        TABS.map((t) => t.key).includes(selectedTabKey) &&
        selectedTabKey !== currentTabKey
      )
    ) {
      return;
    }

    if (
      selectedTabKey !== tabOverall &&
      !Object.prototype.hasOwnProperty.call(allPrevStats, selectedTabKey)
    ) {
      dispatch(
        fetchStatsSummary({
          selectedPortfolioID: selectedPortfolio.id,
          ago: selectedTabKey,
          cancelToken: source.token,
        }),
      );
    }
    setCurrentTabKey(selectedTabKey);
  };

  if (isSSR || loading) {
    return <Loader visible />;
  }

  return (
    <div id="sic_portfolioPerfStats g-per-width-100">
      <NavComponent
        navLinks={TABS}
        tabClasses="g-mb-25"
        activeNav={currentTabKey}
        content={renderComponent(currentTabKey)}
        handleNavClick={onTabSelectCallback}
      />
    </div>
  );
}

function OverallPerfStatsTable({ data, currency }) {
  const hasROI = Object.prototype.hasOwnProperty.call(data, 'roi');
  return (
    <div className="row">
      {/* ROI, Total Value */}
      <div className="col-sm-auto">
        <div className="row">
          {/* ROI */}
          <div className="col-sm-auto">
            <div className="g-mr-15">
              <div className="g-fw-500 g-mt-10 g-mb-10">ROI (%)</div>
              {!hasROI && (
                <div id="sic_perf_stats_roi" className="g-text-size-18 g-lg-text-size-30">
                  <i className="fa-duotone fa-spinner-third fa-spin" />
                </div>
              )}
              {hasROI && (
                <div
                  id="sic_perf_stats_roi"
                  className={`g-text-size-18 g-lg-text-size-30 ${changeTextClass(data?.roi)}`}>
                  {data.roi ? data.roi : '-'}
                </div>
              )}
            </div>
          </div>
          {/* Total Value */}
          <div className="col-sm-auto">
            <div className="g-mr-15">
              <div className="g-fw-500 g-mt-10 g-mb-10">{`Total Value (${currency})`}</div>
              <div
                id="sic_perf_stats_total_value"
                className={`g-text-size-18 g-lg-text-size-30 ${changeTextClass(
                  data?.total_value,
                )}`}>
                {data?.total_value ? data.total_value : '-'}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Total P/L, Dividend, Total P/L + Dividend */}
      <div className="col-sm-auto">
        <div className="row">
          {/* Total P/L */}
          <div className="col-sm-auto">
            <div className="g-mr-15">
              <div className="g-fw-500 g-mt-10 g-mb-10">Total P/L</div>
              <div
                id="sic_perf_stats_total_pl"
                className={`g-text-size-18 g-lg-text-size-30 ${changeTextClass(data?.total_pl)}`}>
                {data?.total_pl ? data.total_pl : '-'}
              </div>
            </div>
          </div>
          {/* Dividend */}
          <div className="col-sm-auto">
            <div className="g-mr-15">
              <div className="g-fw-500 g-mt-10 g-mb-10">Dividend</div>
              <div
                id="sic_perf_stats_dividend"
                className={`g-text-size-18 g-lg-text-size-30 ${changeTextClass(data?.dividend)}`}>
                {data?.dividend ? data.dividend : '-'}
              </div>
            </div>
          </div>
          {/* Total P/L + Dividend */}
          <div className="col-sm-auto">
            <div className="g-mr-15">
              <div className="g-fw-500 g-mt-10 g-mb-10">Total P/L + Div</div>
              <div
                id="sic_perf_stats_pl_plus_div"
                className={`g-text-size-18 g-lg-text-size-30 ${changeTextClass(
                  data?.pl_plus_div,
                )}`}>
                {data?.pl_plus_div ? data.pl_plus_div : '-'}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Cash, Win/Lose */}
      <div className="col-sm-auto">
        <div className="row">
          {/* Cash */}
          <div className="col-sm-auto">
            <div className="g-mr-15">
              <div className="g-fw-500 g-mt-10 g-mb-10">Cash</div>
              <div
                id="sic_perf_stats_cash"
                className={`g-text-size-18 g-lg-text-size-30 ${changeTextClass(
                  data?.remaining_cash,
                )}`}>
                {data?.remaining_cash ? data.remaining_cash : '-'}
              </div>
            </div>
          </div>
          {/* Win/Lose */}
          <div className="col-sm-auto">
            <div className="g-mr-15">
              <div className="g-fw-500 g-mt-10 g-mb-10">Win/Lose</div>
              <div id="sic_perf_stats_winlose" className="g-text-size-18 g-lg-text-size-30">
                <span className={`d-inline-block ${changeTextClass(data?.win_count)}`}>
                  {data?.win_count ? data.win_count : '-'}
                </span>
                {` / `}
                <span className={`d-inline-block ${changeTextClass(data?.lose_count, true)}`}>
                  {data?.lose_count ? data.lose_count : '-'}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function RelativePerfStatsTable({ data, currency, prevStats }) {
  return (
    <div className="row">
      {/* Total Value Chg */}
      <div className="col-sm-auto">
        <div className="g-mr-15">
          <div className="g-fw-500 g-mt-10 g-mb-10">
            {`Total Value Chg (${currency})`}
            <span className="hover_popup_container g-ml-5 text-muted g-text-size-16">
              <i className="fas fa-question-circle fa-sm" />
              <div className="hover_popup_content p-2 g-border-radius-10 border bg-white">
                <div className="hover_popup_content_inner g-max-height-300 overflow-x-hidden overflow-y-scroll">
                  {`Previous: ${prevStats.total_value || '-'}`}
                </div>
              </div>
            </span>
          </div>
          <div
            id="sic_perf_stats_total_value"
            className={`g-text-size-18 g-lg-text-size-30 ${changeTextClass(data?.total_value)}`}>
            {isPositive(data?.total_value) && (
              <span>
                <i className="fa-sharp fa-solid fa-up-long fa-sm g-mr-5" />
                {data.total_value.replace('+', '')}
              </span>
            )}
            {isNegative(data?.total_value) && (
              <span>
                <i className="fa-sharp fa-solid fa-down-long fa-sm g-mr-5" />
                {data.total_value.replace('-', '')}
              </span>
            )}
            {(!data?.total_value || data.total_value === '-' || data.total_value === '0.00') && '-'}
          </div>
        </div>
      </div>
      {/* Total P/L Chg, Dividend Chg */}
      <div className="col-sm-auto">
        <div className="row">
          <div className="col-sm-auto">
            <div className="g-mr-15">
              <div className="g-fw-500 g-mt-10 g-mb-10">
                Total P/L Chg
                <span className="hover_popup_container g-ml-5 text-muted g-text-size-16">
                  <i className="fas fa-question-circle fa-sm" />
                  <div className="hover_popup_content p-2 g-border-radius-10 border bg-white">
                    <div className="hover_popup_content_inner g-max-height-300 overflow-x-hidden overflow-y-scroll">
                      {`Previous: ${prevStats.total_pl || '-'}`}
                    </div>
                  </div>
                </span>
              </div>
              <div
                id="sic_perf_stats_total_pl"
                className={`g-text-size-18 g-lg-text-size-30 ${changeTextClass(data?.total_pl)}`}>
                {isPositive(data?.total_pl) && (
                  <span>
                    <i className="fa-sharp fa-solid fa-up-long fa-sm g-mr-5" />
                    {data.total_pl.replace('+', '')}
                  </span>
                )}
                {isNegative(data?.total_pl) && (
                  <span>
                    <i className="fa-sharp fa-solid fa-down-long fa-sm g-mr-5" />
                    {data.total_pl.replace('-', '')}
                  </span>
                )}
                {(!data?.total_pl || data.total_pl === '-' || data.total_pl === '0.00') && '-'}
              </div>
            </div>
          </div>
          <div className="col-sm-auto">
            <div className="g-mr-15">
              <div className="g-fw-500 g-mt-10 g-mb-10">
                Dividend Chg
                <span className="hover_popup_container g-ml-5 text-muted g-text-size-16">
                  <i className="fas fa-question-circle fa-sm" />
                  <div className="hover_popup_content p-2 g-border-radius-10 border bg-white">
                    <div className="hover_popup_content_inner g-max-height-300 overflow-x-hidden overflow-y-scroll">
                      {`Previous: ${prevStats.dividend || '-'}`}
                    </div>
                  </div>
                </span>
              </div>
              <div
                id="sic_perf_stats_dividend"
                className={`g-text-size-18 g-lg-text-size-30 ${changeTextClass(data?.dividend)}`}>
                {isPositive(data?.dividend) && (
                  <span>
                    <i className="fa-sharp fa-solid fa-up-long fa-sm g-mr-5" />
                    {data.dividend.replace('+', '')}
                  </span>
                )}
                {isNegative(data?.dividend) && (
                  <span>
                    <i className="fa-sharp fa-solid fa-down-long fa-sm g-mr-5" />
                    {data.dividend.replace('-', '')}
                  </span>
                )}
                {(!data?.dividend || data.dividend === '-' || data.dividend === '0.00') && '-'}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Cash Chg */}
      <div className="col-sm-auto">
        <div className="g-mr-15">
          <div className="g-fw-500 g-mt-10 g-mb-10">
            Cash Chg
            <span className="hover_popup_container g-ml-5 text-muted g-text-size-16">
              <i className="fas fa-question-circle fa-sm" />
              <div className="hover_popup_content p-2 g-border-radius-10 border bg-white">
                <div className="hover_popup_content_inner g-max-height-300 overflow-x-hidden overflow-y-scroll">
                  {`Previous: ${prevStats.remaining_cash || '-'}`}
                </div>
              </div>
            </span>
          </div>
          <div
            id="sic_perf_stats_cash"
            className={`g-text-size-18 g-lg-text-size-30 ${changeTextClass(data?.remaining_cash)}`}>
            {isPositive(data?.remaining_cash) && (
              <span>
                <i className="fa-sharp fa-solid fa-up-long fa-sm g-mr-5" />
                {data.remaining_cash.replace('+', '')}
              </span>
            )}
            {isNegative(data?.remaining_cash) && (
              <span>
                <i className="fa-sharp fa-solid fa-down-long fa-sm g-mr-5" />
                {data.remaining_cash.replace('-', '')}
              </span>
            )}
            {(!data?.remaining_cash ||
              data.remaining_cash === '-' ||
              data.remaining_cash === '0.00') &&
              '-'}
          </div>
        </div>
      </div>
    </div>
  );
}
