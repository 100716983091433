import React from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import { setFormState } from './userSettingsSlice';

const MIN_RECORD_PER_PAGE = 10;
function SettingsInputField({ fieldConfig }) {
  const dispatch = useDispatch();
  const { key, name, inputType, icon, value, options } = fieldConfig;

  const handleValueChange = (e) => {
    let newValue;
    if (inputType === 'dropdown') {
      newValue = e.target.getAttribute('value');
    } else if (inputType === 'number' || inputType === 'radio') {
      newValue = e.target.value;
    }

    if (inputType !== 'number' && value === newValue) {
      return;
    }

    if (inputType === 'number') {
      // Regular expression to allow only numbers
      const regex = /^\d*$/;
      if (!regex.test(newValue)) {
        return;
      }
    }

    dispatch(setFormState({ key, value: newValue }));
  };

  return (
    <div className="d-flex justify-content-between align-items-center gap-3 ">
      <h6 className="mb-0 g-text-size-13 g-sm-text-size-15">
        <div className="d-flex flex-row align-items-center">
          <i className={`${icon} fa-xl g-mr-5`} />
          <span>{name}</span>
        </div>
      </h6>
      {inputType === 'dropdown' && (
        <Dropdown>
          <Dropdown.Toggle className="h-100 g-max-width-120" variant="light">
            {options[value]}
          </Dropdown.Toggle>
          <Dropdown.Menu className="g-width-200">
            {Object.keys(options).map((option) => {
              return (
                <Dropdown.Item
                  key={option}
                  value={option}
                  onClick={handleValueChange}
                  active={value === option}>
                  {options[option]}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      )}
      {inputType === 'radio' && (
        <div className="d-flex flex-wrap gap-3 g-max-width-120 g-sm-max-width-none">
          {Object.keys(options).map((option) => {
            return (
              <Form.Label className="mb-0" key={option} htmlFor={`${key}_${option}`}>
                <Form.Check
                  className="d-inline-block g-mr-5"
                  type="radio"
                  id={`${key}_${option}`}
                  name={key}
                  value={option.toString()}
                  checked={value && value.toString() === option.toString()}
                  onChange={handleValueChange}
                />
                {options[option]}
              </Form.Label>
            );
          })}
        </div>
      )}
      {inputType === 'number' && (
        <Form.Group className="d-flex flex-column align-items-end">
          <Form.Control
            type="number"
            name={key}
            value={value}
            onChange={(e) => {
              const newValue = e.target.value;
              // Allow only 5 digits max
              if (newValue === '' || newValue.length <= 5) {
                handleValueChange(e);
              }

              if (newValue < MIN_RECORD_PER_PAGE) {
                e.target.setCustomValidity(`Value must be at least ${MIN_RECORD_PER_PAGE}`);
                e.target.reportValidity();
              } else {
                e.target.setCustomValidity('');
              }
            }}
            className="g-max-width-120 g-width-120"
          />
        </Form.Group>
      )}
    </div>
  );
}

SettingsInputField.propTypes = {
  fieldConfig: PropTypes.oneOfType([PropTypes.object]),
};

export default SettingsInputField;
