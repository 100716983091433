import React, { useLayoutEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Responsive from '@amcharts/amcharts5/themes/Responsive';
import { useSelector } from 'react-redux';
import { parseFloatOrZero } from '../../../utils/utils';

function ValuationChart({ lastDone, intrinsicValue, classes }) {
  const marginSafetyOfBounds = useSelector(
    (state) => state.intrinsicValueAnalysis.marginSafetyOfBounds,
  );

  // define yAxis color ranges
  const calculateFairValueBounds = () => {
    var newFairValueBounds = {};

    if (
      Object.keys(intrinsicValue).length === 0 ||
      isNaN(intrinsicValue.value_f) ||
      intrinsicValue.value <= 0
    ) {
      return newFairValueBounds;
    }

    newFairValueBounds['fair_value_lower_bound'] =
      intrinsicValue.value - (marginSafetyOfBounds[1] / 100) * Math.abs(intrinsicValue.value);
    newFairValueBounds['fair_value_upper_bound'] =
      intrinsicValue.value - (marginSafetyOfBounds[0] / 100) * Math.abs(intrinsicValue.value);

    return newFairValueBounds;
  };

  useLayoutEffect(() => {
    if (
      !intrinsicValue ||
      Object.keys(intrinsicValue).length === 0 ||
      intrinsicValue.value_f === 'n.a.' ||
      intrinsicValue.value <= 0
    ) {
      return;
    }

    var root = am5.Root.new('chartdiv');

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([am5themes_Responsive.new(root)]);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: 'none',
        panY: 'none',
        wheelX: 'none',
        wheelY: 'none',
        pinchZoom: false,
        paddingLeft: 0,
        paddingRight: 1,
        layout: root.verticalLayout,
      }),
    );

    chart.get('colors').set('colors', [am5.color(0x005399), am5.color(0x6894dd)]);

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    var xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 30,
      cellStartLocation: 0.3,
      cellEndLocation: 0.7,
      stroke: false,
    });

    xRenderer.labels.template.setAll({
      centerY: am5.p50,
      centerX: am5.p50,
    });

    var xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        maxDeviation: 0.3,
        categoryField: 'type',
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {}),
      }),
    );

    xAxis.get('renderer').grid.template.setAll({
      strokeWidth: 0,
      visible: false,
    });

    var yRenderer = am5xy.AxisRendererY.new(root, {
      opposite: true,
      strokeOpacity: 0.1,
    });
    yRenderer.grid.template.set('forceHidden', true);

    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        numberFormat: '#.0a',
        autoZoom: false,
        extraMax: 0.1,
        renderer: yRenderer,
      }),
    );

    // Add Range
    var chartData = [
      {
        type: 'Current Share Price',
        value: parseFloatOrZero(lastDone),
      },
      {
        type: 'Intrinsic Value',
        value: intrinsicValue.value,
      },
    ];

    var fairValueBounds = calculateFairValueBounds();
    var maxYValue =
      Math.max(fairValueBounds['fair_value_upper_bound'], ...chartData.map((item) => item.value)) *
      (1 + 0.2);

    var rangeConfig = [
      {
        name: 'Undervalue',
        from: 0,
        to: fairValueBounds['fair_value_lower_bound'],
        color: am5.color(0xddf7d4),
      },
      {
        name: 'Fair Value',
        from: fairValueBounds['fair_value_lower_bound'],
        to: fairValueBounds['fair_value_upper_bound'],
        color: am5.color(0xf7f5d6),
      },
      {
        name: 'Overvalue',
        from: fairValueBounds['fair_value_upper_bound'],
        to: maxYValue,
        color: am5.color(0xf4cccc),
      },
    ];

    for (var i = 0; i < rangeConfig.length; i++) {
      var rangeDataItem = yAxis.makeDataItem({
        value: rangeConfig[i].from,
        endValue: rangeConfig[i].to,
      });
      var range = yAxis.createAxisRange(rangeDataItem);
      rangeDataItem.get('axisFill').setAll({
        visible: true,
        fill: rangeConfig[i].color,
        stroke: rangeConfig[i].color,
        fillOpacity: 1,
      });
    }

    // legend
    const legend = chart.children.push(
      am5.Legend.new(root, {
        nameField: 'name',
        fillField: 'color',
        strokeField: 'color',
        centerX: am5.percent(50),
        x: am5.percent(50),
      }),
    );

    legend.data.setAll(rangeConfig);

    // Create series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/

    var series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: 'Series 1',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'value',
        sequencedInterpolation: true,
        categoryXField: 'type',
      }),
    );

    // add bullet to series
    series.bullets.push(function () {
      return am5.Bullet.new(root, {
        locationY: 0.99,
        sprite: am5.Label.new(root, {
          centerX: am5.percent(50),
          centerY: am5.percent(99),
          fontSize: 11,
          text: '{value.formatNumber("#,###.0")}',
          populateText: true,
        }),
      });
    });

    // Tooltip
    let tooltip = am5.Tooltip.new(root, {
      getFillFromSprite: false,
      autoTextColor: false,
      getStrokeFromSprite: false,
      labelText: '[bold]{type}[/]: {valueY.formatNumber("#,###.0")}',
    });

    tooltip.get('background').setAll({
      fill: am5.color(0xffffff),
      fillOpacity: 0.8,
      stroke: am5.color(0xcccccc),
      strokeOpacity: 0.8,
    });

    tooltip.label.setAll({
      fill: am5.color(0x000000),
    });

    series.set('tooltip', tooltip);

    series.columns.template.setAll({ cornerRadiusTL: 5, cornerRadiusTR: 5, strokeOpacity: 0 });
    series.columns.template.adapters.add('fill', function (fill, target) {
      return chart.get('colors').getIndex(series.columns.indexOf(target));
    });

    // Set data
    xAxis.data.setAll(chartData);
    series.data.setAll(chartData);

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    var cursor = chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        behavior: 'none',
      }),
    );
    cursor.lineY.set('forceHidden', true);
    cursor.lineX.set('forceHidden', true);

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    series.appear(1000);

    return () => {
      if (root) {
        root.dispose();
      }
    };
  }, [intrinsicValue]);

  return (
    <div
      className={`d-flex w-100 h-100 ${classes ? classes : 'g-max-height-550 g-min-height-450'}`}>
      {!intrinsicValue ||
      Object.keys(intrinsicValue).length === 0 ||
      intrinsicValue.value_f === 'n.a.' ||
      intrinsicValue.value < 0 ? (
        <div className="text-danger text-center align-self-center g-text-size-14">
          Calculation of Intrinsic Value for this company counter is unavailable because some
          parameters are missing or invalid.
        </div>
      ) : (
        <div id="chartdiv" className="w-100 h-100" />
      )}
    </div>
  );
}

export default ValuationChart;
