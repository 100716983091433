import React, { useLayoutEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Responsive from '@amcharts/amcharts5/themes/Responsive';
import PropTypes from 'prop-types';
import { seriesColors } from '../../../utils/utils';

function BarLineChart({ data, title }) {
  useLayoutEffect(() => {
    const root = am5.Root.new('stock_comparison_revenue');

    // responsive chart
    const responsive = am5themes_Responsive.newEmpty(root);
    root.setThemes([responsive]);

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: 'none',
        wheelY: 'none',
        layout: root.verticalLayout,
        paddingLeft: 0,
      }),
    );

    // set chart color
    chart.get('colors').set('colors', seriesColors);

    // Create Y-Axis
    const yRenderer = am5xy.AxisRendererY.new(root, {
      minGridDistance: 30,
      minorGridEnabled: true,
    });

    const yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        maxDeviation: 0,
        categoryField: 'name',
        renderer: yRenderer,
      }),
    );

    // Create X-Axis
    const xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0,
        min: 0,
        numberFormatter: am5.NumberFormatter.new(root, {
          numberFormat: '#,###a',
        }),
        extraMax: 0.1,
        renderer: am5xy.AxisRendererX.new(root, {
          strokeOpacity: 0.1,
          minGridDistance: 80,
        }),
      }),
    );

    // Create series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    const series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: 'Company Name',
        xAxis,
        yAxis,
        valueXField: 'value',
        categoryYField: 'name',
        sequencedInterpolation: true,
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: 'left',
          labelText: `[bold]{name}[/]\nRevenue: {valueX.formatNumber('#,###')}`,
        }),
      }),
    );

    // Rounded corners for columns
    series.columns.template.setAll({
      cornerRadiusTR: 5,
      cornerRadiusBR: 5,
      strokeOpacity: 0,
      height: am5.percent(30),
      maxHeight: 70,
    });

    series.columns.template.adapters.add('fill', function (fill, target) {
      return chart.get('colors').getIndex(series.columns.indexOf(target));
    });

    series.columns.template.adapters.add('stroke', function (stroke, target) {
      return chart.get('colors').getIndex(series.columns.indexOf(target));
    });

    yAxis.data.setAll(data);
    series.data.setAll(data);

    const cursor = chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        behavior: 'none',
      }),
    );
    cursor.lineY.set('visible', false);
    cursor.lineX.set('visible', false);

    series.appear();

    // Add legend
    // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
    const legend = chart.children.push(
      am5.Legend.new(root, {
        nameField: 'name',
        fillField: 'color',
        strokeField: 'color',
        centerX: am5.p50,
        x: am5.p50,
        y: am5.percent(95),
      }),
    );

    legend.markers.template.setAll({
      width: 15,
      height: 15,
    });

    legend.labels.template.setAll({
      fontSize: 12,
      fontWeight: '300',
    });

    legend.markerRectangles.template.setAll({
      cornerRadiusTL: 0,
      cornerRadiusTR: 0,
      cornerRadiusBL: 0,
      cornerRadiusBR: 0,
    });

    var legendData = data.map((counter, index) => {
      return {
        name: counter.name,
        color: chart.get('colors').getIndex(index),
      };
    });
    legend.data.setAll(legendData);

    return () => {
      root.dispose();
    };
  }, [data]);

  return (
    <>
      <h6>{title}</h6>
      <div id="stock_comparison_revenue" className="w-100 g-height-400" />
    </>
  );
}

BarLineChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  title: PropTypes.string,
};

export default BarLineChart;
