import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { membershipChooseAddOns } from '../membershipSlice';

export default function MarketDataAddOns({ country, realTimeBursaPriceKey }) {
  const dispatch = useDispatch();
  const plan = useSelector((state) => state.membership.plan);
  const addOns = useSelector((state) => state.membership.addOns);
  const marketType = useSelector((state) => state.membership.marketType);

  const onAddOnsChange = (event) => {
    const newValue = { ...addOns };
    newValue[event.target.name] = event.target.value === 'true';

    if (plan.includes('station')) {
      // additional add-ons for SGX real-time membership
      if (event.target.name === 'real-time_sgx_market_data') {
        newValue['real-time_ftse/st_indices'] = newValue[event.target.name];
      }

      // additional add-ons for Bursa real-time membership
      if (event.target.name === realTimeBursaPriceKey) {
        newValue['real-time_ftse/bm_index_data'] = newValue[event.target.name];
      }
    }

    dispatch(membershipChooseAddOns(newValue));
  };

  return (
    <div className="mx-2">
      {country === 'sg' && <MarketDataSGAddOn onAddOnsChange={onAddOnsChange} />}

      <div className="my-2 p-2 border-bottom">
        <h6>Malaysia Stock Prices - Equity</h6>
        <div className="form-check d-inline-block g-mr-20">
          <label className="form-check-label" htmlFor="bursa_delayed_market_data">
            <input
              type="radio"
              value={false}
              name={realTimeBursaPriceKey}
              className="form-check-input"
              id="bursa_delayed_market_data"
              onChange={onAddOnsChange}
              checked={!addOns[realTimeBursaPriceKey]}
            />
            Delayed (15 mins)
          </label>
        </div>
        <div className="form-check d-inline-block">
          <label className="form-check-label" htmlFor={realTimeBursaPriceKey}>
            <input
              type="radio"
              value
              name={realTimeBursaPriceKey}
              className="form-check-input"
              id={realTimeBursaPriceKey}
              onChange={onAddOnsChange}
              checked={addOns[realTimeBursaPriceKey]}
            />
            Real time
          </label>
        </div>
        {addOns[realTimeBursaPriceKey] && plan.includes('station') && (
          <div className="g-mt-10">
            <div className="g-mt-10">
              <i className="fa-regular fa-check g-mr-10" />
              Real-Time Stock Prices
            </div>
            <div className="g-mt-10">
              <i className="fa-regular fa-check g-mr-10" />
              Real-Time FTSE/BM Index Data
            </div>
          </div>
        )}
      </div>

      {plan.includes('station') && (
        <div className="my-2 p-2 border-bottom">
          <h6>Malaysia Stock Prices - Derivative</h6>
          <div className="form-check d-inline-block g-mr-20">
            <label className="form-check-label" htmlFor="bursa_delayed_derivative_market_data">
              <input
                type="radio"
                value={false}
                name="malaysia_real-time_stock_prices_derivative"
                className="form-check-input"
                id="bursa_delayed_derivative_market_data"
                onChange={onAddOnsChange}
                checked={!addOns['malaysia_real-time_stock_prices_derivative']}
              />
              Delayed (15 mins)
            </label>
          </div>
          <div className="form-check d-inline-block">
            <label
              className="form-check-label"
              htmlFor="malaysia_real-time_stock_prices_derivative">
              <input
                type="radio"
                value
                name="malaysia_real-time_stock_prices_derivative"
                className="form-check-input"
                id="malaysia_real-time_stock_prices_derivative"
                onChange={onAddOnsChange}
                checked={addOns['malaysia_real-time_stock_prices_derivative']}
              />
              Real time
            </label>
          </div>
          {addOns['malaysia_real-time_stock_prices_derivative'] && (
            <div className="g-mt-10">
              <div className="g-mt-10">
                <i className="fa-regular fa-check g-mr-10" />
                Real-Time Stock Prices
              </div>
            </div>
          )}
        </div>
      )}

      {country !== 'sg' && <MarketDataSGAddOn onAddOnsChange={onAddOnsChange} />}

      {marketType !== 'local' && (
        <>
          <div className="my-2 p-2 border-bottom">
            Hong Kong - Delayed (15 mins)<sup>{!plan.includes('station') ? '2' : '4'}</sup>
          </div>

          <div className="my-2 p-2 border-bottom">Indonesia - Delayed (10 mins)</div>

          <div className="my-2 p-2 border-bottom">US, Thailand, Australia - EOD</div>
        </>
      )}
    </div>
  );
}

MarketDataAddOns.propTypes = {
  country: PropTypes.string,
  realTimeBursaPriceKey: PropTypes.string,
};

function MarketDataSGAddOn({ onAddOnsChange }) {
  const plan = useSelector((state) => state.membership.plan);
  const addOns = useSelector((state) => state.membership.addOns);

  if (plan.includes('_local')) {
    return null;
  }

  return (
    <div className="my-2 p-2 border-bottom">
      <h6 className="g-mb-10">Singapore Stock Prices - Equity</h6>
      <div className="form-check d-inline-block g-mr-20">
        <label className="form-check-label" htmlFor="sgx_delayed_market_data">
          <input
            type="radio"
            value={false}
            name="real-time_sgx_market_data"
            id="sgx_delayed_market_data"
            className="form-check-input"
            onChange={onAddOnsChange}
            checked={!addOns['real-time_sgx_market_data']}
          />
          Delayed (10 mins)
        </label>
      </div>
      <div className="form-check d-inline-block">
        <label className="form-check-label" htmlFor="real-time_sgx_market_data">
          <input
            type="radio"
            value
            name="real-time_sgx_market_data"
            id="real-time_sgx_market_data"
            className="form-check-input"
            onChange={onAddOnsChange}
            checked={addOns['real-time_sgx_market_data']}
          />
          Real time
        </label>
      </div>
      {addOns['real-time_sgx_market_data'] && plan.includes('station') && (
        <div className="g-mt-10">
          <div className="g-mt-10">
            <i className="fa-regular fa-check g-mr-10" />
            Real-Time Stock Prices
          </div>
          <div className="g-mt-10">
            <i className="fa-regular fa-check g-mr-10" />
            Real-Time FTSE/ST Indices
          </div>
        </div>
      )}
    </div>
  );
}

MarketDataSGAddOn.propTypes = {
  onAddOnsChange: PropTypes.func,
};
