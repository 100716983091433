import { DataSubscription, BaseTable, pushPage } from './protocol';
import { VisualTable } from './tables';
import { resetModeToStreaming } from ".";

let counterQuoteTable = null;
let counterDelay = -1;
let defaultDivId = 'sic_counterQuoteInfo';

export function startQuoteStreamingForCounter(counter, delay, streamingId) {
  if (typeof document === 'undefined' || !(counter && counter.match(/\.(SI|MY|JK|HK|WLD)/))) {
    return;
  }

  let divId = defaultDivId;
  if (streamingId){
    divId = streamingId;
  }

  const counterQuoteDiv = document.getElementById(divId);
  if (!(counterQuoteDiv && pushPage)) {
    return;
  };

  counterDelay = delay;
  // ensure pushPage will be in streaming mode
  resetModeToStreaming();
  counterQuoteTable = new BaseTable(divId, new DataSubscription("Counter", counter, []));
  counterQuoteTable.onDataUpdate = function(updateData) {
    onCounterQuoteUpdate(updateData, divId);
  };
  //counter_quote_table.setSubscription(data_subscription);
  pushPage.scheduleTable(counterQuoteTable);
  pushPage.createEngine(); // this does nothing if pushPage engine is already running
};

export function stopQuoteStreaming() {
  if (counterQuoteTable && pushPage) {
    pushPage.cancelTable(counterQuoteTable);
  }
  counterQuoteTable = null;
};

function applyUpdateEffect(new_value_, field_, cell_) {
  const old_value = cell_.dataset[field_];
  const dynamicEffect = getNumericDynamicUpdateEffect(old_value, new_value_, field_);
  VisualTable.applyDynamicEffect(cell_, dynamicEffect, 3000);
  cell_.dataset[field_] = new_value_;// store raw value
}

function updatePrice(selectorId, updateData, streamingId) {
  const last_done_cell = document.querySelector(`#${streamingId} #${selectorId}`);
  const last_done_f = counterQuoteTable.formatField('price', updateData.price);
  if (last_done_cell){
    last_done_cell.innerHTML = last_done_f;
    applyUpdateEffect(updateData.price, 'price', last_done_cell);
  }
};

function updateChange(selectorId, updateData, streamingId) {
  const chg_perc_change_cell = document.querySelector(`#${streamingId} #${selectorId}`);
  const chg_f = counterQuoteTable.formatField('change', updateData.change);
  const perc_chg_f = counterQuoteTable.formatField('perc_change', updateData.perc_change);
  if (chg_perc_change_cell){
    chg_perc_change_cell.innerHTML = `${chg_f} (${perc_chg_f}%)`;
    applyUpdateEffect(updateData.change, 'change', chg_perc_change_cell);
    chg_perc_change_cell.classList.remove('text-success');
    chg_perc_change_cell.classList.remove('text-danger');
    if (updateData.change > 0) {
      chg_perc_change_cell.classList.add('text-success');
    } else if (updateData.change < 0){
      chg_perc_change_cell.classList.add('text-danger');
    }
  }
};

//sample data
//{"sell_vol":2000,"buy":"2.230","status":"","change":"0.030","code":"H15.SI","short_name":"HPL","reference":"2.280","buy_vol":2000,"perc_change":"1.32","price":"2.310","update_time":"20110622144500","sell":"2.310","volume":2000,"value":"4620.000","high":"2.310","itemPos":"H15.SI","low":"2.310","full_name":"HOTEL PROPERTIES LTD"}
function onCounterQuoteUpdate(updateData, streamingId) {

  if ('price' in updateData) {
    updatePrice('sic_counterQuote_lastdone', updateData, streamingId);
    updatePrice('sic_counterQuoteMini_lastdone', updateData, streamingId);
  }

  if ('change' in updateData && 'perc_change' in updateData) {
    updateChange('sic_counterQuote_chg_perc_change', updateData, streamingId);
    updateChange('sic_counterQuoteMini_chg_perc_change', updateData, streamingId);
  }

  if ('volume' in updateData) {
    const volume_cell = document.querySelector(`#${streamingId} #sic_counterQuote_total_volume`);
    if (volume_cell){
      volume_cell.innerHTML = counterQuoteTable.formatField('volume', updateData.volume);
      applyUpdateEffect(updateData.volume, 'volume', volume_cell);
    }
  }

  if ('high' in updateData) {
    const high_cell = document.querySelector(`#${streamingId} #sic_counterQuote_high`);
    if (high_cell){
      high_cell.innerHTML = counterQuoteTable.formatField('high', updateData.high);
      applyUpdateEffect(updateData.high, "high", high_cell);
    }
  }

  if ('low' in updateData) {
    const low_cell = document.querySelector(`#${streamingId} #sic_counterQuote_low`);
    if (low_cell){
      low_cell.innerHTML = counterQuoteTable.formatField('low', updateData.low);
      applyUpdateEffect(updateData.low, "low", low_cell);
    }
  }

  if ('buy_vol' in updateData) {
    const buy_vol_cell = document.querySelector(`#${streamingId} #sic_counterQuote_buy_volume`);
    if (buy_vol_cell){
      buy_vol_cell.innerHTML = counterQuoteTable.formatField('buy_vol', updateData.buy_vol);
      applyUpdateEffect(updateData.buy_vol, 'buy_vol', buy_vol_cell);
    }
  }

  if ('sell_vol' in updateData) {
    const sell_vol_cell = document.querySelector(`#${streamingId} #sic_counterQuote_sell_volume`);
    if (sell_vol_cell){
      sell_vol_cell.innerHTML = counterQuoteTable.formatField('sell_vol', updateData.sell_vol);
      applyUpdateEffect(updateData.sell_vol, 'sell_vol', sell_vol_cell);
    }
  }

  if ('buy' in updateData) {
    const buy_cell = document.querySelector(`#${streamingId} #sic_counterQuote_buy_price`);
    if (buy_cell){
      buy_cell.innerHTML = counterQuoteTable.formatField('buy', updateData.buy);
      applyUpdateEffect(updateData.buy, 'buy', buy_cell);
    }
  }

  if ('sell' in updateData) {
    const sell_cell = document.querySelector(`#${streamingId} #sic_counterQuote_sell_price`);
    if (sell_cell){
      sell_cell.innerHTML = counterQuoteTable.formatField('sell', updateData.sell);
    applyUpdateEffect(updateData.sell, 'sell', sell_cell);
    }
  }

  if ('value' in updateData) {
    const value_cell = document.querySelector(`#${streamingId} #sic_counterQuote_total_value`);
    if (value_cell){
      value_cell.innerHTML = counterQuoteTable.formatField('value', updateData.value);
      applyUpdateEffect(updateData.value, 'value', value_cell);
    }
  }

  if ('update_time' in updateData && show_last_updatetime(updateData.update_time)) {
    const last_updated_cell = document.querySelector(`#${streamingId} #sic_counterQuote_last_updated`);
    const last_updated_f = formatDateTime(String(updateData.update_time), 1, {
      "month_starts_with_1": true,
    });
    if (last_updated_cell){
      if (counterDelay === 1440) {
        last_updated_cell.innerHTML =
          `Updated on ${last_updated_f.substr(0, 11)}. (End of trading day)`;
      }
      else if (counterDelay > 0) {
        last_updated_cell.innerHTML = `Quotes ${counterDelay} minutes delayed. Updated at ${last_updated_f}.`;
      }
      else {
        last_updated_cell.innerHTML = `Updated at ${last_updated_f}.`;
      }
    }
  }
}
