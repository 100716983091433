import axios from 'axios';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
import { useSearchParams, useLocation } from 'react-router-dom';

import api from '../../../utils/api';
import Loader from '../../../components/Loader';
import APIErrorHandler from '../../../components/APIErrorHandler';
import { createLoadableComponent } from '../../../utils/Loadable';

const SeasonalityChart = createLoadableComponent(() => import('./SeasonalityChart'));
const { CancelToken } = axios;
const TYPE = 'price';

function SeasonalityChartTab({ counter }) {
  const source = CancelToken.source();
  const [searchParams, setSearchParams] = useSearchParams();
  const [error, setError] = useState();
  const [type, setType] = useState(searchParams.get('seasonality_view') || TYPE);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const [isSSR, setIsSSR] = useState(true);

  const handleTypeChange = (newType) => {
    if (type === newType) {
      return;
    }
    setSearchParams({ seasonality_view: newType });
    setType(newType);
  };

  useEffect(() => {
    if (!type) {
      return;
    }

    const cancelToken = source.token;
    setIsLoading(true);

    api
      .get(`quote/${counter}/seasonality_chart.json?type=${type}`, { cancelToken })
      .then(({ data }) => {
        setData(data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
      });
  }, [type]);

  useEffect(() => {
    if (type !== searchParams.get('seasonality_view')) {
      setType(searchParams.get('seasonality_view') || TYPE);
    }
  }, [location]);

  useEffect(() => {
    setIsSSR(typeof document === 'undefined');

    return () => {};
  }, []);

  if (isSSR) {
    return <Loader visible={isSSR} classes="g-height-800" />;
  }

  if (error) {
    return <APIErrorHandler error={error} />;
  }

  return (
    <>
      <h4 className="g-md-mb-30">Seasonality Chart</h4>
      <Loader visible={isLoading} />
      {!isLoading && (!data || data.length === 0) && (
        <div className="text-center">No data found.</div>
      )}
      {!isLoading && data && data.length > 0 && (
        <>
          <Dropdown className="g-mb-20">
            <Dropdown.Toggle variant="light">
              {type === 'price' ? 'By Price' : 'By Volume'}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => handleTypeChange('price')}>By Price</Dropdown.Item>
              <Dropdown.Item onClick={() => handleTypeChange('volume')}>By Volume</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <SeasonalityChart type={type} data={data} />
          <div className="notes text-muted small g-md-mt-50 g-md-mb-80">
            <div className="fw-bold">Notes:</div>
            <ol type="a">
              <li>
                <div>
                  Seasonality charts are calculated using adjusted historical data. Adjustments
                  include
                  <ul>
                    <li> Special Dividend</li>
                    <li> Dividend-In-Specie</li>
                    <li> Capital Reduction and Cash Distribution</li>
                    <li> Bonus Issue</li>
                    <li> Rights Issue / Preferential Offer / Open Offer</li>
                    <li> Share Split / Share Consolidation</li>
                  </ul>
                </div>
              </li>
            </ol>
          </div>
        </>
      )}
    </>
  );
}

SeasonalityChartTab.propTypes = {
  counter: PropTypes.string,
};

export default SeasonalityChartTab;
