import { DataSubscription, pushPage } from "./protocol";
import { VisualTable } from "./tables";
import { resetModeToStreaming } from ".";
import {
  formatDecimal,
  formatNumberAddCommas,
  formatPrice,
  getSymbolFromCounter,
} from '../utils';


const qmStreamTable = {
  counter: null,
  isIndex: false,
  visualTable: null,
  isAlreadyStreaming: false,
  referencePrice: 0,
  precision: 0,
  totalVolume: 0,
  totalVolumeFromStatic: 0,
  curPage: 1,
  maxRowsInPage: 50,
  shouldStream: false,
  lastDonePrice: 0,
  lastAskPrice: 0,
  lastBidPrice: 0,
  lastAskVolume: 0,
  lastBidVolume: 0,
  volumeChangeSpan: null,
  buyAndSell: false,
  askVol: false,
  bidVol: false,
  previousField: null,
  isFirst: true,
  lastPosition: 0,
};

export const startQuoteMovementsStreaming = function() {
  const qmTable = document.getElementById('sic_quoteMovementTable');
  if (!qmTable || qmStreamTable.isAlreadyStreaming) {
    return;
  }

  // update qmStreamTable fields based on sic_quoteMovementTable dataset
  qmStreamTable.counter = getSymbolFromCounter(qmTable.dataset.counter);
  qmStreamTable.isIndex = qmTable.dataset.is_index === 'true';
  qmStreamTable.referencePrice = Number(qmTable.dataset.reference_price);
  qmStreamTable.precision = Number(qmTable.dataset.precision);
  if (Number(qmTable.dataset.cur_page)) {
    qmStreamTable.curPage = Number(qmTable.dataset.cur_page);
  }
  if (Number(qmTable.dataset.max_rows_per_page)) {
    qmStreamTable.maxRowsInPage = Number(qmTable.dataset.max_rows_per_page);
  }
  qmStreamTable.shouldStream = qmTable.dataset.streaming_quote_movements === 'true';
  qmStreamTable.totalVolume = 0;
  qmStreamTable.totalVolumeFromStatic = getLastVolumeFromStatic();

  // ensure pushPage will be in streaming mode
  resetModeToStreaming();

  var dataSubscription = new DataSubscription("QuoteMovement", qmStreamTable.counter, ['time', 'type', 'volume', 'price', 'type', 'null', 'null', 'null', 'null']);
  if (!qmStreamTable.visualTable) {
    qmStreamTable.visualTable = constructStreamingTable(dataSubscription);
  }

  if (qmStreamTable.visualTable) {
    const last_row_price_vol = get_last_row_price_and_vol();
    qmStreamTable.visualTable.last_row_data = {
      'price' : last_row_price_vol.price,
      'volume' : last_row_price_vol.volume,
    };
    qmStreamTable.visualTable.last_item_pos = null;
  
    if (qmStreamTable.shouldStream && qmStreamTable.referencePrice &&
      (qmStreamTable.curPage == 1 || qmStreamTable.curPage == -1))
    {
      qmStreamTable.visualTable.setSubscription(dataSubscription);
      pushPage.scheduleTable(qmStreamTable.visualTable);
      pushPage.createEngine(); // this does nothing if pushPage engine is already running
      qmStreamTable.isAlreadyStreaming = true;
    }
  }
}

export const stopQuoteMovementsStreaming = function() {
  if (!(qmStreamTable.visualTable && qmStreamTable.isAlreadyStreaming)) {
    return;
  }
  if (qmStreamTable.visualTable) {
    pushPage.cancelTable(qmStreamTable.visualTable);
    // note: set streaming table(s) to null for GC
    qmStreamTable.visualTable = null;
  }
  qmStreamTable.isAlreadyStreaming = false;
}

function getFirstRowCells() {
  const qmTable = document.getElementById('sic_quoteMovementTable');
  return (qmTable ? qmTable.querySelector('tbody > tr')?.cells : []);
}

function getLastVolumeFromStatic() {
  const firstRowCells = getFirstRowCells();
  const buy_volume_from_static = parseFloat(firstRowCells[1]?.innerHTML?.replaceAll(/ *\([^)]*\) */g, "").replaceAll(',', '').replaceAll(/[&]nbsp[;]/gi, "")) || 0;
  const last_done_volume_from_static = parseFloat(firstRowCells[3]?.innerHTML?.replaceAll(/ *\([^)]*\) */g, "").replaceAll(',', '').replaceAll(/[&]nbsp[;]/gi, "")) || 0;
  const sell_volume_from_static = parseFloat(firstRowCells[6]?.innerHTML?.replaceAll(/ *\([^)]*\) */g, "").replaceAll(',', '').replaceAll(/[&]nbsp[;]/gi, "")) || 0;

  var total_volume_from_static = 0;
  if (buy_volume_from_static) {
    total_volume_from_static = buy_volume_from_static;
  }
  else if (last_done_volume_from_static) {
    total_volume_from_static = last_done_volume_from_static;
  }
  else {
    total_volume_from_static = sell_volume_from_static;
  }
  return total_volume_from_static;
}

function calculate_last_done_time() {
  const firstRowCells = getFirstRowCells();
  return parseFloat(firstRowCells[0]?.innerHTML?.replaceAll(':', '')) || 0;
}

function get_last_row_price_and_vol() {
  const firstRowCells = getFirstRowCells();
  const last_row_price_buy = parseFloat(firstRowCells[2]?.innerHTML?.replaceAll(',', '')) || 0;
  const last_row_price_last_done = parseFloat(firstRowCells[4]?.innerHTML?.replaceAll(',', '')) || 0;
  const last_row_price_sell = parseFloat(firstRowCells[7]?.innerHTML?.replaceAll(',', '')) || 0;
  var last_row_price = 0;
  var last_row_volume = 0;
  if (last_row_price_buy) {
    last_row_price = last_row_price_buy;
    last_row_volume = parseFloat(firstRowCells[1]?.innerHTML?.replaceAll(/ *\([^)]*\) */g, "").replaceAll(',', '')) || 0;
  }
  else if(last_row_price_last_done) {
    last_row_price = last_row_price_last_done;
    last_row_volume = parseFloat(firstRowCells[3]?.innerHTML?.replaceAll(/ *\([^)]*\) */g, "").replaceAll(',', '')) || 0;
  }
  else {
    last_row_price = last_row_price_sell;
    last_row_volume = parseFloat(firstRowCells[6]?.innerHTML?.replaceAll(/ *\([^)]*\) */g, "").replaceAll(',', '')) || 0;
  }
  return {
    price: last_row_price,
    volume: last_row_volume,
    display: `${last_row_price}-${last_row_volume}`,
  }
}

function volumeCheckHighlight(currentVolume, previousVolume, lastDonePrice, currentPrice) {
  const volChg = (parseFloat(currentVolume) || 0) - (parseFloat(previousVolume) || 0);
  if (lastDonePrice === currentPrice) {
    if (volChg > 0){
      qmStreamTable.volumeChangeSpan = `(<span class='sic_up'>${
        formatNumberAddCommas(formatDecimal(volChg, 3, false))
      }</span>) `;
    }
    else {
      qmStreamTable.volumeChangeSpan = `(<span class='sic_down'>${
        formatNumberAddCommas(formatDecimal(volChg, 3, false))
      }</span>) `;
    }
  }
  else {
    qmStreamTable.volumeChangeSpan = null;
  }
}

function formatQuoteMovementTypeFromStream(value_) {
  var formated_val;
  switch (value_)
  {
    case 'B':
      formated_val = 'Buy Up';
      break;
    case 'S':
      formated_val = 'Sell Down';
      break;
    case 'PC':
      formated_val = 'Preclose';
      break;
    case 'PO':
      formated_val = 'Preopen';
      break;
    case 'UN':
      formated_val = 'Unknown';
      break;
    case 'M':
      formated_val = 'Mid';
      break;
    case 'AJ':
      formated_val = 'Adjust';
      break;
    case 'Bid':
      formated_val = 'Bid';
      break;
    case 'Ask':
      formated_val = 'Ask';
      break;
    case 'DN':
      formated_val = 'Direct Normal';
      break;
    case 'MN':
      formated_val = 'Married Normal';
      break;
    case 'DO':
      formated_val = 'Direct Odd';
      break;
    case 'MO':
      formated_val = 'Married Odd';
      break;
    case 'TC':
      formated_val = 'Trade Cancel';
      break;
    case 'SL':
      formated_val = 'Strategy Leg';
      break;
    case 'CP':
      formated_val = 'Close Price';
      break;
    default:
      formated_val = 'Adjust';//somehow comet server doesn't provide AJ for the quote movement, so we have to default it to be Adjust
  }
  return formated_val;
}

function constructStreamingTable(data_subscription_) {
  var quoteMovementTable = new VisualTable("sic_quoteMovementTable", data_subscription_);

  quoteMovementTable.stop_streaming = true;

  quoteMovementTable.onUpdating = function()
  {
    this.stop_streaming = false;
    var quote_movement_table = document.getElementById(this.html_id);
    var has_data = quote_movement_table.querySelectorAll('tbody > tr > td').length > 2;
    if (!has_data)
    {
      quote_movement_table.querySelector('tbody').innerHTML = '';
    }
  };

  quoteMovementTable.beforeRowAdd = function (parent_node_, new_row_)
  {
    //always insert at the top row
    return $("tr:first", parent_node_);
  };

  quoteMovementTable.allowUpdate = function(update_)
  {
    //don't allow update if the stop_streaming flag is true
    if (this.stop_streaming)
      return false;

    if (qmStreamTable.isFirst) {
      const update_time = parseFloat(update_.time?.substring(8)) || 0;
      if (!update_time) {
        return false;
      }
      //find last position by matching all fields(time , price ,volume) from static and from streaming
      const last_row_price_time = calculate_last_done_time();
      const last_row_price_vol = get_last_row_price_and_vol();
      const update_price_vol = `${update_.price}-${update_.volume}`;
      if ((update_time > last_row_price_time) || (update_time == last_row_price_time && last_row_price_vol.display == update_price_vol)) {
        qmStreamTable.lastPosition = update_.itemPos;
        qmStreamTable.isFirst = false;
      }
      else {
        return false;
      }
    }

    if (qmStreamTable.lastPosition == 0)
      return false;

    var allowUpdate = false;
    qmStreamTable.totalVolume += update_.volume;

    //only start inserting data with the new data ,differentiated by itempos
    if (qmStreamTable.lastPosition >= update_.itemPos) {
      allowUpdate = false;
    }
    //only start inserting data when the total volume is more than the total volume retrieved from static data
    else if (!(
      Number(qmStreamTable.totalVolume.toFixed(3)) > Number(qmStreamTable.totalVolumeFromStatic.toFixed(3)) &&
      update_.code === qmStreamTable.counter
    )) {
      allowUpdate = false;
    }
    else if (this.last_item_pos && this.last_item_pos >= update_.itemPos) {
      //if the last item position is set and last item position is >= current item position, then don't insert the data
      //this is to prevent the time and sales snapshot data is resent by the streaming server
      allowUpdate = false;
    }
    else {
      allowUpdate = true;
    }

    this.last_item_pos = update_.itemPos;
    return allowUpdate;
  };

  quoteMovementTable.prepareFieldUpdate = function(field_, cell_, updates_)
  {
    updates_.change = updates_.price - qmStreamTable.referencePrice;
    updates_.perc_change = updates_.change / qmStreamTable.referencePrice * 100;
    updates_.total_volume = qmStreamTable.totalVolume;
  };

  quoteMovementTable.prepareCellUpdate = function(update_, field_, change_whole_row_, cell_)
  {
    var curValue = update_.newValue;

    // quote_movement type
    if (field_ == "type") {
      switch(update_.type)
      {
        case 'B':
          update_.addClassNames = 'sic_up';
          qmStreamTable.buyAndSell = true;
          qmStreamTable.askVol = false;
          qmStreamTable.bidVol = false;
          break;
        case 'S':
          update_.addClassNames = 'sic_down';
          qmStreamTable.buyAndSell = true;
          qmStreamTable.askVol = false;
          qmStreamTable.bidVol = false;
          break;
        case 'Ask':
          update_.addClassNames = 'sic_reset_info';
          qmStreamTable.buyAndSell = false;
          qmStreamTable.askVol = true;
          qmStreamTable.bidVol = false;
          break;
        case 'Bid':
          update_.addClassNames = 'sic_reset_info';
          qmStreamTable.buyAndSell = false;
          qmStreamTable.askVol = false;
          qmStreamTable.bidVol = true;
          break;
        default:
          update_.addClassNames = 'sic_reset_info';
          qmStreamTable.buyAndSell = false;
          qmStreamTable.askVol = false;
          qmStreamTable.bidVol = false;
      }
    }
    // quote_movement price and change
    if (field_ == "price" || field_ == "change") {
      if (qmStreamTable.buyAndSell === true) {
        if (update_.price > qmStreamTable.lastDonePrice)
          update_.addClassNames = "sic_upBackground";
        else if (update_.price < qmStreamTable.lastDonePrice)
          update_.addClassNames = "sic_downBackground";
      }
      if (qmStreamTable.askVol === true){
        if (update_.price > qmStreamTable.lastAskPrice)
          update_.addClassNames = "sic_upBackground";
        else if (update_.price < qmStreamTable.lastAskPrice)
          update_.addClassNames = "sic_downBackground";
      }
      if (qmStreamTable.bidVol === true) {
        if (update_.price > qmStreamTable.lastBidPrice)
          update_.addClassNames = "sic_upBackground";
        else if (update_.price < qmStreamTable.lastBidPrice)
          update_.addClassNames = "sic_downBackground";
      }
    }
    // quote_movement volume
    if (field_ === "volume") {
      if (qmStreamTable.buyAndSell === true) {
        if (update_.price > qmStreamTable.lastDonePrice) {
          update_.addClassNames = "sic_upBackground";
        }
        else if (update_.price < qmStreamTable.lastDonePrice) {
          update_.addClassNames = "sic_downBackground";
        }
      }
      if (qmStreamTable.askVol === true){
        if (update_.price > qmStreamTable.lastAskPrice) {
          update_.addClassNames = "sic_upBackground";
        }
        else if (update_.price < qmStreamTable.lastAskPrice) {
          update_.addClassNames = "sic_downBackground";
        }
      }
      if (qmStreamTable.bidVol === true) {
        if (update_.price > qmStreamTable.lastBidPrice){
          update_.addClassNames = "sic_upBackground";
        }
        else if (update_.price < qmStreamTable.lastBidPrice) {
          update_.addClassNames = "sic_downBackground";
        }
      }
    }
    if (qmStreamTable.askVol === true){
      volumeCheckHighlight(
        update_.volume,
        qmStreamTable.lastAskVolume,
        qmStreamTable.lastAskPrice,
        update_.price
      );
    }
    if (qmStreamTable.bidVol === true) {
      volumeCheckHighlight(
        update_.volume,
        qmStreamTable.lastBidVolume,
        qmStreamTable.lastBidPrice,
        update_.price
      );
    }
    if (qmStreamTable.volumeChangeSpan && (update_.type == 'Ask' || update_.type == 'Bid')) {
      update_.volume = qmStreamTable.volumeChangeSpan + update_.volume;
    }

    if (!change_whole_row_) {
      update_.dynamicEffect = getNumericDynamicUpdateEffect(update_.oldValue, curValue, field_);
    }

    if (qmStreamTable.previousField === "time") {
      update_.addClassNames = 'sic_hidden';
    }

    qmStreamTable.previousField = field_;
  };

  quoteMovementTable.afterRowUpdate = function(row_, update_)
  {
    VisualTable.applyDynamicEffect(row_, "sic_neutralBackground", 4000);
    var rows = $('#' + this.html_id).find("tbody > tr");
    var value = update_.price * update_.volume;

    // highlight row if the value is greater than 150000
    if (value > 150000 && !qmStreamTable.isIndex) {
      row_.addClass("sic_highlight");
    }

    this.adjustCurrentDisplay(rows);

    //store last row price so as can compare row change when new time and sales comes in
    switch(update_.type)
    {
      case 'B':
        qmStreamTable.lastDoneVolume = update_.volume;
        qmStreamTable.lastDonePrice = update_.price;
        break;
      case 'S':
        qmStreamTable.lastDoneVolume = update_.volume;
        qmStreamTable.lastDonePrice = update_.price;
        break;
      case 'Ask':
        qmStreamTable.lastAskVolume = update_.volume;
        qmStreamTable.lastAskPrice = update_.price;
        break;
      case 'Bid':
        qmStreamTable.lastBidVolume = update_.volume;
        qmStreamTable.lastBidPrice = update_.price;
        break;
      default:
    }

    $('.sic_reset_info').html(' ');
    this.last_row_data = update_;
  };

  quoteMovementTable.adjustCurrentDisplay = function (rows_)
  {
    if (qmStreamTable.curPage == 1 && rows_.length > qmStreamTable.maxRowsInPage)
    {
      //remove last row only if it is the first page
      rows_.last().remove();
    }
  };

  quoteMovementTable.formatField = function(field_, value_, volume_size_)
  {
    var volChange = null;
    if (field_ === 'volume') {
        value_ = value_.toString();
        volChange = value_.match(/ *\([^)]*\) */g, "");
        value_ = value_.replace(/ *\([^)]*\) */g, "");
    }

    var formated_val = null;
    if (field_ == "time") {
      var time = value_ + "";
      var hour = time.substr(8, 2);
      var min = time.substr(10, 2);
      var second = time.substr(12, 2);
      formated_val = hour + ":" + min + ":" + second
    }
    else if (field_ == "type") {
      formated_val = formatQuoteMovementTypeFromStream(value_)
    }
    else if (field_ == "price") {
      formated_val = formatPrice(parseFloat(value_), qmStreamTable.precision);
    }
    else {
      formated_val = formatNumberAddCommas(formatDecimal(value_, 3, false));
    }
    if (field_ === 'volume' && volChange) {
      formated_val = volChange + formated_val;
    }
    return formated_val;
  };

  return quoteMovementTable;
}
