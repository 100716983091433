import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './textSearchFilter.module.css';

export default function TextSearchFilter({
  column: { Header, filterValue, preFilteredRows, setFilter },
}) {
  const [showSearchBar, setShowSearchBar] = useState(false);
  const handleOnClickButton = () => {
    setFilter(undefined);
    setShowSearchBar(!showSearchBar);
  };

  return showSearchBar ? (
    <div className="searchBarWrapper d-flex flex-wrap position-relative">
      <input
        className="form-control py-0"
        value={filterValue || ''}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
        autoFocus
        placeholder="Search..."
      />
      <button type="button" className={`${styles.closeButton}`} onClick={handleOnClickButton}>
        <i className="fa-light fa-xmark" />
      </button>
    </div>
  ) : (
    <>
      {Header}
      <button type="button" className={`${styles.searchButton}`} onClick={handleOnClickButton}>
        <i className="fa-light fa-magnifying-glass" />
      </button>
    </>
  );
}

TextSearchFilter.propTypes = {
  column: PropTypes.oneOfType([PropTypes.object]),
};
