import React from 'react';

import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import SettingsInputField from './SettingsInputField';

function SettingsPrices({ timeAndSalesPerPage, quoteMovementsPerPage, historicalPricePerPage }) {
  const timeAndSalesConfig = {
    key: 'time_and_sales_records_per_page',
    name: 'Number of Time and Sales Records per Page',
    icon: 'fa-light fa-display-chart-up-circle-dollar',
    inputType: 'number',
    value: timeAndSalesPerPage,
  };

  const quoteMovementsConfig = {
    key: 'quote_movements_records_per_page',
    name: 'Number of Quote Movements Records per Page',
    icon: 'fa-light fa-chart-pie',
    inputType: 'number',
    value: quoteMovementsPerPage,
  };

  const historicalPriceConfig = {
    key: 'historical_price_records_per_page',
    name: 'Number of Historical Price Records per Page',
    icon: 'fa-light fa-chart-line-up-down',
    inputType: 'number',
    value: historicalPricePerPage,
  };

  return (
    <Card className="g-mb-30">
      <Card.Body>
        <Card.Title className="h5">Prices Settings</Card.Title>
        <ul className="list-unstyled">
          <li className="g-mb-10">
            <SettingsInputField fieldConfig={timeAndSalesConfig} />
          </li>
          <li className="g-mb-10">
            <SettingsInputField fieldConfig={quoteMovementsConfig} />
          </li>
          <li>
            <SettingsInputField fieldConfig={historicalPriceConfig} />
          </li>
        </ul>
      </Card.Body>
    </Card>
  );
}
SettingsPrices.propTypes = {
  streamingMode: PropTypes.string,
  timeAndSalesPerPage: PropTypes.number,
  quoteMovementsPerPage: PropTypes.number,
  historicalPricePerPage: PropTypes.number,
};
export default SettingsPrices;
