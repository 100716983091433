import React from 'react';
import PropTypes from 'prop-types';
import { StaticRouter } from 'react-router-dom/server';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import Content from './Content';
import NotFound from '../NotFound';

export default function Router({ routes, activeNav }) {
  if (!routes) {
    return null;
  }

  if (typeof document === 'undefined') {
    return (
      <StaticRouter>
        <Content activeNav={activeNav} routes={routes} currentLevel={0} />
      </StaticRouter>
    );
  }

  routes.push({
    path: '*',
    element: <NotFound />,
  });

  const router = createBrowserRouter(routes);
  return <RouterProvider router={router} />;
}

Router.propTypes = {
  routes: PropTypes.array,
  activeNav: PropTypes.array,
};
