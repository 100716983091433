import React from 'react';
import PropTypes from 'prop-types';

function FilterTradeTypes({ checkboxTradeTypes, handleCheckboxTradeTypesChange, showAlert }) {
  return (
    <div className="d-flex gap-3 flex-row align-items-center">
      <div className="position-relative">
        <div className="form-check form-check-inline mb-0">
          <input
            className="form-check-input"
            name="insider_dir_ceo"
            checked={checkboxTradeTypes.insider_dir_ceo}
            onChange={handleCheckboxTradeTypesChange}
            type="checkbox"
            id="searchTradeType1"
          />
          <label className="form-check-label align-text-top" htmlFor="searchTradeType1">
            DIR/CEO
          </label>
        </div>
        <div className="form-check form-check-inline mb-0">
          <input
            className="form-check-input"
            name="insider_tm_rp"
            checked={checkboxTradeTypes.insider_tm_rp}
            onChange={handleCheckboxTradeTypesChange}
            type="checkbox"
            id="searchTradeType2"
          />
          <label className="form-check-label align-text-top" htmlFor="searchTradeType2">
            TM/RP
          </label>
        </div>
        <div className="form-check form-check-inline mb-0">
          <input
            className="form-check-input"
            name="insider_ssh"
            checked={checkboxTradeTypes.insider_ssh}
            onChange={handleCheckboxTradeTypesChange}
            type="checkbox"
            id="searchTradeType3"
          />
          <label className="form-check-label align-text-top" htmlFor="searchTradeType3">
            SSH
          </label>
        </div>
        {showAlert && (
          <div className="position-absolute text-danger">Please select at least 1.</div>
        )}
      </div>
    </div>
  );
}

FilterTradeTypes.propTypes = {
  checkboxTradeTypes: PropTypes.oneOfType([PropTypes.object]),
  handleCheckboxTradeTypesChange: PropTypes.func,
  showAlert: PropTypes.bool,
};

export default FilterTradeTypes;
