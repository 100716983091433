import React from 'react';
import PropTypes from 'prop-types';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function Item({ report, reportType }) {
  return (
    <div className={`factsheet-report col-lg-${reportType === 'financial_statements' ? '3' : '4'}`}>
      <div className="g-mb-30 g-pb-30 g-border-1 g-border-b-1 g-pr-15 g-pl-15">
        <div className="row gx-3 align-items-center">
          <div className="col-auto">
            <div className="icon-report g-border-radius-5 p-2 g-width-40 g-height-40">
              <i className="fa-thin fa-book-open-cover fa-lg align-middle" />
            </div>
          </div>
          <div className="col">
            <h5 className="g-mb-0 lh-sm">
              {report.type}&nbsp;{report.upload_year}
            </h5>
            <div className="text-muted">
              {reportType === 'financial_statements' ? 'Financial Period Ended ' : ''}
              {report.upload_date}
            </div>
          </div>
          <div
            className={
              reportType === 'financial_statements'
                ? 'g-mt-15 col-12 g-ml-55'
                : 'col-auto text-sm-end g-sm-mt-10'
            }>
            {report.files &&
              report.files.map((file) => {
                const size = file.size ? ` (${file.size} MB)` : '';
                return (
                  <OverlayTrigger
                    key={file.key}
                    placement="top"
                    overlay={<Tooltip id={file.key}>{`Part ${file.part}${size}`}</Tooltip>}>
                    <a
                      className="factsheet-report-link text-dark"
                      href={file.link}
                      target="_blank"
                      rel="noreferrer">
                      <i className="fa-thin fa-file-pdf fa-xl" />
                    </a>
                  </OverlayTrigger>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}

Item.propTypes = {
  report: PropTypes.object,
  reportType: PropTypes.string,
};

export default Item;
