import { createSlice } from '@reduxjs/toolkit';
import api from '../../utils/api';

const HOVER_DELAY = 400; // milliseconds
let hoverTimeout;
const stockInfoPopupSlice = createSlice({
  name: 'stockInfoPopup',
  initialState: {
    data: {},
    loading: true,
    counter: null,
    isShown: false,
    err: null,
  },
  reducers: {
    setCounter(state, action) {
      state.counter = action.payload;
    },
    setIsShown(state, action) {
      state.isShown = action.payload;
    },
    stockInfoPopupDataFetchStart(state, action) {
      state.data = null;
      state.err = null;
      state.loading = true;
    },
    stockInfoPopupDataFetchSuccess(state, action) {
      state.data = action.payload.data;
      state.loading = false;
    },
    stockInfoPopupDataFetchFail(state, action) {
      state.err = action.payload;
      state.loading = false;
    },
  },
});

export default stockInfoPopupSlice.reducer;

export const {
  setCounter,
  setIsShown,
  stockInfoPopupDataFetchStart,
  stockInfoPopupDataFetchSuccess,
  stockInfoPopupDataFetchFail,
} = stockInfoPopupSlice.actions;

export const fetchData =
  ({ counter, cancelToken }) =>
  (dispatch) => {
    dispatch(stockInfoPopupDataFetchStart());
    api
      .get(`quote/${counter}/stock_summary.json`, { cancelToken })
      .then(({ data }) => {
        dispatch(stockInfoPopupDataFetchSuccess({ data }));
      })
      .catch((error) => {
        dispatch(stockInfoPopupDataFetchFail(error));
      });
  };

export const showStockPopup =
  ({ counter, isShown }) =>
  (dispatch) => {
    if (counter) {
      dispatch(setCounter(counter));
    }
    // add delay to show/hide the popup (to be same behavioural as v1)
    clearTimeout(hoverTimeout);
    hoverTimeout = setTimeout(() => {
      dispatch(setIsShown(isShown));
    }, HOVER_DELAY);
  };
