import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Dropdown, Form } from 'react-bootstrap';

const optAll = 'all';
const TYPES = [
  { value: optAll, label: 'Select All' },
  { value: 'events_exdates', label: 'Ex-Dates' },
  { value: 'events_results', label: 'Results Release' },
  { value: 'events_placement', label: 'Share Placement' },
  { value: 'events_gm', label: 'AGM / EGM / SGM' },
];

export default function TypeFilter({ type, handleTypeChange }) {
  const selectedTypes =
    !type || type.split(',').includes(optAll)
      ? TYPES.map((t) => t.value)
      : TYPES.filter((t) => type.split(',').includes(t.value)).map((t) => t.value);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [dropdownShown, setDropdownShown] = useState(false);
  const [dropdownLabel, setDropdownLabel] = useState('');

  const handleOptionChange = (optValue, isChecked) => {
    let updatedSelectedOptions = [];
    if (isChecked) {
      updatedSelectedOptions = TYPES.filter((t) =>
        [...selectedOptions, optValue].includes(t.value),
      ).map((t) => t.value);
      if (optValue === optAll || TYPES.length - updatedSelectedOptions.length === 1) {
        updatedSelectedOptions = TYPES.map((t) => t.value);
      }
    } else {
      updatedSelectedOptions = selectedOptions.filter((x) => ![optAll, optValue].includes(x));
      if (optValue === optAll) {
        updatedSelectedOptions = [];
      }
    }

    if (updatedSelectedOptions.length === 0) {
      setDropdownLabel('No option selected');
    } else if (updatedSelectedOptions.includes(optAll)) {
      setDropdownLabel('All Event Types');
    } else if (updatedSelectedOptions.length < 3) {
      setDropdownLabel(
        TYPES.filter((t) => updatedSelectedOptions.includes(t.value))
          .map((t) => t.label)
          .join(', '),
      );
    } else {
      setDropdownLabel(`${updatedSelectedOptions.length} options selected`);
    }

    setSelectedOptions(updatedSelectedOptions);
  };

  const onDropdownItemSelect = (e) => {
    e.stopPropagation();
    const optValue = e.target.id.substring('dditem_'.length);
    const optCheckbox = document.getElementById(`event_type_checkbox_${optValue}`);
    if (!optCheckbox) {
      return;
    }
    // toggle previous checked state for new state
    handleOptionChange(optValue, !optCheckbox.checked);
  };

  const onCheckboxClick = (e) => {
    e.stopPropagation();
    const optValue = e.target.value;
    handleOptionChange(optValue, e.target.checked);
  };

  const onClearSelection = (e) => {
    e.stopPropagation();
    // clear selectedOptions
    handleOptionChange(optAll, false);
  };

  const onViewSelection = (e) => {
    e.stopPropagation();
    // apply selectedOptions to filter Events Calendar
    const strSelectedOptions = selectedOptions.sort().join(',');
    if (selectedOptions.length > 0 && strSelectedOptions !== selectedTypes.sort().join(',')) {
      handleTypeChange(selectedOptions.includes(optAll) ? optAll : strSelectedOptions);
    }
    setDropdownShown(false);
  };

  const onDropdownToggle = (nextShow, meta) => {
    setDropdownShown(nextShow);
  };

  useEffect(() => {
    if (selectedTypes === selectedOptions) {
      return;
    }

    setSelectedOptions(selectedTypes);
    setDropdownLabel(() => {
      if (selectedTypes.length < 1 || selectedTypes.includes(optAll)) {
        return 'All Event Types';
      }
      if (selectedTypes.length < 3) {
        return TYPES.filter((t) => selectedTypes.includes(t.value))
          .map((t) => t.label)
          .join(', ');
      }
      return `${selectedTypes.length} options selected`;
    });
  }, [type]);

  return (
    <Dropdown
      drop="down"
      autoClose="outside"
      show={dropdownShown}
      onToggle={onDropdownToggle}
      className="d-inline-block">
      <Dropdown.Toggle variant="light">
        <span className="g-mr-10">{dropdownLabel}</span>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {TYPES.map((t) => (
          <Dropdown.Item
            key={t.value}
            eventKey={t.value}
            className="d-inline-block"
            id={`dditem_${t.value}`}
            onClick={onDropdownItemSelect}>
            <Form.Check
              className="d-inline-block g-ml-10 g-mr-10"
              type="checkbox"
              id={`event_type_checkbox_${t.value}`}
              value={t.value}
              checked={selectedOptions.includes(t.value)}
              onClick={onCheckboxClick}
            />
            {t.label}
          </Dropdown.Item>
        ))}
        <Dropdown.Divider />
        <span className="d-flex flex-row float-end g-mt-20 g-mb-20">
          <Button
            className="btn btn-light btn-sm text-dark g-min-width-0 g-width-50 g-ml-5 g-mr-5 d-inline-block"
            onClick={onClearSelection}>
            Clear
          </Button>
          <Button
            className="btn btn-success btn-sm g-min-width-0 g-width-50 g-ml-5 g-mr-5 d-inline-block"
            disabled={selectedOptions.length < 1}
            onClick={onViewSelection}>
            View
          </Button>
        </span>
      </Dropdown.Menu>
    </Dropdown>
  );
}

TypeFilter.propTypes = {
  type: PropTypes.string,
  handleTypeChange: PropTypes.func,
};
