import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { getParamCounterId } from '../../../../utils/utils';
import Pagination from '../../../../components/Pagination';
import TableColumnSearchFilter from '../../../../components/TableColumnSearchFilter';
import ModalData from './ModalData';

const RECORDS_PER_PAGE = 6;

export default function Table({ data }) {
  const [tableData, setTableData] = useState([]);
  const [pagination, setPagination] = useState({});
  const [modalData, setModalData] = useState({ show: false, data: null });

  const handleShowModal = (taResults, stockInfo, counter, taResultsDate) => {
    setModalData({ show: true, data: { taResults, stockInfo, counter, taResultsDate } });
  };

  const handleCloseModal = () => {
    setModalData({ show: false, data: null });
  };

  useEffect(() => {
    if (data?.length > 0) {
      const newPagination = {
        currentPage: 1,
        rowOffset: 0,
        currentPageRows: data.length > RECORDS_PER_PAGE ? RECORDS_PER_PAGE : data.length,
        totalRows: data.length,
        totalPages: Math.ceil(data.length / RECORDS_PER_PAGE),
      };
      setTableData(data.slice(0, newPagination.currentPageRows));
      setPagination(newPagination);
    }
  }, [data]);

  const handlePageChange = (newPage) => {
    if (!newPage || pagination.currentPage === newPage) {
      return;
    }

    if (data?.length > 0) {
      const newPagination = { ...pagination };
      newPagination.currentPage = newPage;
      newPagination.rowOffset = (newPage - 1) * RECORDS_PER_PAGE;
      const newOffsetTableData = data.slice(newPagination.rowOffset);
      newPagination.currentPageRows =
        newOffsetTableData.length > RECORDS_PER_PAGE ? RECORDS_PER_PAGE : newOffsetTableData.length;
      setTableData(newOffsetTableData.slice(0, newPagination.currentPageRows));
      setPagination(newPagination);
    }
  };

  const handleStockNameFilterChange = (filterValue) => {
    if (data?.length < 1) {
      return;
    }

    if (!filterValue) {
      setTableData(
        data.slice(
          pagination.rowOffset, // start
          pagination.rowOffset + pagination.currentPageRows, // end
        ),
      );
    } else {
      setTableData(
        data.filter(
          (s) =>
            s.stockInfo?.stockName?.toLowerCase().includes(filterValue.toLowerCase()) ||
            s.counter?.toLowerCase().includes(filterValue.toLowerCase()),
        ),
      );
    }
  };

  return (
    <>
      <div className="table-responsive g-min-height-444">
        <table className="table table-hover mb-0">
          <thead className="table-light">
            <tr>
              <th className="align-middle g-per-width-70">
                <TableColumnSearchFilter
                  headerLabel="Name"
                  handleFilterChange={handleStockNameFilterChange}
                />
              </th>
              <th className="align-middle text-center">Results</th>
              <th className="align-middle text-center">View Results</th>
            </tr>
          </thead>
          <tbody>
            {tableData.length < 1 ? (
              <tr>
                <td colSpan="3" className="text-center">
                  No data found.
                </td>
              </tr>
            ) : (
              tableData.map((stockData) => {
                return (
                  <tr key={`${stockData.counter}`}>
                    <td>
                      <a href={`/quote/${getParamCounterId(stockData.counter)}`}>
                        {stockData.stockInfo?.stockName || stockData.counter}
                      </a>
                    </td>
                    <td className="text-center">{stockData.taResultsCount}</td>
                    <td className="text-center">
                      {stockData.taResultsCount > 0 && (
                        <button
                          type="button"
                          className="btn g-min-width-auto border-0 p-0"
                          onClick={() => {
                            handleShowModal(
                              stockData.taResults,
                              stockData.stockInfo,
                              stockData.counter,
                              stockData.taResultsDate,
                            );
                          }}>
                          <i className="fa-thin fa-square-poll-horizontal fa-xl" />
                        </button>
                      )}
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
      <Pagination
        rowOffset={pagination.rowOffset}
        currentPageRows={pagination.currentPageRows}
        totalRows={pagination.totalRows}
        currentPage={pagination.currentPage}
        totalPages={pagination.totalPages}
        handlePageChange={handlePageChange}
      />
      <ModalData
        show={modalData.show}
        taResults={modalData.data?.taResults}
        stockInfo={modalData.data?.stockInfo}
        counter={modalData.data?.counter}
        taResultsDate={modalData.data?.taResultsDate}
        handleClose={handleCloseModal}
      />
    </>
  );
}

Table.propTypes = {
  data: PropTypes.array,
};
