import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import api from '../../../utils/api';

const newsInfiniteScrollSlice = createSlice({
  name: 'newsInfiniteScroll',
  initialState: {
    data: [],
    loading: true,
    err: null,
    isLast: false,
    isNextPage: false,
    nextPageLoading: false,
    sgxnetCategories: null,
    scrollState: null,
    selectedNews: null,
    page: 1,
  },
  reducers: {
    fetchStart(state, action) {
      state.err = null;
      state.isLast = false;
      if (action.payload.isNextPage) {
        state.nextPageLoading = true;
      } else {
        state.loading = true;
      }
    },
    fetchSuccess(state, action) {
      state.isLast = action.payload.data.length < action.payload.newsPerPage;
      state.sgxnetCategories = action.payload.sgxnetCategories;
      state.page = action.payload.page;
      if (action.payload.isNextPage) {
        state.nextPageLoading = false;
        state.data = [...state.data, ...action.payload.data];
      } else {
        state.data = action.payload.data;
        state.loading = false;
      }
    },
    fetchFail(state, action) {
      state.err = action.payload;
      if (action.payload.isNextPage) {
        state.nextPageLoading = false;
      } else {
        state.loading = false;
      }
    },
    handleSetPage(state, action) {
      state.page = action.payload.page;
    },
    handleScrollStateAndSelectedNews(state, action) {
      state.scrollState = action.payload.scrollState;
      state.selectedNews = action.payload.newsID;
    },
  },
});

export default newsInfiniteScrollSlice.reducer;

const { fetchStart, fetchSuccess, fetchFail, handleSetPage, handleScrollStateAndSelectedNews } =
  newsInfiniteScrollSlice.actions;
export const fetchData =
  ({
    market,
    counter,
    cancelToken,
    newsType,
    page,
    newsPerPage,
    isNextPage,
    fromDate,
    toDate,
    category,
    origin,
    folderId,
  }) =>
  async (dispatch) => {
    dispatch(fetchStart({ isNextPage }));
    try {
      let url = `news/retrieve_news_list.json?origin=${origin}&type=${newsType}&news_per_page=${newsPerPage}&page=${page}`;
      if (market) {
        url += `&market=${market}`;
      }
      if (counter) {
        url += `&counter=${counter}`;
      }
      if (fromDate) {
        url += `&fromDate=${fromDate}`;
      }
      if (toDate) {
        url += `&toDate=${toDate}`;
      }
      if (category) {
        url += `&category=${category}`;
      }
      if (folderId) {
        url += `&folderId=${folderId}`;
      }
      const response = await api.get(url, {
        cancelToken,
      });
      dispatch(
        fetchSuccess({
          data: response.data.news,
          page: response.data.pagination.page,
          newsPerPage: response.data.pagination.news_per_page,
          isNextPage,
          sgxnetCategories: response.data.sgxnet_categories,
        }),
      ); // Update to response.data
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled:', error.message);
      } else {
        dispatch(fetchFail({ error, isNextPage }));
      }
    }
  };

export const saveCurrentPage =
  ({ page }) =>
  (dispatch) => {
    dispatch(handleSetPage({ page }));
  };

export const saveScrollStateAndSelectedNews =
  ({ scrollState, newsID }) =>
  (dispatch) => {
    dispatch(handleScrollStateAndSelectedNews({ scrollState, newsID }));
  };
