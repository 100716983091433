import axios from 'axios';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useSearchParams, useLocation } from 'react-router-dom';

import api from '../../../utils/api';
import EventsTable from './EventsTable';
import TypeFilter from './TypeFilter';
import DateFilter from '../../../components/DateFilter';
import Pagination from '../../../components/Pagination';
import Loader from '../../../components/Loader';
import APIErrorHandler from '../../../components/APIErrorHandler';

const { CancelToken } = axios;
const TYPE = 'all';

function EventsCalendar({ counter, toggleFactsheetNav }) {
  const source = CancelToken.source();
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState({});
  const [pagination, setPagination] = useState({});
  const [page, setPage] = useState(searchParams.get('page') || '1');
  const [type, setType] = useState(searchParams.get('type') || TYPE);
  const [periodType, setPeriodType] = useState(searchParams.get('period_type') || '');
  const [fromDate, setFromDate] = useState(searchParams.get('from_date'));
  const [toDate, setToDate] = useState(searchParams.get('to_date'));
  const [attachmentCount, setAttachmentCount] = useState();
  const [currency, setCurrency] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isSSR, setIsSSR] = useState(true);
  const [error, setError] = useState();
  const location = useLocation();

  const getCurrentParams = () => {
    const params = {};

    if (page) {
      params.page = page;
    }

    if (type) {
      params.type = type;
    }

    if (fromDate) {
      params.from_date = fromDate;
    }

    if (toDate) {
      params.to_date = toDate;
    }

    if (periodType) {
      params.period_type = periodType;
    }

    return params;
  };

  const handlePageChange = (newPage) => {
    if (!newPage || page === newPage) {
      return;
    }

    setSearchParams({ ...getCurrentParams(), page: newPage });
    setPage(newPage);
  };

  const handleTypeChange = (newType) => {
    if (!newType || type.split(',').sort().join(',') === newType.split(',').sort().join(',')) {
      return;
    }

    setSearchParams({ ...getCurrentParams(), type: newType });
    setType(newType);
    handlePageChange('1');
  };

  const handleDateReset = () => {
    if (searchParams.has('from_date')) {
      searchParams.delete('from_date');
    }
    if (searchParams.has('to_date')) {
      searchParams.delete('to_date');
    }
    setSearchParams(searchParams);
    setToDate('');
    setFromDate('');
  };

  const handlePeriodTypeChange = (newPeriodType) => {
    if (periodType === newPeriodType) {
      return;
    }

    setSearchParams({
      ...getCurrentParams(),
      period_type: newPeriodType,
      from_date: '',
      to_date: '',
    });
    setPeriodType(newPeriodType);
    setToDate('');
    setFromDate('');
    handlePageChange('1');
  };

  const changePeriodType = (event) => {
    const newPeriodType = event.currentTarget.value;
    event.currentTarget.parentElement.childNodes[0].classList.remove('active');
    event.currentTarget.parentElement.childNodes[1].classList.remove('active');
    event.currentTarget.classList.add('active');
    handlePeriodTypeChange(newPeriodType);
  };

  const handleFromDateChange = (_newDate) => {
    if (!_newDate) {
      return;
    }

    let newDate = '';
    if (_newDate.length > 0) {
      newDate = dayjs(new Date(_newDate)).format('YYYY-MM-DD');
    }

    if (fromDate === newDate) {
      return;
    }

    setSearchParams({ ...getCurrentParams(), from_date: newDate, period_type: '' });
    setFromDate(newDate);
    setPeriodType('');
    handlePageChange('1');
  };

  const handleToDateChange = (_newDate) => {
    if (!_newDate) {
      return;
    }

    let newDate = '';
    if (_newDate.length > 0) {
      newDate = dayjs(new Date(_newDate)).format('YYYY-MM-DD');
    }

    if (toDate === newDate) {
      return;
    }

    setSearchParams({ ...getCurrentParams(), to_date: newDate, period_type: '' });
    setToDate(newDate);
    setPeriodType('');
    handlePageChange('1');
  };

  const fetchData = () => {
    const cancelToken = source.token;
    let url = `quote/${counter}/events_calendar.json?type=${type}`;

    if (fromDate) {
      url = `${url}&from_date=${fromDate}`;
    }

    if (toDate) {
      url = `${url}&to_date=${toDate}`;
    }

    if (page) {
      url = `${url}&page=${page}`;
    }

    if (periodType) {
      url = `${url}&period_type=${periodType}`;
    }

    api
      .get(url, { cancelToken })
      .then(({ data }) => {
        setData(data.data);
        setPagination(data.pagination);
        setAttachmentCount(data.attachment_count);
        setCurrency(data.currency);
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    const delayDebounceFn = setTimeout(() => {
      fetchData();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [type, fromDate, toDate, page, periodType]);

  useEffect(() => {
    if (type !== searchParams.get('type')) {
      setType(searchParams.get('type') || TYPE);
    }
    if (fromDate !== searchParams.get('from_date')) {
      setFromDate(searchParams.get('from_date'));
    }
    if (toDate !== searchParams.get('to_date')) {
      setToDate(searchParams.get('to_date'));
    }
    if (page !== searchParams.get('page')) {
      setPage(searchParams.get('page') || '1');
    }
    if (periodType !== searchParams.get('period_type')) {
      setPeriodType(searchParams.get('period_type') || '');
    }
  }, [location]);

  useEffect(() => {
    setIsSSR(typeof document === 'undefined');

    return () => {};
  }, []);

  if (isSSR) {
    return <Loader visible={isSSR} classes="g-height-800" />;
  }

  if (error) {
    return <APIErrorHandler error={error} />;
  }

  return (
    <div className="g-md-mt-60" id="eventsCalendarComponent">
      <div className="row gx-2 gy-2 g-mb-20">
        <div className="col-sm-auto">
          <TypeFilter type={type} handleTypeChange={handleTypeChange} />
        </div>
        <div className="col-sm-auto">
          <div className="btn-group flex-wrap" role="group" aria-label="Period selection">
            <button
              onClick={(e) => changePeriodType(e)}
              type="button"
              className={`btn btn-outline-light g-border-radius-7 ${
                periodType === 'events_upcoming' ? 'active' : ''
              }`}
              value="events_upcoming">
              Upcoming Events
            </button>
            <button
              onClick={(e) => changePeriodType(e)}
              type="button"
              className={`btn btn-outline-light ${
                periodType === 'events_historical' ? 'active' : ''
              }`}
              value="events_historical">
              Historical Events
            </button>
            <DateFilter
              isInterval
              fromDate={fromDate}
              handleFromDateChange={handleFromDateChange}
              toDate={toDate}
              handleToDateChange={handleToDateChange}
              handleReset={handleDateReset}
            />
          </div>
        </div>
      </div>
      <Loader visible={isLoading} classes="g-height-800" />
      {!isLoading && data && data.length > 0 && (
        <>
          <EventsTable
            data={data}
            attachmentCount={attachmentCount}
            currency={currency}
            toggleFactsheetNav={toggleFactsheetNav}
          />
          <Pagination
            rowOffset={pagination.page_row_offset}
            currentPageRows={data.length}
            totalRows={pagination.total_rows}
            currentPage={Number(page)}
            totalPages={pagination.total_pages}
            handlePageChange={handlePageChange}
          />

          <div className="notes text-muted small g-md-mt-50 g-md-mb-80">
            <div className="fw-bold">Notes:</div>
            <div>
              For errors or omissions, please send an email to{' '}
              <a href="mailto:admin@shareinvestor.com">admin@shareinvestor.com</a>
            </div>
          </div>
        </>
      )}
      {(!data || data.length === 0) && (
        <div className="text-center">
          <i
            className="fa-duotone fa-xl fa-calendar-circle-exclamation g-text-size-50"
            style={{
              '--fa-primary-color': '#9f9f9f',
              '--fa-secondary-color': '#9f9f9f',
              '--fa-secondary-opacity': '0.5',
            }}
          />
          <div className="g-text-size-24 fw-bold">No events found.</div>
        </div>
      )}
    </div>
  );
}

EventsCalendar.propTypes = {
  counter: PropTypes.string,
  toggleFactsheetNav: PropTypes.func,
};

export default EventsCalendar;
