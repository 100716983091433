import { createSlice } from '@reduxjs/toolkit';
import api from '../../../../utils/api';

const portfolioBrokerSlice = createSlice({
  name: 'portfolioBroker',
  initialState: {
    loading: true,
    completed: false,
    brokers: [],
    result: null,
    err: null,
  },
  reducers: {
    brokerFetchStart(state, action) {
      state.brokers = [];
      state.err = null;
      state.completed = false;
      state.loading = true;
      state.result = null;
    },
    brokerFetchSuccess(state, action) {
      if (action.payload.data.brokers) {
        state.brokers = action.payload.data.brokers;
      }
      state.completed = true;
      state.loading = false;
    },
    brokerFetchFail(state, action) {
      state.err = action.payload;
      state.completed = false;
      state.loading = false;
    },
    brokerCreateUpdateFetchStart(state, action) {
      state.err = null;
      state.completed = false;
      state.loading = true;
      state.result = null;
    },
    brokerCreateUpdateFetchSuccess(state, action) {
      if (action.payload.data.error) {
        state.err = { result: action.payload.data.error };
      }
      if (action.payload.data.brokers) {
        state.brokers = action.payload.data.brokers;
      }
      if (action.payload.data.result) {
        state.result = action.payload.data.result;
      }
      state.completed = true;
      state.loading = false;
    },
    brokerCreateUpdateFetchFail(state, action) {
      state.err = action.payload;
      state.completed = false;
      state.loading = false;
    },
  },
});

export default portfolioBrokerSlice.reducer;

const {
  brokerFetchStart,
  brokerFetchSuccess,
  brokerFetchFail,
  brokerCreateUpdateFetchStart,
  brokerCreateUpdateFetchSuccess,
  brokerCreateUpdateFetchFail,
} = portfolioBrokerSlice.actions;

export const fetchData =
  ({ folder, cancelToken }) =>
  (dispatch) => {
    dispatch(brokerFetchStart());

    let url = 'portfolio';
    url += `/${folder}/brokers_list.json`;

    api
      .get(url, { cancelToken })
      .then(({ data }) => {
        dispatch(brokerFetchSuccess({ data }));
      })
      .catch((error) => {
        dispatch(brokerFetchFail(error));
      });
  };

export const createFetchData =
  ({
    folder,
    brokerName,
    commissionRate,
    clearingRate,
    sgxAccessFee,
    stampDuty,
    gst,
    minCommission,
    maxClearingCharge,
    maxStampDuty,
    country,
    cancelToken,
  }) =>
  (dispatch) => {
    dispatch(brokerCreateUpdateFetchStart());
    const params = {
      brokerName,
      commissionRate,
      clearingRate,
      sgxAccessFee,
      stampDuty,
      gst,
      minCommission,
      maxClearingCharge,
      maxStampDuty,
      country,
      cancelToken,
    };

    api
      .post(`portfolio/${folder}/add_broker.json`, params, { cancelToken })
      .then(({ data }) => {
        dispatch(brokerCreateUpdateFetchSuccess({ data }));
      })
      .catch((error) => {
        dispatch(brokerCreateUpdateFetchFail(error));
      });
  };

export const updateFetchData =
  ({
    folder,
    brokerName,
    commissionRate,
    clearingRate,
    sgxAccessFee,
    stampDuty,
    gst,
    minCommission,
    maxClearingCharge,
    maxStampDuty,
    brokerId,
    cancelToken,
  }) =>
  (dispatch) => {
    dispatch(brokerCreateUpdateFetchStart());
    const params = {
      brokerName,
      commissionRate,
      clearingRate,
      sgxAccessFee,
      stampDuty,
      gst,
      minCommission,
      maxClearingCharge,
      maxStampDuty,
      brokerId,
    };

    api
      .post(`portfolio/${folder}/update_broker.json`, params, { cancelToken })
      .then(({ data }) => {
        dispatch(brokerCreateUpdateFetchSuccess({ data }));
      })
      .catch((error) => {
        dispatch(brokerCreateUpdateFetchFail(error));
      });
  };

export const deleteFetchData =
  ({ brokerId, folder, cancelToken }) =>
  (dispatch) => {
    dispatch(brokerCreateUpdateFetchStart());
    const params = {
      brokerId,
    };

    api
      .post(`portfolio/${folder}/delete_broker.json`, params, { cancelToken })
      .then(({ data }) => {
        dispatch(brokerCreateUpdateFetchSuccess({ data }));
      })
      .catch((error) => {
        dispatch(brokerCreateUpdateFetchFail(error));
      });
  };

export const changeDefaultBroker =
  ({ folder, new_default_broker, country, cancelToken }) =>
  (dispatch) => {
    dispatch(brokerCreateUpdateFetchStart());
    const params = {
      new_default_broker,
      country,
    };

    api
      .post(`portfolio/${folder}/change_default_broker.json`, params, { cancelToken })
      .then(({ data }) => {
        dispatch(brokerCreateUpdateFetchSuccess({ data }));
      })
      .catch((error) => {
        dispatch(brokerCreateUpdateFetchFail(error));
      });
  };
