import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';

import CompanyNameLink from '../StockInfoPopup/CompanyNameLink';
import { createLoadableComponent } from '../../utils/Loadable';
import PopUpNote from '../PopUpNote';
import FixedHeaderTable from '../FixedHeaderTable';

const StockInfoPopup = createLoadableComponent(() => import('../StockInfoPopup'));
const SECURITIES_TYPES = `
    <div class='row mb-2 g-text-size-12 g-lg-text-size-14 fw-bold'>
      <div class='col-3'>S/U</div>
      <div class='col-9'>Ordinary Voting Shares/Units/Stapled Securities</div>
    </div>
    <div class='row mb-2'>
      <div class='col-3'>OS/OU</div>
      <div class='col-9'>Other Types of Shares/Units/Stapled Securities</div>
    </div>
    <div class='row mb-2'>
      <div class='col-3'>R/O/W</div>
      <div class='col-9'>
        Rights/Options/Warrants over Voting Shares/Units/Stapled Securities
      </div>
    </div>
    <div class='row mb-2'>
      <div class='col-3'>D</div>
      <div class='col-9'>
        Convertible Debentures over Voting Shares/Units/Stapled Securities
      </div>
    </div>
    <div class='row mb-2'>
      <div class='col-3'>R/O (D)</div>
      <div class='col-9'>Rights/Options over Debentures</div>
    </div>
    <div class='row mb-2'>
      <div class='col-3'>C</div>
      <div class='col-9'>
        Contracts under which any person has a right to call for or to make delivery of
        shares/units/stapled securities in the Listed Issuer
      </div>
    </div>
    <div class='row mb-2'>
      <div class='col-3'>PI</div>
      <div class='col-9'>Participatory Interests</div>
    </div>
    <div class='row mb-2'>
      <div class='col-3'>PS</div>
      <div class='col-9'>Perpetual Securities</div>
    </div>
    <div class='row mb-2'>
      <div class='col-3'>OTH</div>
      <div class='col-9'>Others</div>
    </div>
  `;

function InsiderTradesTable({ data, classificationTypes, currency, toggleFactsheetNav }) {
  const tableContainerID = 'insiderTradesTable';

  useEffect(() => {
    const table = document.querySelector('#insiderTradesTable');
    if (toggleFactsheetNav) {
      toggleFactsheetNav(null, table);
    }
    return () => {
      if (toggleFactsheetNav) {
        toggleFactsheetNav(null, table);
      }
    };
  }, []);

  return (
    <>
      <FixedHeaderTable
        header={<TableHeader currency={currency} />}
        body={<TableBody data={data} classificationTypes={classificationTypes} />}
        handleScrollY={toggleFactsheetNav}
        tableContainerID={tableContainerID}
      />
      <StockInfoPopup />
    </>
  );
}

InsiderTradesTable.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  classificationTypes: PropTypes.oneOfType([PropTypes.array]),
  currency: PropTypes.string,
  toggleFactsheetNav: PropTypes.func,
};

export default InsiderTradesTable;

function TableHeader({ currency }) {
  return (
    <thead className="table-light align-middle">
      <tr>
        <th rowSpan={2} className="text-start relationship">
          Buyer / Seller Name [Classification<sup>a</sup>]
        </th>
        <th rowSpan={2}>Stock Name</th>
        <th rowSpan={2} className="securityTypes">
          Security Types<sup>b</sup>{' '}
          <div className="g-min-width-20 d-inline-block">
            <PopUpNote
              content={SECURITIES_TYPES}
              classes="g-max-width-300"
              icon="fa-solid fa-circle-exclamation fa-sm"
            />
          </div>
        </th>
        <th className="text-center effectiveDate" rowSpan={2}>
          <div className="d-block d-lg-none">Date: Effective (Notice)</div>
          <div className="d-none d-lg-block">Effective Change Date</div>
          <div className="d-none d-lg-block">(Notice Date)</div>
        </th>
        <th colSpan={3} className="border-bottom-0">
          Acquired / (Disposed)['000]
        </th>
        <th rowSpan={2}>Price</th>
        <th rowSpan={2} className="closingPrice">
          Closing Price
          <span
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(`(${currency})`),
            }}
          />
          <sup>d</sup>
        </th>
        <th colSpan={4} className="border-bottom-0">
          No. of Shares After Trade ['000]
        </th>
        <th rowSpan={2}>Note</th>
      </tr>
      <tr>
        <th className="acquired">Direct</th>
        <th className="acquired">Deemed</th>
        <th className="acquired">Total</th>
        <th>Direct</th>
        <th>Deemed</th>
        <th>Total</th>
        <th className="text-nowrap">
          % Held <sup>c</sup>
        </th>
      </tr>
    </thead>
  );
}

TableHeader.propTypes = {
  currency: PropTypes.string,
};

function TableBody({ data, classificationTypes }) {
  return (
    <tbody>
      {data.map((insider) => {
        const classification = classificationTypes.find(
          (type) => type.value === insider.transaction_type,
        )
          ? classificationTypes.find((type) => type.value === insider.transaction_type).name
          : '';
        return (
          <tr key={insider.id}>
            <td className="text-start">
              <div>
                {insider.buyers_sellers.map((bs) => {
                  return (
                    <div key={bs.name}>
                      {bs.factset_owner_id ? (
                        <a
                          href={`/significant_shareholders/ownership_by_shareholder?shareholder_id=${bs.factset_owner_id}`}>
                          {bs.name}
                        </a>
                      ) : (
                        bs.name
                      )}
                    </div>
                  );
                })}
              </div>
              <div>[{classification}]</div>
            </td>
            <td>
              <CompanyNameLink
                counter={insider.counter}
                showStockInfoPopUp
                name={insider.stock_name}
              />
            </td>
            <td>{insider.share_warrant}</td>
            <td className="text-center">
              <div>{insider.trans_date}</div>
              <i>({insider.announcement_date})</i>
            </td>
            <td className={insider.volume_transacted_direct === '' ? 'td-blank' : ''}>
              {insider.volume_transacted_direct}
            </td>
            <td className={insider.volume_transacted_deemed === '' ? 'td-blank' : ''}>
              {insider.volume_transacted_deemed}
            </td>
            <td>{insider.volume_transacted_total}</td>
            <td className={insider.price === '' ? 'td-blank' : ''}>
              {insider.price !== '' && `${insider.currency}${insider.price}`}
            </td>
            <td>{insider.closing_price}</td>
            <td className={insider.num_shares_direct === '' ? 'td-blank' : ''}>
              {insider.num_shares_direct}
            </td>
            <td className={insider.num_shares_deemed === '' ? 'td-blank' : ''}>
              {insider.num_shares_deemed}
            </td>
            <td>{insider.num_shares}</td>
            <td>{insider.percent_held}</td>
            <td>
              {insider.remarks !== '' && (
                <div className="g-min-width-20">
                  <PopUpNote
                    content={insider.remarks}
                    icon="fa-thin fa-note fa-xl"
                    classes="text-start text-muted g-width-300 g-sm-width-500 g-md-width-700"
                  />
                </div>
              )}
            </td>
          </tr>
        );
      })}
    </tbody>
  );
}

TableBody.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  classificationTypes: PropTypes.oneOfType([PropTypes.array]),
};
