import React from 'react';
import PropTypes from 'prop-types';

import QuotePrice from './QuotePrice';
import QuoteLinks from './QuoteLinks';
import QuoteNews from './QuoteNews';
import styles from './searchQuote.module.css';

export default function QuoteInfo({
  activeCounter,
  activeCounterPrice,
  activeCounterNews,
  eodMarkets,
}) {
  if (!activeCounter.stock_id) {
    return (
      <div className="border-top">
        <div className={`${styles.quoteInfo} text-center`}>No results</div>
      </div>
    );
  }

  return (
    <div className={styles.quoteInfo}>
      <a
        className="g-text-size-20 text-dark fw-bold"
        href={`/home/redirect?type=factsheet&counter=${activeCounter.marketAndCode}`}>
        {activeCounter.full_name}
      </a>
      <hr className="my-1" />
      <QuotePrice activeCounterPrice={activeCounterPrice} />
      <QuoteLinks activeCounter={activeCounter} eodMarkets={eodMarkets} view="search" />
      <QuoteNews
        activeCounter={activeCounter}
        activeCounterNews={activeCounterNews}
        eodMarkets={eodMarkets}
      />
    </div>
  );
}

QuoteInfo.propTypes = {
  activeCounter: PropTypes.object,
  activeCounterPrice: PropTypes.object,
  activeCounterNews: PropTypes.object,
  eodMarkets: PropTypes.array,
};
