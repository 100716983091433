import React from 'react';
import PropTypes from 'prop-types';

function NumberOfRatings({ minRatingByMarket, selectedMinRatings, handleOnClick }) {
  if (!minRatingByMarket || minRatingByMarket.length === 0) {
    return null;
  }

  return (
    <div className="row align-items-center">
      <div className="col-auto text-muted">Minimum Number of Ratings:</div>
      <div className="col-auto">
        <div className="d-flex flex-wrap flex-row gap-2">
          {minRatingByMarket.map((number) => {
            return (
              <button
                onClick={handleOnClick}
                type="button"
                key={number}
                className={`${
                  selectedMinRatings === number ? 'active' : ''
                } btn btn-sm btn-gray-100 g-min-width-0 g-width-50 border-0`}>
                {number}
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
}

NumberOfRatings.propTypes = {
  minRatingByMarket: PropTypes.oneOfType([PropTypes.array]),
  selectedMinRatings: PropTypes.number,
  handleOnClick: PropTypes.func,
};

export default NumberOfRatings;
