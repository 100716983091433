import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';

import { NavComponent } from '../../../../components/PageLocalNav';
import AssetClass from './AssetClass';
import Capital from './Capital';
import Market from './Market';
import Sector from './Sector';

const tabSector = 'sector';
const TABS = [
  { key: tabSector, label: 'Sector' },
  { key: 'capital', label: 'Capital' },
  { key: 'asset_class', label: 'Asset Class' },
  { key: 'market', label: 'Market' },
];

export default function Allocation() {
  const [currentTabKey, setCurrentTabKey] = useState(tabSector);

  const onTabSelectCallback = (event, selectedTabKey) => {
    event.preventDefault();
    if (
      !(
        selectedTabKey &&
        TABS.map((t) => t.key).includes(selectedTabKey) &&
        selectedTabKey !== currentTabKey
      )
    ) {
      return;
    }

    setCurrentTabKey(selectedTabKey);
  };

  const renderComponent = (tabKey) => {
    switch (tabKey) {
      case tabSector:
        return <Sector />;
      case 'capital':
        return <Capital />;
      case 'asset_class':
        return <AssetClass />;
      case 'market':
        return <Market />;
      default:
        null;
    }
  };

  return (
    <Card className="position-static  h-100">
      <Card.Body className="g-min-height-555">
        <Card.Title>Allocation</Card.Title>
        <NavComponent
          navLinks={TABS}
          tabClasses="g-mb-15"
          activeNav={currentTabKey}
          content={renderComponent(currentTabKey)}
          handleNavClick={onTabSelectCallback}
        />
      </Card.Body>
    </Card>
  );
}
