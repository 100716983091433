import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import APIErrorHandler from '../../../components/APIErrorHandler';
import { createLoadableComponent } from '../../../utils/Loadable';
import Loader from '../../../components/Loader';

const PriceDownload = createLoadableComponent(() => import('./PriceDownload'));

function PriceDownloadTab({ counter, priceDownloadConfig }) {
  const [isSSR, setIsSSR] = useState(true);
  useEffect(() => {
    setIsSSR(typeof document === 'undefined');

    return () => {};
  }, []);

  if (isSSR) {
    return <Loader visible={isSSR} classes="g-height-800" />;
  }

  return (
    <>
      <h4 className="g-md-mb-30">Price Download</h4>
      <div className="row justify-content-lg-center g-3">
        <div className="col-lg-4 col-xl-2 offset-lg-0 col-sm-6 offset-sm-3 col-8 offset-2">
          <PriceDownload counter={counter} title="Historical Price" type="historical" />
        </div>
        {priceDownloadConfig.hasIntradayData && (
          <div className="col-lg-4 col-xl-2 offset-lg-0 col-sm-6 offset-sm-3 col-8 offset-2">
            <PriceDownload counter={counter} title="Intraday Price" type="intraday" />
          </div>
        )}
        {priceDownloadConfig.isSgMyMarket && (
          <>
            <div className="col-lg-4 col-xl-2 offset-lg-0 col-sm-6 offset-sm-3 col-8 offset-2">
              <PriceDownload
                counter={counter}
                title="Time &amp; Sales"
                type="timesales"
                dayRange={priceDownloadConfig.recentPriceDays}
              />
            </div>
            <div className="col-lg-4 col-xl-2 offset-lg-0 col-sm-6 offset-sm-3 col-8 offset-2">
              <PriceDownload
                counter={counter}
                title="Trade Summary Matrix (TSM)"
                type="tsm"
                dayRange={priceDownloadConfig.recentPriceDays}
              />
            </div>
            <div className="col-lg-4 col-xl-2 offset-lg-0 col-sm-6 offset-sm-3 col-8 offset-2">
              <PriceDownload
                counter={counter}
                title="Quote Movements"
                type="quotemovements"
                dayRange={priceDownloadConfig.recentPriceDays}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}

PriceDownloadTab.propTypes = {
  counter: PropTypes.string,
  priceDownloadConfig: PropTypes.shape({
    recentPriceDays: PropTypes.oneOfType([PropTypes.array]),
    hasIntradayData: PropTypes.bool,
    isSgMyMarket: PropTypes.bool,
  }),
};

export default PriceDownloadTab;
