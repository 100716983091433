import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FacebookShareButton, FacebookIcon, TelegramShareButton, TelegramIcon } from 'react-share';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function ShareButtons({ url, title }) {
  const [tooltipText, setTooltipText] = useState('Copy url to clipboard');

  const copyUrlToClipboard = () => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(url)
        .then(() => {
          if (tooltipText === 'Copy url to clipboard') {
            setTooltipText('Copied');
          } else {
            setTooltipText('Copy url to clipboard');
          }
          console.log(`Copied to clipboard: ${url}`);
        })
        .catch((error) => {
          console.error('Failed to copy: ', error);
        });
    }
  };

  const handleMouseLeave = () => {
    setTooltipText('Copy url to clipboard');
  };

  return (
    <div className="dropdown">
      <button
        type="button"
        className="btn btn-light g-min-width-0 g-width-40 g-height-40 p-0"
        data-bs-toggle="dropdown"
        data-bs-auto-close="outside"
        id="dropdownMenuLink"
        aria-expanded="false">
        <i className="fa-light fa-lg fa-share-nodes" />
      </button>
      <ul className="dropdown-menu g-min-width-0">
        <li className="g-pb-10">
          <FacebookShareButton
            url={url}
            quote={title}
            className="dropdown-item justify-content-center">
            <FacebookIcon size={32} round />
          </FacebookShareButton>
        </li>
        <li className="g-pb-10">
          <TelegramShareButton
            url={url}
            title={title}
            className="dropdown-item justify-content-center">
            <TelegramIcon size={32} round />
          </TelegramShareButton>
        </li>
        <li>
          <OverlayTrigger
            placement="top-start"
            delay={{ show: 100, hide: 200 }}
            overlay={<Tooltip id="button-tooltip">{tooltipText}</Tooltip>}>
            <button
              id="copyUrlToClipboard"
              onClick={copyUrlToClipboard}
              onMouseLeave={handleMouseLeave}
              className="dropdown-item tooltip-button justify-content-center bg-transparent p-0">
              <i className="fa-light fa-link-simple fa-xl" />
            </button>
          </OverlayTrigger>
        </li>
        {/* TODO: Share button to IN  */}
      </ul>
    </div>
  );
}

ShareButtons.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
};

export default ShareButtons;
