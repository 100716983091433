import React, { useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';

import { processMembership } from './membershipSlice';
import { getPlanID, getAddOnsID, getFilteredFeatures } from './prices';
import MembershipSummary from './MembershipSummary';

const { CancelToken } = axios;
const SG_PAYMENT_URL = 'pay-enets-sg.shareinvestor.com/redirect.cgi';
const MY_PAYMENT_URL = 'pay-ipay88-my.shareinvestor.com/redirect_my.cgi';

function MembershipPaymentCard({ country }) {
  const source = CancelToken.source();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.membership.data);
  const plan = useSelector((state) => state.membership.plan);
  const period = useSelector((state) => state.membership.period);
  const addOns = useSelector((state) => state.membership.addOns);
  const invoice = useSelector((state) => state.membership.invoice);
  const priceType = useSelector((state) => state.membership.priceType);
  const promoCode = useSelector((state) => state.membership.promoCode);

  const handlePayment = () => {
    const params = { period, plan, priceType };
    const addOnsKeys = Object.keys(addOns).filter((addOnKey) => addOns[addOnKey]);
    if (addOns && addOnsKeys.length > 0) {
      params.addOns = Object.keys(addOns)
        .filter((addOnKey) => addOns[addOnKey])
        .join(',');
    }

    navigate({
      pathname: '/membership/plans/complete',
      search: createSearchParams(params).toString(),
    });

    if (invoice?.invoice_id) {
      const url = country === 'my' ? MY_PAYMENT_URL : SG_PAYMENT_URL;
      const height = country === 'my' ? 820 : 600;
      const width = country === 'my' ? 805 : 800;

      window.open(
        `https://${url}?id=${invoice.invoice_id}`,
        '_blank',
        `menubar=0,scrollbars=1,resizable=1,toolbar=0,location=0,directories=0,status=0,height=${height},width=${width},left=20,top=20,screenX=0,screenY=0'`,
      );
    }
  };

  if (!plan) {
    navigate({
      pathname: '/membership/plans',
      search: createSearchParams({ period }).toString(),
    });
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    if (data?.membership) {
      const filteredArr = getFilteredFeatures(data, plan, period, addOns, priceType);

      dispatch(
        processMembership({
          plan: getPlanID(data, plan, period),
          period,
          addOns: getAddOnsID(data, plan, period, filteredArr),
          payment: 'visa',
          cancelToken: source.token,
          couponCode: promoCode,
        }),
      );
    }
  }, [data]);

  return (
    <MembershipSummary
      country={country}
      handleNextClick={handlePayment}
      nextLabel="Pay with Credit Card"
      nextDisabled={!invoice || !invoice.invoice_id}
    />
  );
}

export default MembershipPaymentCard;

MembershipPaymentCard.propTypes = {
  country: PropTypes.string,
};
