import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  compareObjectsByNumberAttr,
  compareObjectsByStringAttr,
  formatDecimal,
  getMarketFromCounter,
} from '../../../../utils/utils';
import { createLoadableComponent } from '../../../../utils/Loadable';

const AllocationPieChart = createLoadableComponent(() =>
  import('../../../../components/AllocationPieChart'),
);

function extractMarketAllocationData(counters) {
  if (!(counters?.length > 0)) {
    return [];
  }

  const totalValue = counters.reduce((accumulator, c) => {
    return accumulator + c.value;
  }, 0);

  const allocationMap = counters.reduce(
    (accumulator, c) => {
      if (!Object.prototype.hasOwnProperty.call(accumulator, c.market)) {
        accumulator[c.market] = { value: 0, percentage: 0, breakdown: '' };
      }
      const counterPercentage = 100 * (c.value / totalValue);
      accumulator[c.market].breakdown += `- <strong>${c.stockName}</strong>: ${formatDecimal(
        counterPercentage,
        2,
        true,
      )}%\n<br>`;
      accumulator[c.market].value += c.value;
      accumulator[c.market].percentage = 100 * (accumulator[c.market].value / totalValue);

      return accumulator;
    },
    {}, // start with empty map
  );

  return Object.keys(allocationMap)
    .map((market) => {
      return {
        name: market,
        value: Number(formatDecimal(allocationMap[market].value, 3)),
        percentage: Number(formatDecimal(allocationMap[market].percentage, 2)),
        breakdown: allocationMap[market].breakdown,
      };
    })
    .sort((a, b) => compareObjectsByNumberAttr(a, b, 'value', true));
}

export default function Market() {
  const selectedPortfolio = useSelector((state) => state.portfolio.selected);
  const [currentCounters, setCurrentCounters] = useState([]);
  const [allocationData, setAllocationData] = useState([]);

  useEffect(() => {
    if (selectedPortfolio?.counters?.length > 0) {
      // only get from open positions
      const counters = selectedPortfolio.counters
        .filter((c) => c['Shares Held'] && c['Shares Held'] !== '-')
        .map((c) => {
          const market = getMarketFromCounter(c.counter);
          const value = parseFloat(c['Proceeds, Unrealized']?.replaceAll(',', '')) || 0;
          return { counter: c.counter, stockName: c.Name, value, market };
        })
        .sort((a, b) => compareObjectsByStringAttr(a, b, 'counter'));
      if (JSON.stringify(counters) !== JSON.stringify(currentCounters)) {
        setCurrentCounters(counters);
      }
    } else {
      setCurrentCounters([]);
    }
  }, [selectedPortfolio]);

  useEffect(() => {
    setAllocationData(extractMarketAllocationData(currentCounters));
  }, [currentCounters]);

  return (
    <>
      {allocationData.length < 1 && (
        <div className="text-center">No allocation data available.</div>
      )}
      {allocationData.length > 0 && (
        <div className="g-mt-15 g-mb-15 g-height-450">
          <AllocationPieChart data={allocationData} percentInnerRadius={55} percentHeight={95} />
        </div>
      )}
    </>
  );
}
