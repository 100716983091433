import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import store from '../../../utils/store';
import PredefinedTAScreensMain from './PredefinedTAScreensMain';

function PredefinedTAScreens({ market }) {
  return (
    <Provider store={store}>
      <PredefinedTAScreensMain market={market} />
    </Provider>
  );
}

PredefinedTAScreens.propTypes = {
  market: PropTypes.string,
};

export default PredefinedTAScreens;
