import React from 'react';

function Notes() {
  return (
    <div className="disclaimer text-muted small g-mt-40">
      <div className="fw-bold">Notes:</div>
      <ol type="a">
        <li>
          There is generally a delay between the announcement date and the date of effective change
          of interest. ShareInvestor updates the information as soon as the trades are announced by
          the respective listed companies.
        </li>
        <li>
          Please <a href="mailto:admin@shareinvestor.com">click here</a> to let us know if you spot
          any mistakes.
        </li>
        <li>
          Some of the data presented has been intentionally marked and ShareInvestor will not
          hesitate to prosecute to protect our intellectual property rights. Our licensing charge is
          $100 per data item per month.
        </li>
      </ol>
    </div>
  );
}

export default Notes;
