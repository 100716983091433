import ReactOnRails from 'react-on-rails';

// Common
import { NewsDetails } from '../components/News';
import SearchQuote from '../components/SearchQuote';
import QuotePriceSummary from '../components/QuotePriceSummary';
import WorldIndicesProvider from '../components/WorldIndicesPrices/WorldIndicesProvider';
import ProductCarousel from '../components/ProductCarousel';
import ContactButton from '../components/ContactButton';
import SignUp from '../components/SignUp';
import AccountVerification from '../components/AccountVerification';
import StockNewsAlerts from '../components/StockNewsAlerts';

// Home
import {
  Markets,
  MarketNews,
  FeaturesList,
  NotableInvestorPortfolio,
  StocksHighlight,
  OptimizedInvestmentSignals,
  TopBuySell,
} from '../pages/Home';
import Redirect from '../pages/Redirect';

// Factsheet
import Factsheet from '../pages/Factsheet';

// Prices
import StockPrices from '../pages/StockPrices';

// Markets
import ConsensusEstimates from '../pages/Markets/ConsensusEstimates';
import StocksComparison from '../pages/Markets/StocksComparison';
import CompanyBuyback from '../pages/Markets/CompanyBuyback';
import InsiderTrades from '../pages/Markets/InsiderTrades';
import BrokersCall from '../pages/Markets/BrokersCall/index.tsx';

// Watchlist
import Watchlist from '../pages/Watchlist';

// Screener
import IntrinsicValueAnalysis from '../pages/Screener/IntrinsicValueAnalysis';
import MarketScreener from '../pages/Screener/MarketScreener';
import PredefinedTAScreens from '../pages/Screener/PredefinedTAScreens';

// News
import News from '../pages/News';

// Portfolio
import Portfolio from '../pages/Portfolio';

// IPO
import Ipo from '../pages/Ipo';

// Users
import UserLogin from '../pages/UserLogin';
import UserLoginModal from '../pages/UserLogin/UserLoginModal';
import ResetPassword from '../pages/UserResetPassword';
import ChooseUsername from '../pages/UserLogin/ChooseUsername';
import UserSettings from '../pages/User/UserSettings';

// PriceDownload
import PriceDownload from '../pages/Markets/PriceDownload';
// Significant Shareholders & Ownership By Shareholder
import SignificantShareholders from '../pages/Markets/SignificantShareholders';
import OwnershipByShareholder from '../pages/Markets/SignificantShareholders/OwnershipByShareholder';

// Membership
import Membership from '../pages/Membership';
import MembershipCorner from '../pages/Membership/Corner';
import SuccessfulRegistrationMessage from '../components/SuccessfulRegistrationMessage';

// This is how react_on_rails can see the StockPrices in the browser.
ReactOnRails.register({
  // Common
  NewsDetails,
  SearchQuote,
  QuotePriceSummary,
  WorldIndicesProvider,
  ProductCarousel,
  ContactButton,
  SignUp,
  AccountVerification,
  StockNewsAlerts,

  // Home
  Markets,
  MarketNews,
  FeaturesList,
  StocksHighlight,
  OptimizedInvestmentSignals,
  NotableInvestorPortfolio,
  TopBuySell,
  Redirect,

  // Factsheet
  Factsheet,

  // Prices
  StockPrices,

  // Markets
  ConsensusEstimates,
  StocksComparison,
  CompanyBuyback,
  InsiderTrades,
  BrokersCall,

  // Watchlist
  Watchlist,

  // Screener
  IntrinsicValueAnalysis,
  MarketScreener,
  PredefinedTAScreens,

  // News
  News,

  // Portfolio
  Portfolio,

  // IPO
  Ipo,

  // Users
  UserLogin,
  UserLoginModal,
  ResetPassword,
  ChooseUsername,
  UserSettings,

  // PriceDownload
  PriceDownload,
  // Significant Shareholders
  SignificantShareholders,

  // Ownership By Shareholder
  OwnershipByShareholder,

  // Membership
  Membership,
  MembershipCorner,
  SuccessfulRegistrationMessage,
});
