import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { Provider } from 'react-redux';
import ShareButtons from '../../ShareButtons';
import APIErrorHandler from '../../APIErrorHandler';
import Loader from '../../Loader';
import api from '../../../utils/api';
import store from '../../../utils/store';
import { createLoadableComponent } from '../../../utils/Loadable';
import './newsDetails.css';

const StockInfo = createLoadableComponent(() => import('../../StockInfoPopup/StockInfo'));

const { CancelToken } = axios;
function NewsDetails({ nid, nsource }) {
  const source = CancelToken.source();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [shareUrl, setShareUrl] = useState();
  const [error, setError] = useState();
  const [isSSR, setIsSSR] = useState(true);

  let newsId = nid;
  let newsSource = nsource;
  let backTo = () => window.history.back();

  if (!nid && !nsource) {
    const params = useParams();
    newsId = params.newsId;
    const [searchParams] = useSearchParams();
    newsSource = searchParams.get('source');
    const navigate = useNavigate();
    backTo = () => navigate(-1);
  }

  useEffect(() => {
    setIsSSR(typeof document === 'undefined');
    const cancelToken = source.token;

    api
      .get(`news/${newsId}.json?source=${newsSource}`, { cancelToken })
      .then(({ data }) => {
        setData(data);
        setLoading(false);
        setShareUrl(window.location.href);
        // redirect to login page when data.has_access is false
        if (data && !data.has_access) {
          const redirectUrl = '/user/login?fail=market_access&f_c=news&f_a=show';
          window.location.href = redirectUrl;
        }
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }, []);

  if (isSSR || loading) {
    return <Loader visible={isSSR || loading} classes="g-height-800" />;
  }

  if (error) {
    return <APIErrorHandler error={error} />;
  }

  return (
    <Provider store={store}>
      <div className="news-detail-container" id={`newsDetail-${nid}`}>
        <div
          id="news-detail-header"
          className="row justify-content-between align-items-start g-mb-15">
          <div className="col-lg-6">
            {data && data.article && (
              <>
                <h5
                  className="mb-0"
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.article.title) }}
                />
                <p className="g-text-size-14 text-muted d-flex gap-2 align-items-center">
                  <span>
                    <i className="fa-solid fa-clock fa-sm text-primary g-mr-3" />
                    {data.article.date}
                  </span>
                  <span className="text-gray-800"> | </span>
                  <span
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.article.source) }}
                  />
                </p>
              </>
            )}
          </div>
          <div className="col-auto ms-lg-auto">
            <div className="d-flex flex-row gap-2">
              {data && data.article && <ShareButtons url={shareUrl} title={data.article.title} />}
              <button
                className="btn btn-light g-min-width-0"
                type="button"
                onClick={() => {
                  backTo();
                }}>
                Back <i className="fa-light fa-arrow-right g-ml-5" />
              </button>
            </div>
          </div>
        </div>
        <div id="news-detail-body" className="border-1 g-border-t-1 g-pt-50 position-relative">
          <div className="row">
            <div className="col-lg-9">
              {data && (!data.article || !data.has_access) && <p>{data.message}</p>}
              {data && data.article && (
                <>
                  {/* 1. Summary */}
                  {data.article.summary && (
                    <div
                      className="news-summary g-mb-30"
                      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.article.summary) }}
                    />
                  )}

                  {/* 2. withdraw reason */}
                  {data.article.withdraw_reason && (
                    <p className="news-withdraw-reason g-fw-500 g-mb-30">
                      Reason for Withdrawal:
                      <br />
                      {data.article.withdraw_reason}
                    </p>
                  )}

                  {/* 3. Banner */}
                  {data.article.banner_image_url && (
                    <div className="news-banner-img g-mb-50">
                      <img
                        src={data.article.banner_image_url}
                        alt={data.article.banner_image_title}
                        className="img-fluid rounded"
                      />
                      {data.article.banner_image_title && <i>{data.article.banner_image_title}</i>}
                    </div>
                  )}

                  {/* 4. Content */}
                  {data.article.content && (
                    <div
                      className="news-content g-mb-30"
                      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.article.content) }}
                    />
                  )}

                  {/* 5. other images */}
                  {data.article.other_images &&
                    data.article.other_images.length > 0 &&
                    data.article.other_images.map((image) => {
                      return (
                        <div key={image.url} className="news-other-img g-mb-50">
                          <img src={image.url} alt={image.title} className="img-fluid rounded" />
                          {image.title && <i>{image.title}</i>}
                        </div>
                      );
                    })}

                  {/* 6. Attachments */}
                  {data.article.attachments && data.article.attachments.length > 0 && (
                    <div className="news-attachments g-mt-50">
                      <p className="g-fw-500 g-mb-30">Attachments</p>
                      <ul className="list-unstyled">
                        {data.article.attachments.map((attachment) => {
                          return (
                            <li key={attachment.url} className="g-mb-20">
                              <a href={attachment.url} target="_blank" rel="noreferrer">
                                <i className="text-dark fa-light fa-file-pdf fa-xl g-mr-10" />
                                {attachment.title}
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}

                  {/* 7. Related Articles */}
                  {data.related_articles && data.related_articles.length > 0 && (
                    <div className="news-related-article g-mt-30 g-pt-30 g-lg-mt-50 g-lg-pt-80 border-1 g-border-t-1">
                      <h6 className="g-mb-30 g-lg-mb-50">Related News</h6>
                      <div className="row g-3">
                        {data.related_articles.map((article) => {
                          return (
                            <div key={article.url} className="col-lg-3">
                              <div className="g-text-size-14 text-muted d-flex gap-2 align-items-center">
                                <span>
                                  <i className="fa-solid fa-clock fa-sm text-primary g-mr-3" />
                                  {article.date}
                                </span>
                                <span className="text-gray-800"> | </span>
                                {article.source}
                              </div>
                              <div>
                                <span className="badge text-bg-info">{article.stock_name}</span>
                              </div>
                              <a
                                className="d-block text-dark g-mt-10"
                                href={article.url}
                                target={article.force_new_window.toString()}>
                                <h6>{article.title}</h6>
                              </a>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="col-lg-3 ">
              <div className="news-related-counters g-mt-30 g-pt-30 g-lg-mt-0 g-lg-pt-0">
                <h6 className="d-block d-lg-none g-mb-30">Related Counters</h6>
                {data.related_counters && data.related_counters.length > 0 && (
                  <>
                    {data.related_counters.map((relatedCounter) => {
                      return (
                        <div className="card" key={relatedCounter.symbol}>
                          <div className="card-body">
                            <StockInfo data={relatedCounter} template="news" />
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Provider>
  );
}

NewsDetails.propTypes = {
  nid: PropTypes.string,
  nsource: PropTypes.string,
};

export default NewsDetails;
