import axios from 'axios';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
// import Modal from 'react-bootstrap/Modal';
// import Button from 'react-bootstrap/Button';

import CreateUpdatePortfolioModal from './CreateUpdatePortfolioModal';
import { updateFetchData, deleteFetchData } from '../portfolioSlice';
import ModalDialog from '../../../components/ModalDialog';
import classes from '../portfolio.module.css';

const { CancelToken } = axios;

export default function ActionButton() {
  const dispatch = useDispatch();
  const source = CancelToken.source();
  const loading = useSelector((state) => state.portfolio.loading);
  const allPortfolio = useSelector((state) => state.portfolio.all);
  const selectedPortfolio = useSelector((state) => state.portfolio.selected);
  const [modal, setModal] = useState({ show: false, type: '' });

  const handleRenamePortfolio = () => {
    setModal({ show: true, type: 'CreateUpdatePortfolioModal' });
  };

  const handleCloseModal = () => {
    setModal({ show: false, type: '' });
  };

  const handleDeletePortfolio = () => {
    setModal({ show: true, type: 'DeleteDialog' });
  };

  const confirmDeletePortfolio = () => {
    const params = { id: selectedPortfolio.id, cancelToken: source.token };
    dispatch(deleteFetchData(params));
    handleCloseModal();
  };

  const handleSetDefaultPortfolio = () => {
    const params = { id: selectedPortfolio.id, setDefault: '1', cancelToken: source.token };
    dispatch(updateFetchData(params));
  };

  return (
    <>
      <Dropdown disabled={loading}>
        <Dropdown.Toggle
          variant="light"
          className={`no-default-caret ${classes.portfolioActionButton}`}>
          <i className="fa-light fa-gear" />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={handleRenamePortfolio}>Rename Portfolio</Dropdown.Item>
          {allPortfolio.length !== 1 && (
            <Dropdown.Item onClick={handleDeletePortfolio}>Delete Portfolio</Dropdown.Item>
          )}
          {!selectedPortfolio.default && (
            <Dropdown.Item onClick={handleSetDefaultPortfolio}>Set as Default</Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
      <CreateUpdatePortfolioModal
        show={modal.type === 'CreateUpdatePortfolioModal' && modal.show}
        handleCloseModal={handleCloseModal}
        currentPortfolio={selectedPortfolio}
      />
      <ModalDialog
        show={modal.type === 'DeleteDialog' && modal.show}
        handleClose={handleCloseModal}
        title="Delete Portfolio"
        body={`Are you sure you want to delete ${selectedPortfolio.name}?`}
        handleSave={confirmDeletePortfolio}
        saveLabel="Delete"
        saveVariant="danger"
      />
    </>
  );
}
