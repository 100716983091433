import React from 'react';
import PropTypes from 'prop-types';
import ReactDOMServer from 'react-dom/server';

// If want to hide header or footer, do it in the component itself
function PrintButton({ title, autoPrint, disabled, printNodeID }) {
  /**
   * Convert a URL from a relative to an absolute address so it will work
   * correctly in the popup window which has no base URL.
   *
   * @param  {string} href URL
   */
  const relToAbs = (href, link) => {
    // Assign to a link on the original page so the browser will do all the
    // hard work of figuring out where the file actually is
    link.href = href;
    let linkHost = link.host;

    // IE doesn't have a trailing slash on the host
    // Chrome has it on the pathname
    if (linkHost.indexOf('/') === -1 && link.pathname.indexOf('/') !== 0) {
      linkHost += '/';
    }

    return `${link.protocol}//${linkHost}${link.pathname}${link.search}`;
  };

  /**
   * Clone link and style tags, taking into account the need to change the source
   * path.
   *
   * @param  {node}     el Element to convert
   */
  const styleToAbs = (el, link) => {
    const clone = el.cloneNode(true);

    if (clone.nodeName.toLowerCase() === 'link') {
      clone.href = relToAbs(clone.href, link);
    }

    return clone.outerHTML;
  };

  const handleOnPressPrint = () => {
    const printNode = document.getElementById(printNodeID);
    if (!printNode) {
      return null;
    }

    const parentContainer = document.createElement('div');
    parentContainer.appendChild(printNode.cloneNode(true));
    const printNodeString = parentContainer.innerHTML;

    const win = window.open('', '');
    win.document.close();

    let head = `<title>${title}</title>`;
    const link = document.createElement('a');

    document.querySelectorAll('style, link').forEach((el) => {
      head += styleToAbs(el, link);
    });

    try {
      win.document.head.innerHTML = head;
    } catch (e) {
      win.document.head.innerHTML = head; // Handle old IE
    }

    win.document.body.innerHTML = `<div class="container">${printNodeString}</div>`;
    win.document.body.classList.add('dt-print-view');

    const images = win.document.querySelectorAll('img');
    images.forEach((img) => {
      img.src = relToAbs(img.src, link);
    });

    setTimeout(() => {
      if (autoPrint) {
        win.print();
        win.close();
      }
    }, 1000);
  };

  return (
    <button
      type="button"
      className="btn btn-light"
      disabled={disabled}
      onClick={handleOnPressPrint}>
      <i className="fa-thin fa-print fa-lg g-mr-5" /> Print
    </button>
  );
}

PrintButton.propTypes = {
  printNodeID: PropTypes.string,
  title: PropTypes.string,
  autoPrint: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default PrintButton;
