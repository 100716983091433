import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Card from 'react-bootstrap/Card';

import api from '../../../../utils/api';
import APIErrorHandler from '../../../../components/APIErrorHandler';
import Table from './Table';

const { CancelToken } = axios;

export default function TechAnalysisAndScanResults() {
  const source = CancelToken.source();
  const selectedPortfolio = useSelector((state) => state.portfolio.selected);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  const fetchData = () => {
    const cancelToken = source.token;
    const url = `portfolio/${selectedPortfolio.id}/ta_scanner_results.json`;

    setLoading(true);

    api
      .get(url, { cancelToken })
      .then(({ data }) => {
        setData(data.data);
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (selectedPortfolio.id) {
      fetchData();
    }
  }, [selectedPortfolio]);

  return (
    <Card className="position-static h-100">
      <Card.Body className="g-min-height-555">
        <Card.Title>Technical Analysis and Scan Results</Card.Title>
        {loading && (
          <div className="g-text-size-18 g-lg-text-size-30 text-center">
            <i className="fa-duotone fa-spinner-third fa-spin" />
          </div>
        )}
        <APIErrorHandler error={error} />
        {!error && !loading && data && data.length > 0 && <Table data={data} />}
        {!error && !loading && (!data || data.length === 0) && (
          <div className="text-center">No data found.</div>
        )}
      </Card.Body>
    </Card>
  );
}
