import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Responsive from '@amcharts/amcharts5/themes/Responsive';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

import classes from '../../portfolio.module.css';

const CURRENCY = 0x1a62e8;
const CAPITAL = 0x6893dc;
const DIVIDEND = 0x67b7dc;

function GrowthChart({ portfolioData, chartMarkers, selectedPeriodInterval, selectedRange }) {
  useLayoutEffect(() => {
    const root = am5.Root.new('PortfolioGrowthChart');
    root.setThemes([am5themes_Animated.new(root), am5themes_Responsive.newEmpty(root)]);
    root.numberFormatter.setAll({
      numberFormat: '#a',

      // Group only into M (millions), and B (billions)
      bigNumberPrefixes: [
        { number: 1e3, suffix: 'K' },
        { number: 1e6, suffix: 'M' },
        { number: 1e9, suffix: 'B' },
      ],

      // Do not use small number prefixes at all
      smallNumberPrefixes: [],
    });

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        paddingLeft: 0,
        layout: root.verticalLayout,
        maxTooltipDistance: 0,
      }),
    );

    const data = Object.entries(portfolioData).map(([key, val]) => {
      return {
        date: Date.parse(key),
        capital_gain: val.incremental_capital_gain,
        currency_gain: val.incremental_currency_gain,
        dividend: val.incremental_dividend,
      };
    });

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    const xRenderer = am5xy.AxisRendererX.new(root, {
      minorGridEnabled: true,
    });
    const xAxis = chart.xAxes.push(
      am5xy.GaplessDateAxis.new(root, {
        groupData: true,
        groupCount: 150,
        groupIntervals: [selectedPeriodInterval],
        baseInterval: {
          timeUnit: 'day',
          count: 1,
        },
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {}),
      }),
    );
    xAxis.get('renderer').labels.template.setAll({
      fill: am5.color(0x646464),
      fontSize: 12,
    });

    xAxis.get('dateFormats').day = 'dd MMM yy';
    xAxis.get('dateFormats').week = 'dd MMM yy';
    xAxis.get('dateFormats').month = 'MMM yy';
    xAxis.get('dateFormats').year = 'yyyy';
    xAxis.get('periodChangeDateFormats').day = 'dd MMM yy';
    xAxis.get('periodChangeDateFormats').week = 'dd MMM yy';
    xAxis.get('periodChangeDateFormats').month = 'MMM yy';
    xAxis.get('periodChangeDateFormats').year = 'yyyy';

    xAxis.data.setAll(data);

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        // min: 0,
        renderer: am5xy.AxisRendererY.new(root, {
          strokeOpacity: 0.1,
        }),
      }),
    );
    yAxis.get('renderer').labels.template.setAll({
      fill: am5.color(0x646464),
      fontSize: 12,
    });

    // Add legend
    // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
    const legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50,
      }),
    );
    legend.labels.template.setAll({
      fill: am5.color(0x646464),
      fontSize: 12,
    });

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    function makeSeries(name, fieldName, color) {
      const tooltip = am5.Tooltip.new(root, {
        getFillFromSprite: false,
        getStrokeFromSprite: false,
        autoTextColor: false,
        getLabelFillFromSprite: true,
      });
      tooltip.get('background').setAll({
        stroke: am5.color(0x646464),
        color: am5.color(0x646464),
        fill: am5.color(0xffffff),
        strokeOpacity: 0.8,
      });
      const series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name,
          stacked: true,
          xAxis,
          yAxis,
          valueYField: fieldName,
          valueXField: 'date',
          fill: am5.color(color),
          tooltip,
          groupDataWithOriginals: true,
          groupDataCallback(dataItem, interval) {
            const originals = dataItem.get('originals');
            const sum = originals.reduce(
              (partialSum, a) => partialSum + (parseFloat(a.get('valueY')) || 0),
              0,
            );
            const valueY = sum / originals.length;
            dataItem.set('valueY', valueY);
            dataItem.set('valueYWorking', valueY);
          },
        }),
      );

      series.get('tooltip').label.adapters.add('text', (text, target) => {
        let newText = "[#646464 bold]{valueX.formatDate('dd MMM yyyy')}[/]\n\n";
        let i = 0;
        chart.series.each((series_) => {
          const tooltipDataItem = series_.get('tooltipDataItem');
          if (tooltipDataItem) {
            if (i !== 0) {
              newText += '\n';
            }
            newText += `[${series_.get('fill').toString()}]●[/] [#646464]${series_.get(
              'name',
            )}:[/] [#646464 bold width:120px]${tooltipDataItem
              .get('valueY')
              .toLocaleString('en-SG')}[/]`;
          }
          i += 1;
        });
        return newText;
      });
      series.get('tooltip').on('visible', function (visible, target) {
        if (visible) {
          chart.series.each(function (s) {
            if (s.get('tooltip') !== target) {
              s.get('tooltip').set('visible', false);
            }
          });
        }
      });

      series.columns.template.setAll({
        tooltipText: '[bold]{name}[/]: {valueY.formatNumber("#.0a")}',
        tooltipY: am5.percent(10),
      });
      series.data.setAll(data);

      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      series.appear();

      series.bullets.push(() => {
        return am5.Bullet.new(root, {});
      });

      legend.data.push(series);

      series.events.once('datavalidated', (ev) => {
        if (!selectedRange || !selectedRange.startDate) {
          return;
        }

        // To make sure the data is in center get start_date - 1day and end_date + 1day
        const startDate = new Date(selectedRange.startDate);
        const endDAte = new Date();
        startDate.setDate(startDate.getDate() - 1);
        endDAte.setDate(endDAte.getDate() + 1);
        ev.target.get('xAxis').zoomToDates(startDate, endDAte);
      });
    }

    makeSeries('Dividends', 'dividend', DIVIDEND);
    makeSeries('Capital Gain', 'capital_gain', CAPITAL);
    makeSeries('Currency Gain', 'currency_gain', CURRENCY);

    // Add cursor
    const cursor = chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        xAxis,
      }),
    );
    cursor.lineY.set('visible', false);

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [portfolioData, chartMarkers, selectedPeriodInterval, selectedRange]);

  return <div id="PortfolioGrowthChart" className={classes.portfolioPerformanceChart} />;
}

GrowthChart.propTypes = {
  portfolioData: PropTypes.object,
  chartMarkers: PropTypes.array,
  selectedPeriodInterval: PropTypes.object,
  selectedRange: PropTypes.object,
};

export default GrowthChart;
