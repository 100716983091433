import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import Card from 'react-bootstrap/Card';
import DOMPurify from 'dompurify';

import { fetchRelatedInsiderTrades } from '../portfolioSlice';
import Pagination from '../../../components/Pagination';
import APIErrorHandler from '../../../components/APIErrorHandler';
import { compareObjectsByNumberAttr, getParamCounterId } from '../../../utils/utils';
import classes from './insights.module.css';

const { CancelToken } = axios;
const RECORDS_PER_PAGE = 8;

export default function InsiderTradesAndCompanyBuyback() {
  const dispatch = useDispatch();
  const source = CancelToken.source();
  const selectedPortfolio = useSelector((state) => state.portfolio.selected);
  const relatedInsiderTrades = useSelector((state) => state.portfolio.relatedInsiderTrades);
  const [currentCounters, setCurrentCounters] = useState([]);
  const [selectOptions, setSelectOptions] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [activeCounter, setActiveCounter] = useState(null);

  useEffect(() => {
    if (selectedPortfolio?.counters?.length > 0) {
      const counters = selectedPortfolio.counters
        .map((c) => {
          return { counter: c.counter, stockName: c.Name, position: c.No };
        })
        .sort((a, b) => compareObjectsByNumberAttr(a, b, 'position'));
      if (JSON.stringify(counters) !== JSON.stringify(currentCounters)) {
        setCurrentCounters(counters);
        setActiveCounter(counters[0].counter);
      }
    }
  }, [selectedPortfolio]);

  useEffect(() => {
    if (currentCounters.length > 0) {
      setSelectOptions(
        currentCounters.map((c) => {
          return { value: c.counter, label: `${c.stockName} (${c.counter})` };
        }),
      );

      dispatch(
        fetchRelatedInsiderTrades({
          selectedPortfolioID: selectedPortfolio.id,
          cancelToken: source.token,
        }),
      );
    } else {
      setSelectOptions([]);
    }
  }, [currentCounters]);

  useEffect(() => {
    if (activeCounter && relatedInsiderTrades?.insiderTrades?.length > 0) {
      setFilteredData(
        relatedInsiderTrades.insiderTrades.filter((it) => it.counter === activeCounter),
      );
    }
  }, [activeCounter, relatedInsiderTrades]);

  const handleActiveCounterChange = (newCounter) => {
    if (newCounter && newCounter.value !== activeCounter) {
      setActiveCounter(newCounter.value);
    }
  };

  return (
    <Card className="position-static">
      <Card.Body className="g-min-height-555">
        <div className="g-mb-15 d-flex flex-column flex-xl-row justify-content-between">
          <Card.Title>Related Insider Trades and Company Buyback</Card.Title>
          {selectOptions.length > 0 && (
            <Select
              unstyled
              isClearable
              backspaceRemovesValue
              className="react-select-container d-inline-block g-width-275 g-max-per-width-100"
              classNamePrefix="react-select"
              defaultValue={{
                value: activeCounter || selectOptions[0].value,
                label:
                  currentCounters.filter((c) => c.value === activeCounter)[0]?.stockName ||
                  selectOptions[0].label,
              }}
              onChange={handleActiveCounterChange}
              options={selectOptions}
            />
          )}
        </div>
        <APIErrorHandler error={relatedInsiderTrades?.error} />
        <RelatedInsiderTradesTable data={filteredData} />
      </Card.Body>
    </Card>
  );
}

function RelatedInsiderTradesTable({ data }) {
  const [tableData, setTableData] = useState([]);
  const [pagination, setPagination] = useState({});

  useEffect(() => {
    if (data?.length > 0) {
      const newPagination = {
        currentPage: 1,
        rowOffset: 0,
        currentPageRows: data.length > RECORDS_PER_PAGE ? RECORDS_PER_PAGE : data.length,
        totalRows: data.length,
        totalPages: Math.ceil(data.length / RECORDS_PER_PAGE),
      };
      setTableData(data.slice(0, newPagination.currentPageRows));
      setPagination(newPagination);
    } else {
      setTableData([]);
    }
  }, [data]);

  const handlePageChange = (newPage) => {
    if (!newPage || pagination.currentPage === newPage) {
      return;
    }

    if (data?.length > 0) {
      const newPagination = { ...pagination };
      newPagination.currentPage = newPage;
      newPagination.rowOffset = (newPage - 1) * RECORDS_PER_PAGE;
      const newOffsetTableData = data.slice(newPagination.rowOffset);
      newPagination.currentPageRows =
        newOffsetTableData.length > RECORDS_PER_PAGE ? RECORDS_PER_PAGE : newOffsetTableData.length;
      setTableData(newOffsetTableData.slice(0, newPagination.currentPageRows));
      setPagination(newPagination);
    }
  };

  return (
    <>
      <div className="table-responsive g-min-height-542">
        <table className="table table-hover mb-0">
          <thead className="table-light">
            <tr>
              <th className={`${classes.alignMiddle} ${classes.alignCenter}`}>Announcement Date</th>
              <th className={`${classes.alignMiddle} ${classes.alignCenter}`}>Effective Date</th>
              <th className={`${classes.alignMiddle} ${classes.alignCenter}`}>Name</th>
              <th className={`${classes.alignMiddle} ${classes.alignCenter}`}>Buyer/Seller Name</th>
              <th className={`${classes.alignMiddle} ${classes.alignCenter}`}>
                {`Acquired/Disposed ['000]`}
              </th>
              <th className={`${classes.alignMiddle} ${classes.alignCenter}`}>Price</th>
              <th className={`${classes.alignMiddle} ${classes.alignCenter}`}>Note</th>
            </tr>
          </thead>
          <tbody>
            {tableData.length < 1 && (
              <tr>
                <td colSpan="7" className="text-center align-middle">
                  No available data.
                </td>
              </tr>
            )}
            {tableData.length > 0 &&
              tableData.map((it, index) => {
                return (
                  <tr key={`${index}_${it.counter}`}>
                    {/* Announcement Date */}
                    <td className={classes.alignCenter}>{it.announcementDate || '-'}</td>
                    {/* Effective Date */}
                    <td className={classes.alignCenter}>{it.effectiveDate || '-'}</td>
                    {/* Stock Name */}
                    <td>
                      <a href={`/quote/${getParamCounterId(it.counter)}/insider_trades`}>
                        {it.stockName}
                      </a>
                    </td>
                    {/* Buyer/Seller Name */}
                    <td className={classes.alignCenter}>{it.buyerSeller || '-'}</td>
                    {/* Acquired/Disposed ['000] */}
                    <td className={classes.alignCenter}>{it.tradedVolume || '-'}</td>
                    {/* Price */}
                    <td className={classes.alignCenter}>{it.tradedPrice || '-'}</td>
                    {/* Note */}
                    <td className={classes.alignCenter}>
                      {it.remarksHtml && (
                        <span className="hover_popup_container g-pl-5">
                          <i className="fa-thin fa-note fa-xl" role="button" />
                          <div className="hover_popup_content left arrow p-3 g-border-radius-10 border bg-white">
                            <div className="hover_popup_content_inner g-max-height-300 overflow-y-scroll text-start text-muted g-width-300 g-sm-width-500 g-md-width-700">
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: DOMPurify.sanitize(it.remarksHtml),
                                }}
                              />
                            </div>
                          </div>
                        </span>
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      {tableData.length > 0 && (
        <Pagination
          rowOffset={pagination.rowOffset}
          currentPageRows={pagination.currentPageRows}
          totalRows={pagination.totalRows}
          currentPage={pagination.currentPage}
          totalPages={pagination.totalPages}
          handlePageChange={handlePageChange}
        />
      )}
    </>
  );
}
