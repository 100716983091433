import { createSlice } from '@reduxjs/toolkit';
import api from '../../../../utils/api';

const companyBuybackLatestSlice = createSlice({
  name: 'marketscompanyBuybackLatest',
  initialState: {
    data: {},
    loading: true,
    err: null,
    pagination: {},
    allCounterLists: {},
    loadingCounterList: true,
    errCounterList: null,
  },
  reducers: {
    companyBuybackLatestDataFetchStart(state, action) {
      state.data = null;
      state.err = null;
      state.loading = true;
    },
    companyBuybackLatestDataFetchSuccess(state, action) {
      state.data = action.payload.data;
      state.pagination = action.payload.data.pagination;
      state.err = null;
      state.loading = false;
    },
    companyBuybackLatestDataFetchFail(state, action) {
      state.err = action.payload;
      state.loading = false;
    },
    companyBuybackCounterListDataFetchStart(state, action) {
      state.allCounterLists = null;
      state.errCounterList = null;
      state.loadingCounterList = true;
    },
    companyBuybackCounterListDataFetchSuccess(state, action) {
      state.allCounterLists = action.payload.data.all_counter_lists;
      state.errCounterList = null;
      state.loadingCounterList = false;
    },
    companyBuybackCounterListDataFetchFail(state, action) {
      state.errCounterList = action.payload;
      state.loadingCounterList = false;
    },
  },
});

export default companyBuybackLatestSlice.reducer;

const {
  companyBuybackLatestDataFetchStart,
  companyBuybackLatestDataFetchSuccess,
  companyBuybackLatestDataFetchFail,
  companyBuybackCounterListDataFetchStart,
  companyBuybackCounterListDataFetchSuccess,
  companyBuybackCounterListDataFetchFail,
} = companyBuybackLatestSlice.actions;

export const fetchData =
  ({ market, fromDate, toDate, counter, page, cancelToken }) =>
  (dispatch) => {
    let url = `/${market}/company_buyback_latest.json?page=${page}`;
    if (counter) {
      url += `&counter=${counter}`;
    }
    if (fromDate) {
      url += `&search_start_date=${fromDate}`;
    }
    if (toDate) {
      url += `&search_end_date=${toDate}`;
    }

    dispatch(companyBuybackLatestDataFetchStart());
    api
      .get(url, {
        cancelToken,
      })
      .then(({ data }) => {
        dispatch(companyBuybackLatestDataFetchSuccess({ data }));
      })
      .catch((error) => {
        dispatch(companyBuybackLatestDataFetchFail(error));
      });
  };

export const fetchAllCounterLists =
  ({ market, cancelToken }) =>
  (dispatch) => {
    const url = `/${market}/company_buyback_all_counter_lists.json`;

    dispatch(companyBuybackCounterListDataFetchStart());
    api
      .get(url, {
        cancelToken,
      })
      .then(({ data }) => {
        dispatch(companyBuybackCounterListDataFetchSuccess({ data }));
      })
      .catch((error) => {
        dispatch(companyBuybackCounterListDataFetchFail(error));
      });
  };
