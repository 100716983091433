import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';

import store from '../../utils/store';
import WorldIndicesPrices from '.';

async function stopStreaming() {
  const { forceStopStreaming } = await import('../../utils/Streaming');
  const { stopWldIndicesPricesStreaming } = await import(
    '../../utils/Streaming/world_indices_prices'
  );
  stopWldIndicesPricesStreaming();
  forceStopStreaming();
}

export default function WorldIndicesProvider({ defaultIndices }) {
  useEffect(() => {
    return () => {
      stopStreaming();
    };
  }, []);

  return (
    <Provider store={store}>
      <WorldIndicesPrices defaultIndices={defaultIndices} />
    </Provider>
  );
}

WorldIndicesProvider.propTypes = {
  defaultIndices: PropTypes.array,
};
