import { createSlice } from '@reduxjs/toolkit';
import api from '../../../../utils/api';

const companyBuybackOverviewSlice = createSlice({
  name: 'marketsCompanyBuybackOverview',
  initialState: {
    data: {},
    loading: true,
    err: null,
    allCounterLists: null,
    loadingCounterList: true,
    errCounterList: null,
  },
  reducers: {
    companyBuybackOverviewDataFetchStart(state, action) {
      state.data = null;
      state.err = null;
      state.loading = true;
    },
    companyBuybackOverviewDataFetchSuccess(state, action) {
      state.data = action.payload.data;
      state.err = null;
      state.loading = false;
    },
    companyBuybackOverviewDataFetchFail(state, action) {
      state.err = action.payload;
      state.loading = false;
    },
    companyBuybackCounterListDataFetchStart(state, action) {
      state.allCounterLists = null;
      state.errCounterList = null;
      state.loadingCounterList = true;
    },
    companyBuybackCounterListDataFetchSuccess(state, action) {
      state.allCounterLists = action.payload.data.all_counter_lists;
      state.errCounterList = null;
      state.loadingCounterList = false;
    },
    companyBuybackCounterListDataFetchFail(state, action) {
      state.errCounterList = action.payload;
      state.loadingCounterList = false;
    },
  },
});

export default companyBuybackOverviewSlice.reducer;

const {
  companyBuybackOverviewDataFetchStart,
  companyBuybackOverviewDataFetchSuccess,
  companyBuybackOverviewDataFetchFail,
  companyBuybackCounterListDataFetchStart,
  companyBuybackCounterListDataFetchSuccess,
  companyBuybackCounterListDataFetchFail,
} = companyBuybackOverviewSlice.actions;

export const fetchData =
  ({ market, cancelToken }) =>
  (dispatch) => {
    const url = `/${market}/company_buyback_overview.json`;
    dispatch(companyBuybackOverviewDataFetchStart());
    api
      .get(url, {
        cancelToken,
      })
      .then(({ data }) => {
        dispatch(companyBuybackOverviewDataFetchSuccess({ data }));
      })
      .catch((error) => {
        dispatch(companyBuybackOverviewDataFetchFail(error));
      });
  };

export const fetchAllCounterLists =
  ({ market, cancelToken }) =>
  (dispatch) => {
    const url = `/${market}/company_buyback_all_counter_lists.json`;

    dispatch(companyBuybackCounterListDataFetchStart());
    api
      .get(url, {
        cancelToken,
      })
      .then(({ data }) => {
        dispatch(companyBuybackCounterListDataFetchSuccess({ data }));
      })
      .catch((error) => {
        dispatch(companyBuybackCounterListDataFetchFail(error));
      });
  };
