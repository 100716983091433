import React from 'react';
import PropTypes from 'prop-types';
import RouterNavComponent from './RouterNavComponent';

export default function Content({ activeNav, routes, currentLevel }) {
  if (!routes || routes.length === 0) {
    return null;
  }

  if (currentLevel === 0 && routes[currentLevel].element) {
    const parentProps = routes[currentLevel].element.props;
    if (parentProps.classes && parentProps.navLinks && parentProps.defaultActiveNav) {
      return (
        <RouterNavComponent
          classes={parentProps.classes}
          navLinks={parentProps.navLinks}
          defaultActiveNav={parentProps.defaultActiveNav}
          content={
            <Content
              activeNav={activeNav}
              routes={routes[currentLevel].children}
              currentLevel={currentLevel}
            />
          }
        />
      );
    }
  }

  const active = activeNav[currentLevel] || routes[0].key;
  let currentRoute = routes.filter((r) => r.key === active);
  if (!currentRoute || currentRoute.length === 0) {
    if (!Number.isNaN(parseInt(active))) {
      currentRoute = routes.filter((r) => r.key[0] === ':');
    } else {
      return null;
    }
  }

  const component = currentRoute[0].element;
  if (component) {
    return component;
  }
  return (
    <Content
      activeNav={activeNav}
      routes={currentRoute[0].children}
      currentLevel={currentLevel + 1}
    />
  );
}

Content.propTypes = {
  routes: PropTypes.array,
  activeNav: PropTypes.array,
  currentLevel: PropTypes.number,
};
