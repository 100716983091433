import React from 'react';
import contactSG from 'images/icon-contact-sg.svg';
import contactMY from 'images/icon-contact-my.svg';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import styles from './contactButton.module.css';

const MY_CONTENT = (
  <div class="position-relative">
    <div>
      <strong>Phone</strong>
    </div>
    <div>
      Main : <strong>+60 3 8681 0580</strong>{' '}
      <a target="_blank" class="${styles.whatsAppIcon}" href="https://wa.me/601163438939">
        <i class="fa-brands fa-whatsapp"></i>
      </a>
    </div>
    <div>(Please call during office hours)</div>
    <div>Monday - Friday, 8.30am - 12.30pm, 2.00pm - 5.30pm</div>
    <hr />
    <div>
      <strong>Email</strong>
    </div>
    <div>
      Customer Service:{' '}
      <strong>
        <a href="mailto:accounts.my@shareinvestor.com">accounts.my@shareinvestor.com</a>
      </strong>
    </div>
    <div>
      Technical Support:{' '}
      <strong>
        <a href="mailto:admin.my@shareinvestor.com ">admin.my@shareinvestor.com</a>
      </strong>
    </div>
  </div>
);

const SG_CONTENT = (
  <div class="position-relative">
    <div>
      <strong>Phone</strong>
    </div>
    <div>
      Main : <strong>+65 6958 8000</strong>
    </div>
    <div>
      Membership Support : <strong>+65 6958 8000</strong>
    </div>
    <div>
      Technical Support : <strong>+65 6958 8001</strong>
    </div>
    <div>(Please call during office hours)</div>
    <div>Monday - Friday, 8.30am - 12.30pm, 2.00pm - 5.30pm</div>
    <hr />
    <div>
      <strong>Email</strong>
    </div>
    <div>
      Customer Service:{' '}
      <strong>
        <a href="mailto:admin@shareinvestor.com">admin@shareinvestor.com</a>
      </strong>
    </div>
    <div>
      Technical Support:{' '}
      <strong>
        <a href="mailto:admin@shareinvestor.com">admin@shareinvestor.com</a>
      </strong>
    </div>
  </div>
);

const baseUrl = 'https://blog.shareinvestor.com';

function CardItem({ href, iconClass, title }) {
  return (
    <div className="col-6 col-md-5 mb-3 d-flex">
      <div className="card h-100 w-100">
        <div className="card-body text-center">
          <a className="no-underline" href={baseUrl + href} target="_blank" rel="noreferrer">
            <i className={`${iconClass} text-success g-text-size-22`} />
            <div className="card-title fw-bold">{title}</div>
          </a>
        </div>
      </div>
    </div>
  );
}

const chunkArray = (array, size) => {
  const result = [];
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
};

function ContactButton({ country = 'sg', announcementMsg = {} }) {
  const content = country === 'sg' ? SG_CONTENT : MY_CONTENT;
  const buttonImage = country === 'sg' ? contactSG : contactMY;

  const handleClick = () => {
    const supportButton = document.getElementById('supportButton');
    if (supportButton) {
      // close
      supportButton.click();
    }
  };

  const renderOverlay = (data, announcementMsg) => {
    const cardData = [
      {
        href: '/faqs/factsheet/',
        iconClass: 'fa-light fa-file-chart-column',
        title: 'Factsheet',
      },
      {
        href: '/faqs/stock-price/',
        iconClass: 'fa-light fa-chart-mixed',
        title: 'Stock Price',
      },
      {
        href: '/faqs/stocks-comparison/',
        iconClass: 'fa-light fa-code-compare',
        title: 'Stocks Comparison',
      },
      {
        href: '/faqs/consensus-estimates/',
        iconClass: 'fa-light fa-chart-scatter',
        title: 'Consensus Estimates',
      },
      {
        href: '/faqs/insider-trades/',
        iconClass: 'fa-light fa-chart-user',
        title: 'Insider Trades',
      },
      {
        href: '/faqs/company-buyback/',
        iconClass: 'fa-light fa-money-bill-transfer',
        title: 'Company Buyback',
      },
      {
        href: '/faqs/brokers-call/',
        iconClass: 'fa-light fa-rectangle-history-circle-user',
        title: "Broker's Call",
      },
      {
        href: '/faqs/price-download/',
        iconClass: 'fa-light fa-file-arrow-down',
        title: 'Price Download',
      },
      {
        href: '/faqs/significant-shareholders/',
        iconClass: 'fa-light fa-hand-holding-dollar',
        title: 'Significant Shareholders',
      },
      {
        href: '/faqs/charts/',
        iconClass: 'fa-light fa-chart-pie',
        title: 'Charts',
      },
      {
        href: '/faqs/watchlist/',
        iconClass: 'fa-light fa-chart-user',
        title: 'Watchlist',
      },
      {
        href: '/faqs/portfolio/',
        iconClass: 'fa-light fa-file-chart-pie',
        title: 'Portfolio',
      },
      {
        href: '/faqs/screener/',
        iconClass: 'fa-light fa-chart-bullet',
        title: 'Screener',
      },
      {
        href: '/faqs/news/',
        iconClass: 'fa-light fa-newspaper',
        title: 'News',
      },
      {
        href: '/faqs/ipo/',
        iconClass: 'fa-light fa-city',
        title: 'IPO',
      },
    ];

    // each row contian 2 cards only
    const cardRows = chunkArray(cardData, 2);

    return (
      <div className={`${styles.tooltipFloatContact} tooltip_custom`}>
        <div className={`${styles.tooltipHeader} text-start text-white p-3 text-center`}>
          <div className="row justify-content-between">
            <div className="col-auto">
              <button
                className={`${styles.supportButton} btn btn-sm text-center badge`}
                type="submit">
                <a href="/support" target="_blank" className="no-underline g-text-size-14">
                  <i className="fa-regular fa-headset" /> Support
                </a>
              </button>
            </div>
            <div className="col-auto">
              <button
                className={`text-center btn btn-sm btn-rounded p-0 d-inline-block g-min-width-auto ${styles.closeButton}`}
                onClick={handleClick}
                type="submit">
                <i className="fa-solid fa-dash" />
              </button>
            </div>
          </div>
          <div className="g-text-size-22">What can we help you with?</div>
        </div>
        <div className="text-start p-2 text-center">
          <p className="g-text-size-24 pt-2">Get the answers you're looking for</p>
          <p className="small">Browse our knowledge base by category</p>
        </div>
        <div className={`${styles.scrollableContainer} container mt-3 text-center`}>
          {cardRows.map((row, rowIndex) => (
            <div key={rowIndex} className="row justify-content-center d-flex align-items-stretch">
              {row.map((card, cardIndex) => (
                <CardItem key={cardIndex} {...card} />
              ))}
            </div>
          ))}

          {announcementMsg && (
            <div className={`text-start overflow-x-hidden ${styles.annContainer}`}>
              <div className="alert alert-secondary">
                <p>
                  <b>{announcementMsg.title}</b>
                </p>
                <p>{announcementMsg.description}</p>
              </div>
            </div>
          )}
          <div className="text-start overflow-x-hidden p-3">{data}</div>
        </div>
      </div>
    );
  };

  return (
    <div className={`${styles.floatContact} d-print-none`}>
      <OverlayTrigger
        overlay={renderOverlay(content, announcementMsg)}
        trigger={['click']}
        placement="top-start"
        rootClose>
        <button className={styles.contactButton} id="supportButton" type="submit">
          <img src={buttonImage} className="g-width-50" />
        </button>
      </OverlayTrigger>
    </div>
  );
}

ContactButton.propTypes = {
  country: PropTypes.string,
  announcementMsg: PropTypes.any,
};

export default ContactButton;
