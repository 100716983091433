import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import { fetchData } from './ipoSlice';
import RecentIpo from './RecentIpo';
import IpoOpen from './IpoOpen';
import MarketSelectDropdown from '../../components/MarketSelectDropdown';

const { CancelToken } = axios;

function IpoMain({ market }) {
  const dispatch = useDispatch();
  const source = CancelToken.source();
  const data = useSelector((state) => state.ipo.data);
  const selectedMarket = useSelector((state) => state.selectedMarket.selectedMarket);

  useEffect(() => {
    if (selectedMarket != null) {
      // set params
      const url = new URL(window.location);
      url.searchParams.set('market', selectedMarket);
      window.history.pushState({ market: selectedMarket }, document.title, url);

      // get data
      dispatch(
        fetchData({
          market: selectedMarket,
          cancelToken: source.token,
        }),
      );
    }
    return () => {};
  }, [selectedMarket]);

  const ipoHandler = () => {
    const url =
      selectedMarket == 'bursa'
        ? 'https://www.sc.com.my/regulation/guidelines/lodge-and-launch-framework'
        : 'https://eservices.mas.gov.sg/opera/';
    window.open(url, '_blank', '');
  };

  const catalistHandler = () => {
    window.open('https://www2.sgx.com/regulation/catalodge', '_blank', '');
  };

  return (
    <div>
      <div className="row align-items-center g-lg-mb-30 g-mb-20">
        <div className="col-lg-8">
          <h3 className="g-mb-5 g-lg-mb-0">Initial Public Offering (IPO)</h3>
        </div>
        <div className="col-lg-4 d-flex justify-content-lg-end justify-content-start" />
      </div>
      <div className="divider-h w-100 g-mb-20 g-lg-mb-30" />
      <div className="row">
        <div className="col-md-3 g-mb-30">
          <MarketSelectDropdown market={market} validMarkets={['sgx', 'bursa']} />
        </div>
        <div className="col-md-9 g-mb-30">
          <div className="float-md-end">
            <button type="button" onClick={ipoHandler} className="btn btn-light">
              <i className="fa-thin fa-chart-mixed g-mr-5" /> IPO Lodgements
            </button>
            &nbsp;&nbsp;&nbsp;
            {selectedMarket === 'sgx' && (
              <button type="button" onClick={catalistHandler} className="btn btn-light">
                <i className="fa-thin fa-chart-line g-mr-5" /> Catalist IPO
              </button>
            )}
          </div>
        </div>
      </div>

      {data?.ipo_open?.length > 0 && <IpoOpen data={data.ipo_open} />}
      {data?.recent_ipo?.length > 0 && <RecentIpo recentIPO={data.recent_ipo} />}
    </div>
  );
}

export default IpoMain;

IpoMain.propTypes = {
  market: PropTypes.string,
};
