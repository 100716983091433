import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';

import Loader from '../../Loader';
import APIErrorHandler from '../../APIErrorHandler';

import NewsItem from '../NewsItem';
import {
  fetchData,
  saveCurrentPage,
  saveScrollStateAndSelectedNews,
} from './newsInfiniteScrollSlice';

const { CancelToken } = axios;

function NewsInfiniteScroll({ delay, newsPerLoad, newsConfig, basePath, viewMode }) {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.newsInfiniteScroll.data);
  const loading = useSelector((state) => state.newsInfiniteScroll.loading);
  const nextPageLoading = useSelector((state) => state.newsInfiniteScroll.nextPageLoading);
  const isLast = useSelector((state) => state.newsInfiniteScroll.isLast);
  const error = useSelector((state) => state.newsInfiniteScroll.err);
  const storedScrollPosition = useSelector((state) => state.newsInfiniteScroll.scrollState);
  const bottom = useRef(null);
  const observer = useRef(null);
  const defaultPage = useSelector((state) => state.newsInfiniteScroll.page);
  const [page, setPage] = useState(defaultPage);
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    // stop loading this fetchData if storedScrollPosition exist (usually means it is cliked `back` from newsDetail page)
    if (storedScrollPosition) {
      return;
    }

    const source = CancelToken.source();

    dispatch(
      fetchData({
        market: newsConfig.market,
        counter: newsConfig.counter,
        newsType: newsConfig.newsType,
        fromDate: newsConfig.fromDate,
        toDate: newsConfig.toDate,
        category: newsConfig.category,
        origin: newsConfig.origin,
        folderId: newsConfig.folderId,
        cancelToken: source.token,
        page: 1,
        newsPerPage: newsPerLoad,
      }),
    );

    setPage(1);
    setShowLoading(false);

    return () => {
      source.cancel('Component unmounted or tab changed');
    };
  }, [
    newsConfig.market,
    newsConfig.newsType,
    newsConfig.counter,
    newsConfig.fromDate,
    newsConfig.toDate,
    newsConfig.category,
  ]);

  const fetchMoreNews = debounce(async (source) => {
    setShowLoading(true);

    await dispatch(
      fetchData({
        market: newsConfig.market,
        counter: newsConfig.counter,
        newsType: newsConfig.newsType,
        fromDate: newsConfig.fromDate,
        toDate: newsConfig.toDate,
        category: newsConfig.category,
        origin: newsConfig.origin,
        folderId: newsConfig.folderId,
        cancelToken: source.token,
        page: page + 1,
        newsPerPage: newsPerLoad,
        isNextPage: true,
      }),
    );
    setPage(page + 1);
    dispatch(saveCurrentPage({ page: page + 1 }));
    setShowLoading(false);
  }, delay);

  const handleClickNews = () => {
    dispatch(saveScrollStateAndSelectedNews({ scrollState: window.pageYOffset, newsID: news.nid }));
  };

  useEffect(() => {
    const source = CancelToken.source();

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && showLoading === false && !isLast) {
        fetchMoreNews(source);
      }
    });

    if (bottom.current) {
      observer.current.observe(bottom.current);
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
      source.cancel('Component unmounted or tab changed');
    };
  }, [
    loading,
    page,
    newsConfig.market,
    newsConfig.newsType,
    newsConfig.counter,
    newsConfig.fromDate,
    newsConfig.toDate,
    newsConfig.category,
  ]);

  useEffect(() => {
    if (storedScrollPosition) {
      window.scrollTo(0, storedScrollPosition);
    }
  }, []);

  if (error) {
    return <APIErrorHandler error={error} />;
  }

  return (
    <>
      <Loader visible={loading} classes="g-height-300" />
      {!loading && (!data || data.length === 0) && <p>No news found</p>}
      {!loading &&
        data &&
        data.length > 0 &&
        data.map((news) => {
          return (
            <NewsItem
              key={`${news.stock_id}_${news.nid}`}
              news={news}
              basePath={basePath}
              viewMode={viewMode}
              handleClickNews={handleClickNews}
            />
          );
        })}
      {!loading && data && data.length > 0 && <Loader visible={nextPageLoading} />}
      {!loading && data && data.length > 0 && <div ref={bottom} />}
    </>
  );
}

NewsInfiniteScroll.propTypes = {
  delay: PropTypes.number,
  newsPerLoad: PropTypes.number,
  newsConfig: PropTypes.shape({
    counter: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    newsType: PropTypes.string,
    fromDate: PropTypes.string,
    toDate: PropTypes.string,
    category: PropTypes.string,
    origin: PropTypes.string,
    folderId: PropTypes.number,
  }),
  basePath: PropTypes.string,
  viewMode: PropTypes.string,
};

export default NewsInfiniteScroll;
