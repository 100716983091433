import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

import api from '../../utils/api';
import styles from './stocksHighlight.module.css';
import Loader from '../../components/Loader';
import APIErrorHandler from '../../components/APIErrorHandler';
import CompanyLogoImage from '../../components/CompanyLogoImage';

const { CancelToken } = axios;

function StocksHighlight({ country }) {
  const [isSSR, setIsSSR] = useState(true);
  const source = CancelToken.source();
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    setIsSSR(typeof document === 'undefined');
    const cancelToken = source.token;
    setIsLoading(true);

    api
      .get(`prices/home_highlight_stocks.json?country=${country}`, {
        cancelToken,
      })
      .then(({ data }) => {
        setData(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setError(err);
        setIsLoading(false);
      });
  }, []);

  if (isSSR || isLoading) {
    return <Loader visible={isSSR || isLoading} className="g-height-50" />;
  }

  if (error) {
    return <APIErrorHandler error={error} />;
  }

  return (
    <div id="homeStocksHighlight">
      {data && data.stocks && data.stocks.length > 0 && (
        <div className="d-flex flex-wrap gap-3">
          {data.stocks.map((stock) => {
            return (
              stock.success && (
                <div key={stock.symbol} className="g-width-185">
                  <a href={`/quote/${stock.counter}`} className={styles.card}>
                    <div className="d-flex">
                      <div className="g-width-45 g-height-45 g-pt-6 g-pr-6 g-pb-6 g-pl-6">
                        <CompanyLogoImage
                          counter={`${stock.symbol}.${stock.market_suffix}`}
                          classes="g-border-radius-5"
                        />
                      </div>
                      <div className="g-ml-4">
                        <div className="lh-sm">
                          <strong className="g-text-size-14">
                            {stock.counter === 'HKEX:700' ? 'TENCENT' : stock.name}
                          </strong>
                          <span className="g-text-size-13 g-ml-5">{stock.symbol}</span>
                        </div>
                        <div className="lh-sm">
                          <span className="g-text-size-14">{stock.last_done_price}</span>
                          <span className="g-text-size-13 g-ml-5">{stock.currency}</span>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              )
            );
          })}
        </div>
      )}
    </div>
  );
}

StocksHighlight.propTypes = {
  country: PropTypes.string,
};

export default StocksHighlight;
