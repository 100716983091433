import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  compareObjectsByNumberAttr,
  compareObjectsByStringAttr,
  formatDecimal,
} from '../../../../utils/utils';
import { createLoadableComponent } from '../../../../utils/Loadable';

const AllocationPieChart = createLoadableComponent(() =>
  import('../../../../components/AllocationPieChart'),
);

function extractAssetAllocationData(counters) {
  if (!(counters?.length > 0)) {
    return [];
  }

  const totalValue = counters.reduce((accumulator, c) => {
    return accumulator + c.value;
  }, 0);

  const allocationMap = counters.reduce(
    (accumulator, c) => {
      if (!Object.prototype.hasOwnProperty.call(accumulator, c.assetClass)) {
        accumulator[c.assetClass] = { value: 0, percentage: 0, breakdown: '' };
      }
      const counterPercentage = 100 * (c.value / totalValue);
      accumulator[c.assetClass].breakdown += `- <strong> ${c.stockName}</strong>: ${formatDecimal(
        counterPercentage,
        2,
        true,
      )}%\n<br>`;
      accumulator[c.assetClass].value += c.value;
      accumulator[c.assetClass].percentage = 100 * (accumulator[c.assetClass].value / totalValue);

      return accumulator;
    },
    {}, // start with empty map
  );

  return Object.keys(allocationMap)
    .map((assetClass) => {
      return {
        name: assetClass,
        value: Number(formatDecimal(allocationMap[assetClass].value, 3)),
        percentage: Number(formatDecimal(allocationMap[assetClass].percentage, 2)),
        breakdown: allocationMap[assetClass].breakdown,
      };
    })
    .sort((a, b) => compareObjectsByNumberAttr(a, b, 'value', true));
}

export default function AssetClass() {
  const selectedPortfolio = useSelector((state) => state.portfolio.selected);
  const [currentCounters, setCurrentCounters] = useState([]);
  const [allocationData, setAllocationData] = useState([]);

  useEffect(() => {
    if (selectedPortfolio?.counters?.length > 0) {
      // only get from open positions
      const counters = selectedPortfolio.counters
        .filter((c) => c['Shares Held'] && c['Shares Held'] !== '-')
        .map((c) => {
          const assetClass = c.asset_category || 'Others';
          const value = parseFloat(c['Proceeds, Unrealized']?.replaceAll(',', '')) || 0;
          return { counter: c.counter, stockName: c.Name, value, assetClass };
        })
        .sort((a, b) => compareObjectsByStringAttr(a, b, 'counter'));
      if (JSON.stringify(counters) !== JSON.stringify(currentCounters)) {
        setCurrentCounters(counters);
      }
    } else {
      setCurrentCounters([]);
    }
  }, [selectedPortfolio]);

  useEffect(() => {
    setAllocationData(extractAssetAllocationData(currentCounters));
  }, [currentCounters]);

  return (
    <>
      {allocationData.length < 1 && (
        <div className="text-center">No allocation data available.</div>
      )}
      {allocationData.length > 0 && (
        <div className="g-mt-15 g-mb-15 g-height-450">
          <AllocationPieChart data={allocationData} percentInnerRadius={55} percentHeight={95} />
        </div>
      )}
    </>
  );
}
