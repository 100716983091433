import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import CorporateActionTransactionModal from './CorporateActionTransactionModal';

function CorporateAction({ label, loading }) {
  const [showModal, setShowModal] = useState({ show: false, type: '' });

  const handleDeposit = () => {
    setShowModal({ show: true, type: 'corporateAction' });
  };

  return (
    <>
      <Button
        className="btn btn-lg btn-light"
        variant="light"
        disabled={loading}
        onClick={handleDeposit}>
        <i className="fa-light fa-display-chart-up-circle-dollar" /> {label}
      </Button>
      <CorporateActionTransactionModal show={showModal} setShow={setShowModal} />
    </>
  );
}

CorporateAction.propTypes = {
  label: PropTypes.string,
  loading: PropTypes.bool,
};

export default CorporateAction;
