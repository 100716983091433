import { DataSubscription, BaseTable, pushPage } from "./protocol";
import { VisualTable } from "./tables";
import { resetModeToStreaming } from ".";
import { isNegative, isPositive } from '../utils';

const update_index_interval = 15;
let is_bursa_realtime = 'false';
// indices tables
let world_indices_streaming_tables = [];
let isAlreadyStreamingIndicesPreview = false;
// indices mini charts
let indexChartsMap = {
  STI: { code: "STI.SI", last_generated_at: null },
  KLCI: { code: "0200I.MY", last_generated_at: null },
  HSI: { code: "HSI.WLD", last_generated_at: null },
  SSE: { code: "000001.WLD", last_generated_at: null },
  N225: { code: "N225.WLD", last_generated_at: null },
  JCI: { code: "COMPOSITE.WLD", last_generated_at: null },
  SET: { code: "SET.BK", last_generated_at: null },
  KOSPI: { code: "KOSPI.WLD", last_generated_at: null },
};

export const startWldIndicesPricesStreaming = function() {
  const indices_list_obj = document.getElementById('sic_indexList');
  if (!indices_list_obj || isAlreadyStreamingIndicesPreview) { return };
  if (!pushPage) { return };

  is_bursa_realtime = pushPage.hasMarketRealtimeStreamingAccess('bursa');

  // ensure pushPage will be in streaming mode
  resetModeToStreaming();

  const indexSTITable = new BaseTable("sti_info", new DataSubscription("Counter", "STI.SI", []));
  indexSTITable.onDataUpdate = function(updateInfo)
  {
    onIndexUpdated("STI", updateInfo);
  };
  world_indices_streaming_tables.push(indexSTITable);

  const indexKLCITable = new BaseTable("klci_info", new DataSubscription("Counter", "0200I.MY", []));
  indexKLCITable.onDataUpdate = function(updateInfo)
  {
    onIndexUpdated("KLCI", updateInfo);
  };
  world_indices_streaming_tables.push(indexKLCITable);

  const indexHSITable = new BaseTable("hsi_info", new DataSubscription("Counter", "HSI.WLD", []));
  indexHSITable.onDataUpdate = function(updateInfo)
  {
    onIndexUpdated("HSI", updateInfo);
  };
  world_indices_streaming_tables.push(indexHSITable);

  const indexSSETable = new BaseTable("sse_info", new DataSubscription("Counter", "000001.WLD", []));
  indexSSETable.onDataUpdate = function(updateInfo)
  {
    onIndexUpdated("SSE", updateInfo);
  };
  world_indices_streaming_tables.push(indexSSETable);

  const indexN225Table = new BaseTable("n225_info", new DataSubscription("Counter", "N225.WLD", []));
  indexN225Table.onDataUpdate = function(updateInfo)
  {
    onIndexUpdated("N225", updateInfo);
  };
  world_indices_streaming_tables.push(indexN225Table);

  const indexJCITable = new BaseTable("jci_info", new DataSubscription("Counter", "COMPOSITE.WLD", []));
  indexJCITable.onDataUpdate = function(updateInfo)
  {
    onIndexUpdated("JCI", updateInfo);
  };
  world_indices_streaming_tables.push(indexJCITable);

  const indexSETTable = new BaseTable("set_info", new DataSubscription("Counter", "SET.BK", []));
  indexSETTable.onDataUpdate = function(updateInfo)
  {
    onIndexUpdated("SET", updateInfo);
  };
  world_indices_streaming_tables.push(indexSETTable);

  const indexKOSPITable = new BaseTable("kospi_info", new DataSubscription("Counter", "KOSPI.WLD", []));
  indexKOSPITable.onDataUpdate = function(updateInfo)
  {
    onIndexUpdated("KOSPI", updateInfo);
  };
  world_indices_streaming_tables.push(indexKOSPITable);

  /// ///////////////////////////////////////////////////////////////////
  for (let i = 0; i < world_indices_streaming_tables.length; i++) {
    pushPage.scheduleTable(world_indices_streaming_tables[i]); // schedule table for streaming
  }

  pushPage.createEngine(); // this does nothing if pushPage engine is already running
  isAlreadyStreamingIndicesPreview = true;
}

export const stopWldIndicesPricesStreaming = function() {
  if (!isAlreadyStreamingIndicesPreview)  { return };
  // cancel streaming for subscribed tables
  pushPage.cancelTables(world_indices_streaming_tables);
  isAlreadyStreamingIndicesPreview = false;
  // cleanup
  for (let i = 0; i < world_indices_streaming_tables.length; i++) {
    world_indices_streaming_tables[i] = null;
  }
  world_indices_streaming_tables = [];
}

function updateIndexChart(index_label_)
{
  if (!Object.prototype.hasOwnProperty.call(indexChartsMap, index_label_)) {
    return;
  }

  const indexChart = indexChartsMap[index_label_];
  const utcTimeNowInMilliseconds = Date.now();
  const utcTimeNowInSeconds = Math.floor(utcTimeNowInMilliseconds / 1000);

  if (indexChart.last_generated_at &&
    (indexChart.last_generated_at + update_index_interval) > utcTimeNowInSeconds) {
    return; // only regenerate chart 15-seconds after the last one
  }
  indexChart.last_generated_at = utcTimeNowInSeconds;

  const indexChartImgSource = "//chart.shareinvestor.com/charts_cached/charts.pl?type=si_index_mini_plain";
  let indexChartURL = `${indexChartImgSource}&id=${indexChart.code}&ts=${utcTimeNowInMilliseconds}_${Math.floor(Math.random())}`;

  if (is_bursa_realtime == 'false' && index_label_ == "KLCI"){
    indexChartURL =  indexChartURL + "&bs_delay=1"
  }

  const indexChartImgs = document.querySelectorAll(`[id=sic_${ index_label_ }_indexChart]`);

  if (window.document.documentMode) { // only has value in IE
    for (let i = 0; i < indexChartImgs.length; i++) {
      const img = indexChartImgs[i];
      img.setAttribute('src', indexChartURL);
    }
    window.setTimeout(function() {
      for (let i = 0; i < indexChartImgs.length; i++) {
        const img = indexChartImgs[i];
        img.setAttribute('src', indexChartURL);
      }
    }, 1000);
  }
  else {
    imagePreLoader.init();
    imagePreLoader.add(indexChartURL);
    imagePreLoader.onFinish(function() {
      for (let i = 0; i < indexChartImgs.length; i++) {
        const img = indexChartImgs[i];
        img.setAttribute('src', indexChartURL);
      }
    });
    imagePreLoader.load();
  }
}

function onIndexUpdated(index_label_, update_)
{
  if ('price' in update_)
  {
    const lastDoneCells = document.querySelectorAll(`[id=sic_${ index_label_ }_lastDone]`);
    for (let i = 0; i < lastDoneCells.length; i++) {
      const last_done_cell = lastDoneCells[i];
      last_done_cell.innerHTML = formatNumberAddCommas(formatDecimal(update_.price, 1, true));
      const update = $.extend({}, update_, {
        oldValue: last_done_cell.dataset.rawValue,
        newValue: update_.price
      });
      applyDynamicUpdateCellEffect(update, last_done_cell);
      last_done_cell.dataset.rawValue = update_.price;// store raw value
    }
  }

  if ('change' in update_)
  {
    const change_val = parseFloat(update_.change?.replaceAll(',', '')) || 0;
    const changeCells = document.querySelectorAll(`[id=sic_${ index_label_ }_change]`);
    for (let i = 0; i < changeCells.length; i++) {
      const change_cell = changeCells[i];
      change_cell.classList.remove("text-success");
      change_cell.classList.remove("text-danger");

      if (change_val > 0) {
        change_cell.classList.add("text-success");
        change_cell.querySelector(`[id=sic_${  index_label_  }_changeValue]`).innerHTML =
        `+${ formatDecimal(change_val, 1, true) }`;
      }
      else if (change_val < 0) {
        change_cell.classList.add("text-danger");
        change_cell.querySelector(`[id=sic_${  index_label_  }_changeValue]`).innerHTML =
        `${ formatDecimal(change_val, 1, true) }`;
      } else {
        change_cell.querySelector(`[id=sic_${  index_label_  }_changeValue]`).innerHTML = '-';
      }

      // change_cell[0] is the DOM element of the jQuery Object
      const update = $.extend({}, update_, {
        oldValue: change_cell.dataset.rawValue,
        newValue: update_.change
      });

      applyDynamicUpdateCellEffect(update, change_cell);
      change_cell.dataset.rawValue = update_.change;// store raw value
    }
  }

  if ('perc_change' in update_)
  {
    const perc_change_val = parseFloat(update_.perc_change?.replaceAll(',', '')) || 0;
    let perc_change_str = '';
    if (perc_change_val < 0) {
      perc_change_str = `(${ formatDecimal(perc_change_val, 2, true) }%)`;
    } else if (perc_change_val > 0) {
      perc_change_str = `(+${ formatDecimal(perc_change_val, 2, true) }%)`;
    } else {
      perc_change_str = isNegative(update_.change) ? '(-0.00%)' : isPositive(update_.change) ? '(+0.00%)' : '';
    }

    const percChangeCells = document.querySelectorAll(`[id=sic_${ index_label_ }_percentageChange]`);
    for (let i = 0; i < percChangeCells.length; i++) {
      percChangeCells[i].innerHTML = perc_change_str;
    }
  }

  updateIndexChart(index_label_);
}

function getSideBarDynamicUpdateEffect(oldValue, newValue)
{
  let cls = null;
  if (oldValue && newValue && oldValue != "" && newValue != "")
  {
    const oldf = Number(oldValue);
    const newf = Number(newValue);
    if (oldf > newf)
      cls = "sic_sidebar_downBackground";
    else if (oldf < newf)
      cls = "sic_sidebar_upBackground";
  }
  return cls;
}

function applyDynamicUpdateCellEffect(update_, cell_){
  update_.dynamicEffect = getSideBarDynamicUpdateEffect(update_.oldValue, update_.newValue);
  setTimeout(function(){
    VisualTable.applyDynamicEffect(cell_, update_.dynamicEffect, update_.dynamicEffectPeriod || 2000);
  }, update_.delay || 0);
}
