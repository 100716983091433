import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Select from 'react-select';
function FoldersDropdown({ folders, type, selectedOption, onDropdownChange }) {
  const [selectOptions, setSelectOptions] = useState([]);

  function truncate(str, n) {
    return str.length > n ? str.slice(0, n - 1) + '...' : str;
  }

  useEffect(() => {
    if (folders.length > 0) {
      setSelectOptions(
        folders.map((folder) => {
          return { value: folder[0], label: truncate(folder[1], 15) };
        }),
      );
    } else {
      setSelectOptions([]);
    }
  }, [folders]);

  return (
    <>
      {selectOptions.length > 0 && (
        <Select
          unstyled
          isClearable
          backspaceRemovesValue
          className={`react-select-container d-inline-block g-width-200`}
          classNamePrefix="react-select"
          placeholder={`Screen by ${type}`}
          onChange={(selectedOption) => onDropdownChange(type, selectedOption)}
          options={selectOptions}
          value={selectedOption}
        />
      )}
    </>
  );
}

FoldersDropdown.propTypes = {
  folders: PropTypes.oneOfType([PropTypes.array]),
  type: PropTypes.string,
};

export default FoldersDropdown;
