/* Constants.js */
export const Constants = Object.freeze({
  /* Fetch status */
  STATUS_IDLE: 'idle',
  STATUS_LOADING: 'loading',
  STATUS_SUCCESS: 'success',
  STATUS_FAILED: 'failed',
});

export const COUNT_PER_PAGE = 50;
export const BROKERS_CALL_BASE_URL = 'https://brokerscall.shareinvestor.com/brokers_call/www/';
