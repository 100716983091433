import { createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';

const predefinedTAScreensSlice = createSlice({
  name: 'predefinedTAScreens',
  initialState: {
    data: {},
    loading: true,
    stockDataLoading: true,
    scannerResultLoading: true,
    err: null,
    screenStockData: {},
    screenScannerResult: {},
  },
  reducers: {
    predefinedTAScreensFetchDataStart(state, action) {
      state.data = null;
      state.err = null;
      state.loading = true;
    },
    predefinedTAScreensFetchDataSuccess(state, action) {
      state.data = action.payload.data;
      state.err = action.payload.data.success ? null : { message: action.payload.data.message };
      state.loading = false;
    },
    predefinedTAScreensFetchDataFail(state, action) {
      state.err = action.payload;
      state.loading = false;
    },
    predefinedTAScreensFetchStockDataStart(state, action) {
      state.screenStockData = null;
      state.err = null;
      state.stockDataLoading = true;
    },
    predefinedTAScreensFetchStockDataSuccess(state, action) {
      state.screenStockData = action.payload.data;
      state.err = null;
      state.stockDataLoading = false;
    },
    predefinedTAScreensFetchStockDataFail(state, action) {
      state.err = action.payload;
      state.stockDataLoading = false;
    },
    predefinedTAScreensFetchScannerResultStart(state, action) {
      state.screenScannerResult = null;
      state.err = null;
      state.scannerResultLoading = true;
    },
    predefinedTAScreensFetchScannerResultSuccess(state, action) {
      state.screenScannerResult = action.payload.data;
      state.err = null;
      state.scannerResultLoading = false;
    },
    predefinedTAScreensFetchScannerResultFail(state, action) {
      state.err = action.payload;
      state.scannerResultLoading = false;
    },
  },
});

export default predefinedTAScreensSlice.reducer;

const {
  predefinedTAScreensFetchDataStart,
  predefinedTAScreensFetchDataSuccess,
  predefinedTAScreensFetchDataFail,
  predefinedTAScreensFetchStockDataStart,
  predefinedTAScreensFetchStockDataSuccess,
  predefinedTAScreensFetchStockDataFail,
  predefinedTAScreensFetchScannerResultStart,
  predefinedTAScreensFetchScannerResultSuccess,
  predefinedTAScreensFetchScannerResultFail,
} = predefinedTAScreensSlice.actions;

export const fetchData =
  ({ market, cancelToken }) =>
  (dispatch) => {
    const url = `/screener/predefined_ta_screens.json?market=${market}`;
    dispatch(predefinedTAScreensFetchDataStart());
    api
      .get(url, { cancelToken })
      .then(({ data }) => {
        dispatch(predefinedTAScreensFetchDataSuccess({ data }));
      })
      .catch((error) => {
        dispatch(predefinedTAScreensFetchDataFail(error));
      });
  };

export const fetchScreenStockData =
  ({ counter, cancelToken }) =>
  (dispatch) => {
    const url = `/screener/predefined_ta_screens_stock.json?counter=${counter}`;
    dispatch(predefinedTAScreensFetchStockDataStart());
    api
      .get(url, { cancelToken })
      .then(({ data }) => {
        dispatch(predefinedTAScreensFetchStockDataSuccess({ data }));
      })
      .catch((error) => {
        dispatch(predefinedTAScreensFetchStockDataFail(error));
      });
  };

export const fetchScreenScannerResult =
  ({ scannerID, market, cancelToken }) =>
  (dispatch) => {
    const url = `/screener/predefined_ta_screens_results.json?id=${scannerID}&market=${market}`;
    dispatch(predefinedTAScreensFetchScannerResultStart());
    api
      .get(url, { cancelToken })
      .then(({ data }) => {
        dispatch(predefinedTAScreensFetchScannerResultSuccess({ data }));
      })
      .catch((error) => {
        dispatch(predefinedTAScreensFetchScannerResultFail(error));
      });
  };
