export const serializeForm = (form) => {
  const formData = {};
  const formElements = form.elements;

  for (let i = 0; i < formElements.length; i++) {
    const field = formElements[i];
    if (field.name) {
      if (field.type === 'checkbox') {
        formData[field.name] = field.checked ? field.value : '';
      } else {
        formData[field.name] = field.value;
      }
    }
  }

  return formData;
};

export const constructXMLNode = (params_hash, criteria_config) => {
  const criteria_key = params_hash.criteria;
  let xml_expression_str = '';
  if (criteria_key.match(/^ta/)) {
    xml_expression_str = constructTaXML(params_hash, criteria_config);
  } else {
    xml_expression_str = constructFaXML(params_hash, criteria_config);
  }
  return xml_expression_str;
};

export const constructTaXML = (params_hash, criteria_config) => {
  const criteria_key = params_hash.criteria;
  const xml_expression_tmpl = params_hash.xml_tmpl;
  const xml_expression_str = xml_expression_tmpl;
  return xml_expression_str;
};

export const constructFaXML = (params_hash, criteria_config) => {
  const criteria_key = params_hash.criteria;
  let xml_expression_str = '';
  let criteria_key_xml = criteria_key;
  let sing_val_xml = params_hash.val;
  let gteq_val_xml = params_hash.GTEQ;
  let lteq_val_xml = params_hash.LTEQ;
  let { xml_unit } = criteria_config[criteria_key];

  if (params_hash.input_val_perc == 'true') {
    sing_val_xml *= 0.01;
    gteq_val_xml *= 0.01;
    lteq_val_xml *= 0.01;
  }

  if (criteria_key.match(/faPriceTo\d{2}Wks(High|Low)/)) {
    let operator = '';
    let val;
    if (params_hash.operator == 'GTEQ') {
      operator = 'GTEQ';
      val = params_hash.GTEQ;
    } else if (params_hash.operator == 'LTEQ') {
      operator = 'LTEQ';
      val = params_hash.LTEQ;
    } else if (params_hash.GTEQ) {
      operator = 'GTEQ';
      val = params_hash.GTEQ;
    } else {
      operator = 'LTEQ';
      val = params_hash.LTEQ;
    }

    xml_expression_str =
      `<${operator}><Field>${criteria_key_xml}</Field>` +
      `<${xml_unit}>${val}</${xml_unit}>` +
      `</${operator}>`;
    return xml_expression_str;
  }

  if (criteria_key.match(/groupStockStatusShariah/)) {
    // <EQ><Field>groupStockStatusShariah</Field><StrVal>shariah</StrVal></EQ>
    xml_expression_str = `<${params_hash.operator}><Field>groupStockStatusShariah</Field><StrVal>${params_hash.val}</StrVal></${params_hash.operator}>`;
    return xml_expression_str;
  }

  if (criteria_key.match(/groupStockStatus/)) {
    // <EQ><Field>groupStockStatus</Field><StrVal>cpfis</StrVal></EQ>
    xml_expression_str = `<${params_hash.operator}><Field>groupStockStatus</Field><StrVal>${params_hash.val}</StrVal></${params_hash.operator}>`;
    return xml_expression_str;
  }

  if (criteria_key.match(/groupComponentCategory/)) {
    // <EQ><Field>filterGeneral</Field><StrVal>CC02</StrVal></EQ>
    xml_expression_str = `<EQ><Field>filterGeneral</Field><StrVal>${params_hash.val}</StrVal></EQ>`;
    return xml_expression_str;
  }

  // Total Shareholders return for 3Y and 5Y.
  if (criteria_key.match(/faTotalShareholderReturns/)) {
    xml_expression_str = `<${params_hash.operator}><Field>${criteria_key}${params_hash.year}Y</Field><DblVal>${sing_val_xml}</DblVal></${params_hash.operator}>`;
    return xml_expression_str;
  }
  // cagr (3, 5 yrs)
  // <GTEQ><Field>faCAGRRevenue</Field><DblVal>2</DblVal></GTEQ>
  if (criteria_key.match(/CAGR/)) {
    xml_expression_str = `<${params_hash.operator}><Field>${criteria_key}${params_hash.year}Y</Field><DblVal>${sing_val_xml}</DblVal></${params_hash.operator}>`;
    return xml_expression_str;
  }

  if (parseInt(params_hash.year) > 0 || criteria_key.match(/^value/)) {
    if (criteria_key.match(/^fa((?!CAGR).)*$/))
      criteria_key_xml = criteria_config[criteria_key].key_periods_value;
    sing_val_xml = `${params_hash.year}:${sing_val_xml}`;
    gteq_val_xml = `${params_hash.year}:${gteq_val_xml}`;
    lteq_val_xml = `${params_hash.year}:${lteq_val_xml}`;
    xml_unit = 'StrVal';
  }

  if (params_hash.operator == 'BT') {
    // <AND>
    // <GTEQ><Field>faPE</Field><IntVal>2</IntVal></GTEQ>
    // <LTEQ><Field>faPE</Field><IntVal>50</IntVal></LTEQ>
    // </AND>
    xml_expression_str =
      `<AND>` +
      `<GTEQ><Field>${criteria_key_xml}</Field>` +
      `<${xml_unit}>${gteq_val_xml}</${xml_unit}>` +
      `</GTEQ>` +
      `<LTEQ><Field>${criteria_key_xml}</Field>` +
      `<${xml_unit}>${lteq_val_xml}</${xml_unit}>` +
      `</LTEQ>` +
      `</AND>`;
  } else if (params_hash.operator == 'NO') {
    // TODO: xml for no dividend paid
    let val_ = '';
    if (parseInt(params_hash.year) > 0) {
      val_ = `${params_hash.year}:0`;
    } else {
      val_ = 0;
    }
    xml_expression_str =
      `<EQ>` +
      `<Field>${criteria_key_xml}</Field>` +
      `<${xml_unit}>${val_}</${xml_unit}>` +
      `</EQ>`;
  } else {
    // <GTEQ><Field>faDivYield</Field><DblVal>1</DblVal></GTEQ>
    xml_expression_str =
      `<${params_hash.operator}>` +
      `<Field>${criteria_key_xml}</Field>` +
      `<${xml_unit}>${sing_val_xml}</${xml_unit}>` +
      `</${params_hash.operator}>`;
  }
  return xml_expression_str;
};

export const buildTemplateString = (and_groups) => {
  const and_xml_nodes = [];

  and_groups.forEach((group) => {
    const params_forms = group.querySelectorAll('form');
    const or_xml_nodes = [];
    const isDisabled = group.closest('#form_selected_criteria').classList.contains('disabled');
    if (isDisabled) {
      or_xml_nodes.push({ disabled: true });
    }
    params_forms.forEach((params_form) => {
      const formData = new FormData(params_form);
      const params = Array.from(formData.entries());
      const params_hash = {};
      let params_bt = false;
      let params_perc = false;
      let params_highlow = false;

      params.forEach(([name, value]) => {
        if (name === 'operator' && value === 'BT') {
          params_bt = true;
        }
        if (name === 'operator' && /(GTEQ|LTEQ)_SELECT/.test(value)) {
          params_highlow = true;
        }
        if (name === 'input_val_perc' && value === 'true') {
          params_perc = true;
        }
      });

      params.forEach(([name, value]) => {
        switch (name) {
          case 'criteria':
            params_hash.key = value;
            break;
          case 'operator':
          case 'year':
          case 'W':
          case 'X':
          case 'Y':
          case 'Z':
          case 'MA1':
          case 'MA2':
            params_hash[name] = value;
            break;
          case 'val':
            if (params_perc) {
              value /= 100;
            }
            params_hash[name] = value;
            break;
          case 'GTEQ':
          case 'LTEQ':
            if (params_bt || params_highlow) {
              if (params_perc) {
                value /= 100;
              }
              params_hash[name] = value;
            }
            break;
          default:
            // Handle default case if needed
            break;
        }
      });

      or_xml_nodes.push(params_hash);
    });

    and_xml_nodes.push(or_xml_nodes);
  });

  const tmpl_str = JSON.stringify(and_xml_nodes);
  return tmpl_str;
};

// Reformat some criterias that is in percentage format, eg: 0.03 change to 3.
export const convertPercValue = (field, tmpl) => {
  const perc_criterias = [
    'tmpl_perc_for_years',
    'tmpl_perc_growth_for_years',
    'tmpl_perc_for_years_div',
    'tmpl_perc_for_years_cagr',
    'tmpl_perc_for_years_returns',
    'tmpl_value_range_perc',
    'tmpl_estimates_perc_for_years',
    'tmpl_estimates_perc_growth_for_years',
  ];

  const isPerc = perc_criterias.includes(tmpl);
  if (isPerc) {
    const copyField = { ...field };
    if (copyField.hasOwnProperty('val')) {
      copyField.val *= 100;
    }
    if (copyField.hasOwnProperty('GTEQ')) {
      copyField.GTEQ *= 100;
    }
    if (copyField.hasOwnProperty('LTEQ')) {
      copyField.LTEQ *= 100;
    }
    return copyField;
  }

  return field;
};

export const getXMLMarket = (market) => {
  let xmlMarket;
  switch (market) {
    case 'sgx':
      xmlMarket = 'SI';
      break;
    case 'bursa':
      xmlMarket = 'MY';
      break;
    case 'bursa_derivative':
      xmlMarket = 'MD';
      break;
    case 'set':
      xmlMarket = 'BK';
      break;
    case 'idx':
      xmlMarket = 'JK';
      break;
    case 'nasdaq':
      xmlMarket = 'NQ';
      break;
    case 'nyse':
      xmlMarket = 'NY';
      break;
    case 'nyse_mkt':
      xmlMarket = 'NM';
      break;
    case 'hkex':
      xmlMarket = 'HK';
      break;
    case 'asx':
      xmlMarket = 'AX';
      break;
    case 'world':
      xmlMarket = 'WLD';
      break;
  }
  return xmlMarket;
};
