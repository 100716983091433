import React from 'react';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

import { useDetectMobileScreen } from '../../../utils/utils';
import classes from '../membership.module.css';

export default function UserReviews() {
  const REVIEWS = [
    {
      reviewerName: 'Thomas Chua',
      reviewerDesignation: 'Author of Dividend-Growth Investing',
      userSince: 'Webpro, User since 2017',
      testimonial:
        'Low-costs platform that allows me to do detail financial analysis on malaysia stocks. It saves alot of time. One of the feature I use the most is the 10 year historical valuation ratios. It has all types of valuation ratio such as dividend yield, PE, price-to-sales, price-to-book, price-to-cashflow, etc. This allows me to perform a quick valuation on any types of companies before deciding to invest.',
    },
    {
      reviewerName: 'Muhamad Firdaus bin Majid ',
      reviewerDesignation: 'Remisier',
      userSince: 'ShareInvestor Station, User since 2018',
      testimonial:
        "As a 📈 Remisier, I've used ShareInvestor Station since 2018. This platform stands out for its remarkable coding feature, allowing traders to tailor their strategies with precision. Its market revscreener is another highlight, efficiently filtering stocks from an extensive database to pinpoint promising opportunities. These tools collectively empower investors with a high degree of customization and insightful market analysis, making ShareInvestor Station a robust choice for serious traders.",
    },
    {
      reviewerName: 'Dato Mohd Nazri Khan Bin Adam',
      reviewerDesignation: 'Chief Economist/Fund Manager, Interpacific Asset Management',
      userSince: 'ShareInvestor Station, User since 2019',
      testimonial:
        "The Shareinvestor Platform is an excellent choice for fundamental stock analysis, offering easy access to crucial financial data and insights on insider trades. It's particularly beneficial for users who value comprehensive yet concise financial information. The platform is also known for hosting informative member events featuring expert speakers on stock market trends. This combination of detailed financial analysis tools and educational resources makes it a cost-effective and valuable tool for investors.",
    },
    {
      reviewerName: 'Datuk Haji Mohd Asri Ahmad',
      reviewerDesignation: 'Master Trainer & Co-Founder Asri Ahmad Academy',
      userSince: 'ShareInvestor Station, User since 2019',
      testimonial:
        "Shareinvestor Station serves as a comprehensive, friendly tool for investors and traders navigating the stock market. Its intuitive interface simplifies complex technical analysis through a variety of screening tools, helping in informed decision-making. The platform's user-friendly design facilitates ease of use, allowing users to filter through stocks based on preferred fundamental and technical indicators, charts and market trends. Shareinvestor Station provides in-depth analysis while remaining accessible to both beginners and seasoned traders. With its user-friendly layout and powerful analytical capabilities in fundamental and technical, it's a valuable asset for anyone seeking efficient and effective stock market analysis. ",
    },
    {
      reviewerName: 'Dan Chang C S',
      reviewerDesignation:
        'Designation: Trading Representative of Phillip Capital, SGX Academy Speaker ',
      userSince: '',
      testimonial:
        'WebPro is one of the most comprehensive system that I have seen for a long time. Be it fundamental data points, charts or even up-to-date news-flow, WebPro have everything! The screener, which can be based on fundamental and technical indicators, are also useful for me to seek out investment opportunities. My go-to platform everyday!',
    },
    {
      reviewerName: 'James Yeo',
      reviewerDesignation: 'Designation: Founder of SmallCapAsia',
      userSince: 'ShareInvestor WebPro, User since 2019',
      testimonial:
        "I've been using ShareInvestor WebPro for my investment research, and it's been a game-changer esp. for Singapore investors. The platform's comprehensive tools and analyst consensus data have helped me make informed decisions, leading to better investment outcomes. Highly recommended for serious investors!",
    },
    {
      reviewerName: 'Peter Loh',
      reviewerDesignation: 'Retail Investor',
      userSince: 'Station User since 2003',
      testimonial:
        'I have been a SI Station member since Dr. Michael Leong started it about 22 years ago.  I found the platform to be very good and is worth spending the subscription money. On a single platform, I can get all the technical tools for stock trading plus the necessary financial information. I dont have to do anything extra. Also, whenever I have technical difficulties, I can always ring up their technical staff for help. I must compliment them for their courteous and speedy assistance. I am a very satisfied customer, otherwise I would not have stayed with them for over 20 years.',
    },
  ];

  return (
    <>
      <div id="userReviews" />
      <div className={`bg-cover text-white g-mt-80 ${classes.userReviews}`}>
        <div className="container text-center g-pt-80 g-pb-80">
          <div className="row">
            <div className="col-12">
              <h2>What our clients say about us</h2>
            </div>
          </div>
          <div className="row g-mt-40 g-mb-40">
            <div className="col-12">
              <ReviewsCarousel reviews={REVIEWS} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function ReviewsCarousel({ reviews }) {
  const isMobile = useDetectMobileScreen();

  const mainOptions = {
    type: 'loop',
    drag: 'free',
    perPage: isMobile ? 1 : 3,
    perMove: isMobile ? 1 : 3,
    autoScroll: {
      speed: 1,
    },
    pagination: false,
    lazyload: 'nearby',
    autoHeight: !isMobile,
  };

  if (!reviews) {
    return null;
  }

  return (
    <Splide
      options={mainOptions}
      aria-labelledby="user-reviews-slider"
      hasTrack={false}
      id="userReviewsSlider">
      <SplideTrack className={isMobile ? 'g-ml-40 g-mr-40' : 'g-ml-50 g-mr-50'}>
        {reviews.map((row, index) => (
          <SplideSlide key={`splide_${index}_${row.reviewerName}`} className="mt-1">
            <Card className={`${classes.userReviewsCarouselItem}`}>
              <Card.Body className="text-center p-4">
                <div className="mb-2">
                  <h5 className="mb-0">{row.reviewerName}</h5>
                  <small className="d-block">{row.reviewerDesignation}</small>
                </div>
                <div className="mb-2 text-muted">{row.userSince}</div>
                <div className={`mb-2 ${classes.userReviewsCarouselItemStar}`}>
                  <i className="fa-solid fa-star g-mr-2" />
                  <i className="fa-solid fa-star g-mr-2" />
                  <i className="fa-solid fa-star g-mr-2" />
                  <i className="fa-solid fa-star g-mr-2" />
                  <i className="fa-solid fa-star g-mr-2" />
                </div>
                <div className="my-4 border-bottom" />
                <p className="mb-2 text-start">{row.testimonial}</p>
              </Card.Body>
            </Card>
          </SplideSlide>
        ))}
      </SplideTrack>
    </Splide>
  );
}

ReviewsCarousel.propTypes = {
  reviews: PropTypes.arrayOf(
    PropTypes.shape({
      reviewerName: PropTypes.string,
      reviewerDesignation: PropTypes.string,
      userSince: PropTypes.string,
      testimonial: PropTypes.string,
    }),
  ),
};
