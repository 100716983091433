import React, { useEffect, useState, useRef } from 'react';

export const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, deps);
};

export const useDetectMobileScreen = (screenMaxWidth = 991) => {
  if (typeof document === 'undefined') {
    return false;
  }

  const [width, setWidth] = useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return width <= screenMaxWidth;
};

export const compareObjectsByNumberAttr = (a, b, attrKey, isReversed = false) => {
  const c1 = a ? Number(a[attrKey]) : undefined;
  const c2 = b ? Number(b[attrKey]) : undefined;
  if (c1 < c2) {
    return isReversed ? 1 : -1;
  }
  if (c1 > c2) {
    return isReversed ? -1 : 1;
  }
  return 0;
};

export const compareObjectsByStringAttr = (a, b, attrKey, isReversed = false) => {
  const c1 = a ? a[attrKey]?.toLowerCase() : undefined;
  const c2 = b ? b[attrKey]?.toLowerCase() : undefined;
  if (c1 < c2) {
    return isReversed ? 1 : -1;
  }
  if (c1 > c2) {
    return isReversed ? -1 : 1;
  }
  return 0;
};

export const getParamCounterId = (counter, idOnly = false) => {
  if (!counter) {
    return null;
  }
  if (/^([A-Za]+\:[A-Za-z0-9]+)$/.test(counter)) {
    return counter; // already in <MKT>:<CODE> format
  }

  const lastIndex = counter.lastIndexOf('.');
  if (lastIndex !== -1) {
    const code = counter.substring(0, lastIndex);
    const market = counter.substring(lastIndex + 1);
    if (idOnly) {
      return code.toUpperCase();
    }
    switch (market.toLowerCase()) {
      case 'si':
        return `SGX:${code.toUpperCase()}`;
      case 'my':
        return `BURSA:${code.toUpperCase()}`;
      case 'md':
        return `BURSAD:${code.toUpperCase()}`;
      case 'hk':
        return `HKEX:${code.toUpperCase()}`;
      case 'bk':
        return `SET:${code.toUpperCase()}`;
      case 'jk':
        return `IDX:${code.toUpperCase()}`;
      case 'ax':
        return `ASX:${code.toUpperCase()}`;
      case 'ny':
        return `NYSE:${code.toUpperCase()}`;
      case 'nq':
        return `NASDAQ:${code.toUpperCase()}`;
      case 'nm':
        return `NYSEMKT:${code.toUpperCase()}`;
      case 'wld':
        return `INDEX:${code.toUpperCase()}`;
      default:
        return `SGX:${code.toUpperCase()}`;
    }
  }

  return counter;
};

export const getSymbolFromCounter = (counter) => {
  if (!counter) {
    return null;
  }

  if (counter.lastIndexOf(':') !== -1) {
    const [market, code] = counter.split(':');
    switch (market) {
      case 'SGX':
        return `${code}.SI`;
      case 'BURSA':
        return `${code}.MY`;
      case 'BURSAD':
        return `${code}.MD`;
      case 'HKEX':
        return `${code}.HK`;
      case 'SET':
        return `${code}.BK`;
      case 'IDX':
        return `${code}.JK`;
      case 'ASX':
        return `${code}.AX`;
      case 'NYSE':
        return `${code}.NY`;
      case 'NASDAQ':
        return `${code}.NQ`;
      case 'NYSEMKT':
        return `${code}.NM`;
      case 'INDEX':
        return `${code}.WLD`;
      default:
        return `${code}.SI`;
    }
  }

  return counter;
};

export const getMarketFromMarketSymbol = (market) => {
  switch (market.toLowerCase()) {
    case 'si':
      return `SGX`;
    case 'my':
      return `BURSA`;
    case 'md':
      return `BURSAD`;
    case 'hk':
      return `HKEX`;
    case 'bk':
      return `SET`;
    case 'jk':
      return `IDX`;
    case ('asx', 'ax'):
      return 'ASX';
    case 'ny':
      return `NYSE`;
    case 'nq':
      return `NASDAQ`;
    case 'nm':
      return `NYSEMKT`;
    case 'wld':
      return `INDEX`;
    default:
      return `SGX`;
  }
};

export const getMarketFromCounter = (counter) => {
  if (!counter) {
    return null;
  }
  if (/^([A-Za-z0-9]+\.[A-Za-z]+)$/.test(counter)) {
    const market = counter.split('.').pop();
    return getMarketFromMarketSymbol(market);
  }
  if (/^([A-Za]+\:[A-Za-z0-9]+)$/.test(counter)) {
    const market = counter.split(':')[0]?.toUpperCase() || 'SGX';
    return market;
  }
  return `SGX`;
};

export const formatNumberAddCommas = (value) => {
  const strval = String(value);
  const x = strval.split('.');
  let x1 = x[0];
  const x2 = x.length > 1 ? `.${x[1]}` : '';
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, `$1,$2`);
  }
  return x1 + x2;
};

export const formatDecimal = (value, decimals, keepZero) => {
  let mul = '1';
  const zero = '0';
  for (let i = decimals; i > 0; i -= 1) {
    mul += zero;
  }
  let val = Math.round(value * mul);
  val /= mul;
  let strVal = String(val);
  if (!keepZero) return strVal;

  let nowDecimals = 0;
  const dot = strVal.indexOf('.');
  if (dot === -1) strVal += '.';
  else nowDecimals = strVal.length - dot - 1;

  for (let j = nowDecimals; j < decimals; j += 1) {
    strVal += zero;
  }

  if (strVal.slice(-1) == '.') {
    strVal = strVal.substring(0, strVal.length - 1);
  }

  return strVal;
};

export const seriesColors = [
  'red',
  'blueviolet',
  'deepskyblue',
  'hotpink',
  'darkkhaki',
  'steelblue',
  'orangered',
  'mediumvioletred',
  'darkorange',
  'lightcoral',
];
export function formatVolume(value, plusminus = false, precision = 2) {
  if (!value || value === 0) {
    return '-';
  }

  // Format the number based on whether it has a decimal part or not
  const formattedNumber = formatDecimal(value, precision, false);

  // Conditionally add a plus sign based on the plusminus prop and the sign of the value
  const sign = Math.sign(value);
  const formattedValue = plusminus && sign !== -1 ? `+${formattedNumber}` : formattedNumber;

  return formatNumberAddCommas(formattedValue);
}

export function formatBuySellPrice(price, volume) {
  return parseFloat(price) === 0 && parseFloat(volume) !== 0 ? 'MO' : price;
}

export function formatTimeAndSalesType(type) {
  switch (type) {
    case 'B':
      return 'Sell Down';
    case 'S':
      return 'Buy Up';
    case 'PC':
      return 'Preclose';
    case 'PO':
      return 'Preopen';
    case 'UN':
      return 'Unknown';
    case 'M':
      return 'Mid';
    case 'AJ':
      return 'Adjust';
    case 'CP':
      return 'Close Price';
    default:
      return type;
  }
}

export function formatPrice(price, precision = 3) {
  if (price === null || price === undefined || price === '') {
    return '-';
  }
  return parseFloat(price).toFixed(precision);
}

export function formatChange(change, precision = 3) {
  if (change === null || parseFloat(change) === 0 || isNaN(parseFloat(change))) {
    return '-';
  }
  return `${parseFloat(change).toFixed(precision) > 0 ? '+' : ''}${parseFloat(change).toFixed(
    precision,
  )}`;
}

export function isNegative(value) {
  const val = parseFloat(value?.replaceAll(',', '')) || 0;
  return val < 0;
}

export function isPositive(value) {
  const val = parseFloat(value?.replaceAll(',', '')) || 0;
  return val > 0;
}

export function isZero(value) {
  const val = parseFloat(value?.replaceAll(',', '')) || 0;
  return val === 0;
}

export function formatValue(value, plusminus = false, precision = 3) {
  if (!value || value === 0) {
    return '-';
  }

  const formattedNumber = formatDecimal(value, precision, true);

  return formatNumberAddCommas(formattedNumber);
}

export function parseFloatOrZero(value) {
  // Remove commas delimited format
  if (typeof value === 'string') {
    value = value.replace(/,/g, '');
  }

  if (isNaN(value) || !isFinite(value)) {
    return 0;
  }
  return parseFloat(value);
}

export function changeTextClass(value, isReversed) {
  const val = parseFloat(value?.replaceAll(',', '')) || 0;
  if (isReversed) {
    return val > 0 ? 'sic_down' : val < 0 ? 'sic_up' : '';
  }
  return val > 0 ? 'sic_up' : val < 0 ? 'sic_down' : '';
}

export const getCsrfToken = () => {
  const param = document.querySelector('meta[name="csrf-param"]');
  const token = document.querySelector('meta[name="csrf-token"]');
  return {
    csrf_param: param?.getAttribute('content'),
    csrf_token: token?.getAttribute('content'),
  };
};

export const isDefined = (type) => {
  return type != 'undefined' && type != 'unknown' && type != undefined;
};
