import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown';
import PropTypes from 'prop-types';
import flagSG from 'images/icon-flag-singapore.svg';
import flagMY from 'images/icon-flag-malaysia.svg';
import flagTH from 'images/icon-flag-thailand.svg';
import flagID from 'images/icon-flag-indonesia.svg';
import flagAU from 'images/icon-flag-australia.svg';
import flagHK from 'images/icon-flag-hongkong.svg';
import flagUS from 'images/icon-flag-us.svg';
import { initSelectedMarket, updateSelectedMarket } from './selectedMarketSlice';

export default function MarketSelectDropdown({ market, validMarkets, variant, disabled }) {
  const validMarketsMap = getMarketsMap(validMarkets);
  let paramMarket = String(market).toLowerCase();

  if (
    !(market && Object.prototype.hasOwnProperty.call(validMarketsMap, String(market).toLowerCase()))
  ) {
    paramMarket = 'sgx';
  }

  const dispatch = useDispatch();
  const isSelectedMarketInitialized = useSelector((state) => state.selectedMarket.isInitialized);
  const selectedMarket = useSelector((state) => state.selectedMarket.selectedMarket);

  if (!isSelectedMarketInitialized) {
    // trigger init on selectedMarket
    dispatch(initSelectedMarket({ market: paramMarket }));
  }

  const onClickHandler = (e) => {
    const newMarketSelected = e.target.getAttribute('market');
    if (!(newMarketSelected && newMarketSelected != selectedMarket)) {
      return;
    }
    // trigger update on selectedMarket
    dispatch(updateSelectedMarket({ market: newMarketSelected }));
  };

  useEffect(() => {
    const paramMarket = String(market).toLowerCase();
    if (paramMarket !== selectedMarket) {
      dispatch(updateSelectedMarket({ market: paramMarket }));
    }
  }, [market]);

  return (
    <Dropdown id="selectMarket">
      <div className="sic_one sic_item">
        <Dropdown.Toggle variant={variant} disabled={disabled} className="g-min-width-150">
          {validMarketsMap[selectedMarket || paramMarket]?.icon}
          <strong
            id="sic_selectedMarket"
            selected_market={selectedMarket || paramMarket}
            className="g-mr-10">
            {validMarketsMap[selectedMarket || paramMarket]?.label}
          </strong>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {Object.entries(validMarketsMap).map(
            ([mktKey, mktData]) =>
              mktKey != selectedMarket && (
                <Dropdown.Item
                  key={`selectMarket_${mktKey}`}
                  market={mktKey}
                  onClick={onClickHandler}>
                  {mktData.label}
                </Dropdown.Item>
              ),
          )}
        </Dropdown.Menu>
      </div>
    </Dropdown>
  );
}

function getMarketsMap(arrMarkets) {
  const allMarketsMap = {
    sgx: {
      label: 'SGX',
      icon: <img src={flagSG} className="icon g-mr-15" alt="SGX" />,
    },
    bursa: {
      label: 'Bursa',
      icon: <img src={flagMY} className="icon g-mr-15" alt="Bursa" />,
    },
    bursa_derivative: {
      label: 'Bursa Derivatives',
      icon: <img src={flagMY} className="icon g-mr-15" alt="Bursa Derivatives" />,
    },
    hkex: {
      label: 'HKEx',
      icon: <img src={flagHK} className="icon g-mr-15" alt="HKEx" />,
    },
    set: {
      label: 'SET',
      icon: <img src={flagTH} className="icon g-mr-15" alt="SET" />,
    },
    idx: {
      label: 'IDX',
      icon: <img src={flagID} className="icon g-mr-15" alt="IDX" />,
    },
    asx: {
      label: 'ASX',
      icon: <img src={flagAU} className="icon g-mr-15" alt="ASX" />,
    },
    nyse: {
      label: 'NYSE',
      icon: <img src={flagUS} className="icon g-mr-15" alt="NYSE" />,
    },
    nasdaq: {
      label: 'NASDAQ',
      icon: <img src={flagUS} className="icon g-mr-15" alt="NASDAQ" />,
    },
    nyse_mkt: {
      label: 'NYSE American',
      icon: <img src={flagUS} className="icon g-mr-15" alt="NYSE American" />,
    },
    world: {
      label: 'World',
      // world icon is font-awesome, however, use <svg> format instead of <i> because <i> will be changed to <svg> automatically during rendering and this can cause errors when switching to other markets
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon g-mr-15"
          height="1em"
          viewBox="0 0 512 512">
          <defs>
            <style>{`.fa-secondary{opacity:0.4;fill:#214a97;}.fa-primary{fill:#ebf2ff;}`}</style>
          </defs>
          <path
            className="fa-primary"
            d="M1.1 280.3l81.7 20.4c8.2 2 16.8-.4 22.8-6.3l14.8-14.8c11.6-11.6 31.1-8.4 38.4 6.2l9.3 18.5c4.8 9.6 14.6 15.7 25.4 15.7c15.2 0 26.1-14.6 21.7-29.2l-6-19.9c-4.6-15.4 6.9-30.9 23-30.9h2.3c13.4 0 25.9-6.7 33.3-17.8l10.7-16.1c5.6-8.5 5.3-19.6-.8-27.7l-16.1-21.5c-10.3-13.7-3.3-33.5 13.4-37.7l17.6-4.4c7.2-1.8 13.1-6.8 16.1-13.5l37.7-84.9C318.3 5.8 287.8 0 256 0C114.6 0 0 114.6 0 256c0 8.2 .4 16.3 1.1 24.3zm500.4 48.3c-.3-.3-.7-.6-1.1-.8l-31.9-23c-5.9-4.3-13.4-5.6-20.5-3.6l-13.5 3.8c-6.7 1.9-13.8-.9-17.5-6.7l-2-3.1c-6-9.4-16.4-15.1-27.6-15.1s-21.6 5.7-27.6 15.1l-6.1 9.5c-1.4 2.2-3.4 4.1-5.7 5.3L312 330.1c-18.1 10.1-25.5 32.4-17 51.3l5.5 12.4c8.6 19.2 30.7 28.5 50.4 21.1l3.7-1.4c9.4-3.5 20-2.4 28.4 3.1l45.1 28.9c34.2-31.1 60-71.4 73.5-116.9zM144.5 348.1c-2.1 8.6 3.1 17.3 11.6 19.4l32 8c8.6 2.1 17.3-3.1 19.4-11.6s-3.1-17.3-11.6-19.4l-32-8c-8.6-2.1-17.3 3.1-19.4 11.6zm92-20c-2.1 8.6 3.1 17.3 11.6 19.4s17.3-3.1 19.4-11.6l8-32c2.1-8.6-3.1-17.3-11.6-19.4s-17.3 3.1-19.4 11.6l-8 32zM343.2 113.7c-7.9-4-17.5-.7-21.5 7.2l-16 32c-4 7.9-.7 17.5 7.2 21.5s17.5 .7 21.5-7.2l16-32c4-7.9 .7-17.5-7.2-21.5z"
          />
          <path
            className="fa-secondary"
            d="M1.1 280.3l81.7 20.4c8.2 2 16.8-.4 22.8-6.3l14.8-14.8c11.6-11.6 31.1-8.4 38.4 6.2l9.3 18.5c4.8 9.6 14.6 15.7 25.4 15.7c15.2 0 26.1-14.6 21.7-29.2l-6-19.9c-4.6-15.4 6.9-30.9 23-30.9h2.3c13.4 0 25.9-6.7 33.3-17.8l10.7-16.1c5.6-8.5 5.3-19.6-.8-27.7l-16.1-21.5c-10.3-13.7-3.3-33.5 13.4-37.7l17.6-4.4c7.2-1.8 13.1-6.8 16.1-13.5l37.7-84.9C443.2 53 512 146.5 512 256c0 25.2-3.6 49.6-10.4 72.6c-.3-.3-.7-.6-1.1-.8l-31.9-23c-5.9-4.3-13.4-5.6-20.5-3.6l-13.5 3.8c-6.7 1.9-13.8-.9-17.5-6.7l-2-3.1c-6-9.4-16.4-15.1-27.6-15.1s-21.6 5.7-27.6 15.1l-6.1 9.5c-1.4 2.2-3.4 4.1-5.7 5.3L312 330.1c-18.1 10.1-25.5 32.4-17 51.3l5.5 12.4c8.6 19.2 30.7 28.5 50.4 21.1l3.7-1.4c9.4-3.5 20-2.4 28.4 3.1l45.1 28.9C382.7 486.8 322.3 512 256 512C122.8 512 13.4 410.3 1.1 280.3zm162.7 56.2c-8.6-2.1-17.3 3.1-19.4 11.6s3.1 17.3 11.6 19.4l32 8c8.6 2.1 17.3-3.1 19.4-11.6s-3.1-17.3-11.6-19.4l-32-8zm84.2 11c8.6 2.1 17.3-3.1 19.4-11.6l8-32c2.1-8.6-3.1-17.3-11.6-19.4s-17.3 3.1-19.4 11.6l-8 32c-2.1 8.6 3.1 17.3 11.6 19.4zM350.3 135.2c4-7.9 .7-17.5-7.2-21.5s-17.5-.7-21.5 7.2l-16 32c-4 7.9-.7 17.5 7.2 21.5s17.5 .7 21.5-7.2l16-32z"
          />
        </svg>
      ),
    },
    regional: {
      label: 'Regional',
      // world icon is font-awesome, however, use <svg> format instead of <i> because <i> will be changed to <svg> automatically during rendering and this can cause errors when switching to other markets
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon g-mr-15"
          height="1em"
          viewBox="0 0 512 512">
          <defs>
            <style>{`.fa-secondary{opacity:0.4;fill:#214a97;}.fa-primary{fill:#ebf2ff;}`}</style>
          </defs>
          <path
            className="fa-primary"
            d="M1.1 280.3l81.7 20.4c8.2 2 16.8-.4 22.8-6.3l14.8-14.8c11.6-11.6 31.1-8.4 38.4 6.2l9.3 18.5c4.8 9.6 14.6 15.7 25.4 15.7c15.2 0 26.1-14.6 21.7-29.2l-6-19.9c-4.6-15.4 6.9-30.9 23-30.9h2.3c13.4 0 25.9-6.7 33.3-17.8l10.7-16.1c5.6-8.5 5.3-19.6-.8-27.7l-16.1-21.5c-10.3-13.7-3.3-33.5 13.4-37.7l17.6-4.4c7.2-1.8 13.1-6.8 16.1-13.5l37.7-84.9C318.3 5.8 287.8 0 256 0C114.6 0 0 114.6 0 256c0 8.2 .4 16.3 1.1 24.3zm500.4 48.3c-.3-.3-.7-.6-1.1-.8l-31.9-23c-5.9-4.3-13.4-5.6-20.5-3.6l-13.5 3.8c-6.7 1.9-13.8-.9-17.5-6.7l-2-3.1c-6-9.4-16.4-15.1-27.6-15.1s-21.6 5.7-27.6 15.1l-6.1 9.5c-1.4 2.2-3.4 4.1-5.7 5.3L312 330.1c-18.1 10.1-25.5 32.4-17 51.3l5.5 12.4c8.6 19.2 30.7 28.5 50.4 21.1l3.7-1.4c9.4-3.5 20-2.4 28.4 3.1l45.1 28.9c34.2-31.1 60-71.4 73.5-116.9zM144.5 348.1c-2.1 8.6 3.1 17.3 11.6 19.4l32 8c8.6 2.1 17.3-3.1 19.4-11.6s-3.1-17.3-11.6-19.4l-32-8c-8.6-2.1-17.3 3.1-19.4 11.6zm92-20c-2.1 8.6 3.1 17.3 11.6 19.4s17.3-3.1 19.4-11.6l8-32c2.1-8.6-3.1-17.3-11.6-19.4s-17.3 3.1-19.4 11.6l-8 32zM343.2 113.7c-7.9-4-17.5-.7-21.5 7.2l-16 32c-4 7.9-.7 17.5 7.2 21.5s17.5 .7 21.5-7.2l16-32c4-7.9 .7-17.5-7.2-21.5z"
          />
          <path
            className="fa-secondary"
            d="M1.1 280.3l81.7 20.4c8.2 2 16.8-.4 22.8-6.3l14.8-14.8c11.6-11.6 31.1-8.4 38.4 6.2l9.3 18.5c4.8 9.6 14.6 15.7 25.4 15.7c15.2 0 26.1-14.6 21.7-29.2l-6-19.9c-4.6-15.4 6.9-30.9 23-30.9h2.3c13.4 0 25.9-6.7 33.3-17.8l10.7-16.1c5.6-8.5 5.3-19.6-.8-27.7l-16.1-21.5c-10.3-13.7-3.3-33.5 13.4-37.7l17.6-4.4c7.2-1.8 13.1-6.8 16.1-13.5l37.7-84.9C443.2 53 512 146.5 512 256c0 25.2-3.6 49.6-10.4 72.6c-.3-.3-.7-.6-1.1-.8l-31.9-23c-5.9-4.3-13.4-5.6-20.5-3.6l-13.5 3.8c-6.7 1.9-13.8-.9-17.5-6.7l-2-3.1c-6-9.4-16.4-15.1-27.6-15.1s-21.6 5.7-27.6 15.1l-6.1 9.5c-1.4 2.2-3.4 4.1-5.7 5.3L312 330.1c-18.1 10.1-25.5 32.4-17 51.3l5.5 12.4c8.6 19.2 30.7 28.5 50.4 21.1l3.7-1.4c9.4-3.5 20-2.4 28.4 3.1l45.1 28.9C382.7 486.8 322.3 512 256 512C122.8 512 13.4 410.3 1.1 280.3zm162.7 56.2c-8.6-2.1-17.3 3.1-19.4 11.6s3.1 17.3 11.6 19.4l32 8c8.6 2.1 17.3-3.1 19.4-11.6s-3.1-17.3-11.6-19.4l-32-8zm84.2 11c8.6 2.1 17.3-3.1 19.4-11.6l8-32c2.1-8.6-3.1-17.3-11.6-19.4s-17.3 3.1-19.4 11.6l-8 32c-2.1 8.6 3.1 17.3 11.6 19.4zM350.3 135.2c4-7.9 .7-17.5-7.2-21.5s-17.5-.7-21.5 7.2l-16 32c-4 7.9-.7 17.5 7.2 21.5s17.5 .7 21.5-7.2l16-32z"
          />
        </svg>
      ),
    },
  };

  if (!arrMarkets) {
    return allMarketsMap;
  }

  return arrMarkets.reduce(function (result, mkt) {
    result[mkt] = allMarketsMap[mkt];
    return result;
  }, {});
}

MarketSelectDropdown.propTypes = {
  validMarkets: PropTypes.oneOfType([PropTypes.array]),
  market: PropTypes.string,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
};

MarketSelectDropdown.defaultProps = {
  variant: 'light',
  disabled: false,
};
