import React from 'react';
import PropTypes from 'prop-types';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import classes from '../membership.module.css';

export default function PlansComparison() {
  const COMPARISON_TABLE = {
    Platform: [
      {
        header: 'Recommended for',
        columns: [
          'Retail Investors, FA Practitioners',
          'Retail Investors, FA Practitioners',
          'Active Investors, Chartists, Traders, TA and FA Practitioners',
        ],
      },
      {
        header: 'Internet Browser',
        columns: ['__CHECK__', '__CHECK__', '__CHECK__'],
      },
      {
        header: 'Windows Application with Customizable Layout',
        columns: ['__XMARK__', '__XMARK__', '__CHECK__'],
      },
      {
        header: 'ShareInvestor Mobile (iOS and Android)',
        columns: ['__CHECK__', '__CHECK__', '__CHECK__'],
      },
    ],
    Market: [
      {
        header: 'Stock Prices',
        columns: ['Streaming', 'Streaming', 'Streaming'],
      },
      {
        header: 'Stocks Comparison',
        columns: ['__XMARK__', '__CHECK__', '__CHECK__'],
      },
      {
        header: 'Consensus Estimates',
        columns: ['__XMARK__', '__CHECK__', '__CHECK__'],
      },
      {
        header: 'Insider Trades',
        columns: ['__XMARK__', '__CHECK__', '__CHECK__'],
      },
      {
        header: 'Company Buyback',
        columns: ['__XMARK__', '__CHECK__', '__CHECK__'],
      },
      {
        header: 'Price Download',
        columns: ['__XMARK__', '__CHECK__', '__CHECK__'],
      },
      {
        header: 'Significant Shareholders',
        columns: ['__XMARK__', '__CHECK__', '__CHECK__'],
      },
      {
        header: 'Intraday Market Ticker',
        columns: ['__CHECK__', '__CHECK__', '__CHECK__ (Customizable)'],
      },
      {
        header: 'Market Depth (Singapore)',
        columns: ['__XMARK__', '__XMARK__', '__CHECK__ (Opt)'],
      },
      {
        header: 'Market Depth (Malaysia)',
        columns: ['__XMARK__', '__XMARK__', '__CHECK__ (Opt)'],
      },
      {
        header: 'Dynamic Data Exchange (DDE)',
        columns: ['__XMARK__', '__XMARK__', '__CHECK__'],
      },
      {
        header: 'Price Spread Calculator',
        columns: ['__XMARK__', '__XMARK__', '__CHECK__'],
      },
      {
        header: 'IPO',
        columns: ['__CHECK__', '__CHECK__', '__CHECK__'],
      },
      {
        header: "Brokers' Call (Bursa Malaysia only)",
        columns: ['__CHECK__', '__CHECK__', '__CHECK__'],
      },
    ],
    Watchlist: [
      {
        header: 'Total Counters in a watchlist',
        columns: ['50', '∞', '∞'],
      },
      {
        header: 'Number of Watchlist',
        columns: ['1', '∞', '∞'],
      },
    ],
    Portfolio: [
      {
        header: 'Portfolio Manager',
        columns: ['__CHECK__', '__CHECK__', '__CHECK__'],
      },
      {
        header: 'Smart Information Aggregation',
        indentClass: 'g-pl-30',
        columns: ['__CHECK__', '__CHECK__', '__CHECK__'],
      },
      {
        header: 'Sector/Funds Allocation',
        indentClass: 'g-pl-30',
        columns: ['__CHECK__', '__CHECK__', '__CHECK__'],
      },
      {
        header: 'Transaction Manager',
        columns: ['__CHECK__', '__CHECK__', '__CHECK__'],
      },
      {
        header: 'Dividend Tracking',
        columns: ['__CHECK__', '__CHECK__', '__CHECK__'],
      },
      {
        header: 'Cash Management',
        columns: ['__CHECK__', '__CHECK__', '__CHECK__'],
      },
      {
        header: 'Number of Counters',
        columns: ['5', '50', '80'],
      },
      {
        header: 'Number of Portfolio',
        columns: ['1', '∞', '∞'],
      },
    ],
    Screener: [
      {
        header: 'Market Screener (FA & TA)',
        columns: ['__XMARK__', '__CHECK__', '__CHECK__'],
      },
      {
        header: 'Predefined TA Screens',
        columns: ['__XMARK__', '__CHECK__', '__CHECK__'],
      },
      {
        header: 'Intrinsic Value Analysis',
        columns: ['__XMARK__', '__CHECK__', '__CHECK__'],
      },
    ],
    News: [
      {
        header: 'Newswire Aggregation',
        columns: ['__CHECK__', '__CHECK__', '__CHECK__'],
      },
      {
        header: 'News Source: ShareInvestor Express',
        columns: ['__CHECK__', '__CHECK__', '__CHECK__'],
      },
      {
        header: 'News Source: BT Breaking News',
        columns: ['__CHECK__', '__CHECK__', '__CHECK__'],
      },
      {
        header: 'News Source: SGX Announcements / Bursa Link',
        columns: ['__CHECK__', '__CHECK__', '__CHECK__'],
      },
    ],
    Chart: [
      {
        header: 'Dynamic TA Charts',
        columns: ['__XMARK__', '__XMARK__', '__CHECK__'],
      },
      {
        header: 'Customisable Indicator Database',
        indentClass: 'g-pl-30',
        columns: ['__XMARK__', '__XMARK__', '__CHECK__ (100+)'],
      },
      {
        header: 'Indicator Formula Editor',
        indentClass: 'g-pl-30',
        columns: ['__XMARK__', '__XMARK__', '__CHECK__'],
      },
      {
        header: 'Enhanced Drawing Tools',
        indentClass: 'g-pl-30',
        columns: ['__XMARK__', '__XMARK__', '__CHECK__'],
      },
      {
        header: 'Performance Comparison of Counters',
        indentClass: 'g-pl-30',
        columns: ['__XMARK__', '__XMARK__', '__CHECK__'],
      },
      {
        header: 'Candlestick Pattern Recognition',
        indentClass: 'g-pl-30',
        columns: ['__XMARK__', '__XMARK__', '__CHECK__'],
      },
      {
        header: 'News on Chart',
        indentClass: 'g-pl-30',
        columns: ['__XMARK__', '__XMARK__', '__CHECK__'],
      },
      {
        header: 'C2 Charts',
        columns: ['__XMARK__', '__CHECK__', '__CHECK__'],
      },
      {
        header: 'Factsheet Charts',
        columns: ['__CHECK__', '__CHECK__', '__CHECK__'],
      },
      {
        header: 'Volume Distribution Charts',
        columns: [
          '__CHECK__ (1 Day)',
          '__CHECK__ (1 Day, 5 Days, 10 Days)',
          '__CHECK__ (1 Day, 5 Days, 10 Days)',
        ],
      },
      {
        header: 'Trade Summary Charts',
        columns: ['__XMARK__', '__CHECK__', '__CHECK__'],
      },
    ],
    Factsheet: [
      {
        header: 'Company Overview',
        columns: ['__CHECK__', '__CHECK__', '__CHECK__'],
      },
      {
        header: 'News',
        columns: ['__CHECK__', '__CHECK__', '__CHECK__'],
      },
      {
        header: 'Financials',
        columns: ['__CHECK__ (Profit & Loss Only)', '__CHECK__', '__CHECK__'],
      },
      {
        header: 'Price Analysis',
        columns: ['__XMARK__', '__CHECK__', '__CHECK__'],
      },
      {
        header: 'Events Calendar',
        columns: ['__XMARK__', '__CHECK__', '__CHECK__'],
      },
      {
        header: 'Consensus Estimates',
        columns: ['__XMARK__', '__CHECK__', '__CHECK__'],
      },
      {
        header: 'Insider Trades',
        columns: ['__XMARK__', '__CHECK__', '__CHECK__'],
      },
      {
        header: 'Shareholders',
        columns: ['__XMARK__', '__CHECK__', '__CHECK__'],
      },
      {
        header: 'Dividend Analysis',
        columns: ['__XMARK__', '__CHECK__', '__CHECK__'],
      },
      {
        header: 'Company Buyback',
        columns: ['__XMARK__', '__CHECK__', '__CHECK__'],
      },
      {
        header: 'Reports',
        columns: ['__XMARK__', '__CHECK__', '__CHECK__'],
      },
      {
        header: 'Trade Summary Matrix',
        columns: ['__XMARK__', '__CHECK__', '__CHECK__ (with trade breakdown)'],
      },
      {
        header: 'Quote Movements',
        columns: ['__XMARK__', '__CHECK__', '__CHECK__'],
      },
      {
        header: 'Historical Prices',
        columns: ['__XMARK__', '__CHECK__', '__CHECK__'],
      },
    ],
    'Notifications and Alerts': [
      {
        header: 'Stock Price Alerts',
        superscript: 'a',
        columns: ['__XMARK__', '120', '160'],
      },
      {
        header: 'News Alerts',
        superscript: 'b',
        columns: ['__XMARK__', '120', '160'],
      },
      {
        header: 'Free SMS credits (for 12 months subsciption only)',
        columns: ['__XMARK__', '100 SMS credits', '300 SMS credits'],
      },
      {
        header: 'Alert by SMS',
        superscript: 'c',
        columns: ['__XMARK__', '__CHECK__', '__CHECK__'],
      },
      {
        header: 'Alert by Email',
        columns: ['__XMARK__', '__CHECK__', '__CHECK__'],
      },
      {
        header: 'Alert by ShareInvestor.com',
        columns: ['__XMARK__', '__CHECK__', '__CHECK__'],
      },
      {
        header: 'Alert by ShareInvestor Station',
        columns: ['__XMARK__', '__XMARK__', '__CHECK__'],
      },
    ],
    'Mobile Platform': [
      {
        header: 'Native iPhone App',
        columns: ['__CHECK__', '__CHECK__', '__CHECK__'],
      },
      {
        header: 'Native Android App',
        columns: ['__CHECK__', '__CHECK__', '__CHECK__'],
      },
      {
        header: 'ShareInvestor Mobile Website',
        columns: ['__CHECK__', '__CHECK__', '__CHECK__'],
      },
    ],
  };

  return (
    <>
      <div id="plansComparison" />
      <div className="g-mt-80">
        <div className={`row ${classes.stickyHeader}`}>
          <div className="col-md-6 col-sm-12">
            <h2>Plans Comparison</h2>
          </div>
          <div className="col-md-2 col-4">
            <div className="text-bg-dark g-border-radius-10 text-center">
              <h6 className="p-1">Free</h6>
            </div>
          </div>
          <div className="col-md-2 col-4">
            <div className="text-bg-dark g-border-radius-10 text-center">
              <h6 className="p-1">Pro</h6>
            </div>
          </div>
          <div className="col-md-2 col-4">
            <div className="text-bg-dark g-border-radius-10 text-center">
              <h6 className="p-1">Premium</h6>
            </div>
          </div>
        </div>
        {Object.keys(COMPARISON_TABLE).map((title) => {
          return <Table tableTitle={title} rows={COMPARISON_TABLE[title]} key={title} />;
        })}
        <Notes />
      </div>
    </>
  );
}

function Table({ tableTitle, rows }) {
  return (
    <>
      <div className="row g-mt-30 border-bottom py-2 border-dark">
        <div className="col-12">
          <h4>{tableTitle}</h4>
        </div>
      </div>

      {rows.map((row) => {
        return (
          <div className="row border-bottom" key={row.header}>
            <div className={`col-md-6 col-sm-12 py-2 ${row.indentClass ? row.indentClass : ''}`}>
              {row.header} <sup>{row.superscript}</sup>{' '}
              {row.info && (
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className="tooltip_custom">
                      <div className="text-start overflow-x-hidden overflow-y-scroll">
                        {row.info}
                      </div>
                    </Tooltip>
                  }>
                  <span>
                    <i className="fa-solid fa-circle-info" />
                  </span>
                </OverlayTrigger>
              )}
            </div>

            {row.columns.map((col, i) => {
              return (
                <div className="col-md-2 col-4 text-center py-2" key={i}>
                  {col.includes('__XMARK__') && <i className="fa-regular fa-xmark" />}
                  {col.includes('__CHECK__') && <i className="fa-regular fa-check text-success" />}
                  {(col.includes('__XMARK__') || col.includes('__CHECK__')) && <br />}
                  {col.replace(/__XMARK__/g, '').replace(/__CHECK__/g, '')}
                </div>
              );
            })}
          </div>
        );
      })}
    </>
  );
}

Table.propTypes = {
  tableTitle: PropTypes.string,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string,
      info: PropTypes.string,
      superscript: PropTypes.string,
      indentClass: PropTypes.string,
      columns: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
};

function Notes() {
  return (
    <div className="text-muted g-mt-30">
      <div className="mb-2">
        <strong>Notes:</strong>
      </div>
      <div className="sic_legendAlpha">
        <div className="d-flex gap-1 ms-4">
          <sup className="footer-disclaimer">a</sup>
          <span className="g-text-size-14 footer-disclaimer">
            Applicable for subscription with real-time SGX market data access only.
          </span>
        </div>
        <div className="d-flex gap-1 ms-4">
          <sup className="footer-disclaimer">b</sup>
          <span className="g-text-size-14 footer-disclaimer">
            Applicable for all paying subscriptions.
          </span>
        </div>
        <div className="d-flex gap-1 ms-4">
          <sup className="footer-disclaimer">c</sup>
          <span className="g-text-size-14 footer-disclaimer">
            SMS credits apply. Existing SMS credits in your membership package (where applicable)
            will be utilised first.
          </span>
        </div>
      </div>
    </div>
  );
}
