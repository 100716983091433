import React, { useState } from 'react';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';

import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

import { useDispatch } from 'react-redux';
import { editUserTemplates } from './marketScreenerSlice';

const { CancelToken } = axios;

function EditUserTemplatesList({ templates, handleToggleEdit }) {
  const dispatch = useDispatch();
  const source = CancelToken.source();
  const [templatesList, setTemplatesList] = useState(templates);

  const handleDragEnd = ({ destination, source }) => {
    if (!destination || destination.index === source.index) {
      return;
    }

    const startIndex = source.index;
    const endIndex = destination.index;

    const result = Array.from(templatesList);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    setTemplatesList(result);
  };

  const onRemoveFromSelection = (e, fieldID) => {
    e.stopPropagation();
    if (templatesList.includes(fieldID)) {
      const updatedTemplatesList = templatesList.filter((x) => x !== fieldID);
      setTemplatesList(updatedTemplatesList);
    }
  };

  const handleChangeName = (e, key) => {
    const { value } = e.target;

    const index = templatesList.findIndex((template) => template.key === key);

    if (index !== -1) {
      const updatedTemplatesList = [...templatesList];

      updatedTemplatesList[index] = {
        ...updatedTemplatesList[index],
        title: value,
      };
      setTemplatesList(updatedTemplatesList);
    }
  };

  const handleClickSaveEdit = () => {
    // format result to object and compress
    const formatResult = templatesList.reduce((acc, item, index) => {
      acc[item.key] = {
        name: item.title,
        seq: (index + 1).toString(),
      };
      return acc;
    }, {});
    const result = JSON.stringify(formatResult);

    dispatch(
      editUserTemplates({
        templatesData: result,
        cancelToken: source.token,
      }),
    );
  };

  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="edit_user_templates_droppable">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {templatesList.map((template, index) => (
                <Draggable key={template.key} index={index} draggableId={template.key}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="d-flex flex-row gap-3 justify-content-between draggable_item g-mb-5">
                      <Form.Control
                        value={template.title}
                        onChange={(e) => handleChangeName(e, template.key)}
                      />
                      <div className="d-flex flex-row gap-3 align-items-center ">
                        <div
                          role="button"
                          onClick={(e) => onRemoveFromSelection(e, templatesList[index])}>
                          <i className="fa-regular fa-xmark" />
                        </div>
                        <div>
                          <i className="fa-regular fa-grip-lines" />
                        </div>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className="d-flex gap-3 align-items-center justify-content-end">
        <Button variant="light" onClick={() => handleToggleEdit(false)}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleClickSaveEdit}>
          Save
        </Button>
      </div>
    </>
  );
}

EditUserTemplatesList.propTypes = {
  templates: PropTypes.oneOfType([PropTypes.array]),
  handleToggleEdit: PropTypes.func,
};

export default EditUserTemplatesList;
