import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { getCurrencyName } from '../../currencies';
import CreateUpdateDividend from './CreateUpdateDividend';
import ModalDialog from '../../../../components/ModalDialog';
import { clearResponseAndError, deleteDividend } from './portfolioDividendSlice';
import PopUpNote from '../../../../components/PopUpNote';

const { CancelToken } = axios;

function DividendTable() {
  const dispatch = useDispatch();
  const source = CancelToken.source();
  const selectedPortfolio = useSelector((state) => state.portfolio.selected);
  const dividendData = useSelector((state) => state.portfolioDividend.data);
  const selectedLayout = useSelector((state) => state.portfolio.selectedLayout);
  const [selectedDividendForUpdate, setSelectedDividendForUpdate] = useState(null);
  const [selectedDividendForDelete, setSelectedDividendForDelete] = useState(null);

  const showDeleteModal = (dividend) => {
    setSelectedDividendForDelete(dividend);
    dispatch(clearResponseAndError());
  };

  const handleDeleteDividend = (dividendID) => {
    dispatch(
      deleteDividend({
        portfolioID: selectedPortfolio.id,
        dividendID,
        selectedLayout,
        cancelToken: source.token,
      }),
    );
  };

  return (
    <>
      <div className="table-responsive">
        <table className="table table-hover mb-0 table-with-hover-popup">
          <thead className="table-light">
            <tr>
              <th>Name</th>
              <th className="text-end">Shares</th>
              <th>Ex-Date</th>
              <th>Pay Date</th>
              <th className="text-end">Amt</th>
              <th className="text-end">Amt ({getCurrencyName(selectedPortfolio.currency)})</th>
              <th className="text-end">Exchange</th>
              <th>Type</th>
              <th className="text-end">Total ({getCurrencyName(selectedPortfolio.currency)})</th>
              <th>Note</th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {dividendData.map((dividend) => {
              return (
                <tr key={dividend.id}>
                  <td>{dividend.stock_name}</td>
                  <td className="text-end">{dividend.shares}</td>
                  <td>{dividend.ex_date}</td>
                  <td>{dividend.pay_date}</td>
                  <td className="text-end">
                    {dividend.amount &&
                      dividend.amount !== 'n.a.' &&
                      `${getCurrencyName(dividend.curr_id)} `}
                    {dividend.amount}
                  </td>
                  <td className="text-end">{dividend.amount_in_currency}</td>
                  <td className="text-end">{dividend.exchange_rate}</td>
                  <td>{dividend.type}</td>
                  <td className="text-end">{dividend.total}</td>
                  <td>
                    {dividend.notes && dividend.notes.length > 0 && (
                      <PopUpNote
                        content={dividend.notes}
                        icon="fa-thin fa-note fa-xl"
                        classes="text-start text-muted g-width-300 g-sm-width-500 g-md-width-700"
                      />
                    )}
                  </td>
                  <td className="text-center">
                    <OverlayTrigger
                      placement="top"
                      key={`edit_dividend_${dividend.id}`}
                      overlay={<Tooltip>Edit Dividend</Tooltip>}>
                      <span
                        role="button"
                        className="g-ml-5 g-mr-5 icon"
                        onClick={() => setSelectedDividendForUpdate(dividend)}>
                        <i className="fa-light fa-pen-to-square" />
                      </span>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="top"
                      key={`delete_${dividend.id}`}
                      overlay={<Tooltip>Delete Dividend</Tooltip>}>
                      <span
                        role="button"
                        className="g-ml-5 g-mr-5 icon"
                        onClick={() => showDeleteModal(dividend)}>
                        <i className="fa-light fa-square-xmark" />
                      </span>
                    </OverlayTrigger>
                  </td>
                </tr>
              );
            })}
            {!dividendData ||
              (dividendData.length === 0 && (
                <tr>
                  <td colSpan="11" className="text-center text-muted">
                    No Data
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <CreateUpdateDividend
        dividend={selectedDividendForUpdate}
        setDividend={setSelectedDividendForUpdate}
      />
      <ModalDialog
        show={!!selectedDividendForDelete}
        handleClose={() => {
          setSelectedDividendForDelete(null);
        }}
        title="Delete Dividend"
        body={`Are you sure you want to delete dividend for ${selectedDividendForDelete?.stock_name} (Pay Date: ${selectedDividendForDelete?.pay_date})?`}
        handleSave={() => handleDeleteDividend(selectedDividendForDelete.id)}
        saveLabel="Delete"
        saveVariant="danger"
      />
    </>
  );
}

export default DividendTable;
