import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';

import AnalysisToolsButton from '../../components/AnalysisToolsButton';

export default function StockPricesAnalysisTools() {
  const currentFilters = useSelector((state) => state.stockPrices.filters);
  const selectedMarket = useSelector((state) => state.selectedMarket.selectedMarket);
  const data = useSelector((state) => state.stockPrices.data);

  if (!currentFilters || !currentFilters.filter || !selectedMarket) {
    return <Spinner />;
  }

  const match = currentFilters.filter.match(/^(DA01|DS01|DW01)(\w{1}|0\-9)$/);
  const filter = match ? match[1] : currentFilters.filter;
  const alphabet = match ? match[2] : null;

  const colonSeparatedCounters = data?.stock_info?.map((s) => s.counter).join(':');
  return (
    <AnalysisToolsButton
      filter={filter}
      alphabet={alphabet}
      market={selectedMarket}
      page={currentFilters.page}
      counters={colonSeparatedCounters}
      availableFeatures={data?.has_analysis_charts_feature}
    />
  );
}

StockPricesAnalysisTools.propTypes = {};
