const streaming = require.context('.', true, /\.js$/)
streaming.keys().forEach(streaming)

import { pushPage } from "./protocol";

var is_legal_user = false;
var is_streaming_sgx = false;
var is_streaming_bursa = false;
var enc_user_details = null;
var streaming_token = null;
var streaming_usr_mkt_acs = null;
var streaming_server_host = null;
var streaming_transport_method = null;

export const resetModeToStreaming = function() {
  if (!pushPage) { return };
  if (streaming_token && streaming_usr_mkt_acs && streaming_server_host && streaming_transport_method) {
    pushPage.engine.connection.setTransportMethod(streaming_transport_method);
    pushPage.setData("mode", 'streaming');
  } else {
    initConfigForStreaming();
  }
}

export const forceStopStreaming = function() {
  if (!pushPage) { return };
  pushPage.setData("mode", 'static');
  if (!pushPage.engine.isStopped()) {
    pushPage.cancelAllTables();
    pushPage.engine.stop();
  }
}

function prepareForStreaming() {
  if (!pushPage) { return };
  pushPage.setData('token', streaming_token);
  pushPage.setStreamingUsrMktAcs(streaming_usr_mkt_acs);
  pushPage.engine.connection.setStreamingServerHost(streaming_server_host);
  if (is_legal_user == "true") {
    pushPage.setData("user_id", enc_user_details);
  }
  pushPage.setStreamingMode({
    "sgx": is_streaming_sgx,
    "bursa": is_streaming_bursa
  });
  pushPage.engine.connection.setTransportMethod(streaming_transport_method);
  pushPage.setData("mode", 'streaming');
  //console.log("Done setting up pushPage for streaming.");
}

function initConfigForStreaming() {
  let divForStreaming = document.getElementById('divForStreaming');
  if (!divForStreaming) { return };

  Cookies.write("cookiecheck", 1, 1);
  streaming_token = divForStreaming.dataset.streaming_token;
  streaming_usr_mkt_acs = divForStreaming.dataset.streaming_usr_mkt_acs;
  streaming_server_host = divForStreaming.dataset.streaming_server_host;
  is_legal_user = divForStreaming.dataset.is_legal_user;
  enc_user_details = divForStreaming.dataset.enc_user_details;
  is_streaming_sgx = divForStreaming.dataset.is_streaming_sgx;
  is_streaming_bursa = divForStreaming.dataset.is_streaming_bursa;
  streaming_transport_method = divForStreaming.dataset.streaming_transport_method;

  prepareForStreaming();
}

document.addEventListener("DOMContentLoaded", () => {
  if (typeof document === 'undefined') {
    return;
  }
  if (!(streaming_token && streaming_usr_mkt_acs && streaming_server_host && streaming_transport_method)) {
    initConfigForStreaming();
  }
});

window.onbeforeunload = () => {
  // ensure streaming is stopped before user is redirected to a diff url
  forceStopStreaming();
};

function receiveMsgsFromStreamingFrame(event) {
  if (!pushPage || pushPage.engine.isStopped()) {
    return;
  }

  const streamingFrame = document.getElementById(pushPage.engine.getIFrameID());
  if (!streamingFrame) {
    return;
  }

  if (event.origin !== pushPage.engine.connection.getOrigin()) {
    // ignore message from unexpected origin
    return;
  }

  switch (event.data.funcName) {
    case 'p':
      if (event.data.arrArgs && event.data.arrArgs.length > 4) {
        window.p(
          event.data.arrArgs[0],
          event.data.arrArgs[1],
          event.data.arrArgs[2],
          event.data.arrArgs[3],
          event.data.arrArgs[4],
        );
      }
      break;
    case 'p_s':
      if (event.data.arrArgs && event.data.arrArgs.length > 1) {
        window.p_s(
          event.data.arrArgs[0],
          event.data.arrArgs[1],
        );
      }
      break;
    case 'p_error':
      if (event.data.arrArgs && event.data.arrArgs.length > 0) {
        window.p_error(
          event.data.arrArgs[0],
        );
      }
      break;
    case 'p_clear':
      if (event.data.arrArgs && event.data.arrArgs.length > 2) {
        window.p_clear(
          event.data.arrArgs[0],
          event.data.arrArgs[1],
          event.data.arrArgs[2],
        );
      }
      break;
    default:
      break; // do nothing
  }
}

window.addEventListener('message', receiveMsgsFromStreamingFrame, false);
