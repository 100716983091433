import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';

export default function Loader({ visible, classes }) {
  if (!visible) {
    return null;
  }

  return (
    <div className={`d-flex justify-content-center w-100 ${classes}`}>
      <Spinner />
    </div>
  );
}

Loader.propTypes = {
  visible: PropTypes.bool,
  classes: PropTypes.string,
};
