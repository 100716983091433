import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';

import { useDetectMobileScreen } from '../../utils/utils';
import styles from './searchQuote.module.css';

export default function SearchQuotesList({
  searchResult,
  activeCounter,
  changeActiveCounter,
  enableCheckButton,
  checkedData,
  allowRedirect,
}) {
  if (!searchResult || !searchResult.data || searchResult.data.length === 0) {
    return (
      <div className="border-top">
        <div className="m-3 text-center">No results</div>
      </div>
    );
  }
  const redirectUrl = '/home/redirect?type=factsheet&counter=';
  const isMobile = useDetectMobileScreen();
  const redirectToFactsheetOverview = (newActiveCounter) => {
    if (!newActiveCounter?.stock_id || !newActiveCounter?.market_suffix) {
      return;
    }

    // Go to Factsheet
    window.location.href = `${redirectUrl}${newActiveCounter?.stock_id}.${newActiveCounter?.market_suffix}`;
  };
  const handleItemClick = (e, data) => {
    if (enableCheckButton) {
      const checkbox = e.currentTarget.querySelector('input');
      checkbox.checked = !checkbox.checked;
      data.checkbox = checkbox.checked;
    }

    if (changeActiveCounter) {
      changeActiveCounter(data);
    }

    if (isMobile && allowRedirect) {
      redirectToFactsheetOverview(data);
    }
  };

  return (
    <ul className={`${styles.quoteList} list-group border-top mb-1`}>
      {searchResult.data.map((data, index) => (
        <li
          className={`list-group-item ${
            activeCounter && `${activeCounter.symbol}` === `${data.stock_id}.${data.market_suffix}`
              ? 'bg-light border-end-0'
              : ''
          }`}
          key={`${data.stock_id}.${data.market_suffix}`}
          onClick={(e) => {
            handleItemClick(e, data);
          }}>
          <div className="row">
            <div className="col-9">
              <div>
                <strong className="g-text-size-16">
                  {allowRedirect ? (
                    <a href={`${redirectUrl}${data?.stock_id}.${data?.market_suffix}`}>
                      {data.stock_name}
                    </a>
                  ) : (
                    data.stock_name
                  )}
                </strong>
                {data.is_delisted === '1.0' && (
                  <span className="g-ml-10 badge badge-delisted">DELISTED</span>
                )}
              </div>
              <span className={styles.grayText}>{data.stock_id}</span>
            </div>
            <div className={enableCheckButton ? 'col-2' : 'col-3'}>
              <span className={`${styles.grayText} float-end pt-2`}>{data.market}</span>
            </div>
            {enableCheckButton && (
              <div className="col-1">
                <Form.Check // prettier-ignore
                  type="switch"
                  id={data.stock_id}
                  className="g-mt-5"
                  defaultChecked={
                    checkedData && checkedData.includes(`${data.stock_id}.${data.market_suffix}`)
                  }
                  disabled
                />
              </div>
            )}
          </div>
        </li>
      ))}
    </ul>
  );
}

SearchQuotesList.propTypes = {
  searchResult: PropTypes.object,
  activeCounter: PropTypes.object,
  changeActiveCounter: PropTypes.func,
  enableCheckButton: PropTypes.bool,
  checkedData: PropTypes.arrayOf(PropTypes.string),
  allowRedirect: PropTypes.bool,
};
