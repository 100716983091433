import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Dropdown, Modal } from 'react-bootstrap';

import { SelectedFieldsDnD } from '.';
import classes from './editCustomLayoutDropdown.module.css';

export default function EditCustomLayoutModal({
  allFields,
  currentSelectedFields,
  fieldCategories,
  currentActiveCategory,
  cbEvenHandlers,
  fnGetFieldSelectionsList,
  isPortfolio,
}) {
  const [modalShown, setModalShown] = useState(false);
  const [selectedFields, setSelectedFields] = useState([]);
  const [activeCategory, setActiveCategory] = useState('');
  const [fieldsDropdownShown, setFieldsDropdownShown] = useState(false);
  const [fieldsDropdownLabel, setDropdownLabel] = useState('');

  useEffect(() => {
    if (currentSelectedFields != selectedFields) {
      setSelectedFields(currentSelectedFields ? [...currentSelectedFields] : []);
    }
  }, [currentSelectedFields]);

  useEffect(() => {
    if (currentActiveCategory != activeCategory) {
      setActiveCategory(currentActiveCategory);
    }
  }, [currentActiveCategory]);

  useEffect(() => {
    const categoryFieldIdRanges =
      fieldCategories.filter((x) => x.name === activeCategory)[0]?.fieldIdRanges || [];
    const selectedFieldIDsInCategory = Object.keys(allFields).filter((k) => {
      for (let i = 0; i < categoryFieldIdRanges.length; i += 1) {
        if (
          k >= categoryFieldIdRanges[i].min &&
          k <= categoryFieldIdRanges[i].max &&
          selectedFields.includes(Number(k))
        ) {
          return true;
        }
      }
      return false;
    });

    if (selectedFieldIDsInCategory.length > 0) {
      setDropdownLabel(`${selectedFieldIDsInCategory.length} fields selected`);
    } else {
      setDropdownLabel('Select Fields');
    }
  }, [activeCategory, selectedFields, isPortfolio]);

  const onFieldsDropdownToggle = (nextShow, meta) => {
    setFieldsDropdownShown(nextShow);
  };

  const {
    onCategoryClick,
    onRemoveFromSelection,
    onDragEnd,
    onClearSelections,
    onCancelSelections,
    onSaveSelections,
  } = cbEvenHandlers;

  const ddMenuMaxHeight = Math.ceil(window.innerHeight * 0.4);
  return (
    <>
      <Button
        className={`btn btn-light ${!isPortfolio && 'g-mb-10'}`}
        onClick={() => setModalShown(true)}>
        <span className="text-dark">Edit Custom Layout</span>
      </Button>
      <Modal
        show={modalShown}
        onHide={() => setModalShown(false)}
        dialogClassName={classes.customModalDialog}
        scrollable>
        <Modal.Header closeButton>
          <Modal.Title>Edit Custom Layout</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="g-per-height-80">
            {/* field categories */}
            <h6 className="g-mt-10 g-mb-10">Category</h6>
            <div className="row justify-content-center g-mb-15">
              <div className="g-per-width-95 g-sm-per-width-80">
                <Dropdown drop="down">
                  <Dropdown.Toggle variant="light" className="g-per-width-100">
                    <span className="g-mr-10">{activeCategory || 'Select Category'}</span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className={`g-per-width-100 g-max-height-${ddMenuMaxHeight}`}>
                    <div>
                      {fieldCategories &&
                        fieldCategories.map((cat, index) => {
                          return (
                            <Dropdown.Item
                              key={`edit_custom_layout_fldcat_${index}`}
                              className={`dropdown-item ${
                                activeCategory === cat.name ? 'active' : ''
                              }`}
                              onClick={() => {
                                onCategoryClick && onCategoryClick(cat.name);
                              }}>
                              {cat.name}
                            </Dropdown.Item>
                          );
                        })}
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            {/* select fields */}
            <h6 className="g-mt-10 g-mb-10">Fields</h6>
            <div className="row justify-content-center g-mb-15">
              <div className="g-per-width-95 g-sm-per-width-80">
                <Dropdown
                  drop="down"
                  autoClose="outside"
                  show={fieldsDropdownShown}
                  onToggle={onFieldsDropdownToggle}>
                  <Dropdown.Toggle variant="light" className="g-per-width-100">
                    <span className="g-mr-10">{fieldsDropdownLabel}</span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    className={`g-per-width-100 g-height-${ddMenuMaxHeight} ${classes.customModalDropdownMenuContainer}`}>
                    <div>
                      <ul id="edit_custom_layout_select_fields" className={`list-unstyled`}>
                        {fnGetFieldSelectionsList &&
                          fnGetFieldSelectionsList(activeCategory, selectedFields)}
                      </ul>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            {/* selected fields sorting */}
            <h6 className="g-mt-10 g-mb-10">Selected Fields</h6>
            <div className="row justify-content-center g-mb-15">
              <div className="g-per-width-95 g-sm-per-width-80">
                <SelectedFieldsDnD
                  currentFieldsSelected={selectedFields}
                  allFields={allFields}
                  onDragEnd={onDragEnd}
                  onItemRemove={onRemoveFromSelection}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center p-1">
          <div className="row g-mt-20 g-mb-10">
            <div className="col-auto px-1">
              <Button
                className="btn btn-light btn text-dark g-min-width-100 g-mb-10"
                onClick={() => {
                  onClearSelections && onClearSelections();
                }}>
                Clear
              </Button>
            </div>
            <div className="col-auto px-1">
              <Button
                className="btn btn-light btn text-dark g-min-width-100 g-mb-10"
                onClick={() => {
                  onCancelSelections && onCancelSelections();
                  setModalShown(false);
                }}>
                Cancel
              </Button>
            </div>
            <div className="col-auto px-1">
              <Button
                className="btn btn-success btn g-min-width-100 g-mb-10"
                onClick={() => {
                  onSaveSelections && onSaveSelections();
                  setModalShown(false);
                }}>
                Update
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

EditCustomLayoutModal.propTypes = {
  allFields: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  currentCustomFields: PropTypes.arrayOf(PropTypes.number),
  fieldCategories: PropTypes.arrayOf(PropTypes.string),
  currentActiveCategory: PropTypes.string,
  cbEvenHandlers: PropTypes.arrayOf(PropTypes.func),
  fnGetFieldSelectionsList: PropTypes.func,
  isPortfolio: PropTypes.bool,
};
