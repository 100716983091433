import React from 'react';
import PropTypes from 'prop-types';

import NewsOutline from './NewsOutline';

function NewsItem({ news, basePath, viewMode, handleClickNews }) {
  const isGridBorder = viewMode === 'grid_border';

  return (
    <>
      {(viewMode === 'grid' || viewMode === 'grid_border') && (
        <div className="g-min-height-100 g-lg-min-height-150 col-lg-4 col-12">
          <div className={`card ${isGridBorder ? 'border-1 hover_shadow' : 'border-0'}  h-100`}>
            <div className={`card-body ${isGridBorder ? '' : 'p-0'}`}>
              <NewsOutline
                news={news}
                basePath={basePath}
                viewMode={viewMode}
                handleClickNews={handleClickNews}
              />
            </div>
          </div>
        </div>
      )}
      {viewMode === 'grid_main' && (
        <div className="g-min-height-100 col-lg-3 col-md-4 ">
          <div className="card bg-gray-200 border-0 h-100 g-border-radius-10">
            <div className="card-body p-3">
              <NewsOutline
                news={news}
                basePath={basePath}
                viewMode={viewMode}
                handleClickNews={handleClickNews}
              />
            </div>
          </div>
        </div>
      )}
      {(viewMode == 'list' || viewMode == 'list_main') && (
        <div className="col-12">
          <div className="border-1 g-border-b-1 pb-4">
            <NewsOutline
              news={news}
              basePath={basePath}
              viewMode={viewMode}
              handleClickNews={handleClickNews}
            />
          </div>
        </div>
      )}
    </>
  );
}

NewsItem.propTypes = {
  news: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  basePath: PropTypes.string,
  viewMode: PropTypes.string,
  handleClickNews: PropTypes.func,
};

export default NewsItem;
