import { createSlice } from '@reduxjs/toolkit';
import api from '../../utils/api';

const insiderTradesSlice = createSlice({
  name: 'insiderTrades',
  initialState: {
    completed: false,
    data: [],
    err: null,
    loading: true,
    pagination: {},
  },
  reducers: {
    insiderTradesFetchStart(state, action) {
      state.completed = false;
      state.loading = true;
    },
    insiderTradesFetchSuccess(state, action) {
      state.data = action.payload.data;
      state.pagination = action.payload.data.pagination;
      state.completed = true;
      state.loading = false;
    },
    insiderTradesFetchFail(state, action) {
      state.err = action.payload;
      state.completed = true;
      state.loading = false;
    },
  },
});

export default insiderTradesSlice.reducer;

const { insiderTradesFetchStart, insiderTradesFetchSuccess, insiderTradesFetchFail } =
  insiderTradesSlice.actions;

export const fetchDataMarkets =
  ({ filters, cancelToken }) =>
  (dispatch) => {
    dispatch(insiderTradesFetchStart());
    let url = `/${filters.market}/insider_trades.json?`;
    if (filters.counter !== null && filters.counter !== '') {
      url += `&counter=${filters.counter}`;
    }
    if (filters.buyerSeller !== null && filters.buyerSeller !== '') {
      url += `&search_buyer_seller=${filters.buyerSeller}`;
    }
    if (filters.fromDate !== null && filters.fromDate !== '') {
      url += `&search_start_date=${filters.fromDate}`;
    }
    if (filters.toDate !== null && filters.toDate !== '') {
      url += `&search_end_date=${filters.toDate}`;
    }
    if (filters.tradeTypes !== null && filters.tradeTypes !== '') {
      url += `&search_trade_types=${filters.tradeTypes}`;
    }
    url += `&page=${filters.page}`;
    api
      .get(url, {
        cancelToken,
      })
      .then(({ data }) => {
        dispatch(insiderTradesFetchSuccess({ data }));
      })
      .catch((error) => {
        dispatch(insiderTradesFetchFail(error));
      });
  };

export const fetchDataFactsheets =
  ({ filters, cancelToken }) =>
  (dispatch) => {
    dispatch(insiderTradesFetchStart());
    let url = `quote/${filters.counter}/insider_trades/${filters.tab}.json?`;
    if (filters.tab === 'insider_search') {
      if (filters.buyerSeller !== null && filters.buyerSeller !== '') {
        url += `&search_buyer_seller=${filters.buyerSeller}`;
      }
      if (filters.fromDate !== null && filters.fromDate !== '') {
        url += `&search_start_date=${filters.fromDate}`;
      }
      if (filters.toDate !== null && filters.toDate !== '') {
        url += `&search_end_date=${filters.toDate}`;
      }
      if (filters.tradeTypes !== null && filters.tradeTypes !== '') {
        url += `&search_trade_types=${filters.tradeTypes}`;
      }
    }
    url += `&page=${filters.page}`;
    api
      .get(url, {
        cancelToken,
      })
      .then(({ data }) => {
        dispatch(insiderTradesFetchSuccess({ data }));
      })
      .catch((error) => {
        dispatch(insiderTradesFetchFail(error));
      });
  };
