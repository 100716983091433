import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { showStockPopup } from './stockInfoPopupSlice';
import { useDetectMobileScreen, getSymbolFromCounter } from '../../utils/utils';
import CompanyLogoImage from '../CompanyLogoImage';

function CompanyNameLink({
  counter,
  name,
  showStockInfoPopUp = false,
  showLogo = false,
  showCounter = false,
}) {
  const dispatch = useDispatch();

  const handleMouseEnter = (event) => {
    if (!showStockInfoPopUp) {
      return;
    }
    const currentElement = event.currentTarget;
    const offsets = currentElement.getBoundingClientRect();
    const popupContainer = document.getElementById('stock_popup_container');
    if (popupContainer) {
      popupContainer.style.top = `${
        offsets.top + window.pageYOffset - currentElement.ownerDocument.documentElement.clientTop
      }px`;
      popupContainer.style.left = `${offsets.right + 5}px`;
    }

    dispatch(showStockPopup({ counter, isShown: true }));
  };

  const handleMouseLeave = () => {
    if (!showStockInfoPopUp) {
      return;
    }
    dispatch(showStockPopup({ isShown: false }));
  };

  const isMobile = useDetectMobileScreen();

  return (
    <a
      className="stock_popup_hover d-inline-block"
      onMouseEnter={!isMobile ? handleMouseEnter : undefined}
      onMouseLeave={!isMobile ? handleMouseLeave : undefined}
      href={`/quote/${counter}`}>
      {showLogo && showCounter && (
        <div className="d-flex align-items-center">
          <div className="g-width-45 g-height-45 g-mr-8">
            <CompanyLogoImage counter={getSymbolFromCounter(counter)} />
          </div>
          <div>
            <div>{name}</div>
            <div className="text-muted g-text-size-13">{counter}</div>
          </div>
        </div>
      )}
      {showLogo && !showCounter && (
        <div className="d-flex align-items-center">
          <div className="g-width-45 g-height-45 g-mr-8">
            <CompanyLogoImage counter={getSymbolFromCounter(counter)} />
          </div>
          <div>{name}</div>
        </div>
      )}
      {!showLogo && showCounter && (
        <div>
          <div>{name}</div>
          <div className="text-muted g-text-size-13">{counter}</div>
        </div>
      )}
      {!showLogo && !showCounter && <span>{name}</span>}
    </a>
  );
}

CompanyNameLink.propTypes = {
  counter: PropTypes.string,
  name: PropTypes.string,
  showStockInfoPopUp: PropTypes.bool,
  showLogo: PropTypes.bool,
  showCounter: PropTypes.bool,
};

export default CompanyNameLink;
