import React, { useEffect, useState, lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';

import WorldIndicesCarousel from './WorldIndicesCarousel';

async function stopStreaming() {
  const { stopWldIndicesPricesStreaming } = await import(
    '../../utils/Streaming/world_indices_prices'
  );
  stopWldIndicesPricesStreaming();
}

async function startStreaming() {
  const { startWldIndicesPricesStreaming } = await import(
    '../../utils/Streaming/world_indices_prices'
  );
  startWldIndicesPricesStreaming();
}

export default function WorldIndicesList({ data, children }) {
  if (!data) {
    return null;
  }

  const [isSSR, setIsSSR] = useState(true);
  const isModeStreaming = useSelector((state) => state.pricesStreamingMode.isModeStreaming);

  useEffect(() => {
    const isDocTypeUndef = typeof document === 'undefined';
    setIsSSR(isDocTypeUndef);
    const indicesList = document.getElementById('sic_indexList');
    if (indicesList && isModeStreaming && !isDocTypeUndef) {
      startStreaming();
    }
    return () => {
      stopStreaming();
    };
  }, []);

  if (children) {
    const CustomList = (props) => {
      const renderChildren = () => {
        return React.Children.map(props.children, (child) => {
          return React.cloneElement(child, {
            data,
            dataFeedMode: isModeStreaming ? 'streaming' : 'static',
          });
        });
      };

      return renderChildren();
    };

    return <CustomList data={data}>{children}</CustomList>;
  }
  return (
    <WorldIndicesCarousel
      data={data}
      data-feed_mode={isModeStreaming ? 'streaming' : 'static'}
      isSSR={isSSR || false}
    />
  );
}
