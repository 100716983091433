import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import ScrollSpy from 'react-ui-scrollspy';

import Header from './Header';
import NoDataAvailable from './NoDataAvailable';
import Loader from '../../components/Loader';
import APIErrorHandler from '../../components/APIErrorHandler';
import classes from './portfolio.module.css';
import { useDetectMobileScreen } from '../../utils/utils';

function Content({ tabs }) {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useDetectMobileScreen();
  const error = useSelector((state) => state.portfolio.err);
  const loading = useSelector((state) => state.portfolio.loading);
  const selectedPortfolio = useSelector((state) => state.portfolio.selected);
  const parentScrollContainerRef = useRef(null);
  const [tabsToShow, setTabsToShow] = useState([]);

  const scrollToSection = (target) => {
    if (!target) {
      return;
    }

    const headerOffset = isMobile ? 120 : 80;
    const elementPosition = target.getBoundingClientRect().top;
    const offsetPosition = elementPosition - headerOffset;

    window.scrollBy({
      top: offsetPosition,
      behavior: 'instant',
    });
  };

  const navigateToTab = (tab) => {
    if (!tab || tab.length === 0) {
      return;
    }

    navigate(`/portfolio/${params.portfolioID}/${tab}`);
  };

  const onTabChange = (e, tab) => {
    e.preventDefault();
    const target = window.document.getElementById(e.currentTarget.href.split('#')[1]);

    if (target) {
      scrollToSection(target);
    }

    navigateToTab(tab.path);
  };

  const onScrollCallback = (section) => {
    if (!section || section.length === 0) {
      return;
    }

    const tab = section.replace('section-', '');
    navigateToTab(tab);
  };

  useEffect(() => {
    // Set default tab
    if (
      params.portfolioID &&
      !location.pathname.split('/')[3] &&
      selectedPortfolio.counters &&
      selectedPortfolio.counters.length > 0
    ) {
      navigateToTab('analytics');
    }
  }, [params.portfolioID, selectedPortfolio.counters]);

  useEffect(() => {
    const tab = location.pathname.split('/')[3];
    const target = window.document.getElementById(`section-${tab}`);

    if (target) {
      scrollToSection(target);
    }
  }, []);

  useEffect(() => {
    const tab = location.pathname.split('/')[3];

    if (tab && tab.length > 0 && !tabsToShow.includes(tab)) {
      setTabsToShow([...tabsToShow, tab]);
    }
  }, [location]);

  return (
    <div className="container-fluid">
      <div className="row g-3">
        <Header />
      </div>
      <div className="divider-h w-100 g-mt-15 g-mb-30" />
      <div className="row g-1">
        {error && <APIErrorHandler error={error} classes="g-mt-20" />}
        <Loader visible={loading} />
        {!loading &&
          !(
            (selectedPortfolio.counters && selectedPortfolio.counters.length > 0) ||
            selectedPortfolio.totalCashFlow > 0
          ) && <NoDataAvailable />}
        {!loading &&
          ((selectedPortfolio.counters && selectedPortfolio.counters.length > 0) ||
            selectedPortfolio.totalCashFlow > 0) && (
            <>
              <div
                className={isMobile ? 'sidebar g-mb-20' : 'col-sm-auto g-per-width-10 sticky pt-0'}>
                <Nav
                  className={`nav-tabs design-2 g-mb-40 flex-column p-3 card basic-card ${
                    isMobile ? 'd-inline w-100' : 'sidebar'
                  }`}>
                  {tabs.map((tab) => (
                    <a
                      key={tab.path}
                      onClick={(e) => onTabChange(e, tab)}
                      href={`#section-${tab.path}`}
                      className={`ss-links ${isMobile ? 'd-inline-block g-pl-10 g-pr-10' : ''}`}>
                      <div data-to-scrollspy-id={`section-${tab.path}`}>
                        <span className={`g-mb-10 ${classes.navIconSize}`}>
                          {tab.icon && <i className={`${tab.icon} ${classes.navIconSize}`} />}
                        </span>
                        <span className="g-ml-5 d-inline-block g-mb-10">{tab.label}</span>
                      </div>
                    </a>
                  ))}
                </Nav>
              </div>
              <div
                ref={parentScrollContainerRef}
                className={`${isMobile ? '' : 'g-per-width-90'} col pt-sm-0`}>
                <ScrollSpy
                  activeClass="ss-active"
                  offsetBottom={100}
                  scrollThrottle={80}
                  updateHistoryStack={false}
                  onUpdateCallback={(section) => {
                    onScrollCallback(section);
                  }}
                  useBoxMethod>
                  {tabs.map((tab) => (
                    <div
                      key={tab.path}
                      id={`section-${tab.path}`}
                      className="g-min-height-500 g-mb-20">
                      {!tabsToShow.includes(tab.path) && (
                        <Loader visible={!tabsToShow.includes(tab.path)} />
                      )}
                      {tabsToShow.includes(tab.path) && tab.element}
                    </div>
                  ))}
                </ScrollSpy>
              </div>
            </>
          )}
      </div>
    </div>
  );
}

Content.propTypes = {
  tabs: PropTypes.array,
};

export default Content;
