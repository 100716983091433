import React from 'react';
import PropTypes from 'prop-types';

import { Provider } from 'react-redux';
import store from '../../../utils/store';
import UserSettingsMain from './UserSettingsMain';

function UserSettings({ settings, thirdPartyAuthApps }) {
  return (
    <Provider store={store}>
      <UserSettingsMain settings={settings} thirdPartyAuthApps={thirdPartyAuthApps} />
    </Provider>
  );
}

UserSettings.propTypes = {
  settings: PropTypes.oneOfType([PropTypes.object]),
  thirdPartyAuthApps: PropTypes.oneOfType([PropTypes.array]),
};

export default UserSettings;
