import React from 'react';

function Notes() {
  return (
    <div className="disclaimer text-muted small g-mt-40">
      <div className="fw-bold">Notes:</div>
      <ol type="a">
        <li>Consensus Estimates are updated once a day in the evening at around 10:30pm</li>
        <li>
          Consensus Rating is calculated based on the average of all recommendations using the
          following scale:
          <div className="fw-bold">
            Buy: 1 &nbsp;&nbsp;&nbsp;&nbsp;Overweight: 1.5 &nbsp;&nbsp;&nbsp;&nbsp;Hold: 2
            &nbsp;&nbsp;&nbsp;&nbsp;Underweight: 2.5 &nbsp;&nbsp;&nbsp;&nbsp;Sell: 3
          </div>
        </li>
      </ol>
    </div>
  );
}

export default Notes;
