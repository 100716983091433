import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { createLoadableComponent } from '../../utils/Loadable';

import { showStockPopup } from '../../components/StockInfoPopup/stockInfoPopupSlice';

const HEADER = [
  { title: 'Stock Name', field: 'name' },
  { title: 'Last Done', field: 'last_done' },
  { title: 'IPO Price', field: 'ipo_price' },
  { title: '% Change Since IPO', field: 'percent_change_ipo' },
  { title: 'Listing Date', field: 'listing_date' },
  { title: 'Times Subscribed (Public)', field: 'times_subscribed' },
  { title: 'Initial Size (Public)', field: 'initial_public' },
  { title: 'Initial Size (Placement)', field: 'initial_placement' },
];

const StockInfoPopup = createLoadableComponent(() => import('../../components/StockInfoPopup'));

function RecentIPO({ recentIPO }) {
  const dispatch = useDispatch();

  // handle StockInfoPopUp data
  const handleMouseOver = (event) => {
    const currentElement = event.currentTarget;
    const counter = currentElement.getAttribute('data-counter');
    if (!counter) {
      return;
    }

    const offsets = currentElement.getBoundingClientRect();
    const popupContainer = document.getElementById('stock_popup_container');
    if (popupContainer) {
      popupContainer.style.top = `${
        offsets.top + window.pageYOffset - currentElement.ownerDocument.documentElement.clientTop
      }px`;
      popupContainer.style.left = `${offsets.right + 5}px`;
    }

    dispatch(showStockPopup({ counter, isShown: true }));
  };

  const handleMouseOut = () => {
    dispatch(showStockPopup({ isShown: false }));
  };

  return (
    <div>
      <h4>Recent IPO Listings</h4>
      <div className="table-responsive g-mt-30">
        <table className="table table-hover">
          <thead>
            <tr className="table-light align-middle fw-bold table-no-hover">
              {HEADER.map((row) => (
                <th key={row.title} className={row.title.toLowerCase().includes('name') ? "text-start" : "text-end"}>{row.title}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {recentIPO.map((stock) => (
              <tr key={stock.name}>
                {HEADER.map((row, i) => (
                  <td key={i} className={row.field == 'name' ? "text-start" : "text-end"}>
                    {row.field == 'name' ? (
                      <a
                        className="stock_popup_hover"
                        onMouseEnter={handleMouseOver}
                        onMouseLeave={handleMouseOut}
                        data-counter={`${stock.market}:${stock.stock_id}`}
                        href={`/home/redirect?type=factsheet&counter=${stock.market}:${stock.stock_id}`}>
                        {stock[row.field]}
                      </a>
                    ) : (
                      <div>{stock[row.field]}</div>
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <StockInfoPopup />
    </div>
  );
}

export default RecentIPO;

RecentIPO.propTypes = {
  recentIPO: PropTypes.array,
};
