import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Carousel from 'react-bootstrap/Carousel';
import DOMPurify from 'dompurify';

import './ProductCarousel.css';
import { useDetectMobileScreen } from '../../utils/utils';

function isTitleImage(url) {
  const imageFormatsRegex = /\.(gif|png|jpg|jpeg)$/i;
  return imageFormatsRegex.test(url);
}

const MOBILE_WIDTH = 767;
function ProductCarousel({ data }) {
  if (!data || (data && data.length === 0)) {
    return null;
  }
  const isMobile = useDetectMobileScreen(MOBILE_WIDTH);

  return (
    <Carousel
      className="product_carousel mx-auto g-min-width-300 g-max-width-300 g-per-width-60 g-md-per-width-100 g-md-max-per-width-100"
      controls={false}>
      {data.map((slide) => {
        return (
          <Carousel.Item
            style={{
              backgroundImage: `url(${isMobile ? slide.mobile_image_url : slide.image_url})`,
            }}
            key={slide.image_url}
            className={`${slide.classes} g-height-550 g-md-height-400`}>
            <Carousel.Caption className="row justify-content-end align-items-center ">
              <div className="col-12 col-md-6 text-center">
                {slide.content_before_title && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(slide.content_before_title),
                    }}
                  />
                )}
                <div className="g-mb-0 g-md-mb-15">
                  {isTitleImage(slide.title) ? (
                    <img
                      className="img-fluid g-max-per-width-85 g-md-max-width-250 g-md-max-per-width-100 g-mb-20 g-md-mb-0"
                      src={slide.title}
                      alt={slide.title}
                    />
                  ) : (
                    <span className="g-text-size-40 g-md-text-size-50 g-fw-500">{slide.title}</span>
                  )}
                </div>
                {slide.content && (
                  <p className="g-mb-20 g-md-mb-15 g-text-size-16 g-md-text-size-23">
                    {slide.content}
                  </p>
                )}
                <div className="g-mt-0 g-md-mt-10 d-flex justify-content-center gap-2">
                  {slide.button_1_link && (
                    <a
                      href={slide.button_1_link}
                      target="_blank"
                      className={slide.button_1_class || 'btn btn-light'}
                      rel="noreferrer">
                      {slide.button_1_text}
                    </a>
                  )}
                  {slide.button_2_link && (
                    <a
                      href={slide.button_2_link}
                      target="_blank"
                      className="btn btn-dark"
                      rel="noreferrer">
                      {slide.button_2_text}
                    </a>
                  )}
                </div>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
}

ProductCarousel.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      image_url: PropTypes.string,
      title: PropTypes.string,
      content: PropTypes.string,
      classes: PropTypes.string,
      button_1_text: PropTypes.string,
      button_1_link: PropTypes.string,
      button_2_text: PropTypes.string,
      button_2_link: PropTypes.string,
    }),
  ),
};

export default ProductCarousel;
