import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import DOMPurify from 'dompurify';
import styles from './DividendAnalysis.module.css';

export default function DividendPayoutHistoryTable({ data, isREIT, currency }) {
  if (!(data && data.length > 0)) {
    return null;
  }

  const monthsMap = {
    m1: 'Jan',
    m2: 'Feb',
    m3: 'Mar',
    m4: 'Apr',
    m5: 'May',
    m6: 'Jun',
    m7: 'Jul',
    m8: 'Aug',
    m9: 'Sep',
    m10: 'Oct',
    m11: 'Nov',
    m12: 'Dec',
  };

  const divTitle = `${isREIT ? 'Distribution' : 'Dividend'}`;
  const unitTitle = `${isREIT ? 'Unit' : 'Share'}`;
  return (
    <div className="g-pb-30">
      <h4>{`${divTitle} History Table`}</h4>
      <p
        dangerouslySetInnerHTML={{
          __html:
            DOMPurify.sanitize(`${divTitle} Per ${unitTitle} For Each Calendar Year (based on corresponding Financial Periods) in
        ${currency || 'SGD'} (Cents)`),
        }}
      />
      <div className="table-responsive">
        <Table bordered className="text-center">
          <thead className="table-light">
            <tr>
              <th className="g-per-width-8">&nbsp;</th>
              {Object.entries(monthsMap).map(([mKey, mLabel]) => (
                <th key={mKey} className={styles.td_width}>
                  {mLabel}
                </th>
              ))}
              <th className={styles.td_width}>Total</th>
              <th className={styles.td_width}>
                Yield<sup>a</sup>
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((yearData, index) => {
              return (
                <tr key={`${index}_${yearData.year}`}>
                  <td className="text-start">{yearData.year}</td>
                  {/* Loop through the month abbreviations array */}
                  {Object.keys(monthsMap).map((mKey) => {
                    // Check if the month exists in the year data
                    return (
                      <Fragment key={mKey}>
                        {Object.prototype.hasOwnProperty.call(yearData, mKey) ? (
                          <td>
                            {Number(yearData[mKey].pl_special_div_share_f) > 0 ? (
                              <span>
                                {yearData[mKey].pl_div_share_f}
                                <sup>{yearData[mKey].period_notation.toLocaleUpperCase()}</sup>
                                {` ${yearData[mKey].pl_special_div_share_f}`}
                                <sup>{`${yearData[
                                  mKey
                                ].period_notation.toLocaleUpperCase()},SD`}</sup>
                              </span>
                            ) : (
                              <span>
                                {yearData[mKey].pl_div_share_f}
                                <sup>{yearData[mKey].period_notation.toLocaleUpperCase()}</sup>
                              </span>
                            )}
                          </td>
                        ) : (
                          <td className="td-blank">&nbsp;</td>
                        )}
                      </Fragment>
                    );
                  })}
                  <td>
                    {yearData.total.pl_div_share.toFixed(4)}
                    {yearData.total.pl_special_div_share > 0
                      ? ` ${yearData.total.pl_special_div_share.toFixed(4)}`
                      : ''}
                    {yearData.total.pl_special_div_share > 0 && <sup>SD</sup>}
                  </td>
                  <td>{`${yearData.total.yield}${Number(yearData.total.yield) ? '%' : ''}`}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>

      <div className="text-muted g-mb-15">
        <strong>Legend:</strong>
        <br />
        <ul className="list-unstyled list-inline g-ml-20">
          <li className="list-inline-item">
            <sup className="fw-bold">1Q</sup> 1st Quarter
          </li>
          <li className="list-inline-item">
            <sup className="fw-bold">2Q</sup> 2nd Quarter
          </li>
          <li className="list-inline-item">
            <sup className="fw-bold">3Q</sup> 3rd Quarter
          </li>
          <li className="list-inline-item">
            <sup className="fw-bold">4Q</sup> 4th Quarter
          </li>
          <li className="list-inline-item">
            <sup className="fw-bold">1H</sup> 1st Half
          </li>
          <li className="list-inline-item">
            <sup className="fw-bold">2H</sup> 2nd Half
          </li>
          <li className="list-inline-item">
            <sup className="fw-bold">FY</sup> Full Year
          </li>
          <li className="list-inline-item">
            <sup className="fw-bold">SD</sup> Special Dividend
          </li>
          <li className="list-inline-item">
            <sup className="fw-bold">NS</sup> Length of Financial Period is non-standard, where half
            yearly results is not based on 6 months or quarterly results is not based on 3 months.
          </li>
        </ul>
      </div>
      <div className="text-muted g-mb-15">
        <strong>Notes:</strong>
        <br />
        <ol className="list-unstyled g-ml-20">
          <li className="d-flex">
            <span className="g-mr-5">
              <sup>a</sup>
            </span>
            <span>
              {`Yield is calculated using the current market price and is based on the total${divTitle.toLocaleLowerCase()} (including any special ${divTitle.toLocaleLowerCase()}) declared in each calendar year.`}
            </span>
          </li>
        </ol>
      </div>
    </div>
  );
}

DividendPayoutHistoryTable.propTypes = {
  data: PropTypes.array,
  isREIT: PropTypes.bool,
  currency: PropTypes.string,
};
