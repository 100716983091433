import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import { BrowserRouter, Routes, Route, useNavigate, Outlet } from 'react-router-dom';
import {
  setStockCodes,
  setWarrantCodes,
  setCompareType,
  selectScreenType,
  selectStockCodes,
  selectWarrantCodes,
  setStockReadyForInit,
  setWarrantReadyForInit,
} from './stocksComparisonSlice';
import { fetchDefinition } from '../../../components/DefinitionPopup/definitionSlice';
import StockScreen from './Screens/StockScreen';
import WarrantScreen from './Screens/WarrantScreen';

const { CancelToken } = axios;

function StocksComparisonMain({ code = [], type = 'stocks' }) {
  const useTypedDispatch = () => useDispatch();
  const dispatch = useTypedDispatch();
  const source = CancelToken.source();

  /* Set default type and code to redux */
  useEffect(() => {
    dispatch(setCompareType(type));

    const uniqueLowercaseCode = [...new Set(code.map((item) => item.toLowerCase()))];

    const hashCode = uniqueLowercaseCode.reduce(
      (acc, currentValue, index) => ({ ...acc, [index]: currentValue.toLowerCase() }),
      {},
    );

    if (type == 'warrants') {
      dispatch(setWarrantCodes(hashCode));
    } else {
      dispatch(setStockCodes(hashCode));
    }

    dispatch(setStockReadyForInit(true));
    dispatch(setWarrantReadyForInit(true));

    /* Fetch Definition */
    dispatch(fetchDefinition({ cancelToken: source.token, source: 'stocks_comparison' }));
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/stocks_comparison/" element={<Layout />}>
          <Route index element={<StockScreen />} />
          <Route path="warrants" element={<WarrantScreen />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

function Layout() {
  return (
    <>
      <Header />
      <Outlet />
    </>
  );
}

function Header() {
  const useTypedDispatch = () => useDispatch();
  const dispatch = useTypedDispatch();

  const screenType = useSelector(selectScreenType);
  const warrantCodes = useSelector(selectWarrantCodes);
  const stockCodes = useSelector(selectStockCodes);

  const navigate = useNavigate();
  const handleCompareSelect = (type) => {
    /* Set state */
    dispatch(setCompareType(type));

    /* Navigate with param */
    if (type == 'warrants') {
      const queryString = Object.entries(warrantCodes)
        .map(([key, code]) => `code=${encodeURIComponent(code.toLowerCase())}`)
        .join('&');
      navigate(`/stocks_comparison/warrants${queryString ? `?${queryString}` : ''}`);
    } else {
      const queryString = Object.entries(stockCodes)
        .map(([key, code]) => `code=${encodeURIComponent(code.toLowerCase())}`)
        .join('&');
      navigate(`/stocks_comparison${queryString ? `?${queryString}` : ''}`);
    }
  };

  return (
    <>
      <div className="row align-items-center g-lg-mb-30 g-mb-20">
        <div className="col-lg-8">
          <h3 className="g-mb-5 g-lg-mb-0">Stock Comparison</h3>
        </div>
        <div className="col-lg-4 d-flex justify-content-lg-end justify-content-start" />
      </div>
      <div className="divider-h w-100 g-mb-20 g-lg-mb-30" />
      <div className="g-mb-30">
        <button
          type="button"
          className={`btn btn-lg btn-${screenType == 'stocks' ? 'dark' : 'light'} g-mr-12`}
          onClick={() => handleCompareSelect('stocks')}
          id="button_stocks">
          Compare Stocks
        </button>
        <button
          type="button"
          className={`btn btn-lg btn-${screenType == 'warrants' ? 'dark' : 'light'}`}
          onClick={() => handleCompareSelect('warrants')}
          id="button_warrants">
          Compare Warrants
        </button>
      </div>
    </>
  );
}

StocksComparisonMain.propTypes = {
  code: PropTypes.arrayOf(PropTypes.string),
  type: PropTypes.string,
};

export default StocksComparisonMain;
