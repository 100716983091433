import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Tab, Nav } from 'react-bootstrap';

import AlertSettingList from './AlertSettingList';
import AlertHistoryList from './AlertHistoryList';
import AlertListActions from './AlertListActions';
import styles from './alert.module.css';

const ALERT_TABS = {
  setting: 'Alert Settings',
  history: 'Alert History',
};
function StockNewsAlertsDropdownMenu({
  showAlertFormModal,
  setShowAlertFormModal,
  activeTab,
  setActiveTab,
}) {
  const alertSettings = useSelector((state) => state.stockNewsAlerts.alertSettings);
  const alertHistories = useSelector((state) => state.stockNewsAlerts.alertHistories);
  const newHistoriesCount = useSelector((state) => state.stockNewsAlerts.newHistoriesCount);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleShowAlertFormModal = ({ action, alertID }) => {
    setShowAlertFormModal({ show: !showAlertFormModal.show, action, alertID });
  };

  return (
    <div>
      {/* NEW SETTING BUTTON */}
      {/* SEARCH BUTTON */}
      <Tab.Container activeKey={activeTab}>
        <Nav className="nav-tabs design-2">
          {Object.keys(ALERT_TABS).map((tab) => {
            return (
              <Nav.Item key={tab}>
                <Nav.Link
                  className={`nav-link g-p-12 px-3 ${activeTab === tab ? 'active' : ''}`}
                  eventKey={tab}
                  id={tab}
                  onClick={() => handleTabClick(tab)}>
                  <span className="me-2">{ALERT_TABS[tab]}</span>
                  {tab === 'history' && parseInt(newHistoriesCount) > 0 && (
                    <span className="ms-2 top-0 start-100 translate-middle badge rounded-pill bg-danger">
                      {newHistoriesCount}
                    </span>
                  )}
                </Nav.Link>
              </Nav.Item>
            );
          })}
          <div className="ms-auto align-self-center me-4 d-flex gap-3">
            <button
              type="button"
              aria-label="add"
              className="btn-rounded align-middle p-0 d-inline-block g-min-width-auto btn btn-primary g-width-25 g-height-25 g-text-size-12"
              onClick={() => handleShowAlertFormModal({ action: 'create' })}>
              <i className="fa-sharp fa-plus " />
            </button>
            {/* Hide search for now as this is a new feature */}
            {/* <button
              type="button"
              aria-label="search"
              className="btn-rounded align-middle p-0 d-inline-block g-min-width-auto btn btn-light g-width-25 g-height-25 g-text-size-12">
              <i className="fa-regular fa-magnifying-glass" />
            </button> */}
          </div>
        </Nav>
        <Tab.Content>
          {Object.keys(ALERT_TABS).map((tab) => (
            <Tab.Pane key={tab} eventKey={tab}>
              <AlertListActions tab={tab} />
              <div
                className={`${styles.alertList} g-width-400 g-lg-width-450 overflow-x-hidden overflow-y-scroll styled-scrollbar mb-3`}>
                {tab === 'setting' ? (
                  <AlertSettingList
                    alerts={alertSettings}
                    handleShowAlertFormModal={handleShowAlertFormModal}
                  />
                ) : (
                  <AlertHistoryList alerts={alertHistories} />
                )}
              </div>
            </Tab.Pane>
          ))}
        </Tab.Content>
      </Tab.Container>
    </div>
  );
}

export default StockNewsAlertsDropdownMenu;

StockNewsAlertsDropdownMenu.propTypes = {
  showAlertFormModal: PropTypes.oneOfType([PropTypes.object]),
  setShowAlertFormModal: PropTypes.func,
  activeTab: PropTypes.string,
  setActiveTab: PropTypes.func,
};
