import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './rangeSlider.module.css';

function RangeSlider({
  minTitle = 'Min',
  maxTitle = 'Max',
  minValue = 1,
  maxValue = 3,
  fromVal = 1,
  toVal = 1.76,
  minInputName = 'minInput',
  maxInputName = 'maxInput',
  step = 0.02,
  handleChange,
}) {
  const [fromValue, setFromValue] = useState(fromVal);
  const [toValue, setToValue] = useState(toVal);

  const fromSliderRef = useRef(null);
  const toSliderRef = useRef(null);

  useEffect(() => {
    const fromSlider = fromSliderRef.current;
    const toSlider = toSliderRef.current;

    fillSlider(fromSlider, toSlider, '#C6C6C6', '#25daa5');
  }, [fromValue, toValue]);

  useEffect(() => {
    setFromValue(fromVal);
    setToValue(toVal);
  }, [fromVal, toVal]);

  const handleFromSliderChange = (e) => {
    const value = parseFloat(e.target.value);
    const toSlider = toSliderRef.current;
    if (value <= toSlider.value) {
      setFromValue(value);
      handleChange(e);
    }
  };

  const handleToSliderChange = (e) => {
    const value = parseFloat(e.target.value);
    const fromSlider = fromSliderRef.current;
    if (value >= fromSlider.value) {
      setToValue(value);
      handleChange(e);
    }
  };

  const fillSlider = (from, to, sliderColor, rangeColor) => {
    const rangeDistance = to.max - to.min;
    const fromPosition = from.value - to.min;
    const toPosition = to.value - to.min;
    to.style.background = `linear-gradient(
      to right,
      ${sliderColor} 0%,
      ${sliderColor} ${(fromPosition / rangeDistance) * 100}%,
      ${rangeColor} ${(fromPosition / rangeDistance) * 100}%,
      ${rangeColor} ${(toPosition / rangeDistance) * 100}%,
      ${sliderColor} ${(toPosition / rangeDistance) * 100}%,
      ${sliderColor} 100%)`;
  };

  return (
    <div className={`slider ${styles.range_container}`}>
      <div className={styles.sliders_control}>
        <input
          ref={fromSliderRef}
          type="range"
          className={styles.fromSlider}
          min={minValue}
          max={maxValue}
          step={step}
          value={fromValue}
          name={minInputName}
          onChange={handleFromSliderChange}
        />
        <input
          ref={toSliderRef}
          type="range"
          className={styles.toSlider}
          min={minValue}
          max={maxValue}
          step={step}
          value={toValue}
          name={maxInputName}
          onChange={handleToSliderChange}
        />
      </div>
      <div className={styles.form_control}>
        <div className="text-center">
          <div>{minTitle}</div>
          <div className={styles.value_text}>{fromValue}</div>
        </div>
        <div className="text-center">
          <div>{maxTitle}</div>
          <div className={styles.value_text}>{toValue}</div>
        </div>
      </div>
      <input type="hidden" name="operator" value="BT" />
      <input type="hidden" name={minInputName} value={fromValue} />
      <input type="hidden" name={maxInputName} value={toValue} />
    </div>
  );
}

RangeSlider.propTypes = {
  minTitle: PropTypes.string,
  maxTitle: PropTypes.string,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  step: PropTypes.number,
  handleChange: PropTypes.func,
  minInputName: PropTypes.string,
  maxInputName: PropTypes.string,
  fromVal: PropTypes.number,
  toVal: PropTypes.number,
};

export default RangeSlider;
