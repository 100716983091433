/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import DOMPurify from 'dompurify';

import RemarksSgx from './RemarksSgx';
import RemarksBursa from './RemarksBursa';
import { getParamCounterId } from '../../utils/utils';
import CompanyLogoImage from '../CompanyLogoImage';

async function stopStreaming() {
  const { stopQuoteStreaming } = await import('../../utils/Streaming/counter_quote_table');
  stopQuoteStreaming();
}

async function startStreaming(counter, delay) {
  const { startQuoteStreamingForCounter } = await import(
    '../../utils/Streaming/counter_quote_table'
  );
  startQuoteStreamingForCounter(counter, delay);
}

export default function QuotePriceSummary({
  counter,
  priceSummary,
  isDuringStreamingHours,
  isDelisted,
}) {
  if (!(counter && priceSummary)) {
    return null;
  }

  const renderOverlay = (counter) => {
    return (
      <div
        className="g-max-width-290 g-sm-max-width-500 p-3 g-border-radius-10 border bg-white"
        style={{ zIndex: '9999' }}>
        <div className=" g-max-height-300 overflow-x-hidden overflow-y-scroll">
          {counter.endsWith('.SI') && <RemarksSgx />}
          {counter.endsWith('.MY') && <RemarksBursa />}
        </div>
      </div>
    );
  };

  useEffect(() => {
    const isDocTypeUndef = typeof document === 'undefined';
    const counterQuoteDiv = document.getElementById('sic_counterQuoteInfo');
    if (counterQuoteDiv && isDuringStreamingHours && !isDocTypeUndef) {
      startStreaming(counter, priceSummary.delay);
    }
    return () => {
      stopStreaming();
    };
  }, []);

  return (
    <div id="sic_counterQuoteInfo" className="row g-mb-60 gy-4">
      <div className="col-lg-7">
        <div className="row gy-4">
          <div className="col-md-5">
            <div className="">
              <div className="float-start">
                <div className="stock_info_logo_wrapper g-width-90 g-height-90 g-mb-5 float-start g-mr-10">
                  <CompanyLogoImage counter={counter} classes="g-border-radius-5 border p-1" />
                </div>
              </div>
              <div className="g-ml-10">
                <a
                  className="h2 g-fw-500 text-dark text-decoration-none"
                  href={`/home/redirect?type=factsheet&counter=${counter}`}>
                  <span>{priceSummary.short_name}</span>
                  <span className="g-text-size-14 text-muted g-ml-5">
                    {getParamCounterId(counter)}
                  </span>
                </a>

                {isDelisted && (
                  <span className="g-ml-10 badge badge-delisted align-middle">DELISTED</span>
                )}

                <div>
                  <div
                    id="sic_counterQuote_full_name"
                    className="g-text-size-14 text-muted text-uppercase">
                    {priceSummary.full_name}
                  </div>
                  <div id="sic_counterQuote_remarks" className="text-muted g-mt-0">
                    {`Remarks: ${priceSummary.remarks}`}
                    {priceSummary.remarks &&
                      priceSummary.remarks !== '-' &&
                      (counter.endsWith('.SI') || counter.endsWith('.MY')) && (
                        <OverlayTrigger
                          overlay={renderOverlay(counter)}
                          trigger={['click']}
                          rootClose
                          placement="auto-end">
                          <button className="g-ml-5 g-min-width-0 bg-transparent border-0 p-0">
                            {/* the following <svg> tag was derived from the one generated from FontAwesome. */}
                            {/* using <i> tag for FontAwesome icons becomes problematic with SSR. */}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="fa-solid fa-circle-question fa-sm text-dark"
                              role="button"
                              data-prefix="fas"
                              data-icon="circle-question"
                              height="1em"
                              viewBox="0 0 512 512">
                              <path
                                fill="currentColor"
                                d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM169.8 165.3c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V250.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H222.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"
                              />
                            </svg>
                          </button>
                        </OverlayTrigger>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-7">
            <span id="sic_counterQuote_lastdone" className="h2 g-mr-5 g-fw-500">
              {priceSummary.last_done_price_f}
            </span>
            {priceSummary.currency && (
              <span
                id="sic_counterQuote_currency"
                className="g-fw-500 g-font-size-14 g-mr-5"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(priceSummary.currency),
                }}
              />
            )}
            <span
              id="sic_counterQuote_chg_perc_change"
              className={`h5 ${
                priceSummary.percent_change_f || parseFloat(priceSummary.percent_change_f) != 0
                  ? parseFloat(priceSummary.percent_change_f) > 0
                    ? 'text-success'
                    : 'text-danger'
                  : ''
              }`}>
              {`${priceSummary.change_f} (${priceSummary.percent_change_f}%)`}
            </span>
            <div id="sic_counterQuote_last_updated" className="text-muted">
              {priceSummary.last_updated_at}
            </div>
            <div className="row g-mt-30 gx-lg-3 gx-2">
              <div className="col-3">
                <div className="g-border-1 g-border-r-1 ">
                  <div className="g-mb-10">High</div>
                  <div id="sic_counterQuote_high" className="g-fw-500">
                    {priceSummary.high_f}
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="g-border-1 g-border-r-1 ">
                  <div className="g-mb-10">Low</div>
                  <div id="sic_counterQuote_low" className="g-fw-500">
                    {priceSummary.low_f}
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="g-border-1 g-border-r-1 ">
                  <div className="g-mb-10">Open</div>
                  <div id="sic_counterQuote_open" className="g-fw-500">
                    {priceSummary.open_price_f}
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div>
                  <div className="g-mb-10">{`Yesterday's Close`}</div>
                  <div id="sic_counterQuote_yesterday_close" className="g-fw-500">
                    {priceSummary.yesterday_price_f}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-5">
        <div className="row gx-lg-3 gx-2">
          <div className="col-6">
            <table className="table table-borderless table-sm">
              <tbody>
                <tr>
                  <td>Buy Price</td>
                  <td id="sic_counterQuote_buy_price" className="g-fw-500">
                    {priceSummary.buy_f}
                  </td>
                </tr>
                <tr>
                  <td>Sell Price</td>
                  <td id="sic_counterQuote_sell_price" className="g-fw-500">
                    {priceSummary.sell_f}
                  </td>
                </tr>
                <tr>
                  <td>{`Buy Volume (${priceSummary.volume_units_text})`}</td>
                  <td id="sic_counterQuote_buy_volume" className="g-fw-500">
                    {priceSummary.buy_volume_f}
                  </td>
                </tr>
                <tr>
                  <td>{`Sell Volume (${priceSummary.volume_units_text})`}</td>
                  <td id="sic_counterQuote_sell_volume" className="g-fw-500">
                    {priceSummary.sell_volume_f}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-6">
            <table className="table table-borderless table-sm">
              <tbody>
                <tr>
                  <td>{`Cumulative Volume (${priceSummary.volume_units_text})`}</td>
                  <td id="sic_counterQuote_total_volume" className="g-fw-500">
                    {priceSummary.cumulative_volume_f}
                  </td>
                </tr>
                <tr>
                  <td>Cumulative Value</td>
                  <td id="sic_counterQuote_total_value" className="g-fw-500">
                    {priceSummary.cumulative_value_f}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
