import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import pricesStreamingMode from '../components/PricesStreamingButton/pricesStreamingModeSlice';
import worldIndicesPrices from '../components/WorldIndicesPrices/worldIndicesPricesSlice';
import stockPrices from '../pages/StockPrices/stockPricesSlice';
import marketsConsensusEstimates from '../pages/Markets/ConsensusEstimates/consensusEstimatesSlice';
import marketsStocksComparison from '../pages/Markets/StocksComparison/stocksComparisonSlice';
import marketsCompanyBuybackOverview from '../pages/Markets/CompanyBuyback/BuybackOverview/companyBuybackOverviewSlice';
import marketsCompanyBuybackLatest from '../pages/Markets/CompanyBuyback/BuybackLatest/companyBuybackLatestSlice';
import ownership from '../pages/Markets/SignificantShareholders/ownershipSlice';
import analysisTools from '../components/AnalysisToolsButton/analysisToolsSlice';
import selectedMarket from '../components/MarketSelectDropdown/selectedMarketSlice';
import dividendAnalysis from '../pages/Factsheet/DividendAnalysis/dividendAnalysisSlice';
import insiderTrades from '../components/InsiderTrades/insiderTradesSlice';
import factsheetOverview from '../pages/Factsheet/Overview/factsheetSlice';
import home from '../pages/Home/homeSlice';
import consensusEstimates from '../pages/Factsheet/ConsensusEstimates/consensusEstimatesSlice';
import newsInfiniteScroll from '../components/News/NewsInfiniteScroll/newsInfiniteScrollSlice';
import financials from '../pages/Factsheet/Financials/financialsSlice';
import watchlist from '../pages/Watchlist/watchlistSlice';
import portfolio from '../pages/Portfolio/portfolioSlice';
import transaction from '../pages/Portfolio/Analytics/Transactions/transactionSlice';
import portfolioBroker from '../pages/Portfolio/Analytics/Transactions/portfolioBrokerSlice';
import portfolioDividend from '../pages/Portfolio/Analytics/DividendAnalysis/portfolioDividendSlice';
import portfolioInstruments from '../pages/Portfolio/Analytics/Positions/portfolioInstrumentsSlice';
import stockInfoPopup from '../components/StockInfoPopup/stockInfoPopupSlice';
import priceTable from '../components/PriceTable/priceTableSlice';
import intrinsicValueAnalysis from '../pages/Screener/IntrinsicValueAnalysis/intrinsicValueAnalysisSlice';
import marketScreener from '../pages/Screener/MarketScreener/marketScreenerSlice';
import predefinedTAScreens from '../pages/Screener/PredefinedTAScreens/predefinedTAScreensSlice';
import priceDownload from '../pages/Markets/PriceDownload/priceDownloadSlice';
import ipo from '../pages/Ipo/ipoSlice';
import membership from '../pages/Membership/membershipSlice';
import signUp from '../components/SignUp/signUpSlice';
import brokersCall from '../pages/Markets/BrokersCall/brokersCallSlice.tsx';
import definition from '../components/DefinitionPopup/definitionSlice';
import userSettings from '../pages/User/UserSettings/userSettingsSlice';
import {
  membershipProfileReducer as membershipProfile,
  membershipPlanReducer as membershipPlan,
  paymentHistoriesReducer as paymentHistories,
} from '../pages/Membership/Corner/membershipSlice';
import stockNewsAlerts from '../components/StockNewsAlerts/stockNewsAlertsSlice';

const reducer = combineReducers({
  pricesStreamingMode,
  selectedMarket,
  worldIndicesPrices,
  stockPrices,
  marketsConsensusEstimates,
  marketsStocksComparison,
  marketsCompanyBuybackOverview,
  marketsCompanyBuybackLatest,
  analysisTools,
  dividendAnalysis,
  insiderTrades,
  factsheetOverview,
  home,
  consensusEstimates,
  newsInfiniteScroll,
  financials,
  watchlist,
  portfolio,
  transaction,
  portfolioBroker,
  portfolioDividend,
  stockInfoPopup,
  portfolioInstruments,
  priceTable,
  intrinsicValueAnalysis,
  marketScreener,
  priceDownload,
  ipo,
  ownership,
  membership,
  signUp,
  brokersCall,
  definition,
  userSettings,
  membershipProfile,
  membershipPlan,
  paymentHistories,
  stockNewsAlerts,
  predefinedTAScreens,
});

const store = configureStore({
  reducer,
});

export default store;
