import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  createEntityAdapter,
} from '@reduxjs/toolkit';

import api from '../../../utils/api.js';
import { Constants, COUNT_PER_PAGE } from '../../../utils/constants.js';
import { BrokersCallPageState, BrokerCall, FilteredResult } from './brokersCallInterfaces';

import store from '../../../utils/store';
import { CancelToken } from 'axios';

export const fetchBrokersCall = createAsyncThunk<
  Array<BrokerCall>,
  { dataUrl: string; cancelToken: CancelToken }
>('brokersCall/fetchBrokersCall', async ({ dataUrl, cancelToken }): Promise<Array<BrokerCall>> => {
  const response = await api.get(dataUrl, { cancelToken, responseType: 'json' });
  const responseData = response.data;
  return responseData;
});

const selectId = (call) => call.originalData.arrayOfIds.find((id) => id);

const brokersCallAdapter = createEntityAdapter<BrokersCallPageState>({
  selectId: selectId,
});

const initialState: BrokersCallPageState = brokersCallAdapter.getInitialState({
  displayData: [],
  originalData: [],
  filterCounter: '',
  filterIndustry: '',
  filterCall: '',
  fromDate: '',
  toDate: '',
  pagination: {
    rowOffset: 0,
    currentPageRows: 0,
    totalRows: 0,
    currentPage: 1,
    totalPages: 0,
    classes: '',
  },
  status: Constants.STATUS_IDLE,
  error: undefined,
});

const brokersCallSlice = createSlice({
  name: 'brokersCall',
  initialState: initialState,
  reducers: {
    setFilterCounter: (state, action: PayloadAction<string>) => {
      state.filterCounter = action.payload;
      const pagination = { ...state.pagination, currentPage: 1, rowOffset: 0 };
      const filteredResult = getResult({
        ...state,
        pagination: pagination,
        filterCounter: action.payload,
      });
      state.pagination = filteredResult.pagination;
      state.displayData = filteredResult.brokerCalls;
    },
    setFilterIndustry: (state, action: PayloadAction<string>) => {
      state.filterIndustry = action.payload;
      const pagination = { ...state.pagination, currentPage: 1, rowOffset: 0 };
      const filteredResult = getResult({
        ...state,
        pagination: pagination,
        filterIndustry: action.payload,
      });
      state.pagination = filteredResult.pagination;
      state.displayData = filteredResult.brokerCalls;
    },
    setFilterCall: (state, action: PayloadAction<string>) => {
      state.filterCall = action.payload;
      const pagination = { ...state.pagination, currentPage: 1, rowOffset: 0 };
      const filteredResult = getResult({
        ...state,
        pagination: pagination,
        filterCall: action.payload,
      });
      state.pagination = filteredResult.pagination;
      state.displayData = filteredResult.brokerCalls;
    },
    setFromDate: (state, action: PayloadAction<string>) => {
      state.fromDate = action.payload;
      const pagination = { ...state.pagination, currentPage: 1, rowOffset: 0 };
      const filteredResult = getResult({
        ...state,
        pagination: pagination,
        fromDate: action.payload,
      });
      state.pagination = filteredResult.pagination;
      state.displayData = filteredResult.brokerCalls;
    },
    setToDate: (state, action: PayloadAction<string>) => {
      state.toDate = action.payload;
      const pagination = { ...state.pagination, currentPage: 1, rowOffset: 0 };
      const filteredResult = getResult({
        ...state,
        pagination: pagination,
        toDate: action.payload,
      });
      state.pagination = filteredResult.pagination;
      state.displayData = filteredResult.brokerCalls;
    },
    changePage: (state, action: PayloadAction<number>) => {
      /* Check if exceed max page */
      /* Check if below 1 */
      var pageNo = action.payload;
      var pagination = { ...state.pagination };
      if (pageNo < 1) {
        pageNo = 1;
      }
      if (pageNo > state.pagination.totalPages) {
        pageNo = state.pagination.totalPages;
      }

      const rowOffset = (pageNo - 1) * COUNT_PER_PAGE;
      pagination.currentPage = pageNo;
      pagination.rowOffset = rowOffset;

      const filteredResult = getResult({ ...state, pagination: pagination });

      state.pagination = filteredResult.pagination;
      state.displayData = filteredResult.brokerCalls;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBrokersCall.pending, (state) => {
      state.status = Constants.STATUS_LOADING;
    });
    builder.addCase(fetchBrokersCall.fulfilled, (state, action) => {
      state.status = Constants.STATUS_SUCCESS;
      state.originalData = action.payload.sort((a, b) => Number(b.call_date) - Number(a.call_date));

      /* Set pagination for first load */
      const pagination = { ...state.pagination, currentPage: 1, rowOffset: 0 };
      const filteredResult = getResult({
        ...state,
        pagination: pagination,
      });

      state.pagination = filteredResult.pagination;
      state.displayData = filteredResult.brokerCalls;
    });
    builder.addCase(fetchBrokersCall.rejected, (state, action) => {
      state.status = Constants.STATUS_FAILED;
      state.error = action.error.message;
    });
  },
});

const getResult = (state: BrokersCallPageState): FilteredResult => {
  /* Filter Counter */
  var originalData = [...state.originalData];

  if (state.filterCounter) {
    originalData = originalData.filter((item) => item.code == state.filterCounter);
  }

  /* Filter Industry */
  if (state.filterIndustry) {
    originalData = originalData.filter((item) => item.industry == state.filterIndustry);
  }

  /* Filter Call */
  if (state.filterCall) {
    originalData = originalData.filter((item) => item.call_id == state.filterCall);
  }

  /* Filter Date Range */
  if (state.fromDate) {
    const fromDate = new Date(state.fromDate);
    if (!isNaN(fromDate.getTime())) {
      /* Convert to call_date to date object */
      originalData = originalData.filter((item) => {
        const itemTimestamp = parseInt(item.call_date, 10);
        if (!isNaN(itemTimestamp)) {
          return new Date(itemTimestamp * 1000) >= fromDate;
        } else {
          return false;
        }
      });
    }
  }

  if (state.toDate) {
    const toDate = new Date(state.toDate);
    if (!isNaN(toDate.getTime())) {
      /* Convert to call_date to date object */
      originalData = originalData.filter((item) => {
        const itemTimestamp = parseInt(item.call_date, 10);
        if (!isNaN(itemTimestamp)) {
          return new Date(itemTimestamp * 1000) <= toDate;
        } else {
          return false;
        }
      });
    }
  }

  const pagination = { ...state.pagination };
  pagination.totalRows = originalData.length;
  pagination.totalPages = Math.ceil(originalData.length / COUNT_PER_PAGE);

  if (pagination.rowOffset + COUNT_PER_PAGE > pagination.totalRows) {
    pagination.currentPageRows = pagination.totalRows - pagination.rowOffset;
  } else {
    pagination.currentPageRows = COUNT_PER_PAGE;
  }

  /* Filter by pagination. Update currentPageRows */
  originalData = originalData.slice(
    state.pagination.rowOffset,
    state.pagination.rowOffset + COUNT_PER_PAGE,
  );

  return { brokerCalls: originalData, pagination: pagination };
};

type RootState = ReturnType<typeof store.getState>;

export const {
  setFilterCounter,
  setFilterIndustry,
  setFilterCall,
  setFromDate,
  setToDate,
  changePage,
} = brokersCallSlice.actions;

export const selectStatus = (state: RootState) => state.brokersCall.status;
export const selectFilterCounter = (state: RootState) => state.brokersCall.filterCounter;
export const selectFilterIndustry = (state: RootState) => state.brokersCall.filterIndustry;
export const selectFilterCall = (state: RootState) => state.brokersCall.filterCall;
export const selectFilterFromDate = (state: RootState) => state.brokersCall.fromDate;
export const selectFilterToDate = (state: RootState) => state.brokersCall.toDate;
export const selectDisplayData = (state: RootState) => state.brokersCall.displayData;
export const selectOriginalData = (state: RootState) => state.brokersCall.originalData;
export const selectPagination = (state: RootState) => state.brokersCall.pagination;

export default brokersCallSlice.reducer;
