import React from 'react';
import axios from 'axios';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { revokeConnectedApp } from './userSettingsSlice';

const { CancelToken } = axios;
function SettingsConnectedApps({ connectedApps }) {
  const dispatch = useDispatch();
  const source = CancelToken.source();
  const handleClickRevoke = (appID) => {
    dispatch(revokeConnectedApp({ appID, cancelToken: source.token }));
  };
  return (
    <Card className="g-mb-30">
      <Card.Body>
        <Card.Title className="h5">Connected Apps</Card.Title>
        {connectedApps && connectedApps.length > 0 ? (
          <>
            <p>Third-party applications connecting to your profile:</p>
            {connectedApps.map((app, index) => {
              return (
                <>
                  <div className="row g-3 justify-content-between align-items-center">
                    <div className="col bg-gray">{app.name}</div>
                    <div className="col-auto">
                      <Button variant="light" onClick={() => handleClickRevoke(app.id)}>
                        Revoke
                      </Button>
                    </div>
                  </div>
                  {index !== connectedApps.length - 1 && <hr />}
                </>
              );
            })}
          </>
        ) : (
          <p>There are no authorized third-party applications connecting to your profile.</p>
        )}
      </Card.Body>
    </Card>
  );
}

SettingsConnectedApps.propTypes = {
  connectedApps: PropTypes.oneOfType([PropTypes.array]),
};

export default SettingsConnectedApps;
