import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import IntrinsicValue from './IntrinsicValue';
import MarginOfSafety from './MarginOfSafety';

import CompanyNameLink from '../../../components/StockInfoPopup/CompanyNameLink';
import { createLoadableComponent } from '../../../utils/Loadable';

const StockInfoPopup = createLoadableComponent(() => import('../../../components/StockInfoPopup'));

function PeerAnalysisTable({ selectedModel, tableData }) {
  if (!tableData) {
    return <div className="text-danger text-center">Data not found.</div>;
  }

  const DEFAULT_PEERS_NO = 10;
  const [showAllPeers, setShowAllPeers] = useState(false);
  const [showAllPeersLocal, setShowAllPeersLocal] = useState(false);
  const displayedPeers =
    !tableData.other_global_peers || showAllPeers
      ? tableData.other_global_peers
      : tableData.other_global_peers.slice(0, DEFAULT_PEERS_NO);
  const displayedPeersLocal =
    !tableData.other_local_peers || showAllPeersLocal
      ? tableData.other_local_peers
      : tableData.other_local_peers.slice(0, DEFAULT_PEERS_NO);

  return (
    <div>
      <div className="g-pb-30">
        <div className="table-responsive g-max-height-500 g-lg-max-height-800">
          <Table bordered hover size="sm">
            <thead className="table-light">
              <tr>
                <th>Company Name</th>
                <th>
                  Market Cap (M) <sup>a</sup>
                </th>
                <th>Last Done</th>
                <th>Piotroski F Score</th>
                <th className={selectedModel == 'discounted_earning' ? 'd-table-cell' : 'd-none'}>
                  Earnings Per Share
                </th>
                <th className={selectedModel == 'discounted_cashflow' ? 'd-table-cell' : 'd-none'}>
                  Free Cash Flow Per Share
                </th>
                <th>Intrinsic Value</th>
                <th>Margin of Safety</th>
              </tr>
            </thead>
            <tbody>
              {tableData.reference &&
                tableData.reference.length > 0 &&
                tableData.reference.map((item) => (
                  <PeerTableRow key={item.counter} item={item} selectedModel={selectedModel} />
                ))}

              {tableData.local_peers &&
                tableData.local_peers.length > 0 &&
                tableData.local_peers.map((item) => (
                  <PeerTableRow key={item.counter} item={item} selectedModel={selectedModel} />
                ))}

              {tableData.other_local_peers && tableData.other_local_peers.length > 0 && (
                <tr>
                  <td>Other Local Peers</td>
                  <td colSpan={7}>
                    {displayedPeersLocal.map((item) =>
                      item.link ? (
                        <a key={item.counter} href={`${item.link}`}>
                          <span className="badge badge-outlined peer-badge me-2 mb-2">
                            {item.name} ({item.market})
                          </span>
                        </a>
                      ) : (
                        <span
                          key={item.counter}
                          className="badge badge-outlined peer-badge me-2 mb-2">
                          {item.name} ({item.market})
                        </span>
                      ),
                    )}
                    {tableData.other_local_peers.length > DEFAULT_PEERS_NO && (
                      <button
                        type="button"
                        className="btn btn-dark btn-sm d-block"
                        onClick={() => setShowAllPeersLocal(!showAllPeersLocal)}>
                        {showAllPeersLocal ? 'Show Less' : 'Show All'}
                      </button>
                    )}
                  </td>
                </tr>
              )}

              {tableData.global_peers &&
                tableData.global_peers.length > 0 &&
                tableData.global_peers.map((item) => (
                  <PeerTableRow key={item.counter} item={item} selectedModel={selectedModel} />
                ))}

              {tableData.other_global_peers && tableData.other_global_peers.length > 0 && (
                <tr>
                  <td>Other Global Peers</td>
                  <td colSpan={7}>
                    {displayedPeers.map((item) =>
                      item.link ? (
                        <a key={item.counter} href={`${item.link}`}>
                          <span className="badge badge-outlined peer-badge me-2 mb-2">
                            {item.name} ({item.market})
                          </span>
                        </a>
                      ) : (
                        <span
                          key={item.counter}
                          className="badge badge-outlined peer-badge me-2 mb-2">
                          {item.name} ({item.market})
                        </span>
                      ),
                    )}
                    {tableData.other_global_peers.length > DEFAULT_PEERS_NO && (
                      <button
                        type="button"
                        className="btn btn-dark btn-sm d-block"
                        onClick={() => setShowAllPeers(!showAllPeers)}>
                        {showAllPeers ? 'Show Less' : 'Show All'}
                      </button>
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
        <StockInfoPopup />
      </div>
    </div>
  );
}

function PeerTableRow({ selectedModel, item }) {
  return (
    <tr>
      <td>
        <div>
          <CompanyNameLink counter={item.counter} name={item.name} showStockInfoPopUp />
        </div>
        {item.type}: {item.market}
      </td>
      <td>{item.market_cap}</td>
      <td>{item.last_done}</td>
      <td>{item.piotroski_f_score}</td>
      <td className={selectedModel == 'discounted_earning' ? 'd-table-cell' : 'd-none'}>
        {item.ratio_eps}
      </td>
      <td className={selectedModel == 'discounted_cashflow' ? 'd-table-cell' : 'd-none'}>
        {item.ratio_fcf_per_share}
      </td>
      <td>
        <IntrinsicValue data={item.intrinsic_value} type="peer_analysis" />
      </td>
      <MarginOfSafety
        data={item.margin_of_safety}
        statement={item.statement}
        type="peer_analysis"
      />
    </tr>
  );
}

PeerAnalysisTable.propTypes = {
  tableData: PropTypes.shape({
    reference: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        name: PropTypes.string,
        market: PropTypes.string,
        market_cap: PropTypes.string,
        last_done: PropTypes.string,
        piotroski_f_score: PropTypes.string,
        ratio_eps: PropTypes.string,
        ratio_fcf_per_share: PropTypes.string,
      }),
    ),
    local_peers: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        name: PropTypes.string,
        market: PropTypes.string,
        market_cap: PropTypes.string,
        last_done: PropTypes.string,
        piotroski_f_score: PropTypes.string,
        ratio_eps: PropTypes.string,
        ratio_fcf_per_share: PropTypes.string,
      }),
    ),
    other_local_peers: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        name: PropTypes.string,
        market: PropTypes.string,
        market_cap: PropTypes.string,
        last_done: PropTypes.string,
        piotroski_f_score: PropTypes.string,
        ratio_eps: PropTypes.string,
        ratio_fcf_per_share: PropTypes.string,
      }),
    ),
    global_peers: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        name: PropTypes.string,
        market: PropTypes.string,
        market_cap: PropTypes.string,
        last_done: PropTypes.string,
        piotroski_f_score: PropTypes.string,
        ratio_eps: PropTypes.string,
        ratio_fcf_per_share: PropTypes.string,
      }),
    ),
    other_global_peers: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        name: PropTypes.string,
        market: PropTypes.string,
        market_cap: PropTypes.string,
        last_done: PropTypes.string,
        piotroski_f_score: PropTypes.string,
        ratio_eps: PropTypes.string,
        ratio_fcf_per_share: PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default PeerAnalysisTable;
