import React, { useState } from 'react';
import PropTypes from 'prop-types';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';

import styles from './AddCSVTransaction.module.css';
import { importCsvFetchData } from './transactionSlice';

const { CancelToken } = axios;
const source = CancelToken.source();

function AddCSVTransaction() {
  const selectedPortfolio = useSelector((state) => state.portfolio.selected);
  const loading = useSelector((state) => state.transaction.loading);
  const [file, setFile] = useState();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState('');

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile.name.endsWith('.csv')) {
        setFile(selectedFile);
        setErrorMessage('');
      } else {
        setFile(null);
        setErrorMessage('Please select a file with .csv extension.');
      }
    }
  };

  const clearFile = () => {
    setFile();
    setErrorMessage('');
  };

  const handleUpload = async () => {
    if (!file) {
      setErrorMessage('Please select a file to upload.');
      return;
    }
    const folder = selectedPortfolio.id;
    const formData = new FormData();
    formData.append('file', file);
    const params = {
      folder,
      cancelToken: source.token,
      formData,
    };
    dispatch(importCsvFetchData(params));
  };

  return (
    <div className="container">
      <h5>Please note the following</h5>
      <ul className={styles.uploadCsvCheck}>
        <li>Follow the format shown in the screenshot</li>
        <li>Fields highlighted in yellow are compulsory, while the rest are optional</li>
        <li>Optional cells can be left blank if no value.</li>
        <li>Stock and Exchange symbols are case insensitive</li>
        <li>
          If a stock is specified from exchanges other than the Singapore Exchange (SGX), prepend
          the exchange code in this format: HKEX:700, BURSA:5099, nasdaq:tsla
        </li>
        <li>
          Our system supports the following exchanges: SGX, BURSA, HKEx, SET, IDX, ASX, NYSE, NASDAQ
          and NYSEMKT
        </li>
      </ul>
      <div className="row">
        <div className="col-lg-6">
          <a
            type="button"
            href="/import_transaction.csv"
            className={`btn btn-lg btn-primary d-block ${styles.downloadTemplate}`}>
            Download CSV Template
          </a>
        </div>
        <div className="col-lg-6">
          <label htmlFor="file" className={`btn btn-lg btn-dark ${styles.customFileUpload}`}>
            Choose a file
          </label>
          <input
            id="file"
            type="file"
            onChange={handleFileChange}
            className={styles.fileInput}
            accept=".csv"
          />
        </div>
      </div>
      {errorMessage && <div className="alert alert-danger g-mt-20">{errorMessage}</div>}
      <div>
        {file && (
          <>
            <ul className="mt-lg-3">
              <li>
                File Name: {file.name}{' '}
                <OverlayTrigger
                  placement="top"
                  key="clear_counter"
                  overlay={<Tooltip>Clear selection</Tooltip>}>
                  <span role="button" onClick={clearFile} className="g-ml-5 g-mr-5 icon">
                    <i className="fa-light fa-square-xmark" />
                  </span>
                </OverlayTrigger>
              </li>
            </ul>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-lg btn-success g-width-300"
                onClick={handleUpload}
                disabled={loading}>
                <i className="fa fa-cloud-upload" /> {loading ? 'Uploading...' : 'Import CSV'}
              </button>
            </div>
          </>
        )}
      </div>
      <img className={styles.csvExample} src="/images/csv_example.png" alt="CSV Example" />
    </div>
  );
}

AddCSVTransaction.propTypes = {};

export default AddCSVTransaction;
