import React from 'react';
import PropTypes from 'prop-types';

function FilterBuyerSeller({ buyerSeller, handleBuyerSellerChange }) {
  return (
    <>
      <input
        id="searchBuyerSeller"
        className="form-control g-max-width-200"
        type="text"
        placeholder="Buyer / Seller Name"
        value={buyerSeller}
        onChange={handleBuyerSellerChange}
      />
    </>
  );
}

FilterBuyerSeller.propTypes = {
  buyerSeller: PropTypes.string,
  handleBuyerSellerChange: PropTypes.func,
};

export default FilterBuyerSeller;
