import React, { useState, useRef } from 'react';
import { Button, Form, Alert, Col, Container, Row } from 'react-bootstrap';
import axios from 'axios';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import * as yup from 'yup';
import { Formik, Form as FormikForm } from 'formik';
import api from '../../utils/api';
import styles from './userlogin.module.css';

function ChooseUsername({ site_key = '', perform_check = true, name = '', profile_picture = '' }) {
  const [loading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const reCaptchaRef = useRef(null);

  const { CancelToken } = axios;

  const schema = yup.object().shape({
    username: yup.string().trim().required('Username is required'),
  });

  const SOCIAL_SIGN_UP_URL = '/user/social_sign_up';
  const SERVER_ERROR_FAILED_MESSAGE =
    'Failed to send request. Please contact us if you are unable to reset password.';
  const RECAPTCHA_FAILED_MESSAGE =
    'Failed to verify that you are a human. Please refresh page and try again.';

  const getRecaptchaToken = async () => {
    try {
      return await reCaptchaRef.current.executeAsync();
    } catch (error) {
      return '';
    }
  };

  const doSocialSignUp = async (values) => {
    setIsLoading(true);

    /* Get grecaptcha */
    let captchaToken = '';
    if (perform_check) {
      captchaToken = await getRecaptchaToken();
      if (!captchaToken) {
        setErrorMessage(RECAPTCHA_FAILED_MESSAGE); // Recaptcha failed.
        return;
      }
    }

    api
      .post(
        SOCIAL_SIGN_UP_URL,
        {
          username: values.username,
          name,
          profile_picture,
          recaptcha_token: captchaToken,
        },
        {
          CancelToken,
        },
      )
      .then((response) => {
        if (response.data.success) {
          /* Redirect to homepage */
          window.location.href = '/';
        } else {
          setErrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        setErrorMessage(`${SERVER_ERROR_FAILED_MESSAGE} ${error.message}`);
      })
      .then(() => {
        setIsLoading(false);
        reCaptchaRef.current?.reset();
      });
  };

  return (
    <Container className="mt-4 mb-4">
      <Row className="justify-content-center">
        <Col sm={12} xxl={4} lg={8}>
          <Formik
            validationSchema={schema}
            initialValues={{
              username: name
                .replace(/[^a-zA-Z0-9]/g, '')
                .toLowerCase()
                .slice(0, 20),
            }}
            onSubmit={doSocialSignUp}>
            {({ handleSubmit, handleChange, values, touched, errors }) => (
              <FormikForm noValidate onSubmit={handleSubmit}>
                <h4 className="text-center mb-3">Choose Your Username</h4>

                {errorMessage && (
                  <Alert variant="danger" className="g-text-size-15">
                    <i className="fa fa-solid fa-triangle-exclamation fa-fw" /> <b>Error: </b>
                    {errorMessage}
                  </Alert>
                )}

                <div className="text-center g-text-size-15">
                  Please enter a username. You will use this username for future login.
                </div>

                <Form.Group as={Col} className="mt-4">
                  <Form.Control
                    name="username"
                    type="text"
                    placeholder="Username"
                    value={values.username}
                    className={styles.signInInput}
                    onChange={handleChange}
                    isInvalid={touched.username && !!errors.username}
                  />
                  <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
                </Form.Group>

                {perform_check && (
                  <ReCAPTCHA
                    ref={reCaptchaRef}
                    className="d-inline-block"
                    theme="dark"
                    size="invisible"
                    sitekey={site_key}
                  />
                )}

                <div className="text-center mt-3 mb-1 pt-1">
                  {loading ? (
                    <Button
                      variant="success"
                      type="button"
                      className="w-100 d-inline-flex justify-content-center align-items-center "
                      name="submit"
                      disabled
                      onClick={() => {}}>
                      <div
                        className="spinner-border spinner-border-sm text-light me-2"
                        role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                      Processing...
                    </Button>
                  ) : (
                    <Button
                      variant="success"
                      type="button"
                      className={`w-100 d-inline-flex justify-content-center align-items-center ${styles.signInButton}`}
                      name="submit"
                      onClick={handleSubmit}>
                      Proceed to Login
                    </Button>
                  )}
                </div>
              </FormikForm>
            )}
          </Formik>
        </Col>
      </Row>
    </Container>
  );
}

ChooseUsername.propTypes = {
  site_key: PropTypes.string,
  perform_check: PropTypes.bool,
  name: PropTypes.string,
  profile_picture: PropTypes.string,
};

export default ChooseUsername;
