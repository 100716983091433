import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import api from '../../../../utils/api';
import Loader from '../../../../components/Loader';
import CashAllocationChart from './CashAllocationChart';
import PeriodFilters, { PERIOD_OPTIONS, RANGE_OPTIONS } from './PeriodFilters';
import CashAllocationSetting from './CashAllocationSetting';

const { CancelToken } = axios;

function CashAllocationManagement({ portfolioData, loading }) {
  const source = CancelToken.source();
  const selectedPortfolio = useSelector((state) => state.portfolio.selected);
  const [period, setPeriod] = useState('d');
  const [range, setRange] = useState('all');
  const [benchmark, setBenchmark] = useState();
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [error, setError] = useState();

  const toggleShowSettingsModal = () => {
    setShowSettingsModal(!showSettingsModal);
  };

  const setBenchmarkRequest = (newBenchmark) => {
    const cancelToken = source.token;
    const params = { cash_allocation_benchmark: newBenchmark };
    const url = `portfolio/${selectedPortfolio.id}/update_settings.json`;

    api
      .post(url, params, { cancelToken })
      .then(({ data }) => {
        if (data.success) {
          setBenchmark(data.cash_allocation_benchmark);
        }
      })
      .catch((err) => {
        setError(err);
      });
  };

  const handleUpdateBenchmark = (value) => {
    if (value !== benchmark) {
      setBenchmarkRequest(parseInt(value));
    }
    toggleShowSettingsModal();
  };

  useEffect(() => {
    if (
      period === 'd' &&
      portfolioData &&
      portfolioData.performance &&
      Object.keys(portfolioData.performance).length > 30
    ) {
      setPeriod('w');
    }
    const initialBenchmark = portfolioData?.settings?.cash_allocation_benchmark || 0;
    setBenchmark(parseInt(initialBenchmark));
  }, [portfolioData]);

  return (
    <Card className="g-mt-20">
      <Card.Body>
        <Card.Title>Cash Allocation Management</Card.Title>

        <Loader visible={loading} classes="text-center m-5" />
        {!loading && (!portfolioData || !portfolioData.performance) && (
          <h6 className="text-center text-muted m-5">No data found.</h6>
        )}
        {!loading && portfolioData && portfolioData.performance && (
          <>
            <div className="d-flex gap-3 flex-wrap flex-lg-row flex-column">
              <PeriodFilters
                period={period}
                setPeriod={setPeriod}
                range={range}
                setRange={setRange}
                loading={loading}
              />

              <Button
                variant="light"
                onClick={() => toggleShowSettingsModal()}
                className="g-min-width-30 ms-lg-auto align-self-start">
                <i className="fa-light fa-gear" />
              </Button>
            </div>
            <CashAllocationChart
              portfolioData={portfolioData}
              selectedPeriodInterval={PERIOD_OPTIONS[period].interval}
              selectedRange={RANGE_OPTIONS[range]}
              benchmark={benchmark}
            />
            <CashAllocationSetting
              show={showSettingsModal}
              handleClose={toggleShowSettingsModal}
              benchmark={benchmark}
              setBenchmark={handleUpdateBenchmark}
            />
          </>
        )}
      </Card.Body>
    </Card>
  );
}

CashAllocationManagement.propTypes = {
  portfolioData: PropTypes.object,
  loading: PropTypes.bool,
};

export default CashAllocationManagement;
