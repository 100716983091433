import { createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';
import store from '../../../utils/store';

const priceDownloadSlice = createSlice({
  name: 'priceDownload',
  initialState: {
    data: [],
    err: null,
    loading: false,
  },
  reducers: {
    priceDownloadFetchStart(state, action) {
      state.data = null;
      state.err = null;
      state.loading = true;
    },
    priceDownloadFetchSuccess(state, action) {
      state.data = action.payload.data.pricedownload;
      state.err = null;
      state.loading = false;
    },
    priceDownloadFetchFail(state, action) {
      state.err = action.payload;
      state.loading = false;
    },
  },
});

export default priceDownloadSlice.reducer;

const {
  priceDownloadFetchStart,
  priceDownloadFetchSuccess,
  priceDownloadFetchFail,
} = priceDownloadSlice.actions;

export const fetchData =
  ({ market, cancelToken }) =>
  (dispatch) => {
    const url = market+`/price_download_all_stocks.json?type=price_download_all_stocks_`+market;
    dispatch(priceDownloadFetchStart());
    api
      .get(url, {
        cancelToken,
      })
      .then(({ data }) => {
        dispatch(priceDownloadFetchSuccess({ data }));
      })
      .catch((error) => {
        dispatch(priceDownloadFetchFail(error));
      });
  };
