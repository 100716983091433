import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { ToastContainer, toast, Slide } from 'react-toastify';
import {
  fetchData,
  addToWatchlistFetchData,
  clearMessage,
} from '../../pages/Watchlist/watchlistSlice';
import 'react-toastify/dist/ReactToastify.css';

const { CancelToken } = axios;

function AddToWatchListModal({ show, setShow, selectedStock }) {
  const dispatch = useDispatch();
  const source = CancelToken.source();

  const [selectedWatchlist, setSelectedWatchlist] = useState('');
  const allWatchlist = useSelector((state) => state.watchlist.all);
  const response = useSelector((state) => state.watchlist.response);
  const error = useSelector((state) => state.watchlist.err);
  const [validated, setValidated] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    if (response) setNotificationMessage({ success: true, message: response });
    if (error && error.response?.status !== 401) {
      setNotificationMessage({ success: false, message: error.message });
    }
  }, [response, error]);

  const toastId = React.useRef(null);

  useEffect(() => {
    if (!toast.isActive(toastId.current)) {
      if (notificationMessage && notificationMessage.message) {
        toast(notificationMessage.message, {
          type: notificationMessage.success ? 'success' : 'error',
          position: 'bottom-center',
          onClose: () => dispatch(clearMessage()),
          toastId: 'watchListToast',
        });
      }
    }
  }, [notificationMessage]);

  useEffect(() => {
    if (allWatchlist.length <= 0) dispatch(fetchData({ cancelToken: source.token }));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      return;
    }

    const params = {
      id: selectedWatchlist,
      counter: selectedStock,
      cancelToken: source.token,
    };

    if (selectedStock) {
      dispatch(addToWatchlistFetchData(params));
    }
    handleClose();
  };

  if (error && error.response && error.response.status === 401) {
    return (
      <Modal
        show={show}
        centered
        dialogClassName="g-text-size-14 AddToWatchListModal"
        onHide={handleClose}>
        <Modal.Body>
          <div className="text-center">
            <h4>You need to login to access this feature</h4>
            <a href={error.response.data?.redirect_to || '/user/login'}>Login</a>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <>
      <ToastContainer transition={Slide} />
      <Modal
        show={show}
        centered
        dialogClassName="g-text-size-14 AddToWatchListModal"
        onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Prices - Add to Watchlist</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {allWatchlist && (
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Label>
                  <b>Watchlist</b>
                </Form.Label>
                <Form.Select
                  name="watchlist"
                  required
                  value={selectedWatchlist}
                  onChange={(e) => setSelectedWatchlist(e.target.value)}>
                  <option value="" disabled>
                    Select Watchlist
                  </option>
                  {allWatchlist.map((watchlist) => (
                    <option key={watchlist.id} value={watchlist.id}>
                      {watchlist.name}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please select a watchlist.
                </Form.Control.Feedback>
              </Form.Group>
              <div className="g-mt-20">
                <Button type="submit">Add</Button>
              </div>
            </Form>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

AddToWatchListModal.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  selectedStock: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};

export default AddToWatchListModal;
