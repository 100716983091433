import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import dayjs from 'dayjs';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';

import SearchQuote from '../../../../components/SearchQuote';
import api from '../../../../utils/api';
import {
  createTransactionFetchData,
  updateTransactionFetchData,
  fetchData as transactionFetchData,
} from './transactionSlice';
import { calculateBrokerFeeCharges } from './Brokers';
import { getCurrencyName } from '../../currencies';
import AddCSVTransaction from './AddCSVTransaction';
import { getParamCounterId, formatValue } from '../../../../utils/utils';
import { fetchData as portfolioFetchData } from '../../portfolioSlice';

const { CancelToken } = axios;

function AddUpdateTransactionModal({ show, setShow, editingTransaction, showUploadCSV }) {
  const dispatch = useDispatch();
  const source = CancelToken.source();
  const [error, setError] = useState('');

  const selectedPortfolio = useSelector((state) => state.portfolio.selected);
  const selectedLayout = useSelector((state) => state.portfolio.selectedLayout);
  const [date, setDate] = useState(dayjs(new Date()).format('YYYY-MM-DD HH:mm'));

  const [activity, setActivity] = useState(0); // buy 0 , sell 1
  const [activeCounter, setActiveCounter] = useState({});
  const [price, setPrice] = useState();
  const [isQuoteInfoLoading, setIsQuoteInfoLoading] = useState(true);
  const [commission, setCommission] = useState('');
  const [quantity, setQuantity] = useState();
  const [exchangeRate, setExchangeRate] = useState(1);
  const [grossValue, setGrossValue] = useState();
  const brokers = useSelector((state) => state.portfolioBroker.brokers);
  const availableCash = useSelector((state) => state.transaction.totalCash);
  const [selectedBroker, setSelectedBroker] = useState({});
  const [remarks, setRemarks] = useState('');
  const [remainingCash, setRemainingCash] = useState('00.000');

  const [transactionToEdit, setTransactionToEdit] = useState(editingTransaction);
  const [transactionId, setTransactionId] = useState();

  const [activeStockStockCurrencyMarket, setActiveStockStockCurrencyMarket] = useState({});

  const [editMode, setEditMode] = useState(false);
  const [validated, setValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const allPortfolio = useSelector((state) => state.portfolio.all);
  const [currentSelectedPortfolio, setCurrentSelectedPortfolio] = useState(selectedPortfolio);

  const calculateRemainingCash = (option) => {
    if (!editMode) {
      if (isNaN(grossValue)) {
        if (availableCash) {
          setRemainingCash(availableCash);
        } else {
          setRemainingCash('00.000');
        }
      } else if (show.show) {
        if (option === 0) setRemainingCash(availableCash - grossValue);
        if (option === 1) setRemainingCash(availableCash + grossValue);
      }
    }
  };

  useEffect(() => {
    const isEditMode =
      show.type === 'transaction' &&
      editingTransaction &&
      typeof editingTransaction === 'object' &&
      Object.keys(editingTransaction).length > 0;

    setEditMode(isEditMode);
    setTransactionToEdit(editingTransaction);

    if (
      show.show &&
      show.type === 'addToPortfolio' &&
      show.watchlistCounter &&
      typeof show.watchlistCounter === 'object' &&
      Object.keys(show.watchlistCounter).length > 0
    ) {
      const newLayout =
        selectedLayout?.length > 0
          ? selectedLayout
          : !selectedPortfolio.id
          ? ''
          : selectedPortfolio.classic
          ? 'trading_data'
          : 'portfolio';

      dispatch(portfolioFetchData({ selectedLayout: newLayout, cancelToken: source.token }));
      setActiveCounter({
        symbol: show.watchlistCounter.counter,
        marketAndCode: getParamCounterId(show.watchlistCounter.counter),
        stock_name: show.watchlistCounter.name,
      });
    }
  }, [show.type, editingTransaction, show.watchlistCounter]);

  useEffect(() => {
    if (editMode) {
      setTransactionId(transactionToEdit.id);

      if (transactionToEdit.action === 'Buy') {
        setActivity(0);
      } else if (transactionToEdit.action === 'Sell') setActivity(1);
      setCommission(transactionToEdit.comm);

      setDate(dayjs(transactionToEdit.date).format('YYYY-MM-DD HH:mm'));
      setExchangeRate(transactionToEdit.exchange_rate);
      setRemarks(transactionToEdit.note);

      setPrice(transactionToEdit.price);
      setQuantity(transactionToEdit.shares);
      setActiveCounter({
        symbol: transactionToEdit.stock_code,
        marketAndCode: transactionToEdit.marketAndCode,
        stock_name: transactionToEdit.stock_name,
      });
      setIsQuoteInfoLoading(false);
      setSelectedBroker({ Id: transactionToEdit.broker_id });
      setActiveStockStockCurrencyMarket({
        counter_currency: transactionToEdit.counter_currency,
        folder_currency: currentSelectedPortfolio.currency,
      });
    }
  }, [transactionToEdit]);

  // gross value
  useEffect(() => {
    const value = parseFloat(quantity) * parseFloat(price) * parseFloat(exchangeRate);
    let valueAfterComm = parseFloat(value);
    if (commission) valueAfterComm += parseFloat(commission);
    setGrossValue(parseFloat(valueAfterComm));
  }, [commission, quantity, price, exchangeRate, selectedBroker]);

  useEffect(() => {
    if (!editMode) calculateRemainingCash(activity);
  }, [grossValue]);

  // get prices after stock selection
  useEffect(() => {
    if (!editMode) {
      if (
        Object.keys(currentSelectedPortfolio).length > 0 &&
        currentSelectedPortfolio.id &&
        Object.keys(activeCounter).length > 0
      ) {
        const cancelToken = source.token;
        setIsQuoteInfoLoading(true);

        // get price & exchange price
        setIsQuoteInfoLoading(true);
        api
          .get(
            `/portfolio/${currentSelectedPortfolio.id}/get_price_and_exchange_price?counter=${activeCounter.symbol}`,
            {
              cancelToken,
            },
          )
          .then(({ data }) => {
            setActiveStockStockCurrencyMarket(data);
            setExchangeRate(data.exchange_rate);
            setPrice(data.last_done_price);
            setIsQuoteInfoLoading(false);
          })
          .catch((error) => {
            console.log('Search retrieve price error', error);
            setIsQuoteInfoLoading(false);
          });
      } else {
        setPrice('');
        setActiveStockStockCurrencyMarket({});
        setExchangeRate(1);
        setQuantity('');
      }
    }
  }, [activeCounter, currentSelectedPortfolio]);

  const handleAddOrRemoveFromTransaction = (counterData) => {
    setActiveCounter(counterData);
  };

  const handleClose = () => {
    setShow({ show: false, type: '' });
  };

  const handleOptionChange = (option) => {
    setActivity(option);
    if (!editMode) calculateRemainingCash(option);
  };

  const handleBrokerChange = (BrokerId) => {
    const broker = brokers.find((b) => b.Id === parseInt(BrokerId, 10));
    setSelectedBroker(broker);
  };

  const handlePortolioChange = (selectedId) => {
    const portfolioById = allPortfolio.find((portfolio) => portfolio.id === Number(selectedId));
    setCurrentSelectedPortfolio(portfolioById);
    dispatch(transactionFetchData({ folder: portfolioById.id, cancelToken: source.token }));
  };

  useEffect(() => {
    if (selectedBroker && Object.keys(selectedBroker).length > 0 && selectedBroker.Id) {
      const commissionInput = document.getElementById('commissionInput');
      const commValue = calculateBrokerFeeCharges(price, quantity, selectedBroker, exchangeRate);
      if (commValue !== undefined && commValue !== null) {
        setCommission(commValue);
        if (commissionInput) {
          commissionInput.value = commValue;
        }
      }
    }
    // for edit only
    if (editMode && selectedBroker.Id) handleBrokerChange(selectedBroker.Id);
  }, [selectedBroker, quantity, price]);

  useEffect(() => {
    const priceInput = document.getElementById('priceInput');
    if (price && priceInput) {
      priceInput.value = price;
    }
  }, [price]);

  useEffect(() => {
    if (activeStockStockCurrencyMarket.value === currentSelectedPortfolio.currency)
      setExchangeRate(1);
  }, [exchangeRate]);

  const handleSave = (e) => {
    e.preventDefault(); // stop reload
    const form = e.currentTarget;
    if (form.checkValidity() === false || Object.keys(activeCounter).length === 0) {
      if (Object.keys(activeCounter).length === 0) {
        setErrorMessage('Please select a stock.');
      } else {
        setErrorMessage('');
      }
      e.stopPropagation();

      setValidated(true);
      return;
    }

    const counter = activeCounter.symbol;
    const broker = selectedBroker.Id;
    const folder = currentSelectedPortfolio.id;
    const params = {
      exchangeRate,
      quantity,
      price,
      commission,
      counter,
      activity,
      date,
      broker,
      folder,
      remarks,
      selectedLayout,
      cancelToken: source.token,
    };

    if (e && e.target && e.target.value) {
      params.name = e.target.value;
    }

    if (quantity > 0) {
      if (editMode && transactionToEdit && Object.keys(transactionToEdit).length && transactionId) {
        params.transactionId = transactionId;
        dispatch(updateTransactionFetchData(params));
      } else {
        dispatch(createTransactionFetchData(params));
      }
      handleClose();
    } else {
      setError('quantity is zero');
    }
  };

  // Clear state when modal is closed
  const resetState = () => {
    setActivity(0);
    setActiveCounter({});
    setPrice('');
    setQuantity('');
    setExchangeRate(1);
    setGrossValue(0);
    setSelectedBroker({});
    setRemarks('');
    setTransactionToEdit({});
    setTransactionId('');
    setEditMode(false);
    setError('');
    setDate(dayjs(new Date()).format('YYYY-MM-DD HH:mm'));
    setActiveStockStockCurrencyMarket({});
    setRemainingCash(availableCash);
    setValidated(false);
    setErrorMessage('');
    setCommission('');
    setCurrentSelectedPortfolio(selectedPortfolio);
  };

  useEffect(() => {
    if (availableCash) setRemainingCash(availableCash);
  }, [availableCash]);

  useEffect(() => {
    if (!show.show) {
      resetState();
    }
  }, [show.show]);

  useEffect(() => {
    // fetch transaction data if there are no counters yet since the table is not rendered and fetch is not called. TODO: refactor
    if (selectedPortfolio?.counters?.length === 0 && selectedPortfolio?.id) {
      dispatch(transactionFetchData({ folder: selectedPortfolio.id, cancelToken: source.token }));
    }
  }, []);

  return (
    <Modal
      show={show.show && (show.type === 'transaction' || show.type === 'addToPortfolio')}
      centered
      dialogClassName="g-text-size-14 AddUpdateTransactionModal"
      onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{editMode ? 'Edit' : 'Add'} Transaction</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="nav nav-tabs mb-3 design-2" role="tablist">
          <div className="nav-item" role="presentation">
            <button
              className="nav-link active"
              data-bs-target="#tab-transaction"
              data-bs-toggle="pill"
              aria-selected="true"
              type="button"
              role="tab">
              <i className="fa-light fa-file-chart-column g-mr-5" />
              {editMode ? 'Edit' : 'Add'} Transactions
            </button>
          </div>
          {!editMode && showUploadCSV && (
            <div className="nav-item" role="presentation">
              <button
                className="nav-link"
                data-bs-target="#tab-import"
                data-bs-toggle="pill"
                aria-selected="false"
                role="tab"
                type="button"
                tabIndex="-1">
                <i className="fa-light fa-file-csv g-mr-5" />
                Import CSV
              </button>
            </div>
          )}
        </div>
        <div className="tab-content">
          <div className="tab-pane fade active show" id="tab-transaction" role="tabpanel">
            {!isQuoteInfoLoading && Object.keys(activeCounter).length > 0 && (
              <div className="d-flex">
                <h6>
                  Stock:
                  <b>
                    {' '}
                    {activeCounter.stock_name} ({activeCounter.marketAndCode})
                  </b>
                </h6>
                {!editMode && show.type !== 'addToPortfolio' && (
                  <OverlayTrigger
                    placement="top"
                    key="clear_counter"
                    overlay={<Tooltip>Clear selection</Tooltip>}>
                    <span
                      role="button"
                      onClick={() => {
                        setActiveCounter({});
                      }}
                      className="g-ml-5 g-mr-5 icon">
                      <i className="fa-light fa-square-xmark" />
                    </span>
                  </OverlayTrigger>
                )}
              </div>
            )}
            {Object.keys(activeCounter).length === 0 && (
              <div className="col-12 g-mb-20">
                <SearchQuote
                  placeholder="Search a stock"
                  handleActiveCounterChanged={handleAddOrRemoveFromTransaction}
                  type="transaction"
                />
                {errorMessage && (
                  <div
                    className="invalid-feedback"
                    style={{ display: errorMessage ? 'block' : 'none' }}>
                    {errorMessage}
                  </div>
                )}
              </div>
            )}
            <Form noValidate validated={validated} onSubmit={handleSave}>
              {show.type === 'addToPortfolio' && allPortfolio && (
                <div className="row g-mb-20">
                  <div className="col-12">
                    <Form.Group>
                      <Form.Label>
                        <b>Select Portfolio</b>
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="portfolio"
                        defaultValue=""
                        required
                        size="lg"
                        onChange={(e) => handlePortolioChange(e.target.value)}>
                        <option value="" disabled>
                          Select Portfolio
                        </option>
                        {allPortfolio.map((portfolio) => (
                          <option key={portfolio.id} value={portfolio.id}>
                            {portfolio.name}
                          </option>
                        ))}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Please select a portfolio
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
              )}
              <div className="row g-mb-20">
                <div className="col-6">
                  <Form.Group>
                    <Form.Label>
                      <b>Action</b>
                    </Form.Label>
                    <div className="d-flex flex-wrap flex-column flex-lg-row gap-2">
                      <div>
                        <input
                          type="radio"
                          className="btn-check"
                          name="options-outlined"
                          id="success-outlined"
                          autoComplete="off"
                          checked={activity === 0}
                          onChange={() => handleOptionChange(0)}
                        />
                        <label
                          className={`btn btn-sm btn-outline-success ${
                            activity === 0 ? 'active text-white' : ''
                          }`}
                          htmlFor="success-outlined">
                          Buy
                        </label>
                      </div>

                      <div>
                        <input
                          type="radio"
                          className="btn-check"
                          name="options-outlined"
                          id="danger-outlined"
                          autoComplete="off"
                          checked={activity === 1}
                          onChange={() => handleOptionChange(1)}
                        />
                        <label
                          className={`btn btn-sm btn-outline-danger ${
                            activity === 1 ? 'active white-active' : ''
                          }`}
                          htmlFor="danger-outlined">
                          Sell
                        </label>
                      </div>
                    </div>
                  </Form.Group>
                </div>
                <div className="col-6">
                  <Form.Group>
                    <Form.Label>
                      <b>Transaction Date</b>
                    </Form.Label>
                    <Form.Control
                      type="datetime-local"
                      name="date"
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please choose a valid date.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
              <div className="row g-mb-20">
                <div className="col-6">
                  <Form.Group>
                    <Form.Label>
                      <b>Quantity</b>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      name="quantity"
                      placeholder="eg. 1000"
                      min="1"
                      defaultValue={quantity}
                      onChange={(e) => setQuantity(e.target.value)}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Quantity must be a positive number.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col-6">
                  <Form.Group>
                    <Form.Label>
                      <b>Unit/share Price</b>
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        id="priceInput"
                        type="number"
                        name="price"
                        min="0.001"
                        step="0.001"
                        placeholder="eg. 1.23"
                        value={price}
                        required
                        onChange={(e) => setPrice(e.target.value)}
                      />
                      {activeStockStockCurrencyMarket &&
                        activeStockStockCurrencyMarket.counter_currency && (
                          <InputGroup.Text id="basic-addon3" className="g-text-size-14">
                            {getCurrencyName(activeStockStockCurrencyMarket.counter_currency)}
                          </InputGroup.Text>
                        )}
                      <Form.Control.Feedback type="invalid">
                        Price must be a positive number.
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </div>
              </div>
              <div className="row g-mb-20">
                <div className="col-6">
                  <Form.Group>
                    <Form.Label>
                      <b>Select Broker</b>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="broker"
                      value={selectedBroker?.Id || ''}
                      onChange={(e) => handleBrokerChange(e.target.value)}>
                      <option value="" disabled>
                        Select Broker
                      </option>
                      {brokers.map((broker) => (
                        <option key={broker.Id} value={broker.Id}>
                          {broker.BrokerName}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </div>
                <div className="col-6">
                  <Form.Group>
                    <Form.Label>
                      <b>Commission</b>
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        id="commissionInput"
                        type="number"
                        name="commission"
                        placeholder="eg. 10.00"
                        min="0"
                        step="0.001"
                        value={commission}
                        onChange={(e) => setCommission(e.target.value)}
                      />
                      {currentSelectedPortfolio && currentSelectedPortfolio.currency && (
                        <InputGroup.Text id="basic-addon3" className="g-text-size-14">
                          {getCurrencyName(currentSelectedPortfolio.currency)}
                        </InputGroup.Text>
                      )}
                      <Form.Control.Feedback type="invalid">
                        Commission must be a positive number.
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </div>
              </div>
              <div className="row g-mb-20">
                <Form.Group>
                  <Form.Label>
                    <b>Exchange Rate</b>
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="number"
                      name="exchangeRate"
                      placeholder="N/A"
                      value={exchangeRate === 1 ? '' : exchangeRate}
                      onChange={(e) =>
                        e.target.value > 0 ? setExchangeRate(e.target.value) : setExchangeRate(1)
                      }
                      disabled={
                        activeStockStockCurrencyMarket.counter_currency ===
                          currentSelectedPortfolio.currency && !editMode
                      }
                    />
                    {activeStockStockCurrencyMarket &&
                      activeStockStockCurrencyMarket.counter_currency && (
                        <InputGroup.Text id="basic-addon3" className="g-text-size-14">
                          {getCurrencyName(activeStockStockCurrencyMarket.counter_currency)} to{' '}
                          {getCurrencyName(currentSelectedPortfolio.currency)}
                        </InputGroup.Text>
                      )}
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="row g-mb-20">
                <Form.Group>
                  <Form.Label>
                    <b>Remarks(optional)</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="remarks"
                    defaultValue={remarks}
                    onChange={(e) => setRemarks(e.target.value)}
                  />
                </Form.Group>
              </div>
              <div className="row g-mb-20 footer-lower">
                <div className="col-4 text-center align-items-center">
                  <div>
                    <h5>
                      {getCurrencyName(currentSelectedPortfolio.currency)}{' '}
                      {isNaN(grossValue) ? '00.000' : formatValue(grossValue)}
                    </h5>
                    Transaction Value
                  </div>
                </div>
                <div
                  className="col-4 text-center d-flex align-items-center"
                  style={{ borderLeft: '1px solid black' }}>
                  <div>
                    <h6>
                      {getCurrencyName(currentSelectedPortfolio.currency)}{' '}
                      {formatValue(availableCash || '0')}
                    </h6>
                    Available Cash
                  </div>
                </div>
                <div className="col-4 text-center d-flex align-items-center">
                  <div>
                    <h6>
                      {getCurrencyName(currentSelectedPortfolio.currency)}{' '}
                      {formatValue(remainingCash)}
                    </h6>
                    Remaining Cash
                  </div>
                </div>
              </div>
              <div className="g-mb-20">
                <Button variant="primary" type="submit" className="text-center">
                  {editMode ? 'Edit' : 'Add'} Transaction
                </Button>
                <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
              </div>
            </Form>
          </div>
          {showUploadCSV && (
            <div className="tab-pane fade" id="tab-import" role="tabpanel">
              <AddCSVTransaction />
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}

AddUpdateTransactionModal.propTypes = {
  show: PropTypes.object,
  setShow: PropTypes.func,
  editingTransaction: PropTypes.object,
  showUploadCSV: PropTypes.bool,
};

export default AddUpdateTransactionModal;
