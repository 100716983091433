import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import { Button, Table } from 'react-bootstrap';

import { selectWarrantCodes, clearWarrantCodes, fetchData, selectWarrantReadyForInit, setWarrantReadyForInit } from '../stocksComparisonSlice';
import StocksComparisonRow from '../StocksComparisonRow';
import AddRemoveToStocksComparison from '../AddRemoveToStocksComparison';
import {
  COMPARISON_TABLE_HEADER_WARRANT,
} from '../StocksComparisonConstants';
import { MAX_STOCKS } from '../StocksComparisonConstants';

const { CancelToken } = axios;

function WarrantScreen() {
  const dispatch = useDispatch();
  const source = CancelToken.source();
  const warrantCodes = useSelector(selectWarrantCodes);
  const readyForInit = useSelector(selectWarrantReadyForInit);

  const [_, setSearchParams] = useSearchParams();

  /* Stock selected. Update get params */
  useEffect(() => {
    const newSearchParams = new URLSearchParams();
    for (const key in warrantCodes) {
      newSearchParams.append("code", warrantCodes[key].toLowerCase());
    }
    setSearchParams(newSearchParams);
  }, [warrantCodes]);

  useEffect(() => {
    /* Fetch data on init and Set suggestion on init */
    if (readyForInit) {
      if (Object.values(warrantCodes).length > 0) {
        dispatch(fetchData({ columnIndex: "", stockList: Object.values(warrantCodes), stockType: "warrants", cancelToken: source.token }));
      }
      dispatch(setWarrantReadyForInit(false));
    }
  }, [readyForInit]);

  return (<div className="row d-print-none">
    <div className="col-lg-3">
      <h4>Enter Warrants</h4>
      <span className="text-muted">
        Compare up to {MAX_STOCKS} warrants
      </span>
    </div>
    <div className="col-lg-9">
      <div className="g-mb-20">
        <AddRemoveToStocksComparison screenType="warrants" />
      </div>
      <div className="text-center">
        <Button variant="secondary" onClick={() => dispatch(clearWarrantCodes())}>
          Clear All
        </Button>
      </div>
    </div>
    <div>
      <hr className="d-print-none my-5" />
      <h4>Result</h4>
      <Table hover>
        <tbody>
          <StocksComparisonRow screenType="warrants" columnHeader={COMPARISON_TABLE_HEADER_WARRANT}></StocksComparisonRow>
        </tbody>
      </Table>
    </div>
  </div>)
};

export default WarrantScreen;
