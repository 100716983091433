import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { fetchData } from './consensusEstimatesSlice';
import { fetchDefinition } from '../../../components/DefinitionPopup/definitionSlice';
import Loader from '../../../components/Loader';

import './consensusEstimates.css';
import ConsensusEstimatesInfo from './ConsensusEstimatesInfo';
import ForwardEstimatesFinancials from './ForwardEstimatesFinancials';
import ForwardEstimatesRatios from './ForwardEstimatesRatios';
import ConcensusEstimatesHistory from './ConsensusEstimatesHistory';
import APIErrorHandler from '../../../components/APIErrorHandler';

const { CancelToken } = axios;

const checkDataIsEmpty = (data) => {
  if (!data) {
    return false;
  }

  return Object.keys(data).every((key, index, arr) => {
    if (!Array.isArray(data[key])) return true;

    return data[key].length === 0;
  });
};

function ConsensusEstimates({ counter, toggleFactsheetNav }) {
  const source = CancelToken.source();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.consensusEstimates.data);
  const loading = useSelector((state) => state.consensusEstimates.loading);
  const error = useSelector((state) => state.consensusEstimates.err);
  const isEmptyData = useMemo(() => checkDataIsEmpty(data), [data]);
  const [isSSR, setIsSSR] = useState(true);

  useEffect(() => {
    setIsSSR(typeof document === 'undefined');
    dispatch(fetchData({ counter, cancelToken: source.token }));
    dispatch(fetchDefinition({ cancelToken: source.token, source: 'consensus_estimates' }));
    return () => {};
  }, []);

  if (isSSR || loading) {
    return <Loader visible={isSSR || loading} classes="g-height-800" />;
  }

  if (error) {
    return <APIErrorHandler error={error} />;
  }

  if (!(data && Object.keys(data).length > 0) || (data && isEmptyData)) {
    return (
      <div className="d-flex justify-content-center" style={{ width: '100%', height: '1000px' }}>
        <h6 className="mt-5">- No consensus estimates is available -</h6>
      </div>
    );
  }

  return (
    <>
      {data.consensus_estimates_info && !data.consensus_estimates_info.error && (
        <ConsensusEstimatesInfo data={data.consensus_estimates_info} />
      )}
      {data.consensus_estimates_history_chart && !data.consensus_estimates_history_chart.error && (
        <ConcensusEstimatesHistory data={data.consensus_estimates_history_chart} />
      )}

      {data.consensus_estimates_forward_estimates &&
        !data.consensus_estimates_forward_estimates.error && (
          <ForwardEstimatesFinancials
            tableData={data.consensus_estimates_forward_estimates}
            showSparklines={data.show_sparklines_financials}
            currency={data.currency}
            toggleFactsheetNav={toggleFactsheetNav}
          />
        )}
      {data.consensus_estimates_forward_estimates_financials_ratios &&
        !data.consensus_estimates_forward_estimates_financials_ratios.error && (
          <ForwardEstimatesRatios
            tableData={data.consensus_estimates_forward_estimates_financials_ratios}
            showSparklines={data.show_sparklines_financials}
            currency={data.currency}
            toggleFactsheetNav={toggleFactsheetNav}
          />
        )}
      <div className="g-text-size-14 g-fw-500 footer-disclaimer mb-3">Legend</div>
      <div className="sic_legendAlpha">
        <div className="d-flex gap-1 ms-4">
          <sup className="footer-disclaimer">a</sup>
          <div className="g-text-size-14 footer-disclaimer">
            Length of Financial Period is non-standard, where yearly results is not based on 12
            months or half yearly results is not based on 6 months or quarterly results is not based
            on 3 months.
          </div>
        </div>
      </div>
      <br />
    </>
  );
}

ConsensusEstimates.propTypes = {
  counter: PropTypes.string,
  toggleFactsheetNav: PropTypes.func,
};

export default ConsensusEstimates;
