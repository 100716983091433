import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import api from '../../../../utils/api';
import { getCounterList } from '../../portfolioSlice';

export const RANGE_OPTIONS = {
  today: {
    label: 'Today',
    date: dayjs().format('YYYY-MM-DD'),
  },
  yesterday: {
    label: 'Yesterday',
    date: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
  },
  threeDays: {
    label: '3 Days',
    date: dayjs().subtract(3, 'day').format('YYYY-MM-DD'),
  },
  tenDays: {
    label: '10 Days',
    date: dayjs().subtract(10, 'day').format('YYYY-MM-DD'),
  },
  week: {
    label: '2 Weeks',
    date: dayjs().subtract(2, 'week').format('YYYY-MM-DD'),
  },
  month: {
    label: '1 Month',
    date: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
  },
  quarter: {
    label: 'Last Quarter',
    date: dayjs().subtract(3, 'month').format('YYYY-MM-DD'),
  },
  yearToDate: {
    label: 'Year to Date',
    date: dayjs().subtract(1, 'year').format('YYYY-MM-DD'),
  },
  all: {
    label: 'First Purchase',
    date: null,
  },
};

const portfolioDividendSlice = createSlice({
  name: 'portfolioDividend',
  initialState: {
    loading: true,
    err: null,
    response: null,
    data: [],
    rangeFilter: null,
    categoryFilter: null,
    totalDividendIncome: 0,
    pagination: {},
  },
  reducers: {
    portfolioDividendFetchStart(state, action) {
      state.err = null;
      state.loading = true;
      state.data = [];
      state.totalDividendIncome = 0;
    },
    portfolioDividendFetchSuccess(state, action) {
      state.data = action.payload.data.data;
      state.pagination = action.payload.data.pagination;
      state.totalDividendIncome = action.payload.data.total_dividend_income;
      state.loading = false;
    },
    portfolioDividendFetchFail(state, action) {
      state.err = action.payload;
      state.loading = false;
    },
    portfolioDividendFilter(state, action) {
      state.rangeFilter = action.payload.rangeFilter;
      state.categoryFilter = action.payload.categoryFilter;
      state.totalDividendIncome = action.payload.data.total_dividend_income;
    },
    portfolioDividendActionStart(state, action) {
      state.err = null;
      state.loading = true;
    },
    portfolioDividendActionSuccess(state, action) {
      if (action.payload.data && action.payload.data.success) {
        state.data = action.payload.data.data;
        state.pagination = action.payload.data.pagination;
        state.totalDividendIncome = action.payload.data.total_dividend_income;
        state.response = action.payload.data.message;
      } else {
        state.err = { message: action.payload.data.error };
      }
      state.loading = false;
    },
    portfolioDividendActionFail(state, action) {
      state.err = action.payload;
      state.loading = false;
    },
    portfolioDividendClearResponseAndError: (state) => {
      state.response = null;
      state.err = null;
    },
  },
});

export default portfolioDividendSlice.reducer;

const {
  portfolioDividendFetchStart,
  portfolioDividendFetchSuccess,
  portfolioDividendFetchFail,
  portfolioDividendFilter,
  portfolioDividendActionStart,
  portfolioDividendActionSuccess,
  portfolioDividendActionFail,
  portfolioDividendClearResponseAndError,
} = portfolioDividendSlice.actions;

export const fetchData =
  ({ portfolioID, page, categoryFilter, rangeFilter, cancelToken }) =>
  (dispatch) => {
    dispatch(portfolioDividendFetchStart());

    const rangeFilterFormatted =
      rangeFilter && RANGE_OPTIONS[rangeFilter] ? RANGE_OPTIONS[rangeFilter].date : 'all';
    const url = `portfolio/${portfolioID}/dividends.json`;
    api
      .get(url, {
        params: { page, categoryFilter, rangeFilter: rangeFilterFormatted },
        cancelToken,
      })
      .then(({ data }) => {
        dispatch(portfolioDividendFetchSuccess({ data }));
      })
      .catch((error) => {
        dispatch(portfolioDividendFetchFail(error));
      });
  };

export const clearResponseAndError = () => (dispatch) => {
  dispatch(portfolioDividendClearResponseAndError());
};

export const createUpdateDividend =
  ({ dividendObj, portfolioID, selectedLayout, cancelToken }) =>
  (dispatch) => {
    dispatch(portfolioDividendActionStart());

    const url = `portfolio/${portfolioID}/create_update_dividend.json`;
    api
      .post(url, { dividend: dividendObj }, { cancelToken })
      .then(({ data }) => {
        dispatch(portfolioDividendActionSuccess({ data }));

        if (data && data.success) {
          dispatch(getCounterList({ selectedPortfolioID: portfolioID, selectedLayout }));
        }
      })
      .catch((error) => {
        dispatch(portfolioDividendActionFail(error));
      });
  };

export const deleteDividend =
  ({ dividendID, portfolioID, selectedLayout, cancelToken }) =>
  (dispatch) => {
    dispatch(portfolioDividendActionStart());

    const url = `portfolio/${portfolioID}/destroy_dividend.json`;
    api
      .post(url, { dividend_id: dividendID }, { cancelToken })
      .then(({ data }) => {
        dispatch(portfolioDividendActionSuccess({ data }));

        if (data && data.success) {
          dispatch(getCounterList({ selectedPortfolioID: portfolioID, selectedLayout }));
        }
      })
      .catch((error) => {
        dispatch(portfolioDividendActionFail(error));
      });
  };
