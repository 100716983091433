import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import api from '../../utils/api';
import Loader from '../../components/Loader';
import APIErrorHandler from '../../components/APIErrorHandler';
import ConsensusEstimatesTable from '../../components/ConsensusEstimatesTable';
import { Provider } from 'react-redux';
import store from '../../utils/store';
import { createLoadableComponent } from '../../utils/Loadable';
const StockInfoPopup = createLoadableComponent(() => import('../../components/StockInfoPopup'));

const { CancelToken } = axios;
function TopBuySell({ market }) {
  const [isSSR, setIsSSR] = useState(true);
  const source = CancelToken.source();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});
  const [error, setError] = useState();
  const [activeTab, setActiveTab] = useState('top_buy');
  const TABS = [
    {
      key: 'top_buy',
      label: 'Top Buy Calls',
    },
    {
      key: 'top_sell',
      label: 'Top Sell Calls',
    },
  ];
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    setIsSSR(typeof document === 'undefined');
    const cancelToken = source.token;
    setIsLoading(true);

    api
      .get(`/${market}/home_consensus_estimates.json`, {
        cancelToken,
      })
      .then(({ data }) => {
        setData(data);
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
      });
  }, []);

  if (isSSR || isLoading) {
    return <Loader visible={isSSR || isLoading} classes="g-height-800" />;
  }

  if (error) {
    return <APIErrorHandler error={error} />;
  }

  return (
    <Provider store={store}>
      <div className="topBuySell">
        <Tab.Container defaultActiveKey={activeTab}>
          <Nav variant="tabs" className="justify-content-center design-2 g-mb-50">
            {TABS.map((tab) => {
              return (
                <Nav.Item key={tab.key}>
                  <Nav.Link eventKey={tab.key} onClick={() => handleTabClick(tab.key)}>
                    <div className="g-lg-text-size-24 g-text-size-20">{tab.label}</div>
                  </Nav.Link>
                </Nav.Item>
              );
            })}
          </Nav>
          <Tab.Content>
            {TABS.map((tab) => {
              return (
                <Tab.Pane key={tab.key} eventKey={tab.key} className="text-center">
                  {data && data.estimates_table_data && (
                    <>
                      <ConsensusEstimatesTable
                        template="home"
                        data={data.estimates_table_data[tab.key]}
                        showStockInfoPopUp
                      />
                      <a
                        className="btn btn-primary g-mt-20"
                        href={`/${market}/consensus_estimates`}>
                        View Top 10
                      </a>
                    </>
                  )}
                </Tab.Pane>
              );
            })}
            <StockInfoPopup />
          </Tab.Content>
        </Tab.Container>
      </div>
    </Provider>
  );
}
TopBuySell.propTypes = {
  market: PropTypes.string,
};

export default TopBuySell;
