import axios from 'axios';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, Provider, useDispatch } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import store from '../../utils/store';
import { fetchData } from './watchlistSlice';
import { createLoadableComponent } from '../../utils/Loadable';
import NotFound from '../../components/NotFound';

const Header = createLoadableComponent(() => import('./Header'));
const Content = createLoadableComponent(() => import('./Content'));
const { CancelToken } = axios;

async function stopStreaming() {
  const { forceStopStreaming } = await import('../../utils/Streaming');
  forceStopStreaming();
}

function Watchlist({ selectedID, selectedLayout }) {
  useEffect(() => {
    return () => {
      stopStreaming();
    };
  }, []);

  return (
    <Provider store={store}>
      <Router selectedID={selectedID} selectedLayout={selectedLayout} />
    </Provider>
  );
}

Watchlist.propTypes = {
  selectedID: PropTypes.string,
  selectedLayout: PropTypes.string,
};

export default Watchlist;

function Router({ selectedID, selectedLayout }) {
  const dispatch = useDispatch();
  const source = CancelToken.source();
  const allWatchlist = useSelector((state) => state.watchlist.all);

  useEffect(() => {
    dispatch(fetchData({ selectedID, selectedLayout, cancelToken: source.token }));
  }, []);

  if (!allWatchlist || allWatchlist.length === 0) {
    return null;
  }

  const router = createBrowserRouter([
    {
      path: '/watchlist',
      element: <Header />,
      children: [
        {
          path: ':watchlistID',
          element: <Content />,
        },
      ],
    },
    {
      path: '*',
      element: <NotFound />,
    },
  ]);

  return <RouterProvider router={router} />;
}

Router.propTypes = {
  selectedID: PropTypes.string,
  selectedLayout: PropTypes.string,
};
