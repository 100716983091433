import React, { useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { Container, Button } from 'react-bootstrap';

import Header from './Header';
import MembershipPaymentCard from './MembershipPaymentCard';

import { reloadMembershipAccess } from './membershipSlice';

const { CancelToken } = axios;

function MembershipComplete({ country }) {
  const source = CancelToken.source();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector((state) => state.membership.data);
  const plan = useSelector((state) => state.membership.plan);
  const period = useSelector((state) => state.membership.period);
  const addOns = useSelector((state) => state.membership.addOns);
  const priceType = useSelector((state) => state.membership.priceType);
  const accessUpdated = useSelector((state) => state.membership.accessUpdated);

  const handleBackClick = () => {
    const params = { period, plan, priceType };
    const addOnsKeys = Object.keys(addOns).filter((addOnKey) => addOns[addOnKey]);
    if (addOns && addOnsKeys.length > 0) {
      params.addOns = Object.keys(addOns)
        .filter((addOnKey) => addOns[addOnKey])
        .join(',');
    }

    navigate({
      pathname: '/membership/plans/payment_options',
      search: createSearchParams(params).toString(),
    });
  };

  if (!plan) {
    navigate({
      pathname: '/membership/plans',
      search: createSearchParams({ period }).toString(),
    });
  }

  const backToHomepage = () => {
    dispatch(reloadMembershipAccess(CancelToken));
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    if (accessUpdated) {
      window.location.href = `/${country}`;
    }
  }, [accessUpdated]);

  return (
    <>
      <Header title="Plan Subscription" step="Complete" />
      {data?.membership && (
        <Container>
          <div className="row g-mt-60 g-mb-60">
            <div className="d-flex align-middle g-mb-60">
              <button
                className="btn btn-light g-min-width-40 d-inline-block"
                type="button"
                onClick={handleBackClick}>
                <i className="fa-thin fa-arrow-left fa-xl" />
              </button>
              <h2 className="d-inline-block mx-3 mb-0">Complete</h2>
            </div>
            <div className="col-md-6 order-md-1 col-12 order-2">
              <MembershipPaymentCard country={country} />
            </div>
            <div className="col-md-6 order-md-2 col-12 order-1">
              <div className="text-center">
                <p>A new window was opened for payment.</p>
                <p>Once your payment is done please click the button below.</p>

                <Button
                  className="w-30 p-2"
                  onClick={backToHomepage}>
                  Back to Homepage
                </Button>
              </div>
            </div>
          </div>
        </Container>
      )}
    </>
  );
}

export default MembershipComplete;

MembershipComplete.propTypes = {
  country: PropTypes.string,
};
