import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import * as am5stock from '@amcharts/amcharts5/stock';
import am5themes_Responsive from '@amcharts/amcharts5/themes/Responsive';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

import classes from '../../portfolio.module.css';

const MARKER = {
  split: {
    color: 0x52aebf,
    letter: 'S',
    label: 'Insider Trades',
  },
  news: {
    color: 0xb625d1,
    letter: 'N',
    label: 'News',
  },
  earningsUp: {
    color: 0x94c54c,
    letter: 'E',
    label: 'Corporate Actions',
  },
};
const INDEX_COLOR = 0x83cf6b;
const PORTFOLIO_COLOR = 0x7193d8;
const showChartMarkers = (root, dateAxis, chartMarkers) => {
  // Show Markers
  const tooltip = am5.Tooltip.new(root, {
    getStrokeFromSprite: false,
    getFillFromSprite: false,
    keepTargetHover: true,
  });
  tooltip.get('background').setAll({
    strokeOpacity: 1,
    stroke: am5.color(0x000000),
    fillOpacity: 1,
    fill: am5.color(0xffffff),
  });
  tooltip.label.setAll({ interactive: true, fontSize: 12 });
  const makeEvent = (date, type, letter, color, link) => {
    const dateF = new Date(date).toLocaleDateString('en-SG', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
    const description = `<div class="chartMarkerTooltip"><strong class="g-mb-20">${type}</strong><br>${dateF}<br>${link}</div>`;
    const dataItem = dateAxis.createAxisRange(dateAxis.makeDataItem({ value: date }));
    const grid = dataItem.get('grid');
    if (grid) {
      grid.setAll({ visible: true, strokeOpacity: 0.2, strokeDasharray: [3, 3] });
    }

    const bullet = am5.Container.new(root, {
      dy: -10,
    });

    const circle = bullet.children.push(
      am5.Circle.new(root, {
        radius: 10,
        stroke: color,
        fill: color,
        tooltipHTML: description,
        tooltip,
        tooltipY: 0,
      }),
    );

    const label = bullet.children.push(
      am5.Label.new(root, {
        text: letter,
        centerX: am5.p50,
        centerY: am5.p50,
        fill: am5.color(0xffffff),
        fontSize: 12,
      }),
    );

    dataItem.set(
      'bullet',
      am5xy.AxisBullet.new(root, {
        location: 0,
        stacked: true,
        sprite: bullet,
      }),
    );
  };
  if (chartMarkers) {
    chartMarkers.forEach((marker) => {
      makeEvent(
        Date.parse(marker.x),
        MARKER[marker.category].label,
        MARKER[marker.category].letter,
        am5.color(MARKER[marker.category].color),
        marker.story,
      );
    });
  }
};

function ComparisonChart({
  portfolioData,
  indexData,
  chartMarkers,
  selectedPeriodInterval,
  selectedRange,
}) {
  useLayoutEffect(() => {
    const relativePL = (data) => {
      const buyCost = parseFloat(data.buy_cost);
      if (buyCost === 0) {
        return 0;
      }
      const pl =
        parseFloat(data.realized_sell_proceeds) +
        parseFloat(data.unrealized_sell_proceeds) +
        parseFloat(data.total_dividend) -
        buyCost;
      return (pl / buyCost) * 100;
    };

    // Format data
    const index = Object.entries(indexData.performance).map(([key, val]) => {
      return {
        date: Date.parse(key),
        close: parseFloat(val),
      };
    });
    const portfolio = Object.entries(portfolioData.performance).map(([key, val]) => {
      return {
        date: Date.parse(key),
        close: relativePL(val) + 100,
      };
    });

    const root = am5.Root.new('PortfolioComparisonChart');
    root.setThemes([am5themes_Animated.new(root), am5themes_Responsive.newEmpty(root)]);

    const stockChart = root.container.children.push(
      am5stock.StockChart.new(root, {
        percentScaleValueAxisSettings: {
          numberFormat: "#.##'%'",
        },
        autoSetPercentScale: true,
      }),
    );

    const mainPanel = stockChart.panels.push(
      am5stock.StockPanel.new(root, {
        wheelX: 'none',
        wheelY: 'none',
        panX: false,
        panY: false,
      }),
    );

    const valueAxis = mainPanel.yAxes.push(
      am5xy.ValueAxis.new(root, {
        numberFormat: "#'%'",
        renderer: am5xy.AxisRendererY.new(root, {}),
        tooltip: am5.Tooltip.new(root, {
          animationDuration: 200,
        }),
        extraTooltipPrecision: 2,
      }),
    );
    valueAxis.get('renderer').labels.template.setAll({
      fill: am5.color(0x646464),
      fontSize: 12,
    });

    const dateAxis = mainPanel.xAxes.push(
      am5xy.GaplessDateAxis.new(root, {
        groupData: true,
        groupCount: 150,
        groupIntervals: [selectedPeriodInterval],
        baseInterval: {
          timeUnit: 'day',
          count: 1,
        },
        renderer: am5xy.AxisRendererX.new(root, {}),
        tooltip: am5.Tooltip.new(root, {
          animationDuration: 200,
        }),
      }),
    );
    dateAxis.get('renderer').labels.template.setAll({
      fill: am5.color(0x646464),
      fontSize: 12,
    });

    dateAxis.get('dateFormats').day = 'dd MMM yy';
    dateAxis.get('dateFormats').week = 'dd MMM yy';
    dateAxis.get('dateFormats').month = 'MMM yy';
    dateAxis.get('dateFormats').year = 'yyyy';
    dateAxis.get('periodChangeDateFormats').day = 'dd MMM yy';
    dateAxis.get('periodChangeDateFormats').week = 'dd MMM yy';
    dateAxis.get('periodChangeDateFormats').month = 'MMM yy';
    dateAxis.get('periodChangeDateFormats').year = 'yyyy';

    const labelText =
      '{date.formatDate("dd MMM YY")}\n{name}: [bold]{valueYChangeSelectionPercent.formatNumber("#,###.00")}%[/]';

    const portfolioSeries = mainPanel.series.push(
      am5xy.LineSeries.new(root, {
        name: 'Portfolio Performance',
        valueXField: 'date',
        valueYField: 'close',
        xAxis: dateAxis,
        yAxis: valueAxis,
        calculateAggregates: true,
        valueYShow: 'valueYChangeSelectionPercent',
        fill: am5.color(PORTFOLIO_COLOR),
        stroke: am5.color(PORTFOLIO_COLOR),
      }),
    );
    portfolioSeries.set(
      'tooltip',
      am5.Tooltip.new(root, {
        labelText,
      }),
    );
    portfolioSeries.data.setAll(portfolio);
    portfolioSeries.strokes.template.setAll({
      strokeWidth: 2,
    });

    stockChart.set('stockSeries', portfolioSeries);

    const indexSeries = am5xy.LineSeries.new(root, {
      name: indexData.name,
      valueYField: 'close',
      calculateAggregates: true,
      valueXField: 'date',
      xAxis: dateAxis,
      yAxis: valueAxis,
      fill: am5.color(INDEX_COLOR),
      stroke: am5.color(INDEX_COLOR),
    });
    const comparingSeries = stockChart.addComparingSeries(indexSeries);
    indexSeries.strokes.template.setAll({
      strokeWidth: 2,
    });
    comparingSeries.data.setAll(index);
    indexSeries.set(
      'tooltip',
      am5.Tooltip.new(root, {
        labelText,
      }),
    );

    // Show cursor
    mainPanel.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        yAxis: valueAxis,
        xAxis: dateAxis,
        snapToSeries: [portfolioSeries],
        snapToSeriesBy: 'y!',
      }),
    );

    showChartMarkers(root, dateAxis, chartMarkers);

    // Show Legend
    const legend = stockChart.children.push(
      am5.Legend.new(root, {
        paddingTop: 30,
        centerX: am5.p50,
        x: am5.p50,
        useDefaultMarker: true,
      }),
    );
    legend.labels.template.setAll({
      fill: am5.color(0x646464),
    });
    legend.markers.template.setAll({
      width: 16,
      height: 16,
    });
    legend.data.push(portfolioSeries);
    legend.data.push(indexSeries);

    const addCurrentLabel = (series, color) => {
      const { dataItems } = series;
      if (!dataItems || dataItems.length < 2) {
        return;
      }

      const currentValueDataItem = valueAxis.createAxisRange(valueAxis.makeDataItem({ value: 0 }));
      const currentLabel = currentValueDataItem.get('label');
      if (currentLabel) {
        currentLabel.setAll({
          fill: am5.color(0xffffff),
          background: am5.Rectangle.new(root, { fill: am5.color(0x000000) }),
          layer: 100,
        });
      }
      const currentGrid = currentValueDataItem.get('grid');
      if (currentGrid) {
        currentGrid.setAll({ strokeOpacity: 0.5, strokeDasharray: [2, 5] });
      }

      const settings = dataItems[dataItems.length - 1]._settings;
      if (settings && settings.valueYChangeSelectionPercent) {
        const value = am5.math.round(settings.valueYChangeSelectionPercent, 2);
        currentValueDataItem.animate({
          key: 'value',
          to: value,
          duration: 500,
          easing: am5.ease.out(am5.ease.cubic),
        });
        if (currentLabel) {
          currentLabel.set('text', `${stockChart.getNumberFormatter().format(value)}%`);
          currentLabel.get('background').set('fill', am5.color(color));
        }
      }
    };

    portfolioSeries.appear();
    indexSeries.appear();

    const periodSelector = am5stock.PeriodSelector.new(root, {
      stockChart,
    });
    portfolioSeries.events.once('datavalidated', (ev) => {
      periodSelector.selectPeriod(selectedRange);
    });

    setTimeout(() => {
      addCurrentLabel(portfolioSeries, PORTFOLIO_COLOR);
      addCurrentLabel(indexSeries, INDEX_COLOR);
    }, 300);

    return () => {
      root.dispose();
    };
  }, [portfolioData, indexData, chartMarkers, selectedPeriodInterval, selectedRange]);

  return <div id="PortfolioComparisonChart" className={classes.portfolioPerformanceChart} />;
}

ComparisonChart.propTypes = {
  portfolioData: PropTypes.object,
  indexData: PropTypes.object,
  chartMarkers: PropTypes.array,
  selectedPeriodInterval: PropTypes.object,
  selectedRange: PropTypes.object,
};

export default ComparisonChart;
