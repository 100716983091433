import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

export default function Pagination({
  rowOffset,
  currentPageRows,
  totalRows,
  currentPage,
  totalPages,
  handlePageChange,
  classes,
}) {
  const [inputKey, setInputKey] = useState(currentPage);
  const onPageChange = (e) => {
    let page = e.target.value ? Number(e.target.value) : 1;

    if (page < 1) {
      page = 1;
    } else if (page > totalPages) {
      page = totalPages;
    }

    if (page !== currentPage) {
      handlePageChange(page);
    } else {
      setInputKey(Math.random()); // This is used to reset the value of the input
    }
  };

  useEffect(() => {
    setInputKey(Math.random());
    return () => {};
  }, [currentPage]);

  return (
    <div
      className={`pagination d-flex bg-gray-100 px-sm-3 px-2 justify-content-between ${classes}`}>
      <span className=" py-3">
        {totalRows > 0 && `${rowOffset + 1}-${rowOffset + currentPageRows} of ${totalRows} results`}
        {totalRows === 0 && `${totalRows} results`}
      </span>

      {(currentPageRows !== totalRows || currentPage !== 1) && (
        <span className="d-flex gap-3">
          <span className="d-flex gap-1 align-items-center" key={inputKey}>
            <input
              className="h-100 text-center"
              type="number"
              min={1}
              max={totalPages}
              defaultValue={currentPage}
              onBlur={(e) => {
                onPageChange(e);
              }}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  onPageChange(e);
                }
              }}
            />
            <span>of</span>
            <span>{totalPages}</span>
          </span>

          <button
            type="button"
            disabled={currentPage === 1}
            className=" border-0"
            onClick={() => handlePageChange(currentPage - 1)}>
            <i className="fa-thin fa-angle-left fa-lg" />
          </button>

          <button
            type="button"
            disabled={currentPage === totalPages}
            className=" border-0"
            onClick={() => handlePageChange(currentPage + 1)}>
            <i className="fa-thin fa-angle-right fa-lg" />
          </button>
        </span>
      )}
    </div>
  );
}

Pagination.propTypes = {
  rowOffset: PropTypes.number,
  currentPageRows: PropTypes.number,
  totalRows: PropTypes.number,
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  handlePageChange: PropTypes.func,
  classes: PropTypes.string,
};
