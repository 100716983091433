import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

const CHART_X_AXIS_MIN_MARGIN_OF_SAFETY = -500;
function PeerAnalysisChart({ data }) {
  useLayoutEffect(() => {
    const root = am5.Root.new('peerAnalysisChart');

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([am5themes_Animated.new(root)]);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        layout: root.verticalLayout,
        paddingRight: 20,
      }),
    );

    // format chart data
    const formattedData = Object.entries(data).flatMap(([key, values]) => {
      if (key.includes('other')) {
        return [];
      }

      return values
        .map(({ name, piotroski_f_score, margin_of_safety, statement }) => {
          if (
            margin_of_safety === undefined ||
            Object.keys(margin_of_safety).length === 0 ||
            !margin_of_safety.value ||
            isNaN(margin_of_safety.value) ||
            margin_of_safety.value_f === 'n.a.' ||
            margin_of_safety.value_f === '-' ||
            margin_of_safety.value < CHART_X_AXIS_MIN_MARGIN_OF_SAFETY
          ) {
            return null; // Skip formatting for objects with invalid margin_of_safety
          }
          let copy_piotroski_f_score = parseInt(piotroski_f_score);
          return {
            margin_of_safety: parseFloat(margin_of_safety.value_f),
            key: key,
            [`${key}`]: copy_piotroski_f_score, //use [key] for piotroski_f_score to allow different categorises of series and legends.
            piotroski_f_score: copy_piotroski_f_score,
            statement: statement.label,
            stock_name: name,
          };
        })
        .filter((item) => item !== null); // Filter out null items
    });

    let smallestXValue = Infinity;
    let largestXValue = -Infinity;

    formattedData.forEach((item) => {
      if (item.margin_of_safety < smallestXValue) {
        smallestXValue = item.margin_of_safety;
      }
      if (item.margin_of_safety > largestXValue) {
        largestXValue = item.margin_of_safety;
      }
    });

    smallestXValue = smallestXValue * 1.3;
    largestXValue = largestXValue * 1.3;

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    // Create X-Axis
    const xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 50,
    });
    xRenderer.grid.template.set('visible', false);
    xRenderer.ticks.template.setAll({
      location: 0.5,
      stroke: am5.color(0x000000),
      visible: true,
    });
    xRenderer.labels.template.setAll({
      paddingRight: 20,
    });

    const xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        min: smallestXValue,
        max: largestXValue,
        autoZoom: false,
        strictMinMax: true,
        numberFormat: "#'%'",
        renderer: xRenderer,
      }),
    );

    // Add xAxis title
    const xAxisTitle = am5.Label.new(root, {
      text: 'Margin of Safety (%)',
      centerX: am5.p50,
      textAlign: 'center',
      x: am5.p50,
    });
    xAxis.children.push(xAxisTitle);

    // Create Y-axis
    const yRenderer = am5xy.AxisRendererY.new(root, {});
    yRenderer.grid.template.set('visible', false);

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        max: 10,
        strictMinMax: true,
        renderer: yRenderer,
      }),
    );

    // Draw fixed lines on xAxis
    const pointLow = yAxis.makeDataItem({
      value: 2,
      endValue: undefined,
    });

    const yRangeLow = yAxis.createAxisRange(pointLow);

    yRangeLow.get('grid').setAll({
      stroke: am5.color(0xff0000),
      strokeWidth: 1,
      strokeOpacity: 1,
      location: 1,
      visible: true,
      layer: 3,
    });

    const pointHeight = yAxis.makeDataItem({
      value: 8,
      endValue: undefined,
    });

    const yRangeHeight = yAxis.createAxisRange(pointHeight);

    yRangeHeight.get('grid').setAll({
      stroke: am5.color(0x008000),
      strokeWidth: 1,
      strokeOpacity: 1,
      location: 1,
      visible: true,
      layer: 3,
    });

    // Add yAxis title
    const yAxisTitle = am5.Label.new(root, {
      rotation: -90,
      text: 'Piotroski F Score',
      y: am5.p50,
      centerX: am5.p50,
      textAlign: 'center',
      x: -5,
    });
    yAxis.children.unshift(yAxisTitle);

    // function to set backgroundColor on yAxis using axisRange
    function backgroundColorXAxis(value, endValue, color) {
      const rangeDataItem = xAxis.makeDataItem({
        value,
        endValue,
      });

      const range = xAxis.createAxisRange(rangeDataItem);

      // fill background color
      range.get('axisFill').setAll({
        fill: color,
        fillOpacity: 1,
        visible: true,
      });

      // set grid color
      range.get('grid').setAll({
        stroke: color,
        strokeOpacity: 1,
        location: 1,
      });
    }

    // set yAxis color range
    backgroundColorXAxis(smallestXValue, 0, am5.color(0xf7b9b9));
    backgroundColorXAxis(0, 25, am5.color(0xfff8a9));
    backgroundColorXAxis(25, largestXValue, am5.color(0xbdecbd));

    function createSeries(name, field, color) {
      const series = chart.series.push(
        am5xy.LineSeries.new(root, {
          calculateAggregates: true,
          name,
          xAxis,
          yAxis,
          valueYField: field,
          valueXField: 'margin_of_safety',
          tooltip: am5.Tooltip.new(root, {
            labelText: `[bold]{stock_name}[/] \nPiotroski F Score: {piotroski_f_score} \nMargin of Safety: {margin_of_safety.formatNumber('#.##')}% \nValuation: [bold]{statement}[/]`,
          }),
          fill: am5.color(color),
        }),
      );

      series.data.setAll(formattedData);

      // Add bullet
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/#Bullets
      const circleTemplate = am5.Template.new({});
      series.bullets.push(function () {
        const circle = am5.Circle.new(
          root,
          {
            strokeOpacity: 0,
            strokeWidth: 1,
            fill: series.get('fill'),
            fillOpacity: 0.7,
            interactive: true,
            layer: 5,
          },
          circleTemplate,
        );
        circle.states.create('hover', {
          stroke: am5.color(0xffffff),
          strokeOpacity: 1,
          scale: 1.2,
        });

        return am5.Bullet.new(root, {
          sprite: circle,
        });
      });

      // remove stroke for series since we are using bullets instead
      series.strokes.template.set('strokeOpacity', 0);
      series.appear();
    }

    createSeries('Reference', 'reference', '#000000');
    createSeries('Local Peer', 'local_peers', '#686868');
    createSeries('Global Peer', 'global_peers', '#0878c7');

    // Legend
    const legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.percent(50),
        x: am5.percent(50),
      }),
    );

    legend.data.setAll(chart.series.values);

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    const cursor = am5xy.XYCursor.new(root, {
      xAxis,
      yAxis,
    });
    chart.set('cursor', cursor);

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [data]);

  return <div id="peerAnalysisChart" className="w-100 g-height-500" />;
}

PeerAnalysisChart.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    stock_id: PropTypes.string,
    upside_percent: PropTypes.number,
    consensus_score: PropTypes.number,
    number_of_ratings: PropTypes.number,
    consensus_recommendation: PropTypes.string,
  }),
};

export default PeerAnalysisChart;
