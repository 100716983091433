/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import DOMPurify from 'dompurify';

import { getSymbolFromCounter } from '../../utils/utils';
import CompanyLogoImage from '../CompanyLogoImage';

async function stopStreaming() {
  const { stopQuoteStreaming } = await import('../../utils/Streaming/counter_quote_table');
  stopQuoteStreaming();
}

async function startStreaming(counter, delay) {
  const { startQuoteStreamingForCounter } = await import(
    '../../utils/Streaming/counter_quote_table'
  );
  startQuoteStreamingForCounter(counter, delay);
}

export default function QuotePriceSummaryMini({ counter, priceSummary, isDuringStreamingHours }) {
  if (!(counter && priceSummary)) {
    return null;
  }
  const miniChartBaseUrl = '//chart.shareinvestor.com/charts_cached/charts.pl';

  useEffect(() => {
    const isDocTypeUndef = typeof document === 'undefined';
    const counterQuoteDiv = document.getElementById('sic_counterQuoteInfo');
    if (counterQuoteDiv && isDuringStreamingHours && !isDocTypeUndef) {
      startStreaming(counter, priceSummary.delay);
    }
    return () => {
      stopStreaming();
    };
  }, []);

  return (
    <div id="sic_counterQuoteInfo" className="g-mt-5 g-mb-5 g-ml-5 g-mr-5 align-items-center lh-sm">
      <div className="g-mr-5 d-none d-sm-block">
        <div className="stock_info_logo_wrapper g-width-50 g-height-50 float-start">
          <CompanyLogoImage
            counter={getSymbolFromCounter(counter)}
            classes="g-border-radius-5 border p-1"
          />
        </div>
      </div>
      <div className="g-mr-5">
        <div>
          <span id="sic_counterQuoteMini_full_name" className="fw-bold g-mr-5 g-text-size-14">
            {priceSummary.short_name}
          </span>
          <span id="sic_counterQuoteMini_short_name" className="g-text-size-12 text-muted">
            {counter}
          </span>
        </div>

        <span id="sic_counterQuoteMini_lastdone" className="g-text-size-14 g-mr-2 fw-bold">
          {priceSummary.last_done_price_f}
        </span>
        {priceSummary.currency && (
          <span
            id="sic_counterQuoteMini_currency"
            className="g-text-size-12 g-mr-5"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(priceSummary.currency),
            }}
          />
        )}
        <span
          id="sic_counterQuoteMini_chg_perc_change"
          className={`g-text-size-14 ${
            priceSummary.percent_change_f || parseFloat(priceSummary.percent_change_f) != 0
              ? parseFloat(priceSummary.percent_change_f) > 0
                ? 'text-success'
                : 'text-danger'
              : ''
          }`}>
          {`${priceSummary.change_f} (${priceSummary.percent_change_f}%)`}
        </span>
      </div>
      <div className="d-none d-sm-block">
        <img
          className="g-height-50 img-fluid"
          src={`${miniChartBaseUrl}?type=si_stock_historical_mini_plain&id=${getSymbolFromCounter(
            counter,
          )}`}
          alt="Chart"
        />
      </div>
    </div>
  );
}
