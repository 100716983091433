import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import SearchQuote from '../../../components/SearchQuote';

function SearchStockFilter({ counter, handleCounterChange }) {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      <Button
        type="text"
        className="g-min-width-160 text-start d-flex align-items-center"
        variant="light"
        onClick={handleShowModal}>
        <span className="">Search counter</span>
        <span className="ms-auto g-pl-20">
          <i className="fa-light fa-magnifying-glass" />
        </span>
      </Button>
      <Modal
        show={showModal}
        centered
        dialogClassName="addStockModal g-text-size-14"
        onHide={handleShowModal}>
        <Modal.Header closeButton>
          <Modal.Title>Search stock</Modal.Title>
        </Modal.Header>

        <SearchQuote
          placeholder="Search stock"
          type="watchlist"
          handleActiveCounterChanged={handleCounterChange}
          checkedData={[counter]}
        />
      </Modal>
    </>
  );
}

SearchStockFilter.propTypes = {
  counter: PropTypes.string,
  handleCounterChange: PropTypes.func,
};

export default SearchStockFilter;
