import { createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';

const dividendAnalysisSlice = createSlice({
  name: 'dividendAnalysis',
  initialState: {
    isInitialized: false,
    yearsToShow: null,
    loading: true,
    completed: false,
    page: 1,
    data: null,
    err: null,
  },
  reducers: {
    setYearsToShow(state, action) {
      state.yearsToShow = action.payload;
    },
    setPage(state, action) {
      state.page = action.payload;
    },
    dividendAnalysisFetchStart(state, action) {
      state.loading = true;
      state.completed = false;
      state.data = null;
      state.err = null;
    },
    dividendAnalysisFetchSuccess(state, action) {
      state.data = action.payload.data;
      state.completed = true;
      state.loading = false;
    },
    dividendAnalysisFetchFail(state, action) {
      state.err = action.payload;
      state.completed = true;
      state.loading = false;
    },
  },
});

export default dividendAnalysisSlice.reducer;

export const {
  setYearsToShow,
  setPage,
  dividendAnalysisFetchStart,
  dividendAnalysisFetchSuccess,
  dividendAnalysisFetchFail,
} = dividendAnalysisSlice.actions;

export const fetchData =
  ({ filters, cancelToken }) =>
  (dispatch) => {
    dispatch(dividendAnalysisFetchStart());
    api
      .get(`quote/${filters.counter}/dividend_analysis.json`, {
        params: { years_to_show: filters?.yearsToShow, page: filters?.page },
        cancelToken,
      })
      .then(({ data }) => {
        dispatch(dividendAnalysisFetchSuccess({ data }));
      })
      .catch((error) => {
        dispatch(dividendAnalysisFetchFail(error));
      });
  };

export const onChangeYears =
  ({ counter, cancelToken, yearsToShow, page }) =>
  (dispatch) => {
    dispatch(setYearsToShow(yearsToShow));
    dispatch(fetchData({ filters: { counter, yearsToShow, page }, cancelToken }));
  };

export const onChangePage =
  ({ counter, cancelToken, yearsToShow, page }) =>
  (dispatch) => {
    dispatch(setYearsToShow(yearsToShow));
    dispatch(fetchData({ filters: { counter, yearsToShow, page }, cancelToken }));
  };
