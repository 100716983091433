import React from 'react';
import { Button } from 'react-bootstrap';
import priceDownloadLogo from 'images/price_download_logo.svg';
import { Table } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function PriceDownloadTable({ data, market }) {
  const capitalizeMarket = (word) => {
    if (word === 'bursa' || word === 'world') {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }
    if (word === 'bursa_derivative') {
      return 'Bursa Derivatives';
    }
    if (word === 'hkex') {
      return 'HKEx';
    }
    if (word === 'nyse_mkt') {
      return 'NYSE American';
    }
    return word.toUpperCase();
  };

  const downloadFile = (event) => {
    window.location.href = event.target.value;
  };

  return (
    <>
      <div className="row g-3 g-mt-15 g-mb-30">
        <div className="col-lg-6">
          <div className="row g-3">
            <div className="col-auto">
              <img className="g-width-45 g-lg-width-55 img-fluid" src={priceDownloadLogo} />
            </div>
            <div className="col">
              <h6 className="mb-0">Daily Price Data Updates</h6>
              <h4>For {capitalizeMarket(market)} Stocks</h4>
              <ul>
                <li>
                  Download the file below to retrieve the daily historical and intraday data for all
                  stocks.
                </li>
                <li>Up to 30 trading days of data will be provided.</li>
                <li>
                  For historical data, the file is updated at the end of each trading day at around
                  08:00 PM.
                </li>
                <li>
                  For intraday data, intraday trading data from 08:59 AM to 12:30 PM is updated at
                  02:00 PM.
                </li>
                <li>Full intraday trading data is available at around 08:00PM.</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="row g-3">
            <div className="col-auto">
              <i
                className="fa-duotone fa-download fa-2x g-width-45 g-lg-width-55 g-text-size-45 g-lg-text-size-50"
                style={{
                  '--fa-primary-color': '#000000',
                  '--fa-secondary-color': '#000000',
                  '--fa-secondary-opacity': '0.2',
                }}
              />
            </div>
            <div className="col">
              <h6 className="mb-0">Consolidated Price Data</h6>
              <h4>For All Stocks</h4>
              <div className="g-mb-15">
                Download the file below to retrieve the historical and intraday data for all stocks.
                The file is updated at the end of each trading day.
              </div>
              <div className="d-flex flex-wrap gap-3">
                <Button
                  type="button"
                  className="btn btn-lg btn-primary"
                  onClick={downloadFile}
                  value={data.historical_all.link}>
                  <i className="fa-thin fa-circle-arrow-down g-mr-5" /> All Historical Data
                </Button>
                {data && data.intraday_all && (
                  <Button
                    type="button"
                    className="btn btn-lg btn-dark"
                    onClick={downloadFile}
                    value={data.intraday_all.link}>
                    <i className="fa-thin fa-circle-arrow-down g-mr-5" /> All Intraday Data
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="table-responsive">
        <Table hover>
          <thead>
            <tr className="table-light">
              <th className="g-per-width-60">Date</th>
              <th className="text-center">Historical Data</th>
              <th className="text-center">{data && data.intraday_all ? 'Intraday Data' : ''}</th>
            </tr>
          </thead>
          <tbody>
            {data.daily.map((row, i) => (
              <tr key={i}>
                <td>{row.date}</td>
                <td className="text-center">
                  {row.historical ? (
                    <OverlayTrigger placement="top" overlay={<Tooltip>Historical Data</Tooltip>}>
                      <a href={row.historical}>
                        <i className="fa-light fa-arrow-down-to-bracket fa-xl" />
                      </a>
                    </OverlayTrigger>
                  ) : (
                    ''
                  )}
                </td>
                <td className="text-center">
                  {row.intraday ? (
                    <OverlayTrigger placement="top" overlay={<Tooltip>Intraday Data</Tooltip>}>
                      <span>
                        <a href={row.intraday}>
                          <i className="fa-light fa-arrow-down-to-bracket fa-xl" />
                        </a>
                        {row.date_from && row.date_to ? (
                          <div className="g-mt-7 g-text-size-12 text-gray-600 text-center">
                            ({row.date_from} - {row.date_to})
                          </div>
                        ) : (
                          ''
                        )}
                      </span>
                    </OverlayTrigger>
                  ) : (
                    ''
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
}

export default PriceDownloadTable;
