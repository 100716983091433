import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import styles from './TableSearchBar.module.css';

function TableSearchBar({
  columnTitle,
  setFiltered,
  listToSearch,
  searchKeys,
  setSetSearchVal,
  placeholder,
}) {
  const [searchVisible, setSearchVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const inputRef = useRef(null); // Create a ref for the input element

  const toggleSearchVisibility = () => {
    setSearchVisible(!searchVisible);
    if (!searchVisible) {
      setSearchTerm('');
    }
  };

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
    setSetSearchVal(event.target.value);
  };

  useEffect(() => {
    let filtered = listToSearch;
    if (searchTerm) {
      filtered = listToSearch.filter((transaction) =>
        searchKeys.some((key) => transaction[key].toLowerCase().includes(searchTerm.toLowerCase())),
      );
    }
    setFiltered(filtered);
  }, [searchTerm, setFiltered]);

  useEffect(() => {
    // Focus on the input field when searchVisible becomes true
    if (searchVisible && inputRef.current) {
      inputRef.current.focus();
    }
  }, [searchVisible]);

  return (
    <div className=" d-flex position-relative ">
      <span className={`${searchVisible ? 'd-none' : 'd-inline'}`}>{columnTitle}</span>
      <div className={styles.searchBar}>
        <button
          type="button"
          className={styles.togglSearchBtn}
          onClick={toggleSearchVisibility}
          hidden={searchVisible}>
          <span className={`${searchVisible ? 'd-none' : ''}`}>
            <i className="fa-light fa-magnifying-glass" />
          </span>
        </button>
        <div className={styles.searchInputContainer}>
          <input
            ref={inputRef}
            type="text"
            placeholder={placeholder}
            value={searchTerm}
            onChange={handleChange}
            hidden={!searchVisible}
            className="form-control"
          />
          <button
            type="button"
            className={styles.togglSearchBtnClose}
            onClick={toggleSearchVisibility}
            hidden={!searchVisible}>
            <span className={`${!searchVisible ? 'd-none' : ''}`}>
              <i className="fa-light fa-xmark" />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default TableSearchBar;

TableSearchBar.propTypes = {
  columnTitle: PropTypes.string,
  setFiltered: PropTypes.func,
  listToSearch: PropTypes.arrayOf(PropTypes.object),
  searchKeys: PropTypes.arrayOf(PropTypes.string),
  setSetSearchVal: PropTypes.func,
  placeholder: PropTypes.string,
};
