import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import api from '../../../utils/api';
import { Constants } from '../../../utils/constants.js';
import { MAX_STOCKS } from './StocksComparisonConstants.js';

/** ******************************************************** */
/** ********************* Asyncthunk /********************** */
/** ******************************************************** */
export const fetchData = createAsyncThunk(
  'stocksComparison/fetchData',
  async ({ columnIndex, stockList, stockType, cancelToken }) => {
    const dataUrl = `markets/SGX/stocks_comparison.json?stocks_to_compare=${stockList
      .map((item) => item.toUpperCase())
      .join('|')}&compare_type=${stockType}`;
    try {
      const response = await api.get(dataUrl, { cancelToken, responseType: 'json' });
      const responseData = { data: response.data, columnIndex, stockType };
      return responseData;
    } catch (error) {
      return rejectWithValue({ error: error.message, columnIndex });
    }
  },
);

/** ******************************************************** */
/** *********************   Reducer  /********************** */
/** ******************************************************** */
const initialState = {
  screenType: 'stocks',
  stockCodes: {} /* Numeric index based position */,
  stockData: {} /* Stock code index based */,
  stockStatus: {} /* Numeric index based */,
  stockReadyForInit: false /* Load compare data after complete loading. */,
  warrantCodes: {} /* Numeric index based position */,
  warrantData: {} /* Stock code index based */,
  warrantStatus: {} /* Numeric index based */,
  stockSuggestion: [],
  warrantReadyForInit: false /* Load compare data after complete loading. */,
  status: Constants.STATUS_IDLE,
  error: undefined,
};

const stocksComparisonSlice = createSlice({
  name: 'marketsStocksComparison',
  initialState,
  reducers: {
    setStockCodes: (state, action) => {
      state.stockCodes = action.payload;
    },
    setWarrantCodes: (state, action) => {
      state.warrantCodes = action.payload;
    },
    setCompareType: (state, action) => {
      state.screenType = action.payload;
    },
    clearStockCodes: (state) => {
      state.stockCodes = {};
      state.stockData = {};
    },
    clearWarrantCodes: (state) => {
      state.warrantCodes = {};
      state.warrantData = {};
    },
    chooseStock: (state, action) => {
      /* if no column index, choose next available or last one */
      const { columnIndex } = action.payload;
      state.stockCodes[columnIndex] = action.payload.code;
    },
    chooseWarrant: (state, action) => {
      state.warrantCodes[action.payload.columnIndex] = action.payload.code;
    },
    removeStock: (state, action) => {
      const stockCodes = { ...state.stockCodes };
      delete stockCodes[action.payload]; /* Index of the column */
      state.stockCodes = stockCodes;
    },
    removeWarrant: (state, action) => {
      const warrantCodes = { ...state.warrantCodes };
      delete warrantCodes[action.payload]; /* Index of the column */
      state.warrantCodes = warrantCodes;
    },
    setStockReadyForInit: (state, action) => {
      state.stockReadyForInit = action.payload;
    },
    setWarrantReadyForInit: (state, action) => {
      state.warrantReadyForInit = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchData.pending, (state, action) => {
      state.status = Constants.STATUS_LOADING;
      const statusIndices =
        action.meta.arg.columnIndex == ''
          ? [...Array(MAX_STOCKS).keys()]
          : [action.meta.arg.columnIndex];
      if (state.screenType == 'warrants') {
        const { warrantStatus } = state;
        statusIndices.forEach((val) => {
          warrantStatus[val] = Constants.STATUS_LOADING;
        });
        state.warrantStatus = warrantStatus;
      } else {
        const { stockStatus } = state;
        statusIndices.forEach((val) => {
          stockStatus[val] = Constants.STATUS_LOADING;
        });
        state.stockStatus = stockStatus;
      }
    });
    builder.addCase(fetchData.fulfilled, (state, action) => {
      state.status = Constants.STATUS_SUCCESS;

      /* Update state for a specified column only */
      const { columnIndex } = action.payload; /* If undefined, means replace all. */
      const { stockType } = action.payload;
      const { data } = action.payload;
      const statusIndices = columnIndex == '' ? [...Array(MAX_STOCKS).keys()] : [columnIndex];

      if (stockType == 'warrants') {
        const warrantCode = state.warrantCodes[columnIndex];
        if (warrantCode) {
          const warrantInfo = data.stocks?.find(
            (stock) => stock.counter.toLowerCase() == warrantCode,
          );
          if (warrantInfo) {
            state.warrantData[warrantCode] = warrantInfo;
          }
        } else {
          /* Update all if not value found */
          data.stocks.forEach((element) => {
            state.warrantData[element.counter.toLowerCase()] = element;
          });
        }
      } else {
        const stockCode = state.stockCodes[columnIndex];
        if (stockCode) {
          const stockInfo = data.stocks?.find((stock) => stock.counter.toLowerCase() == stockCode);
          if (stockInfo) {
            state.stockData[stockCode] = stockInfo;
          }
        } else {
          /* Update all if not value found */
          data.stocks.forEach((element) => {
            state.stockData[element.counter.toLowerCase()] = element;
          });
        }
        /* Update suggestion */
        if (Object.keys(state.stockCodes).length !== 0) {
          state.stockSuggestion = data.suggested_stocks_comparison;
        } else {
          state.stockSuggestion = {};
        }
      }

      if (state.screenType == 'warrants') {
        const { warrantStatus } = state;
        statusIndices.forEach((val) => {
          warrantStatus[val] = Constants.STATUS_IDLE;
        });
        state.warrantStatus = warrantStatus;
      } else {
        const { stockStatus } = state;
        statusIndices.forEach((val) => {
          stockStatus[val] = Constants.STATUS_IDLE;
        });
        state.stockStatus = stockStatus;
      }
    });
    builder.addCase(fetchData.rejected, (state, action) => {
      state.status = Constants.STATUS_FAILED;
      state.error = action.error.message;
      const statusIndices =
        action.meta.arg.columnIndex == ''
          ? [...Array(MAX_STOCKS).keys()]
          : [action.meta.arg.columnIndex];

      if (state.screenType == 'warrants') {
        const { warrantStatus } = state;
        statusIndices.forEach((val) => {
          warrantStatus[val] = Constants.STATUS_IDLE;
        });
        state.warrantStatus = warrantStatus;
      } else {
        const { stockStatus } = state;
        statusIndices.forEach((val) => {
          stockStatus[val] = Constants.STATUS_IDLE;
        });
        state.stockStatus = stockStatus;
      }
    });
  },
});

export default stocksComparisonSlice.reducer;
export const {
  setStockCodes,
  setWarrantCodes,
  setCompareType,
  clearStockCodes,
  clearWarrantCodes,
  chooseStock,
  chooseWarrant,
  removeStock,
  removeWarrant,
  setStockReadyForInit,
  setWarrantReadyForInit,
} = stocksComparisonSlice.actions;

export const selectScreenType = (state) => state.marketsStocksComparison.screenType;
export const selectStockCodes = (state) => state.marketsStocksComparison.stockCodes;
export const selectWarrantCodes = (state) => state.marketsStocksComparison.warrantCodes;
export const selectStockSuggestion = (state) => state.marketsStocksComparison.stockSuggestion;
export const selectStockData = (state) => state.marketsStocksComparison.stockData;
export const selectWarrantData = (state) => state.marketsStocksComparison.warrantData;
export const selectStockReadyForInit = (state) => state.marketsStocksComparison.stockReadyForInit;
export const selectWarrantReadyForInit = (state) =>
  state.marketsStocksComparison.warrantReadyForInit;
export const selectStockStatus = (state) => state.marketsStocksComparison.stockStatus;
export const selectWarrantStatus = (state) => state.marketsStocksComparison.warrantStatus;
