import axios from 'axios';
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { OverlayTrigger, Tooltip, Table } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import Card from 'react-bootstrap/Card';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Loader from '../../../../components/Loader';
import TableSearchBar from '../../../../components/TableSearchBar';
import Pagination from '../../../../components/Pagination';
import AddTransaction from './AddTransaction';
import DepositWithdraw from './DepositWithdraw';
import CorporateAction from './CorporateAction';
import AddUpdateTransactionModal from './AddUpdateTransactionModal';
import ModalDialog from '../../../../components/ModalDialog';
import DepositWithdrawTransactionModal from './DepositWithdrawTransactionModal';
import Brokers from './Brokers';
import APIErrorHandler from '../../../../components/APIErrorHandler';
import { fetchData, deleteTransactionFetchData } from './transactionSlice';
import { fetchData as fetchBrokersData } from './portfolioBrokerSlice';
import CorporateActionTransactionModal from './CorporateActionTransactionModal';

const { CancelToken } = axios;

function TransactionTable() {
  const dispatch = useDispatch();
  const source = CancelToken.source();
  const selectedPortfolio = useSelector((state) => state.portfolio.selected);
  const selectedLayout = useSelector((state) => state.portfolio.selectedLayout);
  const transactions = useSelector((state) => state.transaction.transactionsList);
  const result = useSelector((state) => state.transaction.result);
  const folder = selectedPortfolio.id;
  const [notificationMessage, setNotificationMessage] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [modal, setModal] = useState({ show: false, type: '' });
  const [editingTransaction, setEditingTransaction] = useState(null);
  const [csvData, setCsvData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const stateError = useSelector((state) => state.transaction.err);
  const [error, setError] = useState('');

  const [searchVal, setSetSearchVal] = useState('');

  useEffect(() => {
    dispatch(fetchData({ folder, cancelToken: source.token }));
    dispatch(fetchBrokersData({ folder, cancelToken: source.token }));
  }, [selectedPortfolio]);

  useEffect(() => {
    setError(stateError);
  }, [stateError]);

  const editTransaction = (transaction) => {
    if (transaction.action === 'Cash') {
      setModal({ show: true, type: 'cash' });
    } else if (transaction.action === 'Buy' || transaction.action === 'Sell') {
      setModal({ show: true, type: 'transaction' });
    } else {
      setModal({ show: true, type: 'corporateAction' });
    }
    setEditingTransaction(transaction);
  };

  const scrollToTable = () => {
    const transactionTable = document.getElementById('transactionTable');
    if (transactionTable) {
      const scrollToOptions = {
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      };
      // Add pixels to the top position to scroll after the banner
      const topPosition = transactionTable.getBoundingClientRect().top + window.scrollY - 165;
      scrollToOptions.top = topPosition;
      window.scrollTo(scrollToOptions);
    }
  };

  useEffect(() => {
    if (notificationMessage && notificationMessage.message) {
      const message = Array.isArray(notificationMessage.message) ? (
        <div>
          {notificationMessage.message.map((msg, index) => (
            <div key={index}>
              {msg}
              <br />
            </div>
          ))}
        </div>
      ) : (
        <div>{notificationMessage.message}</div>
      );

      toast(message, {
        type: notificationMessage.success ? 'success' : 'error',
        onOpen: () => scrollToTable(),
      });
    }
  }, [notificationMessage]);

  const handleDeleteTransaction = useCallback((transactionId) => {
    setModal({ show: true, type: 'DeleteDialog', transactionId });
  }, []);

  const handleCloseModal = useCallback(() => {
    setModal({ show: false, type: '' });
  }, []);

  const confirmDeleteTransaction = useCallback(() => {
    const params = {
      id: modal.transactionId,
      folder,
      selectedLayout,
      refreshPortfolio: selectedPortfolio?.counters?.length === 0,
      cancelToken: source.token,
    };
    dispatch(deleteTransactionFetchData(params));
    handleCloseModal();
  }, [dispatch, folder, handleCloseModal, modal.transactionId, selectedLayout, source.token]);

  useEffect(() => {
    if (transactions.length > 0) {
      setFilteredTransactions(transactions);
    } else {
      setLoading(false);
      setCsvData([]);
    }
  }, [transactions]);

  useEffect(() => {
    if (result) {
      setNotificationMessage(result);
    }
  }, [result]);

  // Pagination function
  const ITEMS_PER_PAGE = 6;
  const totalTransactionCount = filteredTransactions.length;
  const indexOfLastTransaction = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstTransaction = indexOfLastTransaction - ITEMS_PER_PAGE;

  const currentTransactions = filteredTransactions.slice(
    indexOfFirstTransaction,
    indexOfLastTransaction,
  );

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= Math.ceil(totalTransactionCount / ITEMS_PER_PAGE)) {
      setCurrentPage(newPage);
    }
  };

  useEffect(() => {
    if (!filteredTransactions || filteredTransactions.length === 0) {
      return;
    }

    const copyTransactions = filteredTransactions.map((transaction) => ({
      Action: transaction.action,
      Name: transaction.stock_name,
      'Stock Code': transaction.marketAndCode,
      Price: transaction.price,
      Shares: transaction.shares,
      'Exchange Rate': transaction.exchange_rate,
      Commission: transaction.comm,
      Amount: transaction.amount,
      Date: transaction.date,
      Note: transaction.note,
    }));
    setCsvData(copyTransactions);
    setLoading(false);
  }, [filteredTransactions]);

  const AddTransactionButtonLabel = (
    <>
      <i className="fa-light fa-rectangle-history-circle-plus" /> Add Transactions
    </>
  );

  const DepositButtonLabel = (
    <>
      <i className="fa-sharp fa-light fa-money-bill-transfer" /> Deposit / Withdraw
    </>
  );

  return (
    <div className="g-mt-25 g-mb-25">
      <Card>
        <Card.Body>
          <div>
            <div className="row g-mb-20">
              <div className="col-lg-3">
                <Card.Title>Transactions</Card.Title>
              </div>
              <div className="col-lg-9">
                <div className="d-flex flex-wrap gap-3 justify-content-lg-end">
                  <div className="">
                    <AddTransaction label={AddTransactionButtonLabel} />
                  </div>
                  <div className="">
                    <CorporateAction label="Corporate Action" />
                  </div>
                  <div className="">
                    <DepositWithdraw label={DepositButtonLabel} />
                  </div>
                  <div className="d-flex flex-wrap flex-row gap-3 ">
                    <Brokers label="Brokers" />
                    {!loading && csvData && csvData.length > 0 && (
                      <CSVLink
                        data={csvData}
                        filename={`Portfolio-${selectedPortfolio.name}${
                          searchVal ? `-${searchVal}-` : '-'
                        }transactions-ShareInvestor`}
                        target="_blank"
                        className="btn btn-lg btn-light g-min-width-0">
                        <i className="fa-light fa-file-arrow-down" />
                      </CSVLink>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <Loader visible={loading} />
            <ToastContainer transition={Slide} />
            {error && <APIErrorHandler error={error} />}
            {!loading && (
              <>
                <div className="table-responsive">
                  <Table className="table table-hover" id="transactionTable">
                    <thead className="table-light">
                      <tr>
                        <th>Action</th>
                        <th>
                          <TableSearchBar
                            columnTitle="Name"
                            placeholder="Search..."
                            setFiltered={setFilteredTransactions}
                            listToSearch={transactions}
                            setSetSearchVal={setSetSearchVal}
                            searchKeys={['stock_name', 'marketAndCode', 'note']}
                          />
                        </th>
                        <th>Price</th>
                        <th>Shares</th>
                        <th>Exchange Rate</th>
                        <th>Commission</th>
                        <th>Amount</th>
                        <th>Date</th>
                        <th>Note</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {!(currentTransactions?.length > 0) && (
                        <tr>
                          <td colSpan={10} className="text-center text-muted m-5">
                            No data found.
                          </td>
                        </tr>
                      )}
                      {currentTransactions &&
                        currentTransactions.length > 0 &&
                        currentTransactions.map(
                          (transaction, index) =>
                            transaction.action !== null && (
                              <tr key={index}>
                                <td>
                                  <span
                                    className={`badge g-text-size-14 g-fw-400 g-min-width-80 ${
                                      transaction.action === 'Buy'
                                        ? 'text-bg-success'
                                        : transaction.action === 'Cash'
                                        ? 'text-bg-warning'
                                        : transaction.action === 'Sell'
                                        ? 'text-bg-danger'
                                        : 'text-bg-info'
                                    }`}>
                                    {transaction.action}
                                  </span>
                                </td>
                                <td>
                                  {transaction.stock_name}
                                  <br />
                                  <small>
                                    {transaction.action === 'Cash' ? '' : transaction.marketAndCode}
                                  </small>
                                </td>
                                <td>{transaction.price}</td>
                                <td>{transaction.shares}</td>
                                <td>{transaction.exchange_rate}</td>
                                <td>{transaction.comm}</td>
                                <td>{transaction.amount}</td>
                                <td>{transaction.date}</td>
                                <td>{transaction.note}</td>
                                <td>
                                  <OverlayTrigger
                                    placement="top"
                                    key={`edit_transaction_${transaction.id}`}
                                    overlay={<Tooltip>Edit Transaction</Tooltip>}>
                                    <span
                                      role="button"
                                      className="g-ml-5 g-mr-5 icon"
                                      onClick={() => editTransaction(transaction)}>
                                      <i className="fa-light fa-pen-to-square" />
                                    </span>
                                  </OverlayTrigger>
                                  <OverlayTrigger
                                    placement="top"
                                    key={`delete_${transaction.id}`}
                                    overlay={<Tooltip>Delete Transaction</Tooltip>}>
                                    <span
                                      role="button"
                                      className="g-ml-5 g-mr-5 icon"
                                      onClick={() => handleDeleteTransaction(transaction.id)}>
                                      <i className="fa-light fa-square-xmark" />
                                    </span>
                                  </OverlayTrigger>
                                </td>
                              </tr>
                            ),
                        )}
                    </tbody>
                  </Table>
                </div>
                <Pagination
                  rowOffset={indexOfFirstTransaction}
                  currentPageRows={currentTransactions.length}
                  totalRows={totalTransactionCount}
                  currentPage={currentPage}
                  totalPages={Math.ceil(totalTransactionCount / ITEMS_PER_PAGE)}
                  handlePageChange={handlePageChange}
                  classes="g-p-0"
                />
              </>
            )}
          </div>
          <ModalDialog
            show={modal.type === 'DeleteDialog' && modal.show}
            handleClose={handleCloseModal}
            title="Delete Transaction"
            body="Are you sure you want to delete this transaction?"
            handleSave={confirmDeleteTransaction}
            saveLabel="Delete"
            saveVariant="danger"
          />
          <AddUpdateTransactionModal
            show={modal}
            setShow={setModal}
            editingTransaction={editingTransaction}
            showUploadCSV
          />
          <DepositWithdrawTransactionModal
            show={modal}
            setShow={setModal}
            editingTransaction={editingTransaction}
          />
          <CorporateActionTransactionModal
            show={modal}
            setShow={setModal}
            editingTransaction={editingTransaction}
          />
        </Card.Body>
      </Card>
    </div>
  );
}

export default TransactionTable;
