import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import store from '../../../utils/store';
import MarketScreenerMain from './MarketScreenerMain';

function MarketScreener({
  market,
  markets_with_access, // used to check if selected_market is valid
  criteria_config,
  criteria_groups,
  stock_categories,
  porfolio_series_templates,
  portfolio_folders,
  watchlist_folders,
}) {
  return (
    <Provider store={store}>
      <MarketScreenerMain
        market={market}
        markets_with_access={markets_with_access}
        criteria_config={criteria_config}
        criteria_groups={criteria_groups}
        stock_categories={stock_categories}
        porfolio_series_templates={porfolio_series_templates}
        portfolio_folders={portfolio_folders}
        watchlist_folders={watchlist_folders}
      />
    </Provider>
  );
}

MarketScreener.propTypes = {};

MarketScreener.propTypes = {
  market: PropTypes.string,
  markets_with_access: PropTypes.oneOfType([PropTypes.array]),
  criteria_config: PropTypes.oneOfType([PropTypes.object]),
  criteria_groups: PropTypes.oneOfType([PropTypes.object]),
  stock_categories: PropTypes.oneOfType([PropTypes.object]),
  porfolio_series_templates: PropTypes.oneOfType([PropTypes.object]),
  portfolio_folders: PropTypes.oneOfType([PropTypes.array]),
  watchlist_folders: PropTypes.oneOfType([PropTypes.array]),
};

export default MarketScreener;
