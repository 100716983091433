import { JSHttpRequest } from "./cdajax";

///////////////////////////////////////////////
// helper functions
///////////////////////////////////////////////

window.isDefined = function(type)
{
  return type != 'undefined' && type != 'unknown' && type != undefined;
}

// log to firebug console for debuging purpose
window.log = function(msg)
{
  if(isDefined(typeof console))
  {
    console.log(msg); //this console.log will display log from comet and screener.
  }
}

/////////////////////////////////////////////////////////
// server callback functions
/////////////////////////////////////////////////////////

// notify server error on this channel
window.p_error = function(msg)
{
  var error_msg = "p_error:" + msg;
  if(pushPage.engine.policy.debug_mode)
    alert(error_msg);
  else
    log(error_msg);
  pushPage.engine.handleEvent("on_server_error");
}

window.p_warning = function(msg)
{
  log("p_warning:" + msg);
}

// notify to clear all historical data, and prepare for fresh start
// for example, in the case that start a new trading day
window.p_clear = function(channel_, sub_id_, version_)
{
  if(pushPage.channel != channel_)
  {
    log("p_clear: pushPage.channelId != channel");
    pushPage.engine.handleEvent("on_general_error");
    return;
  }

  var target_table = pushPage.getTableBySubscriptionId(sub_id_);

  if(target_table)
  {
    if(target_table.getStatus() == BaseTable.RUNNING || target_table.getStatus()== BaseTable.UPDATING)
    {
      target_table.onDataClear();
      target_table.subscription.version = version_;
    }
    else
    {
      log("p_clear: updating table with wrong status(" + target_table.getStatus() + ")");
    }
  }
  else
  {
    log("p_clear: table with subscription id = " + sub_id_ + " not found.");
  }
}

// pass back updates on individual subscription item
window.p = function(channel_, sub_id_, version_, is_snap_shot_, item_data_)
{
  //store the scripts for future process
  queue.push([channel_, sub_id_, version_, is_snap_shot_, item_data_]);

  if (main_thread_started)
    return;
  else
  {
    //start main thread
    var main_thread = setInterval(function(){
      if (!busy)
      {
        //execute some scripts one at a time
        for(var i= 0; i < scripts_per_execution; i++)
        {
          var arrArgs = queue.shift();
          if (arrArgs)
          {
            busy = true;
            firefox_hack();

            if(pushPage.channel != arrArgs[0])
            {
              log("p: pushPage.channel != sever channel");
              log("p: pushPage.channel == " + pushPage.channel);
              log("p: sever channel == " + arrArgs[0]);
              pushPage.engine.handleEvent("on_p_error");
              return;
            }

            var table = pushPage.getTableBySubscriptionId(arrArgs[1]);

            if(table)
            {
              if(table.isScheduled())
              {
                table.setStatus(BaseTable.UPDATING);
                table.onDataUpdate(arrArgs[4]);
                table.subscription.version = arrArgs[2];
              }
              else
              {
                log("p: updating data with wrong table status(" + table.getStatus() + ")");
              }
            }
            else
            {
              log("p: table with subscription id = " + arrArgs[1] + " not found.");
            }
            busy = false;
          }
          else
          {
            clearInterval(main_thread);
            main_thread_started = false;
            break;
          }
        }
      }
    }, scripts_execution_interval);
    main_thread_started = true;
  }
}

// notify back the result of subscription
window.p_s = function(channel_, data_)
{
  if(pushPage.channel == null)
  {
    pushPage.channel = channel_;
  }
  else if(pushPage.channel != channel_)
  {
    log("p_s: wrong channel id in subscription callback");
    pushPage.engine.handleEvent("on_general_error");
    return;
  }

  channel_returned = true;

  var tables = pushPage.getTablesByStatus(BaseTable.PENDING);

  for(var i = 0; i<tables.length; ++i)
  {
    var table = tables[i];
    var sub_version = data_[table.subscription.id + ""];
    if(sub_version != undefined )
    {
      // update version
      table.subscription.version = parseInt(sub_version);
      table.setStatus(BaseTable.RUNNING);
    }
  }
}

/////////////////////////////////////////////////////////////////
// implementation helpers
/////////////////////////////////////////////////////////////////

// generate a random number according to current timestamp
function generateUniqueStamp()
{
  return new Date().getTime() + "_" + Math.floor(Math.random());
}

function firefox_hack () {
  var state = pushPage.engine.state;
  if (state == 'IFraming'){
    if (is_firefox){
      var fake_iframe;
      if (fake_iframe == null){
        fake_iframe = document.createElement('iframe');
        fake_iframe.style.display = 'none';
      }
      var body = document.body;
      body.appendChild(fake_iframe);
      body.removeChild(fake_iframe);
    }
  }
}
/////////////////////////////////////////////////////////////////
// protocol implementation classes
/////////////////////////////////////////////////////////////////

// connection settings
class Connection {
  constructor() {
    this.port = 80;
    this.url_path = "/1";
    this.domain = "shareinvestor.com";
    this.same_domain = false;

    this.transports = [{
      name: 'iframe',
      errors: 0,
      max_errors: 0
    }, {
      name: 'lpoll',
      errors: 0,
      max_errors: 3
    }, {
      name: 'poll',
      errors: 0
    }]; // trying sequence

    this.errorManager = {
      subscribe: {
        consecutive_errors: 0,
        max_errors: 10
      },
      poll: {
        consecutive_errors: 0,
        max_errors: 10
      },
      iframe: {
        consecutive_errors: 0,
        max_errors: 3
      }
    };

    var self = this;
    // come from same domain
    if (window.document.domain.indexOf(this.domain) != -1) {
      this.currTransport = this.transports[0];
      try {
        self.same_domain = true;
      }
      catch (e) {
        log("set cross domain failed." + e.message);
      }
    }
    else
      this.currTransport = this.transports[1];
  }
  setTransportMethod(method) {
    var transport_method = method == 'iframe' ? 0 : method == 'lpoll' ? 1 : 2;
    this.setCurrentTransportMethod(transport_method);
  }
  setCurrentTransportMethod(transport_method) {
    if (transport_method == 0) {
      if (this.same_domain) {
        this.currTransport = this.transports[0];
      }
      else {
        this.currTransport = this.transports[1];
      }
    }
    else {
      this.currTransport = this.transports[transport_method];
    }
    log("setCurrentTransportMethod(): " + this.currTransport);
  }
  setStreamingServerHost(host) {
    this.host = host;
    log("setStreamingServerHost(): " + this.host);
  }
  setStreamingMode(streaming_mode_) {
    this.streaming_mode = streaming_mode_;
  }
  getFeederUrl() {
    const port = [80, 443].includes(this.port) ? "" : `:${this.port}`;
    return `${window.location.protocol}//${this.host}${port}${this.url_path}`;
  }
  getOrigin() {
    const port = [80, 443].includes(this.port) ? "" : `:${this.port}`;
    return `${window.location.protocol}//${this.host}${port}`;
  }
  _downgradeTransport() {
    var index = this.transports.indexOf(this.currTransport);
    this.currTransport = this.transports[Math.min(this.transports.length - 1, ++index)];
    this.onDowngrade();
    log("_downgradeTransport(): " + this.currTransport);
    return this.currTransport;
  }
  onDowngrade() {
  }
}

class Policy {
  constructor() {
    this.idleTimeout = 60 * 1000;
    this.subscribeInterval = 2000;
    this.pollingInterval = 5 * 1000;
    this.pollingTimeout = 60 * 1000;
    this.reloadWhenError = false;
    this.debug_mode = false;
    this.clear_script_interval = 2000;
    this.reloadTimeout = 5000;
    this.iframeInterval = 1000;
  }
}


class Engine {
  constructor(page_) {
    this.state = "Stopped";
    this.policy = new Policy();
    this.connection = new Connection();
    this.stop_flag = true;
    this.iframe_id = "streaming_frame";
    this.push_page = page_;
    this.clear_script_mode = true;
  }
  start() {
    this.stop_flag = false;
    this.handleEvent("start");
  }
  setStreamingMode(streaming_mode_) {
    this.connection.setStreamingMode(streaming_mode_);
  }
  stop() {
    this.handleEvent("stop");
  }
  isRunning() {
    return (this.state != "Stopped" && this.state != "Initializing");
  }
  isStopped() {
    return (this.state == "Stopped");
  }
  handleEvent(event) {
    log("- handle event '" + event + "' under state '" + this.state + "'");

    var actionTransitionFunction = this._statusTransitionMap[this.state][event];

    var nextState = (actionTransitionFunction) ? actionTransitionFunction.call(this) : this._unexpectedEvent(event);

    if (!nextState)
      nextState = this.state; // no return just stay put

    if (!this._statusTransitionMap[nextState])
      nextState = this._undefinedState(nextState);

    // check and announce state change
    if (this.state != nextState) {
      var prevState = this.state;
      this.state = nextState;
      this.onStatusChange(nextState, prevState);
    }
  }
  _unexpectedEvent(event) {
    if (event == "stop" || event == "on_general_error" || event == "on_server_error") // signal to stop
    {
      this.stop_flag = true;
      if (event.search(/_error$/) > 0 && this.policy.reloadWhenError)
        setTimeout(function () {
          window.location.reload();
        }, this.policy.reloadTimeout);
      return "Stopped";
    }
    log("Engine handled unexpected event '" + event + "' under status '" + this.state + "'");
    return "Stopped";
  }
  _undefinedState(state_) {
    log("Engine transitioned to undefined state '" + state_ + "' from state '" + this.state + "'");
    return "Stopped";
  }
  _startTransport() {
    var transport = this.connection.currTransport;
    switch (transport.name) {
      case 'poll':
        this._poll(false);
        return "Polling";
      case 'lpoll':
        this._poll(true);
        return "LPolling";
      case 'iframe':
        var self = this;
        setTimeout(function () {
          self._iframe();
        }, 1000);
        return "IFraming";
      default:
        return "Stopped";
    }
  }
  _repoll(longpoll_) {
    var self = this;
    setTimeout(function () {
      self._poll(longpoll_);
    }, self.policy.pollingInterval);
  }
  _poll(longpoll_, sendMessage_) {
    if (this.stop_flag) {
      return;
    }

    try {
      this.connection.currTransport.requestTime = new Date().getTime();

      // compose polling url
      var path = "?action=" + (longpoll_ ? "lpoll" : "poll");
      path += "&channel=" + this.push_page.channel;

      // indicate last version received for each subscription
      var tables = this.push_page.getTablesByStatus(BaseTable.RUNNING);
      tables = tables.concat(this.push_page.getTablesByStatus(BaseTable.UPDATING));

      for (var i = 0; i < tables.length; ++i) {
        var table = tables[i];
        path += "&subscription" + (i + 1) + "=" + table.subscription.id;
        path += "&version" + (i + 1) + "=" + table.subscription.version;
      }

      var sendMessage = sendMessage_ != undefined ? sendMessage_ : true;

      var events = sendMessage ? ["on_poll_ok", "on_poll_error"] : [];
      this._sendMessage(path, events);
    }
    catch (e) {
      log("*** fail in poll operation: " + e);
      this.handleEvent("on_general_error");
    }
  }
  _iframe() {
    if (this.stop_flag) {
      return;
    }

    this.connection.currTransport.requestTime = new Date().getTime();
    var url = this.connection.getFeederUrl() + "?action=iframe";
    url += "&channel=" + this.push_page.channel + "&ts=" + generateUniqueStamp() + "&tk=" + this.push_page.getData('token');

    var elem_iframe = document.getElementById(this.iframe_id);
    if (!elem_iframe) {
      elem_iframe = document.createElement("iframe");
      elem_iframe.name = elem_iframe.id = this.iframe_id;
      document.body.appendChild(elem_iframe);
    }
    elem_iframe.style.display = "none";

    var self = this;
    var iframe_disconnected = function () {
      self.handleEvent("on_iframe_error");
    };

    if (isDefined(typeof elem_iframe.onreadystatechange)) {
      elem_iframe.onreadystatechange = function () {
        log("iframe state changed to " + elem_iframe.readyState);
        if (elem_iframe.readyState == 'complete') {
          //only when the connection with the server is closed, the ready state will be changed to complete
          iframe_disconnected();
        }
      };
    }

    else {
      elem_iframe.onload = iframe_disconnected;
      elem_iframe.onerror = iframe_disconnected;
    }
    elem_iframe.src = url; //activiate iframe to get data

    this.handleEvent("on_iframe_ok");

    return false;
  }
  onStatusChange(new_, old_) {
    log("- engine state change from '" + old_ + "' to '" + new_ + "'.");
  }
  _sendMessage(path_, events_) {
    var url = this.connection.getFeederUrl() + path_ + "&ts=" + generateUniqueStamp() + "&tk=" + this.push_page.getData('token');
    var self = this;

    // create cross domain javascript request
    var xmlHttp = new JSHttpRequest();

    if (events_ && events_ instanceof Array && events_.length == 2) {
      var responsed = false; // prevent repeat trigger
      xmlHttp.onreadystatechange = function () {
        if (xmlHttp.readyState == 4 && !responsed) {
          responsed = true;
          if (xmlHttp.status != 200 && xmlHttp.status != 0) {
            if (!!events_[1])
              self.handleEvent(events_[1]);
          }
          else {
            if (self.clear_script_mode) {
              //clear up newly added script
              setTimeout(function () {
                xmlHttp.abort();
              }, self.policy.clear_script_interval);
            }

            var response_ok = self._checkResponse(events_);

            if (response_ok && !!events_[0]) {
              self.handleEvent(events_[0]);
            }
            else if (!!events_[1]) {
              self.handleEvent(events_[1]);
            }
          }
        }
      };
      // issue a fail event when time out, and we still not get the data
      setTimeout(function () {
        if (!responsed) {
          log("XMlhttp request timeout");
          xmlHttp.abort();
          if (!!events_[1])
            self.handleEvent(events_[1]);
        }
      }, this.policy.pollingTimeout);
    }
    xmlHttp.open("GET", url, true);
    log("- send url " + url);
    xmlHttp.send(null);
  }
  _checkResponse(events_) {
    if (events_.length > 1 && events_[0].search(/_subscribe_/) > 0 && events_[1].search(/_subscribe_/) > 0) {
      var channel = this.push_page.channel;
      if (channel != null && channel.length > 5 & channel_returned) {
        //reset channel_returned
        channel_returned = false;
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }
  getIFrameID() {
    return this.iframe_id;
  }
}

Engine.prototype._statusTransitionMap =
{
  Stopped:
  {
    start: function()
    {
      // subscribe all pending tables
      var pending_tables = this.push_page.getTablesByStatus(BaseTable.PENDING);
      if(pending_tables.length > 0) {
        this.push_page._subscribe(pending_tables);
      }
      return "Initializing";
    }
  },
  Initializing:
  {
    on_subscribe_ok: function()
    {
      this.connection.errorManager['subscribe'].consecutive_errors = 0;

      // subscribe later pending tables, which can only direcly subscribe in runing status
      var pending_tables = this.push_page.getTablesByStatus(BaseTable.PENDING);
      if(pending_tables.length > 0)
        this.push_page._subscribe(pending_tables);
      return this._startTransport();
    },
    on_subscribe_error: function()
    {
      var error_manager = this.connection.errorManager['subscribe'];
      error_manager.consecutive_errors += 1;
      if (error_manager.consecutive_errors >= error_manager.max_errors)
      {
        log('There is problem connecting to server. Please try again later. Sorry for any inconvenience caused.');
        this.stop_flag = true;
      }
      else
      {
        // resubscribe all pending tables
        var self = this;
        var pending_tables = this.push_page.getTablesByStatus(BaseTable.PENDING);
        if (pending_tables.length > 0)
          setTimeout(function(){
            self.push_page._subscribe(pending_tables)
          }, self.policy.subscribeInterval);
      }
    }
  },
  Polling:
  {
    on_poll_ok: function()
    {
      this.connection.errorManager['poll'] = 0;
      this._repoll(false); // poll again
    },
    on_poll_error: function()
    {
      var error_manager = this.connection.errorManager['poll'];
      error_manager.consecutive_errors += 1;

      if (error_manager.consecutive_errors >= error_manager.max_errors)
      {
        log('There is a problem connecting to server. Please try again later. Sorry for any inconvenience caused.');
        this.stop_flag = true;
      }
      else
      {
        this._repoll(false);// poll again
      }
    },
    on_subscribe_ok : function()
    {
      this.connection.errorManager['subscribe'].consecutive_errors = 0;
    },
    on_subscribe_error: function()
    {
      var error_manager = this.connection.errorManager['subscribe'];
      error_manager.consecutive_errors += 1;

      if (error_manager.consecutive_errors >= error_manager.max_errors)
      {
        log('There is a problem connecting to server. Please try again later. Sorry for any inconvenience caused.');
        this.stop_flag = true;
      }
      else
      {
        // resubscribe all pending tables
        var self = this;
        var pending_tables = this.push_page.getTablesByStatus(BaseTable.PENDING);
        if (pending_tables.length > 0)
          setTimeout(function(){
            self.push_page._subscribe(pending_tables)
          }, self.policy.subscribeInterval);
      }
    }
  },
  LPolling:
  {
    on_poll_ok: function()
    {
      // reset historic errors
      this.connection.currTransport.errors = 0;
      this._repoll(true); // poll again
    },
    on_poll_error: function()
    {
      var transport = this.connection.currTransport;
      transport.errors = (transport.errors || 0) + 1;

      if(transport.errors > transport.max_errors)
      {
        this.connection._downgradeTransport();
        return this._startTransport();
      }
      this._repoll(true); // poll again
    },
    on_subscribe_ok : function()
    {
      this.connection.errorManager['subscribe'].consecutive_errors = 0;
      this._poll(true, false);
    },
    on_subscribe_error: function()
    {
      var error_manager = this.connection.errorManager['subscribe'];
      error_manager.consecutive_errors += 1;
      if (error_manager.consecutive_errors >= error_manager.max_errors)
      {
        log('There is a problem connecting to server. Please try again later. Sorry for any inconvenience caused.');
        this.stop_flag = true;
      }
      else
      {
        // resubscribe all pending tables
        var self = this;
        var pending_tables = this.push_page.getTablesByStatus(BaseTable.PENDING);
        if (pending_tables.length > 0)
          setTimeout(function(){
            self.push_page._subscribe(pending_tables)
          }, self.policy.subscribeInterval);
      }
    }
  },
  IFraming:
  {
    on_iframe_error: function()
    {
      var transport = this.connection.currTransport;
      var error_manager = this.connection.errorManager['iframe'];

      // counter error if has error in 5 minutes
      if(new Date().getTime() - transport.requestTime < 5*60*1000)
        transport.errors = (transport.errors || 0) + 1;
      else
      {
        transport.errors = 0;
        error_manager.consecutive_errors += 1;
      }

      if(transport.errors > transport.max_errors || error_manager.consecutive_errors >= error_manager.max_errors)
      {
        this.connection._downgradeTransport();
        return this._startTransport();
      }

      var self = this;
      setTimeout(function(){
        self._iframe(); // restart a new iframe
      }, self.policy.iframeInterval);
    },
    on_iframe_ok: function()
    {
      var error_manager = this.connection.errorManager['iframe'];
      error_manager.consecutive_errors = 0;
    },
    on_subscribe_ok : function()
    {
      this.connection.errorManager['subscribe'].consecutive_errors = 0;
    },
    on_subscribe_error: function()
    {
      var error_manager = this.connection.errorManager['subscribe'];
      error_manager.consecutive_errors += 1;

      if ( error_manager.consecutive_errors >= error_manager.max_errors)
      {
        log('There is problem connecting to streaming server. Please try again later. Sorry for any inconvenience caused.');
        this.stop_flag = true;
      }
      else
      {
        var self = this;
        var pending_tables = this.push_page.getTablesByStatus(BaseTable.PENDING);
        if (pending_tables.length > 0)
          setTimeout(function(){
            self.push_page._subscribe(pending_tables)
          }, self.policy.subscribeInterval);
      }
    }
  }
};


export class DataSubscription {
  constructor(adapter_, query_, fields_, snapShotRequired_) {
    this.id = DataSubscription._getUniqueId(); // the unique subscriptin id within a channel
    this.adapter = adapter_;
    this.query = query_;
    this.fields = fields_ ? fields_ : [];
    this.snapShotRequired = snapShotRequired_ != undefined ? snapShotRequired_ : true;

    var match = query_ ? (query_.match(/\.(\w{2})$/) ? query_.match(/\.(\w{2})$/) : (query_.match(/\.(\w{3})$/) ? query_.match(/\.(\w{3})$/) : undefined)) : undefined;
    if (match)
      this.market = match[1];

    else
      this.market = undefined;
  }
  toUrlString(position_) {
    if (position_ == undefined)
      position_ = 1;
    var str = "&id" + position_ + "=" + this.id;
    str += "&adapter" + position_ + "=" + this.adapter;
    str += "&query" + position_ + "=" + this.query;
    str += "&snapshot" + position_ + "=" + (this.snapShotRequired ? 1 : 0);

    if (this.fields && this.fields.length > 0) {
      str += "&fields" + position_ + "=" + this.fields.join(',');
    }
    return str;
  }
}

DataSubscription._getUniqueId = (function()
{
  var subscriptionIdStepper = 0;

  function constructor() {
    return "" + (++subscriptionIdStepper);
  }

  return constructor;
})();


export class BaseTable {
  constructor(table_id_, subscription_) {
    if (!table_id_)
      return; // called as a prototype object
    this.html_id = table_id_;
    this.status = BaseTable.IDLE;
    this.subscription = subscription_;
    this.market = subscription_.market;
    this.dom = document.getElementById(table_id_);
  }
  getId() {
    return this.html_id;
  }
  setSubscription(subscription_) {
    if (this.status == BaseTable.RUNNING || this.status == BaseTable.UPDATING)
      throw 'cannot change subscrption during run time';

    this.subscription = subscription_;
    this.market = subscription_.market;
  }
  // return whehter this table is scheduled to run
  isScheduled() {
    return (this.status == BaseTable.RUNNING || this.status == BaseTable.PENDING || this.status == BaseTable.UPDATING);
  }
  setStatus(stat) {
    if (this.status != stat) {
      if (stat == BaseTable.IDLE)
        this.onIdle();
      else if (stat == BaseTable.PENDING)
        this.onPending();
      else if (stat == BaseTable.RUNNING)
        this.onRunning();
      else if (stat == BaseTable.UPDATING)
        this.onUpdating();
      else if (stat == BaseTable.ERROR)
        this.onError();

      this.status = stat;
    }
  }
  getStatus() {
    return this.status;
  }
  onIdle() {
    log("BaseTable::onIdle() - " + this.html_id);
  }
  onPending() {
    log("BaseTable::onPending() - " + this.html_id);
  }
  onRunning() {
    log("BaseTable::onRunning() - " + this.html_id);
  }
  onUpdating() {
    log("BaseTable::onUpdating() - " + this.html_id);
  }
  onError() {
    log("BaseTable::onError() - " + this.html_id);
  }
  // callback when update data arrives
  onDataUpdate(update_data_) {
  }
  // callback when a clear instruction received
  onDataClear() {
  }
  //table time format 20120209093409 and convert into time object
  formatTime(date_time_) {
    var date_time = date_time_ + "";
    var year = date_time.substr(0, 4);
    var month = date_time.substr(4, 2);
    var date = date_time.substr(6, 2);
    var hour = date_time.substr(8, 2);
    var min = date_time.substr(10, 2);
    var seconds = date_time.substr(12, 2);

    return date + " " + m_names[parseInt(month, 10) - 1] + " " + year + " " + hour + ":" + min + ":" + seconds;
  }
  formatField(field_, value_, update_, volume_size) {
    // if volume size not provided, then we check if we can determine that from the market
    var formated_val = '-';
    if (!isDefined(volume_size))
      volume_size = getLotSizeForMarket(this.market);
    if (field_ == "volume" || field_ == "buy_vol" || field_ == "sell_vol")
      if (value_ == 0)
        formated_val = '-';
      else
        formated_val = formatNumberAddCommas(formatDecimal(value_ / volume_size, 3, false));
    else if (field_ == 'board_lot')
      if (value_ == 0)
        formated_val = '-';
      else
        formated_val = formatNumberAddCommas(value_);
    else if (field_ == 'value')
      if (value_ == 0)
        formated_val = '-';
      else
        formated_val = formatNumberAddCommas(formatDecimal(value_, 3, false));
    else if(field_ == "change" || field_ == "perc_change")
      if (value_ == 0)
        formated_val = '-';
      else
        formated_val = value_ > 0 ? '+' + value_ : value_;
    else if (field_ == "high" || field_ == "low")
      if (value_ == 0)
        formated_val = '-';
      else
        formated_val = formatNumberAddCommas(value_);
    else if ( field_ == "buy")
      if (value_ == 0)
        if (update_ && ('buy_vol' in update_) && update_.buy_vol != 0)
          formated_val = 'MO';
        else
          formated_val = '-';
      else
        formated_val = value_;
    else if ( field_ == "sell")
      if (value_ == 0)
        if (update_ && ('sell_vol' in update_) && update_.sell_vol != 0)
          formated_val = 'MO';
        else
          formated_val = '-';
      else
        formated_val = value_;
    else if (field_ == "status")
      if (value_ == 'NULL' || value_ == '')
        formated_val = '-';
      else
        formated_val = value_;
    else if (field_ == "price")
      formated_val = formatNumberAddCommas(value_);
    else if (field_ == "open")
      if (value_ == 0)
        formated_val = '-';
      else
        formated_val = formatNumberAddCommas(value_);
    else
      formated_val = value_;

    return formated_val ;
  }
}

// Table status
BaseTable.IDLE = 1;
BaseTable.PENDING = 2;
BaseTable.RUNNING = 3;
BaseTable.UPDATING = 4;
BaseTable.ERROR = 5;


export class PushPage {
  constructor() {
    this.engine = new Engine(this);
    this.channel = null;
    this.tables = new Object;
    this.data = new Object;
  }
  createEngine() {
    if (this.engine.isStopped())
      this.engine.start();
  }
  setStreamingMode(streaming_mode_) {
    this.engine.setStreamingMode(streaming_mode_);
  }
  setData(key_, value_) {
    this.data[key_] = value_;
  }
  getData(key_) {
    return this.data[key_];
  }
  setStreamingUsrMktAcs(encrypted_usr_mkt_acs_) {
    this.encrypted_usr_mkt_acs = encrypted_usr_mkt_acs_;
  }
  hasAnyMarketRealtimeStreamingAccess() {
    var streaming_mode = this.engine.connection.streaming_mode;
    return (streaming_mode && (streaming_mode['sgx'] == 'true' || streaming_mode['bursa'] == 'true')) ? 'true' : 'false';
  }
  hasMarketRealtimeStreamingAccess(market_notation) {
    var streaming_mode = this.engine.connection.streaming_mode;
    return (streaming_mode && streaming_mode[market_notation] == 'true') ? 'true' : 'false';
  }
  scheduleTable(table) {
    if (pushPage.getData("mode") == "static")
      return;

    if (this.tables[table.getId()]) {
      throw "Error: table id " + table.getId() + " already registered.";
    }
    else {
      this.tables[table.getId()] = table;
    }

    table.setStatus(BaseTable.PENDING);
    if (this.engine.isRunning())
      this._subscribe(table);
  }
  cancelTable(table) {
    if (this.engine.isRunning()) {
      this._unsubscribe(table);
    }
    if (this.tables[table.getId()]) {
      this.tables[table.getId()].setStatus(BaseTable.IDLE);
      this.tables[table.getId()] = null;
    }
  }
  cancelTables(tables_to_cancel) {
    var tables = new Array;
    for (var i = 0; i < tables_to_cancel.length; ++i) {
      var tbl_id = tables_to_cancel[i].getId();
      if (this.tables[tbl_id] != null) {
        tables.push(this.tables[tbl_id]);
        this.tables[tbl_id].setStatus(BaseTable.IDLE);
        this.tables[tbl_id] = null;
      }
    }
    if (this.engine.isRunning()) {
      this._unsubscribe(tables);
    }
    // cleanup
    for (var i = 0; i < tables.length; ++i) {
      tables[i] = null;
    }
    tables = null;
  }
  cancelAllTables() {
    var tables = new Array;
    for (var id in this.tables) {
      if (this.tables[id] != null) {
        tables.push(this.tables[id]);
        this.tables[id].setStatus(BaseTable.IDLE);
        this.tables[id] = null;
      }
    }
    if (this.engine.isRunning()) {
      this._unsubscribe(tables);
    }
    // cleanup
    for (var i = 0; i < tables.length; ++i) {
      tables[i] = null;
    }
    tables = null;
  }
  getTableById(tid) {
    return this.tables[tid];
  }
  getTablesByStatus(stat) {
    var tables = new Array;
    for (var id in this.tables) {
      if (this.tables[id] && this.tables[id].getStatus() == stat)
        tables.push(this.tables[id]);
    }

    return tables;
  }
  // find table by table id
  getTableBySubscriptionId(sub_id_) {
    for (var id in this.tables) {
      var table = this.tables[id];
      if (table && table.subscription.id == sub_id_)
        return table;
    }

    return null;
  }
  _subscribe(tables_) {
    var path = "?action=subscribe";
    path += "&origin=" + window.origin;
    if (this.encrypted_usr_mkt_acs != null)
      path += "&mktacs=" + this.encrypted_usr_mkt_acs;

    if (this.channel != null)
      path += "&channel=" + this.channel;

    if (tables_ instanceof Array) {
      for (var i = 0; i < tables_.length; ++i) {
        path += tables_[i].subscription.toUrlString(i + 1);
      }
    }
    else // single table
    {
      path += tables_.subscription.toUrlString(1);
    }

    this.engine._sendMessage(path, ["on_subscribe_ok", "on_subscribe_error"]);
  }
  _unsubscribe(tables_) {
    var path = "?action=unsubscribe";
    path += "&channel=" + this.channel;

    if (tables_ instanceof Array) {
      for (var i = 0; i < tables_.length; ++i) {
        path += "&subscription" + (i + 1) + "=" + tables_[i].subscription.id;
      }
    }
    else {
      path += "&subscription1=" + tables_.subscription.id;
    }

    this.engine._sendMessage(path);
  }
}

export let pushPage = new PushPage();
var channel_returned = false;
var is_firefox = /Firefox[\/\s](\d+\.\d+)/.test(navigator.userAgent);
var busy = false;
var queue = new Array();
var main_thread_started = false;
var scripts_per_execution = 40;
var scripts_execution_interval = 20;
var m_names = new Array("Jan", "Feb", "Mar",
  "Apr", "May", "Jun", "Jul", "Aug", "Sep",
  "Oct", "Nov", "Dec");

if (/MSIE (\d+\.\d+);/.test(navigator.userAgent))
{
  var ieversion=new Number(RegExp.$1);
  if (ieversion <= 6)
  {
    scripts_per_execution = 10;
    scripts_execution_interval = 20;
  }
  else if (ieversion == 7)
  {
    scripts_per_execution = 20;
    scripts_execution_interval = 20;
  }
}

if (!Array.prototype.indexOf)
{
  Array.prototype.indexOf = function(elt /*, from*/)
  {
    var len = this.length;

    var from = Number(arguments[1]) || 0;
    from = (from < 0)
    ? Math.ceil(from)
    : Math.floor(from);
    if (from < 0)
      from += len;

    for (; from < len; from++)
    {
      if (from in this &&
        this[from] === elt)
        return from;
    }
    return -1;
  };
}
