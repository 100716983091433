import React from 'react';
import DOMPurify from 'dompurify';

import FactsheetCollapseTable from '../../../components/FactsheetCollapseTable';

function ForwardEstimates({ tableData, showSparklines, currency, toggleFactsheetNav }) {
  return (
    <div className="mb-lg-5 mb-3 g-mt-30 g-lg-mt-80">
      <h4 className="mb-lg-4 mb-3">Forward Estimates - Financials</h4>
      <div>
        <h5 className="mt-lg-4 mt-3 mb-lg-4 mb-3">
          Profit & Loss (
          <span
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(currency),
            }}
          />{' '}
          '000)
        </h5>
        <FactsheetCollapseTable
          tableData={tableData.profit_loss_table}
          showSparklines={showSparklines}
          isConsensus
          tableID="forward_estimates_table_pl"
          toggleFactsheetNav={toggleFactsheetNav}
        />

        <h5 className="mt-lg-4 mt-3 mb-lg-4 mb-3">
          Balance Sheet (
          <span
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(currency),
            }}
          />{' '}
          '000)
        </h5>
        <FactsheetCollapseTable
          tableData={tableData.balance_sheet_table}
          showSparklines={showSparklines}
          isConsensus
          tableID="forward_estimates_table_bs"
          toggleFactsheetNav={toggleFactsheetNav}
        />

        <h5 className="mt-lg-4 mt-3 mb-lg-4 mb-3">
          Cash Flow (
          <span
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(currency),
            }}
          />{' '}
          '000)
        </h5>
        <FactsheetCollapseTable
          tableData={tableData.cash_flow_table}
          showSparklines={showSparklines}
          isConsensus
          tableID="forward_estimates_table_cf"
          toggleFactsheetNav={toggleFactsheetNav}
        />
      </div>
    </div>
  );
}

export default ForwardEstimates;
