import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'react-bootstrap';
import Select from 'react-select';
import { fetchData, fetchAllCounterLists } from './companyBuybackOverviewSlice';
import Loader from '../../../../components/Loader';

import CompanyNameLink from '../../../../components/StockInfoPopup/CompanyNameLink';

import MarketSelectDropdown from '../../../../components/MarketSelectDropdown';
import Notes from './Notes';
import APIErrorHandler from '../../../../components/APIErrorHandler';
import { createLoadableComponent } from '../../../../utils/Loadable';
import { updateSelectedMarket } from '../../../../components/MarketSelectDropdown/selectedMarketSlice';

const StockInfoPopup = createLoadableComponent(() =>
  import('../../../../components/StockInfoPopup'),
);
const { CancelToken } = axios;

function BuybackOverview({ market, availableMarkets }) {
  const dispatch = useDispatch();
  const [isSSR, setIsSSR] = useState(true);
  const source = CancelToken.source();
  const data = useSelector((state) => state.marketsCompanyBuybackOverview.data);
  const allCounterLists = useSelector(
    (state) => state.marketsCompanyBuybackOverview.allCounterLists,
  );
  const loadingCounterList = useSelector(
    (state) => state.marketsCompanyBuybackOverview.loadingCounterList,
  );
  const completed = useSelector((state) => state.marketsCompanyBuybackOverview.completed);
  const error = useSelector((state) => state.marketsCompanyBuybackOverview.err);
  const loading = useSelector((state) => state.marketsCompanyBuybackOverview.loading);
  const [selectedMarket, setSelectedMarket] = useState('SGX');
  const [selectedCounter, setSelectedCounter] = useState('');
  const [counterListOptions, setCounterListOptions] = useState(null);
  const [searchTerm] = useState(['counter']);

  function searchStock(stocks) {
    return stocks.filter((stock) => {
      return searchTerm.some((newStock) => {
        return stock[newStock].toString().indexOf(selectedCounter) > -1;
      });
    });
  }

  const handleOnChangeSelectedCounter = (newCounter) => {
    let counter = '';
    if (newCounter) {
      counter = newCounter.value;
    }
    setSelectedCounter(counter);
  };

  useEffect(() => {
    setIsSSR(typeof document === 'undefined');
    dispatch(updateSelectedMarket({ market: 'sgx' }));
    dispatch(
      fetchAllCounterLists({
        market: selectedMarket,
        cancelToken: source.token,
      }),
    );
    dispatch(
      fetchData({
        market: selectedMarket,
        cancelToken: source.token,
      }),
    );
    return () => {};
  }, []);

  // Get counter filter dropdown data
  useEffect(() => {
    if (!allCounterLists || !(allCounterLists && allCounterLists.length)) {
      return;
    }
    const options = allCounterLists.map(({ name, counter }) => ({
      value: counter,
      label: name,
    }));
    setCounterListOptions(options);
  }, [allCounterLists]);

  if (isSSR) {
    return <Loader visible={isSSR} classes="g-height-800" />;
  }

  if (error) {
    return <APIErrorHandler error={error} />;
  }

  return (
    <>
      <div className="g-mb-30 d-flex gap-3">
        <MarketSelectDropdown
          market="sgx"
          variant="light"
          validMarkets={availableMarkets}
          updateUrlOnClick
          disabled
        />
        {loadingCounterList && (
          <span>
            <Loader visible={loadingCounterList} />
          </span>
        )}
        {!loadingCounterList && counterListOptions && counterListOptions.length > 0 && (
          <Select
            unstyled
            isClearable
            backspaceRemovesValue
            placeholder="Search Counter"
            className="g-width-250 react-select-container"
            classNamePrefix="react-select"
            onChange={handleOnChangeSelectedCounter}
            options={counterListOptions}
          />
        )}
      </div>
      <Loader visible={loading} classes="g-height-800" />
      {data && data.success && data.buyback_stocks && data.buyback_stocks.length > 0 && (
        <>
          <h5>Summary Of Company Buyback In Past 2 Years</h5>
          <div className="text-muted g-mb-15">Updated on {data.updated_on}</div>
          <div className="table-responsive g-mb-50">
            <Table bordered hover className="text-center align-middle">
              <thead className="table-light align-middle">
                <tr>
                  <th rowSpan="2">No</th>
                  <th className="text-start" rowSpan="2">
                    Stock Name
                  </th>
                  <th colSpan={data.years_to_show.length + 1}>No.of Trades</th>
                  <th colSpan={data.years_to_show.length + 1}>Cumulative Volume ('000)</th>
                  <th colSpan={data.years_to_show.length + 1}>Cumulative Value ('000)</th>
                  <th rowSpan="2">Details</th>
                </tr>
                <tr>
                  {data.years_to_show.map((i) => {
                    return <th key={i}>{i}</th>;
                  })}
                  <th>Total</th>
                  {data.years_to_show.map((i) => {
                    return <th key={i}>{i}</th>;
                  })}
                  <th>Total</th>
                  {data.years_to_show.map((i) => {
                    return <th key={i}>{i}</th>;
                  })}
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {searchStock(data.buyback_stocks).map((stock, index) => {
                  return (
                    <tr id={stock.counter} key={stock.counter}>
                      <td>{index + 1}</td>
                      <td className="text-start">
                        <CompanyNameLink
                          counter={stock.counter}
                          name={stock.name}
                          showStockInfoPopUp
                        />
                      </td>
                      {Object.keys(stock.trades).map((yearKey, i, array) => {
                        return (
                          <td
                            key={`${stock.counter}_trades_${yearKey}`}
                            className={i === array.length - 1 ? 'bg-gray-200' : ''}>
                            {stock.trades[yearKey]}
                          </td>
                        );
                      })}
                      {Object.keys(stock.volume).map((yearKey, i, array) => {
                        return (
                          <td
                            key={`${stock.counter}_volume_${yearKey}`}
                            className={i === array.length - 1 ? 'bg-gray-200' : ''}>
                            {stock.volume[yearKey]}
                          </td>
                        );
                      })}
                      {Object.keys(stock.value).map((yearKey, i, array) => {
                        return (
                          <td
                            key={`${stock.counter}_value_${yearKey}`}
                            className={i === array.length - 1 ? 'bg-gray-200' : ''}>
                            {stock.value[yearKey]}
                          </td>
                        );
                      })}
                      <td>
                        <a href={`/quote/${stock.counter}/company_buyback`} className="text-dark" target="_blank">
                          <i className="fa-thin fa-xl fa-magnifying-glass-plus" />
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </>
      )}
      {(!data || !data.success) && <div className="text-center">No data found.</div>}
      <StockInfoPopup />
      <Notes />
    </>
  );
}

BuybackOverview.propTypes = {
  market: PropTypes.string,
  availableMarkets: PropTypes.oneOfType([PropTypes.array]),
};

export default BuybackOverview;
