/* eslint-disable react/prop-types */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CountersDropdownFilters from './CountersDropdownFilters';
import { NavComponent } from '../../components/PageLocalNav';
import APIErrorHandler from '../../components/APIErrorHandler';
import { createLoadableComponent } from '../../utils/Loadable';
import { fetchData, setFilters } from './stockPricesSlice';
import {
  buildUrlParamsFromBasePath,
  updateUrlBasedOnFilters,
  redirectUrlToSelectedMarket,
} from './url';
import { LayoutOptions } from '../../components/PriceTable';
import Loader from '../../components/Loader';
import ColumnSelection from '../../components/ColumnSelection';
import EditCustomLayout from '../../components/PriceTable/EditCustomLayout';

async function stopStreaming() {
  const { stopPricesStreaming } = await import('../../utils/Streaming/prices_table');
  stopPricesStreaming();
}

const PriceTable = createLoadableComponent(() => import('../../components/PriceTable'));
const { CancelToken } = axios;

export default function StockPriceTable({ props }) {
  if (!props) {
    return null;
  }

  const dispatch = useDispatch();
  const source = CancelToken.source();
  const data = useSelector((state) => state.stockPrices.data);
  const completed = useSelector((state) => state.stockPrices.completed);
  const loading = useSelector((state) => state.stockPrices.loading);
  const error = useSelector((state) => state.stockPrices.err);
  const initializedFilters = useSelector((state) => state.stockPrices.filtersInitialized);
  const currentFilters = useSelector((state) => state.stockPrices.filters);
  const selectedMarket = useSelector((state) => state.selectedMarket.selectedMarket);
  const isModeStreaming = useSelector((state) => state.pricesStreamingMode.isModeStreaming);
  const selectedStocks = useSelector((state) => state.priceTable.selectedstocks);
  const [isSSR, setIsSSR] = useState(true);
  const [searchFilter, setSearchFilter] = useState('');

  useEffect(() => {
    setIsSSR(typeof document === 'undefined');
  }, []);

  useEffect(() => {
    // ensure the price table is not streaming while retrieving data
    stopStreaming();
    const pricesContiner = document.getElementById('sic_priceContainer');
    if (pricesContiner) {
      pricesContiner.innerHTML = ''; // reset contents of sic_priceContainer
    }
    // make sure currentFilters had already been set before data retireval
    if (selectedMarket && currentFilters.market) {
      setSearchFilter('');
      if (selectedMarket === currentFilters.market) {
        // fetch data
        dispatch(fetchData({ filters: currentFilters, cancelToken: source.token }));
      } else {
        // reload the whole StockPrices page with new selectedMarket
        setTimeout(function () {
          redirectUrlToSelectedMarket(selectedMarket);
        }, 0);
      }
    }
  }, [currentFilters, selectedMarket]);

  if (!isSSR) {
    window.onpopstate = (event) => {
      if (event.state) {
        const filters = event.state.restoreFilters;
        if (filters && filters.market) {
          setTimeout(function () {
            dispatch(setFilters({ filters }));
          }, 0);
        }
      }
    };
  }

  if (!initializedFilters && !isSSR) {
    const filters = {
      tab: props.tab,
      filter: props.filter,
      type: props.type,
      layout: props.layout,
      page: props.page,
      market: props.market,
    };
    // save current window history state in browser
    window.history.replaceState({ restoreFilters: filters }, document.title, window.location.href);
    // trigger update on currentFilters
    setTimeout(function () {
      dispatch(setFilters({ filters }));
    }, 1000);
  }

  const handleChangeLayout = (newLayout) => {
    const filtersCopy = { ...currentFilters };
    filtersCopy.layout = newLayout;
    filtersCopy.page = '1';
    // update address bar in browser
    updateUrlBasedOnFilters(filtersCopy);
    // trigger update on currentFilters
    dispatch(setFilters({ filters: filtersCopy }));
  };

  const handleChangePagination = (newPage) => {
    if (!data || !data.pagination) {
      return;
    }
    const { pagination } = data;

    let pageToSet = newPage;
    if (newPage < 1) {
      pageToSet = 1;
    } else if (newPage > pagination.total_pages) {
      pageToSet = pagination.total_pages;
    }

    if (pageToSet != parseInt(currentFilters.page)) {
      const filtersCopy = { ...currentFilters }; // clone
      filtersCopy.page = String(pageToSet);
      // update address bar in browser
      updateUrlBasedOnFilters(filtersCopy);
      // trigger update on currentFilters
      dispatch(setFilters({ filters: filtersCopy }));
    }
  };

  const renderComponent = (tabKey) => {
    if (tabKey !== currentFilters.tab) {
      return null;
    }

    if (isSSR || loading) {
      return <Loader visible={isSSR || loading} classes="g-height-800" />;
    }

    if (!(completed && data && data.types && data.stock_info)) {
      return null;
    }

    return (
      <div id="sic_priceContainer">
        <div className="row justify-content-xxl-between g-mb-15">
          <div className="col-xl gap-2 g-mb-10 g-xl-mb-0 d-flex flex-column flex-xxl-row align-items-start">
            <ColumnSelection selectedStocks={selectedStocks} enableCompare enableAddWatchlist />
            <div id="sic_filterList" className={`sic_${currentFilters.tab} d-flex flex-wrap gap-2`}>
              <div className="vr d-none d-xxl-block" />
              <CountersDropdownFilters
                filtersTypes={props.filters_types}
                stockInfoTypes={data.types}
                bdContracts={props.bd_contracts}
                currentFilters={currentFilters}
              />
              {!currentFilters.layout.includes('trading_data') && (
                <input
                  className="form-control g-width-180"
                  placeholder="Filter"
                  onChange={(e) => setSearchFilter(e.target.value)}
                />
              )}
            </div>
          </div>
          <div
            id="sic_layoutOptions"
            className="col-lg-auto d-flex justify-content-xl-end align-self-lg-end gap-2">
            {data?.selected_layout === 'custom' && (
              <EditCustomLayout
                allFields={data.all_fields}
                currentCustomFields={data.custom_layout}
                market={currentFilters.market}
              />
            )}
            <LayoutOptions
              filter={currentFilters.filter}
              market={currentFilters.market}
              selectedLayout={currentFilters.layout}
              setSelectedLayout={handleChangeLayout}
              isLegalUser={data.is_legal_user}
              isEodUser={data.is_eod_user}
            />
          </div>
        </div>
        {data.selected_layout === 'custom' && !(data.custom_layout?.length > 0) && (
          <div className="text-center">
            <h4 className="g-mt-75">You have not yet setup your Custom layout for this page.</h4>
            <h6 className="g-mt-25">You can set it up using the Edit Custom Layout button.</h6>
          </div>
        )}
        {(data.selected_layout !== 'custom' || data.custom_layout?.length > 0) && (
          <PriceTable
            data={data.stock_info}
            filters={currentFilters}
            streamingChannel={
              data.types[currentFilters.filter]
                ? data.types[currentFilters.filter].streaming_channel
                : undefined
            }
            isDuringStreamingHours={data.is_during_streaming_hours}
            pagination={data.pagination}
            searchFilter={searchFilter}
            handleChangePagination={handleChangePagination}
            isModeStreaming={isModeStreaming}
            streamingType="Channel"
          />
        )}
        <br />
      </div>
    );
  };

  const onTabSelectCallback = (event, selectedTabKey) => {
    event.preventDefault();
    if (!(selectedTabKey && Object.keys(props.prices_tabs_map).includes(selectedTabKey))) {
      return;
    }
    const filtersCopy = { ...currentFilters }; // clone
    filtersCopy.tab = selectedTabKey;
    filtersCopy.filter = props.prices_tabs_map[selectedTabKey].filter;
    filtersCopy.type = props.prices_tabs_map[selectedTabKey].type;
    filtersCopy.page = '1';
    if (selectedTabKey === 'indices') {
      // reset layout for Indices tab to default (trading_data), if necessary
      if (!['trading_data', 'fundamental', 'tsr'].includes(filtersCopy.layout)) {
        filtersCopy.layout = 'trading_data';
      }
    }
    // update address bar in browser
    updateUrlBasedOnFilters(filtersCopy);
    // trigger update on currentFilters
    dispatch(setFilters({ filters: filtersCopy }));
  };

  const tabs = Object.entries(props.prices_tabs_map).map(([tabKey, tabInfo]) => {
    const filtersCopy = isSSR ? { ...props } : { ...currentFilters };
    filtersCopy.tab = tabKey;
    filtersCopy.filter = tabInfo.filter;
    filtersCopy.type = tabInfo.type;
    filtersCopy.page = '1';
    return {
      key: tabKey,
      path: buildUrlParamsFromBasePath(props.url, filtersCopy),
      label: tabInfo.name,
    };
  });

  const tabKey = currentFilters.tab || props.tab;
  return (
    <>
      {error && <APIErrorHandler error={error} classes="g-mt-20 g-mb-10" />}
      <NavComponent
        navLinks={tabs}
        activeNav={tabKey}
        content={renderComponent(tabKey)}
        handleNavClick={onTabSelectCallback}
      />
    </>
  );
}
