import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Row, Col, Button, Form, Spinner } from 'react-bootstrap';
import * as yup from 'yup';
import md5 from 'md5';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import styles from './membership.module.css';
import { Constants } from '../../../utils/constants';
import api from '../../../utils/api';
import { selectMembershipProfile } from './membershipSlice';

const validationSchema = yup.object().shape({
  currentPassword: yup
    .string()
    .trim()
    .min(8, 'Password must be at least 8 characters')
    .required('Password is required'),
  newPassword: yup
    .string()
    .trim()
    .min(8, 'Password must be at least 8 characters')
    .required('Password is required'),
  confirmPassword: yup
    .string()
    .trim()
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
    .required('Confirm Password is required'),
});

function ChangePasswordForm({ setIsPasswordModalOpen }) {
  const STAGES = Object.freeze({
    ENTER_PASSWORD: 0,
    CHANGE_COMPLETE: 1,
  });

  const RECAPTCHA_FAILED_MESSAGE =
    'Failed to verify that you are a human. Please refresh page and try again.';
  const SERVER_ERROR_FAILED_MESSAGE =
    'Failed to verify with server. Please contact us if you are unable to change password';
  const MEMBERSHIP_PROFILE_ROUTE = 'membership/profile.json';

  const { CancelToken } = axios;

  const membershipProfile = useSelector(selectMembershipProfile);
  const { performCheck, siteKey } = membershipProfile;

  const [stage, setStage] = useState(STAGES.ENTER_PASSWORD);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [status, setStatus] = useState(Constants.STATUS_IDLE);
  const reCaptchaRef = useRef(null);

  const getRecaptchaToken = async () => {
    try {
      return await reCaptchaRef.current.executeAsync();
    } catch (err) {
      setPasswordError(err);
      return '';
    }
  };

  const handleUpdatePassword = async () => {
    /* Get grecaptcha */
    let captchaToken = '';
    const source = CancelToken.source();

    if (performCheck) {
      captchaToken = await getRecaptchaToken();
      if (!captchaToken) {
        setPasswordError(RECAPTCHA_FAILED_MESSAGE);
        return;
      }
    }

    validationSchema
      .pick(['currentPassword', 'newPassword', 'confirmPassword'])
      .validate({ currentPassword, newPassword, confirmPassword }, { abortEarly: true })
      .then(() => {
        setStatus(Constants.STATUS_LOADING);
        setPasswordError('');
        api
          .patch(
            MEMBERSHIP_PROFILE_ROUTE,
            {
              user: {
                password: newPassword,
                verify_password: currentPassword.trim().toLowerCase(),
                verify_password_m: md5(currentPassword.trim().toLowerCase()).toLowerCase(),
                confirmPassword,
              },
              recaptcha_token: captchaToken,
            },
            { cancelToken: source.token, responseType: 'json' },
          )
          .then((response) => {
            if (response.data.success) {
              setStage(STAGES.CHANGE_COMPLETE);
            } else {
              setPasswordError(response.data.message);
            }
          })
          .catch((err) => {
            setPasswordError(`${SERVER_ERROR_FAILED_MESSAGE} ${err.message}`);
          })
          .then(() => {
            setStatus(Constants.STATUS_IDLE);
            reCaptchaRef.current?.reset();
          });
      })
      .catch((err) => {
        setPasswordError(err.message);
      });
  };

  return (
    <div>
      {stage === STAGES.ENTER_PASSWORD && (
        <Form>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="current-password">
                <Form.Label className={styles.formLabel}>Current password</Form.Label>
                <Form.Control
                  type="password"
                  className={styles.formInput}
                  placeholder="Current Password"
                  name="current_password"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  data-lpignore="true"
                  autoComplete="off"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="newPassword">
                <Form.Label className={styles.formLabel}>New password</Form.Label>
                <Form.Control
                  type="password"
                  className={styles.formInput}
                  placeholder="New Password"
                  name="new_password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  data-lpignore="true"
                  autoComplete="off"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="confirm-password">
                <Form.Label className={styles.formLabel}>Confirm password</Form.Label>
                <Form.Control
                  type="password"
                  className={styles.formInput}
                  placeholder="Confirm Password"
                  name="confirm_password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  data-lpignore="true"
                  autoComplete="off"
                  isInvalid={!!passwordError}
                />
                <Form.Control.Feedback type="invalid">{passwordError}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="d-flex justify-content-center">
                <Button
                  type="button"
                  variant="dark"
                  className={`${styles.greenButton} mb-2 mt-3`}
                  onClick={handleUpdatePassword}>
                  {status === Constants.STATUS_LOADING ? (
                    <Spinner animation="border" role="status" size="sm" variant="light">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : (
                    'Update'
                  )}
                </Button>
              </div>
            </Col>
            <div className="g-text-size-12 mt-2 text-center">
              <span className={`${styles.supportNote}`}>
                If you need assistance with changing your password, please{' '}
                <a href="/support" target="_blank">
                  contact support.
                </a>
              </span>
            </div>
          </Row>
        </Form>
      )}
      {stage === STAGES.CHANGE_COMPLETE && (
        <div className="text-center">
          <h5>Password changed</h5>
          <div className="mt-2 mb-2" style={{ fontSize: 14 }}>
            Your password has been successfully changed.
          </div>
          <Button
            type="button"
            variant="dark"
            className={`${styles.greenButton} mb-3 mt-4 d-inline-block`}
            onClick={() => setIsPasswordModalOpen(false)}>
            Okay
          </Button>
        </div>
      )}
      {performCheck && (
        <ReCAPTCHA
          ref={reCaptchaRef}
          className="d-inline-block"
          theme="dark"
          size="invisible"
          sitekey={siteKey}
        />
      )}
    </div>
  );
}

ChangePasswordForm.propTypes = {
  setIsPasswordModalOpen: PropTypes.func,
};

export default ChangePasswordForm;
