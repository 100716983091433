import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import { useSelector, useDispatch } from 'react-redux';
import BrokersModal from './BrokersModal';
import TableSearchBar from '../../../../components/TableSearchBar';

function Brokers({ label, loading }) {
  const [showModal, setShowModal] = useState({ show: false, type: '' });

  const handleOpenBrokerModal = () => {
    setShowModal({ show: true, type: 'brokers' });
  };

  return (
    <>
      <Button
        className="btn btn-lg"
        variant="light"
        disabled={loading}
        onClick={handleOpenBrokerModal}>
        <i className="fa-light fa-address-card" /> {label}
      </Button>
      <BrokersModal show={showModal} setShow={setShowModal} />
    </>
  );
}

Brokers.propTypes = {
  label: PropTypes.string,
  loading: PropTypes.bool,
};

export default Brokers;

export function calculateBrokerFeeCharges(price, quantity, brokerSetting, exchangeRate = 1) {
  if (!(price && quantity && brokerSetting && Object.keys(brokerSetting).length > 0)) {
    return 0;
  }

  const contractValue = parseFloat(price) * parseFloat(quantity) * exchangeRate;

  const minCommission = parseFloat(brokerSetting.MinCommission) || 0;
  const commissionRate = parseFloat(brokerSetting.CommissionRate) || 0;
  const commissionCharge = Math.max(minCommission, (contractValue * commissionRate) / 100);

  const maxClearingCharge = parseFloat(brokerSetting.MaxClearingCharge) || 0;
  const clearingRate = parseFloat(brokerSetting.ClearingRate) || 0;
  const clearingCharge = Math.min(maxClearingCharge, (contractValue * clearingRate) / 100);

  // SGX broker only
  const sgxAccessFeeRate = parseFloat(brokerSetting.SGXAccessFee) || 0;
  let sgxAccessFee = 0;
  if (sgxAccessFeeRate > 0) {
    sgxAccessFee = (contractValue * sgxAccessFeeRate) / 100;
  }

  const gstRate = parseFloat(brokerSetting.GST) || 0;
  const gstCharge = (clearingCharge + commissionCharge + sgxAccessFee) * (gstRate / 100);

  // MY broker only
  const stampDutyRate = parseFloat(brokerSetting.StampDuty) || 0;
  const maxStampDuty = parseFloat(brokerSetting.MaxStampDuty) || 0;
  let stampDuty = 0;
  if (stampDutyRate > 0) {
    stampDuty = Math.min(maxStampDuty, Math.ceil((contractValue * stampDutyRate) / 100));
  }

  const totalCharges = commissionCharge + clearingCharge + stampDuty + gstCharge + sgxAccessFee;
  return parseFloat(totalCharges.toFixed(3));
}
