import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Dropdown } from 'react-bootstrap';

import { SelectedFieldsDnD } from '.';
import classes from './editCustomLayoutDropdown.module.css';

export default function EditCustomLayoutDropdown({
  allFields,
  currentSelectedFields,
  fieldCategories,
  currentActiveCategory,
  cbEvenHandlers,
  fnGetFieldSelectionsList,
  isPortfolio,
}) {
  const [dropdownShown, setDropdownShown] = useState(false);
  const [selectedFields, setSelectedFields] = useState([]);
  const [activeCategory, setActiveCategory] = useState('Basic');

  useEffect(() => {
    if (currentSelectedFields != selectedFields) {
      setSelectedFields(currentSelectedFields ? [...currentSelectedFields] : []);
    }
  }, [currentSelectedFields]);

  useEffect(() => {
    if (currentActiveCategory != activeCategory) {
      setActiveCategory(currentActiveCategory);
    }
  }, [currentActiveCategory]);

  const onDropdownToggle = (nextShow, meta) => {
    setDropdownShown(nextShow);
  };

  const {
    onCategoryClick,
    onRemoveFromSelection,
    onDragEnd,
    onClearSelections,
    onCancelSelections,
    onSaveSelections,
  } = cbEvenHandlers;

  const ddMenuContainerWidth = () => {
    let containerWidth = window.innerWidth - 72;
    const parentContainer = document.getElementById('sic_priceContainer');
    if (parentContainer) {
      containerWidth = parentContainer.clientWidth - 40;
    }
    return containerWidth;
  };

  return (
    <Dropdown
      autoClose="outside"
      show={dropdownShown}
      onToggle={onDropdownToggle}
      className={`${!isPortfolio && 'g-mb-10'}`}>
      <Dropdown.Toggle variant="light">
        <span className="g-mr-10">Edit Custom Layout</span>
      </Dropdown.Toggle>
      <Dropdown.Menu
        align={{ xl: 'end' }}
        className={`shadow g-max-width-${ddMenuContainerWidth()} ${
          isPortfolio ? classes.ddMenuContainerForPortfolio : classes.dropdownMenuContainer
        }`}>
        <div className={`g-max-width-${ddMenuContainerWidth()} overflow-x-auto`}>
          <div className={`row ${classes.dropdownMenuArea}`}>
            {/* field categories */}
            <div className="col-auto g-min-width-200">
              <h6 className="g-mt-10 g-mb-15">Select Category</h6>
              <ul className="list-unstyled">
                {fieldCategories &&
                  fieldCategories.map((cat, index) => {
                    return (
                      <li
                        key={`edit_custom_layout_fldcat_${index}`}
                        className={
                          activeCategory === cat.name
                            ? classes.activeFieldCategoryCell
                            : classes.fieldCategoryCell
                        }
                        role="button"
                        onClick={() => {
                          onCategoryClick && onCategoryClick(cat.name);
                        }}>
                        {cat.name}
                      </li>
                    );
                  })}
              </ul>
            </div>
            {/* select fields */}
            <div className={`col-auto ${classes.selectableFieldsBorder}`}>
              <h6 className="g-mt-10 g-mb-15">Select Fields</h6>
              <ul
                id="edit_custom_layout_select_fields"
                className={`list-unstyled ${classes.ul3Columns}`}>
                {fnGetFieldSelectionsList &&
                  fnGetFieldSelectionsList(activeCategory, selectedFields)}
              </ul>
            </div>
            {/* selected fields sorting */}
            <div className="col-auto g-min-width-300">
              <h6 className="g-mt-10 g-mb-15">Selected Fields</h6>
              <SelectedFieldsDnD
                currentFieldsSelected={selectedFields}
                allFields={allFields}
                onDragEnd={onDragEnd}
                onItemRemove={onRemoveFromSelection}
              />
            </div>
          </div>
        </div>
        <Dropdown.Divider />
        <div className="row float-end g-mt-20 g-ml-10 g-mr-10 g-mb-10">
          <div className="col-auto">
            <Button
              className="btn btn-light btn text-dark g-min-width-110 g-mb-10"
              onClick={() => {
                onClearSelections && onClearSelections();
              }}>
              Clear
            </Button>
          </div>
          <div className="col-auto">
            <Button
              className="btn btn-light btn text-dark g-min-width-110 g-mb-10"
              onClick={() => {
                onCancelSelections && onCancelSelections();
                setDropdownShown(false);
              }}>
              Cancel
            </Button>
          </div>
          <div className="col-auto">
            <Button
              className="btn btn-success btn g-min-width-110 g-mb-10"
              onClick={() => {
                onSaveSelections && onSaveSelections();
                setDropdownShown(false);
              }}>
              Update
            </Button>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}

EditCustomLayoutDropdown.propTypes = {
  allFields: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  currentCustomFields: PropTypes.arrayOf(PropTypes.number),
  fieldCategories: PropTypes.arrayOf(PropTypes.string),
  currentActiveCategory: PropTypes.string,
  cbEvenHandlers: PropTypes.arrayOf(PropTypes.func),
  fnGetFieldSelectionsList: PropTypes.func,
  isPortfolio: PropTypes.bool,
};
