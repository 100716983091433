import React, { useState, useEffect } from 'react';
import axios from 'axios';

import Loader from '../../components/Loader';
import { NewsItem } from '../../components/News';
import api from '../../utils/api';
import { useDetectMobileScreen } from '../../utils/utils';

const { CancelToken } = axios;
const NEWS_SIZE = 12;
const ORIGIN = 'home';

function MarketNews({ market }) {
  const isMobile = useDetectMobileScreen();
  const source = CancelToken.source();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const showNews = isMobile && data ? data.slice(0, 5) : data;

  useEffect(() => {
    const cancelToken = source.token;

    api
      .get(
        `news/retrieve_news_list.json?origin=${ORIGIN}&news_per_page=${NEWS_SIZE}&market=${market}`,
        {
          cancelToken,
        },
      )
      .then(({ data }) => {
        setData(data.news);
        setLoading(false);
      })
      .catch((error) => {
        console.log('Fetch Home News List Error', error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <Loader visible={loading} classes="g-height-200" />;
  }

  return (
    <div id="newsLists" className="row gy-4 g-mb-15">
      {(!data || data.length === 0) && <p>No news found</p>}
      {data &&
        data.length > 0 &&
        showNews.map((news) => {
          return <NewsItem key={news.nid} news={news} basePath="/news" viewMode="grid_border" />;
        })}
    </div>
  );
}

export default MarketNews;
