import { createSlice } from '@reduxjs/toolkit';
import api from '../../utils/api';

const signUpSlice = createSlice({
  name: 'signUp',
  initialState: {
    data: [],
    err: null,
    loading: false,
    userid: null,
    email: null,
    mobile: null,
    otpSentCount: 0,
    otpRetrySeconds: 0,
    redirectTo: null,
  },
  reducers: {
    membershipActionStart(state, action) {
      state.err = null;
      state.loading = true;
      state.data = [];
    },
    membershipCreateSuccess(state, action) {
      state.data = action.payload.data;
      state.err = null;
      state.loading = false;
      if (action.payload.data.success) {
        state.userid = action.payload.data.userid;
        state.email = action.payload.data.email;
      }
    },
    membershipActionFail(state, action) {
      state.err = action.payload;
      state.loading = false;
    },
    membershipSendOTPStart(state, action) {
      state.err = null;
      state.loading = true;
    },
    membershipSendOTPSuccess(state, action) {
      state.loading = false;
      if (action.payload.data.success) {
        state.otpSentCount += 1;
        state.otpRetrySeconds = action.payload.data.data.retry_seconds;
      } else {
        state.err = action.payload.data.message;
      }
    },
    membershipVerificationSuccess(state, action) {
      state.loading = false;
      if (action.payload.data.success) {
        state.userid = action.payload.data.userid;
        state.email = action.payload.data.email;
        state.mobile = action.payload.data.mobile;
      } else {
        state.err = action.payload.data.message;
      }
    },
    membershipSendOTPFail(state, action) {
      state.err = action.payload;
      state.loading = false;
    },
    membershipResetData(state) {
      state.data = [];
      state.userid = '';
      state.email = '';
      state.mobile = '';
      state.err = '';
      state.redirectTo = '';
      state.otpSentCount = 0;
      state.otpRetrySeconds = 0;
    },
    membershipSetRedirectTo(state, action) {
      state.redirectTo = action.payload;
    },
    membershipTrialRegistrationSuccess(state, action) {
      state.data = action.payload.data;
      state.err = null;
      state.loading = false;
    },
    membershipResetOtpRetrySeconds(state) {
      state.otpRetrySeconds = 0;
    },
    membershipSetUserID(state, action) {
      state.userid = action.payload;
    },
    membershipSetMobile(state, action) {
      state.mobile = action.payload;
    },
  },
});

export default signUpSlice.reducer;
export const {
  membershipResetData,
  membershipSetRedirectTo,
  membershipResetOtpRetrySeconds,
  membershipSetUserID,
  membershipSetMobile,
} = signUpSlice.actions;

const {
  membershipActionStart,
  membershipCreateSuccess,
  membershipActionFail,
  membershipSendOTPStart,
  membershipSendOTPSuccess,
  membershipSendOTPFail,
  membershipTrialRegistrationSuccess,
  membershipVerificationSuccess,
} = signUpSlice.actions;

export const selectMobile = (state) => state.signUp.mobile;

export const createUser =
  ({ userid, email, password, country, promoCode, couponCode, captchaToken, cancelToken }) =>
  (dispatch) => {
    const url = `membership/registration.json`;

    dispatch(membershipActionStart());
    api
      .post(url, {
        userid,
        email,
        password,
        country,
        promo_code: promoCode,
        coupon_code: couponCode,
        recaptcha_token: captchaToken,
        cancelToken,
      })
      .then(({ data }) => {
        dispatch(membershipCreateSuccess({ data }));
      })
      .catch((error) => {
        dispatch(membershipActionFail(error));
      });
  };

export const sendOTP =
  ({ userid, mobile, dialCode, cancelToken }) =>
  (dispatch) => {
    const url = `membership/send_mobile_otp.json?`;

    dispatch(membershipSendOTPStart());
    api
      .post(url, {
        userid,
        mobile,
        dialCode,
        cancelToken,
      })
      .then(({ data }) => {
        dispatch(membershipSendOTPSuccess({ data }));
      })
      .catch((error) => {
        dispatch(membershipSendOTPFail(error));
      });
  };

export const verifyAccount =
  ({ userid, mobile, otp, dialCode, cancelToken }) =>
  (dispatch) => {
    const url = `membership/verify_account.json?`;

    dispatch(membershipActionStart());
    api
      .post(url, {
        userid,
        mobile,
        dialCode,
        otp,
        cancelToken,
      })
      .then(({ data }) => {
        dispatch(membershipVerificationSuccess({ data }));
      })
      .catch((error) => {
        dispatch(membershipActionFail(error));
      });
  };

export const registerForTrial =
  ({ country, promoCode, couponCode, cancelToken }) =>
  (dispatch) => {
    const url = `membership/trial_registration.json`;

    dispatch(membershipActionStart());
    api
      .post(url, {
        country,
        promo_code: promoCode,
        coupon_code: couponCode,
        cancelToken,
      })
      .then(({ data }) => {
        dispatch(membershipTrialRegistrationSuccess({ data }));
      })
      .catch((error) => {
        dispatch(membershipActionFail(error));
      });
  };
