import React, { useState, useEffect, useDeferredValue } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import Loader from '../../../components/Loader';
import APIErrorHandler from '../../../components/APIErrorHandler';
import { createLoadableComponent } from '../../../utils/Loadable';
import { fetchData } from './factsheetSlice';
import { fetchDefinition } from '../../../components/DefinitionPopup/definitionSlice';
import classes from './factsheet.module.css';

const PriceChart = createLoadableComponent(() => import('../../../components/PriceChart'));
const IndexComponent = createLoadableComponent(() => import('./IndexComponent'));
const FinancialAnalysis = createLoadableComponent(() => import('./FinancialAnalysis'));
const CompanyBackground = createLoadableComponent(() => import('./CompanyBackground'));
const KeyStatistics = createLoadableComponent(() => import('./KeyStatistics'));
const PastReturnAnalysis = createLoadableComponent(() => import('./PastReturnAnalysis'));
const PeerComparison = createLoadableComponent(() => import('./PeerComparison'));

const { CancelToken } = axios;

function Overview({ counter, hasIntradayData, isPayingUser, toggleFactsheetNav }) {
  const [isSSR, setIsSSR] = useState(true);
  const [financialAnalysisData, setFinancialAnalysisData] = useState(null);
  const deferredFinancialAnalysisData = useDeferredValue(financialAnalysisData);
  const [totalReturnsChartData, setTotalReturnsChartData] = useState(null);
  const deferredTotalReturnsChartData = useDeferredValue(totalReturnsChartData);
  const [peerComparisonData, setPeerComparisonData] = useState(null);
  const deferredPeerComparisonData = useDeferredValue(peerComparisonData);
  const data = useSelector((state) => state.factsheetOverview.data);
  const loading = useSelector((state) => state.factsheetOverview.loading);
  const error = useSelector((state) => state.factsheetOverview.err);
  const dispatch = useDispatch();
  const source = CancelToken.source();
  const chartDataURL = '/api/v1/charts/factsheet_overview_chart_data';
  const chartOptions = {
    showZoomOptions: true,
    showChartTypeOptions: true,
    hasIntradayData,
    showNewsMarkersOptions: true,
    showPeriodicityOptions: true,
    showTimeIntervalOptions: true,
    showRangeSlider: true,
    chartClasses: 'g-height-450 g-lg-height-500 g-mb-30 g-lg-mb-60',
  };

  useEffect(() => {
    setIsSSR(typeof document === 'undefined');
    dispatch(fetchData({ counter, cancelToken: source.token }));
    dispatch(fetchDefinition({ cancelToken: source.token, source: 'factsheet' }));
    return () => {};
  }, []);

  useEffect(() => {
    if (!data) {
      return;
    }
    // update financialAnalysisData if needed
    if (data.si_grades && data.si_grades !== financialAnalysisData) {
      setFinancialAnalysisData(data.si_grades);
    }
    // update totalReturnsChartData if needed
    if (data.movement && data.movement !== totalReturnsChartData) {
      setTotalReturnsChartData(data.movement);
    }
    // update peerComparisonData if needed
    if (
      data.peer_comparison &&
      !data.peer_comparison.message &&
      data.peer_comparison !== peerComparisonData
    ) {
      setPeerComparisonData(data.peer_comparison);
    }
  }, [data]);

  if (isSSR || loading) {
    return <Loader visible={isSSR || loading} classes="g-height-800" />;
  }

  if (error) {
    return <APIErrorHandler error={error} />;
  }

  return (
    <>
      {counter && (
        <PriceChart
          counter={counter}
          dataURL={chartDataURL}
          options={chartOptions}
          isPayingUser={isPayingUser}
        />
      )}
      {data && data.is_index && data.components ? (
        <IndexComponent
          components={data.components}
          exchangeNotation={data.exchange_notation}
          indexType={data.index_type}
        />
      ) : (
        <>
          {deferredFinancialAnalysisData &&
            deferredFinancialAnalysisData === financialAnalysisData && (
              <FinancialAnalysis grades={deferredFinancialAnalysisData} />
            )}
          {data && data.ir_links && <CompanyBackground irLinks={data.ir_links} />}
          {data && data.key_statistics && data.table_notes && (
            <KeyStatistics keyStatistics={data.key_statistics} notes={data.table_notes} />
          )}
          {deferredTotalReturnsChartData &&
            deferredTotalReturnsChartData === totalReturnsChartData && (
              <PastReturnAnalysis
                movement={deferredTotalReturnsChartData}
                isTrust={data.is_trust}
                showReturns={data.show_returns}
              />
            )}
          {deferredPeerComparisonData && deferredPeerComparisonData === peerComparisonData && (
            <PeerComparison
              tableData={deferredPeerComparisonData}
              toggleFactsheetNav={toggleFactsheetNav}
            />
          )}
        </>
      )}
    </>
  );
}

Overview.propTypes = {
  counter: PropTypes.string,
  hasIntradayData: PropTypes.bool,
  isPayingUser: PropTypes.bool,
  toggleFactsheetNav: PropTypes.func,
};

export default Overview;
