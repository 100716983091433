import React from 'react';

import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import SettingsInputField from './SettingsInputField';

const SHOW_CHART = {
  0: 'Hide',
  1: 'Show',
};

const FINANCIAL_DISPLAY_ORDER = {
  asc: 'Ascending',
  desc: 'Descending',
};

const FINANCIALS_FILTER = {
  fy: 'Full Year',
  quarter: 'Quarterly',
  t12m: 'Trailing 12 Months',
  '1q': '1st Quarter',
  '2q': '2nd Quarter',
  '3q': '3rd Quarter',
  '4q': '4th Quarter',
  half: 'Half Yearly',
  '1h': '1st Half',
  '2h': '2nd Half',
  all: 'All',
};

const FINANCIALS_PERIOD = {
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
  8: 8,
  9: 9,
  10: 10,
};

function SettingsFinancials({
  financialsShowChart,
  financialsDisplayOrder,
  financialsFilter,
  financialsPeriod,
}) {
  const financialsShowChartConfig = {
    key: 'financials_show_chart',
    name: 'Default Financial Show/Hide Charts',
    icon: 'fa-light fa-eye',
    inputType: 'radio',
    value: financialsShowChart,
    options: SHOW_CHART,
  };

  const financialsDiplayOrderConfig = {
    key: 'financials_display_order',
    name: 'Default Financial Display Order',
    icon: 'fa-light fa-arrow-down-short-wide',
    inputType: 'radio',
    value: financialsDisplayOrder,
    options: FINANCIAL_DISPLAY_ORDER,
  };

  const financialsFilterConfig = {
    key: 'financials_filter',
    name: 'Default Financial Filter',
    icon: 'fa-light fa-filter',
    inputType: 'dropdown',
    value: financialsFilter,
    options: FINANCIALS_FILTER,
  };

  const financialsPeriodConfig = {
    key: 'financials_period',
    name: 'Default Financial Periods to Show',
    icon: 'fa-light fa-chart-mixed',
    inputType: 'dropdown',
    value: financialsPeriod,
    options: FINANCIALS_PERIOD,
  };

  return (
    <Card className="g-mb-30">
      <Card.Body>
        <Card.Title className="h5">Financials Settings</Card.Title>
        <ul className="list-unstyled">
          <li className="g-mb-10">
            <SettingsInputField fieldConfig={financialsShowChartConfig} />
          </li>
          <li className="g-mb-10">
            <SettingsInputField fieldConfig={financialsDiplayOrderConfig} />
          </li>
          <li className="g-mb-10">
            <SettingsInputField fieldConfig={financialsFilterConfig} />
          </li>
          <li className="g-mb-10">
            <SettingsInputField fieldConfig={financialsPeriodConfig} />
          </li>
        </ul>
      </Card.Body>
    </Card>
  );
}

SettingsFinancials.propTypes = {
  financialsShowChart: PropTypes.string,
  financialsDisplayOrder: PropTypes.string,
  financialsFilter: PropTypes.string,
  financialsPeriod: PropTypes.string,
};
export default SettingsFinancials;
