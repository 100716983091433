import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

function FactsheetAttachmentRow({ rowData, showSparklines }) {
  return (
    <tr>
      <th aria-label="title" className="text-start align-middle">
        {rowData.title}
      </th>
      {showSparklines && <td aria-label="trend_placeholder" />}
      {Object.values(rowData.values).map((colData, index) => {
        const id = uuidv4();
        return (
          <td key={id}>
            {colData.map((link) => {
              return (
                <span className="d-inline-block me-1 mb-2" key={link}>
                  <a href={link} target="_blank" rel="noreferrer">
                    <i className="fa-thin fa-file-pdf fa-2xl text-dark" />
                  </a>
                </span>
              );
            })}
          </td>
        );
      })}
    </tr>
  );
}

FactsheetAttachmentRow.propTypes = {
  rowData: PropTypes.shape({
    title: PropTypes.string,
    title_indent_space: PropTypes.number,
    show_growth: PropTypes.bool,
    is_attachment: PropTypes.bool,
    values: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  }),
  showSparklines: PropTypes.bool,
};

export default FactsheetAttachmentRow;
