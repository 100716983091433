import React from 'react';
import PropTypes from 'prop-types';
import { Table, Button, Accordion } from 'react-bootstrap';

import { useSelector } from 'react-redux';
import Loader from '../../../components/Loader';

import styles from './predefinedTAScreens.module.css';
import CompanyNameLink from '../../../components/StockInfoPopup/CompanyNameLink';
import { useDetectMobileScreen } from '../../../utils/utils';

const getSignalColor = (signal) => {
  let color = 'text-gray-600';

  switch (signal.toString().toLowerCase()) {
    case 'long':
      color = 'text-primary';
      break;
    case 'short':
      color = 'text-danger';
      break;
    default:
      color = 'text-gray-600';
  }
  return color;
};

const getChgColor = (value) => {
  let color = 'text-gray-600';

  if (value === '' || value === '-') {
    color = 'text-gray-600';
  } else if (value > 0) {
    color = 'text-primary';
  } else if (value < 0) {
    color = 'text-danger';
  }

  return color;
};

function IndividualTACondition({
  selectedScanner,
  handleClickScannerResult,
  handleClickViewResult,
}) {
  const isMobile = useDetectMobileScreen();
  const data = useSelector((state) => state.predefinedTAScreens.data);
  const scannerResultLoading = useSelector(
    (state) => state.predefinedTAScreens.scannerResultLoading,
  );
  const screenScannerResult = useSelector((state) => state.predefinedTAScreens.screenScannerResult);

  if (!data || Object.keys(data).length === 0 || data?.scanner?.data?.length === 0) {
    return null;
  }

  return isMobile ? (
    <div className="g-max-height-500 g-md-max-height-600  overflow-x-hidden overflow-y-scroll styled-scrollbar">
      <Accordion activeKey={selectedScanner} id="custom-accordion">
        {data.scanner.data.map((result) => {
          const signalColor = getSignalColor(result.signal);
          return (
            <Accordion.Item eventKey={result.id} key={result.id}>
              <Accordion.Header onClick={() => handleClickScannerResult(result.id)}>
                <div className="row w-100 justify-content-between align-items-center">
                  <div className="col-7 g-fw-500">{result.title}</div>
                  <div className="col-3 ">
                    <div>
                      <span className="d-inline-block g-width-80">Results:</span>{' '}
                      <strong>{result.count}</strong>
                    </div>
                    <div>
                      <span className="d-inline-block g-width-80">Signal:</span>{' '}
                      <strong className={signalColor}>{result.signal}</strong>
                    </div>
                  </div>
                </div>
              </Accordion.Header>
              <Accordion.Body className="bg-gray-200">
                {scannerResultLoading && <Loader visible={scannerResultLoading} />}
                {screenScannerResult && Object.keys(screenScannerResult).length > 0 && (
                  <>
                    <h5>{screenScannerResult.title}</h5>
                    <div className="g-mb-30">
                      <i className="fa-sharp fa-solid fa-circle-exclamation g-mr-5" />
                      <strong>Explanation:</strong> {screenScannerResult.explanation}
                    </div>
                    <div className="table-responsive">
                      <Table>
                        <thead className="table-light">
                          <tr>
                            <th>Name</th>
                            <th className="text-center">Rem</th>
                            <th className="text-end">Last</th>
                            <th className="text-end">Chg</th>
                            <th className="text-end">% Chg</th>
                            <th className="text-end">Vol</th>
                            <th className="text-center">Scan Results</th>
                          </tr>
                        </thead>
                        <tbody>
                          {screenScannerResult.stocks?.length > 0 ? (
                            screenScannerResult.stocks.map((stock) => {
                              const color = getChgColor(stock.per_change);
                              return (
                                <tr key={stock.counter}>
                                  <td>
                                    <CompanyNameLink
                                      counter={stock.market_and_code}
                                      name={stock.name}
                                      showStockInfoPopUp
                                      showCounter
                                    />
                                  </td>
                                  <td className="text-center">{stock.rem}</td>
                                  <td className="text-end">{stock.last_done}</td>
                                  <td className={`${color} text-end`}>{stock.change}</td>
                                  <td className={`${color} text-end`}>{stock.per_change}</td>
                                  <td className="text-end">{stock.vol}</td>
                                  <td className="text-center">
                                    {stock.count}
                                    {stock.is_not_index && (
                                      <Button
                                        variant="light"
                                        aria-label="View result"
                                        onClick={() => handleClickViewResult(stock.counter)}
                                        className="btn-rounded g-ml-15 p-0 d-inline-block g-min-width-auto bg-transparent">
                                        <i className="fa-light fa-xl fa-magnifying-glass-chart" />
                                      </Button>
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td className="text-center" colSpan={7}>
                                No Data
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </>
                )}
              </Accordion.Body>
            </Accordion.Item>
          );
        })}
      </Accordion>
    </div>
  ) : (
    <div className="row g-0 g-mb-25">
      <div className="col-5">
        <div className="g-max-height-400 g-md-max-height-500 g-lg-max-height-600 g-xl-max-height-700 overflow-x-hidden overflow-y-scroll styled-scrollbar">
          <ul className="list-unstyled">
            {data.scanner.data.map((result) => {
              const signalColor = getSignalColor(result.signal);
              return (
                <li
                  key={result.id}
                  onClick={() => handleClickScannerResult(result.id)}
                  className={`${
                    selectedScanner === result.id ? styles.resultListItemPointerActive : ''
                  } ${styles.resultListItemPointer} p-2`}>
                  <div className="row justify-content-between align-items-center">
                    <div className="col-8 g-fw-500">{result.title}</div>
                    <div className="col-4 ">
                      <div>
                        <span className="d-inline-block g-width-80">Results:</span>{' '}
                        <strong>{result.count}</strong>
                      </div>
                      <div>
                        <span className="d-inline-block g-width-80">Signal:</span>{' '}
                        <strong className={signalColor}>{result.signal}</strong>
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="col-7 bg-gray-200">
        <div className="g-max-height-400 g-md-max-height-500 g-lg-max-height-600 g-xl-max-height-700 overflow-x-hidden overflow-y-scroll styled-scrollbar p-3">
          {scannerResultLoading && <Loader visible={scannerResultLoading} />}
          {screenScannerResult && Object.keys(screenScannerResult).length > 0 && (
            <>
              <h5>{screenScannerResult.title}</h5>
              <div className="g-mb-30">
                <i className="fa-sharp fa-solid fa-circle-exclamation g-mr-5" />
                <strong>Explanation:</strong> {screenScannerResult.explanation}
              </div>
              <Table>
                <thead className="table-light">
                  <tr>
                    <th>Name</th>
                    <th className="text-center">Rem</th>
                    <th className="text-end">Last</th>
                    <th className="text-end">Chg</th>
                    <th className="text-end">% Chg</th>
                    <th className="text-end">Vol</th>
                    <th className="text-center">Scan Results</th>
                  </tr>
                </thead>
                <tbody>
                  {screenScannerResult.stocks?.length > 0 ? (
                    screenScannerResult.stocks.map((stock) => {
                      const color = getChgColor(stock.per_change);
                      return (
                        <tr key={stock.counter}>
                          <td>
                            <CompanyNameLink
                              counter={stock.market_and_code}
                              name={stock.name}
                              showStockInfoPopUp
                              showCounter
                            />
                          </td>
                          <td className="text-center">{stock.rem}</td>
                          <td className="text-end">{stock.last_done}</td>
                          <td className={`${color} text-end`}>{stock.change}</td>
                          <td className={`${color} text-end`}>{stock.per_change}</td>
                          <td className="text-end">{stock.vol}</td>
                          <td className="text-center">
                            {stock.count}
                            {stock.is_not_index && (
                              <Button
                                variant="light"
                                aria-label="View result"
                                onClick={() => handleClickViewResult(stock.counter)}
                                className="btn-rounded g-ml-15 p-0 d-inline-block g-min-width-auto bg-transparent">
                                <i className="fa-light fa-xl fa-magnifying-glass-chart" />
                              </Button>
                            )}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td className="text-center" colSpan={7}>
                        No Data
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

IndividualTACondition.propTypes = {
  selectedScanner: PropTypes.string,
  handleClickScannerResult: PropTypes.func,
  handleClickViewResult: PropTypes.func,
};

export default IndividualTACondition;
