import { createSlice } from '@reduxjs/toolkit';

import api from '../../utils/api';

const AnalysisToolsSlice = createSlice({
  name: 'analysisTools',
  initialState: {
    loading: true,
    completed: false,
    data: {},
  },
  reducers: {
    analysisChartDataFetchStart(state, action) {
      state.loading = true;
      state.completed = false;
    },
    analysisChartDataFetchSuccess(state, action) {
      state.data = action.payload.data;
      state.completed = true;
      state.loading = false;
    },
    analysisChartDataFetchFail(state, action) {
      state.err = action.payload.message;
      state.completed = true;
      state.loading = false;
    },
  },
});

export default AnalysisToolsSlice.reducer;

const { analysisChartDataFetchStart, analysisChartDataFetchSuccess, analysisChartDataFetchFail } =
  AnalysisToolsSlice.actions;

export const fetchData =
  ({ filter, folder, market, counters, alphabet, page, analysisToolsType, cancelToken }) =>
  (dispatch) => {
    let url = `charts/analysis_charts.json?type=${analysisToolsType}`;
    if (filter) {
      url += `&filter=${filter}`;
    }
    if (market) {
      url += `&market=${market}`;
    }
    if (folder) {
      url += `&folder=${folder}`;
    }
    if (counters) {
      url += `&counters=${counters}`;
    }
    if (alphabet) {
      url += `&alphabet=${alphabet}`;
    }
    if (page) {
      url += `&page=${page}`;
    }
    dispatch(analysisChartDataFetchStart());
    api
      .get(url, { cancelToken })
      .then(({ data }) => {
        dispatch(analysisChartDataFetchSuccess({ data }));
      })
      .catch((error) => {
        dispatch(analysisChartDataFetchFail(error));
      });
  };
