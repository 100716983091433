import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import Card from 'react-bootstrap/Card';

import api from '../../../utils/api';
import { compareObjectsByNumberAttr } from '../../../utils/utils';
import { NavComponent } from '../../../components/PageLocalNav';
import classes from './insights.module.css';

const { CancelToken } = axios;

const tab1d = '1d';
const TABS = [
  { key: tab1d, label: '1 Day' },
  { key: '5d', label: '5 Day' },
  { key: '10d', label: '10 Day' },
];

export default function VolumeDistribution() {
  const source = CancelToken.source();
  const selectedPortfolio = useSelector((state) => state.portfolio.selected);
  const [currentTabKey, setCurrentTabKey] = useState(tab1d);
  const [currentCounters, setCurrentCounters] = useState([]);
  const [selectOptions, setSelectOptions] = useState([]);
  const [activeCounter, setActiveCounter] = useState(null);
  const [allVoldistData, setAllVoldistData] = useState([]);
  const [counterVoldistData, setCounterVoldistData] = useState(null);

  useEffect(() => {
    if (selectedPortfolio?.counters?.length > 0) {
      const counters = selectedPortfolio.counters
        .map((c) => {
          return { counter: c.counter, stockName: c.Name, position: c.No };
        })
        .sort((a, b) => compareObjectsByNumberAttr(a, b, 'position'));
      if (JSON.stringify(counters) !== JSON.stringify(currentCounters)) {
        setCurrentCounters(counters);
        setActiveCounter(counters[0].counter);
      }
    } else {
      setCurrentCounters([]);
      setActiveCounter(null);
    }
  }, [selectedPortfolio]);

  useEffect(() => {
    if (currentCounters.length > 0) {
      setSelectOptions(
        currentCounters.map((c) => {
          return { value: c.counter, label: `${c.stockName} (${c.counter})` };
        }),
      );

      const cancelToken = source.token;
      const url = `charts/analysis_charts.json?type=volume_distribution&folder=${selectedPortfolio.id}`;
      api
        .get(url, { cancelToken })
        .then(({ data }) => {
          setAllVoldistData(data);
        })
        .catch((err) => {
          // ignore error at this point
        });
    } else {
      setSelectOptions([]);
    }
  }, [currentCounters]);

  useEffect(() => {
    if (activeCounter) {
      const data = allVoldistData?.symbols?.filter(
        (s) => activeCounter === `${s.symbol}.${s.market}`,
      )[0];
      setCounterVoldistData(data);
    }
  }, [activeCounter, allVoldistData]);

  const handleActiveCounterChange = (newCounter) => {
    if (newCounter && newCounter.value !== activeCounter) {
      setActiveCounter(newCounter.value);
    }
  };

  const onTabSelectCallback = (event, selectedTabKey) => {
    event.preventDefault();
    if (
      !(
        selectedTabKey &&
        TABS.map((t) => t.key).includes(selectedTabKey) &&
        selectedTabKey !== currentTabKey
      )
    ) {
      return;
    }

    setCurrentTabKey(selectedTabKey);
  };

  const renderComponent = (tabKey, symbol, voldistData) => {
    if (!symbol) {
      return null;
    }

    let changeClass = '';
    if (voldistData) {
      if (voldistData.price_change > 0) {
        changeClass = 'text-success';
      } else if (voldistData.price_change < 0) {
        changeClass = 'text-danger';
      }
    }

    let imgUrl = `//chart.shareinvestor.com/charts_cached/charts.pl?id=${symbol}&type=si_v2_vol_dist_${tabKey}`;
    if (allVoldistData?.delay) {
      imgUrl += `&delay=${allVoldistData.delay}`;
    }
    if (allVoldistData?.no_cache) {
      imgUrl += `&nocache=${allVoldistData.no_cache}`;
    }

    return (
      <div
        id="insights_voldist_container"
        className="g-mt-20 g-ml-15 g-mr-15 g-mb-20 g-per-width-95">
        {voldistData && <h6 className="fw-500">{voldistData.stock_name}</h6>}
        {voldistData && (
          <div className={`g-text-size-14 ${classes.dateText}`}>
            {voldistData.delay > 0 && <span>Quotes {voldistData.delay} Minutes Delayed.</span>}
            Price updated at {voldistData.date_time}
          </div>
        )}
        <div className={`${classes.alignMiddle} ${classes.alignCenter}`}>
          <img
            className={`${classes.volumeDistributionChartImg} img-fluid g-mt-15 g-mb-15`}
            src={imgUrl}
            alt={`${symbol} volume distribution chart`}
          />
        </div>
        {voldistData && (
          <div className="row">
            <div className="col-lg-6 d-flex justify-content-between">
              <span className="g-text-size-14">Last Price</span>
              <span className="fw-500 g-text-size-14">{voldistData.last_done}</span>
            </div>
            <div className="col-lg-6 d-flex justify-content-between">
              <span className="g-text-size-14">Volume</span>
              <span className="fw-500 g-text-size-14">{voldistData.volume}</span>
            </div>
            <div className="col-lg-6 d-flex justify-content-between">
              <span className="g-text-size-14">Change (%)</span>
              <span className={`fw-500 g-text-size-14 ${changeClass}`}>
                {voldistData.price_change_f} ({voldistData.percent_change})
              </span>
            </div>
            <div className="col-lg-6 d-flex justify-content-between">
              <span className="g-text-size-14">Range</span>
              <span className="fw-500 g-text-size-14">
                {voldistData.low &&
                voldistData.low !== '-' &&
                voldistData.high &&
                voldistData.high !== '-'
                  ? `${voldistData.low} - ${voldistData.high}`
                  : '-'}
              </span>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <Card className="position-static  h-100">
      <Card.Body className="g-min-height-555">
        <div className="g-mb-20 d-flex flex-column flex-xl-row justify-content-between">
          <Card.Title>Volume Distribution</Card.Title>
          {selectOptions.length > 0 && (
            <Select
              unstyled
              isClearable
              backspaceRemovesValue
              className="react-select-container d-inline-block g-width-275 g-max-per-width-100"
              classNamePrefix="react-select"
              defaultValue={{
                value: activeCounter || selectOptions[0].value,
                label:
                  currentCounters.filter((c) => c.value === activeCounter)[0]?.stockName ||
                  selectOptions[0].label,
              }}
              onChange={handleActiveCounterChange}
              options={selectOptions}
            />
          )}
        </div>

        <NavComponent
          navLinks={TABS}
          tabClasses="g-mb-15"
          activeNav={currentTabKey}
          content={renderComponent(currentTabKey, activeCounter, counterVoldistData)}
          handleNavClick={onTabSelectCallback}
        />
      </Card.Body>
    </Card>
  );
}
