export const buildUrlParamsFromBasePath = function (basePath, filters) {
  const market = filters.market ? `?market=${filters.market}` : '';
  const tab = filters.tab ? `&tab=${filters.tab}` : '';
  const page = filters.page ? `&page=${filters.page}` : '';
  const filter = filters.filter ? `&filter=${filters.filter}` : '';
  const type = filters.type ? `&type=${filters.type}` : '';
  const layout = filters.layout ? `&layout=${filters.layout}` : '';
  return basePath.concat(market, tab, page, filter, type, layout);
};

export const updateUrlBasedOnFilters = function (filters) {
  let url = window.location.origin + window.location.pathname;
  const params = [];

  if (filters.market) params.push(`market=${filters.market}`);
  if (filters.tab) params.push(`tab=${filters.tab}`);
  if (filters.page) params.push(`page=${filters.page}`);
  if (filters.filter) params.push(`filter=${filters.filter}`);
  if (filters.type) params.push(`type=${filters.type}`);
  if (filters.layout) params.push(`layout=${filters.layout}`);

  if (params && params.length > 0) {
    url = `${url}?${params.join('&')}`;
  }

  window.history.pushState({ restoreFilters: filters }, document.title, url);
};

export const redirectUrlToSelectedMarket = function (selectedMarket) {
  const url = window.location.origin + window.location.pathname;
  const market = `?market=${selectedMarket}`;
  window.location.assign(url.concat(market));
};
