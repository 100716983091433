import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown';
import { ToastContainer, Slide, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

import { fetchAlerts, showNewAlertSetting, fetchAlertsHistory } from './stockNewsAlertsSlice';
import StockNewsAlertsDropdownMenu from './StockNewsAlertsDropdownMenu';
import AlertFormModal from './AlertFormModal';
import AlertNotification from './AlertNotification';
import { isDefined } from '../../utils/utils';

const { CancelToken } = axios;

async function stopStreaming() {
  const { stopAlertsStreaming } = await import('../../utils/Streaming/alerts');
  stopAlertsStreaming();
}

async function startStreaming(alertSettingTableUpdate, alertHistoryTableUpdate) {
  const { startAlertsStreaming } = await import('../../utils/Streaming/alerts');
  startAlertsStreaming(alertSettingTableUpdate, alertHistoryTableUpdate);
}

function StockNewsAlertsMain() {
  const dispatch = useDispatch();
  const source = CancelToken.source();
  const [showDropdown, setShowDropdown] = useState(false);
  const [showAlertFormModal, setShowAlertFormModal] = useState({ show: false });
  const alertSettings = useSelector((state) => state.stockNewsAlerts.alertSettings);
  const alertHistories = useSelector((state) => state.stockNewsAlerts.alertHistories);
  const newHistoriesCount = useSelector((state) => state.stockNewsAlerts.newHistoriesCount);
  const err = useSelector((state) => state.stockNewsAlerts.err);
  const alertSettingsRef = useRef(alertSettings);
  const alertSoundRef = useRef(null);
  const [newAlerts, setNewAlerts] = useState([]);
  const [canPlaySound, setCanPlaySound] = useState(false);
  const [tab, setTab] = useState('setting');

  const playSound = () => {
    const alertSound = alertSoundRef.current;
    if (alertSound && canPlaySound) {
      alertSound.play();
    }
  };

  const handleShowDropdown = () => {
    setShowDropdown(!showDropdown);
    // close modal when closing dropdown
    if (showDropdown) {
      setShowAlertFormModal({ show: false });
    }
  };

  const handleAlertViewDetails = (e) => {
    e.stopPropagation();
    setShowDropdown(true);
    if (tab !== 'history') {
      setTab('history');
    }
  };

  const processAlertHistories = (histories) => {
    const newSettings = {};

    histories.forEach((params) => {
      const key = `${params.obj_id}`;
      const alertSetting = alertSettingsRef.current.filter(
        (alert) => alert.id.toString() === key,
      )[0];

      if (isDefined(typeof alertSetting) && isDefined(params.status) && isDefined(params.itemPos)) {
        const { alertMethod } = alertSetting;
        const alertByApplication = alertMethod === 1;

        if (params.status === 1 && alertByApplication) {
          if (!newSettings[key]) {
            newSettings[key] = {
              historyUpdateTime: [],
              alertSetting,
            };
          }

          // push the new alert history id into the array
          newSettings[key].historyUpdateTime.push(params.time);
          // update last alert insertion time; used for sorting newAlerts
          newSettings[key].last_alert_time = new Date();
        }
      }
    });

    setNewAlerts((prevAlert) => [...prevAlert, ...Object.values(newSettings)]);
  };

  const alertSettingsUpdate = (params, pendingHistories) => {
    const alertSettingsIDs = alertSettingsRef.current.map((alert) => alert.id.toString());

    if (
      isDefined(params.status) &&
      isDefined(params.itemPos) &&
      !alertSettingsIDs.includes(params.itemPos.toString())
    ) {
      dispatch(showNewAlertSetting({ id: params.itemPos, newAlert: true }));
    }

    if (pendingHistories.length > 0) {
      processAlertHistories(pendingHistories);
    }
  };

  const alertHistoryUpdate = (params) => {
    const alertSettingsIDs = alertSettingsRef.current.map((alert) => alert.id.toString());
    const key = `${params.obj_id}`;

    if (!alertSettingsIDs.includes(key)) {
      return false;
    }

    processAlertHistories([params]);
    return true;
  };

  useEffect(() => {
    dispatch(fetchAlerts({ cancelToken: source.token }));
    startStreaming(alertSettingsUpdate, alertHistoryUpdate);

    const onUserInteraction = () => {
      setCanPlaySound(true);
    };

    // Add event listeners for user interactions
    window.addEventListener('click', onUserInteraction);
    window.addEventListener('keydown', onUserInteraction);
    window.addEventListener('mousemove', onUserInteraction);
    window.addEventListener('touchstart', onUserInteraction);

    return () => {
      if (alertSoundRef.current) {
        alertSoundRef.current.innerHTML = '';
      }
      stopStreaming();
      window.removeEventListener('click', onUserInteraction);
      window.removeEventListener('keydown', onUserInteraction);
      window.removeEventListener('mousemove', onUserInteraction);
      window.removeEventListener('touchstart', onUserInteraction);
    };
  }, []);

  useEffect(() => {
    alertSettingsRef.current = alertSettings;
  }, [alertSettings]);

  useEffect(() => {
    if (newAlerts && newAlerts.length > 0) {
      let historyCheck = [];
      const currentHistoryIDs = alertHistories.map((h) => `${h.objectID}-${h.dateTimeBasic}`);

      newAlerts.forEach((alert) => {
        const currentHistoryCheck = alert.historyUpdateTime.map(
          (time) => `${alert.alertSetting?.id}-${time}`,
        );
        historyCheck = [...historyCheck, ...currentHistoryCheck];

        if (currentHistoryCheck.filter((pair) => !currentHistoryIDs.includes(pair)).length > 0) {
          dispatch(showNewAlertSetting({ id: alert.alertSetting.id }));
          toast(
            <AlertNotification
              alertSetting={alert.alertSetting}
              time={alert.last_alert_time}
              handleAlertViewDetails={handleAlertViewDetails}
            />,
            {
              autoClose: 10000,
              className: 'alertToastNotification',
              type: 'success',
              icon: false,
            },
          );
        }
      });

      if (historyCheck.filter((pair) => !currentHistoryIDs.includes(pair)).length > 0) {
        playSound();
      }

      dispatch(
        fetchAlertsHistory({
          counters: ['ALL'],
          cancelToken: source.token,
        }),
      );
      setNewAlerts([]);
    }
  }, [newAlerts]);

  useEffect(() => {
    if (err) {
      setShowAlertFormModal({ show: false });
    }
  }, [err]);

  return (
    <>
      <audio ref={alertSoundRef} src="/images/alert_popup_warning_2.mp3" preload="auto" hidden />
      <Dropdown
        className="position-relative d-inline-block"
        show={showDropdown}
        onToggle={handleShowDropdown}
        drop="down"
        autoClose="outside">
        <Dropdown.Toggle
          variant="light"
          className="btn-rounded g-mr-15 p-0 d-inline-block g-min-width-auto no-default-caret bg-transparent"
          aria-label="Alert">
          <i className="fa-sharp fa-solid fa-bell icon-sm text-white" />
          {parseInt(newHistoriesCount) > 0 && (
            <span className="position-absolute top-30 start-60 translate-middle g-p-3 bg-danger rounded-circle" />
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu className="p-0">
          <StockNewsAlertsDropdownMenu
            showAlertFormModal={showAlertFormModal}
            setShowAlertFormModal={setShowAlertFormModal}
            activeTab={tab}
            setActiveTab={setTab}
          />
          <AlertFormModal
            showAlertFormModal={showAlertFormModal}
            setShowAlertFormModal={setShowAlertFormModal}
          />
        </Dropdown.Menu>
      </Dropdown>
      <ToastContainer transition={Slide} limit={6} containerId="stockNewsAlertsSlice" />
    </>
  );
}

export default StockNewsAlertsMain;
