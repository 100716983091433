import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import store from '../../utils/store';
import { Pagination } from '../../pages/Markets/BrokersCall/brokersCallInterfaces';

export default function Paging({ selectPagination, changePageAction }) {
  type AppDispatch = typeof store.dispatch;
  const useTypedDispatch = () => useDispatch<AppDispatch>();
  const dispatch = useTypedDispatch();
  const pagination: Pagination = useSelector(selectPagination);
  const [, setSearchParams] = useSearchParams();

  const onPageChange = (e) => {
    let page = e.target.value ? Number(e.target.value) : 1;

    if (page < 1) {
      page = 1;
    } else if (page > pagination.totalPages) {
      page = pagination.totalPages;
    }

    setSearchParams((searchParams) => {
      searchParams.set('currentPage', String(page));
      return searchParams;
    });
    dispatch(changePageAction(page));
  };

  return (
    <div className={`pagination d-flex bg-gray-100 g-pl-20 g-pr-20 justify-content-between`}>
      <span className="py-3">
        {pagination.totalRows > 0 &&
          `${pagination.rowOffset + 1}-${pagination.rowOffset + pagination.currentPageRows} of ${
            pagination.totalRows
          } results`}
        {pagination.totalRows === 0 && `${pagination.totalRows} results`}
      </span>

      {(pagination.currentPageRows !== pagination.totalRows || pagination.currentPage !== 1) && (
        <span>
          <span>
            <input
              className="h-100 text-center"
              type="number"
              min={1}
              max={pagination.totalPages}
              value={pagination.currentPage}
              onChange={(event) => {
                onPageChange(event);
              }}
              onBlur={(event) => {
                onPageChange(event);
              }}
              onKeyUp={(event) => {
                if (event.key === 'Enter') {
                  onPageChange(event);
                }
              }}
            />
            {` of ${pagination.totalPages}`}
          </span>

          <button
            type="button"
            disabled={pagination.currentPage === 1}
            className="g-ml-15 border-0"
            onClick={() => {
              setSearchParams((searchParams) => {
                searchParams.set('currentPage', String(pagination.currentPage - 1));
                return searchParams;
              });
              dispatch(changePageAction(pagination.currentPage - 1));
            }}>
            <i className="fa-thin fa-angle-left fa-lg" />
          </button>

          <button
            type="button"
            disabled={pagination.currentPage === pagination.totalPages}
            className="g-ml-15 border-0"
            onClick={() => {
              setSearchParams((searchParams) => {
                searchParams.set('currentPage', String(pagination.currentPage + 1));
                return searchParams;
              });
              dispatch(changePageAction(pagination.currentPage + 1));
            }}>
            <i className="fa-thin fa-angle-right fa-lg" />
          </button>
        </span>
      )}
    </div>
  );
}

Paging.propTypes = {
  rowOffset: PropTypes.number,
  currentPageRows: PropTypes.number,
  totalRows: PropTypes.number,
  pageNo: PropTypes.number,
  totalPages: PropTypes.number,
  setPageNo: PropTypes.func,
  classes: PropTypes.string,
};
