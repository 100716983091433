import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import api from '../../utils/api';

const stockNewsAlertsSlice = createSlice({
  name: 'stockNewsAlerts',
  initialState: {
    err: null,
    loading: false,
    completed: false,
    alertSettings: [],
    alertHistories: [],
    selectedAlertSettings: [],
    alertSettingsFilter: {
      active: 1,
      inactive: 0,
      counters: ['ALL'],
    },
    userSetHistoryFilter: null,
    alertSettingsSort: {},
    newHistoriesCount: 0,
    refreshHistory: false,
    market: 'sgx',
    formData: {},
    editAlertData: {},
    editLoading: false,
    response: null,
  },
  reducers: {
    fetchAlertsStart(state, action) {
      state.err = null;
      state.loading = true;
      state.data = [];
    },
    fetchAlertsSettingSuccess(state, action) {
      state.data = action.payload.data;
      state.err = null;
      state.loading = false;
      if (action.payload.data.success) {
        state.alertSettings = action.payload.data.alert_settings;
      } else {
        state.err = action.payload.data.message;
      }
    },
    fetchAlertsFail(state, action) {
      state.err = action.payload;
      state.loading = false;
    },
    fetchAlertsHistoryStart(state, action) {
      state.err = null;
      state.loading = true;
    },
    fetchAlertsHistorySuccess(state, action) {
      state.loading = false;
      if (action.payload.data.success) {
        state.alertHistories = action.payload.data.alert_histories;
        state.newHistoriesCount = action.payload.data.new_histories_count;
      } else {
        state.err = action.payload.data.message;
      }
    },
    fetchAlertsHistoryFail(state, action) {
      state.err = action.payload;
      state.loading = false;
    },
    createAlertStart(state, action) {
      state.err = null;
      state.loading = true;
      state.completed = false;
      state.response = null;
    },
    createAlertSuccess(state, action) {
      state.loading = false;
      state.response = action.payload.data.message;
      state.completed = !!action.payload.data.success;

      if (action.payload.data.success) {
        // add in UI since there is a delay in alerts app backend
        const data = { ...action.payload.data.alert_setting, new: true };
        state.alertSettings = [
          ...state.alertSettings.filter((alert) => alert.id !== data.id),
          data,
        ];
      }

      toast(action.payload.data.message, {
        type: action.payload.data.success ? 'success' : 'error',
        containerId: 'stockNewsAlertsSlice',
      });
    },
    createAlertFail(state, action) {
      state.err = action.payload;
      state.loading = false;
      state.completed = false;
      state.response = null;
    },
    fetchEditAlertStart(state, action) {
      state.err = null;
      state.editLoading = true;
    },
    fetchEditAlertSuccess(state, action) {
      state.editLoading = false;
      if (action.payload.data.success) {
        state.editAlertData = action.payload.data;
      } else {
        state.err = action.payload.data.message;
        toast(action.payload.data.message, {
          type: 'error',
          containerId: 'stockNewsAlertsSlice',
        });
      }
    },
    fetchEditAlertFail(state, action) {
      state.err = action.payload;
      state.editLoading = false;
    },
    updateAlertStart(state, action) {
      state.err = null;
      state.loading = true;
      state.completed = false;
      state.response = null;
    },
    updateAlertSuccess(state, action) {
      state.loading = false;
      state.response = action.payload.data.message;
      state.completed = !!action.payload.data.success;

      if (action.payload.data.success) {
        // add in UI since there is a delay in alerts app backend
        const data = action.payload.data.alert_setting;
        state.alertSettings = [
          ...state.alertSettings.filter((alert) => alert.id !== data.id),
          data,
        ];
      }
      toast(action.payload.data.message, {
        type: action.payload.data.success ? 'success' : 'error',
        containerId: 'stockNewsAlertsSlice',
      });
    },
    updateAlertFail(state, action) {
      state.err = action.payload;
      state.loading = false;
      state.completed = false;
      state.response = null;
    },
    deleteAlertStart(state, action) {
      state.err = null;
      state.loading = true;
    },
    deleteAlertSettingsSuccess(state, action) {
      state.loading = false;

      if (action.payload.data.deleted_ids && action.payload.data.deleted_ids.length > 0) {
        // Remove deleted_ids from state.alertSettings
        state.alertSettings = [
          ...state.alertSettings.filter(
            (setting) => !action.payload.data.deleted_ids.includes(setting.id.toString()),
          ),
        ];
      }

      toast(action.payload.data.message, {
        type: action.payload.data.success ? 'success' : 'error',
        containerId: 'stockNewsAlertsSlice',
      });
    },
    deleteAlertHistoriesSuccess(state, action) {
      state.loading = false;

      if (action.payload.data.success) {
        if (action.payload.data.deleted) {
          // Remove deleted_ids from state.alertHistories
          state.alertHistories = [
            ...state.alertHistories.filter((history) => action.payload.data.deleted !== history.id),
          ];
        } else {
          // clear all state.alertHistories
          state.alertHistories = [];
        }
        state.newHistoriesCount = action.payload.data.new_histories_count;
      }

      toast(action.payload.data.message, {
        type: action.payload.data.success ? 'success' : 'error',
        containerId: 'stockNewsAlertsSlice',
      });
    },
    deleteAlertFail(state, action) {
      state.err = action.payload;
      state.loading = false;
    },
    clearAlertsHistory(state) {
      state.alertHistories = [];
      state.newHistoriesCount = 0;
    },
    updateSelectedAlertSettings(state, action) {
      state.selectedAlertSettings = action.payload;
    },
    updateAlertSettingsFilters(state, action) {
      const { activeFilter, inactiveFilter, countersFilter } = action.payload;
      state.alertSettingsFilter = {
        active: activeFilter,
        inactive: inactiveFilter,
        counters: countersFilter,
      };
    },
    updateAlertSettingsSorting(state, action) {
      const { by, dir } = action.payload;
      state.alertSettingsSort = { by, dir };
    },
    updateUserSetHistoryFilter(state, action) {
      const { counters } = action.payload;
      state.userSetHistoryFilter = {
        counters,
      };
    },
    showAlertSetting(state, action) {
      if (action.payload.data.success) {
        const data = { ...action.payload.data.alert_setting, new: action.payload.newAlert };
        state.alertSettings = [
          ...state.alertSettings.filter(
            (alert) =>
              !(
                (alert.expressContent === data.expressContent && alert.id === 0) ||
                alert.id === data.id
              ),
          ),
          data,
        ];
      }
    },
    setMarkNewAlertHistory(state, action) {
      if (action.payload.data.success) {
        const newData = [
          ...state.alertHistories.filter(
            (history) => action.payload.data.objectID !== history.objectID,
          ),
          ...state.alertHistories
            .filter((history) => action.payload.data.objectID === history.objectID)
            .map((history) => {
              return { ...history, ...{ alertStatus: 2 } };
            }),
        ];

        state.alertHistories = newData.sort((a, b) => (a.id > b.id ? -1 : b.id > a.id ? 1 : 0));
        state.newHistoriesCount = newData.filter((alert) => alert.alertStatus === 1).length;
      }
    },
    updateActiveStatusStart(state, action) {
      state.err = null;
      state.loading = true;
    },
    updateActiveStatusSuccess(state, action) {
      state.loading = false;
      const updatedAlerts = action.payload.data.alert_settings;
      if (action.payload.data.success || updatedAlerts.length > 0) {
        state.alertSettings = state.alertSettings.map((alertSetting) => {
          // Find the updated alert object with the same ID
          const updatedAlert = updatedAlerts.find((alert) => alert.id === alertSetting.id);
          // If found, replace the existing alert setting with the updated one
          return updatedAlert ? { ...alertSetting, ...updatedAlert } : alertSetting;
        });
      }
      toast(action.payload.data.message, {
        type: action.payload.data.success ? 'success' : 'error',
        containerId: 'stockNewsAlertsSlice',
      });
    },
    updateActiveStatusFail(state, action) {
      state.err = action.payload;
      state.loading = false;
    },
  },
});

export default stockNewsAlertsSlice.reducer;
export const {
  clearAlertsHistory,
  updateSelectedAlertSettings,
  updateAlertSettingsFilters,
  updateAlertSettingsSorting,
  updateUserSetHistoryFilter,
} = stockNewsAlertsSlice.actions;

const {
  fetchAlertsStart,
  fetchAlertsSettingSuccess,
  fetchAlertsFail,
  fetchAlertsHistoryStart,
  fetchAlertsHistorySuccess,
  fetchAlertsHistoryFail,
  createAlertStart,
  createAlertSuccess,
  createAlertFail,
  fetchEditAlertStart,
  fetchEditAlertSuccess,
  fetchEditAlertFail,
  updateAlertStart,
  updateAlertSuccess,
  updateAlertFail,
  deleteAlertStart,
  deleteAlertSettingsSuccess,
  deleteAlertHistoriesSuccess,
  deleteAlertFail,
  showAlertSetting,
  setMarkNewAlertHistory,
  updateActiveStatusStart,
  updateActiveStatusSuccess,
  updateActiveStatusFail,
} = stockNewsAlertsSlice.actions;

export const fetchAlertsHistory =
  ({ counters, orderBy, orderDir, cancelToken }) =>
  (dispatch) => {
    const params = [];

    if (counters) {
      params.push(`counters=${counters}`);
    }

    if (orderBy) {
      params.push(`order_by=${orderBy}`);
    }

    if (orderDir) {
      params.push(`order_dir=${orderDir}`);
    }

    const url = `alerts/all_stock_alert_history.json${
      params.length > 0 ? `?${params.join('&')}` : ''
    }`;

    dispatch(fetchAlertsHistoryStart());
    api
      .get(url, {
        cancelToken,
      })
      .then(({ data }) => {
        dispatch(fetchAlertsHistorySuccess({ data }));
      })
      .catch((error) => {
        dispatch(fetchAlertsHistoryFail(error));
      });
  };

export const fetchAlerts =
  ({ cancelToken }) =>
  (dispatch) => {
    const url = `alerts/all_stock_alert_setting.json`;

    dispatch(fetchAlertsStart());
    api
      .get(url, {
        cancelToken,
      })
      .then(({ data }) => {
        dispatch(fetchAlertsSettingSuccess({ data }));
        dispatch(fetchAlertsHistory({ cancelToken, counters: ['ALL'] }));
      })
      .catch((error) => {
        dispatch(fetchAlertsFail(error));
      });
  };

export const createAlert =
  ({ cancelToken, formData }) =>
  (dispatch) => {
    const url = `alerts/add_alert_setting.json`;

    dispatch(createAlertStart());
    api
      .post(url, formData, { cancelToken })
      .then(({ data }) => {
        dispatch(createAlertSuccess({ data }));
      })
      .catch((error) => {
        dispatch(createAlertFail(error));
      });
  };

export const fetchEditAlert =
  ({ cancelToken, id }) =>
  (dispatch) => {
    const url = `alerts/fetch_edit_alert.json?ObjectID=${id}`;
    dispatch(fetchEditAlertStart());
    api
      .get(url, { cancelToken })
      .then(({ data }) => {
        dispatch(fetchEditAlertSuccess({ data }));
      })
      .catch((error) => {
        dispatch(fetchEditAlertFail(error));
      });
  };

export const updateAlert =
  ({ cancelToken, id, formData }) =>
  (dispatch) => {
    const url = `alerts/edit_alert_setting.json`;
    const params = { ...formData, ObjectID: id };
    dispatch(updateAlertStart());
    api
      .post(url, params, { cancelToken })
      .then(({ data }) => {
        dispatch(updateAlertSuccess({ data }));
      })
      .catch((error) => {
        dispatch(updateAlertFail(error));
      });
  };

export const deleteAlertSettings =
  ({ cancelToken, alertSettingIDs }) =>
  (dispatch) => {
    const url = `alerts/batch_destroy_alert_settings.json`;

    dispatch(deleteAlertStart());
    api
      .post(
        url,
        { alert_setting_ids: alertSettingIDs.join(',') },
        {
          cancelToken,
        },
      )
      .then(({ data }) => {
        dispatch(deleteAlertSettingsSuccess({ data }));
      })
      .catch((error) => {
        dispatch(deleteAlertFail(error));
      });
  };

export const updateActiveStatus =
  ({ cancelToken, alertSettingIDs, action }) =>
  (dispatch) => {
    const url = `alerts/batch_change_alert_active_status.json`;

    dispatch(updateActiveStatusStart());
    api
      .post(
        url,
        { alert_setting_ids: alertSettingIDs.join(','), update_action: action },
        {
          cancelToken,
        },
      )
      .then(({ data }) => {
        dispatch(updateActiveStatusSuccess({ data }));
      })
      .catch((error) => {
        dispatch(updateActiveStatusFail(error));
      });
  };
export const deleteAlertHistories =
  ({ cancelToken, alertHistoryID }) =>
  (dispatch) => {
    const url = `alerts/batch_destroy_alert_history.json`;

    dispatch(deleteAlertStart());
    api
      .post(
        url,
        { alert_history_id: alertHistoryID },
        {
          cancelToken,
        },
      )
      .then(({ data }) => {
        dispatch(deleteAlertHistoriesSuccess({ data }));
      })
      .catch((error) => {
        dispatch(deleteAlertFail(error));
      });
  };

export const showNewAlertSetting =
  ({ cancelToken, id, newAlert }) =>
  (dispatch) => {
    const url = `alerts/show_alert_setting.json?id=${id}`;

    api
      .get(url, {
        cancelToken,
      })
      .then(({ data }) => {
        dispatch(showAlertSetting({ data, newAlert }));
      });
  };

export const markNewAlertHistory =
  ({ cancelToken, objectID }) =>
  (dispatch) => {
    const url = `alerts/update_alert_status.json`;

    api.post(url, { object_id: objectID, cancelToken }).then(({ data }) => {
      dispatch(setMarkNewAlertHistory({ data }));
    });
  };
