import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Modal, Table } from 'react-bootstrap';

import { useDispatch, useSelector } from 'react-redux';
import {
  fetchData,
  fetchScreenStockData,
  fetchScreenScannerResult,
} from './predefinedTAScreensSlice';
import Loader from '../../../components/Loader';

import APIErrorHandler from '../../../components/APIErrorHandler';
import MarketSelectDropdown from '../../../components/MarketSelectDropdown';
import SignalTable from './SignalTable';
import styles from './predefinedTAScreens.module.css';
import IndividualTACondition from './IndividualTACondition';
import { createLoadableComponent } from '../../../utils/Loadable';

const StockInfoPopup = createLoadableComponent(() => import('../../../components/StockInfoPopup'));

const ALL_MARKETS = ['sgx', 'bursa'];
const { CancelToken } = axios;

const getSignalColor = (signal) => {
  let color = 'text-gray-600';

  switch (signal.toString().toLowerCase()) {
    case 'long':
      color = 'text-primary';
      break;
    case 'short':
      color = 'text-danger';
      break;
    default:
      color = 'text-gray-600';
  }
  return color;
};

const getChgColor = (value) => {
  let color = 'text-gray-600';

  if (value === '' || value === '-') {
    color = 'text-gray-600';
  } else if (value > 0) {
    color = 'text-primary';
  } else if (value < 0) {
    color = 'text-danger';
  }

  return color;
};

function PredefinedTAScreensMain({ market }) {
  const dispatch = useDispatch();
  const source = CancelToken.source();
  const [isSSR, setIsSSR] = useState(true);
  const data = useSelector((state) => state.predefinedTAScreens.data);
  const screenStockData = useSelector((state) => state.predefinedTAScreens.screenStockData);
  const loading = useSelector((state) => state.predefinedTAScreens.loading);
  const stockDataLoading = useSelector((state) => state.predefinedTAScreens.stockDataLoading);

  const error = useSelector((state) => state.predefinedTAScreens.err);
  const defaultMarket = useSelector((state) => state.selectedMarket.selectedMarket);
  const [selectedMarket, setSelectedMarket] = useState(market || defaultMarket);
  const [showModal, setShowModal] = useState(false);
  const [selectedScanner, setSelectedScanner] = useState(null);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleClickViewResult = (counter) => {
    setShowModal(true);
    dispatch(fetchScreenStockData({ counter, cancelToken: source.token }));
  };

  const handleClickScannerResult = (scannerID) => {
    if (scannerID !== selectedScanner) {
      dispatch(
        fetchScreenScannerResult({ scannerID, market: selectedMarket, cancelToken: source.token }),
      );
      setSelectedScanner(scannerID);
    }
  };

  useEffect(() => {
    setIsSSR(typeof document === 'undefined');
  }, []);

  // update selectedMarket state when selectedMarket redux has changed.
  useEffect(() => {
    if (defaultMarket == null || defaultMarket === selectedMarket.toLowerCase()) {
      return;
    }
    setSelectedMarket(defaultMarket);
  }, [defaultMarket]);

  useEffect(() => {
    dispatch(fetchData({ market: selectedMarket, cancelToken: source.token }));
  }, [selectedMarket]);

  // Load first scanner result after data has changed/ready
  useEffect(() => {
    setSelectedScanner(null);
    if (data && Object.keys(data).length > 0 && data?.scanner?.data?.length > 0) {
      const scannerID = data.scanner.data[0].id;
      handleClickScannerResult(scannerID);
    }
  }, [data]);

  if (isSSR) {
    return <Loader visible={isSSR} className="g-height-800" />;
  }

  return (
    <>
      <div className="row align-items-center g-lg-mb-30 g-mb-20">
        <div className="col-lg-8">
          <h3 className="g-mb-5 g-lg-mb-0">Predefined TA Screens</h3>
        </div>
      </div>
      <div className="divider-h w-100 g-mb-20 g-lg-mb-30" />
      <MarketSelectDropdown
        market={selectedMarket}
        variant="light"
        validMarkets={ALL_MARKETS}
        updateUrlOnClick
      />
      {error && (
        <div className="g-mt-30">
          <APIErrorHandler error={error} />
        </div>
      )}
      {!error && (
        <>
          <div className="row g-3 justify-content-between g-mt-30 g-mb-30">
            <div className="col-xl">
              {loading && <Loader visible={loading} />}
              {data?.long?.length > 0 && (
                <>
                  <h4>Most Long Signals</h4>
                  <SignalTable data={data.long} handleClickViewResult={handleClickViewResult} />
                </>
              )}
            </div>
            <div className="col-xl">
              {loading && <Loader visible={loading} />}
              {data?.short?.length > 0 && (
                <>
                  <h4>Most Short Signals</h4>
                  <SignalTable data={data.short} handleClickViewResult={handleClickViewResult} />
                </>
              )}
            </div>
          </div>

          {data?.scanner?.data?.length > 0 && (
            <>
              <h4>Individual TA Condition</h4>
              <IndividualTACondition
                selectedScanner={selectedScanner}
                handleClickViewResult={handleClickViewResult}
                handleClickScannerResult={handleClickScannerResult}
              />

              <div className="mt-5">
                <div className="g-text-size-14 g-fw-500 footer-disclaimer mb-3 g-mt-25">Notes:</div>
                <div className="sic_legendAlpha">
                  <div className="d-flex gap-1 ">
                    <sup className="footer-disclaimer">a</sup>
                    <div className="g-text-size-14 footer-disclaimer">
                      TA Scanner Results based on historical data up to {data.scanner.date}
                    </div>
                  </div>
                  <div className="d-flex gap-1 ">
                    <sup className="footer-disclaimer">b</sup>
                    <div className="g-text-size-14 footer-disclaimer">
                      The data for this page is updated every weekday evening at 9:00pm.
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
      <StockInfoPopup />
      {showModal && (
        <Modal
          size="lg"
          show={showModal}
          centered
          dialogClassName="g-text-size-14"
          onHide={handleCloseModal}>
          {stockDataLoading && (
            <Modal.Body>
              <Loader visible={stockDataLoading} />
            </Modal.Body>
          )}
          {!stockDataLoading && screenStockData.success && (
            <>
              <Modal.Header closeButton>
                <Modal.Title>
                  {screenStockData.name}
                  <span className="text-gray-600 g-text-size-18 g-ml-10">
                    {screenStockData.counter}
                  </span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p className="text-muted">
                  <i>
                    {screenStockData.delay} {screenStockData.updated_at}
                  </i>
                </p>
                <Table className="g-mb-30">
                  <tbody>
                    <tr>
                      <td className="text-gray-700 g-per-width-20">
                        Last Done ({screenStockData.market})
                      </td>
                      <th className="g-per-width-30 text-end">{screenStockData.last_done}</th>
                      <td className="text-gray-700 g-per-width-20">Volume</td>
                      <th className="g-per-width-30 text-end">{screenStockData.vol}</th>
                    </tr>
                    <tr>
                      <td className="text-gray-700 g-per-width-20">Change (%)</td>
                      <th
                        className={`${getChgColor(
                          screenStockData.per_change,
                        )} g-per-width-30 text-end`}>
                        {screenStockData.change} ({screenStockData.per_change})
                      </th>
                      <td className="text-gray-700 g-per-width-20">Range</td>
                      <th className="g-per-width-30 text-end">{screenStockData.range}</th>
                    </tr>
                  </tbody>
                </Table>
                {screenStockData?.ta_results?.updated_at && (
                  <p className="text-muted">
                    <i>
                      TA Scanner Results based on historical data up to{' '}
                      {screenStockData.ta_results.updated_at}
                    </i>
                  </p>
                )}
                <ul className={`${styles.resultList} list-unstyled`}>
                  {screenStockData.ta_results.data.map((result, index) => {
                    const signalColor = getSignalColor(result.signal);

                    return (
                      <li key={index} className={`p-2 ${styles.resultListItem}`}>
                        <div className="row g-3">
                          <div className="col-lg-6">
                            <div className="g-fw-700 g-mb-15">{result.condition}</div>
                            <div>
                              <span className="text-gray-700">Signal:</span>{' '}
                              <strong className={signalColor}>{result.signal}</strong>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="g-fw-700 g-mb-15">Explanation:</div>
                            {result.explanation}
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </Modal.Body>
            </>
          )}
        </Modal>
      )}
    </>
  );
}

PredefinedTAScreensMain.propTypes = {
  market: PropTypes.string,
};

export default PredefinedTAScreensMain;
