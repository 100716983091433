import { DataSubscription, pushPage } from "./protocol";
import { VisualTable } from "./tables";
import { resetModeToStreaming } from ".";
import {
  formatDecimal,
  formatNumberAddCommas,
  formatPrice,
  formatChange,
  getMarketFromCounter,
  getSymbolFromCounter,
} from '../utils';


const tsStreamTable = {
  counter: null,
  isIndex: false,
  market: null,
  visualTable: null,
  isAlreadyStreaming: false,
  referencePrice: 0,
  precision: 0,
  totalVolume: 0,
  totalVolumeFromStatic: 0,
  curPage: 1,
  maxRowsInPage: 50,
  shouldStream: false,
};

export const startTimeAndSalesStreaming = function() {
  const timesalesTable = document.getElementById('sic_timeAndSalesTable');
  if (!timesalesTable || tsStreamTable.isAlreadyStreaming) {
    return;
  }

  // update tsStreamTable fields based on sic_timeAndSalesTable dataset
  tsStreamTable.counter = getSymbolFromCounter(timesalesTable.dataset.counter);
  tsStreamTable.isIndex = timesalesTable.dataset.is_index === 'true';
  tsStreamTable.market = getMarketFromCounter(timesalesTable.dataset.counter).toLowerCase();
  tsStreamTable.referencePrice = Number(timesalesTable.dataset.reference_price);
  tsStreamTable.precision = Number(timesalesTable.dataset.precision);
  if (Number(timesalesTable.dataset.cur_page)) {
    tsStreamTable.curPage = Number(timesalesTable.dataset.cur_page);
  }
  if (Number(timesalesTable.dataset.max_rows_per_page)) {
    tsStreamTable.maxRowsInPage = Number(timesalesTable.dataset.max_rows_per_page);
  }
  tsStreamTable.shouldStream = timesalesTable.dataset.streaming_time_and_sales === 'true';
  tsStreamTable.totalVolume = 0;

  const firstRowCells = timesalesTable.querySelector('tbody > tr').cells;
  tsStreamTable.totalVolumeFromStatic = parseFloat(firstRowCells[firstRowCells.length - 1].innerHTML?.replaceAll(',', '')) || 0;

  // ensure pushPage will be in streaming mode
  resetModeToStreaming();

  const dataSubscription = new DataSubscription("TimeAndSales", tsStreamTable.counter, ['time', 'type', 'price', 'change', 'volume', 'total_volume']);
  if (!tsStreamTable.visualTable) {
    tsStreamTable.visualTable = constructStreamingTable(dataSubscription);
  }

  if (tsStreamTable.visualTable) {
    tsStreamTable.visualTable.last_row_data = {
      'price' : parseFloat(firstRowCells[2].innerHTML?.replaceAll(',', '')) || 0,
      'change' : parseFloat(firstRowCells[3].innerHTML?.replaceAll(',', '')) || 0,
    };
    tsStreamTable.visualTable.last_item_pos = null;
  
    if (tsStreamTable.shouldStream && tsStreamTable.referencePrice &&
      (tsStreamTable.curPage == 1 || tsStreamTable.curPage == -1))
    {
      tsStreamTable.visualTable.setSubscription(dataSubscription);
      pushPage.scheduleTable(tsStreamTable.visualTable);
      pushPage.createEngine(); // this does nothing if pushPage engine is already running
      tsStreamTable.isAlreadyStreaming = true;
    }
  }
}

export const stopTimeAndSalesStreaming = function() {
  if (!(tsStreamTable.visualTable && tsStreamTable.isAlreadyStreaming)) {
    return;
  }
  if (tsStreamTable.visualTable) {
    pushPage.cancelTable(tsStreamTable.visualTable);
    // note: set streaming table(s) to null for GC
    tsStreamTable.visualTable = null;
  }
  tsStreamTable.isAlreadyStreaming = false;
}

function formatTimeAndSalesTypeFromStream(value_) {
  var formated_val;
  switch (value_)
  {
    case 'B':
      formated_val = 'Buy Up';
      break;
    case 'S':
      formated_val = 'Sell Down';
      break;
    case 'PC':
      formated_val = 'Preclose';
      break;
    case 'PO':
      formated_val = 'Preopen';
      break;
    case 'UN':
      formated_val = 'Unknown';
      break;
    case 'M':
      formated_val = 'Mid';
      break;
    case 'AJ':
      formated_val = 'Adjust';
      break;
    case 'CP':
      formated_val = 'Close Price';
      break;
    default:
      formated_val = 'Adjust';//somehow comet server doesn't provide AJ for the time and sales, so we have to default it to be Adjust
  }
  return formated_val;
}

/*********************************************************/
/** sample streaming data:
{"time":"20110525092545","price":0.595,"volume":2,"code":"R14.SI","itemPos":"2011052501254592908","type":"B","short_name":"Ramba"}
*/
function constructStreamingTable(data_subscription_) {
  const timeAndSalesTable = new VisualTable("sic_timeAndSalesTable", data_subscription_);

  timeAndSalesTable.stop_streaming = true;

  timeAndSalesTable.onUpdating = function ()
  {
    this.stop_streaming = false;
    var time_and_sales_table = document.getElementById(this.html_id);
    var has_data =  time_and_sales_table.querySelectorAll('tbody > tr > td').length > 2;
    if (!has_data)
    {
      time_and_sales_table.querySelector('tbody').innerHTML = '';
    }
  };

  timeAndSalesTable.beforeRowAdd = function (parent_node_, new_row_)
  {
    //always insert at the top row
    return $("tr:first", parent_node_);
  };

  timeAndSalesTable.allowUpdate = function (update_)
  {
    //don't allow update if the stop_streaming flag is true
    if (this.stop_streaming)
      return false;

    var allowUpdate = false;

    tsStreamTable.totalVolume += update_.volume;
    // log("total-volume" + totalVolumeFromStatic + " total-vol " + total_volume + " " + this.last_item_pos + " " + update_.itemPos);
    //only start inserting data when the total volume is more than the total volume retrieved from static data
    if (!(
      Number(tsStreamTable.totalVolume.toFixed(tsStreamTable.precision)) >
      Number(tsStreamTable.totalVolumeFromStatic.toFixed(tsStreamTable.precision)) &&
      update_.code === tsStreamTable.counter
    ))
    {
      allowUpdate = false;
    }
    else if (this.last_item_pos && this.last_item_pos >= update_.itemPos)
    {
      //if the last item position is set and last item position is >= current item position, then don't insert the data
      //this is to prevent the time and sales snapshot data is resent by the streaming server
      allowUpdate = false;
    }
    else
    {
      allowUpdate = true;
    }

    this.last_item_pos = update_.itemPos;
    return allowUpdate;
  };

  timeAndSalesTable.prepareFieldUpdate = function (field_, cell_, updates_)
  {
    updates_.change = updates_.price - tsStreamTable.referencePrice;
    updates_.perc_change = updates_.change / tsStreamTable.referencePrice * 100;
    updates_.total_volume = tsStreamTable.totalVolume;
  };

  timeAndSalesTable.prepareCellUpdate = function(update_, field_, change_whole_row_, cell_)
  {
    var curValue = update_.newValue;

    if (field_ == "type")
    {
      switch(update_.type)
      {
        case 'B':
          update_.addClassNames = "sic_up";
          break;
        case 'S':
          update_.addClassNames = 'sic_down';
          break;
        default:
      }
    }

    if (field_ == "price" || field_ == "change")
    {
      if (update_.price > this.last_row_data.price)
        update_.addClassNames = "sic_upBackground";
      else if (update_.price < this.last_row_data.price)
        update_.addClassNames = "sic_downBackground";
    }

    if (!change_whole_row_)
    {
      update_.dynamicEffect = getNumericDynamicUpdateEffect(update_.oldValue, curValue, field_);
    }
  };

  timeAndSalesTable.afterRowUpdate = function(row_, update_)
  {
    VisualTable.applyDynamicEffect(row_, "sic_neutralBackground", 4000);
    var rows = $('#' + this.html_id).find("tbody > tr");

    //highlight row if the value is greater than 150000
    var value = update_.price * update_.volume;

    if (value > 150000 && !tsStreamTable.isIndex)
    {
      row_.addClass("sic_highlight");
    }

    this.adjustCurrentDisplay(rows);
    // this.updateSummary(update_);

    //store last row price so as can compare row change when new time and sales comes in
    this.last_row_data = update_;
  };

  timeAndSalesTable.adjustCurrentDisplay = function (rows_)
  {
    if (tsStreamTable.curPage == 1 && rows_.length > tsStreamTable.maxRowsInPage)
    {
      //remove last row only if it is the first page       
      tsStreamTable.market !== 'hkex' && rows_.last().remove();
    }
  };

  timeAndSalesTable.formatField = function(field_, value_, update_, volume_size_)
  {
    var formated_val = null;

    if (field_ == "time")
    {
      if (tsStreamTable.market == 'idx') {
        value_ = value_ - 10000;
      }
      var time = value_ + "";
      var hour = time.substr(8, 2);
      var min = time.substr(10, 2);
      var second = time.substr(12, 2);
      formated_val = hour + ":" + min + ":" + second;
    }
    else if (field_ == "type")
    {
      formated_val = formatTimeAndSalesTypeFromStream(value_);
    }
    else if (field_ == "price")
    {
      formated_val = formatPrice(parseFloat(value_), tsStreamTable.precision);
    }
    else if (field_ == "change")
    {
      var value = parseFloat(value_);
      if (value == 0)
        formated_val = '-';
      else
        formated_val = formatChange(parseFloat(value), tsStreamTable.precision);
    }
    else if (field_ == "perc_change")
    {
      var value = parseFloat(value_).toFixed(2);
      if (value == 0)
        formated_val = '-';
      else
        formated_val = value > 0 ? '+' + value : value;
    }
    else if (field_ == "volume" || field_ == "total_volume")
    {
      if (value_ == 0)
        formated_val = '-';
      else
        formated_val = formatNumberAddCommas(formatDecimal(value_, 3, false));
    }
    else
      formated_val = value_;

    return formated_val ;
  };

  return timeAndSalesTable;
}
