import { createSlice } from '@reduxjs/toolkit';
import api from '../../../../utils/api';
import { getCounterList } from '../../portfolioSlice';

const portfolioInstrumentsSlice = createSlice({
  name: 'portfolioInstruments',
  initialState: {
    err: null,
    response: '',
  },
  reducers: {
    instrumentsAddRemoveStart(state, action) {
      state.err = null;
      state.response = '';
    },
    instrumentsAddRemoveSuccess(state, action) {
      if (action.payload.data.error) {
        state.err = { message: action.payload.data.error };
      } else {
        state.response = action.payload.data.message;
      }
    },
    instrumentsAddRemoveFail(state, action) {
      state.err = action.payload;
    },
    valueinstrumentsResetResponseAndError(state, action) {
      state.err = null;
      state.response = '';
    },
  },
});

export default portfolioInstrumentsSlice.reducer;

const {
  instrumentsAddRemoveStart,
  instrumentsAddRemoveSuccess,
  instrumentsAddRemoveFail,
  valueinstrumentsResetResponseAndError,
} = portfolioInstrumentsSlice.actions;

export const addToInstruments =
  ({ id, counter, selectedLayout, cancelToken }) =>
  (dispatch) => {
    dispatch(instrumentsAddRemoveStart());
    const params = { counter, layout: selectedLayout };
    api
      .post(`portfolio/${id}/add_counter.json`, params, { cancelToken })
      .then(({ data }) => {
        dispatch(instrumentsAddRemoveSuccess({ data }));
        dispatch(getCounterList({ selectedPortfolioID: id, selectedLayout, instrument: counter }));
      })
      .catch((error) => {
        dispatch(instrumentsAddRemoveFail(error));
      });
  };

export const removeFromInstruments =
  ({ id, counter, selectedLayout, cancelToken }) =>
  (dispatch) => {
    dispatch(instrumentsAddRemoveStart());
    const params = { counter, layout: selectedLayout };
    api
      .post(`portfolio/${id}/remove_counter.json`, params, { cancelToken })
      .then(({ data }) => {
        dispatch(instrumentsAddRemoveSuccess({ data }));
        dispatch(getCounterList({ selectedPortfolioID: id, selectedLayout, instrument: counter }));
      })
      .catch((error) => {
        dispatch(instrumentsAddRemoveFail(error));
      });
  };

export const resetInstrumentsResponseAndError = () => (dispatch) => {
  dispatch(valueinstrumentsResetResponseAndError());
};
