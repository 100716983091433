import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import am5themes_Responsive from '@amcharts/amcharts5/themes/Responsive';

function ConsensusEstimatesHistory({ data }) {
  useLayoutEffect(() => {
    let formattedRatingsData = [];
    let formattedPricesData = [];

    if (!data.error && data.ratings && data.prices) {
      formattedRatingsData = data.ratings.map((d) => ({
        ...d,
        date: Date.parse(d.date),
      }));

      formattedPricesData = data.prices.map((d) => ({
        ...d,
        date: Date.parse(d.date),
        actual_price_f: parseFloat(d.actual_price_f),
        target_price_f: parseFloat(d.target_price_f),
      }));
    }

    const root = am5.Root.new('concensus-estimate-history');
    const responsive = am5themes_Responsive.newEmpty(root);

    root.setThemes([am5themes_Animated.new(root), responsive]);
    const xyChart = am5xy.XYChart.new(root, {
      layout: root.verticalLayout,
      maxTooltipDistance: 0,
      panX: true,
      panY: true,
      wheelX: 'panX',
      wheelY: 'zoomX',
      pinchZoomX: true,
    });
    const chart = root.container.children.push(xyChart);

    // Create X-Axis
    const xAxisPriceRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 30,
      cellStartLocation: 0.1,
      cellEndLocation: 0.9,
    });
    const xAxisPrice = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: { timeUnit: 'day', count: 1 },
        renderer: xAxisPriceRenderer,
      }),
    );
    xAxisPrice.get('renderer').labels.template.set('visible', false);
    xAxisPrice.get('renderer').grid.template.setAll({
      strokeWidth: 0,
      visible: false,
    });
    xAxisPrice.get('dateFormats').day = 'dd MMM yyyy';
    xAxisPrice.data.setAll(formattedPricesData);

    const xAxisRatingRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 10,
      cellStartLocation: 0.1,
      cellEndLocation: 0.9,
    });
    xAxisRatingRenderer.labels.template.setAll({
      fill: am5.color(0x646464),
      fontSize: 11,
    });
    const xAxisRating = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: { timeUnit: 'month', count: 1 },
        renderer: xAxisRatingRenderer,
        tooltip: am5.Tooltip.new(root, {}),
      }),
    );
    xAxisRating.get('renderer').grid.template.setAll({
      strokeWidth: 0,
      visible: false,
    });
    xAxisRating.get('dateFormats').month = 'MMM yy';
    xAxisRating.get('periodChangeDateFormats').month = 'MMM yy';
    xAxisRating.data.setAll(formattedPricesData);

    // Detect and keep DateAxis first label month, and use regex pattern to hide every 2 month
    const firstDate = (data.ratings || [])[0]?.date || '';
    const dateRegex1 = /Feb|Apr|Jun|Aug|Oct|Dec/;
    const dateRegex2 = /Jan|Mar|May|Jul|Sep|Nov/;
    const datePattern = !dateRegex1.test(firstDate) ? dateRegex1 : dateRegex2;

    xAxisRating.get('renderer').labels.template.adapters.add('text', function (text, target) {
      if (target.dataItem) {
        const interval = xAxisRating.getPrivate('gridInterval');
        const date = new Date(target.dataItem.get('value'));
        const formats = xAxisRating.get('dateFormats');
        const format = formats[interval.timeUnit];
        const dateLabel = root.dateFormatter.format(date, format);
        const formatLabel = datePattern.test(dateLabel) ? '' : dateLabel;
        return formatLabel;
      }
      return text;
    });

    // Create Y-axis
    const yAxisLeftRenderer = am5xy.AxisRendererY.new(root, {
      strokeOpacity: 0.1,
    });
    yAxisLeftRenderer.labels.template.setAll({
      fill: am5.color(0x646464),
      fontSize: 11,
    });
    const yAxisLeft = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        max: 100,
        numberFormat: "#'%'",
        calculateTotals: true,
        renderer: yAxisLeftRenderer,
      }),
    );
    const yTitleLeft = am5.Label.new(root, {
      text: 'Percentage of Recomendations',
      textAlign: 'center',
      y: am5.p50,
      rotation: -90,
      fill: am5.color(0x646464),
    });

    yAxisLeft.children.unshift(yTitleLeft);

    const yAxisRightRenderer = am5xy.AxisRendererY.new(root, {
      opposite: true,
    });
    yAxisRightRenderer.labels.template.setAll({
      fill: am5.color(0x646464),
      fontSize: 11,
    });
    const yAxisRight = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: yAxisRightRenderer,
        numberFormat: '#.0',
      }),
    );
    yAxisRight.get('renderer').grid.template.setAll({
      strokeWidth: 0,
      visible: false,
    });

    const yTitleRight = am5.Label.new(root, {
      text: 'Price',
      textAlign: 'center',
      x: am5.p100,
      dx: 30,
      y: am5.p50,
      centerX: am5.p50,
      position: 'absolute',
      rotation: 90,
      fill: am5.color(0x646464),
    });
    yAxisRight.children.unshift(yTitleRight);

    const legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50,
      }),
    );
    const yTitleTop = am5.Label.new(root, {
      text: '[bold]Number of Ratings[/]',
      y: 0,
      x: am5.percent(90),
      fontSize: 14,
      textAlign: 'center',
      fill: am5.color(0x646464),
    });
    chart.children.unshift(yTitleTop);

    function createColumnChartSeries(name, fieldName, color, isLast = false) {
      const tooltip = am5.Tooltip.new(root, {
        getFillFromSprite: false,
        getStrokeFromSprite: false,
        autoTextColor: false,
        getLabelFillFromSprite: true,
      });
      tooltip.get('background').setAll({
        stroke: am5.color(0x000000),
        color: am5.color(0x000000),
        fill: am5.color(0xffffff),
        strokeOpacity: 0.8,
      });
      const series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name,
          stacked: true,
          xAxis: xAxisRating,
          yAxis: yAxisLeft,
          valueYField: fieldName,
          valueYShow: 'valueYTotalPercent',
          valueXField: 'date',
          fill: am5.color(color),
          tooltip,
          maskBullets: false,
        }),
      );
      series.data.setAll(formattedRatingsData);

      // show the roundoff percentage on the column body
      series.bullets.push(function () {
        const sprite = am5.Label.new(root, {
          text: "{valueYTotalPercent.formatNumber('#.')}",
          fill: root.interfaceColors.get('alternativeText'),
          fontSize: 11,
          centerY: am5.p50,
          centerX: am5.p50,
          populateText: true,
          fontWeight: 'bold',
        });

        sprite.adapters.add('text', function (text, target) {
          if (target._dataItem.dataContext[fieldName] === 0) {
            return '';
          }
          return text;
        });

        return am5.Bullet.new(root, {
          sprite,
        });
      });

      if (isLast) {
        // show the total rating above the column
        series.bullets.push(function () {
          const sprite = am5.Label.new(root, {
            text: '{ratings_total}',
            centerX: am5.percent(50),
            centerY: am5.percent(99),
            fontSize: 11,
            fontWeight: 'bold',
            populateText: true,
          });

          return am5.Bullet.new(root, {
            locationY: 0.99,
            sprite,
          });
        });
      }

      series.get('tooltip').label.adapters.add('text', function (text, target) {
        text = "[#000000 bold]Ratings ({valueX.formatDate('MMM yyyy')})[/]\n\n";
        let total = '';
        let i = 0;

        chart.series.each(function (series) {
          if (series.constructor.className == 'ColumnSeries') {
            const textColor = '#000000 ';
            const tooltipDataItem = series.get('tooltipDataItem');
            if (tooltipDataItem) {
              if (i != 0) {
                text += '\n';
              }
              if (tooltipDataItem.dataContext && tooltipDataItem.dataContext.ratings_total) {
                total = tooltipDataItem.dataContext.ratings_total.toString();
              }

              text += `[${textColor} bold width:110px]${series.get(
                'name',
              )}:[/] [${textColor} width:20px]${tooltipDataItem.get(
                'valueY',
              )}[/] [${textColor}](${tooltipDataItem.get('valueYTotalPercent').toFixed(0)}%)[/]`;
            }
          }
          i++;
        });

        if (total && total.length > 0) {
          text += `\n\n[#000000 bold width:100px]Total: ${total}[/]`;
        }

        return text;
      });

      series.get('tooltip').on('visible', function (visible, target) {
        if (visible) {
          chart.series.each(function (s) {
            if (s.get('tooltip') !== target) {
              s.get('tooltip').set('visible', false);
            }
          });
        }
      });

      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      series.appear();
      legend.data.push(series);
    }

    function createLineChartSeries(name, field, fillColor) {
      const tooltip = am5.Tooltip.new(root, {
        getFillFromSprite: false,
        getStrokeFromSprite: false,
        autoTextColor: false,
        getLabelFillFromSprite: true,
      });
      tooltip.get('background').setAll({
        stroke: am5.color(0x000000),
        color: am5.color(0x000000),
        fill: am5.color(0xffffff),
        strokeOpacity: 0.8,
      });

      const series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name,
          xAxis: xAxisPrice,
          yAxis: yAxisRight,
          valueYField: field,
          valueXField: 'date',
          fill: am5.color(fillColor),
          stroke: am5.color(fillColor),
          tooltip,
        }),
      );

      series.get('tooltip').label.adapters.add('text', function (text, target) {
        text = "[#000000 bold]Price ({valueX.formatDate('dd MMM yyyy')})[/]\n";
        let upside = '';
        let i = 0;

        chart.series.each(function (series) {
          if (series.constructor.className == 'LineSeries') {
            const textColor = series.get('stroke') ? series.get('stroke').toString() : '';
            const tooltipDataItem = series.get('tooltipDataItem');
            if (tooltipDataItem) {
              if (i != 0) {
                text += '\n';
              }
              if (tooltipDataItem.dataContext && tooltipDataItem.dataContext.upside) {
                upside = tooltipDataItem.dataContext.upside;
              }

              text += `[${textColor} bold width:100px]${series.get(
                'name',
              )}:[/] [${textColor}]${tooltipDataItem.get('valueY')}[/]`;
            }
          }
          i++;
        });

        if (upside && upside.length > 0) {
          text += `\n[#000000 width:100px]Upside (%):[/] [#000000]${upside}%[/]`;
        }

        return text;
      });
      series.strokes.template.setAll({
        strokeWidth: 3,
        templateField: 'strokeSettings',
      });
      series.data.setAll(formattedPricesData);
      legend.data.push(series);
    }

    createColumnChartSeries('Buy', 'ratings_buy', '#009900', false);
    createColumnChartSeries('Overweight', 'ratings_overweight', '#9bd364', false);
    createColumnChartSeries('Hold', 'ratings_hold', '#ffa500', false);
    createColumnChartSeries('Underweight', 'ratings_underweight', '#f17d7d', false);
    createColumnChartSeries('Sell', 'ratings_sell', '#cc0000', true);

    createLineChartSeries('Actual Price', 'actual_price_f', '#4572A7');
    createLineChartSeries('Target Price', 'target_price_f', '#AA4643');

    // Add cursor
    chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        behavior: 'none',
        xAxis: xAxisPrice,
      }),
    );

    // Responsive Rule
    responsive.addRule({
      relevant: am5themes_Responsive.widthL,
      applying() {
        xyChart.set('paddingLeft', 0);
        xyChart.set('paddingRight', 0);
        yAxisLeft.get('renderer').labels.template.setAll({
          fontSize: 10,
        });
        yTitleLeft.set('visible', false);
        yTitleRight.set('visible', false);
        yTitleTop.setAll({ fontSize: 10, x: am5.percent(80) });
        xAxisPrice.setAll({ start: 0.65 });
        xAxisRating.setAll({ start: 0.65 });
        xAxisPriceRenderer.labels.template.setAll({
          rotation: -90,
          centerX: am5.p100,
          dx: -10,
          fontSize: 12,
        });
        legend.labels.template.setAll({
          fontSize: 12,
        });
      },
      removing() {
        yAxisRightRenderer.labels.template.set('visible', true);
        yTitleLeft.set('visible', true);
        yTitleRight.set('visible', true);
        yTitleTop.setAll({ fontSize: 14, x: am5.percent(90) });
        xAxisPrice.setAll({ start: 0 });
        xAxisRating.setAll({ start: 0 });
        xAxisPriceRenderer.labels.template.setAll({
          rotation: 0,
          centerX: am5.p50,
          dx: 0,
          fontSize: 14,
        });
        legend.labels.template.setAll({
          fontSize: 16,
        });
      },
    });

    // Make stuff animate on load
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, []);
  return (
    <div className="container">
      <div className="g-lg-mt-80">
        <h4 className="g-lg-mb-60">Concensus Estimates History</h4>
        <div id="concensus-estimate-history" className="w-100 g-height-500" />
      </div>
    </div>
  );
}

ConsensusEstimatesHistory.propTypes = {};

export default ConsensusEstimatesHistory;
