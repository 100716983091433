import React from 'react';
import { Provider } from 'react-redux';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import store from '../../../utils/store';
import Router, { RouterNavComponent } from '../../../components/PageLocalNav';
import WIP from '../../../components/WIP';
import BuybackOverview from './BuybackOverview';
import BuybackLatest from './BuybackLatest';

function CompanyBuyback({ market, url, tabs, tab, availableMarkets, buybackLatestMinStartDate }) {
  let selectedTab = tab;
  if (selectedTab == null || selectedTab.length === 0) {
    if (Object.keys(tabs).includes('buyback_overview')) {
      selectedTab = 'buyback_overview';
    } else {
      selectedTab = 'buyback_latest';
    }
  }

  const parentTabs = Object.keys(tabs).map((key) => ({
    key,
    path: key === 'buyback_overview' ? `/SGX/${url}/${key}` : `/${market}/${url}/${key}`,
    label: tabs[key].label,
    icon: tabs[key].icon,
    class: tabs[key].class,
  }));

  const parentRoutes = Object.keys(tabs).map((key) => ({
    key,
    path: `/:market/${url}/${key}`,
    label: tabs[key].label,
    icon: tabs[key].icon,
    class: tabs[key].class,
  }));

  const getUpdatedMeta = (key) => {
    if (!tabs[key].pageName && !tabs[key].metaDesc) {
      return null;
    }

    return (
      <Helmet>
        {tabs[key].pageName && <title>{tabs[key].pageName}</title>}
        {tabs[key].metaDesc && <meta name="og:title" content={tabs[key].pageName} />}
        {tabs[key].metaDesc && <meta name="description" content={tabs[key].metaDesc} />}
        {tabs[key].metaDesc && <meta name="og:description" content={tabs[key].metaDesc} />}
      </Helmet>
    );
  };

  const getComponent = (key) => {
    let content;
    switch (key) {
      case 'buyback_overview':
        content = <BuybackOverview market={market} availableMarkets={availableMarkets} />;
        break;
      case 'buyback_latest':
        content = (
          <BuybackLatest
            market={market}
            availableMarkets={availableMarkets}
            buybackLatestMinStartDate={buybackLatestMinStartDate}
          />
        );
        break;
      default:
        content = <WIP />;
        break;
    }

    if (content) {
      return (
        <>
          {getUpdatedMeta(key)}
          {content}
        </>
      );
    }

    return content;
  };

  const routes = [
    {
      path: `/:market/${url}`,
      element: (
        <RouterNavComponent
          classes="g-mb-30 d-flex flex-row justify-content-start"
          navLinks={parentTabs}
          defaultActiveNav={selectedTab}
        />
      ),
      children: parentRoutes.map((value) => ({
        ...value,
        element: getComponent(value.key),
      })),
    },
  ];

  routes[0].children.unshift({
    ...routes[0].children[0],
    index: true,
    path: `/:market/${url}`,
  });

  return (
    <Provider store={store}>
      <div className="row align-items-center g-lg-mb-30 g-mb-20">
        <div className="col-lg-8">
          <h3 className="g-mb-5 g-lg-mb-0">Company Buyback</h3>
        </div>
        <div className="col-lg-4 d-flex justify-content-lg-end justify-content-start" />
      </div>
      <div className="divider-h w-100 g-mb-20 g-lg-mb-30" />
      <Router routes={routes} activeNav={[tab]} />
    </Provider>
  );
}

CompanyBuyback.propTypes = {
  market: PropTypes.string,
  url: PropTypes.string,
  tab: PropTypes.string,
  tabs: PropTypes.object,
  availableMarkets: PropTypes.oneOfType([PropTypes.array]),
  buybackLatestMinStartDate: PropTypes.string,
};

export default CompanyBuyback;
