import axios from 'axios';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

import api from '../../../utils/api';
import { useDetectMobileScreen } from '../../../utils/utils';
import classes from './editCustomLayoutDropdown.module.css';
import EditCustomLayoutDropdown from './EditCustomLayoutDropdown';
import EditCustomLayoutModal from './EditCustomLayoutModal';

const { CancelToken } = axios;

const US_MARKETS = ['nasdaq', 'nyse', 'nyse_mkt'];

const FIELD_CATEGORIES_MARKET_PRICES = [
  { name: 'Basic', fieldIdRanges: [{ min: 1, max: 29 }] },
  { name: 'Fundamental', fieldIdRanges: [{ min: 60, max: 75 }] },
  { name: 'Warrant', fieldIdRanges: [{ min: 76, max: 100 }] },
  { name: 'Total Returns', fieldIdRanges: [{ min: 120, max: 125 }] },
  { name: 'Historical', fieldIdRanges: [{ min: 151, max: 200 }] },
  { name: 'Ratios', fieldIdRanges: [{ min: 201, max: 250 }] },
  { name: 'Trailing PE', fieldIdRanges: [{ min: 251, max: 256 }] },
  { name: 'IPO Details', fieldIdRanges: [{ min: 300, max: 309 }] },
  { name: 'Beta/CAGR', fieldIdRanges: [{ min: 401, max: 410 }], remove_if_us: true },
  { name: 'CAGR', fieldIdRanges: [{ min: 403, max: 410 }], remove_if_not_us: true },
  { name: 'High/Low', fieldIdRanges: [{ min: 501, max: 560 }] },
];

const FIELD_CATEGORIES_PORTFOLIO = [
  { name: 'Basic', fieldIdRanges: [{ min: 1, max: 29 }] },
  {
    name: 'Portfolio',
    fieldIdRanges: [
      { min: 30, max: 59 },
      { min: 561, max: 570 },
    ],
  },
  { name: 'Fundamental', fieldIdRanges: [{ min: 60, max: 75 }] },
  { name: 'Warrant', fieldIdRanges: [{ min: 76, max: 100 }] },
  { name: 'Total Returns', fieldIdRanges: [{ min: 120, max: 125 }] },
  { name: 'Historical', fieldIdRanges: [{ min: 151, max: 200 }] },
  { name: 'Ratios', fieldIdRanges: [{ min: 201, max: 250 }] },
  { name: 'Trailing PE', fieldIdRanges: [{ min: 251, max: 256 }] },
  { name: 'IPO Details', fieldIdRanges: [{ min: 300, max: 309 }] },
  { name: 'Beta/CAGR', fieldIdRanges: [{ min: 401, max: 410 }] },
  { name: 'High/Low', fieldIdRanges: [{ min: 501, max: 560 }] },
  { name: 'Others', fieldIdRanges: [{ min: 701, max: 710 }] },
];

const WATCHLIST_CHART_FID_RANGE = { min: 601, max: 610 };
const FIELD_CATEGORIES_WATCHLIST = [
  { name: 'Basic', fieldIdRanges: [{ min: 1, max: 29 }] },
  { name: 'Chart', fieldIdRanges: [WATCHLIST_CHART_FID_RANGE] },
  { name: 'Fundamental', fieldIdRanges: [{ min: 60, max: 75 }] },
  { name: 'Warrant', fieldIdRanges: [{ min: 76, max: 100 }] },
  { name: 'Total Returns', fieldIdRanges: [{ min: 120, max: 125 }] },
  { name: 'Historical', fieldIdRanges: [{ min: 151, max: 200 }] },
  { name: 'Ratios', fieldIdRanges: [{ min: 201, max: 250 }] },
  { name: 'Trailing PE', fieldIdRanges: [{ min: 251, max: 256 }] },
  { name: 'IPO Details', fieldIdRanges: [{ min: 300, max: 309 }] },
  { name: 'Beta/CAGR', fieldIdRanges: [{ min: 401, max: 410 }] },
  { name: 'High/Low', fieldIdRanges: [{ min: 501, max: 560 }] },
];

const MAX_WIDTH_FOR_MODAL = 1199;

export const MAX_CUSTOM_LAYOUT_FIELDS = 20;

export default function EditCustomLayout({
  allFields,
  currentCustomFields,
  market,
  folderID,
  isPortfolio,
  handleUpdatedCustomLayout,
}) {
  if (!(allFields && Object.keys(allFields).length > 0)) {
    return null;
  }

  const source = CancelToken.source();
  const [selectedFields, setSelectedFields] = useState([]);
  const [activeCategory, setActiveCategory] = useState('Basic');
  const [activeCategoryFields, setActiveCategoryFields] = useState([]);

  let fieldCategories = isPortfolio
    ? FIELD_CATEGORIES_PORTFOLIO
    : folderID
    ? FIELD_CATEGORIES_WATCHLIST
    : FIELD_CATEGORIES_MARKET_PRICES;
  if (market) {
    fieldCategories = fieldCategories.filter((cat) => {
      if (cat.remove_if_not_us && !US_MARKETS.includes(market)) {
        return false;
      }
      if (cat.remove_if_us && US_MARKETS.includes(market)) {
        return false;
      }
      return true;
    });
  }

  useEffect(() => {
    if (currentCustomFields != selectedFields) {
      setSelectedFields(currentCustomFields ? [...currentCustomFields] : []);
    }
  }, [currentCustomFields]);

  useEffect(() => {
    const categoryFieldIdRanges =
      fieldCategories.filter((x) => x.name === activeCategory)[0]?.fieldIdRanges || [];
    const selectableFieldIDs = Object.keys(allFields).filter((k) => {
      for (let i = 0; i < categoryFieldIdRanges.length; i += 1) {
        if (k >= categoryFieldIdRanges[i].min && k <= categoryFieldIdRanges[i].max) {
          return true;
        }
      }
      return false;
    });
    setActiveCategoryFields(selectableFieldIDs);
  }, [activeCategory, isPortfolio]);

  const onCategoryClick = (category) => {
    if (category && category !== activeCategory) {
      setActiveCategory(category);
    }
  };

  const handleFieldSelectionChange = (fieldID, isChecked) => {
    const iFieldID = Number(fieldID);
    if (isChecked) {
      /* checked */
      if (selectedFields.length < MAX_CUSTOM_LAYOUT_FIELDS) {
        const updatedSelectedFields = [...selectedFields];
        if (iFieldID < WATCHLIST_CHART_FID_RANGE.min || iFieldID > WATCHLIST_CHART_FID_RANGE.max) {
          if (!updatedSelectedFields.includes(iFieldID)) {
            // update selectedFields
            updatedSelectedFields.push(iFieldID);
            setSelectedFields(updatedSelectedFields);
          }
          return;
        }
        const chartFieldIdx = updatedSelectedFields.findIndex(
          (fid) => fid >= WATCHLIST_CHART_FID_RANGE.min && fid <= WATCHLIST_CHART_FID_RANGE.max,
        );
        // update selectedFields
        if (chartFieldIdx > -1) {
          updatedSelectedFields[chartFieldIdx] = iFieldID;
        } else {
          updatedSelectedFields.push(iFieldID);
        }
        setSelectedFields(updatedSelectedFields);
      }
    } else {
      /* unchecked */
      if (selectedFields.includes(iFieldID)) {
        // update selectedFields
        const updatedSelectedFields = selectedFields.filter((x) => x !== iFieldID);
        setSelectedFields(updatedSelectedFields);
      }
    }
  };

  const onListItemClick = (e) => {
    e.stopPropagation();
    const fieldID = e.target.id.substring('edit_custom_layout_option_field_li_'.length);
    const fieldCheckbox = document.getElementById(
      `edit_custom_layout_option_field_checkbox_${fieldID}`,
    );
    if (fieldCheckbox) {
      // toggle previous checked state for new state
      handleFieldSelectionChange(fieldID, !fieldCheckbox.checked);
      return;
    }
    const fieldRadioButton = document.getElementById(
      `edit_custom_layout_option_field_radio_${fieldID}`,
    );
    if (fieldRadioButton && !fieldRadioButton.checked) {
      // toggle previous checked=false to new checked=true
      handleFieldSelectionChange(fieldID, true);
      return;
    }
  };

  const onFieldCheckboxClick = (e) => {
    e.stopPropagation();
    handleFieldSelectionChange(e.target.value, e.target.checked);
  };

  const onFieldRadioButtonClick = (e) => {
    e.stopPropagation();
    if (!e.target.checked) {
      handleFieldSelectionChange(e.target.value, true);
    }
  };

  const onRemoveFromSelection = (e, fieldID) => {
    e.stopPropagation();
    handleFieldSelectionChange(fieldID, false);
  };

  const onDragEnd = ({ destination, source }) => {
    if (!destination || destination.index === source.index) {
      return;
    }

    const startIndex = source.index;
    const endIndex =
      destination.index < selectedFields.length ? destination.index : selectedFields.length - 1;
    // reorder selectedFields
    const result = Array.from(selectedFields);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    setSelectedFields(result);
  };

  const onClearSelections = () => {
    setSelectedFields([]);
  };

  const onCancelSelections = () => {
    // reset selectedFields to currentCustomLayoutFields
    setSelectedFields(currentCustomFields?.length > 0 ? [...currentCustomFields] : []);
  };

  const onSaveSelections = () => {
    if (JSON.stringify(currentCustomFields) === JSON.stringify(selectedFields)) {
      // no actual changes from the initial currentCustomFields
      return;
    }

    const cancelToken = source.token;
    const params = { selected_fields: selectedFields };
    let url = '';
    if (folderID) {
      params['folder_id'] = folderID;
      url = `${isPortfolio ? 'portfolio' : 'watchlist'}/${folderID}/save_custom_layout.json`;
    } else {
      url = `prices/save_custom_layout.json`;
    }

    api
      .post(url, params, { cancelToken })
      .then(({ data }) => {
        if (data.success) {
          if (handleUpdatedCustomLayout) {
            handleUpdatedCustomLayout();
          } else {
            window.location.reload();
          }
        }
      })
      .catch((err) => {
        console.log(`Failed to update custom layout with error=[${err}] for params=${params}`);
      });
  };

  const renderFieldsForSelection = (currentActiveCategory, currentFieldsSelected) => {
    if (currentActiveCategory !== activeCategory) {
      return [];
    }

    if (currentActiveCategory !== 'Chart') {
      const listItems = activeCategoryFields.map((fid) => {
        const iFieldID = Number(fid);
        const cellKey = `edit_custom_layout_option_field_${iFieldID}`;
        return (
          <li
            key={cellKey}
            id={`edit_custom_layout_option_field_li_${iFieldID}`}
            className={classes.selectableFieldCell}
            role="button"
            onClick={onListItemClick}>
            <Form.Check
              className="d-inline-block g-ml-10 g-mr-10"
              type="checkbox"
              id={`edit_custom_layout_option_field_checkbox_${iFieldID}`}
              value={iFieldID}
              checked={currentFieldsSelected.includes(iFieldID)}
              onClick={onFieldCheckboxClick}
            />
            {allFields[iFieldID].fieldDesc}
          </li>
        );
      });
      return listItems;
    }

    const listItems = activeCategoryFields.map((fid) => {
      const iFieldID = Number(fid);
      const cellKey = `edit_custom_layout_option_field_${iFieldID}`;
      return (
        <li
          key={cellKey}
          id={`edit_custom_layout_option_field_li_${iFieldID}`}
          className={classes.selectableFieldCell}
          role="button"
          onClick={onListItemClick}>
          <Form.Check
            className="d-inline-block g-ml-10 g-mr-10"
            type="radio"
            name="historical_charts"
            id={`edit_custom_layout_option_field_radio_${iFieldID}`}
            value={iFieldID}
            checked={currentFieldsSelected.includes(iFieldID)}
            onClick={onFieldRadioButtonClick}
          />
          {allFields[iFieldID].fieldDesc}
        </li>
      );
    });
    return listItems;
  };

  const cbEvenHandlers = {
    onCategoryClick,
    onRemoveFromSelection,
    onDragEnd,
    onClearSelections,
    onCancelSelections,
    onSaveSelections,
  };

  const shouldRenderModal = useDetectMobileScreen(MAX_WIDTH_FOR_MODAL);
  return shouldRenderModal ? (
    <EditCustomLayoutModal
      allFields={allFields}
      currentSelectedFields={selectedFields}
      fieldCategories={fieldCategories}
      currentActiveCategory={activeCategory}
      cbEvenHandlers={cbEvenHandlers}
      fnGetFieldSelectionsList={renderFieldsForSelection}
      isPortfolio={isPortfolio}
    />
  ) : (
    <EditCustomLayoutDropdown
      allFields={allFields}
      currentSelectedFields={selectedFields}
      fieldCategories={fieldCategories}
      currentActiveCategory={activeCategory}
      cbEvenHandlers={cbEvenHandlers}
      fnGetFieldSelectionsList={renderFieldsForSelection}
      isPortfolio={isPortfolio}
    />
  );
}

export function SelectedFieldsDnD({ currentFieldsSelected, allFields, onDragEnd, onItemRemove }) {
  const emptyCells = [];
  for (let i = currentFieldsSelected.length; i < MAX_CUSTOM_LAYOUT_FIELDS; i += 1) {
    const itemID = `edit_custom_layout_empty_field_slot_${i + 1}`;
    emptyCells.push(
      <div key={itemID} index={i}>
        <div className={`row ${classes.selectedFieldsEmptyCell}`} />
      </div>,
    );
  }

  const getRenderItem = (selectedFields) => (provided, snapshot, rubric) =>
    (
      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
        <div className={`row justify-content-between ${classes.selectedFieldsOccupiedCell}`}>
          <div className="col-auto d-inline-flex g-per-width-90 g-xl-per-width-85">
            <div className="g-mr-10">
              <i className="fa-regular fa-grip-lines" />
            </div>
            <div>{allFields[selectedFields[rubric.source.index]]?.fieldDesc}</div>
          </div>
          <div className="col-auto g-per-width-10 g-xl-per-width-15">
            <div
              role="button"
              onClick={(e) => {
                onItemRemove && onItemRemove(e, selectedFields[rubric.source.index]);
              }}>
              <i className="fa-regular fa-xmark" />
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable
        droppableId="selected_fields_droppable"
        renderClone={getRenderItem(currentFieldsSelected)}>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {/* render cells for selected fields */}
            {currentFieldsSelected.map((iFieldID, index) => {
              const itemID = `edit_custom_layout_selected_field_id_${iFieldID}`;
              return (
                <Draggable key={itemID} index={index} draggableId={itemID}>
                  {getRenderItem(currentFieldsSelected)}
                </Draggable>
              );
            })}
            {/* render empty cells if any */}
            {emptyCells}
            {/* droppable placeholder */}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

EditCustomLayout.propTypes = {
  allFields: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  currentCustomFields: PropTypes.arrayOf(PropTypes.number),
  market: PropTypes.string,
  folderID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isPortfolio: PropTypes.bool,
  handleUpdatedCustomLayout: PropTypes.func,
};
