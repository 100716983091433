import React, { useEffect, useState } from 'react';
import { Provider, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import store from '../../utils/store';
import SignUpModal from '../SignUp/SignUpModal';
import { membershipSetUserID } from '../SignUp/signUpSlice';

function AccountVerification({ country, userid }) {
  return (
    <Provider store={store}>
      <AccountVerificationMain country={country} userid={userid} />
    </Provider>
  );
}

export default AccountVerification;

AccountVerification.propTypes = {
  country: PropTypes.string,
  userid: PropTypes.string,
};

function AccountVerificationMain({ country, userid }) {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(true);

  useEffect(() => {
    dispatch(membershipSetUserID(userid));
  }, []);

  return (
    <SignUpModal
      country={country}
      showModal={showModal}
      setShowModal={setShowModal}
      successSubtitle="Your account is now verified."
    />
  );
}

AccountVerificationMain.propTypes = {
  country: PropTypes.string,
  userid: PropTypes.string,
};
