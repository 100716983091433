import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from 'react-bootstrap';
import { membershipChoosePeriod } from '../membershipSlice';

function BillPeriodSelect() {
  const dispatch = useDispatch();
  const period = useSelector((state) => state.membership.period);

  const handlePeriodChange = (newPeriod) => {
    dispatch(membershipChoosePeriod(newPeriod));
  };

  return (
    <div className="d-flex text-center">
      <Card
        className={`d-inline-block my-2 p-2 shadow-sm g-per-width-48 ${
          period === 6 ? 'border-success border-3' : ''
        }`}
        onClick={() => {
          handlePeriodChange(6);
        }}
        type="button">
        <Card.Body className="text-center">
          <span className="d-inline-block g-mr-10">
            <h4 className="my-3">Half yearly</h4>
          </span>
        </Card.Body>
      </Card>

      <Card
        className={`d-inline-block my-2 p-2 shadow-sm g-per-width-48 ms-2 ${
          period === 12 ? 'border-success border-3' : ''
        }`}
        onClick={() => {
          handlePeriodChange(12);
        }}
        type="button">
        <Card.Body className="text-center">
          <span className="d-inline-block g-mr-10">
            <h4 className="my-3">Annually</h4>
          </span>
        </Card.Body>
      </Card>
    </div>
  );
}

export default BillPeriodSelect;
