import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams, useLocation } from 'react-router-dom';
import { fetchData } from './intrinsicValueAnalysisSlice';
import Loader from '../../../components/Loader';
import APIErrorHandler from '../../../components/APIErrorHandler';
import SearchStockFilter from './SearchStockFilter';
import IntrinsicAnalysisCalculator from './IntrinsicAnalysisCalculator';
import ValuationChart from './ValuationChart';
import IntrinsicValue from './IntrinsicValue';
import MarginOfSafety from './MarginOfSafety';

import { createLoadableComponent } from '../../../utils/Loadable';

const PriceChart = createLoadableComponent(() => import('../../../components/PriceChart'));
const QuotePriceSummary = createLoadableComponent(() =>
  import('../../../components/QuotePriceSummary'),
);
const { CancelToken } = axios;

export default function ValuationTab({ defaultCounter, isPayingUser }) {
  const source = CancelToken.source();
  const dispatch = useDispatch();
  const [isSSR, setIsSSR] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const data = useSelector((state) => state.intrinsicValueAnalysis.data);
  const priceSummary = useSelector((state) => state.intrinsicValueAnalysis.priceSummary);
  const isDuringStreamingHours = useSelector(
    (state) => state.intrinsicValueAnalysis.isDuringStreamingHours,
  );
  const hasIntradayData = useSelector((state) => state.intrinsicValueAnalysis.hasIntradayData);
  const intrinsicValue = useSelector((state) => state.intrinsicValueAnalysis.intrinsicValue);
  const marginOfSafety = useSelector((state) => state.intrinsicValueAnalysis.marginOfSafety);
  const marginOfSafetyStatement = useSelector(
    (state) => state.intrinsicValueAnalysis.marginOfSafetyStatement,
  );
  const error = useSelector((state) => state.intrinsicValueAnalysis.err);
  const loading = useSelector((state) => state.intrinsicValueAnalysis.loading);
  const [counter, setCounter] = useState(searchParams.get('counter') || defaultCounter);
  const chartDataURL = '/api/v1/charts/factsheet_overview_chart_data';

  const chartOptions = {
    showZoomOptions: true,
    showChartTypeOptions: true,
    showNewsMarkersOptions: true,
    showPeriodicityOptions: true,
    showTimeIntervalOptions: true,
    hasIntradayData,
    showRangeSlider: true,
    chartClasses: 'g-height-350 g-lg-height-400 g-mb-30 g-lg-mb-60',
  };

  const handleCounterChange = (counterData) => {
    const newCounter = counterData.symbol;

    if (!newCounter || counter === newCounter) {
      return;
    }
    setSearchParams({ counter: newCounter });
    setCounter(newCounter);
  };

  useEffect(() => {
    setIsSSR(typeof document === 'undefined');
    dispatch(
      fetchData({
        counter,
        cancelToken: source.token,
        type: 'valuation',
      }),
    );
    return () => {};
  }, [counter]);

  useEffect(() => {
    if (searchParams.get('counter') && counter !== searchParams.get('counter')) {
      setCounter(searchParams.get('counter'));
    }
  }, [location]);

  if (isSSR || loading) {
    return <Loader visible={isSSR || loading} className="g-height-800" />;
  }

  if (error) {
    return <APIErrorHandler error={error} />;
  }

  return (
    <>
      <SearchStockFilter counter={counter} handleCounterChange={handleCounterChange} />
      {data && data.success && !loading && (
        <>
          {Object.keys(priceSummary).length > 0 && (
            <div className="g-mt-20">
              <QuotePriceSummary
                counter={data.counter}
                priceSummary={priceSummary}
                isDuringStreamingHours={isDuringStreamingHours}
              />
            </div>
          )}
          <PriceChart
            counter={data.counter}
            dataURL={chartDataURL}
            options={chartOptions}
            isPayingUser={isPayingUser}
          />
          <div className="g-mt-50">
            <div className="row">
              <div className="col-lg-4">
                <ValuationChart
                  lastDone={data.last_done}
                  intrinsicValue={intrinsicValue}
                  classes="g-max-height-550 g-min-height-450"
                />
              </div>
              <div className="col-lg-8">
                <div className="row gx-0 gy-3 g-mb-30">
                  <div className="col-lg-6 g-lg-pr-21">
                    <IntrinsicValue
                      data={intrinsicValue}
                      type="valuation"
                      currency={data.currency}
                    />
                  </div>
                  <div className="col-lg-6">
                    <MarginOfSafety
                      data={marginOfSafety}
                      statement={marginOfSafetyStatement}
                      type="valuation"
                    />
                  </div>
                </div>
                <IntrinsicAnalysisCalculator tab="valuation" showModelWithin />
              </div>
            </div>
          </div>
        </>
      )}
      {!data ||
        (!data.success && <div className="g-mt-25 text-center text-danger">Data not found.</div>)}
    </>
  );
}

ValuationTab.propTypes = {
  defaultCounter: PropTypes.string,
  isPayingUser: PropTypes.bool,
};
