import { DataSubscription, BaseTable, pushPage } from './protocol';
import { resetModeToStreaming } from ".";

let alertSettingTable = null
let alertHistoryTable = null
let counterQuoteTable = null
let pendingSettings = []
let pendingHistories = []

export function startAlertsStreaming(alertSettingTableUpdate, alertHistoryTableUpdate) {
  const user_id = pushPage.getData('user_id');

  //alert streaming only available for logged in user
  if (!user_id){
    return;
  }

  const query = user_id + "." + (new Date().getTime());
  resetModeToStreaming();
  alertSettingTable = new BaseTable('sic_alert_setting', new DataSubscription('AlertSetting', query, []));
  alertHistoryTable = new BaseTable('sic_alert_history', new DataSubscription('AlertHistory', query, []));
  
  // Workaround for issue with comet streamer not streaming alerts via iframe when a counter is not streaming 
  counterQuoteTable = new BaseTable('sic_sti_index', new DataSubscription("Counter", 'STI.SI', []));

  alertSettingTable.onDataUpdate = function (update_){
    let pendings = []

    if (pendingSettings.includes(update_.itemPos)) {
      pendingSettings = [...pendingSettings.filter((setting) => setting !== update_.itemPos.toString())]

      pendings = pendingHistories.filter(
        (history) => history.obj_id.toString() === update_.itemPos.toString(),
      );
    }

    alertSettingTableUpdate(update_, pendings);

    if (pendings.length > 0) {
      pendingHistories = [...pendingHistories.filter((history) => history.obj_id.toString() !== update_.itemPos.toString())]
    }
    
  }

  alertHistoryTable.onDataUpdate = function (update_){
    const isNotPending = alertHistoryTableUpdate(update_)

    if (!isNotPending) {
      pendingHistories = [...pendingHistories, update_];

      if (!pendingSettings.includes(`${update_.obj_id}`)) {
        pendingSettings = [...pendingSettings, `${update_.obj_id}`];
      }
    }
  }

  pushPage.scheduleTable(counterQuoteTable);
  pushPage.scheduleTable(alertSettingTable);
  pushPage.scheduleTable(alertHistoryTable);
  pushPage.createEngine(); 
}

export function stopAlertsStreaming() {
  if (alertSettingTable && pushPage) {
    pushPage.cancelTable(alertSettingTable);
  }
  if (alertHistoryTable && pushPage) {
    pushPage.cancelTable(alertHistoryTable);
  }
  if (counterQuoteTable && pushPage) {
    pushPage.cancelTable(counterQuoteTable);
  }
  alertSettingTable = null; 
  alertHistoryTable = null; 
  counterQuoteTable = null; 
}
