import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';

import store from '../../utils/store';
import classes from './home.module.css';
import IndexPriceChart from './IndexPriceChart';
import WorldIndicesPrices from '../../components/WorldIndicesPrices';
import IndicesCarousel from './IndicesCarousel';

async function stopStreaming() {
  const { forceStopStreaming } = await import('../../utils/Streaming');
  forceStopStreaming();
}

export default function Markets(props) {
  const { defaultIndices, country } = props;
  const [isSSR, setIsSSR] = useState(true);

  useEffect(() => {
    setIsSSR(typeof document === 'undefined');
    return () => {
      stopStreaming();
    };
  }, []);

  return (
    <Provider store={store}>
      <div className={classes.indicesPricesContainer}>
        <WorldIndicesPrices defaultIndices={defaultIndices}>
          <IndicesCarousel country={country} />
        </WorldIndicesPrices>
      </div>
      <div className="g-mt-35">{!isSSR && <IndexPriceChart />}</div>
    </Provider>
  );
}

Markets.propTypes = {
  defaultIndices: PropTypes.array,
  country: PropTypes.string,
};
