import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import MarketSelectDropdown from '../MarketSelectDropdown';
import SelectCounter from '../SelectCounter';
import SelectFolderWatchlistPortfolio from '../SelectFolderWatchlistPortfolio';

const SELECT_MARKET_OPTIONS = [
  'sgx',
  'bursa',
  'hkex',
  'set',
  'idx',
  'asx',
  'nyse',
  'nasdaq',
  'nyse_mkt',
  'world',
];

const TRIGGER_BY_FOLDERS_OPTIONS = [
  { name: 'Portfolio', val: 'portfolio' },
  { name: 'Watchlist', val: 'watchlist' },
  { name: 'Symbol', val: 'symbol' },
];
const TRIGGER_BY_KEYWORD_OPTIONS = [
  { name: 'Search in News Headlines', val: 'keyword_headline' },
  { name: 'Search in News Body', val: 'keyword_body' },
];
const NEWS_SOURCES = [
  { name: 'ShareInvestor Express', val: 'si_news' },
  { name: 'Trading Central', val: 'tc_news' },
  { name: 'SGXNet', val: 'sgx_news' },
  { name: 'Bursa Link', val: 'bs_news' },
  { name: 'Business Times/Straits Times', val: 'stbt_news' },
  // { name: 'Investor-One', val: 'i1_news' },
];

const ALERT_OPTIONS = [
  { name: 'Application', val: '1' },
  { name: 'Email', val: '4' },
  { name: 'SMS', val: '2' },
];
const REPEAT_OPTIONS = [
  { name: 'Once', val: '2' },
  { name: 'Daily', val: '4' },
  { name: 'Always Active', val: '8' },
  { name: 'Disable', val: '1' },
];

function NewsAlertForm({
  formData,
  handleCounterChange,
  handleChange,
  handleFolderChange,
  error,
  action,
  handleClickIncludeOptionalFields,
}) {
  const renderTriggerByFolderTypeSelect = (triggerFolderType) => {
    switch (triggerFolderType) {
      case 'symbol':
        return (
          <div className="d-flex gap-2 align-items-center g-mb-25">
            <MarketSelectDropdown market={formData?.market} validMarkets={SELECT_MARKET_OPTIONS} />
            <SelectCounter
              counter={formData?.counter || {}}
              market={[formData?.market]}
              handleOnChangeCounter={handleCounterChange}
            />
          </div>
        );
      case 'portfolio':
        return (
          <SelectFolderWatchlistPortfolio
            folderType="portfolio"
            activeFolder={formData?.folder}
            handleOnChangeFolder={handleFolderChange}
          />
        );
      case 'watchlist':
        return (
          <SelectFolderWatchlistPortfolio
            folderType="watchlist"
            activeFolder={formData?.folder}
            handleOnChangeFolder={handleFolderChange}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {formData?.stock_code_or_folder_name && (
        <div className="g-mb-25 g-border-b-1 g-pb-25">
          <h5 className="mb-0">{formData.stock_code_or_folder_name}</h5>
        </div>
      )}
      {/* TRIGGER CONDITION */}
      <div className="g-mb-25 g-border-b-1 g-pb-25">
        <h6>Trigger Condition</h6>
        {/* TRIGGER BY FOLDER */}
        {action !== 'edit' && (
          <div className="g-mb-15">
            <Form.Label
              className="d-flex gap-2 align-items-center "
              htmlFor="include_trigger_by_folder">
              <Form.Check
                id="include_trigger_by_folder"
                className="form_field form-check"
                type="checkbox"
                name="include_trigger_by_folder"
                onChange={(e) =>
                  handleClickIncludeOptionalFields(
                    formData?.include_trigger_by_folder,
                    'include_trigger_by_folder',
                  )
                }
                checked={formData?.include_trigger_by_folder}
              />
              Trigger By Portfolio/Watchlist/Symbol
            </Form.Label>
            {formData?.include_trigger_by_folder && (
              <>
                <div className="d-flex gap-3 g-mb-10">
                  {TRIGGER_BY_FOLDERS_OPTIONS.map((option) => (
                    <Form.Label className="d-flex gap-2 align-items-center mb-0" key={option.val}>
                      <Form.Check
                        className="form_field form-check"
                        type="radio"
                        name="trigger_folder_type"
                        value={option.val}
                        onChange={handleChange}
                        checked={formData?.trigger_folder_type === option.val}
                      />
                      {option.name}
                    </Form.Label>
                  ))}
                </div>

                <div>{renderTriggerByFolderTypeSelect(formData?.trigger_folder_type)}</div>
              </>
            )}
            {error?.folder && <p className="text-danger">Please select a folder.</p>}
            {error?.counter && <p className="text-danger">Please select a counter.</p>}
          </div>
        )}
        {/* TRIGGER BY KEYWORDS */}
        <div className="">
          <Form.Label
            className="d-flex gap-2 align-items-center "
            htmlFor="include_trigger_by_keyword">
            <Form.Check
              id="include_trigger_by_keyword"
              type="checkbox"
              className="form_field form-check"
              name="include_trigger_by_keyword"
              onChange={(e) =>
                handleClickIncludeOptionalFields(
                  formData?.include_trigger_by_keyword,
                  'include_trigger_by_keyword',
                )
              }
              checked={formData?.include_trigger_by_keyword}
            />
            Trigger By Keyword
          </Form.Label>
          {formData?.include_trigger_by_keyword && (
            <>
              <Form.Control
                className="form_field g-mb-25"
                name="trigger_by_keyword_val"
                required
                value={formData?.trigger_by_keyword_val}
                onChange={handleChange}
              />
              {error?.trigger_by_keyword_val && (
                <p className="text-danger">Please enter a keyword to search.</p>
              )}
              <div className="d-flex gap-3 g-mb-10">
                {TRIGGER_BY_KEYWORD_OPTIONS.map((option) => (
                  <Form.Label className="d-flex gap-2 align-items-center mb-0" key={option.val}>
                    <Form.Check
                      className="form_field form-check"
                      type="checkbox"
                      name="trigger_keyword_type"
                      value={option.val}
                      onChange={handleChange}
                      checked={formData?.trigger_keyword_type?.includes(option.val)}
                    />
                    {option.name}
                  </Form.Label>
                ))}
              </div>
              {error?.trigger_keyword_type && (
                <p className="text-danger">Please select at least one target to search.</p>
              )}
            </>
          )}
        </div>
        {error?.news_condition && (
          <p className="text-danger">Please select at least one condition.</p>
        )}
      </div>
      {/* NEWS SOURCE */}
      <div className="g-mb-25 g-border-b-1 g-pb-25">
        <h6>News Source</h6>
        <div className="d-flex gap-3 flex-wrap">
          {NEWS_SOURCES.map((option) => (
            <Form.Label className="d-flex gap-1 align-items-center mb-0" key={option.val}>
              <Form.Check
                className="form_field form-check"
                type="checkbox"
                name="news_sources"
                value={option.val}
                onChange={handleChange}
                checked={formData?.news_sources?.includes(option.val)}
              />
              {option.name}
            </Form.Label>
          ))}
        </div>
        {error?.news_sources && (
          <p className="text-danger">Please select at least one news source.</p>
        )}
      </div>

      {/* ALERT METHOD */}
      <div className="g-mb-25 g-border-b-1 g-pb-25">
        <h6>Delivery Option</h6>
        <div className="d-flex gap-3 flex-wrap">
          {ALERT_OPTIONS.map((option) => (
            <Form.Label className="d-flex gap-1 align-items-center mb-0" key={option.val}>
              <Form.Check
                className="form_field form-check"
                type="checkbox"
                name="alert_methods"
                value={option.val}
                onChange={handleChange}
                checked={formData?.alert_methods?.includes(option.val)}
              />
              {option.name}
            </Form.Label>
          ))}
        </div>
        {error?.alert_methods && (
          <p className="text-danger">Please select at least one delivery option.</p>
        )}
      </div>
      {/* REPEAT */}
      <div className="g-mb-25 g-border-b-1 g-pb-25">
        <h6>Repeat </h6>
        <div className="d-flex gap-3 flex-wrap">
          {REPEAT_OPTIONS.map((option) => (
            <Form.Label className="d-flex gap-1 align-items-center mb-0" key={option.val}>
              <Form.Check
                className="form_field form-check"
                type="radio"
                name="repeat"
                value={option.val}
                onChange={handleChange}
                checked={formData?.repeat === option.val}
              />
              {option.name}
            </Form.Label>
          ))}
        </div>
      </div>
      {/* NOTES */}
      <div className="g-mb-25">
        <h6>Notes </h6>
        <Form.Control
          className="form_field"
          name="notes"
          as="textarea"
          rows={3}
          value={formData?.notes || ''}
          onChange={handleChange}
        />
      </div>
    </>
  );
}

export default NewsAlertForm;
NewsAlertForm.propTypes = {
  handleCounterChange: PropTypes.func,
  handleChange: PropTypes.func,
  handleFolderChange: PropTypes.func,
  formData: PropTypes.oneOfType([PropTypes.object]),
  error: PropTypes.oneOfType([PropTypes.object]),
  action: PropTypes.string,
  handleClickIncludeOptionalFields: PropTypes.func,
};
