export const COMPARISON_TABLE_HEADER_STOCK = [
  { title: 'Company', header: true },
  { title: 'Symbol', field: 'symbol' },
  { title: 'Market', field: 'market' },
  { title: 'Currency', field: 'currency' },
  { title: 'Remarks', field: 'remarks' },
  { title: 'Last Done Price', field: 'last_done' },
  { title: 'Change', field: 'change' },
  { title: 'Change (%)', field: 'percent_change' },
  { title: 'Cumulative Volume', field: 'cumulative_vol', definition: 'volume' },
  { title: 'Day Range', field: 'day_range' },
  { title: '52 Weeks Range', field: 'weeks52_range' },
];

export const COMPARISON_TABLE_HEADER_WARRANT = [
  { title: 'Company', field: 'name', header: true },
  { title: 'Market', field: 'market' },
  { title: 'Currency', field: 'currency' },
  { title: 'Remarks', field: 'remarks' },
  { title: 'Last Done Price', field: 'last_done' },
  { title: 'Change', field: 'change' },
  { title: 'Change (%)', field: 'percent_change' },
  { title: 'Cumulative Volume', field: 'cumulative_vol', definition: 'volume' },
  { title: 'Day Range', field: 'day_range' },
  { title: '52 Weeks Range', field: 'weeks52_range' },
  { title: 'Key Statistics', header: true },
  { title: 'Exercise Price', field: 'exercise_price' },
  { title: 'Conversion Ratio', field: 'convert_ratio' },
  { title: 'Implied Gearing', field: 'gearing_implied' },
  { title: 'Simple Gearing', field: 'gearing_simple' },
  { title: 'Premium (%)', field: 'premium' },
  { title: 'Oustanding Warrants', field: 'outstanding' },
  { title: 'Expiry Date', field: 'expiry_date' },
  { title: 'Expiry Countdown', field: 'expiry_countdown' },
];

export const COMPARISON_TABLE_ROWS_DATA = [
  { title: 'Consensus Estimates', header: true },
  {
    title: 'Consensus Recommendation',
    field: 'ce_recommendation',
    classes: 'table-no-hover g-height-50 g-lg-height-250',
  },
  { title: 'Mean Target Price', field: 'ce_target_price_and_market' },
  { title: 'Consensus Rating', field: 'ce_rating_note' },
  { title: 'Number Of Ratings', field: 'ce_number_of_ratings' },
  { title: 'Upside (%)', field: 'ce_upside' },
  { title: 'Key Statistics', header: true },
  {
    title: 'Beta (75 Days)',
    field: 'beta_75d_vs_mkt_idx',
    sparkline: true,
    definition: 'beta_stock',
  },
  {
    title: 'Beta (500 Days)',
    field: 'beta_500d_vs_mkt_idx',
    sparkline: true,
    definition: 'beta_stock',
  },
  { title: 'R-Squared (75 Days)', field: 'r_square_beta_75d', sparkline: true },
  { title: 'R-Squared (500 Days)', field: 'r_square_beta_500d', sparkline: true },
  { title: 'Cash In Hand - Adjusted', field: 'cash_value', definition: 'cash_in_hand_stock' },
  {
    title: 'Price/Cash In Hand',
    field: 'price_cash_value',
    sparkline: true,
    definition: 'price/cash_in_hand_stock',
  },
  { title: 'Issued & Paid-up Shares', field: 'total_share', sparkline: true },
  { title: 'Market Cap (M)', field: 'market_cap', sparkline: true },
  { title: 'Under CPFIS', field: 'is_cpfis' },
  { title: 'Sector Classification', field: 'component_sector' },
  { title: 'Category Classification', field: 'component_category' },
  { title: 'Index Components', field: 'component_index' },
  {
    title: "Latest Full Year Financials ('000)",
    header: true,
    classes: 'page-break-before-always',
    full_width: true,
  },
  { title: 'Revenue', field: 'revenue', classes: 'table-no-hover', full_width: true },
  { title: '', header: true },
  { title: 'Period', field: 'period' },
  { title: 'Profit Before Tax (Include Exceptional Items)', field: 'profit_bef_tax' },
  { title: 'Profit Attributable To Shareholders', field: 'sholders_profit' },
  { title: 'Cash And Cash Equivalents At End', field: 'cash_equi_end' },
  { title: 'Free Cash Flow', field: 'free_cash_flow' },
  { title: 'Per Share Data', header: true },
  {
    title: 'Earnings Per Share (EPS) - Adjusted',
    field: 'eps_adjusted',
    sparkline: true,
    definition: 'eps_stock',
  },
  { title: 'Trailing EPS [$]', field: 'trailing_eps', definition: 'trailing_eps_stock' },
  {
    title: 'Net Asset Value (NAV) Per Share - Adjusted',
    field: 'nav_adjusted',
    sparkline: true,
    definition: 'nav_stock',
  },
  {
    title: 'Net Tangible Asset (NTA) Per Share - Adjusted',
    field: 'nta_adjusted',
    sparkline: true,
    definition: 'nta_stock',
  },
  { title: 'Dividend [$] ', field: 'net_dividend' },
  { title: 'Valuation Ratios', header: true },
  {
    title: 'Price Earnings Ratio (PER) - Adjusted',
    field: 'per_adjusted',
    sparkline: true,
    definition: 'price/earnings_stock',
  },
  { title: 'Trailing PER', definition: 'trailing_pe_stock' },
  {
    title: 'Price/NAV - Adjusted',
    field: 'price_nav_adjusted',
    sparkline: true,
    definition: 'price/nav_stock',
  },
  {
    title: 'Price/NTA - Adjusted',
    field: 'price_nta_adjusted',
    sparkline: true,
    definition: 'price/nta_stock',
  },
  {
    title: 'Price/Revenue - Adjusted',
    field: 'price_revenue_adjusted',
    sparkline: true,
    definition: 'price/revenue_stock',
  },
  {
    title: 'Price/Operating Cash Flow - Adjusted',
    field: 'price_ocf_adjusted',
    sparkline: true,
    definition: 'price/operating_cash_flow_stock',
  },
  {
    title: 'Price/Free Cash Flow - Adjusted',
    field: 'price_free_cash_flow_adjusted',
    sparkline: true,
    definition: 'price/free_cash_flow_stock',
  },
  {
    title: 'Dividend Yield - Adjusted',
    field: 'dividend_yield_adjusted',
    sparkline: true,
    definition: 'dividend_yield_stock',
  },
  {
    title: 'Dividend Payout Ratio - Historical',
    field: 'dividend_payout_historical',
    sparkline: true,
    definition: 'dividend_payout_stock',
  },
  {
    title: 'Dividend Payout Ratio incl Special Dividend - Historical',
    field: 'dividend_payout_special_div',
    sparkline: true,
    definition: 'dividend_payout_stock',
  },
  { title: 'Growth Ratios', header: true },
  {
    title: 'Revenue Growth',
    field: 'revenue_growth',
    sparkline: true,
    definition: 'revenue_growth_stock',
  },
  {
    title: 'Net Earnings Growth',
    field: 'net_earnings_growth',
    sparkline: true,
    definition: 'net_earnings_growth_stock',
  },
  {
    title: 'Price Earnings to Growth',
    field: 'peg',
    sparkline: true,
    definition: 'price_earnings_to_growth_stock',
  },
  { title: 'Profitability Ratios', header: true },
  {
    title: 'Gross Profit (Earnings) Margin',
    field: 'gross_profit_margin',
    sparkline: true,
    definition: 'gross_profit_margin_stock',
  },
  {
    title: 'Pretax Margin',
    field: 'pretax_margin',
    sparkline: true,
    definition: 'pretax_margin_stock',
  },
  {
    title: 'Net Profit (Earnings) Margin',
    field: 'net_earnings_margin',
    sparkline: true,
    definition: 'net_earnings_margin_stock',
  },
  { title: 'Qualitative Ratios', header: true },
  {
    title: 'Quality of Earnings',
    field: 'quality_of_earnings',
    sparkline: true,
    definition: 'quality_of_earnings_stock',
  },
  { title: 'Management Efficiency Ratios', header: true },
  { title: 'Return On Assets (ROA)', field: 'roa', sparkline: true, definition: 'roa_stock' },
  { title: 'Return On Equity (ROE)', field: 'roe', sparkline: true, definition: 'roe_stock' },
  {
    title: 'Cash Earnings Return On Equity',
    field: 'cash_return_on_equity',
    sparkline: true,
    definition: 'cash_earnings_roe_stock',
  },
  {
    title: 'Gross Profitability',
    field: 'gross_profits_to_total_assets',
    sparkline: true,
    definition: 'gross_profitability_stock',
  },
  {
    title: 'Return On Tangible Equity (ROTE)',
    field: 'rote',
    sparkline: true,
    definition: 'rote_stock',
  },
  { title: 'Asset Utilization Ratios', header: true },
  {
    title: 'Total Asset Turnover',
    field: 'asset_turnover',
    sparkline: true,
    definition: 'total_asset_turnover_stock',
  },
  { title: 'Leverage Ratios', header: true },
  {
    title: 'Debt To Net Profit',
    field: 'debt_net_profit',
    sparkline: true,
    definition: 'debt_to_net_profit_stock',
  },
  {
    title: 'Debt To Cash Flow',
    field: 'debt_cf',
    sparkline: true,
    definition: 'debt_to_cash_flow_stock',
  },
  {
    title: 'Interest Coverage',
    field: 'interest_coverage',
    sparkline: true,
    definition: 'interest_coverage_stock',
  },
  {
    title: 'Net Debt To Equity',
    field: 'net_debt_equity',
    sparkline: true,
    definition: 'net_debt_to_equity_stock',
  },
  {
    title: 'Total Debt To Equity',
    field: 'debt_equity',
    sparkline: true,
    definition: 'debt/equity_stock',
  },
  {
    title: 'Debt To Assets',
    field: 'debt_assets',
    sparkline: true,
    definition: 'debt_to_assets_stock',
  },
  { title: 'Liquidity Ratios', header: true },
  {
    title: 'Current Ratio',
    field: 'ratio_current',
    sparkline: true,
    definition: 'current_ratio_stock',
  },
  {
    title: 'Cash Ratio',
    field: 'ratio_cash_liabilities',
    sparkline: true,
    definition: 'cash_ratio_stock',
  },
];

export const MAX_STOCKS = 5;
