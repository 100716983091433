import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';
import dayjs from 'dayjs';
import { createAlert, fetchEditAlert, updateAlert } from './stockNewsAlertsSlice';
import CounterAlertForm from './CounterAlertForm';
import NewsAlertForm from './NewsAlertForm';
import Loader from '../Loader';

function defaultFormValue({ action, alertType, editAlertData }) {
  if (
    (action === 'edit' || action === 'duplicate') &&
    (editAlertData || Object.keys(editAlertData).length > 0)
  ) {
    const { success, ...newEditAlertData } = editAlertData;
    return newEditAlertData;
  }

  // Default value of 'create' alert form
  const initialFormValue = {
    market: 'sgx',
    alert_methods: ['1'],
    repeat: '2',
    counter: {},
  };

  return alertType === 'price'
    ? {
        ...initialFormValue,
        condition_1: '41',
        comparator_1: 'EQ',
        condition_val_1: '',
        condition_2: '41',
        comparator_2: 'EQ',
        condition_val_2: '',
        include_time_range_2: false,
        include_condition_2: false,
        time_start_1: '08:00',
        time_end_1: '18:00',
        time_start_2: '08:00',
        time_end_2: '18:00',
        hd_alert_type: 'counter',
      }
    : {
        ...initialFormValue,
        news_sources: ['si_news'],
        trigger_folder_type: 'portfolio',
        include_trigger_by_folder: false,
        include_trigger_by_keyword: false,
        hd_alert_type: 'news',
      };
}

function validateForm(form, values, alertType) {
  const error = {};
  if (form.checkValidity() === false) {
    error.general = true;
  }

  // CUSTOM VALIDATION
  // check counter not blank
  if (
    (alertType === 'price' ||
      (values?.include_trigger_by_folder && values?.trigger_folder_type === 'symbol')) &&
    (!values?.counter || Object.keys(values.counter).length === 0)
  ) {
    error.counter = true;
  }

  // alert_methods must contain at least one checked
  if (values?.alert_methods?.length === 0) {
    error.alert_methods = true;
  }

  // news_sources must contain at least one checked
  if (values?.news_sources?.length === 0) {
    error.news_sources = true;
  }

  // condition_val_1 must be positive
  if (values?.condition_val_1 === '' || values?.condition_val_1 <= 0) {
    error.condition_val_1 = true;
  }

  // condition_val_2 must be positive if include_condition_2 is true
  if (
    values?.include_condition_2 &&
    (values?.condition_val_2 === '' || values?.condition_val_2 <= 0)
  ) {
    error.condition_val_2 = true;
  }

  // Time Range 1 must always be filled
  if (values?.time_start_1 === '' || values?.time_end_1 === '') {
    error.time_start_1 = true;
  }

  // Time Range 2 must be filled if include_time_range_2 is true
  if (values?.include_time_range_2 && (!values.time_start_2 || !values.time_end_2)) {
    error.time_start_2 = true;
  }

  // At least one trigger condition in News must be checked
  if (
    alertType === 'news' &&
    !values?.include_trigger_by_folder &&
    !values?.include_trigger_by_keyword
  ) {
    error.news_condition = true;
  }

  // Search by keyword fields must be filled if include_trigger_by_folder selected
  if (values?.include_trigger_by_folder) {
    if (
      (values.trigger_folder_type === 'portfolio' || values.trigger_folder_type === 'watchlist') &&
      !values?.folder
    ) {
      error.folder = true;
    }
  }

  // Search by keyword fields must be filled if include_trigger_by_keyword selected
  if (values?.include_trigger_by_keyword) {
    if (!values?.trigger_by_keyword_val) {
      error.trigger_by_keyword_val = true;
    }
    if (!values?.trigger_keyword_type || values?.trigger_keyword_type?.length === 0) {
      error.trigger_keyword_type = true;
    }
  }

  return error;
}
function AlertForm({ action, alertType, alertID, handleClose }) {
  const dispatch = useDispatch();
  const source = axios.CancelToken.source();
  const defaultMarket = useSelector((state) => state.selectedMarket.selectedMarket);
  const editAlertData = useSelector((state) => state.stockNewsAlerts.editAlertData);
  const loading = useSelector((state) => state.stockNewsAlerts.editLoading);
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState({});
  const [values, setValues] = useState(() => {
    return defaultFormValue({ action, alertType, editAlertData });
  });
  const [isLoading, setIsloading] = useState(loading);

  const handleCounterChange = (counterData) => {
    const newCounter = counterData ? { name: counterData.label, code: counterData.value } : {};

    if (values?.counter?.code === newCounter?.code) {
      return;
    }
    setValues((prevValues) => {
      return { ...prevValues, counter: newCounter };
    });
    // clean error if any
    setError((prevValues) => {
      const err = { ...prevValues };
      delete err.counter;
      return err;
    });
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === 'checkbox') {
      setValues((prevValues) => {
        const prevCheckedValues = prevValues[name] || [];
        const updatedCheckedValues = checked
          ? [...prevCheckedValues, value]
          : prevCheckedValues.filter((val) => val !== value);

        return { ...prevValues, [name]: updatedCheckedValues };
      });
    } else {
      setValues((prevValues) => {
        return { ...prevValues, [name]: value };
      });
    }
    // clean error if any
    setError((prevValues) => {
      const err = { ...prevValues };
      delete err[name];
      // Remove errors when specific field changed
      if (name === 'trigger_folder_type' || name === 'include_trigger_by_folder') {
        delete err.folder;
        delete err.counter;
      }
      return err;
    });
  };

  const handleFolderChange = (_newFolder) => {
    const newFolder = _newFolder.currentTarget;
    if (newFolder) {
      const newFolderID = newFolder.getAttribute('value');
      const newFolderName = newFolder.textContent;

      const newFolderData = {
        id: newFolderID,
        name: newFolderName,
      };

      setValues((prevValues) => {
        return { ...prevValues, folder: newFolderData };
      });
      // clean error if any
      setError((prevValues) => {
        const err = { ...prevValues };
        delete err.folder;
        return err;
      });
    }
  };

  const handleClickIncludeOptionalFields = (boolValue, name) => {
    setValues((prevValues) => {
      return { ...prevValues, [name]: !boolValue };
    });

    // clean error if any
    setError((prevValues) => {
      const err = { ...prevValues };
      delete err[name];
      // Remove errors when specific field changed
      if (name === 'include_trigger_by_keyword' || name === 'include_trigger_by_folder') {
        delete err.news_condition;
      }
      return err;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    const formError = validateForm(form, values, alertType);
    if (Object.keys(formError).length > 0) {
      setError(formError);
      setValidated(true);
      return;
    }

    if (action === 'create' || action === 'duplicate') {
      dispatch(createAlert({ formData: values, cancelToken: source.token }));
    } else if (action === 'edit' && alertID) {
      dispatch(
        updateAlert({
          id: alertID,
          formData: values,
          cancelToken: source.token,
        }),
      );
    }
    handleClose();
  };

  // Fetch data for edit form
  useEffect(() => {
    if ((action === 'edit' || action === 'duplicate') && alertID) {
      dispatch(fetchEditAlert({ id: alertID }));
    }
  }, [alertID]);

  useEffect(() => {
    setIsloading(loading);
  }, [loading]);

  useEffect(() => {
    if (defaultMarket == null) {
      return;
    }

    if (defaultMarket === values?.market?.toLowerCase()) {
      return;
    }

    setValues((prevValues) => {
      return { ...prevValues, market: defaultMarket };
    });
  }, [defaultMarket]);

  // Update default form values when editAlertData has changed or form tab changed in create form
  useEffect(() => {
    if ((action === 'edit' || action === 'duplicate') && editAlertData) {
      setValues(defaultFormValue({ action, editAlertData }));
    } else {
      setValues(defaultFormValue({ action: 'create', alertType }));
    }
    setValidated(false);
    setError({});
  }, [editAlertData, alertType]);

  if (isLoading) {
    return <Loader visible={isLoading} classes="g-min-height-300 g-pt-100" />;
  }

  return (
    <Form id="form_alert" noValidate validated={validated} onSubmit={handleSubmit}>
      {alertType === 'news' || values?.alert_type === 'news' ? (
        <NewsAlertForm
          handleChange={handleChange}
          handleCounterChange={handleCounterChange}
          handleFolderChange={handleFolderChange}
          formData={values}
          action={action}
          error={error}
          handleClickIncludeOptionalFields={handleClickIncludeOptionalFields}
        />
      ) : (
        <CounterAlertForm
          handleChange={handleChange}
          handleCounterChange={handleCounterChange}
          // handleOnChangeExpirationDate={handleOnChangeExpirationDate}
          formData={values}
          action={action}
          error={error}
          handleClickIncludeOptionalFields={handleClickIncludeOptionalFields}
        />
      )}

      <Button type="submit">
        {action === 'create' || action === 'duplicate' ? 'Add Alert' : 'Save Edit'}
      </Button>
    </Form>
  );
}

export default AlertForm;
AlertForm.propTypes = {
  action: PropTypes.string,
  alertType: PropTypes.string,
  alertID: PropTypes.string,
  handleClose: PropTypes.func,
};
