import React from 'react';
import PropTypes from 'prop-types';
import { Form, Button } from 'react-bootstrap';
import MarketSelectDropdown from '../MarketSelectDropdown';
import SelectCounter from '../SelectCounter';
import TimeRangePicker from '../TimeRangePicker';
import PopUpNote from '../PopUpNote';

const SELECT_MARKET_OPTIONS = [
  'sgx',
  'bursa',
  'hkex',
  'set',
  'idx',
  'asx',
  'nyse',
  'nasdaq',
  'nyse_mkt',
  'world',
];
const TRIGGER_CONDITIONS = [
  { name: 'Price Done', val: '41' },
  { name: 'Volume Done', val: '81' },
  { name: 'Buy Price', val: '74' },
  { name: 'Buy Volume', val: '75' },
  { name: 'Sell Price', val: '76' },
  { name: 'Sell Volume', val: '77' },
  { name: 'Last Done Volume', val: '42' },
];
const COMPARATORS = [
  { name: 'equal to', val: 'EQ' },
  { name: '= or greater than', val: 'GTEQ' },
  { name: '= or less than', val: 'LTEQ' },
];
const ALERT_OPTIONS = [
  { name: 'Application', val: '1' },
  { name: 'Email', val: '4' },
  { name: 'SMS', val: '2' },
];
const REPEAT_OPTIONS = [
  { name: 'Once', val: '2' },
  { name: 'Daily', val: '4' },
  { name: 'Always Active', val: '8' },
  { name: 'Disable', val: '1' },
];

const CONDITION_NOTE = `
  <div>
    Volume on stocks listed on <strong>SGX</strong> are in thousands <strong>('000)</strong>.
    <br />
    Volume on stocks listed on <strong>Bursa</strong> are in hundreds <strong>('00)</strong>.
    <br />
    Volume on stocks listed on <strong>HKEx</strong> are in thousands <strong>('000)</strong>.
    <br />
    Volume on stocks listed on <strong>SET</strong> are in hundreds <strong>('00)</strong>.
  </div>
`;

const TIME_RANGE_NOTE = `
  <div>
    Trigger the alert only within the following time range. Default is to trigger between 08:00 to 18:00.
  </div>
`;

function CounterAlertForm({
  formData,
  handleCounterChange,
  handleChange,
  // handleOnChangeExpirationDate,
  error,
  action,
  handleClickIncludeOptionalFields,
}) {
  return (
    <>
      {/* COUNTER */}
      <div className="g-mb-25 g-border-b-1 g-pb-25">
        <div className="d-flex gap-2 align-items-center">
          <MarketSelectDropdown
            market={formData?.market}
            validMarkets={SELECT_MARKET_OPTIONS}
            disabled={action === 'edit'}
          />
          <SelectCounter
            disabled={action === 'edit'}
            counter={formData?.counter || {}}
            market={[formData?.market]}
            handleOnChangeCounter={handleCounterChange}
          />
        </div>
        {error?.counter && <p className="text-danger">Please select a counter.</p>}
      </div>
      {/* TRIGGER CONDITION */}
      <div className="g-mb-25 g-border-b-1 g-pb-25">
        <div className="d-flex g-mb-10">
          <strong>Trigger Condition</strong>
          <PopUpNote
            content={CONDITION_NOTE}
            placement="bottom"
            icon="g-ml-5 fa-solid fa-circle-info"
            classes="text-start text-muted g-max-width-300 g-sm-max-width-500 g-max-per-width-80"
          />
        </div>
        <div className="d-flex gap-3 g-mb-15">
          <Form.Select
            className="form_field"
            name="condition_1"
            required
            value={formData?.condition_1}
            onChange={handleChange}>
            {TRIGGER_CONDITIONS.map((condition) => (
              <option key={condition.val} value={condition.val}>
                {condition.name}
              </option>
            ))}
          </Form.Select>
          <Form.Select
            className="form_field"
            name="comparator_1"
            required
            value={formData?.comparator_1}
            onChange={handleChange}>
            {COMPARATORS.map((comparator) => (
              <option key={comparator.val} value={comparator.val}>
                {comparator.name}
              </option>
            ))}
          </Form.Select>
          <Form.Control
            className={`${error?.condition_val_1 ? 'is-invalid' : ''} form_field`}
            name="condition_val_1"
            required
            value={formData?.condition_val_1 || ''}
            onChange={handleChange}
          />
        </div>
        {error?.condition_val_1 && <p className="text-danger">Please enter a positive number.</p>}
        <div className="text-center">
          <Button
            onClick={(e) =>
              handleClickIncludeOptionalFields(formData?.include_condition_2, 'include_condition_2')
            }
            className="bg-transparent text-dark border-0">
            <i className="fa-light fa-regular fa-circle-plus fa-lg g-mr-10" />
            Or (Click to include another condition)
          </Button>
        </div>
        {formData?.include_condition_2 && (
          <>
            <div className="g-mt-15 d-flex gap-3">
              <Form.Select
                className="form_field"
                name="condition_2"
                required
                value={formData?.condition_2}
                onChange={handleChange}>
                {TRIGGER_CONDITIONS.map((condition) => (
                  <option key={condition.val} value={condition.val}>
                    {condition.name}
                  </option>
                ))}
              </Form.Select>
              <Form.Select
                className="form_field"
                name="comparator_2"
                required
                value={formData?.comparator_2}
                onChange={handleChange}>
                {COMPARATORS.map((comparator) => (
                  <option key={comparator.val} value={comparator.val}>
                    {comparator.name}
                  </option>
                ))}
              </Form.Select>
              <Form.Control
                className={`${error?.condition_val_2 ? 'is-invalid' : ''} form_field`}
                name="condition_val_2"
                required
                value={formData?.condition_val_2 || ''}
                onChange={handleChange}
              />
            </div>
            {error?.condition_val_2 && (
              <p className="text-danger">Please enter a positive number.</p>
            )}
          </>
        )}
      </div>
      {/* TRIGGER TIME RANGE */}
      <div className="g-mb-25 g-border-b-1 g-pb-25">
        <div className="d-flex g-mb-10">
          <strong>Trigger Time Range</strong>
          <PopUpNote
            content={TIME_RANGE_NOTE}
            placement="bottom"
            icon="g-ml-5 fa-solid fa-circle-info"
            classes="text-start text-muted g-max-width-300 g-sm-max-width-500 g-max-per-width-80"
          />
        </div>
        <div className="g-mb-15">
          <TimeRangePicker
            startName="time_start_1"
            endName="time_end_1"
            startValue={formData?.time_start_1 || ''}
            endValue={formData?.time_end_1 || ''}
            handleChange={handleChange}
            placeholderStart="Start Time"
            placeholderEnd="End Time"
          />
        </div>
        <div className="text-center">
          <Button
            onClick={(e) =>
              handleClickIncludeOptionalFields(
                formData?.include_time_range_2,
                'include_time_range_2',
              )
            }
            className="bg-transparent text-dark border-0">
            <i className="fa-light fa-regular fa-circle-plus fa-lg g-mr-10" />
            Or (Click to include another time range)
          </Button>
        </div>
        {formData?.include_time_range_2 && (
          <div className="g-mt-15">
            <TimeRangePicker
              startName="time_start_2"
              endName="time_end_2"
              startValue={formData?.time_start_2 || ''}
              endValue={formData?.time_end_2 || ''}
              handleChange={handleChange}
              placeholderStart="Start Time"
              placeholderEnd="End Time"
            />
          </div>
        )}
        {(error?.time_start_1 || error?.time_start_2) && (
          <p className="text-danger">Please enter a time range.</p>
        )}
      </div>
      {/* ALERT METHOD */}
      <div className="g-mb-25 g-border-b-1 g-pb-25">
        <h6>Delivery Option</h6>
        <div className="d-flex gap-3 flex-wrap">
          {ALERT_OPTIONS.map((option) => (
            <Form.Label className="d-flex gap-1 align-items-center mb-0" key={option.val}>
              <Form.Check
                className="form_field form-check"
                type="checkbox"
                name="alert_methods"
                value={option.val}
                onChange={handleChange}
                checked={formData?.alert_methods?.includes(option.val)}
              />
              {option.name}
            </Form.Label>
          ))}
        </div>
        {error?.alert_methods && (
          <p className="text-danger">Please select at least one delivery option.</p>
        )}
      </div>
      {/* TODO: EXPIRATION in V2 */}
      {/* <div className="g-mb-25 g-border-b-1 g-pb-25 ">
        <h6>Expiration</h6>
        <div className="d-flex gap-3">
          <DateFilter
            handleDateChange={handleOnChangeExpirationDate}
            date={formData?.expiration || ''}
            options={{
              static: true,
              enableTime: true,
              wrap: true,
              dateFormat: 'Y-m-d H:i',
            }}
          />
        </div>
      </div> */}
      {/* REPEAT */}
      <div className="g-mb-25 g-border-b-1 g-pb-25">
        <h6>Repeat </h6>
        <div className="d-flex gap-3 flex-wrap">
          {REPEAT_OPTIONS.map((option) => (
            <Form.Label className="d-flex gap-1 align-items-center mb-0" key={option.val}>
              <Form.Check
                className="form_field form-check"
                type="radio"
                name="repeat"
                value={option.val}
                onChange={handleChange}
                checked={formData?.repeat === option.val}
              />
              {option.name}
            </Form.Label>
          ))}
        </div>
      </div>
      {/* NOTES */}
      <div className="g-mb-25">
        <h6>Notes </h6>
        <Form.Control
          className="form_field"
          name="notes"
          as="textarea"
          rows={3}
          value={formData?.notes || ''}
          onChange={handleChange}
        />
      </div>
    </>
  );
}

export default CounterAlertForm;
CounterAlertForm.propTypes = {
  handleCounterChange: PropTypes.func,
  handleChange: PropTypes.func,
  // handleOnChangeExpirationDate: PropTypes.func,
  formData: PropTypes.oneOfType([PropTypes.object]),
  error: PropTypes.oneOfType([PropTypes.object]),
  action: PropTypes.string,
  handleClickIncludeOptionalFields: PropTypes.func,
};
