import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import Card from 'react-bootstrap/Card';

import api from '../../../utils/api';
import classes from './insights.module.css';
import { compareObjectsByNumberAttr, formatDecimal, getParamCounterId } from '../../../utils/utils';
import { createLoadableComponent } from '../../../utils/Loadable';
import APIErrorHandler from '../../../components/APIErrorHandler';

const AllocationPieChart = createLoadableComponent(() =>
  import('../../../components/AllocationPieChart'),
);
const { CancelToken } = axios;

function extractShareholdersData(shareholdersData) {
  if (!(shareholdersData?.data?.length > 0)) {
    return [];
  }
  return shareholdersData.data.map((shareholder) => {
    const strValue = `- Value: ${shareholder.shares_worth} ${shareholdersData.currency}`;
    const strShares = `- Shares: ${shareholder.num_shares}`;
    const breakdown = `${strValue}<br/>${strShares}\n`;
    return {
      name: shareholder.name,
      value: Number(
        formatDecimal(parseFloat(shareholder.shares_worth.replaceAll(',', '')) || 0, 3),
      ),
      percentage: Number(formatDecimal(shareholder.percent_shareholding, 2)),
      breakdown,
    };
  });
}

export default function Ownership() {
  const source = CancelToken.source();
  const selectedPortfolio = useSelector((state) => state.portfolio.selected);
  const [currentCounters, setCurrentCounters] = useState([]);
  const [selectOptions, setSelectOptions] = useState([]);
  const [activeCounter, setActiveCounter] = useState(null);
  const [allocationData, setAllocationData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (selectedPortfolio?.counters?.length > 0) {
      const counters = selectedPortfolio.counters
        .map((c) => {
          return { counter: c.counter, stockName: `${c.Name} (${c.counter})`, position: c.No };
        })
        .sort((a, b) => compareObjectsByNumberAttr(a, b, 'position'));
      if (JSON.stringify(counters) !== JSON.stringify(currentCounters)) {
        setCurrentCounters(counters);
        setActiveCounter(counters[0].counter);
      }
    } else {
      setCurrentCounters([]);
      setActiveCounter(null);
    }
  }, [selectedPortfolio]);

  useEffect(() => {
    if (currentCounters.length > 0) {
      setSelectOptions(
        currentCounters.map((c) => {
          return { value: c.counter, label: c.stockName };
        }),
      );
    } else {
      setSelectOptions([]);
    }
  }, [currentCounters]);

  useEffect(() => {
    if (activeCounter) {
      const cancelToken = source.token;
      setLoading(true);
      api
        .get(`quote/${getParamCounterId(activeCounter)}/shareholders.json?`, { cancelToken })
        .then(({ data }) => {
          setAllocationData(extractShareholdersData(data));
          setLoading(false);
        })
        .catch((err) => {
          setError(err);
          setLoading(false);
        });
    }
  }, [activeCounter]);

  const handleActiveCounterChange = (newCounter) => {
    if (newCounter && newCounter.value !== activeCounter) {
      setActiveCounter(newCounter.value);
    }
  };

  return (
    <Card className="position-static  h-100">
      <Card.Body className="g-min-height-555">
        <div className="g-mb-20 d-flex flex-column flex-xl-row justify-content-between">
          <Card.Title>Ownership</Card.Title>
          {selectOptions.length > 0 && (
            <Select
              unstyled
              isClearable
              backspaceRemovesValue
              className="react-select-container d-inline-block g-width-275 g-max-per-width-100"
              classNamePrefix="react-select"
              defaultValue={{
                value: activeCounter || selectOptions[0].value,
                label:
                  currentCounters.filter((c) => c.value === activeCounter)[0]?.stockName ||
                  selectOptions[0].label,
              }}
              onChange={handleActiveCounterChange}
              options={selectOptions}
            />
          )}
        </div>
        {loading && (
          <div className="g-text-size-18 g-lg-text-size-30 text-center">
            <i className="fa-duotone fa-spinner-third fa-spin" />
          </div>
        )}
        <APIErrorHandler error={error} />
        {!loading && (
          <div className="g-mt-15 g-mb-15 g-height-450">
            <AllocationPieChart data={allocationData} percentInnerRadius={55} percentHeight={95} />
          </div>
        )}
      </Card.Body>
    </Card>
  );
}
