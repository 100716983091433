import React from 'react';
import PropTypes from 'prop-types';

import IpoOpenTable from './IpoOpenTable';
import IpoOpenCard from './IpoOpenCard';

function IpoOpen({ data }) {
  return (
    <div className="g-mb-50">
      <h4>Open For Subscription - Companies</h4>
      <div className="row g-mt-30">
        {data.map((stock, i) => (
          <div className="col-md-4 col-sm-12" key={i}>
            <IpoOpenCard stock={stock} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default IpoOpen;

IpoOpen.propTypes = {
  data: PropTypes.array,
};
