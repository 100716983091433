import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Dropdown from 'react-bootstrap/Dropdown';
import Select from 'react-select';

import Loader from '../../../../components/Loader';
import ComparisonChart from './ComparisonChart';
import WaterfallChart from './WaterfallChart';
import GrowthChart from './GrowthChart';
import StackedLineChart from './StackedLineChart';
import PeriodFilters, { PERIOD_OPTIONS, RANGE_OPTIONS } from './PeriodFilters';

const CHART_TYPES = {
  comparison: 'Index Comparison Chart',
  growth: 'Gain/Loss Analysis',
  stacked: 'Allocation Group Analysis',
  waterfall: 'Portfolio Return Flowchart',
};
const GROUP_BY = {
  market: 'Market',
  currency: 'Currency',
  asset_allocation: 'Asset Allocation',
};

function PortfolioPerformanceChart({
  portfolioData,
  indexData,
  comparisonList,
  setSelectedComparisonIndex,
  loading,
  chartMarkers,
}) {
  const [showChartMarkers, setShowChartMarkers] = useState(false);
  const [period, setPeriod] = useState('d');
  const [range, setRange] = useState('all');
  const [chartType, setChartType] = useState('comparison');
  const [groupBy, setGroupBy] = useState('market');

  const toggleShowChartMarkers = () => {
    setShowChartMarkers(!showChartMarkers);
  };

  const handleChartTypeChange = (e) => {
    const newType = e.target.getAttribute('value');
    if (!newType || newType === chartType) {
      return;
    }

    if (newType !== 'comparison' && showChartMarkers) {
      toggleShowChartMarkers();
    }

    if ((newType === 'waterfall' || newType === 'growth') && period === 'd') {
      if (Object.keys(portfolioData.performance).length > 30) {
        setPeriod('w');
      }
    }

    setChartType(newType);
  };

  const handleGroupByChange = (e) => {
    const newGroupBy = e.target.getAttribute('value');
    if (!newGroupBy || newGroupBy === groupBy) {
      return;
    }
    setGroupBy(newGroupBy);
  };

  return (
    <Card className="g-mt-20">
      <Card.Body>
        <Card.Title>Portfolio Performance</Card.Title>

        <Loader visible={loading} classes="text-center m-5" />
        {!loading && (!portfolioData || !portfolioData.performance) && (
          <h6 className="text-center text-muted m-5">No data found.</h6>
        )}
        {!loading && portfolioData && portfolioData.performance && (
          <>
            <div className="row g-3">
              <div className="col-lg-auto  d-flex gap-3">
                <PeriodFilters
                  period={period}
                  setPeriod={setPeriod}
                  range={range}
                  setRange={setRange}
                  loading={loading}
                />
              </div>
              <div className="col-lg-auto">
                <Dropdown className="d-inline-block">
                  <Dropdown.Toggle variant="light" disabled={loading}>
                    {CHART_TYPES[chartType]}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {Object.keys(CHART_TYPES).map((key) => {
                      return (
                        <Dropdown.Item
                          key={key}
                          value={key}
                          onClick={handleChartTypeChange}
                          active={chartType === key}>
                          {CHART_TYPES[key]}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              {chartType === 'stacked' && (
                <div className="col-lg-auto">
                  <Dropdown className="d-inline-block">
                    <Dropdown.Toggle variant="light" disabled={loading}>
                      {GROUP_BY[groupBy]}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {Object.keys(GROUP_BY).map((key) => {
                        return (
                          <Dropdown.Item
                            key={key}
                            value={key}
                            onClick={handleGroupByChange}
                            active={groupBy === key}>
                            {GROUP_BY[key]}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}

              {indexData && chartType === 'comparison' && (
                <div className="col-12">
                  <div className="row g-3">
                    <div className="col-md">
                      <h6 className="d-inline-block g-mr-10">Portfolio Performance vs</h6>
                      <Select
                        unstyled
                        isClearable
                        backspaceRemovesValue
                        className="portfolioPerformanceComparison react-select-container d-inline-block g-min-width-130"
                        classNamePrefix="react-select"
                        defaultValue={{
                          value: indexData.counter,
                          label: indexData.name,
                        }}
                        onChange={setSelectedComparisonIndex}
                        options={comparisonList}
                      />
                    </div>
                    <div className="col-md text-md-end">
                      <input
                        checked={showChartMarkers}
                        onChange={toggleShowChartMarkers}
                        type="checkbox"
                        id="showChartMarkers"
                        className="align-middle"
                      />
                      <label className="g-ml-10 align-middle" htmlFor="showChartMarkers">
                        View Event
                      </label>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div>
              {chartType === 'waterfall' && (
                <WaterfallChart
                  portfolioData={portfolioData.performance}
                  chartMarkers={showChartMarkers ? chartMarkers : null}
                  selectedPeriodInterval={PERIOD_OPTIONS[period].interval}
                  selectedRange={RANGE_OPTIONS[range]}
                />
              )}
              {chartType === 'growth' && (
                <GrowthChart
                  portfolioData={portfolioData.performance}
                  chartMarkers={showChartMarkers ? chartMarkers : null}
                  selectedPeriodInterval={PERIOD_OPTIONS[period].interval}
                  selectedRange={RANGE_OPTIONS[range]}
                />
              )}
              {chartType === 'stacked' && (
                <StackedLineChart
                  portfolioData={portfolioData.performance}
                  chartMarkers={showChartMarkers ? chartMarkers : null}
                  selectedPeriodInterval={PERIOD_OPTIONS[period].interval}
                  selectedRange={RANGE_OPTIONS[range]}
                  groupBy={groupBy}
                />
              )}
              {chartType === 'comparison' && portfolioData && indexData && (
                <ComparisonChart
                  portfolioData={portfolioData}
                  indexData={indexData}
                  chartMarkers={showChartMarkers ? chartMarkers : null}
                  selectedPeriodInterval={PERIOD_OPTIONS[period].interval}
                  selectedRange={RANGE_OPTIONS[range].range}
                />
              )}
            </div>
          </>
        )}
      </Card.Body>
    </Card>
  );
}

PortfolioPerformanceChart.propTypes = {
  portfolioData: PropTypes.object,
  indexData: PropTypes.object,
  comparisonList: PropTypes.array,
  setSelectedComparisonIndex: PropTypes.func,
  loading: PropTypes.bool,
  chartMarkers: PropTypes.array,
};

export default PortfolioPerformanceChart;
