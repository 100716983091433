import React from 'react';
import PropTypes from 'prop-types';

import { isZero, isNegative, isPositive } from '../../utils/utils';
import styles from './searchQuote.module.css';

export default function QuotePrice({ activeCounterPrice }) {
  if (!activeCounterPrice || !activeCounterPrice.last_done_price) {
    return <div className="my-4 text-center">{activeCounterPrice.message}</div>;
  }

  const arrowStyle = {};
  if (Number(activeCounterPrice.change) < 0) {
    arrowStyle.transform = 'rotate(180deg)';
  } else if (Number(activeCounterPrice.change) > 0) {
    arrowStyle.transform = 'rotate(0deg)';
  } else {
    arrowStyle.display = 'none';
  }

  return (
    <div className="my-2">
      <div className="align-middle my-1">
        <span className="me-1 g-text-size-32">{activeCounterPrice.last_done_price}</span>
        <span
          className={`mx-1 g-text-size-16 ${
            isPositive(activeCounterPrice.change)
              ? 'text-success'
              : isNegative(activeCounterPrice.change)
              ? 'text-danger'
              : ''
          }`}>
          <span className="me-1" style={arrowStyle}>
            <i className={`fa-solid fa-triangle ${styles.quoteInfoPriceIcon}`} />
          </span>
          {`${activeCounterPrice.change} (${activeCounterPrice.percent_change}${
            !isZero(activeCounterPrice.percent_change) ? '%' : ''
          })`}
        </span>
        <span className="mx-1">
          Volume: <span className="g-text-size-16 fw-bold">{activeCounterPrice.volume}</span>
        </span>
        <span className="ms-1">
          Price Range:{' '}
          <span className="g-text-size-16 fw-bold">
            {activeCounterPrice.low} - {activeCounterPrice.high}
          </span>
        </span>
      </div>

      <small className={styles.grayText}>
        {activeCounterPrice.delay > 0 ? `Quotes ${activeCounterPrice.delay} Minutes Delayed. ` : ''}
        Updated at {activeCounterPrice.updated_at}
      </small>
    </div>
  );
}

QuotePrice.propTypes = {
  activeCounterPrice: PropTypes.object,
};
