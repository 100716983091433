import React, { useState, useRef } from 'react';
import { Button, Form, Alert, Col } from 'react-bootstrap';
import axios from 'axios';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import * as yup from 'yup';
import { Formik, Form as FormikForm } from 'formik';
import api from '../../utils/api';
import styles from './userlogin.module.css';

function ForgotPasswordComponent({
  site_key = '',
  setShowResetPasswordPage,
  perform_check = true,
}) {
  const [loading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [thankYou, showThankYou] = useState(false);
  const reCaptchaRef = useRef(null);

  const { CancelToken } = axios;

  const schema = yup.object().shape({
    email: yup.string().email('Invalid email address').required('Email is required'),
  });

  const FORGOT_PASSWORD_URL = '/user/forget_password';
  const SERVER_ERROR_FAILED_MESSAGE =
    'Failed to send request. Please contact us if you are unable to reset password.';
  const RECAPTCHA_FAILED_MESSAGE =
    'Failed to verify that you are a human. Please refresh page and try again.';

  const getRecaptchaToken = async () => {
    try {
      return await reCaptchaRef.current.executeAsync();
    } catch (error) {
      return '';
    }
  };

  const doForgotPassword = async (values) => {
    setIsLoading(true);

    /* Get grecaptcha */
    let captchaToken = '';
    if (perform_check) {
      captchaToken = await getRecaptchaToken();
      if (!captchaToken) {
        setErrorMessage(RECAPTCHA_FAILED_MESSAGE); // Recaptcha failed.
        return;
      }
    }

    api
      .post(
        FORGOT_PASSWORD_URL,
        {
          email: values.email,
          recaptcha_token: captchaToken,
        },
        {
          CancelToken,
        },
      )
      .then((response) => {
        if (response.data.success) {
          showThankYou(true);
        } else {
          setErrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        setErrorMessage(`${SERVER_ERROR_FAILED_MESSAGE} ${error.message}`);
      })
      .then(() => {
        setIsLoading(false);
        reCaptchaRef.current?.reset();
      });
  };

  return (
    <>
      {!thankYou ? (
        <Formik validationSchema={schema} initialValues={{ email: '' }} onSubmit={doForgotPassword}>
          {({ handleSubmit, handleChange, values, touched, errors }) => (
            <FormikForm noValidate onSubmit={handleSubmit}>
              <h4 className="text-center mb-3">Forgot Password?</h4>

              {errorMessage && (
                <Alert variant="danger" className="g-text-size-15">
                  <i className="fa fa-solid fa-triangle-exclamation fa-fw" /> <b>Error: </b>
                  {errorMessage}
                </Alert>
              )}

              <div className="text-center g-text-size-15">
                Please enter your email address and we will send you a link to reset your password.
              </div>

              <Form.Group as={Col} className="mt-4">
                <Form.Control
                  name="email"
                  type="email"
                  placeholder="Email"
                  value={values.email}
                  className={styles.signInInput}
                  onChange={handleChange}
                  isInvalid={touched.email && !!errors.email}
                />
                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
              </Form.Group>

              {perform_check && (
                <ReCAPTCHA
                  ref={reCaptchaRef}
                  className="d-inline-block"
                  theme="dark"
                  size="invisible"
                  sitekey={site_key}
                />
              )}

              <div className="text-center mt-3 pt-1">
                {loading ? (
                  <Button
                    variant="success"
                    type="button"
                    className="w-100 d-inline-flex justify-content-center align-items-center "
                    name="submit"
                    disabled
                    onClick={() => {}}>
                    <div className="spinner-border spinner-border-sm text-light me-2" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                    Processing...
                  </Button>
                ) : (
                  <Button
                    variant="success"
                    type="button"
                    className={`w-100 d-inline-flex justify-content-center align-items-center ${styles.signInButton}`}
                    name="submit"
                    onClick={handleSubmit}>
                    Submit
                  </Button>
                )}
                <Button
                  variant="link"
                  className={`${styles.backLink} mt-3 mb-1`}
                  type="button"
                  name="submit"
                  onClick={() => setShowResetPasswordPage(false)}>
                  <i className="fa fa-chevron-left" />
                  Back
                </Button>
              </div>
            </FormikForm>
          )}
        </Formik>
      ) : (
        <div className="text-center">
          <h4 className="mb-3">Thank You</h4>

          <div className="g-text-size-15">
            If this user exists, we have sent you a password reset email.
          </div>

          <Button
            variant="success"
            type="button"
            className={`d-inline-flex justify-content-center align-items-center mt-5 mb-5 ${styles.signInButton}`}
            name="submit"
            onClick={() => setShowResetPasswordPage(false)}>
            Return to Sign In
          </Button>
        </div>
      )}
    </>
  );
}

ForgotPasswordComponent.propTypes = {
  site_key: PropTypes.string,
  setShowResetPasswordPage: PropTypes.func,
  perform_check: PropTypes.bool,
};

export default ForgotPasswordComponent;
