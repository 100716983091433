import { createSlice } from '@reduxjs/toolkit';
import api from '../../../../utils/api';

import { fetchData as portfolioFetchData, getCounterList } from '../../portfolioSlice';

const transactionSlice = createSlice({
  name: 'transaction',
  initialState: {
    loading: false,
    completed: false,
    transactionsList: [],
    totalCash: null,
    selectedLayout: '',
    isLegalUser: false,
    isEodUser: true,
    result: null,
    err: null,
  },
  reducers: {
    transactionFetchStart(state, action) {
      state.transactionsList = [];
      state.totalCash = null;
      state.err = null;
      state.completed = false;
      state.loading = true;
      state.result = null;
    },
    transactionFetchSuccess(state, action) {
      if (action.payload.data.transactions_all_counter) {
        state.transactionsList = action.payload.data.transactions_all_counter.transactions;
        state.totalCash = action.payload.data.transactions_all_counter.totalCash;
      }
      state.completed = true;
      state.loading = false;
    },
    transactionFetchFail(state, action) {
      state.err = action.payload;
      state.completed = false;
      state.loading = false;
    },
    transactionCreateUpdateFetchStart(state, action) {
      state.err = null;
      state.completed = false;
      state.loading = true;
      state.result = null;
    },
    transactionCreateUpdateFetchSuccess(state, action) {
      if (action.payload.data.error) {
        state.err = { message: action.payload.data.error };
      }
      if (action.payload.data.result) {
        state.result = action.payload.data.result;
      }
      state.completed = true;
      state.loading = false;
    },
    transactionCreateUpdateFetchFail(state, action) {
      state.err = action.payload;
      state.completed = false;
      state.loading = false;
    },
    resetMessage(state) {
      state.result = null;
    },
  },
});

export default transactionSlice.reducer;

const {
  transactionFetchStart,
  transactionFetchSuccess,
  transactionFetchFail,
  transactionCreateUpdateFetchStart,
  transactionCreateUpdateFetchSuccess,
  transactionCreateUpdateFetchFail,
  resetMessage,
} = transactionSlice.actions;

export const fetchData =
  ({ folder, selectedLayout, cancelToken, page }) =>
  (dispatch) => {
    dispatch(transactionFetchStart());

    let url = 'portfolio';
    url += `/${folder}`;
    url = `${url}/transactions_list.json`;

    if (page) url = `${url}?page=${page > 1 ? page : 1}`;

    api
      .get(url, { cancelToken })
      .then(({ data }) => {
        dispatch(transactionFetchSuccess({ data }));
      })
      .catch((error) => {
        dispatch(transactionFetchFail(error));
      });
  };

export const createTransactionFetchData =
  ({
    exchangeRate,
    quantity,
    price,
    commission,
    counter,
    activity,
    date,
    broker,
    folder,
    remarks,
    detail,
    selectedLayout,
    refreshPortfolio,
    cancelToken,
  }) =>
  (dispatch) => {
    dispatch(transactionCreateUpdateFetchStart());
    const params = {
      exchangeRate,
      quantity,
      price,
      commission,
      counter,
      activity,
      date,
      broker,
      folder,
      remarks,
      detail,
    };

    api
      .post(`portfolio/${folder}/add_transaction.json`, params, { cancelToken })
      .then(({ data }) => {
        dispatch(transactionCreateUpdateFetchSuccess({ data }));
      })
      .then(() => {
        if (refreshPortfolio) {
          dispatch(portfolioFetchData({ selectedID: folder, cancelToken }));
        } else {
          dispatch(getCounterList({ selectedPortfolioID: folder, selectedLayout }));
        }
      })
      .catch((error) => {
        dispatch(transactionCreateUpdateFetchFail(error));
      });
  };

export const updateTransactionFetchData =
  ({
    exchangeRate,
    quantity,
    price,
    commission,
    counter,
    activity,
    date,
    broker,
    folder,
    remarks,
    selectedLayout,
    cancelToken,
    transactionId,
    refreshPortfolio,
  }) =>
  (dispatch) => {
    dispatch(transactionCreateUpdateFetchStart());
    const params = {
      exchangeRate,
      quantity,
      price,
      commission,
      counter,
      activity,
      date,
      broker,
      folder,
      remarks,
      transactionId,
    };

    api
      .post(`portfolio/${folder}/update_transaction.json`, params, { cancelToken })
      .then(({ data }) => {
        dispatch(transactionCreateUpdateFetchSuccess({ data }));
      })
      .then(() => {
        if (refreshPortfolio) {
          dispatch(portfolioFetchData({ selectedID: folder, cancelToken }));
        } else {
          dispatch(getCounterList({ selectedPortfolioID: folder, selectedLayout }));
        }
      })
      .catch((error) => {
        dispatch(transactionCreateUpdateFetchFail(error));
      });
  };

export const deleteTransactionFetchData =
  ({ id, folder, selectedLayout, refreshPortfolio, cancelToken }) =>
  (dispatch) => {
    dispatch(transactionCreateUpdateFetchStart());
    const params = {
      transactionId: id,
    };

    api
      .post(`portfolio/${folder}/delete_transaction.json`, params, { cancelToken })
      .then(({ data }) => {
        dispatch(transactionCreateUpdateFetchSuccess({ data }));
      })
      .then(() => {
        if (refreshPortfolio) {
          dispatch(portfolioFetchData({ selectedID: folder, cancelToken }));
        } else {
          dispatch(getCounterList({ selectedPortfolioID: folder, selectedLayout }));
        }
      })
      .catch((error) => {
        dispatch(transactionCreateUpdateFetchFail(error));
      });
  };

export const importCsvFetchData =
  ({ folder, cancelToken, formData }) =>
  (dispatch) => {
    dispatch(transactionCreateUpdateFetchStart());

    api
      .post(`portfolio/${folder}/import_transactions_csv.json`, formData, { cancelToken })
      .then(({ data }) => {
        dispatch(transactionCreateUpdateFetchSuccess({ data }));
      })
      .then(() => {
        dispatch(getCounterList({ selectedPortfolioID: folder }));
      })
      .catch((error) => {
        dispatch(transactionCreateUpdateFetchFail(error));
      });
  };

export const clearMessage = () => (dispatch) => {
  dispatch(resetMessage());
};
