import React from 'react';
import { useSelector } from 'react-redux';

import Loader from '../../../components/Loader';
import APIErrorHandler from '../../../components/APIErrorHandler';
import ConsensusEstimates from './ConsensusEstimates';
import Allocation from './Allocation';
import VolumeDistribution from './VolumeDistribution';
import IntrinsicValueAnalysis from './IntrinsicValueAnalysis';
import InsiderTradesAndCompanyBuyback from './InsiderTradesAndCompanyBuyback';
import TechAnalysisAndScanResults from './TechAnalysisAndScanResults';
import Ownership from './Ownership';

function Insights() {
  const error = useSelector((state) => state.portfolio.err);
  const loading = useSelector((state) => state.portfolio.loading);
  const selectedPortfolio = useSelector((state) => state.portfolio.selected);

  return (
    <>
      {error && <APIErrorHandler error={error} classes="g-mt-20" />}
      <Loader visible={loading} />
      {!loading && selectedPortfolio.counters && selectedPortfolio.counters.length > 0 && (
        <div>
          <div className="row">
            <div className="col-xl-6 g-mb-20">
              <ConsensusEstimates />
            </div>
            <div className="col-xl-6 g-mb-20">
              <Allocation />
            </div>
          </div>
          <div className="row">
            <div className="col-xl-6 g-mb-20">
              <IntrinsicValueAnalysis />
            </div>
            <div className="col-xl-6 g-mb-20">
              <VolumeDistribution />
            </div>
          </div>
          <div className="row">
            <div className="col-12 g-mb-20">
              <InsiderTradesAndCompanyBuyback />
            </div>
          </div>
          <div className="row">
            <div className="col-xl-6 g-mb-20">
              <TechAnalysisAndScanResults />
            </div>
            <div className="col-xl-6 g-mb-20">
              <Ownership />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Insights;
