import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Form, Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';

import api from '../../../utils/api';
import { getMarketFromMarketSymbol } from '../../../utils/utils';
import styles from './significantShareholders.module.css';

const { CancelToken } = axios;

function ShareholderSearchBar({ placeholder, searchInput, handleChange, handleSubmit, autoFocus }) {
  const [searchResult, setSearchResult] = useState([]);
  const [showDropDown, setShowDropDown] = useState(false);
  const source = CancelToken.source();

  useEffect(() => {
    if (searchInput) {
      setShowDropDown(true);
      api
        .get(`significant_shareholders/ownership_lookup?keyword=${searchInput}`, {
          cancelToken: source.token,
        })
        .then(({ data }) => {
          setSearchResult(data.results);
        })
        .catch((error) => {
          console.error('Error:', error);
          setSearchResult([]);
        });
    } else {
      setSearchResult([]);
    }
  }, [searchInput]);

  const handleClick = (e, factsetId) => {
    e.preventDefault();
    handleSubmit(factsetId);
    setShowDropDown(false);
  };

  return (
    <div>
      <div className={styles.searchContainer}>
        <Form.Control
          type="text"
          className={styles.searchBarInput}
          onChange={handleChange}
          value={searchInput}
          placeholder={placeholder}
          autoFocus={autoFocus}
        />
        <span className={styles.searchBarIcon} />
      </div>
      {searchResult.length > 0 && (
        <Dropdown className={styles.dropdown} show={showDropDown}>
          <Dropdown.Menu className={styles.dropdownMenu}>
            {searchResult.map((item) => (
              <Dropdown.Item key={item.id} onClick={(e) => handleClick(e, item.factset_owner_id)}>
                <div>
                  <p className={styles.itemName}>{item.name}</p>
                  <span className={styles.marketStats}>
                    {Object.entries(item.stats_by_market).map(([market, stats]) => (
                      <small key={market} className={styles.grayText}>
                        {getMarketFromMarketSymbol(stats.market)}: {stats.count}{' '}
                      </small>
                    ))}
                  </span>
                </div>
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </div>
  );
}

export default ShareholderSearchBar;

ShareholderSearchBar.propTypes = {
  placeholder: PropTypes.string,
  searchInput: PropTypes.string,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  autoFocus: PropTypes.bool,
};
