import { createSlice } from '@reduxjs/toolkit';
import api from '../../utils/api';

const definitionSlice = createSlice({
  name: 'definitionSlice',
  initialState: {
    data: [],
    err: null,
    loading: false,
  },
  reducers: {
    definitionFetchStart(state, action) {
      state.data = [];
      state.err = null;
      state.loading = true;
    },
    definitionFetchSuccess(state, action) {
      state.data = action.payload.data.definition;
      state.err = null;
      state.loading = false;
    },
    definitionFetchFail(state, action) {
      state.err = action.payload;
      state.loading = false;
    },
  },
});

export default definitionSlice.reducer;

const { definitionFetchStart, definitionFetchSuccess, definitionFetchFail } =
  definitionSlice.actions;

export const fetchDefinition =
  ({ cancelToken, source }) =>
  (dispatch) => {
    let url = `/keywords_definition.json?source=${source}`;
    dispatch(definitionFetchStart());
    api
      .get(url, {
        cancelToken,
      })
      .then(({ data }) => {
        dispatch(definitionFetchSuccess({ data }));
      })
      .catch((error) => {
        dispatch(definitionFetchFail(error));
      });
  };
