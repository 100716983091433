import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import DepositWithdrawTransactionModal from './DepositWithdrawTransactionModal';

function DepositWithdraw({ label, loading, variant, className }) {
  const [showModal, setShowModal] = useState({ show: false, type: '' });

  const handleDeposit = () => {
    setShowModal({ show: true, type: 'cash' });
  };

  return (
    <>
      <Button
        className={className || 'btn btn-lg'}
        variant={variant || 'light'}
        disabled={loading}
        onClick={handleDeposit}>
        {label}
      </Button>
      <DepositWithdrawTransactionModal show={showModal} setShow={setShowModal} />
    </>
  );
}

DepositWithdraw.propTypes = {
  label: PropTypes.object,
  loading: PropTypes.bool,
};

export default DepositWithdraw;
