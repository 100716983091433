import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import CriteriaTemplateRenderer from './CriteriaTemplateRenderer';

import AddCriterias from './AddCriterias';
import './marketScreener.scss';

function MobileSelectedCriteria({
  fieldKey,
  fieldIndex,
  attribute,
  market,
  criteriaConfig,
  criteriaGroups,
  stockCategories,
}) {
  const invalidForms = useSelector((state) => state.marketScreener.invalidForms);

  return (
    <div className="criteria_row_wrapper">
      <div className="criteria_row_form g-mb-15 p-3 border g-border-radius-13">
        <CriteriaTemplateRenderer
          market={market}
          fieldKey={fieldKey}
          fieldIndex={fieldIndex}
          attribute={attribute}
          stockCategories={stockCategories}
          validated={invalidForms.includes(fieldIndex.toString())}
        />
        {attribute.sub_criterias.length > 0 &&
          attribute.sub_criterias.map((subCriterias, sub_index) =>
            Object.entries(subCriterias).map(([sub_key, sub_attribute]) => (
              <div key={sub_key} className="sub_criteria_row g-mb-15">
                <CriteriaTemplateRenderer
                  market={market}
                  parentKey={fieldKey}
                  parentIndex={fieldIndex}
                  fieldKey={sub_key}
                  fieldIndex={sub_index}
                  attribute={sub_attribute}
                  orCriteria
                  stockCategories={stockCategories}
                  validated={invalidForms.includes(`${fieldIndex}_${sub_index}`)}
                />
              </div>
            )),
          )}
        <AddCriterias
          market={market}
          criteriaConfig={criteriaConfig}
          criteriaGroups={criteriaGroups}
          parentKey={fieldKey}
          parentIndex={fieldIndex}
          orCriteria
          isMobile
        />
      </div>
    </div>
  );
}

MobileSelectedCriteria.propTypes = {
  fieldKey: PropTypes.string,
  fieldIndex: PropTypes.number,
  attribute: PropTypes.oneOfType([PropTypes.object]),
  market: PropTypes.string,
  criteriaConfig: PropTypes.oneOfType([PropTypes.object]),
  criteriaGroups: PropTypes.oneOfType([PropTypes.object]),
  stockCategories: PropTypes.oneOfType([PropTypes.object]),
};

export default MobileSelectedCriteria;
