import React from 'react';
import { useSelector } from 'react-redux';

import Positions from './Positions';
import TransactionTable from './Transactions/TransactionTable';
import Loader from '../../../components/Loader';
import APIErrorHandler from '../../../components/APIErrorHandler';
import Charts from './Charts';
import DividendAnalysis from './DividendAnalysis';

function Analytics() {
  const error = useSelector((state) => state.portfolio.err);
  const loading = useSelector((state) => state.portfolio.loading);
  const selectedPortfolio = useSelector((state) => state.portfolio.selected);

  return (
    <>
      {error && <APIErrorHandler error={error} classes="g-mt-20" />}
      <Loader visible={loading} />
      {!loading &&
        ((selectedPortfolio.counters && selectedPortfolio.counters.length > 0) ||
          selectedPortfolio.totalCashFlow > 0) && (
          <>
            <Positions />
            <TransactionTable />
            <Charts />
            <DividendAnalysis />
          </>
        )}
    </>
  );
}

export default Analytics;
