import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { Container } from 'react-bootstrap';

import Header from '../Header';
import MarketDepthAddOns from './MarketDepthAddOns';
import MarketDataAddOns from './MarketDataAddOns';
import MembershipSummary from '../MembershipSummary';
import PriceTypeSelect from './PriceTypeSelect';
import BillPeriodSelect from './BillPeriodSelect';
import MarketDataGlobalSelect from './MarketDataGlobalSelect';
import Note from './Note';
import { membershipChooseAddOns, membershipChoosePeriod } from '../membershipSlice';

function MembershipSelectAddOns({ country }) {
  const navigate = useNavigate();
  const data = useSelector((state) => state.membership.data);
  const url = new URL(window.location.href);
  const paramsvalue = new URLSearchParams(url.search);
  const plan = useSelector((state) => state.membership.plan) || paramsvalue.get('plan');
  const period = useSelector((state) => state.membership.period);
  const addOns = useSelector((state) => state.membership.addOns);
  const priceType = useSelector((state) => state.membership.priceType);
  const marketType = useSelector((state) => state.membership.marketType);
  const promoCode = useSelector((state) => state.membership.promoCode);
  const [nextDisabled, setNextDisabled] = useState(true);
  const [realTimeBursaPriceKey, setRealTimeBursaPriceKey] = useState(
    plan?.includes('station')
      ? 'malaysia_real-time_stock_prices_equity'
      : 'real-time_bursa_market_data',
  );
  const dispatch = useDispatch();

  const handleBackClick = () => {
    navigate({
      pathname: '/membership/plans',
      search: createSearchParams({ period, promoCode }).toString(),
    });
  };

  const goToSelectPlan = () => {
    navigate({
      pathname: '/membership/plans',
      search: createSearchParams({ period }).toString(),
    });
  };

  const handleNextClick = () => {
    const params = { period, plan, priceType };
    const addOnsKeys = Object.keys(addOns).filter((addOnKey) => addOns[addOnKey]);
    if (addOns && addOnsKeys.length > 0) {
      params.addOns = Object.keys(addOns)
        .filter((addOnKey) => addOns[addOnKey])
        .join(',');
    }

    navigate({
      pathname: '/membership/plans/payment_options',
      search: createSearchParams(params).toString(),
    });
  };

  useEffect(() => {
    setRealTimeBursaPriceKey(
      plan?.includes('station')
        ? 'malaysia_real-time_stock_prices_equity'
        : 'real-time_bursa_market_data',
    );
  }, [plan]);

  useEffect(() => {
    const canClickNext =
      (priceType || (plan && !plan.includes('station'))) && (marketType || country === 'sg');
    setNextDisabled(!canClickNext);
  }, [priceType, marketType]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {  
    const selected_plan = plan.replace('_my', '').replace('_local', '');
    if (selected_plan == data?.current_membership.toLowerCase()) {
      if (data?.current_period) {
        dispatch(membershipChoosePeriod(data.current_period));
      }

      if (data?.current_features) {
        const features = 
        (plan == 'webpro_my_local') ?
        {
          'real-time_bursa_market_data': data.current_features.my_realtime_bursa_market_data
        } : 
        (plan == 'station_my_local') ?
        {
          'malaysia_real-time_stock_prices_equity': data.current_features.my_realtime_bursa_market_data,
          'malaysia_real-time_stock_prices_derivative': data.current_features.my_realtime_bursa_derivatives_market_data,
          'bursa_full_market_depth': data.current_features.my_bursa_market_depth_full
        } : 
        (selected_plan == 'webpro') ?
        {
          'real-time_sgx_market_data': data.current_features.sg_realtime_sgx_market_data,
          'real-time_bursa_market_data': data.current_features.my_realtime_bursa_market_data
        } : 
        {
          'real-time_sgx_market_data': data.current_features.sg_realtime_sgx_market_data,
          'malaysia_real-time_stock_prices_equity': data.current_features.my_realtime_bursa_market_data,
          'malaysia_real-time_stock_prices_derivative': data.current_features.my_realtime_bursa_derivatives_market_data,
          'full_market_depth': data.current_features.sg_sgx_market_depth_full,
          'bursa_full_market_depth': data.current_features.my_bursa_market_depth_full,
          'real-time_ftse/st_indices': data.current_features.sg_realtime_sgx_market_data,
          'real-time_ftse/bm_index_data': data.current_features.my_realtime_bursa_market_data
        }
        dispatch(membershipChooseAddOns(features));
      }
    }
  }, [data,plan]);  
 
  if (!plan) {
    navigate({
      pathname: '/membership/plans',
      search: createSearchParams({ period }).toString(),
    });
  }

  return (
    <>
      <Header title="Plan Subscription" step="Select Plans" />
      {data?.membership && (
        <Container>
          <div className="row g-mt-60 g-mb-60">
            <div className="col-lg-6 col-md-7 col-sm-12">
              <div className="d-flex align-middle g-mb-60">
                <button
                  className="btn btn-light g-min-width-40 d-inline-block"
                  type="button"
                  onClick={
                    plan == 'webpro' || plan == 'station' ? goToSelectPlan : handleBackClick
                  }>
                  <i className="fa-thin fa-arrow-left fa-xl" />
                </button>
                <h2 className="d-inline-block mx-3 mb-0">
                  <span style={{ fontWeight: 400 }}>Subscribe to</span>{' '}
                  {plan?.includes('station') ? 'Premium' : 'Pro'} plan
                </h2>
              </div>

              <PriceTypeSelect plan={plan} />

              {(priceType || !plan?.includes('station')) && (
                <>
                  <h5>{country === 'my' && 'Select'} Market Data</h5>
                  <MarketDataGlobalSelect country={country} />

                  {(marketType || country === 'sg') && (
                    <>
                      <MarketDataAddOns
                        country={country}
                        realTimeBursaPriceKey={realTimeBursaPriceKey}
                      />
                      <MarketDepthAddOns
                        country={country}
                        realTimeBursaPriceKey={realTimeBursaPriceKey}
                      />

                      <h5 className="g-mt-40">Select Bill Period</h5>
                      <BillPeriodSelect />
                    </>
                  )}
                </>
              )}
            </div>

            <div className="col-lg-6 col-md-5 col-sm-12 sticky-top">
              <MembershipSummary
                country={country}
                handleNextClick={handleNextClick}
                nextDisabled={nextDisabled}
              />
            </div>
          </div>

          <Note country={country} />
        </Container>
      )}
    </>
  );
}

export default MembershipSelectAddOns;

MembershipSelectAddOns.propTypes = {
  country: PropTypes.string,
};
