import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
import dayjs from 'dayjs';

export const PERIOD_OPTIONS = {
  d: {
    label: 'Daily',
    interval: { timeUnit: 'day', count: 1 },
  },
  w: {
    label: 'Weekly',
    interval: { timeUnit: 'week', count: 1 },
  },
  m: {
    label: 'Monthly',
    interval: { timeUnit: 'month', count: 1 },
  },
  q: {
    label: 'Quarterly',
    interval: { timeUnit: 'month', count: 3 },
  },
  y: {
    label: 'Yearly',
    interval: { timeUnit: 'year', count: 1 },
  },
};
export const RANGE_OPTIONS = {
  today: {
    label: 'Today',
    range: { timeUnit: 'day', count: 2 },
    startDate: new Date(),
  },
  yesterday: {
    label: 'Yesterday',
    range: { timeUnit: 'day', count: 3 },
    startDate: dayjs().subtract(1, 'day'),
  },
  threeDays: {
    label: '3 Days',
    range: { timeUnit: 'day', count: 4 },
    startDate: dayjs().subtract(2, 'day'),
  },
  tenDays: {
    label: '10 Days',
    range: { timeUnit: 'day', count: 11 },
    startDate: dayjs().subtract(10, 'day'),
  },
  week: {
    label: '2 Weeks',
    range: { timeUnit: 'week', count: 2 },
    startDate: dayjs().subtract(2, 'week'),
  },
  month: {
    label: '1 Month',
    range: { timeUnit: 'month', count: 1 },
    startDate: dayjs().subtract(1, 'month'),
  },
  quarter: {
    label: 'Last Quarter',
    range: { timeUnit: 'month', count: 3 },
    startDate: dayjs().subtract(3, 'month'),
  },
  yearToDate: {
    label: 'Year to Date',
    range: { timeUnit: 'ytd' },
    startDate: dayjs().subtract(1, 'year'),
  },
  all: {
    label: 'First Purchase',
    range: { timeUnit: 'max' },
  },
};

function PeriodFilters({ period, setPeriod, range, setRange, loading }) {
  const handleRangeChange = (e) => {
    const newRange = e.target.getAttribute('value');
    if (!newRange || newRange === range) {
      return;
    }
    setRange(newRange);
  };

  const handlePeriodChange = (e) => {
    const newPeriod = e.target.getAttribute('value');
    if (!newPeriod || newPeriod === period) {
      return;
    }
    setPeriod(newPeriod);
  };

  return (
    <>
      <Dropdown className="d-inline-block">
        <Dropdown.Toggle variant="light" disabled={loading}>
          {RANGE_OPTIONS[range].label}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {Object.keys(RANGE_OPTIONS).map((key) => {
            return (
              <Dropdown.Item
                key={key}
                value={key}
                onClick={handleRangeChange}
                active={range === key}>
                {RANGE_OPTIONS[key].label}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown className="d-inline-block">
        <Dropdown.Toggle variant="light" disabled={loading}>
          {PERIOD_OPTIONS[period].label}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {Object.keys(PERIOD_OPTIONS).map((key) => {
            return (
              <Dropdown.Item
                key={key}
                value={key}
                onClick={handlePeriodChange}
                active={period === key}>
                {PERIOD_OPTIONS[key].label}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

PeriodFilters.propTypes = {
  period: PropTypes.string,
  range: PropTypes.string,
  setPeriod: PropTypes.func,
  setRange: PropTypes.func,
  loading: PropTypes.bool,
};

export default PeriodFilters;
