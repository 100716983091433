import React from 'react';
import { Provider } from 'react-redux';
import store from '../../utils/store';
import IpoMain from './IpoMain';

function Ipo(props) {
  return (
    <Provider store={store}>
      <IpoMain market={props.market} />
    </Provider>
  );
}

export default Ipo;
