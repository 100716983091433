import React, { useLayoutEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Micro from '@amcharts/amcharts5/themes/Micro';

import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

function SparklineChart({ data, displayMiniBar }) {
  if (!(data?.length > 0 && !data.every((d) => !d.value))) {
    return null;
  }
  const id = uuidv4();
  useLayoutEffect(() => {
    let root = null;
    const formattedData = data.map((d, index) => ({
      category: d.name,
      value: parseFloat(d.value) || 0,
    }));

    root = am5.Root.new(id);
    root.setThemes([am5themes_Micro.new(root)]);

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panY: false,
        layout: root.verticalLayout,
      }),
    );
    chart.plotContainer.set('wheelable', false);
    chart.zoomOutButton.set('forceHidden', true);

    // Create Y-axis
    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
      }),
    );

    // Create X-Axis
    const xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        renderer: am5xy.AxisRendererX.new(root, {}),
        categoryField: 'category',
      }),
    );
    xAxis.data.setAll(formattedData);

    // Create series
    const series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: 'Series',
        xAxis,
        yAxis,
        valueYField: 'value',
        categoryXField: 'category',
        fill: am5.color('#4bc264'),
      }),
    );

    series.columns.template.adapters.add('fill', function (fill, target) {
      if (target._dataItem.dataContext.value < 0) {
        return am5.color('#ff4444');
      }
      return fill;
    });
    series.data.setAll(formattedData);

    return () => {
      root?.dispose();
    };
  }, [data]);

  return <div id={id} className="g-height-35" role="button" onClick={displayMiniBar} />;
}

SparklineChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date_time_f: PropTypes.string,
      period_f: PropTypes.string,
      period_on_percent_growth: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
      value_f: PropTypes.string,
    }),
  ),
  displayMiniBar: PropTypes.func,
};

export default SparklineChart;
