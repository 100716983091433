import React, { useEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5xy from '@amcharts/amcharts5/xy';

import { seriesColors } from '../../../utils/utils';

function ReturnChart({ returnData }) {
  useEffect(() => {
    let chart;
    let root;
    let series;
    if (returnData && returnData.length > 0) {
      root = am5.Root.new('return-chart');

      chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: false,
          panY: false,
          wheelX: 'panX',
          wheelY: 'zoomX',
          layout: root.verticalLayout,
          paddingBottom: 0,
        }),
      );

      // Add legend
      // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
      const legend = chart.children.push(
        am5.Legend.new(root, {
          centerX: am5.p50,
          x: am5.p50,
          y: am5.percent(95),
        }),
      );

      legend.markers.template.setAll({
        width: 15,
        height: 15,
      });

      legend.labels.template.setAll({
        fontSize: 12,
        fontWeight: '300',
      });

      // Xaxis label rotation
      const xRenderer = am5xy.AxisRendererX.new(root, {
        cellStartLocation: 0.1,
        cellEndLocation: 0.9,
        minGridDistance: 30,
      });

      xRenderer.labels.template.setAll({
        rotation: -45,
        centerY: am5.p50,
        centerX: am5.p100,
        paddingRight: 15,
      });
      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      const xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          categoryField: 'category',
          renderer: xRenderer,
        }),
      );

      xAxis.data.setAll(returnData[0].total_shareholder_return);

      const yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {}),
        }),
      );

      returnData.forEach((stock, index) => {
        const seriesColor = seriesColors[index % seriesColors.length];
        const totalShareholderReturn = stock.total_shareholder_return;
        const data = totalShareholderReturn.map((item) => ({
          category: item.category,
          value: Number.isNaN(parseFloat(item.value)) ? 0 : parseFloat(item.value),
        }));

        // Create a line series for each stock
        series = chart.series.push(
          am5xy.LineSeries.new(root, {
            name: stock.name,
            xAxis,
            yAxis,
            categoryXField: 'category',
            valueYField: 'value',
            tooltip: am5.Tooltip.new(root, {
              labelText: `${stock.name}: {valueY}%`,
            }),
            fill: seriesColor,
            stroke: seriesColor,
          }),
        );

        series.bullets.push(function () {
          return am5.Bullet.new(root, {
            locationY: 0.5,
            sprite: am5.Circle.new(root, {
              radius: 4,
              fill: seriesColor,
            }),
          });
        });

        series.data.setAll(data);
        series.appear(1000);
        legend.data.push(series);
      });

      const cursor = chart.set('cursor', am5xy.XYCursor.new(root, {}));
      cursor.lineY.set('visible', false);

      chart.appear(1000, 100);
    }

    // Clean up the chart when the component unmounts
    return () => {
      if (chart) {
        chart.dispose();
      }
      if (root) {
        root.dispose();
      }
    };
  }, [returnData]);

  return <div id="return-chart" className="w-100 h-100" />;
}

export default ReturnChart;
