import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import RangeSlider from 'react-bootstrap-range-slider';
import { useDispatch, useSelector } from 'react-redux';
import { parseFloatOrZero, formatNumberAddCommas } from '../../../utils/utils';
import CalculatorModel from './CalculatorModel';
import './intrinsicAnalysisCalculator.css';
import {
  setFormData,
  setFilteredFields,
  setIntrinsicValue,
  setMarginOfSafety,
  setMarginOfSafetyStatement,
  setPeerAnalysisTableData,
} from './intrinsicValueAnalysisSlice';

function CalculatorTableRow({
  selectedModel,
  field,
  focusedField,
  formData,
  error,
  handleFieldChange,
  handleOnClickField,
}) {
  return (
    <tr
      className={`${focusedField === field.key ? 'table-light' : ''}`}
      onClick={() => handleOnClickField(field.key)}>
      <th>
        <Form.Label htmlFor={field.key} className="bg-transparent">
          {field.key === 'ivc_growth_rate' ? field.name[selectedModel] : field.name}
        </Form.Label>
      </th>
      <td>
        {field.type === 'text' && (
          <>
            <Form.Control
              name={field.key}
              type={field.type}
              value={formData[field.key]}
              onChange={(event) => handleFieldChange(event, field.key)}
            />
            <Form.Control.Feedback
              type="invalid"
              className={error && error[field.key] ? 'd-block' : ''}>
              {error && error[field.key]}
            </Form.Control.Feedback>
          </>
        )}
        {field.type === 'number' &&
          (field.key === 'ivc_terminal_growth_rate' ? (
            <>
              <Form.Select
                className="g-max-width-250 g-mb-10"
                defaultValue={formData.include_ivc_terminal_growth_rate}
                onChange={(event) => handleFieldChange(event, 'include_ivc_terminal_growth_rate')}>
                <option
                  value="exclude"
                  selected={formData.include_ivc_terminal_growth_rate === 'exclude'}>
                  Excluded
                </option>
                <option
                  value="include"
                  selected={formData.include_ivc_terminal_growth_rate === 'include'}>
                  Included
                </option>
              </Form.Select>
              {formData.include_ivc_terminal_growth_rate === 'include' && (
                <>
                  <Form.Control
                    name={field.key}
                    type={field.type}
                    value={formData[field.key]}
                    step={field.step}
                    min={field.min}
                    max={field.max}
                    onChange={(event) => handleFieldChange(event, field.key)}
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    className={error && error[field.key] ? 'd-block' : ''}>
                    {error && error[field.key]}
                  </Form.Control.Feedback>
                </>
              )}
            </>
          ) : (
            <>
              <Form.Control
                name={field.key}
                type={field.type}
                value={formData[field.key]}
                step={field.step}
                min={field.min}
                max={field.max}
                onChange={(event) => handleFieldChange(event, field.key)}
              />
              <Form.Control.Feedback
                type="invalid"
                className={error && error[field.key] ? 'd-block' : ''}>
                {error && error[field.key]}
              </Form.Control.Feedback>
            </>
          ))}
        {field.type === 'range' && (
          <div className="range-container">
            <RangeSlider
              className="form-range"
              name={field.key}
              size="sm"
              value={formData[field.key]}
              step={field.step}
              min={field.min}
              max={field.max}
              tooltipPlacement="top"
              tooltip="on"
              onChange={(event) => handleFieldChange(event, field.key)}
            />
            <span className="min">{field.min}</span>
            <span className="max">{field.max}</span>
          </div>
        )}
      </td>
    </tr>
  );
}

CalculatorTableRow.propTypes = {
  selectedModel: PropTypes.string,
  field: PropTypes.object.isRequired,
  focusedField: PropTypes.string.isRequired,
  formData: PropTypes.object.isRequired,
  handleFieldChange: PropTypes.func.isRequired,
  handleOnClickField: PropTypes.func.isRequired,
};

export default function IntrinsicAnalysisCalculator({
  tab,
  hideFieldInfo,
  showModelWithin,
  callbackOnClick,
}) {
  const dispatch = useDispatch();
  const selectedModel = useSelector((state) => state.intrinsicValueAnalysis.selectedModel);
  const data = useSelector((state) => state.intrinsicValueAnalysis.data);
  const formData = useSelector((state) => state.intrinsicValueAnalysis.formData);
  const filteredFields = useSelector((state) => state.intrinsicValueAnalysis.filteredFields);
  const marginSafetyOfBounds = useSelector(
    (state) => state.intrinsicValueAnalysis.marginSafetyOfBounds,
  );
  const marginOfSafetyConfig = useSelector(
    (state) => state.intrinsicValueAnalysis.marginOfSafetyConfig,
  );

  const [validated, setValidated] = useState(false);
  const [error, setError] = useState(null);

  const formatResults = (data_i, data_m) => {
    let intrinsic_value = parseFloatOrZero(Math.round(data_i * 1000) / 1000);
    let intrinsic_value_f = intrinsic_value;

    let margin_of_safety = data_m;
    let margin_of_safety_f;
    let statement = '';
    let color = 'overvalue';

    if (!data_i || data_i == 'n.a.' || !data_m || data_m == 'n.a.' || data_i < 0) {
      intrinsic_value_f = 'n.a.';
      margin_of_safety_f = 'n.a.';
    } else if (intrinsic_value == 0) {
      intrinsic_value_f = '-';
      margin_of_safety_f = '-';
    } else {
      if (data_m > marginSafetyOfBounds[1]) {
        statement = marginOfSafetyConfig[0].statement;
        color = marginOfSafetyConfig[0].key;
      } else if (data_m >= marginSafetyOfBounds[0] && data_m <= marginSafetyOfBounds[1]) {
        statement = marginOfSafetyConfig[1].statement;
        color = marginOfSafetyConfig[1].key;
      } else {
        statement = marginOfSafetyConfig[2].statement;
        color = marginOfSafetyConfig[2].key;
      }

      intrinsic_value = parseFloatOrZero(intrinsic_value.toFixed(3));
      intrinsic_value_f = formatNumberAddCommas(intrinsic_value);
      margin_of_safety = (Math.round(margin_of_safety * 100) / 100).toFixed(2);
      margin_of_safety_f = margin_of_safety;
      if (margin_of_safety_f > 0) {
        margin_of_safety_f = `+${margin_of_safety_f}`;
      }
      margin_of_safety_f = `${margin_of_safety_f}%`;
    }

    return {
      intrinsic_value: {
        value: intrinsic_value,
        value_f: intrinsic_value_f,
      },
      margin_of_safety: {
        value: margin_of_safety,
        value_f: margin_of_safety_f,
      },
      statement: {
        label: statement,
        color,
      },
    };
  };

  const calculateResults = (_value, _price, selectedModel) => {
    const yearOfGrowth = parseFloatOrZero(formData.ivc_years_growth);
    const requiredRateReturn = parseFloatOrZero(formData.rate_of_return / 100);
    const growthRate = parseFloatOrZero(formData.ivc_growth_rate / 100);
    const includeTerminalGrowthRate = formData.include_ivc_terminal_growth_rate;
    const discountRate = parseFloatOrZero(formData.ivc_discount_rate / 100);
    const price = parseFloatOrZero(_price);

    let earning;
    let discountedRate;
    let discountedEarning;
    let intrinsicVal = 0;
    let marOfSafety;
    let value = parseFloatOrZero(_value);

    if (selectedModel === 'gordon_growth' && tab === 'valuation') {
      intrinsicVal = parseFloatOrZero(
        (value * (1 + growthRate)) / (requiredRateReturn - growthRate),
      );
      marOfSafety = parseFloatOrZero(((intrinsicVal - price) / Math.abs(intrinsicVal)) * 100);
      const result = formatResults(intrinsicVal, marOfSafety);
      return result;
    }

    for (let i = 1; i <= yearOfGrowth; i++) {
      earning = value * (1 + growthRate);
      discountedRate = parseFloatOrZero(1 / (1 + discountRate) ** i);
      discountedEarning = earning * discountedRate;
      intrinsicVal += discountedEarning;
      value = earning;
    }

    if (includeTerminalGrowthRate === 'include') {
      const terminalGrowthRate = parseFloatOrZero(formData.ivc_terminal_growth_rate / 100);
      const terminal_value = parseFloatOrZero(
        (value * (1 + terminalGrowthRate)) / (discountRate - terminalGrowthRate),
      );
      intrinsicVal += parseFloatOrZero(terminal_value / (1 + discountRate) ** yearOfGrowth);
    }

    marOfSafety = parseFloatOrZero(((intrinsicVal - price) / Math.abs(intrinsicVal)) * 100);

    const result = formatResults(intrinsicVal, marOfSafety);
    return result;
  };

  const startCalculation = () => {
    if (tab === 'valuation') {
      let value;
      const price = data.last_done;
      if (selectedModel === 'discounted_earning') {
        value = parseFloatOrZero(formData.ivc_eps);
      } else if (selectedModel === 'discounted_cashflow') {
        value = parseFloatOrZero(formData.ivc_cfo);
      } else if (selectedModel === 'gordon_growth') {
        value = parseFloatOrZero(formData.dividend_per_share);
      }
      const result = calculateResults(value, price, selectedModel);
      dispatch(setIntrinsicValue({ intrinsicValue: result.intrinsic_value }));
      dispatch(setMarginOfSafety({ marginOfSafety: result.margin_of_safety }));
      dispatch(setMarginOfSafetyStatement({ statement: result.statement }));
    } else if (tab === 'peer_analysis') {
      let value_key;
      if (selectedModel === 'discounted_earning') {
        value_key = 'ratio_eps';
      } else if (selectedModel === 'discounted_cashflow') {
        value_key = 'ratio_fcf_per_share';
      }

      if (data.peer_comparison && Object.keys(data.peer_comparison).length > 0) {
        const tableData = {};
        Object.keys(data.peer_comparison).map((key) => {
          tableData[key] = [];
          data.peer_comparison[key].map((peer) => {
            const value = peer[value_key];
            const price = peer.last_done;
            let result = {};
            if (key !== 'other_global_peers' && key !== 'other_local_peers') {
              result = calculateResults(value, price, selectedModel);
            }

            const updatedPeer = {
              ...peer,
              intrinsic_value:
                key !== 'other_global_peers' && key !== 'other_local_peers'
                  ? result.intrinsic_value
                  : undefined,
              margin_of_safety:
                key !== 'other_global_peers' && key !== 'other_local_peers'
                  ? result.margin_of_safety
                  : undefined,
              statement:
                key !== 'other_global_peers' && key !== 'other_local_peers'
                  ? result.statement
                  : undefined,
            };
            tableData[key].push(updatedPeer);
          });
        });
        dispatch(setPeerAnalysisTableData({ peerAnalysisTableData: tableData }));
      }
    }
  };

  const FORM_FIELDS = [
    {
      key: 'ivc_eps',
      name: 'Earnings Per Share ($)',
      type: 'text',
      model: ['discounted_earning'],
      exclude_tab: 'peer_analysis',
      description: (
        <>
          <p>
            The <strong>Earnings Per Share</strong> (EPS) of the company for the last financial year
            is {data.ratio_eps !== 0 ? data.ratio_eps : 'not available'}.
          </p>
          <p>
            <i>
              Note: The Discounted Earnings Model requires a <strong>positive</strong> EPS to derive
              the intrinsic value.
              {data.ratio_eps < 0.0 && (
                <>
                  Since the historical EPS for this company is negative, the Discounted Earnings
                  Model does not work for this company. Alternatively you can update the EPS to a
                  reasonable positive value to calculate the intrinsic value.
                </>
              )}
            </i>
          </p>
          <p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              width="16"
              className="g-mr-5">
              <path
                fill="#3ca8fb"
                d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"
              />
            </svg>
            <strong>Earnings Per Share</strong>
          </p>
          <hr />
          <p className="mb-0">
            The <strong>Discounted Earnings Model</strong> is a valuation model used to estimate the
            intrinsic value of a company with positive earnings. It calculates the EPS every year
            based on the projected growth rate, discounts that back to present value and then sums
            up the EPS over a fixed number of years to derive the intrinsic value of the company.
          </p>
        </>
      ),
    },
    {
      key: 'ivc_cfo',
      name: 'Free Cash Flow Per Share ($)',
      type: 'text',
      model: ['discounted_cashflow'],
      exclude_tab: 'peer_analysis',
      description: (
        <>
          <p>
            The <strong>Free Cash Flow Per Share</strong> (FCF) of the company for the last
            financial year is{' '}
            {data.ratio_fcf_per_share !== 0 ? data.ratio_fcf_per_share : 'not available'}.
          </p>
          <p>
            <i>
              Note: The Discounted Cash Flow Model requires a <strong>positive</strong> FCF to
              derive the intrinsic value.
              {data.ratio_fcf_per_share < 0.0 && (
                <span className="mb-20">
                  Since the historical FCF for this company is negative, the Discounted Cash Flow
                  Model does not work for this company. Alternatively you can update the FCF to a
                  reasonable positive value to calculate the intrinsic value.
                </span>
              )}
            </i>
          </p>
          <p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              width="16"
              className="g-mr-5">
              <path
                fill="#3ca8fb"
                d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"
              />
            </svg>
            <strong>Free Cash Flow Per Share</strong>
          </p>
          <hr />
          <p>
            The <strong>Discounted Cash Flow Model</strong> is a popular valuation model used on
            companies with regular free cash flow and is used to estimate the intrinsic value of a
            company. It calculates the total Free Cash Flow based on the projected growth rate and
            discounts that back to present value.
          </p>
          <p className="mb-0">
            Note: Free Cash Flow must be a <strong>positive</strong> value to derive the intrinsic
            value.
          </p>
        </>
      ),
    },
    {
      key: 'ivc_growth_rate',
      name: {
        discounted_earning: 'EPS Growth Rate (%)',
        discounted_cashflow: 'Cash Flow Growth Rate (%)',
        gordon_growth: 'Dividend Growth Rate (%)',
      },
      type: 'number',
      min: 0,
      max: 50,
      step: 'any',
      model: ['discounted_earning', 'discounted_cashflow', 'gordon_growth'],
      description: (
        <>
          {data.actual ? (
            <>
              <p className="g-mb-35">
                This company belongs to the <strong>{data.industry_name}</strong> industry. The
                historical earnings compound annual growth rate (CAGR) of this industry in{' '}
                {data.exchange_name} for the past few years are:
              </p>
              <Table bordered className="ivc_growth_rate_input text-center">
                <thead className="bg-secondary">
                  <tr>
                    <th />
                    <th>
                      <div>2 Year</div>
                      Growth
                    </th>
                    <th>
                      <div>3 Year</div>
                      Growth
                    </th>
                    <th>
                      <div>5 Year</div>
                      Growth
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>Actual</th>
                    <td>
                      {data.actual.mean_earnings_cagr_2_year}
                      {data.actual.mean_earnings_cagr_2_year !== '-' ? '%' : ''}
                    </td>
                    <td>
                      {data.actual.mean_earnings_cagr_3_year}
                      {data.actual.mean_earnings_cagr_3_year !== '-' ? '%' : ''}
                    </td>
                    <td>
                      {data.actual.mean_earnings_cagr_5_year}
                      {data.actual.mean_earnings_cagr_5_year !== '-' ? '%' : ''}
                    </td>
                  </tr>
                  {data.adjusted && (
                    <>
                      <tr>
                        <th>Adjusted</th>
                        <td>
                          {data.adjusted.mean_earnings_cagr_2_year}
                          {data.adjusted.mean_earnings_cagr_2_year !== '-' ? '%' : ''}
                        </td>
                        <td>
                          {data.adjusted.mean_earnings_cagr_3_year}
                          {data.adjusted.mean_earnings_cagr_3_year !== '-' ? '%' : ''}
                        </td>
                        <td>
                          {data.adjusted.mean_earnings_cagr_5_year}
                          {data.adjusted.mean_earnings_cagr_5_year !== '-' ? '%' : ''}
                        </td>
                      </tr>
                      <tr>
                        <td />
                        <td>
                          <input
                            name="adjusted_ivc_growth_rate_radio"
                            type="radio"
                            value={data.adjusted.mean_earnings_cagr_2_year}
                            checked={
                              formData.adjusted_ivc_growth_rate_radio ===
                              data.adjusted.mean_earnings_cagr_2_year
                            }
                            onChange={(event) => handleFieldChange(event, 'ivc_growth_rate')}
                          />
                        </td>
                        <td>
                          <input
                            name="adjusted_ivc_growth_rate_radio"
                            type="radio"
                            value={data.adjusted.mean_earnings_cagr_3_year}
                            checked={
                              formData.adjusted_ivc_growth_rate_radio ===
                              data.adjusted.mean_earnings_cagr_3_year
                            }
                            onChange={(event) => handleFieldChange(event, 'ivc_growth_rate')}
                          />
                        </td>
                        <td>
                          <input
                            name="adjusted_ivc_growth_rate_radio"
                            type="radio"
                            value={data.adjusted.mean_earnings_cagr_5_year}
                            checked={
                              formData.adjusted_ivc_growth_rate_radio ===
                              data.adjusted.mean_earnings_cagr_5_year
                            }
                            onChange={(event) => handleFieldChange(event, 'ivc_growth_rate')}
                          />
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </Table>
            </>
          ) : (
            <p className="text-danger mb-0">
              Data for the historical growth rate of this company is not available.
            </p>
          )}
          <p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              width="16"
              className="g-mr-5">
              <path
                fill="#3ca8fb"
                d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"
              />
            </svg>
            <strong>Years of Growth</strong>
          </p>
          <hr />
          <p className="mb-0">
            We look at the growth rate of the industry over the past 2 to 5 year period and we
            adjust the growth rate to at least 5% or a maximum of 20%. This will offset the extreme
            upper and lower growth values. However, you can always customize the growth rate beyond
            this limit.
          </p>
        </>
      ),
    },
    {
      key: 'ivc_years_growth',
      name: 'Years of Growth',
      type: 'range',
      min: 5,
      max: 20,
      step: 1,
      model: ['discounted_earning', 'discounted_cashflow'],
      description: (
        <>
          <p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              width="16"
              className="g-mr-5">
              <path
                fill="#3ca8fb"
                d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"
              />
            </svg>
            <strong>Years of Growth</strong>
          </p>
          <hr />
          <p className="mb-0">
            This parameter controls the number of years of projected growth in the company.
          </p>
        </>
      ),
    },
    {
      key: 'ivc_discount_rate',
      name: 'Discount Rate (%)',
      type: 'range',
      min: 1,
      max: 15,
      step: 1,
      model: ['discounted_earning', 'discounted_cashflow'],
      description: (
        <>
          <p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              width="16"
              className="g-mr-5">
              <path
                fill="#3ca8fb"
                d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"
              />
            </svg>
            <strong>Discount Rate</strong>
          </p>
          <hr />
          <p className="mb-0">
            The discount rate is used to calculate the present value of a future sum of money or
            stream of cash flow. The assumption is based on the rationale that a dollar today is
            worth more than a dollar tomorrow. Therefore, receiving $1,000 in earnings, 5 years
            later, will be worth lesser than receiving $1,000 today. The discount rate would be the
            rate of return applied to future earnings or cash flow to discount it back to present
            value. It could be the risk-free rate of a yield such as government treasury bonds or
            the expected rate of return, at a premium above the risk-free rate.
          </p>
        </>
      ),
    },
    {
      key: 'ivc_terminal_growth_rate',
      name: 'Terminal Growth Rate (%)',
      type: 'number',
      min: 0,
      max: 50,
      step: 'any',
      model: ['discounted_earning', 'discounted_cashflow'],
      description: (
        <>
          <p>
            <Form.Select
              className="d-inline-block w-auto g-mr-5"
              defaultValue={formData.include_ivc_terminal_growth_rate}
              onChange={(event) => handleFieldChange(event, 'include_ivc_terminal_growth_rate')}>
              <option
                value="exclude"
                selected={formData.include_ivc_terminal_growth_rate === 'exclude'}>
                Excluded
              </option>
              <option
                value="include"
                selected={formData.include_ivc_terminal_growth_rate === 'include'}>
                Included
              </option>
            </Form.Select>
            Terminal Growth
          </p>
          {formData.include_ivc_terminal_growth_rate === 'include' && (
            <p className="ivc_terminal_growth_input ">
              <Form.Label className="g-mr-5" htmlFor="ivc_terminal_growth_rate">
                Terminal Growth Rate (%)
              </Form.Label>
              <Form.Control
                className="d-inline-block w-auto"
                name="ivc_terminal_growth_rate"
                type="number"
                value={formData.ivc_terminal_growth_rate}
                step="any"
                min="0"
                max="50"
                onChange={(event) => handleFieldChange(event, 'ivc_terminal_growth_rate')}
              />
              <Form.Control.Feedback
                type="invalid"
                className={error?.ivc_terminal_growth_rate ? 'd-block' : ''}>
                {error?.ivc_terminal_growth_rate}
              </Form.Control.Feedback>
            </p>
          )}
          <p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              width="16"
              className="g-mr-5">
              <path
                fill="#3ca8fb"
                d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"
              />
            </svg>
            <strong>Terminal Growth Rate</strong>
          </p>
          <hr />
          <p>
            The terminal growth rate is an estimate of a company's growth beyond the projection
            period. This is used in calculating the terminal value of a company as it expands its
            future income beyond the initial few years’ projections. However, this is typically not
            included in valuation models as it assumes that a company grows perpetually and this may
            result in over inflation to the intrinsic value of a company.
          </p>
          <p className="mb-0">
            Note: Terminate growth rate used must always be lower than the discount rate.
          </p>
        </>
      ),
    },
    {
      key: 'dividend_per_share',
      name: 'Dividend Per Share ($)',
      type: 'text',
      model: ['gordon_growth'],
      description: (
        <>
          <p>
            The <strong>Dividend Per Share</strong> of the company for the last financial year is{' '}
            {data.net_dividend !== 0 ? data.net_dividend : 'not available'}.
          </p>

          <p>
            <i>
              Note: The Gordon Growth Model requires the company to give out{' '}
              <strong>regular and annual dividends</strong> to derive the intrinsic value.
              {data.net_dividend <= 0.0 && (
                <>
                  Since the company did not give out any dividend for the last financial year, the
                  Gordon Growth Model does not work for this company. Alternatively you can update
                  the Dividends Per Share to a reasonable value to calculate the intrinsic value.
                </>
              )}
            </i>
          </p>

          <p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              width="16"
              className="g-mr-5">
              <path
                fill="#3ca8fb"
                d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"
              />
            </svg>
            <strong>Dividend Per Share</strong>
          </p>
          <hr />
          <p className="mb-0">
            The <strong>Gordon Growth Model</strong>, also known as the Dividend Discount Model
            (DDM), is a method of calculating the intrinsic value of a stock for companies which
            gives out regular and growing dividends. The model equates the intrinsic value to the
            present value of a stock's future dividends.
          </p>
        </>
      ),
    },
    {
      key: 'rate_of_return',
      name: 'Require Rate of Return (%)',
      type: 'text',
      model: ['gordon_growth'],
      description: (
        <>
          <p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              width="16"
              className="g-mr-5">
              <path
                fill="#3ca8fb"
                d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"
              />
            </svg>
            <strong>Require Rate of Return</strong>
          </p>
          <hr />
          <p className="mb-0">
            The <strong>Required Rate of Return</strong> is the expected gain on your investment in
            this company, expressed as a percentage. If you invest $1,000 and expect to make $1,500,
            your Required Rate of Return is 50%.
          </p>
        </>
      ),
    },
  ];

  const [focusedField, setFocusedField] = useState(() => {
    return FORM_FIELDS.find((field) => field.model.includes(selectedModel)).key;
  });

  // change calculator model
  const handleOnClickModel = (newModel) => {
    if (newModel === selectedModel) {
      return;
    }
    const newFocusedField = FORM_FIELDS.find((field) => field.model.includes(newModel)).key;
    handleOnClickField(newFocusedField);
    dispatch(setSelectedModel({ selectedModel: newModel }));
  };

  // highlight selected row and show description
  const handleOnClickField = (newFocusedField) => {
    if (newFocusedField === focusedField) {
      return;
    }
    setFocusedField(newFocusedField);
  };

  // update data on input change
  const handleFieldChange = (event, key) => {
    const { value } = event.target;
    // Regular expression to allow only numbers and a decimal point
    const regex = /^\d*\.?\d*$/;
    // Check if the input value matches the regex pattern
    if (regex.test(value) || key === 'include_ivc_terminal_growth_rate') {
      // Update the state with the valid input value
      let updatedFormData = {
        ...formData,
        [key]: value,
      };

      // make sure ivc_growth_rate and adjusted_ivc_growth_rate_radio are in sync
      if (key === 'ivc_growth_rate') {
        updatedFormData = {
          ...updatedFormData,
          adjusted_ivc_growth_rate_radio: value,
        };
      }
      dispatch(setFormData({ formData: updatedFormData }));
    }
  };

  // submit/calculate data
  const handleSubmit = (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    const valid = validateInputs();
    if (form.checkValidity() && valid) {
      startCalculation();
      if (callbackOnClick) {
        callbackOnClick();
      }
    }

    setValidated(true);
  };

  const validateInputs = () => {
    const newError = {};
    // Check whether has empty fields
    filteredFields.map((field) => {
      if (formData[field.key] === '') {
        newError[field.key] = `${
          field.key === 'ivc_growth_rate' ? field.name[selectedModel] : field.name
        } is required.`;
      }

      // check whether ivc_eps or ivc_cfo is not zero
      if (field.key === 'ivc_eps' || field.key === 'ivc_cfo') {
        if (isNaN(formData[field.key]) || formData[field.key] == 0) {
          newError[field.key] = 'Please input a non-zero number';
        }
      }

      // check whether value is out of ranges
      if (
        (field.type === 'number' || field.type === 'range') &&
        field.hasOwnProperty('min') &&
        field.hasOwnProperty('max')
      ) {
        if (
          parseFloatOrZero(formData[field.key]) > field.max ||
          parseFloatOrZero(formData[field.key]) < field.min
        ) {
          newError[field.key] = `${
            field.key === 'ivc_growth_rate' ? field.name[selectedModel] : field.name
          } must be a number between ${field.min}~${field.max}`;
        }
      }

      // check if rate_of_return is less than ivc_growth_rate
      if (
        field.key === 'rate_of_return' &&
        parseFloatOrZero(formData[field.key]) <= parseFloatOrZero(formData.ivc_growth_rate)
      ) {
        newError[field.key] = `${field.name} should be greater than ${
          filteredFields.find((x) => x.key === 'ivc_growth_rate').name[selectedModel]
        }`;
      }

      // check if ivc_terminal_growth_rate is less than ivc_discount_rate
      if (
        formData.include_ivc_terminal_growth_rate === 'include' &&
        field.key === 'ivc_terminal_growth_rate' &&
        parseFloatOrZero(formData.ivc_discount_rate) < parseFloatOrZero(formData[field.key])
      ) {
        newError[field.key] = `${
          filteredFields.find((x) => x.key === 'ivc_discount_rate').name
        } should be greater than ${field.name}`;
      }
    });

    setError(newError);
    return !(newError && Object.keys(newError).length > 0);
  };

  // show different form based on selectedModel
  useEffect(() => {
    const fields = FORM_FIELDS.map(({ description, ...rest }) => rest).filter((field) => {
      return field.model.includes(selectedModel) && field.exclude_tab !== tab;
    });

    // trigger click on first form field when selectedModel changed,
    // to highlight the first field and show it's description etc
    if (!hideFieldInfo) {
      handleOnClickField(fields[0].key);
    }

    if (selectedModel === 'gordon_growth') {
      // if gordon_growth is selected, move ivc_growth_rate field to the last
      const ivcGrowthRateIndex = fields.findIndex((field) => field.key === 'ivc_growth_rate');
      const ivcGrowthRateField = fields.splice(ivcGrowthRateIndex, 1)[0];
      fields.push(ivcGrowthRateField);
    }

    dispatch(setFilteredFields({ filteredFields: fields }));

    startCalculation();
  }, [selectedModel]);

  return (
    <div className="row gx-0 gy-3">
      <div className={`${hideFieldInfo ? 'col-lg' : 'col-lg-6'} `}>
        {showModelWithin && (
          <div className="g-mb-30">
            <CalculatorModel tab={tab} />
          </div>
        )}
        <Form
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
          className="no-validate-style"
          id={`form_${selectedModel}`}>
          <Table size="sm">
            <tbody>
              {filteredFields.map((field) => (
                <CalculatorTableRow
                  selectedModel={selectedModel}
                  key={field.key}
                  field={field}
                  focusedField={focusedField}
                  formData={formData}
                  error={error}
                  handleFieldChange={handleFieldChange}
                  handleOnClickField={handleOnClickField}
                />
              ))}
            </tbody>
          </Table>
          <div className="text-center">
            <Button type="submit" variant="primary">
              Calculate
            </Button>
          </div>
        </Form>
      </div>
      {!hideFieldInfo && (
        <div className="col-lg-6 bg-light">
          <div className="p-3">
            <Form>{FORM_FIELDS.find((field) => field.key === focusedField).description}</Form>
          </div>
        </div>
      )}
    </div>
  );
}

IntrinsicAnalysisCalculator.propTypes = {
  type: PropTypes.string,
  hideFieldInfo: PropTypes.bool,
  showModelWithin: PropTypes.bool,
  callbackOnClick: PropTypes.fn,
};
