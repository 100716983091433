import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import Tooltip from 'react-bootstrap/Tooltip';

function PopUpNote({ content, icon, placement = 'auto-end', trigger = ['click'], classes }) {
  const renderOverlay = (data) => {
    return (
      <Tooltip className={`tooltip_custom ${classes}`}>
        <div className="text-start g-max-height-300 overflow-x-hidden overflow-y-scroll">
          <span
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(data),
            }}
          />
        </div>
      </Tooltip>
    );
  };

  return (
    <OverlayTrigger
      overlay={renderOverlay(content)}
      trigger={trigger}
      placement={placement}
      rootClose>
      <button
        type="button"
        aria-label="popup"
        className="g-ml-5 g-min-width-0 bg-transparent border-0 p-0">
        <i className={icon} />
      </button>
    </OverlayTrigger>
  );
}

PopUpNote.propTypes = {
  content: PropTypes.string,
  icon: PropTypes.string,
  placement: PropTypes.string,
  trigger: PropTypes.oneOfType([PropTypes.array]),
  classes: PropTypes.string,
};

export default PopUpNote;
