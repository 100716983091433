import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown';

import {
  deleteAlertSettings,
  deleteAlertHistories,
  updateAlertSettingsFilters,
  updateAlertSettingsSorting,
  fetchAlertsHistory,
  updateUserSetHistoryFilter,
  updateActiveStatus,
} from './stockNewsAlertsSlice';
import ModalDialog from '../ModalDialog';

const { CancelToken } = axios;

function AlertListActions({ tab }) {
  const dispatch = useDispatch();
  const source = axios.CancelToken.source();
  const label = tab === 'setting' ? 'Alert Settings' : 'Alert History';
  const selectedSettings = useSelector((state) => state.stockNewsAlerts.selectedAlertSettings);
  const alertSettings = useSelector((state) => state.stockNewsAlerts.alertSettings);
  const alertHistories = useSelector((state) => state.stockNewsAlerts.alertHistories);
  const userSetHistoryFilter = useSelector((state) => state.stockNewsAlerts.userSetHistoryFilter);
  const [showDialog, setShowDialog] = useState({ show: false });

  const deleteSettings = () => {
    dispatch(deleteAlertSettings({ alertSettingIDs: selectedSettings, cancelToken: source.token }));
  };

  const clearHistory = () => {
    dispatch(deleteAlertHistories({ alertHistoryID: null, cancelToken: source.token }));
  };

  const onBatchDeleteSettings = () => {
    setShowDialog({
      show: true,
      title: 'Delete Alert Setting',
      body: 'Are you sure you want to delete the selected alert settings?',
      label: 'Delete',
      variant: 'danger',
      handleSave: deleteSettings,
    });
  };

  const onClearHistory = () => {
    setShowDialog({
      show: true,
      title: 'Delete Alert History',
      body: 'Are you sure you want to delete all alert history?',
      label: 'Delete',
      variant: 'danger',
      handleSave: clearHistory,
    });
  };

  return (
    <>
      <div className="p-3 g-border-b-1">
        <h6 className="d-inline-block my-0">{label}</h6>
        <span className="float-end">
          {tab === 'history' && (
            <button
              type="button"
              aria-label="clear"
              disabled={!(alertHistories && alertHistories.length > 0)}
              onClick={onClearHistory}
              className="d-inline-block btn btn-light g-min-width-30 g-min-height-15 g-ml-5 g-p-2">
              <i className="fa-light fa-broom-wide g-text-size-14" />
            </button>
          )}
          {tab === 'setting' && (
            <>
              <StatusSettingDropdown handleDialogShow={setShowDialog} />
              <button
                type="button"
                aria-label="delete"
                disabled={
                  !(alertSettings && alertSettings.length > 0) ||
                  !(selectedSettings && selectedSettings.length > 0)
                }
                onClick={onBatchDeleteSettings}
                className="d-inline-block btn btn-light g-min-width-30 g-min-height-15 g-ml-5 g-p-2">
                <i className="fa-light fa-trash-xmark g-text-size-14" />
              </button>
            </>
          )}
          <SortAndFilter
            disabled={
              (tab === 'setting' && !(alertSettings && alertSettings.length > 0)) ||
              (tab === 'history' &&
                !((alertHistories && alertHistories.length > 0) || userSetHistoryFilter))
            }
            tab={tab}
          />
        </span>
      </div>

      <ModalDialog
        show={showDialog.show}
        handleClose={(e) => {
          if (e) {
            e.stopPropagation();
          }
          setShowDialog({ show: false });
        }}
        title={showDialog.title}
        body={showDialog.body}
        handleSave={(params, e) => {
          e.stopPropagation();
          showDialog.handleSave();
          setShowDialog({ show: false });
        }}
        saveLabel={showDialog.label}
        saveVariant={showDialog.variant}
      />
    </>
  );
}

export default AlertListActions;

AlertListActions.propTypes = {
  tab: PropTypes.string,
};

function SortAndFilter({ disabled, tab }) {
  const dispatch = useDispatch();
  const source = CancelToken.source();
  const alertSettings = useSelector((state) => state.stockNewsAlerts.alertSettings);
  const [counters, setCounters] = useState([]);
  const [search, setSearch] = useState('');
  const [activeFilter, setActiveFilter] = useState(1);
  const [inactiveFilter, setInactiveFilter] = useState(1);
  const [countersFilter, setCountersFilter] = useState(['ALL']);
  const [sortedOrFiltered, setSortedOrFiltered] = useState(false);
  const [sorting, setSorting] = useState({});

  const resetCounters = () => {
    setCounters({
      ...alertSettings
        .filter((setting) => setting.instCode && setting.instCode.length > 0)
        .reduce((a, v) => ({ ...a, [v.instCode]: v.instName }), {}),
    });
  };

  const selectCounters = (e, counter) => {
    e.stopPropagation();

    if (!counter) {
      return;
    }

    setSortedOrFiltered(true);

    let userCounterFilter = null;

    if (e.target.checked) {
      if (counter === 'ALL') {
        userCounterFilter = ['ALL'];
        setCountersFilter(userCounterFilter);
      } else if (!countersFilter.includes(counter)) {
        userCounterFilter = [...countersFilter, counter];
        setCountersFilter(userCounterFilter);
      }
    } else if (counter === 'ALL') {
      userCounterFilter = [];
      setCountersFilter(userCounterFilter);
    } else {
      if (countersFilter[0] === 'ALL') {
        userCounterFilter = [
          ...alertSettings
            .filter(
              (setting) =>
                setting.instCode && setting.instCode.length > 0 && setting.instCode !== counter,
            )
            .map((c) => c.instCode)
            .filter((value, index, array) => array.indexOf(value) === index),
        ];
      } else {
        userCounterFilter = [...countersFilter.filter((c) => c !== counter && c !== 'ALL')];
      }
      setCountersFilter(userCounterFilter);
    }

    if (userCounterFilter && tab === 'history') {
      dispatch(updateUserSetHistoryFilter({ counters: userCounterFilter }));
    }
  };

  useEffect(() => {
    if (!alertSettings || alertSettings.length === 0) {
      return;
    }
    resetCounters();
  }, [alertSettings]);

  useEffect(() => {
    if (search && search.length > 0) {
      const keyword = search.toLowerCase();
      setCounters({
        ...alertSettings
          .filter(
            (setting) =>
              setting.instCode &&
              setting.instCode.length > 0 &&
              (setting.instCode.toLowerCase().includes(keyword) ||
                setting.instName.toLowerCase().includes(keyword)),
          )
          .reduce((a, v) => ({ ...a, [v.instCode]: v.instName }), {}),
      });
    } else {
      resetCounters();
    }
  }, [search]);

  useEffect(() => {
    if (tab === 'history') {
      return;
    }
    dispatch(updateAlertSettingsFilters({ activeFilter, inactiveFilter, countersFilter }));
  }, [activeFilter, inactiveFilter]);

  useEffect(() => {
    if (tab === 'setting') {
      dispatch(updateAlertSettingsFilters({ activeFilter, inactiveFilter, countersFilter }));
      dispatch(updateAlertSettingsSorting(sorting));
    } else if (sortedOrFiltered) {
      dispatch(
        fetchAlertsHistory({
          counters: countersFilter,
          orderBy: sorting.by,
          orderDir: sorting.dir,
          cancelToken: source.token,
        }),
      );
    }
  }, [countersFilter, sorting]);

  return (
    <>
      <Dropdown className="d-inline-block align-bottom">
        <Dropdown.Toggle
          disabled={disabled}
          variant="light"
          className="no-default-caret g-min-width-30 g-ml-5 g-p-7">
          <i className="fa-light fa-arrow-down-short-wide g-text-size-14" />
        </Dropdown.Toggle>
        <Dropdown.Menu className="p-0">
          <div className="text-muted px-3 py-2">Counter Name</div>
          <Dropdown.Item
            className={
              sorting.by === 'counter' && sorting.dir === 'asc' ? 'active text-primary' : ''
            }
            onClick={() => {
              setSorting({ by: 'counter', dir: 'asc' });
              setSortedOrFiltered(true);
            }}>
            <i className="fa-regular fa-arrow-down-a-z me-2" /> Counter name A-Z
          </Dropdown.Item>
          <Dropdown.Item
            className={
              sorting.by === 'counter' && sorting.dir === 'desc' ? 'active text-primary' : ''
            }
            onClick={() => {
              setSorting({ by: 'counter', dir: 'desc' });
              setSortedOrFiltered(true);
            }}>
            <i className="fa-regular fa-arrow-up-a-z me-2" />
            Counter name Z-A
          </Dropdown.Item>
          <Dropdown.Divider />
          {tab === 'setting' && (
            <>
              <div className="text-muted px-3 py-2">Time created</div>
              <Dropdown.Item
                className={
                  sorting.by === 'time' && sorting.dir === 'asc' ? 'active text-primary' : ''
                }
                onClick={() => {
                  setSorting({ by: 'time', dir: 'asc' });
                  setSortedOrFiltered(true);
                }}>
                <i className="fa-regular fa-arrow-down-a-z me-2" /> Time created (Ascending)
              </Dropdown.Item>
              <Dropdown.Item
                className={
                  sorting.by === 'time' && sorting.dir === 'desc' ? 'active text-primary' : ''
                }
                onClick={() => {
                  setSorting({ by: 'time', dir: 'desc' });
                  setSortedOrFiltered(true);
                }}>
                <i className="fa-regular fa-arrow-up-a-z me-2" /> Time created (Descending)
              </Dropdown.Item>
            </>
          )}

          {tab === 'history' && (
            <>
              <div className="text-muted px-3 py-2">Triggered time</div>
              <Dropdown.Item
                className={
                  sorting.by === 'time' && sorting.dir === 'asc' ? 'active text-primary' : ''
                }
                onClick={() => {
                  setSorting({ by: 'time', dir: 'asc' });
                  setSortedOrFiltered(true);
                }}>
                <i className="fa-regular fa-arrow-down-a-z me-2" /> Triggered time (Ascending)
              </Dropdown.Item>
              <Dropdown.Item
                className={
                  sorting.by === 'time' && sorting.dir === 'desc' ? 'active text-primary' : ''
                }
                onClick={() => {
                  setSorting({ by: 'time', dir: 'desc' });
                  setSortedOrFiltered(true);
                }}>
                <i className="fa-regular fa-arrow-up-a-z me-2" /> Triggered time(Descending)
              </Dropdown.Item>
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown className="d-inline-block align-bottom">
        <Dropdown.Toggle
          variant="light"
          disabled={disabled}
          className="no-default-caret g-min-width-30 g-ml-5 g-p-7">
          <i className="fa-light fa-filter g-text-size-14" />
        </Dropdown.Toggle>
        <Dropdown.Menu className="p-0 g-min-width-250">
          {tab === 'setting' && (
            <>
              <div className="px-3 py-2">
                <label className="align-middle me-3" htmlFor="active">
                  <input
                    className="me-2 align-middle"
                    type="checkbox"
                    id="active"
                    checked={activeFilter === 1}
                    onChange={(e) => {
                      setActiveFilter(e.target.checked ? 1 : 0);
                    }}
                  />
                  Active
                </label>
                <label className="align-middle" htmlFor="inactive">
                  <input
                    className="me-2 align-middle"
                    type="checkbox"
                    id="inactive"
                    checked={inactiveFilter === 1}
                    onChange={(e) => {
                      setInactiveFilter(e.target.checked ? 1 : 0);
                    }}
                  />
                  Inactive
                </label>
              </div>
              <Dropdown.Divider />
            </>
          )}
          <div className="form-group p-3 g-per-width-98">
            <div className="input-group mb-3">
              <span
                className="input-group-text bg-white form-control g-max-width-20"
                id="search-addon">
                <i className="fa-thin fa-magnifying-glass" />
              </span>
              <input
                className="form-control border-start-0"
                type="text"
                placeholder="Search counter"
                aria-label="Counter"
                aria-describedby="search-addon"
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <div className="text-muted">Counter Name</div>
            <div className="g-max-height-250 overflow-auto">
              <div>
                <label className="align-middle me-3" htmlFor="counter-all">
                  <input
                    className="me-2 align-middle"
                    type="checkbox"
                    id="counter-all"
                    checked={countersFilter.includes('ALL')}
                    onChange={(e) => {
                      selectCounters(e, 'ALL');
                    }}
                  />
                  All counters
                </label>
              </div>
              {Object.keys(counters).map((key) => {
                return (
                  <div key={key}>
                    <label className="align-middle me-3" htmlFor={`counter-${key}`}>
                      <input
                        className="me-2 align-middle"
                        type="checkbox"
                        id={`counter-${key}`}
                        checked={countersFilter.includes(key) || countersFilter.includes('ALL')}
                        onChange={(e) => {
                          selectCounters(e, key);
                        }}
                      />
                      {counters[key]}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

SortAndFilter.propTypes = {
  disabled: PropTypes.bool,
  tab: PropTypes.string,
};

function StatusSettingDropdown({ handleDialogShow }) {
  const dispatch = useDispatch();
  const source = axios.CancelToken.source();
  const selectedSettings = useSelector((state) => state.stockNewsAlerts.selectedAlertSettings);
  const alertSettings = useSelector((state) => state.stockNewsAlerts.alertSettings);
  const [disabled, setDisabled] = useState(true);

  const activateSetting = () => {
    dispatch(
      updateActiveStatus({
        alertSettingIDs: selectedSettings,
        action: 'activate',
        cancelToken: source.token,
      }),
    );
  };

  const deactivateSetting = () => {
    dispatch(
      updateActiveStatus({
        alertSettingIDs: selectedSettings,
        action: 'deactivate',
        cancelToken: source.token,
      }),
    );
  };

  const onBatchActivateSettings = () => {
    handleDialogShow({
      show: true,
      title: 'Activate Alert Setting',
      body: 'Are you sure you want to activate the selected alert settings?',
      label: 'Activate',
      variant: 'primary',
      handleSave: activateSetting,
    });
  };

  const onBatchDeactivateSettings = () => {
    handleDialogShow({
      show: true,
      title: 'Deactivate Alert Setting',
      body: 'Are you sure you want to deactivate the selected alert settings?',
      label: 'Deactivate',
      variant: 'danger',
      handleSave: deactivateSetting,
    });
  };

  useEffect(() => {
    setDisabled(
      () =>
        !(alertSettings && alertSettings.length > 0) ||
        !(selectedSettings && selectedSettings.length > 0),
    );
  }, [selectedSettings, alertSettings]);

  return (
    <Dropdown className="d-inline-block align-bottom">
      <Dropdown.Toggle
        disabled={disabled}
        variant="light"
        aria-label="activate/deactivate"
        className="no-default-caret g-min-width-30 g-ml-5 g-p-7 ">
        <i className="fa-light fa-play-pause g-text-size-14" />
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0">
        <Dropdown.Item onClick={onBatchActivateSettings}>
          <i className="fa-regular fa-play me-2" /> Activate all inactive
        </Dropdown.Item>
        <Dropdown.Item onClick={onBatchDeactivateSettings}>
          <i className="fa-regular fa-pause me-2" /> Stop all
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

StatusSettingDropdown.propTypes = {
  handleDialogShow: PropTypes.func,
};
