import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

function ConsensusRecommendationChart({ data }) {
  useLayoutEffect(() => {
    const root = am5.Root.new('concensus-recommendation-chart');

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([am5themes_Animated.new(root)]);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: true,
        paddingRight: 20,
      }),
    );

    chart.plotContainer.events.on("wheel", function(ev) {

      if (ev.originalEvent.shiftKey) {
        ev.originalEvent.preventDefault();
        chart.set("wheelY", "zoomY");
      }
      else {
        chart.set("wheelX", "none");
        chart.set("wheelY", "none");
      }
    });

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    // Create X-Axis
    const xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 100,
    });
    xRenderer.grid.template.set('visible', false);
    xRenderer.ticks.template.setAll({
      location: 0.5,
      stroke: am5.color(0x000000),
      visible: true,
    });
    xRenderer.labels.template.setAll({
      paddingRight: 20,
    });

    const xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        numberFormat: "#'%'",
        renderer: xRenderer,
      }),
    );

    // Add xAxis title
    const xAxisTitle = am5.Label.new(root, {
      text: 'Potential Upside',
      centerX: am5.p50,
      textAlign: 'center',
      x: am5.p50,
    });
    xAxis.children.push(xAxisTitle);

    // Create Y-axis
    const yRenderer = am5xy.AxisRendererY.new(root, {
      inversed: true,
      inside: true,
    });
    yRenderer.labels.template.set('visible', false);
    yRenderer.grid.template.set('visible', false);

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0.8,
        max: 3.2,
        strictMinMax: true,
        renderer: yRenderer,
      }),
    );

    // Draw line at 0 point on xAxis
    const pointZero = xAxis.makeDataItem({
      value: 0,
      endValue: undefined,
    });
    const xRange = xAxis.createAxisRange(pointZero);
    xRange.get('grid').setAll({
      stroke: am5.color(0x000000),
      strokeWidth: 2,
      strokeOpacity: 1,
      location: 1,
      visible: true,
      layer: 3,
    });

    // Add yAxis title
    const yAxisTitle = am5.Label.new(root, {
      rotation: -90,
      text: 'Consensus Recommendation',
      y: am5.p50,
      centerX: am5.p50,
      textAlign: 'center',
      x: 0,
    });
    yAxis.children.unshift(yAxisTitle);

    // function to set backgroundColor on yAxis using axisRange
    function backgroundColorYAxis(name, value, endValue, color) {
      const rangeDataItem = yAxis.makeDataItem({
        value,
        endValue,
      });

      const range = yAxis.createAxisRange(rangeDataItem);

      // fill background color
      range.get('axisFill').setAll({
        fill: color,
        fillOpacity: 1,
        visible: true,
      });

      // set grid color
      range.get('grid').setAll({
        stroke: color,
        strokeOpacity: 1,
        location: 1,
      });

      // custom yAxis label
      range.get('label').setAll({
        fill: am5.color(0x000000),
        text: name,
        inside: true,
        centerX: 0,
        location: 0.5,
        layer: 1,
        visible: true,
      });
    }

    // set yAxis color and custom label.
    backgroundColorYAxis('Buy', 0.8, 1.25, am5.color(0xbdecbd));
    backgroundColorYAxis('Overweight', 1.25, 1.75, am5.color(0xcff4cf));
    backgroundColorYAxis('Hold', 1.75, 2.25, am5.color(0xfff8a9));
    backgroundColorYAxis('Underweight', 2.25, 2.75, am5.color(0xfbcfcf));
    backgroundColorYAxis('Sell', 2.75, 3.2, am5.color(0xf7b9b9));

    // Create Tooltip
    const tooltip = am5.Tooltip.new(root, {
      autoTextColor: false,
      getFillFromSprite: false,
      getStrokeFromSprite: false,
      labelText:
        '[bold]{name}[/] \nUpside: {upside_percent}% \nConsensus Recommendation: [bold]{consensus_recommendation}[/] \nConsensus Score: {consensus_score} \nNumber Of Ratings: {number_of_ratings}',
    });

    tooltip.label.setAll({
      fill: am5.color(0x000000),
    });

    tooltip.get('background').setAll({
      fillGradient: am5.LinearGradient.new(root, {
        stops: [
          {
            color: am5.color(0xfefefe),
          },
          {
            color: am5.color(0xe0e0e0),
          },
        ],
      }),
      fillOpacity: 1,
      stroke: am5.color(0xe0e0e0),
      strokeWidth: 1.5,
      strokeOpacity: 1,
    });

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    const series = chart.series.push(
      am5xy.LineSeries.new(root, {
        calculateAggregates: true,
        xAxis,
        yAxis,
        valueYField: 'consensus_score',
        valueXField: 'upside_percent',
        tooltip,
        fill: am5.color(0x306687),
      }),
    );

    // Add bullet
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/#Bullets
    const circleTemplate = am5.Template.new({});
    series.bullets.push(function () {
      const circle = am5.Circle.new(
        root,
        {
          strokeOpacity: 0,
          strokeWidth: 1,
          fill: series.get('fill'),
          fillOpacity: 0.7,
          interactive: true,
          layer: 5,
        },
        circleTemplate,
      );
      circle.states.create('hover', {
        fill: am5.color(0x103449),
        stroke: am5.color(0xffffff),
        strokeOpacity: 1,
        scale: 1.2,
      });
      circle.events.on('click', function (ev) {
        window.location.href = `/quote/${ev.target.dataItem.dataContext.stock_id}/consensus_estimates`;
      });
      return am5.Bullet.new(root, {
        sprite: circle,
      });
    });

    // remove stroke for series since we are using bullets instead
    series.strokes.template.set('strokeOpacity', 0);

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    const cursor = am5xy.XYCursor.new(root, {
      xAxis,
      yAxis,
      snapToSeries: [series],
    });
    chart.set('cursor', cursor);

    let previousBulletSprites = [];
    function cursorMoved() {
      for (let i = 0; i < previousBulletSprites.length; i++) {
        previousBulletSprites[i].unhover();
      }
      previousBulletSprites = [];
      chart.series.each(function (series) {
        const { dataItem } = series.get('tooltip');
        if (dataItem) {
          const bulletSprite = dataItem.bullets[0].get('sprite');
          bulletSprite.hover();
          previousBulletSprites.push(bulletSprite);
        }
      });
    }
    cursor.events.on('cursormoved', cursorMoved);

    // Populate data
    // const abc = [
    //   {
    //     upside_percent: 10,
    //     consensus_score: 14,
    //     name: 'ABC',
    //     number_of_ratings: '1',
    //     consensus_recommendation: 'buy',
    //   },
    //   {
    //     upside_percent: 5,
    //     consensus_score: 3,
    //     name: 'ABC',
    //     number_of_ratings: '1',
    //     consensus_recommendation: 'buy',
    //   },
    //   {
    //     upside_percent: -10,
    //     consensus_score: 8,
    //     name: 'ABC',
    //     number_of_ratings: '1',
    //     consensus_recommendation: 'buy',
    //   },
    //   {
    //     upside_percent: -6,
    //     consensus_score: 5,
    //     name: 'ABC',
    //     number_of_ratings: '1',
    //     consensus_recommendation: 'buy',
    //   },
    // ];

    // series.data.setAll(abc);
    series.data.setAll(data);

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    series.appear(1000);

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, []);

  return <div id="concensus-recommendation-chart" className="w-100 g-height-500" />;
}

ConsensusRecommendationChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      stock_id: PropTypes.string,
      upside_percent: PropTypes.number,
      consensus_score: PropTypes.number,
      number_of_ratings: PropTypes.number,
      consensus_recommendation: PropTypes.string,
    }),
  ),
};

export default ConsensusRecommendationChart;
