import axios from 'axios';
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import ModalDialog from '../../../../components/ModalDialog';
import TableSearchBar from '../../../../components/TableSearchBar';
import classes from '../../portfolio.module.css';
import AddUpdateBrokerModal from './AddUpdateBrokerModal';
import { changeDefaultBroker, deleteFetchData } from './portfolioBrokerSlice';
import APIErrorHandler from '../../../../components/APIErrorHandler';

const { CancelToken } = axios;

function BrokersModal({ show, setShow }) {
  const dispatch = useDispatch();
  const source = CancelToken.source();
  const brokers = useSelector((state) => state.portfolioBroker.brokers);
  const result = useSelector((state) => state.portfolioBroker.result);
  const selectedPortfolio = useSelector((state) => state.portfolio.selected);
  const folder = selectedPortfolio.id;
  const [filteredList, setFilteredList] = useState([]);
  const [filteredByCountrylist, setFilteredByCountrylist] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('SG');
  const [searchVal, setSearchVal] = useState('');
  const [defaultBrokerId, setDefaultBrokerId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editingBroker, setEditingBroker] = useState({});
  const [deleteModal, setDeleteModal] = useState({ show: false, type: '' });
  const [notificationMessage, setNotificationMessage] = useState('');
  const error = useSelector((state) => state.portfolioBroker.err);

  useEffect(() => {
    const result = brokers.filter((broker) => broker.Country === selectedCountry);
    setFilteredByCountrylist(result);
    setFilteredList(result);

    const defaultBroker = result.find((broker) => broker.DefaultBroker === 1);
    if (defaultBroker) {
      setDefaultBrokerId(defaultBroker.Id);
    } else {
      setDefaultBrokerId(null);
    }
    setLoading(false);
  }, [brokers, selectedCountry]);

  useEffect(() => {
    setNotificationMessage(result);
  }, [result]);

  useEffect(() => {
    if (notificationMessage && notificationMessage.message) {
      toast(notificationMessage.message, {
        type: notificationMessage.success ? 'success' : 'error',
      });
    }
  }, [notificationMessage]);

  const handleClose = () => {
    setShow({ show: false, type: '' });
  };

  const handleCloseFormModal = () => {
    setShow({ show: true, type: 'brokers' });
    setEditingBroker({});
  };

  const handleOpenBrokerFormModal = () => {
    setShow({ show: true, type: 'brokersForm' });
  };

  const editBroker = (broker) => {
    setEditingBroker(broker);
  };

  useEffect(() => {
    if (editingBroker?.Id) {
      handleOpenBrokerFormModal();
    }
  }, [editingBroker]);

  const makeBrokerDefault = (brokerId) => {
    setLoading(true);
    const params = {
      new_default_broker: brokerId,
      country: selectedCountry,
      folder,
      cancelToken: source.token,
    };
    dispatch(changeDefaultBroker(params));
  };

  const handleDeleteBroker = useCallback((brokerId, BrokerName) => {
    setDeleteModal({ show: true, type: 'DeleteDialog', brokerId, BrokerName });
  }, []);

  const handleCloseModal = useCallback(() => {
    setDeleteModal({ show: false, type: '' });
  }, []);

  const confirmDeleteBroker = useCallback(() => {
    const params = {
      brokerId: deleteModal.brokerId,
      folder,
      cancelToken: source.token,
    };
    dispatch(deleteFetchData(params));
    handleCloseModal();
  }, [dispatch, folder, handleCloseModal, deleteModal.brokerId, source.token]);

  // Clear state when modal is closed
  const resetState = () => {};
  useEffect(() => {
    if (!show.show) {
      resetState();
    }
  }, [show.show]);

  return (
    <>
      <Modal
        show={show.show && show.type === 'brokers'}
        centered
        dialogClassName={`g-text-size-14 ${classes.brokerModalWidth}`}
        scrollable
        onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Brokers List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ToastContainer transition={Slide} />
          <div className="row mb-3 d-flex justify-content-between">
            <div className="col-4 d-flex align-items-center">
              <span className="g-mr-10">Country:</span>
              <DropdownButton
                title={`${selectedCountry === 'SG' ? 'Singapore' : 'Malaysia'}`}
                variant="secondary"
                size="lg"
                onSelect={setSelectedCountry}>
                <Dropdown.Item eventKey="SG">Singapore</Dropdown.Item>
                <Dropdown.Item eventKey="MY">Malaysia</Dropdown.Item>
              </DropdownButton>
            </div>

            <div className="col-4 d-flex justify-content-end">
              <Button className="btn btn-lg" variant="light" onClick={handleOpenBrokerFormModal}>
                <i className="fa-thin fa-user-plus g-mr-5" />
                Add Broker
              </Button>
            </div>
          </div>
          {error && <APIErrorHandler error={error} />}
          <div className="overflow-x-auto">
            <table className="table table-hover table-responsive">
              <thead className="table-light">
                <tr>
                  <th>
                    <TableSearchBar
                      columnTitle="Broker Name"
                      placeholder="Search Name"
                      setFiltered={setFilteredList}
                      listToSearch={filteredByCountrylist}
                      setSetSearchVal={setSearchVal}
                      searchKeys={['BrokerName']}
                    />
                  </th>
                  <th>{selectedCountry === 'SG' ? 'Commission Rate(%)' : 'Brokerage Rate (%)'}</th>
                  <th>Clearing Rate (%)</th>
                  {selectedCountry === 'SG' && (
                    <>
                      <th>SGX Access Fee(%) </th>
                      <th>GST(%)</th>
                      <th>Min Commission(SGD)</th>
                    </>
                  )}
                  {selectedCountry === 'MY' && (
                    <>
                      <th>Stamp Duty (%)</th>
                      <th>GST (%)</th>
                      <th>Min Commission (MYR)</th>
                      <th>Max Clearing Charge (MYR)</th>
                      <th>Max Stamp Duty (MYR)</th>
                    </>
                  )}
                  <th />
                  <th />
                </tr>
              </thead>
              <tbody>
                {filteredList.length > 0 &&
                  filteredList.map((broker, index) => (
                    <tr key={index}>
                      <td>{broker.BrokerName}</td>
                      <td>{broker.CommissionRate}</td>
                      <td>{broker.ClearingRate}</td>
                      {selectedCountry === 'SG' && (
                        <>
                          <td>{broker.SGXAccessFee}</td>
                          <td>{broker.GST}</td>
                          <td>{broker.MinCommission}</td>
                        </>
                      )}
                      {selectedCountry === 'MY' && (
                        <>
                          <td>{broker.StampDuty}</td>
                          <td>{broker.GST}</td>
                          <td>{broker.MinCommission}</td>
                          <td>{broker.MaxClearingCharge}</td>
                          <td>{broker.MaxStampDuty}</td>
                        </>
                      )}
                      <td className="g-width-100">
                        <OverlayTrigger
                          placement="top"
                          key={`editBroker_${broker.Id}`}
                          overlay={<Tooltip>Edit broker</Tooltip>}>
                          <span
                            role="button"
                            className="g-ml-5 g-mr-5 icon"
                            onClick={() => editBroker(broker)}>
                            <i className="fa-light fa-pen-to-square" />
                          </span>
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement="top"
                          key={`delete_${broker.id}`}
                          overlay={<Tooltip>Delete Broker</Tooltip>}>
                          <span
                            role="button"
                            className="g-ml-5 g-mr-5 icon"
                            onClick={() => handleDeleteBroker(broker.Id, broker.BrokerName)}>
                            <i className="fa-light fa-square-xmark" />
                          </span>
                        </OverlayTrigger>
                      </td>
                      <td>
                        {broker.DefaultBroker === 1 || broker.DefaultBroker ? (
                          <div>
                            <i className="fa-solid fa-star" style={{ color: 'green' }} />
                          </div>
                        ) : (
                          <button
                            className={classes.starButton}
                            onClick={() => makeBrokerDefault(broker.Id)}
                            type="button"
                            disabled={loading}>
                            <i className="fa-thin fa-star" />
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={show.show && show.type === 'brokersForm'}
        centered
        dialogClassName={`g-text-size-14 ${classes.brokerFormModalWidth}`}
        onExit={handleCloseFormModal}
        onHide={handleCloseFormModal}>
        <AddUpdateBrokerModal
          showBrokerModal={show}
          setShowBrokerModal={setShow}
          editingBroker={editingBroker}
        />
      </Modal>
      <ModalDialog
        show={deleteModal.show && deleteModal.type === 'DeleteDialog'}
        handleClose={handleCloseModal}
        title="Delete Broker"
        body={`Are you sure you want to delete broker ${deleteModal.BrokerName} ?`}
        handleSave={confirmDeleteBroker}
        saveLabel="Delete"
        saveVariant="danger"
      />
    </>
  );
}

BrokersModal.propTypes = {
  show: PropTypes.object,
  setShow: PropTypes.func,
};

export default BrokersModal;
