import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Button, Alert } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import { createUser } from './signUpSlice';
import styles from './signup.module.css';
import SocialLoginComponent from '../SocialLogin';

const { CancelToken } = axios;

function SignUpForm({ country, promoCode, checkCaptcha, siteKey }) {
  const RECAPTCHA_FAILED_MESSAGE = 'Error with reCAPTCHA. Please try again.';

  const dispatch = useDispatch();
  const source = CancelToken.source();
  const reCaptchaRef = useRef(null);
  const data = useSelector((state) => state.signUp.data);
  const loading = useSelector((state) => state.signUp.loading);
  const queryParameters = new URLSearchParams(window.location.search);
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [agree, setAgree] = useState(false);
  const [validated, setValidated] = useState(false);
  const [submitLabel, setSubmitLabel] = useState('Sign Up');
  const [couponCode, setCouponCode] = useState('');
  const [passwordView, setPasswordView] = useState(false);
  const [captchaError, setCaptchaError] = useState();

  const getRecaptchaToken = async () => {
    try {
      return await reCaptchaRef.current.executeAsync();
    } catch (error) {
      return '';
    }
  };

  const registerUser = async () => {
    let captchaToken = '';

    if (checkCaptcha) {
      captchaToken = await getRecaptchaToken();
      reCaptchaRef.current?.reset();
      if (!captchaToken) {
        setCaptchaError(RECAPTCHA_FAILED_MESSAGE);
        return;
      }
    }

    dispatch(
      createUser({
        userid: userName,
        email,
        password,
        country,
        promoCode,
        couponCode,
        captchaToken,
        cancelToken: source.token,
      }),
    );
  };

  const resetFields = () => {
    setUserName('');
    setPassword('');
    setEmail('');
    setAgree(false);
    setCaptchaError();
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity()) {
      setCaptchaError();
      registerUser();
    }
    event.stopPropagation();

    setValidated(true);
  };

  useEffect(() => {
    if (data.success && data.userid) {
      resetFields();
    }
    return () => {};
  }, [data]);

  useEffect(() => {
    const hasCouponCode = queryParameters.get('coupon_code')?.length > 0;

    if (hasCouponCode || promoCode) {
      setSubmitLabel('Sign Up for Free');
    }
    if (hasCouponCode) {
      setCouponCode(queryParameters.get('coupon_code'));
    }
  }, [queryParameters]);

  return (
    <>
      <Modal.Title className="text-center g-mb-30">
        <h3 id="signUpTitle">Sign Up</h3>
      </Modal.Title>

      {(captchaError || data?.message) && (
        <Alert variant="danger">
          <strong>Errors encountered on sign up:</strong>
          <ul className="mb-0">
            {data?.message &&
              data.message.constructor === Array &&
              data.message.map((message, i) => (
                <li key={i}>{message.replace('address 1', 'address').replace('Id', 'Username')}</li>
              ))}
            {(captchaError || data?.message.constructor !== Array) && (
              <li>{captchaError || data?.message}</li>
            )}
          </ul>
        </Alert>
      )}

      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Control
            size="lg"
            type="text"
            placeholder="Username"
            onChange={(e) => setUserName(e.target.value)}
            onBlur={(e) => setUserName(e.target.value.trim())}
            value={userName}
            required
          />
          <Form.Control.Feedback type="invalid">Please set your username</Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Control
            size="lg"
            type="email"
            placeholder="Email Address"
            onChange={(e) => setEmail(e.target.value)}
            onBlur={(e) => setEmail(e.target.value.trim())}
            value={email}
            required
          />
          <Form.Control.Feedback type="invalid">
            Please input a valid email address
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3 position-relative">
          <Form.Control
            size="lg"
            type={passwordView ? 'text' : 'password'}
            placeholder="Password"
            className="form-control"
            onChange={(e) => setPassword(e.target.value)}
            onBlur={(e) => setPassword(e.target.value.trim())}
            minLength={8}
            value={password}
            required
            aria-describedby="passwordHelpBlock"
          />
          <span
            className="position-absolute float-end text-muted"
            onClick={() => {
              setPasswordView(!passwordView);
            }}
            style={{ top: '11px', right: '10px' }}>
            <span className={passwordView ? '' : 'd-none'}>
              <i className="fa-light fa-eye " />
            </span>
            <span className={passwordView ? 'd-none' : ''}>
              <i className="fa-light fa-eye-slash " />
            </span>
          </span>
          <Form.Text id="passwordHelpBlock" muted>
            Your password must be at least 8 characters.
          </Form.Text>
          <Form.Control.Feedback type="invalid">Please set your password.</Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="position-relative mb-3">
          <Form.Check
            required
            name="agree_terms"
            id="userRegistrationTnC"
            label={
              <label className="text-muted g-text-size-14 text-start" htmlFor="userRegistrationTnC">
                I agree to the{' '}
                <a
                  href="https://www.alphainvestholdings.com/privacy-policy.html"
                  target="_blank"
                  rel="noreferrer">
                  Privacy Policy
                </a>{' '}
                and ShareInvestor&apos;s{' '}
                <a href="/membership_terms" target="_blank">
                  Membership Terms and Conditions
                </a>
                .
              </label>
            }
            onChange={() => setAgree(!agree)}
            isInvalid={validated && !agree}
            feedback="Please agree to the Terms and Conditions"
            feedbackType="invalid"
          />
        </Form.Group>
        <Button variant="primary" type="submit" className="text-center w-100" disabled={loading}>
          {submitLabel}
          <span className={loading ? '' : 'd-none'}>
            <i className="fa-solid fa-circle-notch fa-spin icon-sm ms-2" />
          </span>
        </Button>
      </Form>

      <div className={`text-center mt-4 mb-4 pt-2 pb-2 ${styles.orSigninWith}`}>
        <span>OR SIGN UP WITH</span>
      </div>

      <SocialLoginComponent />

      {checkCaptcha && (
        <ReCAPTCHA
          ref={reCaptchaRef}
          className="d-inline-block"
          theme="dark"
          size="invisible"
          sitekey={siteKey}
        />
      )}

      <div className="text-muted text-center my-3">
        Already have an account? <a href="/user/login">Sign in</a>
      </div>
    </>
  );
}

export default SignUpForm;

SignUpForm.propTypes = {
  country: PropTypes.string,
  promoCode: PropTypes.string,
  checkCaptcha: PropTypes.bool,
  siteKey: PropTypes.string,
};
