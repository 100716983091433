import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import am5themes_Micro from '@amcharts/amcharts5/themes/Micro';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import { v4 as uuidv4 } from 'uuid';

function FactsheetStackBarChart({ data }) {
  const id = uuidv4();
  useLayoutEffect(() => {
    const root = am5.Root.new(id, {
      tooltipContainerBounds: {
        top: 50,
        right: 100,
        bottom: 50,
        left: 100,
      },
    });
    root.setThemes([am5themes_Micro.new(root)]);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        layout: root.verticalLayout,
      }),
    );

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    const xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: 'date_time',
        renderer: am5xy.AxisRendererX.new(root, {
          cellStartLocation: 0.1,
          cellEndLocation: 0.9,
        }),
        tooltip: am5.Tooltip.new(root, {}),
      }),
    );

    xAxis.data.setAll([data.data]);

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        renderer: am5xy.AxisRendererY.new(root, {}),
        calculateTotals: true,
      }),
    );
    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    function makeSeries(name, fieldName, color, stacked) {
      const series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          stacked,
          name,
          xAxis,
          yAxis,
          valueYField: fieldName,
          fill: am5.color(color),
          categoryXField: 'date_time',
        }),
      );

      series.columns.template.setAll({
        tooltipText: `{name}\n{${fieldName}_actual.formatNumber('#.0a')}`,
        width: am5.percent(100),
        tooltipY: am5.percent(10),
      });

      series.data.setAll([data.data]);

      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      series.appear();
    }
    Object.values(data.splitter).forEach((split) => {
      const reverseSplit = [...split].reverse();
      reverseSplit.forEach((dataObj, index) => {
        if (index === 0) {
          makeSeries(dataObj.name, dataObj.field, dataObj.color, false);
        } else {
          makeSeries(dataObj.name, dataObj.field, dataObj.color, true);
        }
      });
    });

    return () => {
      root.dispose();
    };
  }, []);

  return <div id={id} className="g-height-300" />;
}

FactsheetStackBarChart.propTypes = {
  data: PropTypes.shape({
    data: PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    ),
    splitter: PropTypes.objectOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          color: PropTypes.string,
          field: PropTypes.string,
          name: PropTypes.string,
        }),
      ),
    ),
  }),
};

export default FactsheetStackBarChart;
