import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Container, Row, Col, Table } from 'react-bootstrap';
import styles from './membership.module.css';
import { selectPaymentHistories, fetchPaymentHistory } from './membershipSlice';

function PaymentHistory() {
  const paymentHistories = useSelector(selectPaymentHistories);
  const dispatch = useDispatch();
  const { CancelToken } = axios;

  useEffect(() => {
    const source = CancelToken.source();
    dispatch(fetchPaymentHistory({ cancelToken: source.token }));
  }, []);

  return (
    <Container className={styles.membershipContainer}>
      <Row>
        <Col>
          <div>
            <h5>Payment History</h5>
            <hr className="mt-2" />
          </div>
        </Col>
      </Row>
      <Row className={styles.sectionGap}>
        <Col className="text-center pt-2">
          <div className="table-responsive">
            <Table
              hover
              className={`table table-hover align-middle ${styles.paymentHistoryTable}`}
              size="sm">
              <thead className="table-light align-middle">
                <tr>
                  <th className="text-start">Invoice Number</th>
                  <th className="text-start">Package Name</th>
                  <th className="text-start">Transaction Date</th>
                  <th className="text-start">Paid Amount</th>
                  <th className="text-center">Status</th>
                  <th className="text-center">Download Invoice</th>
                </tr>
              </thead>
              <tbody>
                {paymentHistories.map((invoice) => {
                  return (
                    <tr key={invoice.id}>
                      <td className="text-start">{invoice.id}</td>
                      <td className="text-start">
                        <div className={styles.monthText}>
                          <b>{invoice.package_name}</b>
                        </div>
                        <div className={styles.smallNote}>{invoice.period}</div>
                      </td>
                      <td className="text-start">{invoice.trans_date}</td>
                      <td className="text-start">{invoice.paid_amount}</td>
                      <td className="text-center">
                        <span className={`${styles.primaryBadge} align-self-center`}>
                          {invoice.status}
                        </span>
                        {invoice.remaining && (
                          <div className={`${styles.smallNote} mt-1`}>
                            {invoice.remaining} months remaining
                          </div>
                        )}
                      </td>
                      <td className="text-center">
                        {invoice.download_link ? (
                          <a
                            href={`/membership/payment_history_invoice?data=${invoice.download_link}`}
                            target="_blank"
                            rel="noreferrer">
                            <i className="fa-light fa-file-invoice-dollar fa-xl" />
                          </a>
                        ) : (
                          <div>-</div>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default PaymentHistory;
