import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { fetchData } from './priceDownloadSlice';
import PriceDownloadTable from './PriceDownloadTable';
import MarketSelectDropdown from '../../../components/MarketSelectDropdown';
import Loader from '../../../components/Loader';

const { CancelToken } = axios;

function PriceDownloadMain({ props }) {
  const dispatch = useDispatch();
  const source = CancelToken.source();
  const data = useSelector((state) => state.priceDownload.data);
  const loading = useSelector((state) => state.priceDownload.loading);
  const selectedMarket = useSelector((state) => state.selectedMarket.selectedMarket);

  useEffect(() => {
    if (selectedMarket != null) {
      dispatch(
        fetchData({
          market: selectedMarket,
          cancelToken: source.token,
        }),
      );

      const url = new URL(window.location);
      url.pathname = `/${selectedMarket.toUpperCase()}/price_download`;
      window.history.pushState({}, document.title, url);
    }
    return () => {};
  }, [selectedMarket]);

  return (
    <div>
      <div className="row align-items-center g-lg-mb-30 g-mb-20">
        <div className="col-lg-8">
          <h3 className="g-mb-5 g-lg-mb-0">Price Download</h3>
        </div>
        <div className="col-lg-4 d-flex justify-content-lg-end justify-content-start" />
      </div>
      <div className="divider-h w-100 g-mb-20 g-lg-mb-30" />

      <MarketSelectDropdown
        market={props.market}
        variant="light"
        validMarkets={props.availableMarkets}
      />

      <Loader visible={loading} className="g-height-800" />
      {data?.daily?.length > 0 && <PriceDownloadTable data={data} market={selectedMarket} />}
    </div>
  );
}

export default PriceDownloadMain;
