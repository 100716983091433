import React from 'react';
import PropTypes from 'prop-types';

import { createLoadableComponent } from '../../../utils/Loadable';
import styles from './DividendAnalysis.module.css';

const PriceChart = createLoadableComponent(() => import('../../../components/PriceChart'));

export default function DividendPayoutChart({ counter, isREIT, dividendData }) {
  const chartDataURL = '/api/v1/charts/factsheet_overview_chart_data';
  const chartOptions = {
    showZoomOptions: true,
    showChartTypeOptions: true,
    hasIntradayData: false,
    showTimeIntervalOptions: true,
    showRangeSlider: true,
    defaultChartType: 'line',
    markerData: dividendData,
    zoom: '5y',
    periodicity: {
      label: '1 Day',
      value: {
        base: 'year',
        multiplier: 5,
        periodicity: { period: 1, interval: 1, timeUnit: 'day' },
      },
    },
    chartClasses: 'g-height-400 g-lg-height-450 g-mb-30 g-lg-mb-60',
  };

  const divTitle = `${isREIT ? 'Distribution' : 'Dividend'}`;
  return (
    <div className="g-pb-30">
      <h4 className="g-mb-22">{`${divTitle} Payout Chart`}</h4>
      <p className="g-mb-40">{`Historical ${divTitle} Payout Based on Ex-Date`}</p>
      <PriceChart counter={counter} dataURL={chartDataURL} options={chartOptions} />
    </div>
  );
}

DividendPayoutChart.propTypes = {
  counter: PropTypes.string,
  isREIT: PropTypes.bool,
  dividendData: PropTypes.array,
};
