import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from 'react-bootstrap/Table';
import { ToastContainer, toast, Slide } from 'react-toastify';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Pagination from '../../../components/Pagination';
import CompanyNameLink from '../../../components/StockInfoPopup/CompanyNameLink';
import { createLoadableComponent } from '../../../utils/Loadable';
import { addSelectedStocks, removeSelectedStocks } from './marketScreenerSlice';
import 'react-toastify/dist/ReactToastify.css';
import { clearMessage } from '../../Portfolio/Analytics/Transactions/transactionSlice';
import AddUpdateTransactionModal from '../../Portfolio/Analytics/Transactions/AddUpdateTransactionModal';

const RECORDS_PER_PAGE = 30;
const StockInfoPopup = createLoadableComponent(() => import('../../../components/StockInfoPopup'));

function ScreenerResultTable({ criteriaConfig }) {
  const dispatch = useDispatch();
  const screeningData = useSelector((state) => state.marketScreener.screeningData);
  const screeningLoading = useSelector((state) => state.marketScreener.screeningLoading);
  const [tableData, setTableData] = useState([]);
  const [pagination, setPagination] = useState({});
  const [showTransactionModal, setShowTransactionModal] = useState({
    show: false,
    type: 'addToPortfolio',
  });
  const transactionResult = useSelector((state) => state.transaction.result);
  const [notificationMessage, setNotificationMessage] = useState('');

  const handleAddToPortfolio = (e, counter, name) => {
    e.preventDefault();
    setShowTransactionModal({
      show: true,
      type: 'addToPortfolio',
      watchlistCounter: { counter, name },
    });
  };

  useEffect(() => {
    if (Object.keys(screeningData).length === 0 || !screeningData.success) {
      setTableData([]);
      return;
    }

    if (screeningData?.results?.length > 0) {
      const dataLength = screeningData.results.length;
      const newPagination = {
        currentPage: 1,
        rowOffset: 0,
        currentPageRows: dataLength > RECORDS_PER_PAGE ? RECORDS_PER_PAGE : dataLength,
        totalRows: dataLength,
        totalPages: Math.ceil(dataLength / RECORDS_PER_PAGE),
      };
      setTableData(screeningData.results.slice(0, newPagination.currentPageRows));
      setPagination(newPagination);
    }
  }, [screeningData]);

  useEffect(() => {
    setNotificationMessage(transactionResult);
  }, [transactionResult]);

  useEffect(() => {
    if (notificationMessage && notificationMessage.message) {
      toast(notificationMessage.message, {
        type: notificationMessage.success ? 'success' : 'error',
        onClose: () => dispatch(clearMessage()),
      });
    }
  }, [notificationMessage]);

  const handlePageChange = (newPage) => {
    if (!newPage || pagination.currentPage === newPage) {
      return;
    }

    if (screeningData?.results.length > 0) {
      const newPagination = { ...pagination };
      newPagination.currentPage = newPage;
      newPagination.rowOffset = (newPage - 1) * RECORDS_PER_PAGE;
      const newOffsetTableData = screeningData.results.slice(newPagination.rowOffset);
      newPagination.currentPageRows =
        newOffsetTableData.length > RECORDS_PER_PAGE ? RECORDS_PER_PAGE : newOffsetTableData.length;
      setTableData(newOffsetTableData.slice(0, newPagination.currentPageRows));
      setPagination(newPagination);
    }
  };

  const formatAttributeHeading = (key) => {
    let k = key;
    let year = '';
    if (k.match(/CAGR/)) {
      k = k.slice(0, -2);
    }
    // Sliced off to get the year. Original value:"faTotalShareholderReturns3Y"
    if (k.match(/faTotalShareholderReturns/)) {
      year = k.slice(-2, -1);
      k = k.slice(0, -2);
    }
    const attrConfig = criteriaConfig[k];
    if (attrConfig) {
      let { title } = attrConfig;
      if (k.match(/CAGR/)) {
        title = attrConfig.display_name;
      }
      if (k.match(/faTotalShareholderReturns/)) {
        title = attrConfig.display_name;
        title += year ? ` (${year} Years)` : '';
      }
      if (k.match(/historical/i)) {
        title += ' (Historical)';
      }
      if (['valueRevenue', 'valueNetEarnings', 'valueNetDebt', 'valueFreeCashFlow'].includes(k)) {
        title += " ('000)";
      }
      if (attrConfig.form_tmpl.match(/perc/)) {
        title += ' [%]';
      }

      return title;
    }
    if (k.match(/_/)) {
      const tokens = k.split('_');
      k = `${tokens[0]}(${tokens.slice(1).join(',')})`;
    }

    return k;
  };

  // Handle when checkbox is checked or unchecked
  const selectStockHandler = (event) => {
    if (event.target.checked) {
      dispatch(addSelectedStocks({ stock: { code: event.target.value, isStock: 'true' } }));
    } else {
      dispatch(removeSelectedStocks({ stock: event.target.value }));
    }
  };

  const renderNoDataMessage = (message) => (
    <div className="text-center g-mt-60">
      <div className="g-mb-20">
        <i className="fa-kit fa-solid-table-list-circle-exclamation fa-3x text-gray-500" />
      </div>
      <h3>{message}</h3>
    </div>
  );

  if (screeningLoading) {
    return null;
  }

  if (
    Object.keys(screeningData).length === 0 ||
    (screeningData.success && screeningData.results.length === 0 && tableData.length === 0)
  ) {
    const message =
      Object.keys(screeningData).length === 0 ? 'No data' : 'No stocks match your criteria';
    return renderNoDataMessage(message);
  }

  return (
    <>
      {tableData.length > 0 && Object.keys(screeningData).length > 0 && screeningData.success && (
        <>
          <div className="table-responsive">
            <Table>
              <thead className="table-light">
                <tr>
                  <th>&nbsp;</th>
                  <th>Code</th>
                  <th>Name</th>
                  <th>Rem</th>
                  <th>Last Done</th>
                  <th>Vol</th>
                  {Object.keys(screeningData.results[0].attributes || []).map((attributeKey) => (
                    <th key={attributeKey}>{formatAttributeHeading(attributeKey)}</th>
                  ))}
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((stock) => (
                  <tr key={stock.symbol}>
                    <td>
                      <div>
                        <label>
                          <input
                            type="checkbox"
                            name="counter_select[]"
                            value={stock.counter}
                            onChange={selectStockHandler}
                          />
                        </label>
                      </div>
                    </td>
                    <td>{stock.symbol}</td>
                    <td>
                      <CompanyNameLink
                        counter={stock.market_counter}
                        name={stock.counter_name}
                        showStockInfoPopUp
                      />
                    </td>
                    <td>{stock.remarks}</td>
                    <td>{stock.last_done}</td>
                    <td>{stock.volume}</td>
                    {Object.keys(screeningData.results[0].attributes || {}).map((attributeKey) => (
                      <td key={attributeKey}>{stock.attributes[attributeKey]?.value_f || ''}</td>
                    ))}
                    <td>
                      <OverlayTrigger placement="top" overlay={<Tooltip>Add to Portfolio</Tooltip>}>
                        <span
                          role="button"
                          onClick={(e) =>
                            handleAddToPortfolio(e, stock.counter, stock.counter_name)
                          }
                          className="g-ml-5 g-mr-5 icon">
                          <i className="fa-light fa-square-plus" />
                        </span>
                      </OverlayTrigger>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <Pagination
            rowOffset={pagination.rowOffset}
            currentPageRows={pagination.currentPageRows}
            totalRows={pagination.totalRows}
            currentPage={pagination.currentPage}
            totalPages={pagination.totalPages}
            handlePageChange={handlePageChange}
          />
          <AddUpdateTransactionModal
            show={showTransactionModal}
            setShow={setShowTransactionModal}
          />
          <ToastContainer transition={Slide} />
          <StockInfoPopup />
        </>
      )}
    </>
  );
}

export default ScreenerResultTable;
