import React, { useState, useRef } from 'react';
import { Button, Form, Alert, Col, Input } from 'react-bootstrap';
import md5 from 'md5';
import axios from 'axios';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import * as yup from 'yup';
import { Formik, Form as FormikForm } from 'formik';
import api from '../../utils/api';
import styles from './userlogin.module.css';
import ForgotPasswordComponent from './ForgotPasswordComponent';
import SocialLoginComponent from '../../components/SocialLogin';

function UserLoginComponent({
  redirect = '',
  site_key = '',
  perform_check = true,
  setIsModalOpen,
  error = '',
  showForgetPasswordPage = false,
}) {
  const [showResetPasswordPage, setShowResetPasswordPage] = useState(showForgetPasswordPage);
  const [loading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(error || '');
  const reCaptchaRef = useRef(null);

  const LOGIN_URL = '/user/do_login';
  const SERVER_ERROR_FAILED_MESSAGE =
    'Failed to login. Please contact us if you are unable to login.';
  const RECAPTCHA_FAILED_MESSAGE =
    'Failed to verify that you are a human. Please refresh page and try again.';

  const { CancelToken } = axios;

  const schema = yup.object().shape({
    username: yup.string().trim().required('Username is required'),
    password: yup.string().trim().required('Password is required'),
  });

  const getRecaptchaToken = async () => {
    try {
      return await reCaptchaRef.current.executeAsync();
    } catch (error) {
      return '';
    }
  };

  const showSignUpPopup = () => {
    /* Close modal if it is inside a modal. */
    if (typeof setIsModalOpen === 'function') {
      setIsModalOpen(false);
    }
    document.getElementById('signup')?.click();
  };

  const doLogin = async (values) => {
    setIsLoading(true);

    /* Get grecaptcha */
    let captchaToken = '';
    if (perform_check) {
      captchaToken = await getRecaptchaToken();
      if (!captchaToken) {
        setErrorMessage(RECAPTCHA_FAILED_MESSAGE); // Recaptcha failed.
        return;
      }
    }

    api
      .post(
        LOGIN_URL,
        {
          username: values.username,
          password: values.password.trim().toLowerCase(),
          password_m: md5(values.password.trim().toLowerCase()).toLowerCase(),
          recaptcha_token: captchaToken,
          rememberMe: values.rememberMe,
        },
        {
          CancelToken,
        },
      )
      .then((response) => {
        if (response.data.success) {
          if (redirect) {
            window.location.replace(redirect);
          } else {
            window.location.replace(window.location.origin);
          }
        } else {
          setErrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        setErrorMessage(`${SERVER_ERROR_FAILED_MESSAGE} ${error.message}`);
      })
      .then(() => {
        setIsLoading(false);
        reCaptchaRef.current?.reset();
      });
  };

  return (
    <>
      {showResetPasswordPage ? (
        <ForgotPasswordComponent
          site_key={site_key}
          setShowResetPasswordPage={setShowResetPasswordPage}
          perform_check={perform_check}
        />
      ) : (
        <Formik
          validationSchema={schema}
          initialValues={{ username: '', password: '', rememberMe: false }}
          onSubmit={doLogin}>
          {({ handleSubmit, handleChange, values, touched, errors }) => (
            <FormikForm noValidate onSubmit={handleSubmit}>
              <h4 className="text-center">Sign In</h4>

              {errorMessage && (
                <Alert variant="danger" className="g-text-size-15">
                  <i className="fa fa-solid fa-triangle-exclamation fa-fw" /> <b>Error: </b>
                  {errorMessage}
                </Alert>
              )}

              <Form.Group as={Col} className="mt-4">
                <Form.Control
                  name="username"
                  type="text"
                  placeholder="Username"
                  value={values.username}
                  className={styles.signInInput}
                  onChange={handleChange}
                  isInvalid={touched.username && !!errors.username}
                />
                <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} className="mt-4">
                <Form.Control
                  name="password"
                  type="password"
                  placeholder="Password"
                  value={values.password}
                  className={styles.signInInput}
                  onChange={handleChange}
                  isInvalid={touched.password && !!errors.password}
                />
                <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
              </Form.Group>
              <div className="d-flex justify-content-between mt-3 pt-1">
                <Form.Group className="mb-2 pb-2 text-center">
                  <Form.Check
                    inline
                    id="remember-me"
                    name="rememberMe"
                    label="Remember me"
                    type="checkbox"
                    className={`d-flex gap-2 justify-content-center align-items-center me-2 align-middle ${styles.rememberMeLabel}`}
                    checked={values.rememberMe}
                    onChange={handleChange}
                  />
                </Form.Group>
                {perform_check && (
                  <ReCAPTCHA
                    ref={reCaptchaRef}
                    className="d-inline-block"
                    theme="dark"
                    size="invisible"
                    sitekey={site_key}
                  />
                )}
                <Button
                  variant="link"
                  className={styles.forgotPasswordLink}
                  onClick={() => setShowResetPasswordPage(true)}>
                  Forgot Password?
                </Button>
              </div>
              <div className="text-center">
                {loading ? (
                  <Button
                    variant="success"
                    type="button"
                    className="w-100 d-inline-flex justify-content-center align-items-center "
                    name="submit"
                    disabled
                    onClick={() => {}}>
                    <div className="spinner-border spinner-border-sm text-light me-2" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                    Signing In...
                  </Button>
                ) : (
                  <Button
                    variant="success"
                    type="button"
                    className={`w-100 d-inline-flex justify-content-center align-items-center ${styles.signInButton}`}
                    name="submit"
                    onClick={handleSubmit}>
                    Sign in
                  </Button>
                )}
              </div>
              <div className={`text-center mt-4 mb-4 pt-2 pb-2 ${styles.orSigninWith}`}>
                <span>OR SIGN IN WITH</span>
              </div>
              <SocialLoginComponent />
              <div className={`text-center m-4 p-1 ${styles.signupRow}`}>
                Don&apos;t have an account?{' '}
                <Button onClick={showSignUpPopup} className={styles.signupButton} variant="link">
                  Sign Up
                </Button>
              </div>
            </FormikForm>
          )}
        </Formik>
      )}
    </>
  );
}

UserLoginComponent.propTypes = {
  redirect: PropTypes.string,
  site_key: PropTypes.string,
  perform_check: PropTypes.bool,
  setIsModalOpen: PropTypes.func,
  showForgetPasswordPage: PropTypes.bool,
};

export default UserLoginComponent;
