import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styles from './significantShareholders.module.css';

import NotableInvestorPortfolio from '../../Home/NotableInvestorPortfolio';
import ShareholderSearchBar from './ShareholderSearchBar';

function SignificantShareholders({ country }) {
  const [keyword, setKeyword] = useState('');

  const handleSearchChange = (e) => {
    e.preventDefault();
    const searchKey = e.target.value;
    setKeyword(searchKey);
  };

  const handleSubmit = (factsetId) => {
    window.location.href = `/significant_shareholders/ownership_by_shareholder?shareholder_id=${factsetId}`;
  };

  return (
    <>
      <h3 className="g-mb-30">Significant Shareholders</h3>
      <div className={`row justify-content-center g-mb-50 ${styles.searchBarContainer}`}>
        <div className="col-lg-5">
          <ShareholderSearchBar
            placeholder="Search shareholder name to view their portfolio"
            searchInput={keyword}
            handleChange={handleSearchChange}
            handleSubmit={handleSubmit}
            autoFocus={false}
          />
        </div>
      </div>
      <div className={styles.investorContainer}>
        <NotableInvestorPortfolio country={country} skipTitle />
      </div>
    </>
  );
}

SignificantShareholders.propTypes = {
  country: PropTypes.string,
};

export default SignificantShareholders;
