import { createSlice } from '@reduxjs/toolkit';
import api from '../../utils/api';

const worldIndicesPricesSlice = createSlice({
  name: 'worldIndicesPrices',
  initialState: {
    completed: false,
    data: [],
    err: null,
  },
  reducers: {
    worldIndicesPricesFetchStart(state, action) {
      state.completed = false;
    },
    worldIndicesPricesFetchSuccess(state, action) {
      state.data = action.payload.data;
      state.completed = true;
    },
    worldIndicesPricesFetchFail(state, action) {
      state.err = action.payload.message;
      state.completed = false;
    },
  },
});

export default worldIndicesPricesSlice.reducer;

const {
  worldIndicesPricesFetchStart,
  worldIndicesPricesFetchSuccess,
  worldIndicesPricesFetchFail,
} = worldIndicesPricesSlice.actions;

export const fetchData =
  ({ cancelToken }) =>
  (dispatch) => {
    dispatch(worldIndicesPricesFetchStart());
    api
      .get(`prices/world_indices_prices.json`, { cancelToken })
      .then(({ data }) => {
        dispatch(worldIndicesPricesFetchSuccess({ data }));
      })
      .catch((error) => {
        dispatch(worldIndicesPricesFetchFail(error));
      });
  };
