import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

function AlertNotification({ alertSetting, time, handleAlertViewDetails }) {
  return (
    <div className="g-text-size-14 text-dark">
      <div className="g-mb-10">
        <strong>Alert notification</strong>
      </div>
      <p>
        Your alert for <strong>{alertSetting.instName}</strong> ({alertSetting.alertContent}) was
        triggered on <strong>{dayjs(time).format('D MMM YYYY')}</strong> at{' '}
        <strong>{dayjs(time).format('H:mm')}</strong>.
      </p>
      <button
        type="button"
        className="btn btn-dark g-text-size-14 p-1"
        onClick={(e) => {
          handleAlertViewDetails(e);
        }}>
        View details
      </button>
    </div>
  );
}

export default AlertNotification;

AlertNotification.propTypes = {
  alertSetting: PropTypes.obj,
  time: PropTypes.string,
  handleAlertViewDetails: PropTypes.func,
};
