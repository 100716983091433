// TODO: Refactor further

import { formatDecimal, formatNumberAddCommas } from '../../utils/utils';

const GST = {
  sg: 0.09,
  my: 0.08,
};

export const PLAN_LEVELS = {
  premium: 3,
  pro: 2,
  trial: 1,
  free: 0,
  loggedOut: -1,
};

const FEATURES_LABEL = {
  'real-time_sgx_market_data': 'Real Time SGX Prices',
  'real-time_ftse/st_indices': 'Real-Time FTSE/ST Indices',
  'real-time_bursa_market_data': 'Real Time Bursa Prices',
  'malaysia_real-time_stock_prices_equity': 'Real Time Bursa Prices',
  'real-time_ftse/bm_index_data': 'Real-Time FTSE/BM Index Data',
  'malaysia_real-time_stock_prices_derivative': 'Real Time Bursa Derivative Prices',
  full_market_depth: 'SGX Market Depth',
  bursa_full_market_depth: 'Bursa Market Depth',
};

const getMonthlyPrice = (totalPrice, period) => {
  return (totalPrice / period).toFixed(2);
};

const getFilteredFeatures = (data, plan, period, features, priceType) => {
  return Object.keys(features)
    .filter((key) => features[key])
    .map((feature) => {
      let featureKey = feature;

      if (
        priceType === 'institution' &&
        data.membership[plan][`period${period}`].features[`${feature}_inst`]
      ) {
        featureKey = `${feature}_inst`;
      }

      return featureKey;
    });
};

const getFeatureDisplayName = (addOnKey, priceType) => {
  let key = addOnKey;
  if (priceType === 'institution') {
    key = addOnKey.replace(/_inst$/, '');
  }
  return FEATURES_LABEL[key];
};

const getFeaturePrice = (data, plan, period, feature) => {
  if (!data.membership[plan]) {
    return null;
  }
  const result = data.membership[plan][`period${period}`].features[feature].price;
  return parseFloat(result) ? parseFloat(result) : 0;
};

const getLocalCurrency = (country) => {
  return country === 'my' ? 'RM' : 'S$';
};

const calculateBasePriceFor = (data, plan, period) => {
  const platformFee = getFeaturePrice(data, plan, period, 'platform_fee');
  const dataFee = getFeaturePrice(data, plan, period, 'data_fee');
  return platformFee + dataFee;
};

const calculateMonthlyPriceForFeature = (data, plan, period, feature) => {
  return getMonthlyPrice(getFeaturePrice(data, plan, period, feature), period);
};

const calculateLoyaltyDisount = (data, plan, period, discount) => {
  const platformFee = getFeaturePrice(data, plan, period, 'platform_fee');
  const discountAmt = (discount / 100) * platformFee;
  return parseFloat(discountAmt);
};

const calculateTotalPrice = (data, plan, period, addOns, loyaltyDiscount, couponDiscAmt) => {
  const basePlan = calculateBasePriceFor(data, plan, period);
  const totalAddOns = addOns.reduce((accumulator, feature) => {
    return accumulator + getFeaturePrice(data, plan, period, feature);
  }, 0);

  const loyaltyDiscountAmt =
    loyaltyDiscount > 0 ? calculateLoyaltyDisount(data, plan, period, loyaltyDiscount) : 0;
  const totalPrice = basePlan - loyaltyDiscountAmt - couponDiscAmt + totalAddOns;
  return parseFloat(totalPrice) ? parseFloat(totalPrice) : 0;
};

const getPlanID = (data, plan, period) => {
  const result = data.membership[plan][`period${period}`].id;
  return result;
};

const getAddOnsID = (data, plan, period, addOns) => {
  const ids = [];
  addOns.forEach((feature) =>
    ids.push(data.membership[plan][`period${period}`].features[feature].id),
  );
  return ids;
};

export const getCurrentPlanLevel = (currentMembership) => {
  if (!currentMembership) {
    return PLAN_LEVELS.loggedOut;
  }

  switch (currentMembership.toLowerCase()) {
    case 'premium':
    case 'station':
      return PLAN_LEVELS.premium;
    case 'webpro':
      return PLAN_LEVELS.pro;
    case 'trial':
      return PLAN_LEVELS.trial;
    default:
      return PLAN_LEVELS.free;
  }
};

export const formatPrice = (price) => {
  const formattedNumber = formatDecimal(price, 2, true);
  return formatNumberAddCommas(formattedNumber);
};

const displayGSTPercentage = (country) => {
  return GST[country] * 100;
};

const calculateGST = (country, price) => {
  return (price * GST[country]).toFixed(2);
};

const calculatePriceWithGST = (country, price) => {
  return (price * (1 + GST[country])).toFixed(2);
};

const calculateMonthlyPriceForPlan = (data, plan, period, country) => {
  const price = getMonthlyPrice(calculateBasePriceFor(data, plan, period), period);
  return calculatePriceWithGST(country, price);
};

export {
  getFeaturePrice,
  getLocalCurrency,
  calculateBasePriceFor,
  calculateMonthlyPriceForPlan,
  calculateMonthlyPriceForFeature,
  calculateTotalPrice,
  getPlanID,
  getAddOnsID,
  calculateGST,
  displayGSTPercentage,
  calculatePriceWithGST,
  calculateLoyaltyDisount,
  getFilteredFeatures,
  getFeatureDisplayName,
};
