import React, { useState, useMemo } from 'react';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import FactsheetCollapseHeader from './FactsheetCollapseHeader';
import FactsheetCollapseTableRow from './FactsheetCollapseTableRow';
import FactsheetStackBarChartRow from './FactsheetStackBarChartRow';
import FactsheetAttachmentRow from './FactsheetAttachmentRow';
import FixedHeaderTable from '../FixedHeaderTable';

function FactsheetCollapseTable({
  tableData,
  showSparklines,
  stackBarChartData = [],
  stackBarChartTitle = '',
  isConsensus,
  tableID,
  showStackBarChart,
  toggleFactsheetNav,
}) {
  const [showAll, setShowAll] = useState(false);
  const headers = useMemo(() => tableData.find((row) => !row.is_sectionTitle), [tableData]);
  const tableProps = {
    id: tableID,
  };

  const handleToggleShowAll = () => {
    setShowAll((_showAll) => !_showAll);
  };

  if (
    tableData.length === 0 ||
    (headers && headers.values.length === 0) ||
    (headers && !Array.isArray(headers.values)) // this is for case where related attachments as header
  ) {
    return null;
  }

  const renderTable = useMemo(
    () =>
      tableData.map((rowData, index) => {
        const id = uuidv4();
        return rowData.is_attachment ? (
          <FactsheetAttachmentRow key={id} rowData={rowData} showSparklines={showSparklines} />
        ) : (
          <FactsheetCollapseTableRow
            rowData={rowData}
            valuesLength={headers.values.length}
            key={id}
            showAll={showAll}
            showSparklines={showSparklines}
            isConsensus={isConsensus}
          />
        );
      }),
    [tableData, showSparklines, stackBarChartData, stackBarChartTitle, isConsensus, showAll],
  );

  return (
    <FixedHeaderTable
      header={
        <FactsheetCollapseHeader
          headers={headers.values}
          showAll={showAll}
          toggleShowAll={handleToggleShowAll}
          showSparklines={showSparklines}
          isConsensus={isConsensus}
        />
      }
      body={
        <tbody>
          {stackBarChartData && stackBarChartData.length > 0 && (
            <FactsheetStackBarChartRow
              showChart={showStackBarChart}
              showSparklines={showSparklines}
              rowData={stackBarChartData}
              stackBarChartData={stackBarChartData}
              stackBarChartTitle={stackBarChartTitle}
            />
          )}
          {renderTable}
        </tbody>
      }
      handleScrollY={toggleFactsheetNav}
      tableContainerID={`${tableID}Container`}
      tableProps={tableProps}
    />
  );
}

FactsheetCollapseTable.propTypes = {
  tableData: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        title_indent_space: PropTypes.number,
        show_growth: PropTypes.bool,
        is_attachment: PropTypes.bool,
        values: PropTypes.oneOfType([
          PropTypes.arrayOf(
            PropTypes.shape({
              date_time_f: PropTypes.string,
              period_f: PropTypes.string,
              period_on_percent_growth: PropTypes.string,
              value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
              value_f: PropTypes.string,
            }),
          ),
          PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
        ]),
      }),
    ),
    PropTypes.objectOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          title_indent_space: PropTypes.number,
          show_growth: PropTypes.bool,
          values: PropTypes.arrayOf(
            PropTypes.shape({
              date_time_f: PropTypes.string,
              period_f: PropTypes.string,
              period_on_percent_growth: PropTypes.string,
              value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
              value_f: PropTypes.string,
            }),
          ),
        }),
      ),
    ),
  ]),
  showSparklines: PropTypes.bool,
  stackBarChartData: PropTypes.arrayOf(
    PropTypes.shape({
      chart_details: PropTypes.shape({
        data: PropTypes.objectOf(
          PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
        ),
        splitter: PropTypes.objectOf(
          PropTypes.arrayOf(
            PropTypes.shape({
              color: PropTypes.string,
              field: PropTypes.string,
              name: PropTypes.string,
            }),
          ),
        ),
      }),
      date_time_f: PropTypes.string,
      period_f: PropTypes.string,
      fitted_values_assets: PropTypes.arrayOf(PropTypes.string),
      fitted_values_liabilities: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
  stackBarChartTitle: PropTypes.string,
  isConsensus: PropTypes.bool,
  showStackBarChart: PropTypes.bool,
  tableID: PropTypes.string,
  toggleFactsheetNav: PropTypes.func,
};

export default FactsheetCollapseTable;
