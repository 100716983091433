import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import PropTypes from 'prop-types';

import { membershipSetPriceType } from '../membershipSlice';

function PriceTypeSelect() {
  const dispatch = useDispatch();
  const url = new URL(window.location.href);
  const paramsvalue = new URLSearchParams(url.search);
  const plan = useSelector((state) => state.membership.plan) || paramsvalue.get('plan');
  const priceType = useSelector((state) => state.membership.priceType);

  const handlePriceTypeChange = (newPriceType) => {
    dispatch(membershipSetPriceType(newPriceType));
  };

  if (!plan?.includes('station')) {
    return null;
  }

  return (
    <div className="d-flex text-center g-mb-40">
      <Card
        className={`d-inline-block my-2 p-2 shadow-sm g-per-width-48 ${
          priceType === 'retail' ? 'border-success border-3' : ''
        }`}
        onClick={() => {
          handlePriceTypeChange('retail');
        }}
        type="button">
        <Card.Body className="text-center">
          <span className="d-inline-block g-mr-10">
            <h4 className="my-3">Retail</h4>
          </span>

          <OverlayTrigger
            placement="auto"
            overlay={
              <Tooltip className="tooltip_custom">
                <div className="text-start overflow-x-hidden overflow-y-scroll">
                  <p>A Retail subscriber is defined as one who meets the following criteria:</p>
                  <ul>
                    <li>subscribes for the Subscriber's Service solely in a personal capacity;</li>
                    <li>
                      uses the Subscriber's Service solely for the purpose of managing his personal
                      funds / investments;
                    </li>
                    <li>
                      is not registered or qualified as a professional with any national or state
                      exchange or regulatory body of professional association;
                    </li>
                    <li>
                      does not act in any capacity as an investment advisor or fund manager; and
                    </li>
                    <li>
                      does not distribute or provide market information or any other data from the
                      Subscriber's Service to any third party in any manner.
                    </li>
                  </ul>
                </div>
              </Tooltip>
            }>
            <span className="d-inline-block">
              <i className="fa-solid fa-circle-info" />
            </span>
          </OverlayTrigger>
        </Card.Body>
      </Card>

      <Card
        className={`d-inline-block my-2 p-2 shadow-sm g-per-width-48 ms-2 ${
          priceType === 'institution' ? 'border-success border-3' : ''
        }`}
        onClick={() => {
          handlePriceTypeChange('institution');
        }}
        type="button">
        <Card.Body className="text-center">
          <span className="d-inline-block g-mr-10">
            <h4 className="my-3">Institution</h4>
          </span>

          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip className="tooltip_custom">
                <div className="text-start overflow-x-hidden overflow-y-scroll">
                  <p>
                    An Institution subscriber is defined as any subscriber who is not a
                    Non-Professional subscriber.
                  </p>
                </div>
              </Tooltip>
            }>
            <span className="d-inline-block">
              <i className="fa-solid fa-circle-info" />
            </span>
          </OverlayTrigger>
        </Card.Body>
      </Card>
    </div>
  );
}

export default PriceTypeSelect;
