// this java script using jquery library
import { BaseTable } from './protocol';

window.getLotSizeForMarket = function(market) {
  let volume_size;
  switch (market) {
    case 'SI':
    case 'HK':
      volume_size = 1000;
      break;
    case 'MY':
    case 'BK':
      volume_size = 100;
      break;
    default:
      volume_size = 1;
  }
  return volume_size;
};

export class VisualTable extends BaseTable {
  constructor(id_, subscription_) {
    //    if (id_ == "sic_quoteMovementTable")
    //        console.log("VISUALTABLE: "+id_+" subscription: "+subscription_.fields);
    super(id_, subscription_); // BaseTable.call(this, id_, subscription_);
    if (!id_) return; // called as a prototype object
    this.updateFieldsAndClasses();
    this.rows_info = {};
  }

  static applyDynamicEffect(elem, effect_, effect_period_) {
    if (effect_) {
      if (elem instanceof jQuery) {
        elem.addClass(effect_);
      } else {
        elem.classList.add(effect_);
      }
      setTimeout(function () {
        if (elem instanceof jQuery) {
          elem.removeClass(effect_);
        } else {
          elem.classList.remove(effect_);
        }
      }, effect_period_ || 3000);
    }
  }

  /* Retrieve fields from each table head and update cellFields and cellClasses
   * so that streamed data will be inserted to the table based on these data.
   * This function should be called when creating new VisualTable or when changing
   * Columns with the same VisualTable,
   * eg. from Stocks Trading Data to Indices Trading Data (same layout but different columns)
   */
  updateFieldsAndClasses() {
    this.cellFields = []; // used for each visual cells
    this.cellClasses = [];
    const visual_fields = this.cellFields;
    const visual_classes = this.cellClasses;

    const self = this;
    // grab fields information automatically from each table head
    (function ($) {
      $.each(self.getHeaderCells(), function (index, c) {
        const temp = $(c);
        visual_fields[index] = temp.attr('field') || '';

        visual_classes[index] = temp.attr('field_class') || null;
      });

      let all_empty = true;
      $.each(self.cellFields, function (i, v) {
        if (v != '') all_empty = false;
      });

      if (all_empty && self.subscription)
        // if all empty string
        self.cellFields = self.subscription.fields;
    })(jQuery);
  }

  // manually specify the column fields
  setColumnField(fields_) {
    if (!this.cellFields || this.cellFields.length == 0 || this.cellFields.length == fields_.length)
      this.cellFields = fields_;
    else throw `incorrect fields length in setColumnField ${fields_}`;
  }

  getHeaderCells() {
    const thead = jQuery(`#${this.html_id} thead`);
    if (thead.length) {
      const rows = thead.children();
      if (rows.length) return rows.first().children();
    }
    return [];
  }

  // default implementation, if itemPos is numeric compare using numeric, otherwise use string comparsion
  beforeRowAdd(parent_node_, new_row_) {
    const retrieve_pos = function (pos_str_) {
      if (pos_str_.match(/\d+/)) return Number(pos_str_);
      return pos_str_;
    };
    const prefix_length = this.html_id.length + 1;
    const pos_part = retrieve_pos(new_row_.attr('id').substring(prefix_length));
    return jQuery.grep(jQuery('tr', parent_node_), function (r) {
      return retrieve_pos(r.id.substring(prefix_length)) > pos_part;
    });
  }

  afterRowAdd(row_) {}

  addNewRow(row_id_) {
    const table_node = jQuery(`#${this.getId()}`);

    if (table_node.length <= 0) throw `cannot find table with id ${this.getId()}`;

    let tbody_node = jQuery('>tbody:first', table_node);

    if (tbody_node.length <= 0) {
      tbody_node = jQuery('<tbody>');
      table_node.append(tbody_node);
    }

    let row = jQuery(`#${row_id_}`, tbody_node);

    if (row.length <= 0) {
      row = jQuery('<tr>');
      row.attr('id', row_id_);

      const after_row = this.beforeRowAdd(tbody_node, row);
      if (after_row && after_row.length) {
        row.insertBefore(after_row[0]);
      } else tbody_node.append(row);
      this.prepareNewRow(row);
      this.afterRowAdd(row);
    }

    return row;
  }

  onDataUpdate(updates_) {
    log(`VisualTable::onDataUpdate() - ${this.getId()}`);
    if (this.allowUpdate(updates_)) {
      const row_id = `${this.getId()}_${updates_.itemPos}`; // coins a unique row id

      // let's store the stock code and map to the row_id
      if (updates_.code) {
        const matches = updates_.code.match(/\.(\w+)$/);
        if (matches) {
          const volume_size = getLotSizeForMarket(matches[1]);
          this.rows_info[row_id] = { code: updates_.code, volume_size };
        }
      }

      const table_node = jQuery(`#${this.getId()}`);
      if (table_node.length) {
        let row = jQuery(`#${row_id}`, table_node);
        if (row.length <= 0) row = this.addNewRow(row_id);
        let change_whole_row = false;
        if ('short_name' in updates_) change_whole_row = true;
        this.updateRow(row, updates_, change_whole_row);
      } else {
        log(`VisualTable(${this.getId()}) not found.`);
      }
    }
  }

  allowUpdate(updates_) {
    return true;
  }

  afterRowUpdate(row_, updates_) {}

  prepareRowUpdate(row_, update_) {
    //    update_.delay = Math.floor(Math.random()*1001)+1;   // introduce random delay
  }

  prepareFieldUpdate(field, cell, updates_) {}

  updateRow(row_, updates_, change_whole_row) {
    this.prepareRowUpdate(row_, updates_);
    var typeValue = '';

    const self = this;
    const row_id = row_.attr('id');
    // restructure the fields default fields ['time', 'type', 'volume', 'price', 'type', 'null', 'null', 'null', 'null']
    if (row_.children().length == '9') {
      if (updates_.type == 'B' || updates_.type == 'S') {
        typeValue = self.cellFields[0];
        self.cellFields.splice(6, 2);
        self.cellFields.splice(1, 0, 'null', 'null');
      } else if (updates_.type == 'Bid') {
        typeValue = self.cellFields[0];
        self.cellFields.splice(6, 2);
        self.cellFields.splice(4, 0, 'null', 'null');
      } else if (updates_.type == 'Ask') {
        var typeValue = self.cellFields[4];
        self.cellFields.splice(4, 5);
        self.cellFields.splice(2, 0, 'null', 'null', 'null', 'null', typeValue);
      }
    }

    (function ($) {
      $.each(row_.children(), function (index, cell) {
        const field = self.cellFields[index];
        cell = $(cell);
        self.prepareFieldUpdate(field, cell, updates_);
        if (field in updates_) {
          const new_value = updates_[field];
          const u = $.extend({}, updates_, {
            oldValue: cell.data('rawValue'),
            newValue: new_value,
          });
          self.prepareCellUpdate(u, field, change_whole_row);
          self.updateCell(cell, u, field, row_id);

          cell.data('rawValue', u.newValue); // store the new updated value
        }
      });
      // revert field structure
      if (row_.children().length == '9') {
        if (updates_.type == 'B' || updates_.type == 'S') {
          typeValue = self.cellFields[5];
          self.cellFields.splice(1, 2);
          self.cellFields.splice(6, 0, 'null', 'null');
        } else if (updates_.type == 'Bid') {
          typeValue = self.cellFields[5];
          self.cellFields.splice(4, 2);
          self.cellFields.splice(6, 0, 'null', 'null');
        } else if (updates_.type == 'Ask') {
          typeValue = self.cellFields[1];
          self.cellFields.splice(2, 5);
          self.cellFields.splice(4, 0, typeValue, 'null', 'null', 'null', 'null');
        }
      }
    })(jQuery);

    this.afterRowUpdate(row_, updates_);
  }

  updateCell(cell_, update_, field_, row_id_) {
    const self = this;

    const { removeClassNames } = update_;
    const { addClassNames } = update_;
    const { dynamicEffect } = update_;
    const volume_size =
      row_id_ && this.rows_info[row_id_] ? this.rows_info[row_id_].volume_size : 1000;

    if (!update_.content) {
      if (
        (update_.type == 'Ask' || update_.type == 'Bid') &&
        this.getId() &&
        this.getId() == 'sic_quoteMovementTable' &&
        field_ === 'volume'
      ) {
        update_.newValue = update_.volume;
      }
      update_.content = self.formatField(field_, update_.newValue, update_, volume_size);
    }
    if (removeClassNames) {
      cell_.removeClass(removeClassNames);
    }
    if (addClassNames) {
      cell_.addClass(addClassNames);
    }
    if (update_.updateAction) {
      // user full customized
      update_.updateAction(cell_, update_);
    } else if (dynamicEffect) {
      // only customize the highlight effect
      cell_.html(update_.content);
      VisualTable.applyDynamicEffect(cell_, dynamicEffect, update_.dynamicEffectPeriod || 2000);
    } // simply update the value
    else {
      cell_.html(update_.content);
    }
  }

  // replace this function to give a customized row creation method
  prepareNewRow(row_) {
    //    if (this.getId() == 'sic_quoteMovementTable'){
    //        console.log("preparing new row: "+row_);
    //    }
    const self = this;
    (function ($) {
      for (let i = 0, { length } = self.cellFields; i < length; ++i) {
        const cell = $('<td>');
        row_.append(cell);
        cell.data('field', self.cellFields[i]);

        if (self.cellClasses[i]) cell.addClass(self.cellClasses[i]);
        const update = {
          content: '&nbsp;',
          index: i,
        };
        if (self.prepareNewCellUpdate) {
          // prepare updates
          self.prepareNewCellUpdate(cell, update);
        }
        const { removeClassNames } = update;
        const { addClassNames } = update;
        if (removeClassNames) {
          cell.removeClass(removeClassNames);
        }
        if (addClassNames) {
          cell.addClass(addClassNames);
        }
        cell.html(update.content);
        //        if ((self.getId() == 'sic_quoteMovementTable') && (self.cellFields[i] == 'type')){
        //            console.log("check info cellfield: "+self.cellFields[i]);
        //        }
      }
    })(jQuery);
  }

  // prepare the default content for new table cells
  prepareNewCellUpdate(cell_, index_) {}

  prepareCellUpdate(update_, field_, change_whole_row_) {}

  formatField(field, value, volume_size) {
    return value;
  }
}

export class ScrollTable extends VisualTable {
  constructor(id, subscription_, option_) {
    super(id_, subscription_); // VisualTable.call(this, id, subscription_);
    this.option = jQuery.extend({}, ScrollTable.default_options, option_ || {});
  }

  setOption(option_) {
    jQuery.extend({}, this.option, option_ || {});
  }

  beforeRowAdd(parent_node_, new_row_) {
    const self = this;
    let top_row;

    (function ($) {
      let rows = $('tr', parent_node_);

      while (rows.length >= self.option.max_row_num) {
        const remove_row = self.option.insert_at_bottom ? rows.first() : rows.last();
        remove_row.remove();
        rows = $('tr', parent_node_);
      }

      if (self.option.insert_at_bottom || $('tr', parent_node_).length <= 0) top_row = null;
      else top_row = $('tr:first', parent_node_);
    })(jQuery);

    return top_row;
  }

  onTotalPageChange() {}

  onCurrentPageChange() {}
}

ScrollTable.default_options = {
  fixed_row_num: 10,
  max_row_num: 10,
  insert_at_bottom: true,
};
