import axios from 'axios';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { fetchPricesSubtitle } from './priceTableSlice';

const { CancelToken } = axios;

function LastUpdatedAt({ currentFilters }) {
  const dispatch = useDispatch();
  const source = CancelToken.source();
  const pricesSubtitle = useSelector((state) => state.priceTable.pricesSubtitle);

  useEffect(() => {
    // make sure currentFilters had already been set before retrieval
    if (currentFilters.layout) {
      // fetch page subtitle
      dispatch(
        fetchPricesSubtitle({
          filter: currentFilters.filter,
          market: currentFilters.market,
          cancelToken: source.token,
        }),
      );
    }
  }, [
    currentFilters.market,
    currentFilters.filter,
    currentFilters.layout,
    currentFilters.folderId,
  ]);

  const renderPageTimestamp = () => {
    if (!pricesSubtitle) {
      return null;
    }

    const markets = currentFilters.market ? [currentFilters.market] : ['SGX', 'Bursa'];
    return (
      <span
        id="sic_subTitle_lastUpdate"
        className="footer-disclaimer d-inline-block g-text-size-12 sic_update">
        {markets.map((market) => {
          let pageTimestampPrefix = '';
          let pageTimestampSuffix = '';
          const quotesDelay = currentFilters.market
            ? pricesSubtitle.quotes_delay
            : pricesSubtitle.quotes_delay[market.toLowerCase()];
          const lastUpdate = currentFilters.market
            ? pricesSubtitle.last_update
            : pricesSubtitle.last_update[market.toLowerCase()];
          const lastUpdateNum = currentFilters.market
            ? pricesSubtitle.last_update_num
            : pricesSubtitle.last_update_num[market.toLowerCase()];
          const pageTimestamp = lastUpdate;

          if (Number(quotesDelay) === 1440) {
            pageTimestampPrefix = 'Price updated on ';
            pageTimestampSuffix = '. (End of trading day)';
          } else if (Number(quotesDelay) !== 0) {
            pageTimestampPrefix = currentFilters.market ? 'Updated at ' : 'counters updated at ';
            pageTimestampSuffix = `. Quotes ${quotesDelay} Minutes Delayed.`;
          } else {
            pageTimestampPrefix = currentFilters.market ? 'Updated at ' : 'counters updated at ';
            pageTimestampSuffix = '.';
          }

          return (
            <span
              className="g-mr-5"
              key={market}
              id={`sic_subTitle_lastUpdate_${market.toLowerCase()}`}
              data-lastupdatenum={lastUpdateNum}>
              {!currentFilters.market && <strong>{market} </strong>}
              {pageTimestampPrefix}
              <strong>{pageTimestamp}</strong>
              {pageTimestampSuffix}
            </span>
          );
        })}
      </span>
    );
  };

  return (
    <>
      {pricesSubtitle && pricesSubtitle.title && pricesSubtitle.title.length > 0 && (
        <span id="sic_pageSubTitle" className="h6 d-inline-block g-mb-0 g-mr-5">
          - {pricesSubtitle.title}
        </span>
      )}
      {renderPageTimestamp()}
    </>
  );
}

export default LastUpdatedAt;

LastUpdatedAt.propTypes = {
  currentFilters: PropTypes.shape({
    layout: PropTypes.string,
    market: PropTypes.string,
    filter: PropTypes.string,
    folderId: PropTypes.number,
  }),
};
