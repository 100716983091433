import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import styles from './searchQuote.module.css';

export default function SearchBar({
  placeholder,
  searchInput,
  handleChange,
  handleSubmit,
  handleShowModal,
  handleEnter,
  autoFocus,
  handleModalClose,
  type,
}) {
  return (
    <Form className={styles.searchBar} onSubmit={handleSubmit}>
      <div className={styles.searchContainer}>
        <Form.Control
          type="text"
          className={type === 'nav' ? styles.searchBarNavInput : styles.searchBarInput}
          onChange={handleChange}
          onClick={handleShowModal && handleShowModal}
          value={searchInput}
          placeholder={placeholder}
          autoFocus={autoFocus}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && handleEnter) handleEnter();
          }}
        />

        <span className="d-flex align-items-center justify-content-center">
          <span
            className={`${type === 'nav' ? styles.searchBarNavIcon : styles.searchBarIcon} ${
              handleModalClose ? styles.rightSpaceWithClose : styles.rightSpaceWithoutClose
            }`}
          />
          {handleModalClose && (
            <button
              type="button"
              className={`btn-close ${styles.closeModalBtn}`}
              aria-label="Close"
              onClick={handleModalClose}
            />
          )}
        </span>
      </div>
    </Form>
  );
}

SearchBar.propTypes = {
  placeholder: PropTypes.string,
  searchInput: PropTypes.string,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleShowModal: PropTypes.func,
  autoFocus: PropTypes.bool,
  type: PropTypes.string,
  handleEnter: PropTypes.func,
};
