import axios from 'axios';
import React, { useState, useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';

import { useSearchParams, useLocation } from 'react-router-dom';

import api from '../../../utils/api';
import { createLoadableComponent } from '../../../utils/Loadable';
import Pagination from '../../../components/Pagination';
import Loader from '../../../components/Loader';
import APIErrorHandler from '../../../components/APIErrorHandler';
import NoteSection from './NoteSection';
import { getMarketFromCounter } from '../../../utils/utils';

const TimeSalesTable = createLoadableComponent(() => import('./TimeSalesTable'));
const Statistics = createLoadableComponent(() => import('./Statistics'));
const { CancelToken } = axios;
const DATE = new Date().toISOString().split('T')[0]; // yyyy-mm-dd format

const findPairByDate = (dataArray, searchDate) => {
  const formattedSearchDate = searchDate;
  return dataArray.find((pair) => pair[1] === formattedSearchDate) || null;
};

function TimeSalesTab({ counter, toggleFactsheetNav }) {
  const source = CancelToken.source();
  const [searchParams, setSearchParams] = useSearchParams();
  const [error, setError] = useState();
  const [date, setDate] = useState(searchParams.get('date') || DATE);
  const [result, setResult] = useState([]);
  const [page, setPage] = useState('1');
  const [pagination, setPagination] = useState({});
  const [statistics, setStatistics] = useState({});
  const [recentDaysDropdown, setRecentDaysDropdown] = useState([]);
  const [isIndex, setisIndex] = useState(false);
  const [shouldStreamTimeAndSales, setShouldStreamTimeAndSales] = useState(false);
  const [precision, setPrecision] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const [isSSR, setIsSSR] = useState(true);

  useEffect(() => {
    setIsSSR(typeof document === 'undefined');

    return () => {};
  }, []);

  const handlePageChange = (newPage) => {
    if (!newPage || page === newPage) {
      return;
    }

    setSearchParams({ date, page: newPage });
    setPage(newPage);
  };

  const handleDateChange = (newDate) => {
    if (date === newDate) {
      return;
    }
    setSearchParams({ date: newDate, page: '1' });
    setDate(newDate);
    setPage('1');
  };

  useEffect(() => {
    if (!date) {
      return;
    }

    const cancelToken = source.token;
    setIsLoading(true);

    let url = `quote/${counter}/time_and_sales.json?date=${date}`;
    if (page) {
      url += `&page=${page}`;
    }

    api
      .get(url, { cancelToken })
      .then(({ data }) => {
        setResult(data.page_result);
        setPagination(data.pagination);
        setRecentDaysDropdown(data.recent_days_dropdown);
        setStatistics(data.statistics);
        setIsLoading(false);
        setisIndex(data.is_index);
        setPrecision(data.precision);
        setShouldStreamTimeAndSales(data.streaming_time_and_sales);
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
      });
  }, [date, page]);

  useEffect(() => {
    if (date !== searchParams.get('date')) {
      setDate(searchParams.get('date') || DATE);
    }
    if (page !== searchParams.get('page')) {
      setPage(searchParams.get('page') || '1');
    }
  }, [location]);

  if (isSSR) {
    return <Loader visible={isSSR} classes="g-height-800" />;
  }

  if (error) {
    return <APIErrorHandler error={error} />;
  }

  return (
    <>
      <h4 className="g-md-mb-30">Time & Sales</h4>
      <Loader visible={isLoading} />
      <div className="row">
        <div className="col-xl-6 col-12 align-items-center mb-2">
          {!isLoading && recentDaysDropdown && recentDaysDropdown.length > 0 && (
            <DateDropdownComponent
              selectedDate={
                recentDaysDropdown.length > 0 ? findPairByDate(recentDaysDropdown, date) : null
              }
              recentDaysDropdown={recentDaysDropdown}
              date={date}
              handleDateChange={handleDateChange}
            />
          )}
        </div>
        <div className="col-xl-6 justify-content-xl-end col-12 gap-2  d-flex flex-column flex-sm-row  align-items-sm-center mb-2">
          {!isLoading && <Statistics counter={counter} date={date} />}
        </div>
      </div>
      {!isLoading && result && result.length > 0 && (
        <div id="timeSalesTableContainer">
          {(getMarketFromCounter(counter) !== 'HKEX' || DATE !== date) && (
            <Pagination
              rowOffset={pagination.page_row_offset}
              currentPageRows={result.length}
              totalRows={pagination.total_rows}
              currentPage={Number(page)}
              totalPages={pagination.total_pages}
              handlePageChange={handlePageChange}
            />
          )}
          <TimeSalesTable
            counter={counter}
            referencePrice={Number(statistics.reference_price)}
            page={Number(page)}
            maxRowsPerPage={Number(pagination.rows_per_page)}
            data={result}
            isIndex={isIndex}
            shouldStreamTimeAndSales={shouldStreamTimeAndSales}
            precision={precision}
            toggleFactsheetNav={toggleFactsheetNav}
          />
          {(getMarketFromCounter(counter) !== 'HKEX' || DATE !== date) && (
            <Pagination
              rowOffset={pagination.page_row_offset}
              currentPageRows={result.length}
              totalRows={pagination.total_rows}
              currentPage={Number(page)}
              totalPages={pagination.total_pages}
              handlePageChange={handlePageChange}
            />
          )}
          <NoteSection type="time_and_sales" />
        </div>
      )}
      {!isLoading && (!result || result.length === 0) && (
        <div className="text-center">No Trades For The Day.</div>
      )}
    </>
  );
}

TimeSalesTab.propTypes = {
  counter: PropTypes.string,
  toggleFactsheetNav: PropTypes.func,
};

export default TimeSalesTab;

function DateDropdownComponent({ selectedDate, recentDaysDropdown, date, handleDateChange }) {
  return (
    <Dropdown className="g-mb-10">
      <Dropdown.Toggle variant="light" size="lg">
        {selectedDate ? selectedDate[0] : 'Select Date'}
      </Dropdown.Toggle>
      <div className="d-flex gap-2 flex-wrap">
        <Dropdown.Menu>
          {recentDaysDropdown.map((day) => (
            <Dropdown.Item
              key={day[1]}
              onClick={() => handleDateChange(day[1])}
              className={`${day[1] === date ? 'active' : ''}`}>
              {day[0]}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </div>
    </Dropdown>
  );
}

DateDropdownComponent.propTypes = {
  selectedDate: PropTypes.arrayOf(PropTypes.string),
  recentDaysDropdown: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  date: PropTypes.string.isRequired,
  handleDateChange: PropTypes.func.isRequired,
};
