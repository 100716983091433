import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Form from 'react-bootstrap/Form';

export default function CashAllocationSetting({ show, handleClose, benchmark, setBenchmark }) {
  const innerRef = useRef();
  const [value, setValue] = useState(0);
  const [thumbPosition, setThumbPosition] = useState([0, -60]);
  const [showTooltip, setShowTooltip] = useState(false);

  const repositionTooltip = (target) => {
    if (!target) {
      return;
    }

    const { width } = target.getBoundingClientRect();
    const percentage = target.value / 100;
    const x = width * percentage - width / 2;
    const y = 10;
    setThumbPosition([x, y]);
  };

  const handleSliderChange = (event) => {
    setValue(event.target.value);
    repositionTooltip(event.target);
  };

  const close = () => {
    setValue(benchmark);
    repositionTooltip(innerRef.current);
    setShowTooltip(false);
    handleClose();
  };

  const save = () => {
    repositionTooltip(innerRef.current);
    setBenchmark(value);
    handleClose();
  };

  useEffect(() => {
    if (!show) {
      setShowTooltip(false);
      return;
    }

    setValue(benchmark);
    setTimeout(() => {
      if (innerRef.current) {
        innerRef.current.focus();
        repositionTooltip(innerRef.current);
        setShowTooltip(true);
      }
    }, 300);
  }, [show]);

  return (
    <Modal show={show} centered dialogClassName="g-text-size-14" onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>Cash Allocation Management Setting</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="g-pb-30">
          <strong>Set benchmark</strong>
          <br />
          To analyze the allocation, risk, and return of a given portfolio.
        </p>
        <OverlayTrigger
          show={showTooltip}
          placement="top"
          overlay={<Tooltip id="cashAllocationSettingTooltip">{value}%</Tooltip>}
          popperConfig={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: thumbPosition,
                },
              },
            ],
          }}>
          <Form.Range
            value={value}
            onChange={(e) => handleSliderChange(e)}
            min={0}
            max={100}
            ref={innerRef}
            autoFocus
          />
        </OverlayTrigger>
        <div className="row">
          <div className="col text-start">0%</div>
          <div className="col text-end">100%</div>
        </div>
      </Modal.Body>
      <div className="col-12 g-mb-30 d-flex align-items-center justify-content-center">
        <Button variant="success" onClick={save}>
          Save
        </Button>
      </div>
    </Modal>
  );
}

CashAllocationSetting.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  setBenchmark: PropTypes.func,
  benchmark: PropTypes.number,
};
