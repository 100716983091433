import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-toastify';
import api from '../../../utils/api';

const MAX_TRY = 2;
const marketScreenerSlice = createSlice({
  name: 'marketScreener',
  initialState: {
    queueNumber: null,
    queueNumberLoading: false,
    err: null,
    analysisChartsFeatures: {},
    screeningData: {},
    screeningDataCounters: null,
    screeningLoading: false,
    screeningComplete: false,
    screeningCancelled: false,
    userTemplatesData: {},
    userTemplatesLoading: true,
    userUpdateTemplatesLoading: false,
    selectedMarket: ['sgx'],
    selectedFolder: { value: '', label: '', type: '' },
    selectedStockType: {
      stock: { name: 'Equities', checked: false },
      etf: { name: 'ETF', checked: false },
      warrant: { name: 'Warrant', checked: false },
      reit: { name: 'Reit', checked: false },
      bonds: { name: 'Bonds', checked: false },
      index: { name: 'Indices', checked: false },
    },
    addedCriterias: [],
    criteriasDropdownLabel: [],
    xmlQueryStr: null,
    selectedStocks: [],
    screeningTryCount: 0,
    invalidForms: [],
  },
  reducers: {
    screenerRequestQueueNumberStart(state, action) {
      state.queueNumber = null;
      state.err = null;
      state.queueNumberLoading = true;
    },
    screenerRequestQueueNumberSuccess(state, action) {
      state.queueNumber = action.payload.data.data;
      state.err = null;
      state.queueNumberLoading = false;
    },
    screenerRequestQueueNumberFail(state, action) {
      state.queueNumber = action.payload;
      state.queueNumberLoading = false;
    },
    screenerResetQueryData(state, action) {
      state.queueNumber = null;
      state.xmlQueryStr = null;
      state.screeningTryCount = 0;
    },
    screenerFetchResultStart(state, action) {
      state.screeningData = {};
      state.screeningDataCounters = null;
      state.err = null;
      state.screeningLoading = true;
      state.screeningCancelled = false;
      state.screeningComplete = false;
    },
    screenerFetchResultSuccess(state, action) {
      state.screeningData = action.payload.data;
      state.screeningDataCounters = action.payload.data.results?.map((s) => s.counter).join(':');
      state.analysisChartsFeatures = action.payload.data.has_analysis_charts_feature;
      state.err = null;
      state.screeningLoading = false;
      state.screeningComplete = true;
    },
    screenerFetchResultFail(state, action) {
      state.err = action.payload;
      state.screeningLoading = false;
      state.screeningComplete = true;
    },
    screenerSetTryCount(state, action) {
      state.screeningTryCount = action.payload;
    },
    screenerClearError(state, action) {
      state.err = null;
    },
    screenerCancelDataFetch(state) {
      state.screeningLoading = false;
      state.screeningCancelled = true;
      state.screeningComplete = true;
    },
    screenerSetAddedCriterias(state, action) {
      state.addedCriterias = action.payload;
    },
    screenerSetCriteriasDropdownLabel(state, action) {
      const { label, index, parentIndex } = action.payload;
      if (parentIndex !== null) {
        if (state.criteriasDropdownLabel[parentIndex].sub_criteria[index]) {
          state.criteriasDropdownLabel[parentIndex].sub_criteria[index] = label;
        } else {
          state.criteriasDropdownLabel[parentIndex].sub_criteria.push(label);
        }
      } else if (state.criteriasDropdownLabel[index]) {
        state.criteriasDropdownLabel[index].value = label;
      } else {
        state.criteriasDropdownLabel.push({ value: label, sub_criteria: [] });
      }
    },
    screenerRemoveCriteriasDropdownLabel(state, action) {
      const { index, parentIndex } = action.payload;
      if (parentIndex !== null && state.criteriasDropdownLabel[parentIndex].sub_criteria[index]) {
        state.criteriasDropdownLabel[parentIndex].sub_criteria.splice(index, 1);
      } else {
        state.criteriasDropdownLabel.splice(index, 1);
      }
    },
    screenerResetCriteriasDropdownLabel(state, action) {
      state.criteriasDropdownLabel = [];
    },
    screenerSetXmlQueryStr(state, action) {
      state.xmlQueryStr = action.payload;
    },
    screenerSetInvalidForms(state, action) {
      state.invalidForms = action.payload;
    },
    screenerSetSelectedFolder(state, action) {
      state.selectedFolder = action.payload;
    },
    screenerSetSelectedMarket(state, action) {
      state.selectedMarket = action.payload;
    },
    screenerSetSelectedStockType(state, action) {
      state.selectedStockType = action.payload;
    },
    screenerFetchUserTemplatesStart(state, action) {
      state.userTemplatesData = {};
      state.err = null;
      state.userTemplatesLoading = true;
    },
    screenerFetchUserTemplatesSuccess(state, action) {
      state.userTemplatesData = action.payload.data;
      state.err = null;
      state.userTemplatesLoading = false;
    },
    screenerFetchUserTemplatesFail(state, action) {
      state.err = action.payload;
      state.userTemplatesLoading = false;
    },
    screenerEditUserTemplatesStart(state, action) {
      state.err = null;
      state.userUpdateTemplatesLoading = true;
    },
    screenerEditUserTemplatesSuccess(state, action) {
      if (
        !action.payload.data.user_tmpl ||
        Object.keys(action.payload.data.user_tmpl).length === 0
      ) {
        // If user_tmpl is empty, remove it from the state
        const { user_tmpl, ...rest } = state.userTemplatesData;
        state.userTemplatesData = rest;
      } else {
        // Otherwise, update user_tmpl with the new data
        state.userTemplatesData = {
          ...state.userTemplatesData,
          user_tmpl: action.payload.data.user_tmpl,
        };
      }
      state.err = null;
      state.userUpdateTemplatesLoading = false;
    },
    screenerEditUserTemplatesFail(state, action) {
      state.err = action.payload;
      state.userUpdateTemplatesLoading = false;
    },
    screenerSaveTemplateStart(state, action) {
      state.err = null;
      state.userUpdateTemplatesLoading = true;
    },
    screenerSaveTemplateSuccess(state, action) {
      state.userTemplatesData = {
        ...state.userTemplatesData,
        user_tmpl: {
          ...state.userTemplatesData.user_tmpl,
          ...action.payload.data.user_tmpl,
        },
      };
      state.err = null;
      state.userUpdateTemplatesLoading = false;
      toast(action.payload.data.message, {
        type: action.payload.data.success ? 'success' : 'error',
        containerId: 'marketScreenerSlice',
      });
    },
    screenerSaveTemplateFail(state, action) {
      state.err = action.payload;
      state.userUpdateTemplatesLoading = false;
    },
    screenerAddSelectedStocks(state, action) {
      state.selectedStocks.push(action.payload);
    },
    screenerRemoveSelectedStocks(state, action) {
      const selectedStock = state.selectedStocks
        ? state.selectedStocks.filter((stock) => stock.code != action.payload)
        : [];
      state.selectedStocks = selectedStock;
    },
  },
});

export default marketScreenerSlice.reducer;

const {
  screenerRequestQueueNumberStart,
  screenerRequestQueueNumberSuccess,
  screenerRequestQueueNumberFail,
  screenerResetQueryData,
  screenerFetchResultStart,
  screenerFetchResultSuccess,
  screenerFetchResultFail,
  screenerSetTryCount,
  screenerCancelDataFetch,
  screenerSetAddedCriterias,
  screenerSetCriteriasDropdownLabel,
  screenerRemoveCriteriasDropdownLabel,
  screenerResetCriteriasDropdownLabel,
  screenerSetXmlQueryStr,
  screenerSetInvalidForms,
  screenerSetSelectedFolder,
  screenerSetSelectedMarket,
  screenerSetSelectedStockType,
  screenerFetchUserTemplatesStart,
  screenerFetchUserTemplatesSuccess,
  screenerFetchUserTemplatesFail,
  screenerEditUserTemplatesStart,
  screenerEditUserTemplatesSuccess,
  screenerEditUserTemplatesFail,
  screenerSaveTemplateStart,
  screenerSaveTemplateSuccess,
  screenerSaveTemplateFail,
  screenerAddSelectedStocks,
  screenerRemoveSelectedStocks,
  screenerClearError,
} = marketScreenerSlice.actions;

export const requestQueueNumber =
  ({ market, xmlQueryStr, cancelToken }) =>
  (dispatch) => {
    const url = `/screener/filter_by_xml.json`;
    const params = {
      xml: xmlQueryStr,
      market,
    };
    dispatch(screenerRequestQueueNumberStart());
    api
      .post(url, params, { cancelToken })
      .then(({ data }) => {
        dispatch(screenerRequestQueueNumberSuccess({ data }));
      })
      .catch((error) => {
        dispatch(screenerRequestQueueNumberFail(error));
      });
  };

export const resetQueryData = () => (dispatch) => {
  dispatch(screenerResetQueryData());
};

export const fetchScreenerData =
  ({ queueNumber, market, xmlQueryStr, tryCount, cancelToken }) =>
  (dispatch) => {
    const url = `/screener/get_market_scanner_results.json?`;
    const params = {
      queue_num: queueNumber,
      market,
    };
    const maxTry = MAX_TRY;
    const fetchResults = (cancelToken) => {
      api
        .post(url, params, { cancelToken })
        .then(({ data }) => {
          if (data.success && !data.completed) {
            console.log('processing');
            setTimeout(() => fetchResults(cancelToken), 2000);
          } else if (!data.success && data.completed) {
            if (tryCount < maxTry) {
              tryCount += 1;
              dispatch(screenerSetTryCount(tryCount));
              console.log(`Retry attempt ${tryCount}`);
              dispatch(requestQueueNumber({ market, xmlQueryStr, cancelToken }));
            } else {
              dispatch(
                screenerFetchResultFail({
                  message: data.message.replace(/Unable to process: /, ''),
                }),
              );
            }
          } else if (data.success && data.completed) {
            console.log('success');
            dispatch(screenerFetchResultSuccess({ data }));
          }
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            console.log('Request canceled', error.message);
          } else {
            dispatch(screenerFetchResultFail(error));
          }
        });
    };
    dispatch(screenerFetchResultStart());
    fetchResults(cancelToken);
  };

export const cancelFetchScreenerData = () => (dispatch) => {
  dispatch(screenerCancelDataFetch());
};

// add critirias to the form
export const setAddedCriterias =
  ({ criteria }) =>
  (dispatch) => {
    dispatch(screenerSetAddedCriterias(criteria));
  };

export const setCriteriasDropdownLabel =
  ({ label, index, parentIndex }) =>
  (dispatch) => {
    dispatch(screenerSetCriteriasDropdownLabel({ label, index, parentIndex }));
  };
export const removeCriteriasDropdownLabel =
  ({ index, parentIndex }) =>
  (dispatch) => {
    dispatch(screenerRemoveCriteriasDropdownLabel({ index, parentIndex }));
  };
export const resetCriteriasDropdownLabel = () => (dispatch) => {
  dispatch(screenerResetCriteriasDropdownLabel());
};
export const setXmlQueryStr =
  ({ xmlQueryStr }) =>
  (dispatch) => {
    dispatch(screenerSetXmlQueryStr(xmlQueryStr));
  };

export const setInvalidForms =
  ({ invalidForms }) =>
  (dispatch) => {
    dispatch(screenerSetInvalidForms(invalidForms));
  };

export const setScreenerError =
  ({ error }) =>
  (dispatch) => {
    dispatch(screenerFetchResultFail({ message: error.message }));
  };

export const removeScreenerError = () => (dispatch) => {
  dispatch(screenerClearError());
};

export const fetchUserTemplates =
  ({ cancelToken }) =>
  (dispatch) => {
    const url = `/screener/load_templates.json`;
    dispatch(screenerFetchUserTemplatesStart());
    api
      .get(url, { cancelToken })
      .then(({ data }) => {
        dispatch(screenerFetchUserTemplatesSuccess({ data }));
      })
      .catch((error) => {
        dispatch(screenerFetchUserTemplatesFail(error));
      });
  };

export const editUserTemplates =
  ({ templatesData, cancelToken }) =>
  (dispatch) => {
    const url = `/screener/save_user_tmpl_attrs.json`;
    const params = { tmpl_params: templatesData };
    dispatch(screenerEditUserTemplatesStart());
    api
      .post(url, params, { cancelToken })
      .then(({ data }) => {
        dispatch(screenerEditUserTemplatesSuccess({ data }));
      })
      .catch((error) => {
        dispatch(screenerEditUserTemplatesFail(error));
      });
  };

export const setSelectedFolder =
  ({ value, label, type }) =>
  (dispatch) => {
    dispatch(screenerSetSelectedFolder({ value, label, type }));
  };

export const setSelectedMarket =
  ({ market }) =>
  (dispatch) => {
    dispatch(screenerSetSelectedMarket(market));
  };

export const setSelectedStockType =
  ({ stockType }) =>
  (dispatch) => {
    dispatch(screenerSetSelectedStockType(stockType));
  };

export const saveTemplate =
  ({ criteria, newName, selectedTemplate, cancelToken }) =>
  (dispatch) => {
    const url = `/screener/save_template.json`;

    const params = {
      criteria,
      ...(newName && { name: newName }),
      ...(selectedTemplate && { id: selectedTemplate }),
    };

    dispatch(screenerSaveTemplateStart());
    api
      .post(url, params, { cancelToken })
      .then(({ data }) => {
        dispatch(screenerSaveTemplateSuccess({ data }));
      })
      .catch((error) => {
        dispatch(screenerSaveTemplateFail(error));
      });
  };

// handle column selection
export const addSelectedStocks =
  ({ stock }) =>
  (dispatch) => {
    dispatch(screenerAddSelectedStocks(stock));
  };
export const removeSelectedStocks =
  ({ stock }) =>
  (dispatch) => {
    dispatch(screenerRemoveSelectedStocks(stock));
  };
