import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { selectStockSuggestion, chooseStock, fetchData, selectStockCodes } from './stocksComparisonSlice';
import { MAX_STOCKS } from './StocksComparisonConstants';

const { CancelToken } = axios;

function StocksComparisonSuggestion() {

  const useTypedDispatch = () => useDispatch();
  const dispatch = useTypedDispatch();
  const source = CancelToken.source();

  const suggestionList = useSelector(selectStockSuggestion);
  const stockCodes = useSelector(selectStockCodes);

  const handleStockSelection = (counter) => {

    /* Don't choose if already selected. */
    if (Object.values(stockCodes).includes(counter.toLowerCase())) {
      return;
    }

    const columnIndex = getNextStockCompareSlot()
    dispatch(chooseStock({ code: counter.toLowerCase(), columnIndex: columnIndex }));

    /* Call fetch data */
    dispatch(fetchData({ columnIndex: columnIndex, stockList: [counter], stockType: "stocks", cancelToken: source.token }));
  }

  const getNextStockCompareSlot = () => {
    const keys = Object.keys(stockCodes);
    const numericKeys = keys.map(key => parseInt(key));
    const rangeSet = new Set([...Array(MAX_STOCKS).keys()])
    numericKeys.forEach(num => {
      rangeSet.delete(num);
    })
    const missingNumber = [...rangeSet];
    if (missingNumber.length === 0) {
      return 4;
    } else {
      return Math.min(...rangeSet);
    }
  }

  return (
    <div>
      {suggestionList && suggestionList.length > 0 && (
        <>
          <p>Suggested Stocks:</p>
          <div className="d-flex flex-wrap gap-2 g-mb-30">
            {suggestionList.map((suggestion) => {
              return (
                <span
                  key={suggestion.name}
                  id={suggestion.counter}
                  role="button"
                  className="badge badge-outlined text-bg-gray-600"
                  onClick={() => handleStockSelection(suggestion.counter)}>
                  <span className="text-primary g-mr-3">{suggestion.name}</span>
                  {suggestion.market}
                </span>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}

export default StocksComparisonSuggestion;
