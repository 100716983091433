$(document).on('turbolinks:load', function () {
  function footerDropdownToggle() {
    if ($(window).width() >= 992) {
      $('.footer-nav .dropdown-toggle').removeAttr('data-bs-toggle');
    } else {
      $('.footer-nav .dropdown-toggle').attr('data-bs-toggle', 'dropdown');
    }
  }

  $(function () {
    footerDropdownToggle();
    $(window).on('resize', function () {
      footerDropdownToggle();
    });

    $('#footer-view-more').on('click', function () {
      $('.footer-nav .dropdown-menu li.hide_menu').toggleClass('d-block');
      $(this).find('.dropdown-toggle-icon').toggleClass('far fa-circle-plus fa-circle-minus');
    });

    if (['/my', '/sg'].includes(window.location.pathname)) {
      const promoCode = Cookies.read('promo_code');
      if (promoCode === 'MOOFEST3MTHS') {
        setTimeout(function () {
          $('#signup').trigger('click');
          setTimeout(function () {
            if ($('#signUpTitle').length > 0) {
              $(
                '<p style="color: red;">Congratulations! You are eligible for a 90-day free trial. Sign up now!</p>',
              ).insertAfter('#signUpTitle');
            }
          }, 200); // Reduced delay to ensure the element is rendered
        }, 5000);
      }
    }

    // banner for transition popup
    var searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get('show_transition_banner') === '1') {
      setTimeout(function () {
        $('#overlay-splash').show();
        $('#image-splash-popup').fadeIn();
      }, 2000);

      $('#overlay-splash').click(function () {
        $('#overlay-splash').hide();
        $('#image-splash-popup').fadeOut();
      });
    }
  });
});
