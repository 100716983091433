import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { createFetchData, updateFetchData } from './portfolioBrokerSlice';

const { CancelToken } = axios;

function AddUpdateBrokerModal({ showBrokerModal, setShowBrokerModal, editingBroker }) {
  const dispatch = useDispatch();
  const source = CancelToken.source();
  const [error, setError] = useState('');

  const selectedPortfolio = useSelector((state) => state.portfolio.selected);
  const selectedLayout = useSelector((state) => state.portfolio.selectedLayout);
  const [editMode, setEditMode] = useState(false);
  const [validated, setValidated] = useState(false);
  const formRef = useRef(null); // Create a ref

  const [brokerName, setBrokerName] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('SG');
  const [commissionRate, setCommissionRate] = useState(''); // brokerage rate for MY
  const [stampDuty, setStampDuty] = useState('');
  const [gst, setGst] = useState('');
  const [clearingRate, setClearingRate] = useState('');
  const [maxClearingCharge, setMaxClearingCharge] = useState('');
  const [minCommission, setMinCommission] = useState('');
  const [maxStampDuty, setMaxStampDuty] = useState('');
  const [sgxAccessFee, setSgxAccessFee] = useState('');
  const [brokerToEdit, setBrokerToEdit] = useState('');

  useEffect(() => {
    const isEditMode = showBrokerModal.type === 'brokersForm' && editingBroker && editingBroker.Id;
    setEditMode(isEditMode);
    setBrokerToEdit(editingBroker);
  }, [showBrokerModal.type, editingBroker]);

  const resetState = () => {
    setBrokerName('');
    setSelectedCountry('SG');
    setCommissionRate('');
    setStampDuty('');
    setGst('');
    setClearingRate('');
    setMaxClearingCharge('');
    setMinCommission('');
    setMaxStampDuty('');
    setSgxAccessFee('');
    setEditMode(false);
    setBrokerToEdit('');
  };

  useEffect(() => {
    if (editMode) {
      setBrokerName(brokerToEdit.BrokerName);
      setSelectedCountry(brokerToEdit.Country);
      setCommissionRate(brokerToEdit.CommissionRate);
      setStampDuty(brokerToEdit.StampDuty);
      setGst(brokerToEdit.GST);
      setClearingRate(brokerToEdit.ClearingRate);
      setMaxClearingCharge(brokerToEdit.MaxClearingCharge);
      setMinCommission(brokerToEdit.MinCommission);
      setMaxStampDuty(brokerToEdit.MaxStampDuty);
      setSgxAccessFee(brokerToEdit.SGXAccessFee);
    }
  }, [brokerToEdit]);

  const handleCloseFormModal = () => {
    setShowBrokerModal({ show: true, type: 'brokers' });
  };

  const handleSave = (e) => {
    e.preventDefault();
    const form = formRef.current; // Access the form using ref
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      return;
    }

    const params = {
      folder: selectedPortfolio.id,
      brokerName,
      commissionRate,
      clearingRate,
      sgxAccessFee,
      stampDuty,
      gst,
      minCommission,
      maxClearingCharge,
      maxStampDuty,
      country: selectedCountry,
      cancelToken: source.token,
    };

    if (editMode && editingBroker && Object.keys(editingBroker).length && editingBroker.Id) {
      params.brokerId = brokerToEdit.Id;
      dispatch(updateFetchData(params));
    } else {
      dispatch(createFetchData(params));
    }
    resetState();
    handleCloseFormModal();
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>{editMode ? 'Edit' : 'Add'} Broker</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate validated={validated} onSubmit={handleSave} ref={formRef}>
          <div className="row">
            <Form.Group className="col-6 g-mb-20">
              <Form.Label>
                <b>Broker Name</b>
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  size="lg"
                  type="text"
                  value={brokerName}
                  placeholder="Enter broker name"
                  required
                  onChange={(e) => setBrokerName(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Broker Name is required
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group className="col-6 g-mb-20">
              <Form.Label>
                <b>Broker Country</b>
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  as="select"
                  disabled={editMode}
                  name="country"
                  value={selectedCountry}
                  size="lg"
                  onChange={(e) => setSelectedCountry(e.target.value)}>
                  <option value="SG">Singapore</option>
                  <option value="MY">Malaysia</option>
                </Form.Control>
              </InputGroup>
            </Form.Group>
          </div>
          <div className="row">
            <Form.Group className="col-6 g-mb-20">
              <Form.Label>
                <b>{selectedCountry === 'SG' ? 'Commission Rate(%)' : 'Brokerage Rate (%)'}</b>
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  size="lg"
                  type="number"
                  value={commissionRate}
                  placeholder="0.00"
                  required
                  onChange={(e) => setCommissionRate(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  {selectedCountry === 'SG' ? 'Commission Rate(%)' : 'Brokerage Rate (%)'} is
                  required
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group className="col-6 g-mb-20">
              <Form.Label>
                <b>Clearing Rate (%)</b>
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  size="lg"
                  type="number"
                  value={clearingRate}
                  placeholder="0.00"
                  required
                  onChange={(e) => setClearingRate(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Clearing Rate is required
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </div>
          <div className="row">
            <Form.Group className="col-6 g-mb-20">
              <Form.Label>
                <b>GST(%)</b>
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  size="lg"
                  type="number"
                  value={gst}
                  placeholder="0.00"
                  required
                  onChange={(e) => setGst(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">GST is required</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            {selectedCountry === 'MY' && (
              <Form.Group className="col-6 g-mb-20">
                <Form.Label>
                  <b>Stamp Duty (%)</b>
                </Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    size="lg"
                    type="number"
                    value={stampDuty}
                    placeholder="0.00"
                    required
                    onChange={(e) => setStampDuty(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Stamp Duty is required
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            )}
            {selectedCountry === 'SG' && (
              <Form.Group className="col-6 g-mb-20">
                <Form.Label>
                  <b>SGX Access Fee (%)</b>
                </Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    size="lg"
                    type="number"
                    value={sgxAccessFee}
                    placeholder="0.00"
                    required
                    max="0.9999"
                    step="0.0001"
                    onChange={(e) => setSgxAccessFee(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    SGX Access Fee is required and can not be more than or equal 1
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            )}
          </div>
          <div className="row">
            <Form.Group className={`col-${selectedCountry === 'SG' ? '6' : '4'} g-mb-20`}>
              <Form.Label>
                <b>{selectedCountry === 'SG' ? 'Min Commission (SGD)' : 'Min Commission (MYR)'}</b>
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  size="lg"
                  type="number"
                  value={minCommission}
                  placeholder="0.00"
                  required
                  onChange={(e) => setMinCommission(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Min Commission is required
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            {selectedCountry === 'MY' && (
              <>
                <Form.Group className="col-4 g-mb-20">
                  <Form.Label>
                    <b>Max Clearing Charge (MYR)</b>
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      size="lg"
                      type="number"
                      value={maxClearingCharge}
                      placeholder="0.00"
                      required
                      onChange={(e) => setMaxClearingCharge(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Max Clearing Charge is required
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Form.Group className="col-4 g-mb-20">
                  <Form.Label>
                    <b>Max Stamp Duty (MYR)</b>
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      size="lg"
                      type="number"
                      value={maxStampDuty}
                      placeholder="0.00"
                      required
                      onChange={(e) => setMaxStampDuty(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Max Stamp Duty is required
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </>
            )}
          </div>
          <div className="g-tb-20 g-mb-20 text-center">
            <Button variant="primary" type="submit" className="text-center">
              {editMode ? 'Edit' : 'Create'}
            </Button>
            <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
          </div>
        </Form>
      </Modal.Body>
    </>
  );
}

AddUpdateBrokerModal.propTypes = {
  setShowBrokerModal: PropTypes.func,
  editingBroker: PropTypes.object,
};

export default AddUpdateBrokerModal;
