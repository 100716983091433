import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import CompanyNameLink from '../StockInfoPopup/CompanyNameLink';
import './consensusEstimatesTable.css';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function ConsensusEstimatesTable({ template, data, showStockInfoPopUp }) {
  if (!data || (data && data.length === 0)) {
    return null;
  }

  return (
    <div
      className={`${template === 'home' ? 'consensusEstimatesTableHome' : ''} table-responsive `}>
      <Table hover className="text-center align-middle">
        <thead className="table-light align-middle">
          <tr>
            <th className="text-center">No</th>
            <th className="text-start">Company Name</th>
            <th className="text-center">Consensus Recommendation</th>
            <th className="text-center">
              Consensus Rating <sup>b</sup>
            </th>
            <th className="text-center">Number of Ratings </th>
            <th className="text-center">Last Done Price </th>
            <th className="text-center">Average Target Price</th>
            <th className="text-center">Potential Upside (%)</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {data.map((stock, index) => {
            return (
              <tr id={stock.stock_code} key={stock.stock_code}>
                <td>{index + 1}</td>
                <td className="text-start">
                  <CompanyNameLink
                    counter={stock.stock_code}
                    name={stock.stock_name}
                    showStockInfoPopUp={showStockInfoPopUp}
                  />
                </td>
                <td>
                  <span
                    className={
                      stock.consensus_recommendation
                        ? `ratingsColor-${stock.consensus_recommendation.toLowerCase()} badge badge-outlined`
                        : ''
                    }>
                    {stock.consensus_recommendation}
                  </span>
                </td>
                <td>{stock.consensus_rating}</td>
                <td>{stock.number_of_ratings}</td>
                <td>{stock.last_done_price}</td>
                <td>{stock.average_target_price}</td>
                <td
                  className={`${
                    stock.potential_upside_percent > 0 ? 'text-primary' : 'text-danger'
                  }`}>
                  {stock.potential_upside_percent}
                </td>
                <td>
                  <OverlayTrigger
                    placement="left"
                    overlay={<Tooltip>View Company Estimates Details</Tooltip>}>
                    <a
                      href={`/quote/${stock.stock_code}/consensus_estimates`}
                      className="text-dark">
                      <i className="fa-light fa-xl fa-magnifying-glass-chart" />
                    </a>
                  </OverlayTrigger>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}

ConsensusEstimatesTable.propTypes = {
  template: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      stock_code: PropTypes.string,
      stock_name: PropTypes.string,
      consensus_recommendation: PropTypes.string,
      consensus_rating: PropTypes.string,
      number_of_ratings: PropTypes.string,
      last_done_price: PropTypes.string,
      average_target_price: PropTypes.string,
      potential_upside_percent: PropTypes.string,
    }),
  ),
  showStockInfoPopUp: PropTypes.bool,
};
ConsensusEstimatesTable.defaultProps = {
  showStockInfoPopUp: false,
};
export default ConsensusEstimatesTable;
