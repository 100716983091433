import React, { useState } from 'react';
import PropTypes from 'prop-types';

import SignUpModal from './SignUpModal';

function SignUpMain({ title, country, checkCaptcha, siteKey }) {
  const [showSignUpModal, setShowSignUpModal] = useState(false);

  const showModal = () => {
    setShowSignUpModal(true);
  };
  return (
    <>
      {!title && (
        <div>
          <button id="signup" className="btn btn-primary" onClick={showModal} type="button">
            Get Started
          </button>

          <SignUpModal
            country={country}
            showModal={showSignUpModal}
            setShowModal={setShowSignUpModal}
            checkCaptcha={checkCaptcha}
            siteKey={siteKey}
            redirectToInit={`/${country}`}
          />
        </div>
      )}
      {title && (
        <>
          <button
            id="signup"
            className="btn btn-lg btn-outline-secondary btn-Premium text-white"
            onClick={showModal}
            type="button">
            {title}
          </button>

          <SignUpModal
            country={country}
            showModal={showSignUpModal}
            setShowModal={setShowSignUpModal}
            checkCaptcha={checkCaptcha}
            siteKey={siteKey}
            redirectToInit={`/${country}`}
            title={title}
          />
        </>
      )}
    </>
  );
}

export default SignUpMain;

SignUpMain.propTypes = {
  title: PropTypes.string,
  country: PropTypes.string,
  checkCaptcha: PropTypes.bool,
  siteKey: PropTypes.string,
};
