import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import { Button, Table } from 'react-bootstrap';
import PerformanceChart from '../PerformanceChart';
import ReturnChart from '../ReturnChart';

import { selectStockCodes, clearStockCodes, selectStockData, selectStockReadyForInit, setStockReadyForInit, fetchData } from '../stocksComparisonSlice';
import StocksComparisonRow from '../StocksComparisonRow';
import AddRemoveToStocksComparison from '../AddRemoveToStocksComparison';
import StocksComparisonSuggestion from '../StocksComparisonSuggestion';
import {
  COMPARISON_TABLE_HEADER_STOCK,
  COMPARISON_TABLE_ROWS_DATA
} from '../StocksComparisonConstants';
import { MAX_STOCKS } from '../StocksComparisonConstants';

const { CancelToken } = axios;

function StockScreen() {
  const dispatch = useDispatch();
  const source = CancelToken.source();
  const stockCodes = useSelector(selectStockCodes);
  const stockData = Object.values(useSelector(selectStockData));
  const readyForInit = useSelector(selectStockReadyForInit);
  const stockList = Object.values(stockCodes);

  const [_, setSearchParams] = useSearchParams();

  /* Stock selected. Update get params */
  useEffect(() => {
    const newSearchParams = new URLSearchParams();
    for (const key in stockCodes) {
      newSearchParams.append("code", stockCodes[key].toLowerCase());
    }
    setSearchParams(newSearchParams);
  }, [stockCodes]);

  useEffect(() => {
    /* Fetch data on init and Set suggestion on init. Init once only. */
    if (readyForInit) {
      if (Object.values(stockCodes).length > 0) {
        dispatch(fetchData({ columnIndex: "", stockList: Object.values(stockCodes), stockType: "stocks", cancelToken: source.token }));
      }
      dispatch(setStockReadyForInit(false));
    }
  }, [readyForInit]);

  const getReturnData = () => {
    const stockDataArray = Object.values(stockData);
    const stockCodesArray = Object.values(stockCodes);
    if (stockDataArray && stockDataArray.length > 0) {
      return stockDataArray.filter(stock => stockCodesArray.includes(stock.counter.toLowerCase()));
    }
    return [];
  }

  return (<div className="row d-print-none">
    <div className="col-lg-3">
      <h4>Enter Stocks</h4>
      <span className="text-muted">
        Compare up to {MAX_STOCKS} stocks
      </span>
    </div>
    <div className="col-lg-9">
      <div className="g-mb-20">
        <AddRemoveToStocksComparison screenType="stocks" />
      </div>
      <StocksComparisonSuggestion />
      <div className="text-center">
        <Button variant="secondary" onClick={() => dispatch(clearStockCodes())}>
          Clear All
        </Button>
      </div>
    </div>
    <div>
      <hr className="d-print-none my-5" />
      <h4>Result</h4>
      <Table hover>
        <tbody>
          <StocksComparisonRow screenType="stocks" columnHeader={COMPARISON_TABLE_HEADER_STOCK}></StocksComparisonRow>
          <tr className="table-no-hover">
            <td colSpan="7" className="p-0">
              <div className="row g-mt-30 g-mb-30">
                <div className="div-col col-lg-6">
                  <div className="div-col-head bg-light fw-bold">PERFORMANCE</div>
                  {stockList && stockList.length > 0 ? (
                    <div className="g-height-400">
                      <PerformanceChart countersToCompare={stockList.map(stock => stock.toUpperCase())} />
                    </div>
                  ) : (
                    <div className="g-height-40 g-mt-20 text-center">No data available</div>
                  )}
                </div>
                <div className="div-col col-lg-6">
                  <div className="div-col-head bg-light fw-bold">RETURN</div>
                  {stockData && stockData.length > 0 ? (
                    <div className="g-height-400">
                      <ReturnChart returnData={getReturnData()} />
                    </div>
                  ) : (
                    <div className="g-height-40 g-mt-20 text-center">No data available</div>
                  )}
                </div>
              </div>
            </td>
          </tr>
          <StocksComparisonRow screenType="stocks" columnHeader={COMPARISON_TABLE_ROWS_DATA}></StocksComparisonRow>
        </tbody>
      </Table>
    </div>
  </div>)
};

export default StockScreen;
