import React from 'react';

import wipImg from 'images/errors-wip.jpg';

export default function WIP() {
  return (
    <img
      alt="This page is currently working in progress"
      className="wip w-100 h-100 img-fluid mx-auto d-block"
      src={wipImg}
    />
  );
}
