import React from 'react';
import { useSelector } from 'react-redux';

import { LastUpdatedAt } from '../../components/PriceTable';

function PageTitle() {
  const currentFilters = useSelector((state) => state.stockPrices.filters);

  return (
    <>
      Stock Prices
      {currentFilters.market && <LastUpdatedAt currentFilters={currentFilters} />}
    </>
  );
}

export default PageTitle;
