import React, { useLayoutEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import * as am5radar from '@amcharts/amcharts5/radar';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import am5themes_Responsive from '@amcharts/amcharts5/themes/Responsive';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

function ConsensusEstimatesGaugeChart({ recommendation, chartType = "factsheet" }) {
  if (!recommendation || recommendation === '-') {
    return 'No data available';
  }
  const id = uuidv4();
  const chartRecommendationConfig = {
    buy: { value: 90, color: '#2aa300' },
    overweight: { short_name: 'OVER\nWEIGHT', value: 75, color: '#7fd03b' },
    hold: { value: 50, color: '#f9c301' },
    underweight: { short_name: 'UNDER\nWEIGHT', value: 25, color: '#f96903' },
    sell: { value: 10, color: '#f82735' },
  };

  const chartRecommendation = chartRecommendationConfig[recommendation.toLowerCase()];
  let chartConfig;
  if (chartType === 'factsheet') {
    chartConfig = {
      rootClass: 'g-height-500',
    };
  } else if (chartType === 'stocks_comparison') {
    chartConfig = {
      rootClass: 'g-height-50 g-lg-height-250 print-height-250',
    };
  }
  useLayoutEffect(() => {
    const root = am5.Root.new(id);
    const responsive = am5themes_Responsive.newEmpty(root);

    root.setThemes([am5themes_Animated.new(root), responsive]);

    // default chart config
    chartConfig = {
      ...chartConfig,
      container: {
        width: am5.percent(100),
        height: am5.percent(100),
      },
      chart: {
        panX: false,
        panY: false,
        startAngle: 150,
        endAngle: 390,
      },
      xAxisValueAxis: {
        maxDeviation: 0,
        min: 0,
        max: 100,
        strictMinMax: true,
      },
      animate: {
        key: 'value',
        to: chartRecommendation.value,
        duration: 800,
        easing: am5.ease.out(am5.ease.cubic),
      },
    };

    // conditional chart config
    if (chartType === 'factsheet') {
      chartConfig = {
        ...chartConfig,
        recommendationText: {
          text: `[${chartRecommendation.color}]${recommendation}`,
          textAlign: 'center',
          x: am5.p50,
          y: am5.percent(55),
          centerX: am5.p50,
          centerY: am5.p50,
          fontSize: 38,
          fontWeight: '500',
        },
        title: {
          text: `Consensus Estimation`,
          textAlign: 'center',
          x: am5.p50,
          y: am5.percent(65),
          centerX: am5.p50,
          centerY: am5.p50,
          fontSize: 20,
          fontWeight: '500',
        },
        axisRenderer: {
          innerRadius: -10,
          strokeOpacity: 1,
          strokeWidth: 35,
          strokeGradient: am5.LinearGradient.new(root, {
            rotation: 0,
            stops: [{ color: am5.color(0xfb7116) }, { color: am5.color(0x19d228) }],
          }),
          labelsTemplate: {
            visible: false,
          },
        },
        clockHand: {
          topWidth: 25,
          bottomWidth: 0,
          radius: am5.percent(110),
          innerRadius: am5.percent(102),
          pinRadius: 0,
          layer: 1,
        },
      };
    } else if (chartType === 'stocks_comparison') {
      chartConfig = {
        ...chartConfig,
        recommendationText: {
          text: `[${chartRecommendation.color}]${recommendation}`,
          textAlign: 'center',
          x: am5.p50,
          y: am5.percent(73),
          centerX: am5.p50,
          centerY: am5.p50,
          fontSize: 20,
          fontWeight: '500',
        },
        axisRenderer: {
          ticks: {
            visible: false,
            forceHidden: true,
          },
          innerRadius: -1,
          strokeOpacity: 1,
          strokeWidth: 8,
          strokeGradient: am5.LinearGradient.new(root, {
            rotation: 0,
            stops: [{ color: am5.color(0xfb7116) }, { color: am5.color(0x19d228) }],
          }),
          labelsTemplate: {
            visible: true, // show custom label (axisRange)
            forceHidden: true, // hide default axis label
          },
          radius: am5.percent(60),
        },
        clockHand: {
          topWidth: 0,
          bottomWidth: 6,
          pinRadius: 3,
          radius: am5.percent(50),
          innerRadius: 0,
          layer: 1,
        },
        customAxisLabel: {
          forceHidden: false,
          radius: 8,
          fontSize: 10,
          crisp: true,
          textType: 'adjusted',
          position: 'absolute',
        },
      };
    }

    // Create wrapper container
    const container = root.container.children.push(am5.Container.new(root, chartConfig.container));

    // Add title
    const recommendationText = am5.Label.new(root, chartConfig.recommendationText);
    container.children.push(recommendationText);

    let title;
    if (chartConfig.title) {
      title = am5.Label.new(root, chartConfig.title);
      container.children.push(title);
    }

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/radar-chart/
    const chart = root.container.children.push(am5radar.RadarChart.new(root, chartConfig.chart));

    // Create axis and its renderer
    // https://www.amcharts.com/docs/v5/charts/radar-chart/gauge-charts/#Axes
    const axisRenderer = am5radar.AxisRendererCircular.new(root, chartConfig.axisRenderer);

    axisRenderer.labels.template.setAll(chartConfig.axisRenderer.labelsTemplate);

    chartConfig.xAxisValueAxis = { ...chartConfig.xAxisValueAxis, renderer: axisRenderer };
    const xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, chartConfig.xAxisValueAxis));

    // Add clock hand
    // https://www.amcharts.com/docs/v5/charts/radar-chart/gauge-charts/#Clock_hands
    const axisRecommendationItem = xAxis.makeDataItem({});
    axisRecommendationItem.set('value', 0); // start animate from value 0

    const bullet = axisRecommendationItem.set(
      'bullet',
      am5xy.AxisBullet.new(root, {
        sprite: am5radar.ClockHand.new(root, chartConfig.clockHand),
      }),
    );
    xAxis.createAxisRange(axisRecommendationItem);

    // Custom axis label
    function createLabel(text, value) {
      const axisDataItem = xAxis.makeDataItem({ value });
      xAxis.createAxisRange(axisDataItem);
      const label = axisDataItem.get('label');
      chartConfig.customAxisLabel = { ...chartConfig.customAxisLabel, text };
      label.setAll(chartConfig.customAxisLabel);
    }

    Object.keys(chartRecommendationConfig).forEach((item) => {
      let labelText = item.toUpperCase();
      if (item === 'underweight' || item === 'overweight') {
        labelText = chartRecommendationConfig[item].short_name;
      }
      createLabel(labelText, chartRecommendationConfig[item].value);
    });

    // animate clockHand
    axisRecommendationItem.animate(chartConfig.animate);

    responsive.addRule({
      relevant: am5themes_Responsive.widthM,
      applying() {
        if (chartType === 'factsheet') {
          recommendationText.set('fontSize', 15);
          recommendationText.set('y', am5.percent(55));
        }

        if (title) {
          title.set('fontSize', 15);
          title.set('y', am5.percent(60));
        }
      },
      removing() {
        recommendationText.set('fontSize', 38);
        recommendationText.set('y', am5.percent(55));

        if (chartType === 'factsheet') {
          recommendationText.set('fontSize', 38);
          recommendationText.set('y', am5.percent(55));
        }

        if (title) {
          title.set('fontSize', 20);
          title.set('y', am5.percent(65));
        }
      },
    });

    //hide the whole gauge chart except the recommendation value in mobile in stock_comparison page
    responsive.addRule({
      relevant: function (width, height) {
        return height <= 50;
      },
      applying() {
        if (chartType === 'stocks_comparison') {
          recommendationText.set('fontSize', 15);
          recommendationText.set('y', am5.percent(50));
          xAxis.set('visible', false);
        }
      },
      removing() {
        if (chartType === 'stocks_comparison') {
          recommendationText.set('fontSize', 20);
          recommendationText.set('y', am5.percent(73));
          xAxis.set('visible', true);
        }
      },
    });

    // Make stuff animate on load
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [recommendation]);

  return <div id={id} className={chartConfig.rootClass} />;
}

ConsensusEstimatesGaugeChart.propTypes = {
  recommendation: PropTypes.string,
  chartType: PropTypes.string,
};

export default ConsensusEstimatesGaugeChart;
