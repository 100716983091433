import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  compareObjectsByNumberAttr,
  compareObjectsByStringAttr,
  formatDecimal,
} from '../../../../utils/utils';
import { createLoadableComponent } from '../../../../utils/Loadable';

const AllocationPieChart = createLoadableComponent(() =>
  import('../../../../components/AllocationPieChart'),
);

function extractCapitalAllocationData(counters) {
  if (!(counters?.length > 0)) {
    return [];
  }

  const totalValue = counters.reduce((accumulator, c) => {
    return accumulator + c.value;
  }, 0);

  const allocationData = counters.reduce(
    (accumulator, c) => {
      accumulator.push({
        name: c.stockName,
        value: Number(formatDecimal(c.value, 3)),
        percentage: Number(formatDecimal(100 * (c.value / totalValue), 2)),
        breakdown: '',
      });
      return accumulator;
    },
    [], // start with empty data
  );

  return allocationData.sort((a, b) => compareObjectsByNumberAttr(a, b, 'value', true));
}

export default function Capital() {
  const selectedPortfolio = useSelector((state) => state.portfolio.selected);
  const [currentCounters, setCurrentCounters] = useState([]);
  const [allocationData, setAllocationData] = useState([]);

  useEffect(() => {
    if (selectedPortfolio?.counters?.length > 0) {
      // only get from open positions
      const counters = selectedPortfolio.counters
        .filter((c) => c['Shares Held'] && c['Shares Held'] !== '-')
        .map((c) => {
          const value = parseFloat(c['Proceeds, Unrealized']?.replaceAll(',', '')) || 0;
          return { counter: c.counter, stockName: c.Name, value: value };
        })
        .sort((a, b) => compareObjectsByStringAttr(a, b, 'counter'));
      if (JSON.stringify(counters) !== JSON.stringify(currentCounters)) {
        setCurrentCounters(counters);
      }
    } else {
      setCurrentCounters([]);
    }
  }, [selectedPortfolio]);

  useEffect(() => {
    setAllocationData(extractCapitalAllocationData(currentCounters));
  }, [currentCounters]);

  return (
    <>
      {allocationData.length < 1 && (
        <div className="text-center">No allocation data available.</div>
      )}
      {allocationData.length > 0 && (
        <div className="g-mt-15 g-mb-15 g-height-450">
          <AllocationPieChart data={allocationData} percentInnerRadius={55} percentHeight={95} />
        </div>
      )}
    </>
  );
}
