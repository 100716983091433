import React from 'react';

export default function RemarksBursa() {
  return (
    <table className="table table-borderless g-text-size-13 g-lg-text-size-14 table-shrink mb-0 text-nowrap">
      <thead>
        <tr>
          <th>Remarks</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>I</th>
          <td>Order entry forbidden</td>
        </tr>
        <tr>
          <th>G</th>
          <td>Frozen</td>
        </tr>
        <tr>
          <th>R</th>
          <td>Reserved</td>
        </tr>
        <tr>
          <th>[x]</th>
          <td>Exclude dividend</td>
        </tr>
        <tr>
          <th>A</th>
          <td>Order entry authorized</td>
        </tr>
        <tr>
          <th>S</th>
          <td>Suspended</td>
        </tr>
        <tr>
          <th>s</th>
          <td>Short selling available</td>
        </tr>
        <tr>
          <th>[c]</th>
          <td>Cum dividend</td>
        </tr>
      </tbody>
    </table>
  );
}
