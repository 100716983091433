/* eslint-disable react/no-unknown-property */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import AddToWatchListModal from '../AddToWatchListModal';
import { pricesClearAllSelectedStocks } from '../PriceTable/priceTableSlice';

function ColumnSelection({ selectedStocks, enableCompare, enableAddWatchlist }) {
  const [showWatchlist, setShowWatchlist] = useState(false);
  const [compareToolTip, setCompareToolTip] = useState("Select at least 2 stocks to compare!");
  const [disabledCompare, setDisabledCompare] = useState(true);

  const currentFilters = useSelector((state) => state.stockPrices.filters);
  const dispatch = useDispatch();

  const addToWatchlistHandler = (e) => {
    setShowWatchlist(true);
  };

  useEffect(() => {
    /* Reset */
    setDisabledCompare(false);
    setCompareToolTip("Compare selected.")

    /* Change tooltip same isStock type and selected at least 2. */
    if (selectedStocks.length < 2) {
      setCompareToolTip("Select at least 2 stocks to compare!");
      setDisabledCompare(true);
      return;
    }
    if ((new Set(selectedStocks.map(item => item.isStock))).size !== 1) {
      setCompareToolTip("You can't mix stocks and derivatives when comparing them.")
      setDisabledCompare(true);
    }
  }, [selectedStocks]);

  useEffect(() => {
    /* Reset selected */
    dispatch(pricesClearAllSelectedStocks());
  }, [currentFilters.layout])

  const compareButtonHandler = (e) => {
    const queryString = selectedStocks.map(stock => `code=${encodeURIComponent(stock.code)}`).join('&')
    const isWarrant = selectedStocks[0]["isStock"] == "true" ? "" : "/warrants"; //Unfortunately, the value is string instead of bool
    const url = `/stocks_comparison${isWarrant}${queryString ? `?${queryString}` : ''}`;
    window.open(url, '_blank');
  };

  return (
    <div className="d-flex gap-2">
      {enableAddWatchlist && (
        <OverlayTrigger overlay={<Tooltip>Select at least 1 stock to add!</Tooltip>}>
          <span className="d-inline-block">
            <button
              type="button"
              className="btn btn-light"
              onClick={addToWatchlistHandler}
              disabled={selectedStocks.length <= 0}
              title={selectedStocks.length <= 0 ? 'Select a stock' : ''}>
              <i className="fa-thin fa-arrows-to-eye fa-lg g-mr-5" />
              Add To Watchlist
            </button>
            <AddToWatchListModal
              show={showWatchlist}
              setShow={setShowWatchlist}
              selectedStock={selectedStocks.map(stock => stock.code)}
            />
          </span>
        </OverlayTrigger>
      )}

      {enableCompare && (
        <OverlayTrigger overlay={<Tooltip>{compareToolTip}</Tooltip>}>
          <span className="d-inline-block">
            <button
              type="button"
              className="btn btn-light"
              onClick={compareButtonHandler}
              disabled={disabledCompare}>
              <i className="fa-thin fa-code-compare fa-lg g-mr-5" />
              Compare
            </button>
          </span>
        </OverlayTrigger>
      )}
    </div>
  );
}

export default ColumnSelection;
