import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

import Loader from '../Loader';
import { membershipResetData, registerForTrial } from './signUpSlice';

const { CancelToken } = axios;

function TrialSignUpResultModal({
  showModal,
  setShowModal,
  country,
  trialDays,
  promoCode,
  couponCode = null,
}) {
  const dispatch = useDispatch();
  const source = CancelToken.source();
  const data = useSelector((state) => state.signUp.data);
  const loading = useSelector((state) => state.signUp.loading);
  const plan = useSelector((state) => state.membership.plan);
  const [confirmed, setConfirmed] = useState(false);

  const closeModal = () => {
    setShowModal(false);
    dispatch(membershipResetData());
    setConfirmed(false);
  };
  const handleClick = () => {
    dispatch(
      registerForTrial({
        country,
        promoCode,
        couponCode,
        cancelToken: source.token,
      }),
    );
    setConfirmed(true);
  };

  useEffect(() => {
    if (data?.success) {
      setTimeout(() => {
        window.location.replace(`/${country}`);
      }, 3000);
    }
    return () => {};
  }, [data]);

  return (
    <Modal centered show={showModal} onHide={closeModal} className="p-4">
      <Modal.Body className="p-4">
        <Loader visible={loading} />

        {!loading && (
          <>
            <button
              type="button"
              className="btn-close float-end"
              aria-label="Close"
              onClick={closeModal}
            />

            {!confirmed && (
              <div className="text-center">
                <h2 className="mt-4">Start your free trial</h2>
                <p>
                  Enjoy {trialDays} Days of our{' '}
                  {promoCode && promoCode.includes('station') ? 'Premium' : 'Pro'} Plan - No Payment
                  Info Required
                </p>
                <button
                  type="button"
                  className="g-lg-mr-13 btn btn-primary d-inline-block btn-lg g-mt-30 w-75"
                  onClick={handleClick}>
                  <h3 className="mb-0">Start free trial</h3>
                </button>
              </div>
            )}

            {confirmed && !data?.success && (
              <div className="text-center">
                <i className="fas fa-times-circle text-danger fa-3x" />
                <h4 className="mt-4">Registration for free trial unsuccessful. {data.error}</h4>
              </div>
            )}

            {confirmed && data?.success && (
              <div className="text-center">
                <i className="fas fa-check-circle text-primary fa-3x" />
                <h4 className="mt-4">You have successfully signed up for trial.</h4>
                {data.message && <h6>{data.message}</h6>}
                <div>
                  Applying changes... <i className="fa-solid fa-circle-notch fa-spin icon-sm" />
                </div>
              </div>
            )}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default TrialSignUpResultModal;

TrialSignUpResultModal.propTypes = {
  country: PropTypes.string,
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  trialDays: PropTypes.number,
  promoCode: PropTypes.string,
  couponCode: PropTypes.string,
};
