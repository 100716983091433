import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import imgMedalGold from 'images/img-medal-gold.svg';
import imgMedalSilver from 'images/img-medal-silver.svg';
import imgMedalBronze from 'images/img-medal-bronze.svg';
import api from '../../utils/api';
import Loader from '../../components/Loader';
import APIErrorHandler from '../../components/APIErrorHandler';
import styles from './notableInvestorPortfolio.module.css';

const imgMedals = [imgMedalGold, imgMedalSilver, imgMedalBronze];

const { CancelToken } = axios;

function NotableInvestorPortfolio({ country, skipTitle = false }) {
  const source = CancelToken.source();
  const [isSSR, setIsSSR] = useState(true);
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    setIsSSR(typeof document === 'undefined');
    const cancelToken = source.token;
    setIsLoading(true);
    api
      .get(`prices/home_ownership_by_shareholder.json?country=${country}`, {
        cancelToken,
      })
      .then(({ data }) => {
        setData(data);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  }, []);

  if (isSSR || isLoading) {
    return <Loader visible={isSSR || isLoading} className="g-height-800" />;
  }

  if (error) {
    return <APIErrorHandler error={error} />;
  }

  if (!data || data.length === 0) {
    return;
  }

  return (
    <div id="homeNotableInvestorPortfolio">
      {!skipTitle && <h3 className="g-mb-30">Notable Investor&apos;s portfolio</h3>}
      {data && data.owners && data.owners.length > 0 && (
        <div className="row rowXscrollable gy-5">
          {data.owners.map((owner) => {
            return (
              <div key={owner.id} className="col-lg-4 g-mb-10 g-max-width-550">
                <div className="card">
                  <div className="d-flex card-header p-4 text-center g-min-height-120">
                    <h5
                      className={`${styles.whiteSpacePreLine} flex-fill align-self-center lh-2 mb-0`}>
                      {owner.name === 'Temasek Holdings Pte Ltd. (Investment Company)'
                        ? 'Temasek Holdings Pte Ltd. \n (Investment Company)'
                        : owner.name}
                    </h5>
                  </div>
                  <ul className="list-group list-group-flush">
                    {owner.stock_info_lookup.map((stock, index) => (
                      <li
                        key={stock.counter}
                        className="list-group-item g-pl-15 g-pr-15 g-pt-30 g-pb-30">
                        <div className="row">
                          <div className="col-auto">
                            <img
                              src={imgMedals[index]}
                              className="img-fluid g-height-70"
                              alt={`Top ${index + 1}`}
                            />
                          </div>
                          <span className="col">
                            <a
                              className="fw-bold d-block text-dark"
                              href={`/quote/${stock.counter}`}
                              title={stock.name}>
                              {stock.name}
                            </a>
                            <small className="text-muted">Currency: {stock.currency}</small>
                            <div className="mt-3">
                              <span className="fw-bold">{stock.last_done_price} </span>
                              <span
                                className={
                                  stock.change > 0
                                    ? 'text-primary'
                                    : stock.change < 0
                                    ? 'text-danger'
                                    : ''
                                }>
                                {stock.change} ({stock.percent_change}%)
                              </span>
                            </div>
                          </span>
                        </div>
                      </li>
                    ))}
                  </ul>
                  <div className="card-body text-center">
                    <a
                      href={`/significant_shareholders/ownership_by_shareholder?shareholder_id=${owner.id}`}
                      target="_blank"
                      className="btn btn-primary"
                      rel="noreferrer">
                      View More
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

NotableInvestorPortfolio.propTypes = {
  country: PropTypes.string,
  skipTitle: PropTypes.bool,
};

export default NotableInvestorPortfolio;
