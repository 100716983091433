import React, { useState, useRef } from 'react';
import { Button, Form, Alert, Col } from 'react-bootstrap';
import axios from 'axios';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import * as yup from 'yup';
import { Formik, Form as FormikForm } from 'formik';
import api from '../../utils/api';
import styles from './resetPassword.module.css';

function ResetPasswordComponent({ site_key = '', valid_code = false, perform_check = true }) {
  const [loading, setIsLoading] = useState(false);
  const [showLoginButton, setShowLoginButton] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const reCaptchaRef = useRef(null);

  const LOGIN_URL = '/user/login';
  const RESET_PASSWORD_URL = '/user/reset_password';
  const SERVER_ERROR_FAILED_MESSAGE =
    'Failed to reset password. Please contact us if you are unable to reset password.';
  const INVALID_CODE_MESSAGE =
    "The link you followed is incomplete. Please ensure the entire link is copied into your browser's address bar.";
  const RECAPTCHA_FAILED_MESSAGE =
    'Failed to verify that you are a human. Please refresh page and try again.';

  const { CancelToken } = axios;

  const schema = yup.object().shape({
    password: yup
      .string()
      .transform((value) => value.trim())
      .min(8, 'Password is at least 8 characters in length')
      .required('Password is required'),
    confirmPassword: yup
      .string()
      .transform((value) => value.trim())
      .oneOf([yup.ref('password'), ''], 'Passwords must match')
      .required('Repeat password is required'),
  });

  const getRecaptchaToken = async () => {
    try {
      return await reCaptchaRef.current.executeAsync();
    } catch (error) {
      return '';
    }
  };

  const doReset = async (values) => {
    setIsLoading(true);

    /* Get grecaptcha */
    let captchaToken = '';
    if (perform_check) {
      captchaToken = await getRecaptchaToken();
      if (!captchaToken) {
        setErrorMessage(RECAPTCHA_FAILED_MESSAGE); // Recaptcha failed.
        return;
      }
    }

    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');

    api
      .post(
        RESET_PASSWORD_URL,
        {
          password: values.password.trim().toLowerCase(),
          confirmPassword: values.confirmPassword.trim().toLowerCase(),
          code,
          recaptcha_token: captchaToken,
        },
        {
          CancelToken,
        },
      )
      .then((response) => {
        if (response.data.success) {
          /* Show login button after password reset. */
          setShowLoginButton(true);
        } else {
          setErrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        setErrorMessage(`${SERVER_ERROR_FAILED_MESSAGE} ${error.message}`);
      })
      .then(() => {
        setIsLoading(false);
        reCaptchaRef.current?.reset();
      });
  };

  if (!valid_code) {
    return (
      <div>
        <h4 className="text-center mb-3 ">Invalid Reset Code</h4>
        <div className="text-center mb-5 g-text-size-15">{INVALID_CODE_MESSAGE}</div>
      </div>
    );
  }

  return (
    <>
      {showLoginButton ? (
        <div className="text-center mb-5 mt-5">
          <h4 className="text-center mb-3 ">Password Changed</h4>
          <div className="text-center mb-5 g-text-size-15">Your password was updated</div>
          <Button
            variant="success"
            type="button"
            className={`d-inline-flex justify-content-center align-items-center ${styles.signInButton}`}
            name="Sign In"
            onClick={() => {
              window.location.href = LOGIN_URL;
            }}>
            Sign in
          </Button>
        </div>
      ) : (
        <div>
          <h4 className="text-center mt-5">Reset your password</h4>

          {errorMessage && (
            <Alert variant="danger" className="g-text-size-15">
              <i className="fa fa-solid fa-triangle-exclamation fa-fw" /> <b>Error: </b>
              {errorMessage}
            </Alert>
          )}

          <Formik
            validationSchema={schema}
            className="mb-5"
            initialValues={{ password: '', confirmPassword: '' }}
            onSubmit={doReset}>
            {({ handleSubmit, handleChange, values, touched, errors }) => (
              <FormikForm noValidate onSubmit={handleSubmit}>
                <Form.Group as={Col} className="mt-4">
                  <Form.Control
                    name="password"
                    type="password"
                    placeholder="Enter new password"
                    value={values.password}
                    className={styles.signInInput}
                    onChange={handleChange}
                    isInvalid={touched.password && !!errors.password}
                  />
                  <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} className="mt-4 mb-4 ">
                  <Form.Control
                    name="confirmPassword"
                    type="password"
                    placeholder="Confirm password"
                    value={values.confirmPassword}
                    className={styles.signInInput}
                    onChange={handleChange}
                    isInvalid={touched.confirmPassword && !!errors.confirmPassword}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.confirmPassword}
                  </Form.Control.Feedback>
                </Form.Group>
                {perform_check && (
                  <ReCAPTCHA
                    ref={reCaptchaRef}
                    className="d-inline-block"
                    theme="dark"
                    size="invisible"
                    sitekey={site_key}
                  />
                )}
                <div className="text-center">
                  {loading ? (
                    <Button
                      variant="success"
                      type="button"
                      className="w-100 d-inline-flex justify-content-center align-items-center "
                      name="submit"
                      disabled
                      onClick={() => {}}>
                      <div
                        className="spinner-border spinner-border-sm text-light me-2"
                        role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                      Signing In...
                    </Button>
                  ) : (
                    <Button
                      variant="success"
                      type="button"
                      className={`w-100 d-inline-flex justify-content-center align-items-center ${styles.signInButton}`}
                      name="submit"
                      onClick={handleSubmit}>
                      Reset password
                    </Button>
                  )}
                </div>
              </FormikForm>
            )}
          </Formik>
        </div>
      )}
    </>
  );
}

ResetPasswordComponent.propTypes = {
  site_key: PropTypes.string,
  valid_code: PropTypes.bool,
  perform_check: PropTypes.bool,
};

export default ResetPasswordComponent;
