import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import dayjs from 'dayjs';

import CreateUpdateDividendModal from './CreateUpdateDividendModal';
import { getCurrencyName } from '../../currencies';
import { clearResponseAndError, createUpdateDividend } from './portfolioDividendSlice';

const { CancelToken } = axios;

function CreateUpdateDividend({ dividend, setDividend }) {
  const dispatch = useDispatch();
  const source = CancelToken.source();
  const selectedPortfolio = useSelector((state) => state.portfolio.selected);
  const loading = useSelector((state) => state.portfolioDividend.loading);
  const selectedLayout = useSelector((state) => state.portfolio.selectedLayout);
  const [showModal, setShowModal] = useState(false);

  const formatNumber = (value) => {
    if (!value) {
      return '';
    }
    const newVal = value.replaceAll(',', '');
    return parseFloat(newVal);
  };
  const formatDate = (value) => {
    if (!value) {
      return '';
    }
    const newVal = new Date(value);
    return dayjs(newVal).format('YYYY-MM-DD');
  };

  const formatDividend = () => {
    return {
      ...dividend,
      ...{
        stockName: dividend?.stock_name,
        shares: formatNumber(dividend?.shares),
        exDate: formatDate(dividend?.ex_date),
        payDate: formatDate(dividend?.pay_date),
        amount: formatNumber(dividend?.amount),
        amountInCurrency: formatNumber(dividend?.amount_in_currency),
        exchangeRate: formatNumber(dividend?.exchange_rate),
        total: formatNumber(dividend?.total),
        exchangeCurrency: {
          value: dividend?.curr_id,
          label: getCurrencyName(dividend?.curr_id),
        },
      },
    };
  };

  const closeModal = () => {
    setShowModal(false);
    if (setDividend) {
      setDividend(null);
    }
  };

  const openModal = () => {
    dispatch(clearResponseAndError());
    setShowModal(true);
  };

  const handleSave = (dividend_) => {
    dispatch(
      createUpdateDividend({
        portfolioID: selectedPortfolio.id,
        dividendObj: dividend_,
        selectedLayout,
        cancelToken: source.token,
      }),
    );
  };

  useEffect(() => {
    if (dividend && dividend.id) {
      openModal();
    }
  }, [dividend]);

  return (
    <>
      {!dividend ||
        (!dividend.id && (
          <Button className="btn btn-lg" variant="light" onClick={openModal} disabled={loading}>
            <i className="fa-light fa-rectangle-history-circle-plus" /> Add Dividend
          </Button>
        ))}
      <CreateUpdateDividendModal
        show={showModal}
        closeModal={closeModal}
        dividendInit={formatDividend(dividend)}
        handleSave={handleSave}
      />
    </>
  );
}
CreateUpdateDividend.propTypes = {
  dividend: PropTypes.object,
  setDividend: PropTypes.func,
};

export default CreateUpdateDividend;
