import React from 'react';
import PropTypes from 'prop-types';
import md5 from 'md5';

import blankLogo from 'images/blank.png';

const LOGO_BASE = 'https://coylogos.s3.ap-southeast-1.amazonaws.com/d/';

function CompanyLogoImage({ counter, classes }) {
  if (!counter) return null;

  const lastIndex = counter.lastIndexOf('.');
  let symbol = '';
  let marketSymbol = '';

  if (lastIndex !== -1) {
    symbol = counter.substring(0, lastIndex);
    marketSymbol = counter.substring(lastIndex + 1);
  }

  const logo = LOGO_BASE + md5(`coylogos://${marketSymbol}/${symbol}`);

  return (
    <img
      src={logo}
      className={`img-fluid ${classes}`}
      alt={symbol}
      onError={(e) => {
        e.target.onerror = null;
        e.target.src = blankLogo;
        e.target.alt = '';
        e.target.className = `border-light border h-100 w-100 ${classes}`;
      }}
    />
  );
}

export default CompanyLogoImage;

CompanyLogoImage.propTypes = {
  counter: PropTypes.string,
  classes: PropTypes.string,
};
