import React from 'react';
import PropTypes from 'prop-types';

import styles from './searchQuote.module.css';

export default function QuoteNews({ activeCounter, activeCounterNews, eodMarkets }) {
  if (activeCounter.market === 'HKEx' || eodMarkets.includes(activeCounter.market)) {
    return null;
  }

  return (
    <div>
      <h6>Company News</h6>
      <hr className="my-1" />
      {(!activeCounterNews || !activeCounterNews.data) && (
        <div className="my-4 text-center">
          {activeCounterNews.message ? activeCounterNews.message : 'No data found.'}
        </div>
      )}
      {activeCounterNews && activeCounterNews.data && (
        <ul className={styles.quoteInfoNewsList}>
          {activeCounterNews.data.map((data, index) => (
            <li key={index}>
              <div className="row">
                <div className="col-3 pe-0">{data.date}</div>
                <div className="col-9">
                  <a className={styles.quoteInfoNewsLink} href={data.link}>
                    {data.title}
                  </a>
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

QuoteNews.propTypes = {
  activeCounterNews: PropTypes.object,
  activeCounter: PropTypes.object,
  eodMarkets: PropTypes.array,
};
