import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import { getParamCounterId } from '../../../../utils/utils';
import { updateCounterNotes } from '../../portfolioSlice';

const { CancelToken } = axios;

export default function EditCounterNotesModal({ show, handleCloseModal, counterNotes }) {
  const dispatch = useDispatch();
  const source = CancelToken.source();
  const innerRef = useRef(null);
  const [notes, setNotes] = useState(counterNotes?.notes?.trim() || '');

  const handleNotesChange = (e) => {
    const value = e.target.value?.trim() || '';
    if (value === notes) {
      return;
    }
    setNotes(value);
  };

  const handleClose = () => {
    handleCloseModal();
    setNotes('');
  };

  const handleSave = () => {
    const origNotes = counterNotes?.notes?.trim() || '';
    if (notes !== origNotes) {
      const params = {
        portfolioID: counterNotes.portfolioID,
        counter: counterNotes.counter,
        notes: notes,
        cancelToken: source.token,
      };
      dispatch(updateCounterNotes(params));
    }
    handleClose();
  };

  useEffect(() => {
    if (innerRef.current) {
      innerRef.current.focus();
    }
  }, [show]);

  return (
    <Modal show={show} centered dialogClassName="g-text-size-14" onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Notes</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="row mt-2">
          <h6 className="g-mb-10">
            {`${counterNotes.name} (${getParamCounterId(counterNotes.counter)})`}
          </h6>
          <Form.Group className="col-12 g-mb-20">
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Enter notes here"
              className="w-100"
              onBlur={(e) => {
                handleNotesChange(e);
              }}
              defaultValue={notes}
              ref={innerRef}
              autoFocus={show}
            />
          </Form.Group>
          <br />
          <Form.Group className="col-12 g-mb-10 d-flex align-items-center justify-content-center">
            <Button variant="secondary" className="g-ml-10 g-mr-10" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" className="g-ml-10 g-mr-10" onClick={handleSave}>
              Save
            </Button>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

EditCounterNotesModal.propTypes = {
  show: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  counterNotes: PropTypes.shape({
    portfolioID: PropTypes.string,
    counter: PropTypes.string,
    name: PropTypes.string,
    notes: PropTypes.string,
  }),
};
