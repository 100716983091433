import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';

import style from './eventsCalendar.module.css';
import FixedHeaderTable from '../../../components/FixedHeaderTable';

export default function EventsTable({ data, attachmentCount, currency, toggleFactsheetNav }) {
  const tableContainerID = 'EventsTableContainer';
  const tableProps = {
    className: style.eventsTable,
  };

  useEffect(() => {
    const table = document.getElementById(tableContainerID);
    if (toggleFactsheetNav) {
      toggleFactsheetNav(null, table);
    }
    return () => {
      if (toggleFactsheetNav) {
        toggleFactsheetNav(null, table);
      }
    };
  }, []);

  return (
    <FixedHeaderTable
      header={<TableHeader attachmentCount={attachmentCount} />}
      body={<TableBody data={data} attachmentCount={attachmentCount} currency={currency} />}
      handleScrollY={toggleFactsheetNav}
      tableContainerID={tableContainerID}
      tableProps={tableProps}
    />
  );
}

EventsTable.propTypes = {
  data: PropTypes.array,
  attachmentCount: PropTypes.number,
  currency: PropTypes.string,
  toggleFactsheetNav: PropTypes.func,
};

function TableHeader({ attachmentCount }) {
  return (
    <thead className="table-secondary">
      <tr className={style.column}>
        <th className="text-start fixedColumn">Date</th>
        <th className="text-start">Type</th>
        <th className={`text-start ${style.remarksColumn}`}>Remarks</th>
        {attachmentCount > 0 && <th className="text-center">Attachments</th>}
      </tr>
    </thead>
  );
}

TableHeader.propTypes = {
  attachmentCount: PropTypes.number,
};

function TableBody({ data, attachmentCount, currency }) {
  return (
    <tbody>
      {data.map((row) => (
        <tr key={row.id}>
          <td className="text-start fixedColumn">{row.date}</td>
          <td className="text-start">
            <EventBadge record={row} />
          </td>
          <td className="text-start">
            <EventRemarks remarks={row.remarks} />
            {row.remarks && row.remarks.length > 0 && row.related_data && <br />}
            <EventRelatedData relatedData={row.related_data} currency={currency} />
          </td>
          {attachmentCount > 0 && (
            <td className="text-center">
              <EventAttachments attachments={row.attachments} />
            </td>
          )}
        </tr>
      ))}
    </tbody>
  );
}

TableBody.propTypes = {
  data: PropTypes.array,
  attachmentCount: PropTypes.number,
  currency: PropTypes.string,
};

function EventBadge({ record }) {
  if (!record || !record.type) {
    return null;
  }

  let badgeClass;
  switch (record.type) {
    case 'EX-DATE':
      badgeClass = style.exDateBadge;
      break;
    case 'RESULT':
      badgeClass = style.resultBadge;
      break;
    case 'SHARE_PLACEMENT':
      badgeClass = style.spBadge;
      break;
    case 'GM':
      badgeClass = style.gmBadge;
      break;
    default:
      badgeClass = 'bg-light text-dark';
  }

  return <span className={`badge ${badgeClass}`}>{record.type_label}</span>;
}

EventBadge.propTypes = {
  record: PropTypes.object,
};

function EventRemarks({ remarks }) {
  if (!remarks) {
    return null;
  }

  return remarks.split('\n').map((line, index) => (
    <div key={index}>
      {line}
      <br />
    </div>
  ));
}

EventRemarks.propTypes = {
  remarks: PropTypes.string,
};

function EventAttachments({ attachments }) {
  if (!attachments || attachments.length === 0) {
    return null;
  }

  return attachments.map((url, index) => (
    <a href={url} className="text-dark" key={index}>
      <i className="fa-thin fa-file-pdf fa-xl m-1" />
    </a>
  ));
}

EventAttachments.propTypes = {
  attachments: PropTypes.array,
};

function EventRelatedData({ relatedData, currency }) {
  if (!relatedData || Object.keys(relatedData).length === 0) {
    return null;
  }

  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(`(${currency} &apos;000)`),
        }}
      />
      {Object.keys(relatedData).map((key) => (
        <div key={key}>
          <b>{key}: </b>
          {relatedData[key]}
          <br />
        </div>
      ))}
    </>
  );
}

EventRelatedData.propTypes = {
  relatedData: PropTypes.object,
  currency: PropTypes.string,
};
