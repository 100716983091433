import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedModel } from './intrinsicValueAnalysisSlice';

const DROPDOWN_MODELS = [
  {
    key: 'discounted_earning',
    name: 'Discounted Earnings Model',
  },
  {
    key: 'discounted_cashflow',
    name: 'Discounted Cash Flow Model',
  },
  {
    key: 'gordon_growth',
    name: 'Gordon Growth Model',
    exclude_tab: 'peer_analysis',
  },
];

export default function CalculatorModel({ tab }) {
  const dispatch = useDispatch();
  const selectedModel = useSelector((state) => state.intrinsicValueAnalysis.selectedModel);

  // change calculator model
  const handleOnClickModel = (newModel) => {
    if (newModel === selectedModel) {
      return;
    }
    dispatch(setSelectedModel({ selectedModel: newModel }));
  };

  return (
    <Dropdown className="d-inline-block">
      <Dropdown.Toggle variant="light">
        {DROPDOWN_MODELS.find((model) => model.key === selectedModel).name}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {DROPDOWN_MODELS.filter((model) => model.exclude_tab !== tab).map((model) => {
          return (
            <Dropdown.Item key={model.key} onClick={() => handleOnClickModel(model.key)}>
              {model.name}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}

CalculatorModel.propTypes = {
  tab: PropTypes.string,
};
