import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Container, Row, Col, Modal, Button, Image, Form, Spinner, Alert } from 'react-bootstrap';
import * as yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import MobileVerification from '../../../components/SignUp/MobileVerification';
import styles from './membership.module.css';
import { Constants } from '../../../utils/constants';
import {
  selectMembershipProfile,
  setFieldValue,
  uploadProfilePhoto,
  updateProfileDetail,
  hideSuccessLabel,
  showSuccessLabel,
} from './membershipSlice';
import { membershipSetUserID, selectMobile } from '../../../components/SignUp/signUpSlice';
import ChangeEmailForm from './ChangeEmailForm';
import ChangePasswordForm from './ChangePasswordForm';

const RECAPTCHA_FAILED_MESSAGE =
  'Failed to verify that you are a human. Please refresh page and try again.';

const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .transform((value) => value.trim())
    .required('First Name is required')
    .min(2, 'First name must be at least 2 characters long')
    .max(50, 'First name cannot exceed 50 characters'),
  lastName: yup
    .string()
    .transform((value) => value.trim())
    .required('First Name is required')
    .min(2, 'First name must be at least 2 characters long')
    .max(50, 'First name cannot exceed 50 characters'),
  birthMonth: yup.string().required('Birth month is required.'),
  birthYear: yup
    .number()
    .typeError('Please select a valid year')
    .required('Birth year is required.'),
  email: yup.string().email('Invalid email format').required('Email is required'),
  password: yup
    .string()
    .trim()
    .min(8, 'Password must be at least 8 characters')
    .required('Password is required'),
  confirmPassword: yup
    .string()
    .trim()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
});

function EditProfile() {
  const dispatch = useDispatch();
  const membershipProfile = useSelector(selectMembershipProfile);
  const { performCheck, siteKey } = membershipProfile;
  const mobileNumber = useSelector(selectMobile);
  const photoFileRef = useRef(null);
  const reCaptchaRef = useRef(null);
  const [errors, setErrors] = useState({});
  const [password, setPassword] = useState('');
  const [isMobileModalOpen, setIsMobileModalOpen] = useState(false);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);

  const { CancelToken } = axios;

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const currentYear = new Date().getFullYear();
  const startYear = Math.floor(currentYear - 90);
  const adjustedStartYear = startYear % 5 === 0 ? startYear : startYear - (startYear % 5);
  const years = [];
  for (let year = adjustedStartYear; year <= currentYear; year += 5) {
    years.push(year);
  }

  const charactersNameInitial = `${membershipProfile.firstName?.[0] || ''}${
    membershipProfile.lastName?.[0] || ''
  }`.toUpperCase();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch(hideSuccessLabel());
    }, 2100);

    return () => clearTimeout(timeoutId);
  }, [membershipProfile.showSuccess]);

  useEffect(() => {
    if (mobileNumber) {
      dispatch(setFieldValue({ fieldName: 'mobile', value: mobileNumber }));
      setIsMobileModalOpen(false);
    }
  }, [mobileNumber]);

  const getRecaptchaToken = async () => {
    try {
      return await reCaptchaRef.current.executeAsync();
    } catch (error) {
      return '';
    }
  };

  const handleTextInputChange = (event) => {
    const { name, value } = event.target;
    dispatch(setFieldValue({ fieldName: name, value }));
  };

  const closeMobileModal = () => {
    setIsMobileModalOpen(false);
  };

  const closeEmailModal = () => {
    setIsEmailModalOpen(false);
  };

  const closePasswordModal = () => {
    setIsPasswordModalOpen(false);
  };

  const handleChoosePhoto = () => {
    photoFileRef.current.click();
  };

  const handleBirthMonthChange = (event) => {
    dispatch(setFieldValue({ fieldName: 'birthMonth', value: event.target.value }));
  };

  const handleBirthYearChange = (event) => {
    dispatch(setFieldValue({ fieldName: 'birthYear', value: event.target.value }));
  };

  const handlePhotoSelected = (event) => {
    /* Display image selected */
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        dispatch(setFieldValue({ fieldName: 'profilePicture', value: reader.result }));

        /* Upload Image */
        const source = CancelToken.source();
        dispatch(uploadProfilePhoto({ imageFile: file, cancelToken: source.token }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUpdateDetails = () => {
    validationSchema
      .pick(['firstName', 'lastName', 'birthMonth', 'birthYear'])
      .validate(
        {
          firstName: membershipProfile.firstName,
          lastName: membershipProfile.lastName,
          birthMonth: membershipProfile.birthMonth,
          birthYear: membershipProfile.birthYear,
        },
        { abortEarly: false },
      )
      .then(async () => {
        /* Get grecaptcha */
        let captchaToken = '';
        if (performCheck) {
          captchaToken = await getRecaptchaToken();
          if (!captchaToken) {
            dispatch(
              setFieldValue({ fieldName: 'updateDetailsError', value: RECAPTCHA_FAILED_MESSAGE }),
            );
            return;
          }
        }

        /* Validation complete. Send to server. */
        setErrors({});
        const source = CancelToken.source();
        await dispatch(
          updateProfileDetail({
            firstName: membershipProfile.firstName,
            lastName: membershipProfile.lastName,
            birthDate: `1-${membershipProfile.birthMonth}-${membershipProfile.birthYear}`,
            captchaToken,
            cancelToken: source.token,
          }),
        );
      })
      .catch((err) => {
        const formErrors = {};
        err.inner.forEach((error) => {
          formErrors[error.path] = error.message;
        });
        setErrors(formErrors);
      })
      .then(() => {
        reCaptchaRef.current?.reset();
      });
  };

  const handleUpdateEmail = () => {
    setIsEmailModalOpen(true);
  };

  const handleChangePassword = () => {
    setIsPasswordModalOpen(true);
  };

  const handleChangeMobileNumber = () => {
    dispatch(membershipSetUserID(membershipProfile.userId));
    setIsMobileModalOpen(true);
  };

  return (
    <Container className={styles.membershipContainer}>
      <Row>
        <Col>
          <div>
            <h5>Your Profile</h5>
            <hr className="mt-2" />
          </div>
        </Col>
      </Row>
      <Row className={styles.sectionGap}>
        <Col md={4} xs={12} className="text-center mt-4 pt-2">
          <div onClick={handleChoosePhoto} className={styles.profilePhotoContainer}>
            <div>{charactersNameInitial}</div>
            {membershipProfile.profilePicture && (
              <>
                <div style={{ backgroundColor: 'white' }} className={styles.profilePicture} />
                <Image src={membershipProfile.profilePicture} className={styles.profilePicture} />
              </>
            )}
          </div>
          <div>
            <Form.Control
              type="file"
              className="d-none"
              ref={photoFileRef}
              onChange={handlePhotoSelected}
              accept="image/*"
            />
            <Button
              type="button"
              variant="dark"
              className={styles.button}
              onClick={handleChoosePhoto}>
              Upload Photo
            </Button>
          </div>
          <div className={styles.finePrint}>JPG, GIF or PNG. Max size of 2MB</div>
        </Col>
        <Col md={8} xs={12} className="mt-4 pt-2">
          <Row>
            <Col md={6} xs={12}>
              <Form.Group className="mb-3" controlId="firstName">
                <Form.Label className={styles.formLabel}>First Name</Form.Label>
                <Form.Control
                  type="text"
                  className={styles.formInput}
                  placeholder="First Name"
                  data-lpignore="true"
                  name="firstName"
                  value={membershipProfile.firstName}
                  onChange={handleTextInputChange}
                  isInvalid={!!errors.firstName}
                />
                <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6} xs={12}>
              <Form.Group className="mb-3" controlId="lastName">
                <Form.Label className={styles.formLabel}>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  className={styles.formInput}
                  placeholder="Last Name"
                  name="lastName"
                  value={membershipProfile.lastName}
                  onChange={handleTextInputChange}
                  isInvalid={!!errors.lastName}
                />
                <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6} xs={12}>
              <Form>
                <Row>
                  <Col>
                    <Form.Group className="mb-2" controlId="month_of_birth">
                      <Form.Label className={styles.formLabel}>Month of birth</Form.Label>
                      <Form.Select
                        value={membershipProfile.birthMonth}
                        onChange={handleBirthMonthChange}
                        isInvalid={!!errors.birthMonth}>
                        <option value="">Select a month</option>
                        {months.map((month) => (
                          <option key={month} value={month}>
                            {month}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors.birthMonth}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-2" controlId="year_of_birth">
                      <Form.Label className={styles.formLabel}>Year of birth</Form.Label>
                      <Form.Select
                        value={membershipProfile.birthYear}
                        onChange={handleBirthYearChange}
                        isInvalid={!!errors.birthYear}>
                        <option value="">Select a year</option>
                        {years.map((year) => (
                          <option key={year} value={year}>
                            {year}-{year + 4 > currentYear ? currentYear : year + 4}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors.birthYear}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <Row>
            {membershipProfile.updateDetailsError && (
              <Col xs={12}>
                <Alert
                  style={{ marginBottom: -5 }}
                  variant="danger"
                  dismissible
                  className={`${styles.alertBox}`}>
                  <i className="fa-solid fa-triangle-exclamation" />
                  <b>Error: </b> {membershipProfile.updateDetailsError}
                </Alert>
              </Col>
            )}
            <Col className="d-flex flex-row align-items-center">
              <Button
                type="button"
                variant="dark"
                className={styles.greenButton}
                onClick={handleUpdateDetails}>
                {membershipProfile.updateDetailsStatus === Constants.STATUS_LOADING ? (
                  <Spinner animation="border" role="status" size="sm" variant="light">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                ) : (
                  'Update details'
                )}
              </Button>
              {membershipProfile.showSuccess.updateProfileDetail && (
                <div className={`${styles.detailsUpdated} ${styles.fadeOutTextEffect}`}>
                  Details updated
                </div>
              )}
            </Col>
          </Row>
          <hr className="mt-2" />
          <Row className={styles.sectionGap}>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group className="mb-3" controlId="mobile">
                  <Form.Label className={styles.formLabel}>Mobile number</Form.Label>
                  <Form.Control
                    type="text"
                    className={styles.formInput}
                    placeholder="Mobile number"
                    name="mobile"
                    value={membershipProfile.mobile}
                    onChange={handleTextInputChange}
                    data-lpignore="true"
                    autoComplete="off"
                    readOnly
                    disabled
                  />
                  <Form.Control.Feedback type="invalid">{}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} md={6} className="pt-md-3">
                <Button
                  type="button"
                  variant="dark"
                  className={styles.greenButton}
                  onClick={handleChangeMobileNumber}>
                  Change mobile number
                </Button>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group className="mb-3" controlId="email">
                  <Form.Label className={styles.formLabel}>Email</Form.Label>
                  <Form.Control
                    type="text"
                    className={styles.formInput}
                    placeholder="Email"
                    name="email"
                    value={membershipProfile.email}
                    onChange={handleTextInputChange}
                    data-lpignore="true"
                    autoComplete="off"
                    readOnly
                    isInvalid={!!errors.email}
                    disabled
                  />
                  <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} md={6} className="pt-md-3">
                <div className="d-flex flex-row align-items-center">
                  <Button
                    type="button"
                    variant="dark"
                    className={styles.greenButton}
                    onClick={handleUpdateEmail}>
                    {membershipProfile.updateEmailStatus === Constants.STATUS_LOADING ? (
                      <Spinner animation="border" role="status" size="sm" variant="light">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    ) : (
                      'Change email'
                    )}
                  </Button>
                </div>
              </Col>
              {membershipProfile.updateEmailError && (
                <Col xs={12}>
                  <Alert variant="danger" dismissible className={`${styles.alertBox}`}>
                    <i className="fa-solid fa-triangle-exclamation" />
                    <b>Error: </b> {membershipProfile.updateEmailError}
                  </Alert>
                </Col>
              )}
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group className="mb-3" controlId="password">
                  <Form.Label className={styles.formLabel}>Password</Form.Label>
                  <Form.Control
                    type="text"
                    className={styles.formInput}
                    placeholder="Password"
                    name="password"
                    readOnly
                    value="************"
                    onChange={(e) => setPassword(e.target.value)}
                    data-lpignore="true"
                    autoComplete="off"
                    isInvalid={!!errors.password}
                    disabled
                  />
                  <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} md={6} className="pt-md-3">
                <div className="d-flex flex-row align-items-center">
                  <Button
                    type="button"
                    variant="dark"
                    className={styles.greenButton}
                    onClick={handleChangePassword}>
                    {membershipProfile.updatePasswordStatus === Constants.STATUS_LOADING ? (
                      <Spinner animation="border" role="status" size="sm" variant="light">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    ) : (
                      'Change password'
                    )}
                  </Button>
                </div>
              </Col>
              {membershipProfile.updatePasswordError && (
                <Col xs={12}>
                  <Alert variant="danger" dismissible className={`${styles.alertBox}`}>
                    <i className="fa-solid fa-triangle-exclamation" />
                    <b>Error: </b> {membershipProfile.updatePasswordError}
                  </Alert>
                </Col>
              )}
            </Row>
          </Row>
        </Col>
      </Row>

      <Modal centered show={isMobileModalOpen} onHide={closeMobileModal} className="p-4">
        <Modal.Body className="p-4">
          <button
            type="button"
            className="btn-close float-end"
            aria-label="Close"
            onClick={closeMobileModal}
          />
          <MobileVerification checkCaptcha={performCheck} siteKey={siteKey} title="Verification" />
        </Modal.Body>
      </Modal>

      <Modal centered show={isEmailModalOpen} onHide={closeEmailModal} className="p-4">
        <Modal.Header closeButton className="pt-2 pb-2">
          <Modal.Title style={{ fontSize: 16 }}>Change email</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          <ChangeEmailForm setIsEmailModalOpen={setIsEmailModalOpen} />
        </Modal.Body>
      </Modal>

      <Modal centered show={isPasswordModalOpen} onHide={closePasswordModal} className="p-4">
        <Modal.Header closeButton className="pt-2 pb-2">
          <Modal.Title style={{ fontSize: 16 }}>Change password</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          <ChangePasswordForm setIsPasswordModalOpen={setIsPasswordModalOpen} />
        </Modal.Body>
      </Modal>

      {performCheck && (
        <ReCAPTCHA
          ref={reCaptchaRef}
          className="d-inline-block"
          theme="dark"
          size="invisible"
          sitekey={siteKey}
        />
      )}
    </Container>
  );
}

export default EditProfile;
