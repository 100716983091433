import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';

const START_YEAR = 2000;

export default function YearFilter({ year, handleYearChange }) {
  let currentYear = new Date().getFullYear();
  const years = [];

  while (currentYear >= START_YEAR) {
    years.push(currentYear);
    currentYear -= 1;
  }

  return (
    <Dropdown>
      <Dropdown.Toggle variant="light" className="text-muted">
        {year || 'All'}
      </Dropdown.Toggle>

      <Dropdown.Menu className="">
        <div className="g-max-height-400 overflow-y-scroll styled-scrollbar">
          <Dropdown.Item
            className="text-wrap g-text-size-14"
            key="All"
            onClick={() => handleYearChange('All')}>
            All
          </Dropdown.Item>

          {years.map((y) => {
            return (
              <Dropdown.Item
                className="text-wrap g-text-size-14"
                key={y}
                onClick={() => handleYearChange(y)}>
                {y}
              </Dropdown.Item>
            );
          })}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}

YearFilter.propTypes = {
  year: PropTypes.string,
  handleYearChange: PropTypes.func,
};
