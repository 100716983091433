import React from 'react';

import PropTypes from 'prop-types';
import { Table, Button } from 'react-bootstrap';
import CompanyNameLink from '../../../components/StockInfoPopup/CompanyNameLink';

function SignalTable({ data, handleClickViewResult }) {
  return (
    <Table hover>
      <thead className="table-light">
        <tr>
          <th className="g-per-width-10">No</th>
          <th className="g-per-width-60">Name</th>
          <th className="g-per-width-30 text-center">Scan Results</th>
        </tr>
      </thead>
      <tbody>
        {data.map((result, index) => {
          return (
            <tr key={result.counter}>
              <td>{index + 1}</td>
              <td>
                <CompanyNameLink
                  counter={result.market_and_code}
                  name={result.name}
                  showStockInfoPopUp
                  showLogo
                  showCounter
                />
              </td>
              <td className="text-center">
                {result.count}
                {result.is_not_index && (
                  <Button
                    variant="light"
                    aria-label="View result"
                    onClick={() => handleClickViewResult(result.counter)}
                    className="btn-rounded g-ml-15 p-0 d-inline-block g-min-width-auto bg-transparent">
                    <i className="fa-light fa-xl fa-magnifying-glass-chart" />
                  </Button>
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

SignalTable.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array]),
  handleClickViewResult: PropTypes.func,
};

export default SignalTable;
