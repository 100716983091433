import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import axios from 'axios';
import dayjs from 'dayjs';
import { createTransactionFetchData, updateTransactionFetchData } from './transactionSlice';
import { getCurrencyName } from '../../currencies';

const { CancelToken } = axios;

const depositWithdrawActionActivitiesCodes = {
  withdraw: '7',
  deposit: '8',
};

function DepositWithdrawTransactionModal({ show, setShow, editingTransaction }) {
  const dispatch = useDispatch();
  const source = CancelToken.source();
  const [error, setError] = useState('');
  const formRef = useRef(null); // Create a ref

  const selectedPortfolio = useSelector((state) => state.portfolio.selected);
  const selectedLayout = useSelector((state) => state.portfolio.selectedLayout);
  const [date, setDate] = useState(dayjs(new Date()).format('YYYY-MM-DD HH:mm'));
  const [activity, setActivity] = useState(depositWithdrawActionActivitiesCodes.deposit);
  const [amount, setAmount] = useState('');
  const [remarks, setRemarks] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [transactionToEdit, setTransactionToEdit] = useState(editingTransaction);
  const [transactionId, setTransactionId] = useState();
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    const isEditMode = editingTransaction?.id;

    setEditMode(isEditMode);
    setTransactionToEdit(editingTransaction);
  }, [show.type, editingTransaction]);

  const handleClose = () => {
    setShow({ show: false, type: '' });
  };

  useEffect(() => {
    if (editMode) {
      setTransactionId(transactionToEdit.id);
      setDate(dayjs(transactionToEdit.date).format('YYYY-MM-DD HH:mm'));
      setAmount(transactionToEdit.amount);
      setRemarks(transactionToEdit.note);
      setActivity(transactionToEdit.trade_type);
    }
  }, [editMode, transactionToEdit]);

  const handleSave = (e) => {
    e.preventDefault();
    const form = formRef.current; // Access the form using ref
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      return;
    }

    const folder = selectedPortfolio.id;
    const params = {
      price: amount,
      activity,
      date,
      folder,
      remarks,
      selectedLayout,
      refreshPortfolio: selectedPortfolio?.counters?.length === 0,
      cancelToken: source.token,
    };

    if (editMode && transactionToEdit && Object.keys(transactionToEdit).length && transactionId) {
      params.transactionId = transactionId;
      dispatch(updateTransactionFetchData(params));
    } else {
      dispatch(createTransactionFetchData(params));
    }
    handleClose();
  };

  // Clear state when modal is closed
  const resetState = () => {
    setActivity(depositWithdrawActionActivitiesCodes.deposit);
    setEditMode(false);
    setRemarks('');
    setError('');
    setAmount('');
    setDate(dayjs(new Date()).format('YYYY-MM-DD HH:mm'));
    setValidated(false);
  };

  useEffect(() => {
    if (!show.show) {
      resetState();
    }
  }, [show.show]);

  let modalTitle = 'Cash Deposit / Withdrawal';
  if (editMode) {
    modalTitle = `Edit Cash ${transactionToEdit.stock_name}`;
  }

  return (
    <Modal
      show={show.show && show.type === 'cash'}
      centered
      dialogClassName="g-text-size-14 DepositWithdrawTransactionModal"
      onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate validated={validated} onSubmit={handleSave} ref={formRef}>
          {' '}
          {/* Attach ref to the form */}
          <Form.Group className="col-12 g-mb-20">
            <Form.Label>
              <b>Action</b>
            </Form.Label>
            <Form.Select
              name="action"
              value={activity}
              onChange={(e) => setActivity(e.target.value)}
              disabled={editMode}>
              <option value={depositWithdrawActionActivitiesCodes.deposit}>Deposit</option>
              <option value={depositWithdrawActionActivitiesCodes.withdraw}>Withdraw</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="col-12 g-mb-20">
            <Form.Label>
              <b>Amount</b>
            </Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                size="lg"
                type="number"
                value={amount}
                placeholder="eg. 100.231"
                min="0.001"
                step="0.001"
                required
                onChange={(e) => setAmount(e.target.value)}
              />
              {selectedPortfolio && selectedPortfolio.currency && (
                <InputGroup.Text className="g-text-size-14">
                  {getCurrencyName(selectedPortfolio.currency)}
                </InputGroup.Text>
              )}
              <Form.Control.Feedback type="invalid">
                Amount must be a positive number.
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group className="col-12 g-mb-20">
            <Form.Label>
              <b>Transaction Date</b>
            </Form.Label>
            <Form.Control
              size="lg"
              type="datetime-local"
              name="date"
              value={date}
              required
              onChange={(e) => setDate(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              Please choose a valid date.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="col-12 g-mb-20">
            <Form.Label>
              <b>Remarks(optional)</b>
            </Form.Label>
            <Form.Control
              type="text"
              size="lg"
              name="remarks"
              defaultValue={remarks}
              onChange={(e) => setRemarks(e.target.value)}
            />
          </Form.Group>
          <div className="g-tb-20 g-mb-20 text-center">
            <Button variant="primary" type="submit" className="text-center">
              {editMode ? 'Edit' : 'Create'}
            </Button>
            <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

DepositWithdrawTransactionModal.propTypes = {
  show: PropTypes.object,
  setShow: PropTypes.func,
  editingTransaction: PropTypes.object,
};

export default DepositWithdrawTransactionModal;
